import { all, fork } from 'redux-saga/effects';

import bonuses from './bonuses/saga';
import tournaments from './tournaments/saga';

export default function* loyaltyRootSaga() {
	yield all([
		fork(bonuses),
		fork(tournaments),
	]);
}
