import { normalize, schema } from 'normalizr';
import ParamsBuilder from '../../../helpers/paramsBuilder';
import EntityAdapter from '../../../helpers/entityAdapter';
import FilterComparator from '../../../helpers/filterComparator';

export const fields = {
	// base model
	userID                    : 'user_id',
	userName                  : 'user_name',
	userCountryID             : 'user_country_id',
	userGroupID               : 'user_group_id',
	userStatusID              : 'user_status_id',
	userBalanceBefore         : 'user_balance_before',
	userBalanceAfter          : 'user_balance_after',
	userBalanceBeforeUSD      : 'user_balance_before_usd',
	userBalanceAfterUSD       : 'user_balance_after_usd',
	websiteID                 : 'website_id',
	channelID                 : 'channel_id',
	// main model
	wdID                      : 'id',
	wdCode                    : 'withdrawal_transaction',
	wdTypeID                  : 'withdrawal_type_id',
	wdNumber                  : 'withdrawal_number',
	wdPlacementTime           : 'withdrawal_placement_time',
	wdSource                  : 'withdrawal_source',
	wdAmount                  : 'withdrawal_amount',
	wdCurrencyID              : 'withdrawal_currency_id',
	wdStatusID                : 'withdrawal_status_id',
	wdSourceAccount           : 'withdrawal_source_account',
	wdPaymentMethodEligibility: 'withdrawal_payment_method_eligibility',
	wdDecision                : 'withdrawal_decision',
	wdDecisionReasonUser      : 'withdrawal_decision_reason',
	wdDecisionReasonAdmin     : 'admin_reason',
	wdDecisionTime            : 'withdrawal_decision_time',
	wdDecisionTimeFrom        : 'withdrawal_decision_time_from',
	wdDecisionTimeTo          : 'withdrawal_decision_time_to',
	wdDecisionSource          : 'withdrawal_decision_source',
	wdIP                      : 'withdrawal_ip',
	wdIPCountry               : 'withdrawal_ip_country',
	// base filter
	//userID                   : 'user_id',
	userCountryIDs            : 'user_country_id',
	userGroupIDs              : 'user_group_id',
	userStatusIDs             : 'user_status_id',
	userBalanceBeforeFrom     : 'user_balance_before_from',
	userBalanceBeforeTo       : 'user_balance_before_to',
	userBalanceAfterFrom      : 'user_balance_after_from',
	userBalanceAfterTo        : 'user_balance_after_to',
	//userName                 : 'user_name',
	// filter
	//wdSource                 : 'withdrawal_source',
	wdWebsiteIDs              : 'withdrawal_website_id',
	wdChannelIDs              : 'withdrawal_channel_id',
	wdCurrencyIDs             : 'withdrawal_currency_ids',
	wdStatusIDs               : 'withdrawal_status_ids',
	wdNumberFrom              : 'withdrawal_number_from',
	wdNumberTo                : 'withdrawal_number_to',
	wdPlacementTimeFrom       : 'withdrawal_placement_time_from',
	wdPlacementTimeTo         : 'withdrawal_placement_time_to',
	wdAmountFrom              : 'withdrawal_amount_from',
	wdAmountTo                : 'withdrawal_amount_to',
	//wdSourceAccount          : 'withdrawal_source_account',
	//wdIP                     : 'withdrawal_ip',

	code       : 'code',
	wdAmountUSD: 'withdrawal_amount_usd',
	wdMessage  : 'withdrawal_message',

	affiliateUser     : 'affiliate_user',
	affiliateReference: 'affiliate_reference',
};

const listAdapter  = createListDataAdapter();
const totalAdapter = createTotalDataAdapter();
const wdComparator = createWithdrawalComparator();

export function getListParams(filter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isID, 'wdID', fields.wdID);
	builder.addField(rules.isID, 'userID', fields.userID);
	builder.addField(rules.isID, 'wdDecisionSource', fields.wdDecisionSource);

	builder.addField(rules.isArrayID, 'userCountryIDs', fields.userCountryIDs);
	builder.addField(rules.isArrayID, 'userGroupIDs', fields.userGroupIDs);
	builder.addField(rules.isArrayID, 'userStatusIDs', fields.userStatusIDs);
	builder.addField(rules.isArrayID, 'wdWebsiteIDs', fields.wdWebsiteIDs);
	builder.addField(rules.isArrayID, 'wdChannelIDs', fields.wdChannelIDs);
	builder.addField(rules.isArrayID, 'wdCurrencyIDs', fields.wdCurrencyIDs);
	builder.addField(rules.isArrayID, 'wdStatusIDs', fields.wdStatusIDs);
	builder.addField(rules.isArrayID, 'wdTypeIDs', fields.wdTypeID);

	builder.addField(rules.isString, 'wdCode', fields.wdCode);
	builder.addField(rules.isString, 'userName', fields.userName);
	builder.addField(rules.isString, 'wdSourceAccount', fields.wdSourceAccount);
	builder.addField(rules.isString, 'wdIP', fields.wdIP);
	builder.addField(rules.isString, 'wdMessage', fields.wdMessage);
	builder.addField(rules.isString, 'affiliateReference', fields.affiliateReference);

	builder.addField(rules.isArrayString, 'wdSource', fields.wdSource);

	builder.addField(rules.isArrayString, 'userCountryIDs', fields.userCountryID);
	builder.addField(rules.isArrayString, 'wdIPCountry', fields.wdIPCountry);
	builder.addField(rules.isString, 'wdDecision', fields.wdDecision);
	builder.addField(rules.isString, 'wdDecisionReasonUser', fields.wdDecisionReasonUser);
	builder.addField(rules.isString, 'wdDecisionReasonAdmin', fields.wdDecisionReasonAdmin);

	builder.addField(rules.isBoolean, 'affiliateUser', fields.affiliateUser);

	builder.addRangeField(rules.isNumberRange, filter.userBalanceBefore, [
		fields.userBalanceBeforeFrom,
		fields.userBalanceBeforeTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.userBalanceAfter, [
		fields.userBalanceAfterFrom,
		fields.userBalanceAfterTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.wdNumber, [
		fields.wdNumberFrom,
		fields.wdNumberTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.wdAmount, [
		fields.wdAmountFrom,
		fields.wdAmountTo,
	]);

	builder.addRangeField(rules.isDateTimeRange, filter.wdPlacementTime, [
		fields.wdPlacementTimeFrom,
		fields.wdPlacementTimeTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.wdDecisionTime, [
		fields.wdDecisionTimeFrom,
		fields.wdDecisionTimeTo,
	]);
	return builder.biuldParams(filter);
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptWithdrawalList(rawData = {}) {

	const adaptedData      = rawData.data ? listAdapter.adaptList(rawData.data) : [];
	const withdrawalTotals = rawData.total ? totalAdapter.adapt(rawData.total)   : totalAdapter.adapt({});

	const tempData = {
		withdrawals: adaptedData,
	};

	const withdrawal = new schema.Entity('withdrawals', {}, { idAttribute: 'wdID' });
	const withdrawalSchema = { withdrawals: [withdrawal] };

	const normalizedData = normalize(tempData, withdrawalSchema);

	const withdrawalIDs = normalizedData.result.withdrawals || [];
	const entities = normalizedData.entities.withdrawals || {};

	return {
		withdrawalIDs,
		entities,
		withdrawalTotals,
	};
}

export function adaptWithdrawal(rawData = {}) {

	return listAdapter.adapt(rawData);
}

// Compare ----------------------------------------------------------------------------------------

export function compareWithdrawalWithFilter(withdrawal, filter) {
	if (!withdrawal) {
		return false;
	}
	return wdComparator.compare(withdrawal, filter);
}

// Adapters ---------------------------------------------------------------------------------------

function createListDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'wdID', fields.wdID);
	adapter.addField(rules.id, 'wdTypeID', fields.wdTypeID);
	adapter.addField(rules.id, 'userGroupID', fields.userGroupID);
	adapter.addField(rules.id, 'userStatusID', fields.userStatusID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'wdCurrencyID', fields.wdCurrencyID);
	adapter.addField(rules.id, 'wdStatusID', fields.wdStatusID);

	adapter.addField(rules.number, 'userBalanceBefore', fields.userBalanceBefore);
	adapter.addField(rules.number, 'userBalanceAfter', fields.userBalanceAfter);
	adapter.addField(rules.number, 'wdNumber', fields.wdNumber);
	adapter.addField(rules.number, 'wdSource', fields.wdSource);
	adapter.addField(rules.number, 'wdAmount', fields.wdAmount);
	adapter.addField(rules.number, 'amount', fields.wdAmount);
	adapter.addField(rules.number, 'wdDecisionSource', fields.wdDecisionSource);
	adapter.addField(rules.number, 'userBalanceBeforeUSD', fields.userBalanceBeforeUSD);
	adapter.addField(rules.number, 'userBalanceAfterUSD', fields.userBalanceAfterUSD);
	adapter.addField(rules.number, 'wdAmountUSD', fields.wdAmountUSD);

	adapter.addField(rules.string, 'userName', fields.userName);
	adapter.addField(rules.string, 'wdCode', fields.wdCode);
	adapter.addField(rules.string, 'userCountryID', fields.userCountryID);
	adapter.addField(rules.string, 'wdSourceAccount', fields.wdSourceAccount);
	adapter.addField(rules.string, 'wdPaymentMethodEligibility', fields.wdPaymentMethodEligibility);
	adapter.addField(rules.string, 'wdDecision', fields.wdDecision);
	adapter.addField(rules.string, 'wdDecisionReasonUser', fields.wdDecisionReasonUser);
	adapter.addField(rules.string, 'wdDecisionReasonAdmin', fields.wdDecisionReasonAdmin);
	adapter.addField(rules.string, 'wdIP', fields.wdIP);
	adapter.addField(rules.string, 'wdIPCountry', fields.wdIPCountry);
	adapter.addField(rules.string, 'code', fields.code);
	adapter.addField(rules.string, 'wdMessage', fields.wdMessage);
	adapter.addField(rules.string, 'affiliateReference', fields.affiliateReference);

	adapter.addField(rules.fullDate, 'wdPlacementTime', fields.wdPlacementTime);
	adapter.addField(rules.fullDate, 'wdDecisionTime', fields.wdDecisionTime);

	adapter.addField(rules.bool, 'affiliateUser', fields.affiliateUser);

	return adapter;
}

function createTotalDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'wdAmount', fields.wdAmount);
	adapter.addField(rules.number, 'wdAmountUSD', fields.wdAmountUSD);

	return adapter;
}

function createWithdrawalComparator() {

	const comparator = new FilterComparator();
	const rules = comparator.RULES;

	comparator.addField(rules.isEqual, 'wdID', 'wdID');
	comparator.addField(rules.isEqual, 'wdCode', 'wdCode');
	comparator.addField(rules.isEqual, 'userID', 'userID');
	comparator.addField(rules.isEqual, 'userName', 'userName');
	comparator.addField(rules.isEqual, 'wdIP', 'wdIP');
	comparator.addField(rules.isEqual, 'wdSource', 'wdSource');
	comparator.addField(rules.isEqual, 'wdSourceAccount', 'wdSourceAccount');
	comparator.addField(rules.isEqual, 'wdDecisionReason', 'wdDecisionReason');

	comparator.addField(rules.inArrayID, 'websiteID', 'wdWebsiteIDs');
	comparator.addField(rules.inArrayID, 'channelID', 'wdChannelIDs');
	comparator.addField(rules.inArrayID, 'wdTypeID', 'wdTypeIDs');
	comparator.addField(rules.inArrayID, 'userGroupID', 'userGroupIDs');
	comparator.addField(rules.inArrayID, 'userStatusID', 'userStatusIDs');
	comparator.addField(rules.inArrayID, 'wdStatusID', 'wdStatusIDs');
	comparator.addField(rules.inArrayID, 'wdCurrencyID', 'wdCurrencyIDs');
	comparator.addField(rules.inArrayID, 'wdDecision', 'wdDecision');
	comparator.addField(rules.inArrayID, 'wdPaymentMethodEligibility', 'wdPaymentMethodEligibility');

	comparator.addField(rules.inArrayString, 'userCountryID', 'userCountryIDs');
	comparator.addField(rules.inArrayString, 'wdIPCountry', 'wdIPCountry');

	comparator.addField(rules.inNumberRange, 'wdNumber', 'wdNumber');
	comparator.addField(rules.inNumberRange, 'wdAmount', 'wdAmount');
	comparator.addField(rules.inNumberRange, 'wdDecisionSource', 'wdDecisionSource');
	comparator.addField(rules.inNumberRange, 'userBalanceBefore', 'userBalanceBefore');
	comparator.addField(rules.inNumberRange, 'userBalanceAfter', 'userBalanceAfter');

	comparator.addField(rules.inDateRange, 'wdPlacementTime', 'wdPlacementTime');
	comparator.addField(rules.inDateRange, 'wdDecisionTime', 'wdDecisionTime');

	return comparator;
}
