const actions = {

	CASINO_GAME_DATA_RESET: 'CASINO_GAME_DATA_RESET',

	CASINO_GAME_BASE_DATA_RELOAD : 'CASINO_GAME_BASE_DATA_RELOAD',
	CASINO_GAME_BASE_DATA_REFRESH: 'CASINO_GAME_BASE_DATA_REFRESH',

	CASINO_GAME_NAMES_DATA_RELOAD : 'CASINO_GAME_NAMES_DATA_RELOAD',
	CASINO_GAME_NAMES_DATA_REFRESH: 'CASINO_GAME_NAMES_DATA_REFRESH',

	CASINO_GAME_SEO_DATA_RELOAD	: 'CASINO_GAME_SEO_DATA_RELOAD',
	CASINO_GAME_SEO_DATA_SAVE	  : 'CASINO_GAME_SEO_DATA_SAVE',
	CASINO_GAME_SEO_DATA_REFRESH: 'CASINO_GAME_SEO_DATA_REFRESH',

	CASINO_GAME_SAVE        : 'CASINO_GAME_SAVE',
	CASINO_GAME_UI_REFRESH  : 'CASINO_GAME_UI_REFRESH',
	CASINO_GAME_TAGS_RELOAD : 'CASINO_GAME_TAGS_RELOAD',
	CASINO_GAME_TAGS_REFRASH: 'CASINO_GAME_TAGS_REFRASH',

	CASINO_GAME_TAGS_SET    : 'CASINO_GAME_TAGS_SET',
	CASINO_GAME_TAGS_SUCCESS: 'CASINO_GAME_TAGS_SUCCESS',

	dataReset: () => ({
		type: actions.CASINO_GAME_DATA_RESET,
	}),

	casinoGameTagsReload: casinoGameID => ({
		type: actions.CASINO_GAME_TAGS_RELOAD,
		data: {
			casinoGameID,
		},
	}),

	baseDataReload: casinoGameID => ({
		type: actions.CASINO_GAME_BASE_DATA_RELOAD,
		data: {
			casinoGameID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.CASINO_GAME_BASE_DATA_REFRESH,
		data,
	}),

	namesDataReload: casinoGameID => ({
		type: actions.CASINO_GAME_NAMES_DATA_RELOAD,
		data: {
			casinoGameID,
		},
	}),
	namesDataRefresh: data => ({
		type: actions.CASINO_GAME_NAMES_DATA_REFRESH,
		data,
	}),

	seoDataReload: casinoGameID => ({
		type: actions.CASINO_GAME_SEO_DATA_RELOAD,
		data: {
			casinoGameID,
		},
	}),

	seoDataSave: casinoGameID => ({
		type: actions.CASINO_GAME_SEO_DATA_SAVE,
		data: {
			casinoGameID,
		},
	}),

	seoDataRefresh: data => ({
		type: actions.CASINO_GAME_SEO_DATA_REFRESH,
		data,
	}),

	casinoGameSave: () => ({
		type: actions.CASINO_GAME_SAVE,
	}),
	uiRefresh: data => ({
		type: actions.CASINO_GAME_UI_REFRESH,
		data,
	}),

	fetchGameTags: data => ({
		type: actions.CASINO_GAME_TAGS_SET,
		data,
	}),


};

export default actions;
