import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';
import confirmActions from '../confirmCancel/actions';

import { marketsAPI } from '../../../../helpers/api/markets';
import notifications from '../../../../helpers/notifications';

import { getListParams, adaptMarketList } from './utils';
import { getUserBonusListParams } from '../../../loyalty/bonuses/userBonus/utils';

const prefix = 'sport.market.list';

const messages = {
	errorLoadMarketList           : `${prefix}.errorLoadMarketList`,
	errorUpdateMarketDisplayStatus: `${prefix}.errorUpdateMarketDisplayStatus`,
	errorUpdateMarketArgument     : `${prefix}.errorUpdateMarketArgument`,
	errorUpdateMarketStatus       : `${prefix}.errorUpdateMarketStatus`,
	errorCancelMarket             : `${prefix}.errorCancelMarket`,
	errorRestoreMarket            : `${prefix}.errorRestoreMarket`,
	errorCheckCancel              : `${prefix}.errorCheckCancel`,
	successMarketDisplayStatus    : `${prefix}.successMarketDisplayStatus`,
	successArgument               : `${prefix}.successArgument`,
	successMarketStatus           : `${prefix}.successMarketStatus`,
	successMarketCancel           : `${prefix}.successMarketCancel`,
	successMarketRestore          : `${prefix}.successMarketRestore`,
};

function getStoreData({ Sport: { Markets } }) {
	return {
		filter: Markets.Filter.get('filter'),
		list  : Markets.List.get('list'),
	};
}

function* listReload() {
	const takeAction = actions.MARKETS_LIST_RELOAD;

	yield takeEvery(takeAction, function* ({ payload }) {
		yield put(actions.uiRefresh({ loading: true }));
		const storeData = yield select(getStoreData);
		const { filter } = storeData;
		const params = payload ? getUserBonusListParams(payload) : getListParams(filter);
		try {
			const response = yield call(marketsAPI.marketList, params);
			if (response && response.status === 200) {
				let list = adaptMarketList(response.data.data);
				list = list.sort((a, b) => (a.name.trim()).localeCompare(b.name.trim()));
				yield put(actions.listRefresh(list));
			}
		} catch (error) {
			notifications.showError(messages.errorLoadMarketList, error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* toggleDisplayStatus() {
	const takeAction = actions.MARKETS_LIST_MARKET_TOGGLE_DISPLAY_STATUS;

	yield takeEvery(takeAction, function* (action) {
		const { marketID, displayStatusID } = action.data;
		const params = {
			id               : marketID,
			display_status_id: displayStatusID,
		};
		try {
			const response = yield call(marketsAPI.marketUpdate, params);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successMarketDisplayStatus);
				yield put(actions.listReload());
			}
		} catch (error) {
			notifications.showError(messages.errorUpdateMarketDisplayStatus, error);
			console.log(error);
		}
	});
}

function* toggleArgument() {
	const takeAction = actions.MARKETS_LIST_MARKET_TOGGLE_ARGUMENT;

	yield takeEvery(takeAction, function* (action) {
		const storeData = yield select(getStoreData);
		const { list } = storeData;
		const { marketID, showArgument } = action.data;
		const listItem = list.filter(item => item.id === marketID);
		listItem[0].showArgument = showArgument;
		const params = {
			id           : marketID,
			show_argument: showArgument,
		};

		try {
			const res = yield call(marketsAPI.marketUpdate, params);
			if (res && res.status === 200) {
				yield put(actions.listReload());
				notifications.showSuccess(messages.successArgument);
			}
		} catch (error) {
			notifications.showError(messages.errorUpdateMarketArgument);
		}
	});
}

function* changeStatusID() {
	const takeAction = actions.MARKETS_LIST_MARKET_CHANGE_STATUS_ID;

	yield takeEvery(takeAction, function* (action) {
		const { marketID, statusID } = action.data;
		const params = {
			id       : marketID,
			status_id: statusID,
		};
		try {
			const response = yield call(marketsAPI.marketUpdate, params);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successMarketStatus);
				yield put(actions.listReload());
			}
		} catch (error) {
			notifications.showError(messages.errorUpdateMarketStatus, error);
			console.log(error);
		}
	});
}

function* cancelCheckMarket() {
	const takeAction = actions.MARKETS_LIST_MARKET_CANCEL_CHECK;

	yield takeEvery(takeAction, function* (action) {
		const { marketID, marketName } = action.data;
		try {
			const response = yield call(marketsAPI.marketCancelCheck, marketID);
			if (response && response.status === 200) {
				const result = response.data.data;
				const { canceled, reason } = result;
				if (!canceled) {
					const modalUI = {
						visible: true,
						marketID,
						marketName,
						info   : reason,
					};
					yield put(confirmActions.modalUIRefresh(modalUI));
					return;
				}
				notifications.showSuccess(messages.successMarketCancel);

			}
		} catch (error) {
			notifications.showError(messages.errorCheckCancel, error);
			console.log(error);
		}

		yield put(actions.listReload());
	});
}

function* cancelMarket() {
	const takeAction = actions.MARKETS_LIST_MARKET_CANCEL;

	yield takeEvery(takeAction, function* (action) {
		yield put(confirmActions.setModalValueUI('loading', true));

		const { marketID } = action.data;
		try {
			const response = yield call(marketsAPI.marketCancel, marketID);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successMarketCancel);
			}
		} catch (error) {
			notifications.showError(messages.errorCancelMarket, error);
			console.log(error);
		}

		yield put(actions.listReload());
		yield put(confirmActions.dataReset());
	});
}

function* restoreMarket() {
	const takeAction = actions.MARKETS_LIST_MARKET_RESTORE;

	yield takeEvery(takeAction, function* (action) {
		const { marketID } = action.data;
		try {
			const response = yield call(marketsAPI.marketRestore, marketID);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successMarketRestore);
			}
		} catch (error) {
			notifications.showError(messages.errorRestoreMarket, error);
			console.log(error);
		}

		yield put(actions.listReload());
	});
}

export default function* marketsListSaga() {
	yield all([
		fork(listReload),
		fork(toggleDisplayStatus),
		fork(toggleArgument),
		fork(cancelCheckMarket),
		fork(cancelMarket),
		fork(restoreMarket),
		fork(changeStatusID),
	]);
}
