import { normalize, schema } from 'normalizr';
import { isArray } from 'lodash';

import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';

export const fields = {
	id                : 'id',                        // number;
	userID            : 'user_id',                   // number;
	cashBackID        : 'cash_back_id',              // number;
	periodic          : 'periodic',                  // boolean;
	amount            : 'amount',                    // number;
	amountUSD         : 'amount_usd',                // number;
	totalStake        : 'total_stake',               // number;
	totalStakeUSD     : 'total_stake_usd',           // number;
	totalWonAmount    : 'total_won_amount',          // number;
	totalWonAmountUSD : 'total_won_amount_usd',      // number;
	settlementDate    : 'settlement_date',           // Date;
	ggrPercent        : 'ggr_percent',               // number;
	settlementStatusID: 'settlement_status_id',      // number;
	currencyID        : 'currency_id',               // number;
	date              : 'date',                      // Date;

	//filter
	dateFrom             : 'date_from',                 // Date;
	dateTo               : 'date_to',                   // Date;
	settlementDateFrom   : 'settlement_date_from',      // Date;
	settlementDateTo     : 'settlement_date_to',        // Date;
	ggrPercentFrom       : 'ggr_percent_from',          // number;
	ggrPercentTo         : 'ggr_percent_to',            // number;
	amountFrom           : 'amount_from',               // number;
	amountTo             : 'amount_to',                 // number;
	amountUSDFrom        : 'amount_usd_from',           // number;
	amountUSDTo          : 'amount_usd_to',             // number;
	totalStakeFrom       : 'total_stake_from',          // number;
	totalStakeTo         : 'total_stake_to',            // number;
	totalStakeUSDFrom    : 'total_stake_usd_from',      // number;
	totalStakeUSDTo      : 'total_stake_usd_to',        // number;
	totalWonAmountFrom   : 'total_won_amount_from',     // number;
	totalWonAmountTo     : 'total_won_amount_to',       // number;
	totalWonAmountUSDFrom: 'total_won_amount_usd_from', // number;
	totalWonAmountUSDTo  : 'total_won_amount_usd_to',   // number;
};

const listAdapter = createListAdapter();
const dataAdapter = createDataAdapter();

export function getListParams(filter, sorting, pagination, websiteID) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);
	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);
	builder.addValue('website_id', websiteID);

	builder.addField(rules.isID, 'id', fields.id);
	builder.addField(rules.isID, 'userID', fields.userID);
	builder.addField(rules.isID, 'cashBackID', fields.cashBackID);
	builder.addField(rules.isID, 'settlementStatusID', fields.settlementStatusID);
	builder.addField(rules.isID, 'currencyID', fields.currencyID);

	builder.addField(rules.isBoolean, 'periodic', fields.periodic);

	builder.addRangeField(rules.isDateTimeRange, filter.date, [
		fields.dateFrom,
		fields.dateTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.settlementDate, [
		fields.settlementDateFrom,
		fields.settlementDateTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.ggrPercent, [
		fields.ggrPercentFrom,
		fields.ggrPercentTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.amount, [
		fields.amountFrom,
		fields.amountTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.amountUSD, [
		fields.amountUSDFrom,
		fields.amountUSDTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.totalStake, [
		fields.totalStakeFrom,
		fields.totalStakeTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.totalStakeUSD, [
		fields.totalStakeUSDFrom,
		fields.totalStakeUSDTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.totalWonAmount, [
		fields.totalWonAmountFrom,
		fields.totalWonAmountTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.totalWonAmountUSD, [
		fields.totalWonAmountUSDFrom,
		fields.totalWonAmountUSDTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------
export function adaptList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	const tempData = {
		items: adaptedData,
	};

	const item           = new schema.Entity('items', {}, { idAttribute: 'id' });
	const itemsSchema    = { items: [item] };
	const normalizedData = normalize(tempData, itemsSchema);
	const entities       = normalizedData.entities.items || {};

	return entities;
}

// Prepare ----------------------------------------------------------------------------------------
export function prepareBonusData(rawData = {}) {

	listAdapter.clearExcludes();

	const preparedData = dataAdapter.prepare(rawData);

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);

	adapter.addField(rules.intOrNull, 'userID', fields.userID);
	adapter.addField(rules.intOrNull, 'cashBackID', fields.cashBackID);
	adapter.addField(rules.intOrNull, 'currencyID', fields.currencyID);
	adapter.addField(rules.intOrNull, 'settlementStatusID', fields.settlementStatusID);

	adapter.addField(rules.bool, 'periodic', fields.periodic);

	adapter.addField(rules.numberOrNull, 'ggrPercent', fields.ggrPercent);
	adapter.addField(rules.number, 'amount', fields.amount);
	adapter.addField(rules.number, 'amountUSD', fields.amountUSD);
	adapter.addField(rules.number, 'totalStake', fields.totalStake);
	adapter.addField(rules.number, 'totalStakeUSD', fields.totalStakeUSD);
	adapter.addField(rules.number, 'totalWonAmount', fields.totalWonAmount);
	adapter.addField(rules.number, 'totalWonAmountUSD', fields.totalWonAmountUSD);

	adapter.addField(rules.fullDate, 'date', fields.date);
	adapter.addField(rules.fullDate, 'settlementDate', fields.settlementDate);

	return adapter;
}

export function createDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.intOrNull, 'settlementStatusID', fields.settlementStatusID);
	adapter.addField(rules.intOrNull, 'websiteID', 'website_id');

	return adapter;
}
