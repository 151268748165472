import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import listActions from '../list/actions';

import { promotionsAPI } from '../../../../../helpers/api/promotions';
import notifications from '../../../../../helpers/notifications';
import { PROMOTION_STATUS } from '../../../../../helpers/commonConstants';

import {
	adaptPromotion,
	preparePromotion,
	adaptRevisionsList,
} from './utils';

const prefix = 'promotions.promotion';

const messages = {
	errorPromotionReload: `${prefix}.errorPromotionReload`,
	errorPromotionSave  : `${prefix}.errorPromotionSave`,
	errorRevisionsLoad  : `${prefix}.errorRevisionsLoad`,
	errorRevisionSave   : `${prefix}.errorRevisionSave`,
	errorRevisionApply  : `${prefix}.errorRevisionApply`,
	successPromotionSave: `${prefix}.successPromotionSave`,
	successRevisionSave : `${prefix}.successRevisionSave`,
	successRevisionApply: `${prefix}.successRevisionApply`,
};

function getStoreData({ CMS: { Pages }, App }) {
	const { Promotions } = Pages;
	return {
		listIDs          : Promotions.List.get('listIDs'),
		entities         : Promotions.List.get('entities'),
		baseData         : Promotions.Promotion.get('baseData'),
		UI               : Promotions.Promotion.get('UI'),
		revisionsEntities: Promotions.Promotion.get('revisionsEntities'),
		websiteID        : App.get('websiteID'),
	};
}

function* baseDataReload() {

	yield takeEvery(actions.PROMOTION_BASE_DATA_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const storeData = yield select(getStoreData);
		const { UI, websiteID } = storeData;
		const { promotionID } = action.data;
		const params = { lang_id: UI.langID, website_id: websiteID, channel_id: UI.channelID };
		let baseData = adaptPromotion({});
		try {
			const res = yield call(promotionsAPI.promotionInfo, promotionID, params);
			if (res && res.status === 200) {
				baseData = adaptPromotion(res.data.data);
				if (!baseData.statusID) {
					baseData.statusID = PROMOTION_STATUS.inactive;
				}

				baseData.gameIDs = baseData.gameIDs.map(item => item.id || item);
			}
		} catch (error) {
			notifications.showError(messages.errorPromotionReload, error);
			console.log(error);
		}

		yield put(actions.baseDataRefresh(baseData));
		// yield put(actions.uiRefresh({
		//   promotionID        : baseData.id,
		//   promotionName      : baseData.name,
		//   editMode      : true,
		//   loading       : false,
		// }));
		if (baseData.id) {
			yield put(actions.uiRefresh({
				promotionID  : baseData.id,
				promotionName: baseData.name,
				editMode     : true,
				loading      : false,
			}));
		}
	});
}

function* promotionSave() {

	yield takeEvery(actions.PROMOTION_SAVE, function* (action) {

		const { baseData, UI, websiteID } = yield select(getStoreData);
		yield put(actions.uiRefresh({ loading: true }));
		baseData.websiteID = websiteID;

		let { promotionID } = action.data;

		const { editMode, langID, channelID } = UI;
		let isError = false;

		const preparedData = preparePromotion(baseData, langID, channelID);

		try {
			if (editMode) {
				const res = yield call(
					promotionsAPI.promotionUpdate,
					promotionID,
					preparedData
				);
				if (res && res.status === 200) {
					const baseData = adaptPromotion(res.data.data);
					yield put(actions.baseDataRefresh(baseData));
					notifications.showSuccess(messages.successPromotionSave);
				}
			} else {
				const res = yield call(promotionsAPI.promotionAdd, preparedData);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successPromotionSave);
					promotionID = toInteger(res.data.data.id);
					yield put(actions.dataReset());
				}
			}
		} catch (error) {
			isError = true;
			notifications.showError(messages.errorPromotionSave, error);
		}

		yield put(actions.uiRefresh({ loading: false }));
		if (!isError) {
			yield put(actions.revisionsListReload(promotionID));
			yield put(listActions.listReload());
		}
	});
}

function* revisionsListReload() {

	yield takeEvery(actions.PROMOTIONS_REVISIONS_LIST_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { promotionID } = action.data;
		const { UI } = yield select(getStoreData);

		let revisionsEntities = {};
		let activeRevisionID = null;
		const params = { lang_id: UI.langID };

		try {
			const res = yield call(promotionsAPI.promotionRevisionsList, promotionID, params);
			if (res && res.status === 200) {
				const result = adaptRevisionsList(res.data.data);
				revisionsEntities = result.revisionsEntities; // eslint-disable-line prefer-destructuring
				activeRevisionID  = result.activeRevisionID;  // eslint-disable-line prefer-destructuring
			}
		} catch (error) {
			notifications.showError(messages.errorRevisionsLoad, error);
			console.error(error);
		}

		yield put(actions.revisionsListRefresh(revisionsEntities));
		yield put(actions.uiRefresh({ revisionID: activeRevisionID, loading: false }));
	});
}

function* revisionApply() {
	yield takeEvery(actions.PROMOTIONS_REVISION_APPLY, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { promotionID, revisionID } = action.data;

		try {
			const res = yield call(
				promotionsAPI.promotionRevisionApply,
				promotionID,
				revisionID
			);
			if (res && res.status === 200) {
				yield put(actions.revisionsListReload(promotionID));
				yield put(actions.baseDataReload(promotionID));

				notifications.showSuccess(messages.successRevisionApply);
			}
		} catch (error) {
			notifications.showError(messages.errorRevisionApply, error);
			console.error(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

export default function* promotionSaga() {
	yield all([
		fork(promotionSave),
		fork(baseDataReload),
		fork(revisionsListReload),
		fork(revisionApply),
	]);
}
