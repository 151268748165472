import { Spin } from 'antd';
import React from 'react';
import { SpinnerContainer } from '../Tournament.style';

const Spinner = (props) => {
	const { size = 'small' } = props;
	return (
		<SpinnerContainer>
			<Spin size={size} />
		</SpinnerContainer>
	);
};

export default Spinner;
