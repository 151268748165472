import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	overallData: {},

});

export default function dashboardPlayersReducer(state = initState, action) {

	switch (action.type) {

	case actions.DASHBOARD_PLAYERS_OVERALL_DATA_REFRESH: {
		const target = state.get('overallData');
		const result = fill(action.data, target);
		return state.set('overallData', result);
	}

	default:
		return state;
	}
}
