import { combineReducers } from 'redux';

import List from './list/reducer';
import Payment from './payment/reducer';
import Payways from './payways/reducer';
import Payway from './payway/reducer';

export default combineReducers({
	List,
	Payment,
	Payways,
	Payway,
});
