import { all, takeEvery, put, fork, call, select, takeLatest } from 'redux-saga/effects';
import { getHeadersTotalCount } from '../../../helpers/utils';
import { deriveTablePagination } from '../../../selectors/tables';
import { gameToPage } from '../gamesBulk/utils';
import actions from './actions';
import tableActions from '../../tables/actions';
import bulkActions from '../gamesBulk/actions';

import { casinoAPI } from '../../../helpers/api/casino';
import notifications from '../../../helpers/notifications';

import { adaptGamesList, getListParams } from './utils';

import { TABLE_TYPES } from '../../../constants/tableTypes';

const tableType   = TABLE_TYPES.game;

const messages = {
	errorListLoad             : 'casinoGames.error.list.load',
	errorListRefresh          : 'casinoGames.error.list.refresh',
	errorWebsiteCasinoListLoad: 'casinoGames.error.websiteCasinoList.load',
	errorGamesListUpdate      : 'casinoGames.error.gamesList.update',
	successListRefresh        : 'casinoGames.success.list.refresh',
	successGamesListUpdate    : 'casinoGames.success.gamesList.update',
};

function getStoreData(state) {
	const { Casino, App, Users, Tables } = state;

	return {
		gamesList  : Casino.Games.get('gamesList'),
		defaultGrid: Casino.Games.get('defaultGrid'),

		isGamesChanged      : Casino.Games.get('UI').isGamesChanged,
		isDefaultGridChanged: Casino.Games.get('UI').isDefaultGridChanged,
		isGamesGridChanged  : Casino.Games.get('UI').isGamesGridChanged,
		websiteID           : App.get('websiteID'),
		casinoID            : Casino.Games.get('UI').casinoID,
		channelID           : Casino.Games.get('UI').channelID,
		providerID          : Casino.Games.get('UI').providerID,
		customProviderID    : Casino.Games.get('UI').customProviderID,
		currencyID          : Users.User.get('baseData').currencyID,
		bulkIDs             : Casino.CasinoGameBulk.get('baseData').bulkGameIDs,

		filter    : Casino.Games.get('filter'),
		sorting   : Tables.get(tableType).sorting,
		pagination: deriveTablePagination(tableType, state),
	};
}

function* gamesListReload() {

	yield takeLatest(actions.CASINO_GAMES_LIST_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { currencyID } = yield select(getStoreData);

		const { websiteID, casinoID, channelID, byCategory, name, providerType, page, limit, isUnlimit, byCurrency, sorting = {} } = action.data;
		let params;
		if (providerType === 'provider') {
			params = {
				unlimit    : isUnlimit,
				channel_id : channelID,
				provider_id: casinoID,
				categories : byCategory,
				name,
				...sorting,
			};
		} else {
			params = {
				unlimit           : isUnlimit,
				channel_id        : channelID,
				custom_provider_id: casinoID,
				name,
				currency_id       : byCurrency && currencyID,
				...sorting,
			};
		}

		params.page = page;
		params.limit = limit;

		let gamesList = [];
		let totalCount;
		try {
			// games
			const res = yield call(casinoAPI.gamesList, websiteID, params);
			if (res && res.status === 200) {
				const { adaptedData } = adaptGamesList(res.data.data);
				gamesList             = adaptedData;
				totalCount            = getHeadersTotalCount(res.headers);

				yield put(actions.gamesListRefresh(gamesList));
				yield put(actions.gamesListModalRefresh(gamesList));

			}
			yield put(actions.setHasMore(gamesList.length > 0));
			yield put(actions.uiRefresh({ isGamesChanged: false }));
			yield put(tableActions.paginationRefresh(tableType, { currentPage: page, totalCount, itemsPerPage: limit }));

		} catch (error) {
			notifications.showError(messages.errorListLoad);
			console.log(error);
		}
		// yield put(currenciesActions.websiteCurrencyListReload);
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* casinoGamesListReload() {
	yield takeEvery(actions.CASINO_GAMES_LIST_RELOAD_GAMES, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const { filter, sorting, pagination, customProviderID, providerID, currencyID, bulkIDs, channelID } = yield select(getStoreData);
		const data = typeof action.data !== 'object' ? {} : action.data;
		const {
			byCategory,
			name,
			providerType = 'provider',
			page,
			limit,
			isUnlimit,
			byCurrency,
		} = yield data;
		const params = getListParams(filter, sorting, pagination);
		let queryParams = { ...params };
		if (providerType === 'provider') {
			queryParams = {
				unlimit           : isUnlimit,
				channel_id        : channelID,
				provider_id       : providerID,
				custom_provider_id: customProviderID,
				categories        : byCategory,
				name,
				...queryParams,
			};
		} else {
			queryParams = {
				unlimit           : isUnlimit,
				channel_id        : channelID,
				provider_id       : providerID,
				custom_provider_id: customProviderID,
				name,
				currency_id       : byCurrency && currencyID,
				...queryParams,
			};
		}

		params.page = page;
		params.limit = limit;

		let gamesList = [];
		try {
			const res = yield call(casinoAPI.casinoGamesList,  queryParams);
			if (res && res.status === 200) {
				const { adaptedData, entities } = adaptGamesList(res.data.data);
				gamesList = adaptedData;
				const totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.gamesEntitiesRefresh(entities));
				const pageData = gameToPage(bulkIDs, entities);
				yield put(bulkActions.currentPageIDsRefresh(pageData));
				yield put(tableActions.paginationRefresh(tableType, { totalCount }));
				yield put(actions.setHasMore(gamesList.length > 0));
				yield put(actions.uiRefresh({ isGamesChanged: false }));
			}

		} catch (e) {
			notifications.showError(messages.errorListLoad, e);
			console.log(e);
		}

		yield put(actions.gamesListRefresh(gamesList));
		yield put(actions.gamesListModalRefresh(gamesList));
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* gamesListRefreshFromAPI() {

	yield takeEvery(actions.CASINO_GAMES_LIST_REFRESH_FROM_API, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { casinoID } = action.data;
		const { websiteID, channelID } = yield select(getStoreData);
		try {
			const res = yield call(casinoAPI.gamesListRefresh, { casinoID, websiteID });
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successListRefresh);
				yield put(actions.casinoGameListReload({
					websiteID,
					casinoID,
					channelID,
				}));
			}
		} catch (error) {
			notifications.showError(messages.errorListRefresh);
			console.log(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

export default function* casinoGamesListSaga() {
	yield all([
		fork(gamesListReload),
		fork(casinoGamesListReload),
		fork(gamesListRefreshFromAPI),
	]);
}
