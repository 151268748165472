const prefix = 'USER_RESPONSIBLE_GAMBLING_';

const actions = {

	DATA_RELOAD : `${prefix}DATA_RELOAD`,
	DATA_REFRESH: `${prefix}DATA_REFRESH`,

	LIMITATION_REMOVE: `${prefix}LIMITATION_REMOVE`,


	dataReload: data => ({
		type: actions.DATA_RELOAD,
		data,
	}),

	dataRefresh: data => ({
		type: actions.DATA_REFRESH,
		data,
	}),

	removeLimitation: data => ({
		type: actions.LIMITATION_REMOVE,
		data,
	}),

};

export default actions;
