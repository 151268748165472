export const adaptTags = (tagsList = [], tagsLimits = [], data) => {
	const { userID, typeID } = data;
	const associativeList    = tagsList.reduce((acc, item) => {
		const { id, name } = item;
		acc[id]            = {
			loading      : false,
			isBaseChanged: false,
			block        : false,
			limitID      : null,
			maxStake     : null,
			minStake     : null,
			tagID        : id,
			limitTypeID  : typeID,
			name,
			userID,
		};
		return acc;
	}, {});

	tagsLimits.forEach(item => {
		const {
			service_id: tagID,
			max_stake : maxStake,
			min_stake : minStake,
			type      : limitTypeID,
			id        : limitID,
			block,
		} = item;

		if (!Object.hasOwn(associativeList, tagID)) {
			return;
		}

		const singleLimit           = {
			tagID,
			maxStake,
			minStake,
			block,
			limitTypeID,
			limitID,
		};
		associativeList[tagID] = Object.assign(associativeList[tagID], singleLimit);
	});

	return {
		list: Object.values(associativeList).map(item => item),
		associativeList,
	};

};
