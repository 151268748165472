import { Map, List } from 'immutable';
import actions from './actions';


const initState = new Map({

	// Data
	baseData     : {},
	selectedEvent: {
		id          : null,
		eventCountry: null,
		eventLeague : null,
		eventName   : null,
		sportName   : null,
	},

	eventStatistic   : {},
	marketsStatistics: [],

	commonData: {
		sport             : {},
		country           : {},
		league            : {},
		regulationTypeList: [],
	},

	oddsData: {
		marketList   : [],
		oddList      : [],
		providersList: [],
	},

	namesData: {
		langID: 1,
		names : [],
	},

	seoData: {
		langID  : 1,
		baseData: {},
	},


	ordersData       : [],
	settingsData     : {},
	dynamicLimitsData: [],

	// UI
	UI: {
		editMode              : true,
		loading               : false,
		isChangedBase         : false,
		isChangedNames        : false,
		isChangedSeo          : false,
		isChangedOrders       : false,
		isChangedSettings     : false,
		isChangedDynamicLimits: false,
		commonDataLoading     : false,
	},
});

export default function eventReducer(state = initState, action) {

	switch (action.type) {

	case actions.EVENT_BASE_DATA_REFRESH: {
		return state.set('baseData', action.data);
	}

	case actions.EVENT_COMMON_DATA_REFRESH: {
		return state.set('commonData', action.data);
	}

	case actions.EVENT_ODDS_DATA_REFRESH: {
		return state.set('oddsData', action.data);
	}

	case actions.EVENT_SETTINGS_DATA_REFRESH: {
		return state.set('settingsData', action.data);
	}

	case actions.EVENT_NAMES_DATA_REFRESH: {
		return state.set('namesData', action.data);
	}
	case actions.EVENT_SEO_DATA_REFRESH: {
		return state.set('seoData', action.data);
	}

	case actions.EVENT_ORDERS_DATA_REFRESH: {
		const data = new List(action.data).toArray();
		return state.set('ordersData', data);
	}

	case actions.EVENT_DYNAMIC_LIMITS_DATA_REFRESH: {
		const data = new List(action.data).toArray();
		return state.set('dynamicLimitsData', data);
	}

	case actions.EVENT_SET_VALUE_UI: {

		const { valueName, value } = action.data;
		const UI                   = new Map(state.get('UI')).toObject();
		UI[valueName]              = value;

		return state.set('UI', UI);
	}

	case actions.EVENT_MARKETS_STATISTICS_DATA_REFRESH: {
		const { data } = action;
		return state.set('marketsStatistics', data);
	}

	case actions.EVENT_STATISTIC_DATA_REFRESH: {
		const { data } = action;
		return state.set('eventStatistic', data);
	}

	case actions.EVENT_SET_SELECTED: {
		const { data } = action;
		return  state.set('selectedEvent', data);
	}

	case actions.EVENT_UPDATE_MARKETS_STATISTICS: {
		return state.set('marketsStatistics', action.data);
	}

	default:
		return state;
	}
}
