import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import isArray from 'lodash/isArray';
import { LoadingOutlined } from '@ant-design/icons';

class CustomModalFooter extends Component {

	static propTypes = {
		buttons: PropTypes.arrayOf(
			PropTypes.shape({
				button  : PropTypes.element.isRequired,
				position: PropTypes.oneOf(['right', 'left']),
			})
		).isRequired,

		loading: PropTypes.bool,
	};

	static defaultProps = {
		loading: false,
	};

	constructor(props) {
		super(props);

		this.renderButtons = this.renderButtons.bind(this);
	}

	renderButtons(buttonList, position = 'right') {
		if (!isArray(buttonList)) {
			return '';
		}

		const divStyle = position === 'left' ? { marginRight: 8 } : { marginLeft: 8 };
		const buttons = buttonList.map((item, index) => {
			return (
			// eslint-disable-next-line react/no-array-index-key
				<div key={index} style={divStyle}>
					{item.button}
				</div>
			);
		});

		return buttons;
	}

	render() {
		const { buttons, loading } = this.props;

		const leftButtons  = [];
		const rightButtons = [];

		buttons.forEach(item => {

			const position = item.position ? String(item.position).toLowerCase() : 'right';
			if (position === 'left') {
				leftButtons.push(item);
			} else {
				rightButtons.push(item);
			}
		});

		if (loading) {
			const spin = {
				button: (
					<LoadingOutlined
						style={{ marginRight: 8, fontSize: 14, fontWeight: 500 }}
					/>
				),
			};
			rightButtons.unshift(spin);
		}

		const showLeftButtons = Boolean(leftButtons.length);
		let resultRender = '';

		const styleStart = {
			display       : 'flex',
			justifyContent: 'flex-start',
			alignItems    : 'center',
		};
		const styleEnd = {
			display       : 'flex',
			justifyContent: 'flex-end',
			alignItems    : 'center',
		};

		if (showLeftButtons) {
			resultRender = (
				<Row>
					<Col span={12} style={styleStart}>
						{this.renderButtons(leftButtons, 'left')}
					</Col>
					<Col span={12} style={styleEnd}>
						{this.renderButtons(rightButtons)}
					</Col>
				</Row>
			);

		} else {
			resultRender = (
				<Row>
					<Col span={24} style={styleEnd}>
						{this.renderButtons(rightButtons)}
					</Col>
				</Row>
			);
		}

		return resultRender;
	}
}

export default CustomModalFooter;
