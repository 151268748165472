import { TRADING_MODE } from '../../../../helpers/commonConstants';

const actions = {
	EVENTS_LIST_RELOAD                     : 'EVENTS_LIST_RELOAD',
	EVENTS_LIST_REFRESH                    : 'EVENTS_LIST_REFRESH',
	EVENTS_LIST_EDITED_DATE_REFRESH        : 'EVENTS_LIST_EDITED_DATE_REFRESH',
	EVENTS_LIST_EDITED_DATE_UPDATE         : 'EVENTS_LIST_EDITED_DATE_UPDATE',
	EVENTS_LIST_EVENT_STATUS_UPDATE        : 'EVENTS_LIST_EVENT_STATUS_UPDATE',
	EVENTS_LIST_EVENT_STATUS_UPDATE_SUCCESS: 'EVENTS_LIST_EVENT_STATUS_UPDATE_SUCCESS',

	EVENT_UPDATE_EVENT_PRIORITY        : 'EVENT_UPDATE_EVENT_PRIORITY',
	EVENT_UPDATE_EVENT_PRIORITY_SUCCESS: 'EVENT_UPDATE_EVENT_PRIORITY_SUCCESS',
	EVENT_FETCH_EVENTS_BY_SPORT        : 'EVENT_FETCH_EVENTS_BY_SPORT',
	EVENTS_PRIOR_LIST_RELOAD           : 'EVENTS_PRIOR_LIST_RELOAD',
	UI_REFRESH							: 'EVENTS_UI_REFRESH',					
	listReload: (type = null) =>  ({
		type     : actions.EVENTS_LIST_RELOAD,
		tableType: type,
	}),
	priorListReload: filter => ({
		type: actions.EVENTS_PRIOR_LIST_RELOAD,
		data: filter,
	}),

	listRefresh: list => ({
		type: actions.EVENTS_LIST_REFRESH,
		data: list,
	}),

	editedDateRefresh: data => ({
		type: actions.EVENTS_LIST_EDITED_DATE_REFRESH,
		data,
	}),
	editedDateUpdate: (eventID, startDate) => ({
		type: actions.EVENTS_LIST_EDITED_DATE_UPDATE,
		data: {
			eventID,
			startDate,
		},
	}),
	eventStatusUpdate: (eventID, statusID) => ({
		type: actions.EVENTS_LIST_EVENT_STATUS_UPDATE,
		data: {
			eventID,
			statusID,
		},
	}),

	updateEventPriority: (id, priority) => ({
		type: actions.EVENT_UPDATE_EVENT_PRIORITY,
		data: {
			id,
			priority,
		},
	}),

	updateEventPrioritySuccess: info => ({
		type: actions.EVENT_UPDATE_EVENT_PRIORITY_SUCCESS,
		data: info,
	}),

	fetchEventsBySport: (id, dateFrom, tradingMode = TRADING_MODE.live) => ({
		type: actions.EVENT_FETCH_EVENTS_BY_SPORT,
		data: {
			id,
			dateFrom,
			tradingMode,
		},
	}),

	eventStatusUpdateSuccess: (eventID, statusID) => ({
		type: actions.EVENTS_LIST_EVENT_STATUS_UPDATE_SUCCESS,
		data: {
			eventID,
			statusID,
		},
	}),

	uiRefresh: data => ({
		type: actions.UI_REFRESH,
		data,
	}),
};

export default actions;
