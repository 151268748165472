import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import { downloadReport } from '../../../helpers/utils';
import { usersAPI } from '../../../helpers/api/users';
import { showError } from '../../../helpers/notifications';

import { createReportParams } from './utils';

const prefix = 'users.reportusers';

const messages = {
	errorDataLoad: `${prefix}.errorDataLoad`,
};

function getStoreData({ Users }) {

	return {
		filter      : Users.Filter.get('filter'),
		sorting     : Users.List.get('sorting'),
		reportFields: Users.ReportUsers.get('reportFields'),
	};
}

function* dataReload() {

	yield takeEvery(actions.REPORT_USERS_DATA_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));
		yield put(actions.dataRefresh(''));

		const { filter, sorting, reportFields } = yield select(getStoreData);
		const params = createReportParams(filter, sorting, reportFields);
		try {
			const res = yield call(usersAPI.usersCSVLoad, params);
			if (res) {
				downloadReport(res);
			}

		} catch (error) {
			showError(messages.errorDataLoad, error);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* reportUsersSaga() {
	yield all([
		fork(dataReload),
	]);
}
