import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import notifications from '../../../../helpers/notifications';
import { eventsAPI } from '../../../../helpers/api/events';
import { settingsAPI } from '../../../../helpers/api/settings';
import { resultsAPI } from '../../../../helpers/api/results';

import actions from './actions';
import {
	adaptStatisticsList,
	adaptScopes,
	adaptStatisticTypes,
	adaptEventParticipants,
	combineStatisticData,
	prepareStatisticList,
} from './utils';

const prefix = 'participants.results.statistics';

const messages = {
	errorStatisticsLoad    : `${prefix}.errorStatisticsLoad`,
	errorScopesLoad        : `${prefix}.errorScopesLoad`,
	errorStatisticTypesLoad: `${prefix}.errorStatisticTypesLoad`,
	errorParticipantsLoad  : `${prefix}.errorParticipantsLoad`,
	errorStatisticsUpdate  : `${prefix}.errorStatisticsUpdate`,
	successStatisticsUpdate: `${prefix}.successStatisticsUpdate`,
};

function getStoreData({ Sport: { Results }, Auth }) {

	return {
		statisticID  : Results.Statistics.get('statisticID'),
		statisticList: Results.Statistics.get('statisticList'),
		UI           : Results.Statistics.get('UI'),
		userID       : Auth.get('user').id,
	};
}

function* dataReload() {
	yield takeEvery(actions.RESULT_STATISTICS_DATA_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		let errorMessage = '';

		const { eventID, sportID } = action.data;
		const params = {
			active_only           : false,
			include_category_names: true,
		};

		let statisticID = null;
		let statisticList = [];
		let scopes = {};
		let statisticTypes = {};
		let participants = {};

		try {
			// main data
			errorMessage = messages.errorStatisticsLoad;
			const resStatistics = yield call(resultsAPI.statisticsList, eventID);
			if (resStatistics && resStatistics.status === 200) {
				const adaptedResult = adaptStatisticsList(resStatistics.data.data);
				statisticID         = adaptedResult.statisticID;   // eslint-disable-line prefer-destructuring
				statisticList       = adaptedResult.statisticList; // eslint-disable-line prefer-destructuring
			}

			// scopes
			errorMessage = messages.errorScopesLoad;
			const resScopes = yield call(settingsAPI.scopeList, sportID);
			if (resScopes && resScopes.status === 200) {
				scopes = adaptScopes(resScopes.data.data);
			}

			// statistic types
			errorMessage = messages.errorStatisticTypesLoad;
			const resTypes = yield call(settingsAPI.statisticTypeList, sportID);
			if (resTypes && resTypes.status === 200) {
				statisticTypes = adaptStatisticTypes(resTypes.data.data);
			}

			// event's participants
			errorMessage = messages.errorParticipantsLoad;
			const resEvent = yield call(eventsAPI.eventGet, eventID, params);
			if (resEvent && resEvent.status === 200) {
				participants = adaptEventParticipants(resEvent.data.data);
			}

			// combine data
			statisticList = combineStatisticData({
				statisticList,
				scopes,
				statisticTypes,
				participants,
			});
		} catch (error) {
			notifications.showError(errorMessage, error);
			console.log(error);
		}

		yield put(
			actions.dataRefresh({
				statisticID,
				statisticList,
			})
		);
		yield put(
			actions.commonDataRefresh({
				scopes,
				statisticTypes,
				participants,
			})
		);
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* dataUpdate() {
	yield takeEvery(actions.RESULT_STATISTICS_DATA_UPDATE, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { statisticID, statisticList, UI, userID } = yield select(
			getStoreData
		);
		const { eventID, sportID, closeModal } = UI;

		const preparedData = prepareStatisticList(
			statisticID,
			statisticList,
			userID
		);
		let isError = false;
		try {
			const res = yield call(
				resultsAPI.statisticsListUpdate,
				eventID,
				preparedData
			);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successStatisticsUpdate);
			}
		} catch (error) {
			isError = true;
			notifications.showError(messages.errorStatisticsUpdate, error);
			console.log(error);
		}

		if (!closeModal && !isError) {
			yield put(actions.dataReload(eventID, sportID));
		}
		yield put(
			actions.uiRefresh({
				loading: false,
				visible: !(closeModal && !isError),
			})
		);
	});
}

export default function* resultStatisticsSaga() {
	yield all([
		fork(dataReload),
		fork(dataUpdate),
	]);
}
