const actions = {

	MARKET_MAPPING_UPDATE          : 'MARKET_MAPPING_UPDATE',
	MARKET_MAPPING_MODAL_UI_REFRESH: 'MARKET_MAPPING_MODAL_UI_REFRESH',
	MARKET_MAPPING_MODAL_UI_RESET  : 'MARKET_MAPPING_MODAL_UI_RESET',

	MARKET_MAPPING_RELOAD_MAPPINGS          : 'MARKET_MAPPING_RELOAD_MAPPINGS',
	MARKET_MAPPING_MAPPED_LIST_REFRESH      : 'MARKET_MAPPING_MAPPED_LIST_REFRESH',
	MARKET_MAPPING_UNMAPPED_LIST_REFRESH    : 'MARKET_MAPPING_UNMAPPED_LIST_REFRESH',
	MARKET_MAPPING_MAPPED_AND_UNMAPPED_LIST_REFRESH: 'MARKET_MAPPING_MAPPED_AND_UNMAPPED_LIST_REFRESH',
	MARKET_MAPPING_SYSTEM_SELECTIONS_REFRESH: 'MARKET_MAPPING_SYSTEM_SELECTIONS_REFRESH',

	MARKET_MAPPING_OUTCOMES_DATA_RELOAD : 'MARKET_MAPPING_OUTCOMES_DATA_RELOAD',
	MARKET_MAPPING_OUTCOMES_DATA_REFRESH: 'MARKET_MAPPING_OUTCOMES_DATA_REFRESH',

	mappingUpdate: () => ({
		type: actions.MARKET_MAPPING_UPDATE,
	}),
	modalUIRefresh: data => ({
		type: actions.MARKET_MAPPING_MODAL_UI_REFRESH,
		data,
	}),
	modalUIReset: () => ({
		type: actions.MARKET_MAPPING_MODAL_UI_RESET,
	}),
	reloadMappings: (marketID, marketName, providerID, sportID, tradingMode) => ({
		type: actions.MARKET_MAPPING_RELOAD_MAPPINGS,
		data: {
			marketID,
			marketName,
			providerID,
			sportID,
			tradingMode,
		},
	}),
	mappedListRefresh: list => ({
		type: actions.MARKET_MAPPING_MAPPED_LIST_REFRESH,
		data: list,
	}),
	unmappedListRefresh: list => ({
		type: actions.MARKET_MAPPING_UNMAPPED_LIST_REFRESH,
		data: list,
	}),
	mappedAndUnmappedListRefresh: list => ({
		type: actions.MARKET_MAPPING_MAPPED_AND_UNMAPPED_LIST_REFRESH,
		data: list,
	}),
	systemSelectionsRefresh: list => ({
		type: actions.MARKET_MAPPING_SYSTEM_SELECTIONS_REFRESH,
		data: list,
	}),
	outcomesDataReload: (providerID, providerMarketID, sportID, tradingMode) => ({
		type: actions.MARKET_MAPPING_OUTCOMES_DATA_RELOAD,
		data: {
			providerID,
			providerMarketID,
			sportID,
			tradingMode,
		},
	}),
	outcomesDataRefresh: data => ({
		type: actions.MARKET_MAPPING_OUTCOMES_DATA_REFRESH,
		data,
	}),
};

export default actions;
