import styled from 'styled-components';

const FormControlWrapper = styled.div`
  .ant-select-selector * {
    cursor: pointer !important;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${props => !props.widthInput ? '100%' : `${props.widthInput}%`};
  overflow: unset;
  box-sizing: border-box;
  margin-bottom: ${props => `${props.marginBottom}px`};
  min-height: ${props => props.minHeight};

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 16px;
    width: ${props => `${props.labelWidth}%`};
    overflow: hidden;
    word-break: break-word;

    span {
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
    }
  }

  .label-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 5px;
    padding-right: 5px;
    width: ${props => `${props.labelWidth}%`};
    overflow: hidden;

    span {
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
    }
  }

  .no-label {
    display: none;
    margin-right: 0px;
  }

  .control {
    display: flex;
    align-items: center;
    width: ${props => `${100 - Number(props.labelWidth)}%`};
    flex: 1;

    &.no-label {
      width: 100%;
    }

    input {
      height: 34px;
    }
  }

  .control_block {
    display: block;
  }

  .control-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: ${props => `${100 - Number(props.labelWidth)}%`};

    input {
      height: 34px;
    }
  }

  .ant-select-disabled {
    color: rgba(0, 0, 0, 0.40);

    .ant-select-selection {
      background-color: #f7f7f7;

      &:hover {
        border: 1px solid #e2e2e2;
        cursor: not-allowed;
      }
    }
  }

  .ant-select-selection__rendered {
    height: auto;
  }

  .ant-input-number-disabled {
    &:hover {
      border-color: #e2e2e2;
    }
  }

  .ant-select {
    max-height: 75px;
  }

  .ant-select-dropdown {
    top: 90% !important;
    left: 0 !important;
    min-width: 100%
  }
`;

const FormControlWrapperColumn = styled.div`
 & .ant-select * {
    cursor: pointer !important;
    max-height: 75px;
  }
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	overflow: unset;
	box-sizing: border-box;
	margin-bottom: ${props => `${props.marginBottom}px`};
	min-height: ${props => props.minHeight};

	.ant-select-disabled {
		color: rgba(0, 0, 0, 0.40);
		.ant-select-selection {
			//background-color: #f7f7f7;
			&:hover {
				border: 1px solid #e2e2e2;
				cursor: not-allowed;
			}
		}
	}

	.ant-select-selection__rendered {
		height: auto;
	}

	.ant-input-number-disabled {
		&:hover {
			border-color: #e2e2e2;
		}
	}
`;

const Label = styled.div`
	width:75%;
`;

const ControlInput = styled.div`
	width: 75%;
`;

export { FormControlWrapper, FormControlWrapperColumn,  Label, ControlInput };
