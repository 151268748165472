import { makeActionCreator } from '../helpers';

const prefix = 'TABLES';

const TYPES = {
	TAKE_SAVED_TABLES   		: `${prefix}_TAKE_SAVED_TABLES`,
	FIRST_GET_SAVED_TABLES      : `${prefix}_FIRST_GET_SAVED_TABLES`,
	SET_TABLES        			: `${prefix}_SET_TABLES`,
	COLUMNS_REFRESH     		: `${prefix}_COLUMNS_REFRESH`,
	POST_TABLES         		: `${prefix}_POST_TABLES`,
	SORTING_REFRESH     		: `${prefix}_SORTING_REFRESH`,
	PAGINATION_REFRESH  		: `${prefix}_PAGINATION_REFRESH`,
	LOADING_REFRESH     		: `${prefix}_LOADING_REFRESH`,
};

const actions = {
	...TYPES,

	getSavedTables    	: makeActionCreator(TYPES.TAKE_SAVED_TABLES, 'type', 'listReload'),
	firstGetSavedTables : makeActionCreator(TYPES.FIRST_GET_SAVED_TABLES),
	columnsRefresh    	: makeActionCreator(TYPES.COLUMNS_REFRESH, 'type', 'tableData'),
	saveTables        	: makeActionCreator(TYPES.POST_TABLES, 'settings'),
	sortingRefresh    	: makeActionCreator(TYPES.SORTING_REFRESH, 'type', 'sorting'),
	paginationRefresh 	: makeActionCreator(TYPES.PAGINATION_REFRESH, 'type', 'pagination'),
	loadingRefresh    	: makeActionCreator(TYPES.LOADING_REFRESH, 'type', 'loading'),
	setTables       	: makeActionCreator(TYPES.SET_TABLES, 'mapTable'),

};

export default actions;
