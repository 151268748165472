import { call, fork, takeEvery, all, put, select } from 'redux-saga/effects';
import { clearLocalStorage } from '../auth/saga';
import { actions } from './actions';
import { newAuthAPI } from '../../helpers/api/newAuth';
import { messages } from './utils';
import { showError, showSuccess } from '../../helpers/notifications';

const getStore = ({ PartnerAuth }) => ({ baseData: PartnerAuth.get('baseData') });

function* reload() {
	yield takeEvery(actions.RELOAD, function* ({ data }) {
		yield put(actions.uiRefresh({ loading: true }));

		try {
			const res = yield call(newAuthAPI.checkPasswordHash, data);
			if ( res && res.status === 200) {
				const { data } = res.data;
				if (data?.success) {
					yield put(actions.uiRefresh({ isValid: true }));
				}
			}
			yield put(actions.uiRefresh({ loading: false }));

		} catch (e) {
			showError(messages.errorReload, e);
			yield put(actions.uiRefresh({ loading: false }));
			console.log(e);
		}
	});
}
function* savePassword() {
	yield takeEvery(actions.DATA_SAVE, function* ({ data: hash }) {

		yield put(actions.uiRefresh({ loading: true }));
		const { baseData } = yield select(getStore);
		const { password, confirmPassword } = baseData;

		let sendBody;
		if (password === confirmPassword) {
			sendBody = {
				password,
			};
		}
		try {
			if (sendBody) {
				const res = yield call(newAuthAPI.setPasswordSave, sendBody, hash);
				if (res && res.status === 200) {
					const { success } = res.data.data;
					yield put(actions.uiRefresh({ success }));
					yield put(actions.uiRefresh({ loading: false }));
					yield put(actions.uiRefresh({ isValid: false }));
				}
				showSuccess(messages.successPasswordSave);
				yield call(clearLocalStorage);
			}
		} catch (e) {
			showError(messages.errorPasswordSave, e);
			yield put(actions.uiRefresh({ loading: false }));
			yield put(actions.uiRefresh({ isValid: false }));

			console.log(e);
		}
		yield put(actions.dataReset());
	});
}

export default function* SetPasswordSaga() {
	yield all([
		fork(reload),
		fork(savePassword),
	]);
}
