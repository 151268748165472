import { Map } from 'immutable';
import actions from './actions';


const initState = new Map({
	list: [],

	UI: {},
});

export default function responsibleGambling(state = initState, action) {

	switch (action.type) {

	case actions.DATA_REFRESH: {
		return state.set('list', action.data);
	}

	default:
		return state;

	}

}
