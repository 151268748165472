//import { normalize, schema } from 'normalizr';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import EntityAdapter from '../../../helpers/entityAdapter';
import { isID } from '../../../helpers/utils';
import { LANGUAGES } from '../../../helpers/commonConstants';
import { fields, createListAdapter } from '../gameCategories/utils';

const listAdapter  = createListAdapter();
const namesAdapter = createNamesAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptGameCategory(rawData = []) {

	listAdapter.clearExcludes();

	if (!isArray(rawData) || isEmpty(rawData)) {
		const adaptedData = listAdapter.adapt(rawData);
		return adaptedData;
	}

	const adaptedData = listAdapter.adapt(rawData[0]);

	return adaptedData;
}

export function adaptNamesData(rawData = [], defaultName = null, langList = []) {

	namesAdapter.clearExcludes();

	const result = [];
	const adaptedList = namesAdapter.adaptList(rawData);

	langList.forEach(languageItem => {

		const langID    = languageItem.id;
		const namesItem = find(adaptedList, { langID }) || {};

		const record = {
			langID,
			name : namesItem.name,
			alias: namesItem.alias,

			OGTitle      : namesItem.OGTitle,
			OGDescription: namesItem.OGDescription,
			OGImage      : namesItem.OGImage,

			SEOTitle      : namesItem.SEOTitle,
			SEODescription: namesItem.SEODescription,
			SEOKeywords   : namesItem.SEOKeywords,
		};

		if (langID === LANGUAGES.en && !record.name) {
			record.name = defaultName;
		}

		result.push(record);
	});

	return result;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareGameCategory(baseData) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	const preparedData = listAdapter.prepare(baseData);
	if (isID(baseData.id)) {
		preparedData.id = toInteger(baseData.id);
	}
	return preparedData;
}

export function adaptGameCategorySimilarList(list) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('logoURL');
	listAdapter.addExcludeField('alias');

	const adaptedList = listAdapter.adaptList(list);

	return adaptedList;
}

export function prepareNamesData(rawData = [], gameCategoryID) {

	namesAdapter.clearExcludes();
	namesAdapter.addExcludeField('gameCategoryID');

	return rawData.map( rawItem => {

		const item = namesAdapter.prepare(rawItem);
		item[fields.gameCategoryID] = gameCategoryID;
		if(!item.name) {
			item.name = null;
		};

		return item;
	});
}

// Adapters ---------------------------------------------------------------------------------------

export function createNamesAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'gameCategoryID', fields.gameCategoryID);
	adapter.addField(rules.id, 'langID', fields.langID);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'alias', fields.alias);

	adapter.addField(rules.string, 'SEOTitle', fields.seoTitle);
	adapter.addField(rules.string, 'SEODescription', fields.seoDescription);
	adapter.addField(rules.string, 'SEOKeywords', fields.seoKeywords);

	adapter.addField(rules.string, 'OGTitle', fields.OGTitle);
	adapter.addField(rules.string, 'OGDescription', fields.OGDescription);
	adapter.addField(rules.string, 'OGImage', fields.OGImage);
	return adapter;
}
