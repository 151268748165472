import { call } from 'redux-saga/effects';
import notifications from '../../../../helpers/notifications';
import { betsAPI } from '../../../../helpers/api/bets';
import { usersAPI } from '../../../../helpers/api/users';
import { BET_HISTORY_TYPES } from '../../../../helpers/commonConstants';

import { createListParams, adaptData, adaptUsers, combineData } from './utils';

export function* loadBetHistoryData(betHistoryType, entityID, filter = {}, pagination = null) {

	let errorMessage = '';
	let bets         = {};
	let totalCount   = 0;
	let usersIDs     = [];
	let users        = {};

	const betslipParams = createListParams(filter, pagination);
	let entityName      = '';

	if (betHistoryType === BET_HISTORY_TYPES.event) {
		betslipParams.event_id = entityID;
		entityName = 'Event bet history';
	}
	if (betHistoryType === BET_HISTORY_TYPES.market) {
		betslipParams.event_market_id = entityID;
		entityName = 'Event Market bet history';
	}
	if (betHistoryType === BET_HISTORY_TYPES.selection) {
		betslipParams.event_selection_id = entityID;
		entityName = 'Event Selection bet history';
	}

	try {
		// betslips
		errorMessage = `Loading ${entityName} failed`;
		const resBets = yield call(betsAPI.betslipsList, betslipParams);
		if (resBets && resBets.status === 200) {
			const adaptedResult = adaptData(resBets.data.data, resBets.headers);
			bets       = adaptedResult.bets;       // eslint-disable-line prefer-destructuring
			totalCount = adaptedResult.totalCount; // eslint-disable-line prefer-destructuring
			usersIDs   = adaptedResult.usersIDs;   // eslint-disable-line prefer-destructuring
		}

		// users
		errorMessage = 'Loading CustomerManagement list failed';
		const usersParams = { ids: usersIDs };
		const resUsers = yield call(usersAPI.usersList, usersParams);
		if (resUsers && resUsers.status === 200) {
			users = adaptUsers(resUsers.data.data);
		}

		// combine
		bets = combineData(bets, users);
	} catch (error) {
		notifications.showError(errorMessage, error);
		console.log(error);
	}

	return {
		bets,
		totalCount,
	};
}
