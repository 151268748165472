import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ImageUploadCard from '../../../../../components/uielements/ImageUploadCard';
import profileActions from '../../../../../redux/profile/actions';
import { lang } from '../../../lang';

class CardAvatar extends Component {

	static propTypes = {
		baseData       : PropTypes.object.isRequired,
		loading        : PropTypes.bool.isRequired,
		baseDataRefresh: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.onUploadSuccess  = this.onUploadSuccess.bind(this);
		this.onClear          = this.onClear.bind(this);
	}

	onUploadSuccess(fileLink) {
		const { baseDataRefresh } = this.props;
		baseDataRefresh({
			avatar: fileLink,
		});
	}

	onClear() {
		const { baseDataRefresh } = this.props;
		baseDataRefresh({ avatar: '' });
	}

	render() {
		const { baseData, loading } = this.props;

		return (
			<ImageUploadCard
				id="profileUserAvatar"
				fileLink={baseData.avatar}
				disabled={loading}
				title={lang.avatar}
				onUploadSuccess={this.onUploadSuccess}
				onClear={this.onClear}
				type="file"
			/>
		);
	}
}

function mapStateToProps(state) {
	const { Profile } = state;
	const UI = Profile.get('UI');

	return {
		baseData: Profile.get('baseData'),
		loading : UI.loading,
	};
}

export default connect(mapStateToProps, {
	baseDataRefresh: profileActions.baseDataRefresh,
})(CardAvatar);
