import dayjs from 'dayjs';
import React from 'react';
import numeral from 'numeral';
import toInteger from 'lodash/toInteger';
import last from 'lodash/last';
import { FORMATS } from './commonConstants';
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

class Formatter {
	logWarning = true;

	setLogWarning(value) {
		this.logWarning = Boolean(value);
	}

	invalidArgument(funcName, value) {
		if (this.logWarning) {
			console.log(`Formatter.${funcName}: Invalid argument: `, value);
		}
	}

	// date
	date(value) {
		if (!value) {
			return '';
		}

		const dayjsValue = dayjs(new Date(value));
		if (!dayjsValue) {
			this.invalidArgument('date', value);
			return '';
		}

		return dayjsValue.format(FORMATS.date);
	}

	monthsAndYearDate(value) {
		if (!value) {
			return '';
		}

		const dayjsValue = dayjs(new Date(value));
		if (!dayjsValue) {
			this.invalidArgument('date', value);
			return '';
		}

		return dayjsValue.format(FORMATS.monthsAndYear);
	}

	dateTime(value) {
		if (!value) {
			return '';
		}
		// this is fixing IOS bug
		value = dayjs(value).format(FORMATS.fullDateSlash);

		const dayjsValue = dayjs(new Date(value));
		if (!dayjsValue) {
			this.invalidArgument('dateTime', value);
			return '';
		}

		return dayjsValue.format(FORMATS.dateTime);
	}

	time(value) {
		if (!value) {
			return '';
		}

		const dayjsValue = dayjs(new Date(value));
		if (!dayjsValue) {
			this.invalidArgument('time', value);
			return '';
		}

		return dayjsValue.format(FORMATS.time);
	}

	fullDateTime(value) {
		if (!value) {
			return '';
		}
		// this is fixing IOS bug
		value = dayjs(value).format(FORMATS.fullDateSlash);

		const dayjsValue = dayjs(new Date(value));
		if (!dayjsValue) {
			this.invalidArgument('fullDateTime', value);
			return '';
		}

		return dayjsValue.format(FORMATS.fullDateTime);
	}

	fullDateMsTime(value) {
		if (!value) {
			return '';
		}
		// this is fixing IOS bug
		// value = dayjs(value).format(FORMATS.fullDateSlash);

		const dayjsValue = dayjs(new Date(value));
		if (!dayjsValue) {
			this.invalidArgument('fullDateTime', value);
			return '';
		}

		return dayjsValue.format(FORMATS.fullDateMsTime);
	}

	isoString(value) {
		if (!value) {
			return '';
		}

		const dayjsValue = dayjs(new Date(value));
		if (!dayjsValue) {
			this.invalidArgument('fullDateTime', value);
			return '';
		}

		return dayjsValue.toISOString();
	}

	humanDuration(value) {
		if (!value) {
			return '';
		}

		const dayjsValue = dayjs.duration(value);
		if (!dayjsValue) {
			this.invalidArgument('humanDuration', value);
			return '';
		}

		return dayjsValue.humanize();
	}

	dateUTC(value) {
		if (!value) {
			return '';
		}

		const dayjsValue = dayjs(new Date(value));
		if (!dayjsValue) {
			this.invalidArgument('dateUTC', value);
			return '';
		}

		return dayjsValue.utc().toISOString();
	}

	dateMsUTC(value) {
		if (!value) {
			return '';
		}

		const dayjsValue = dayjs(new Date(value), FORMATS.fullDateMsTime);
		if (!dayjsValue) {
			this.invalidArgument('dateUTC', value);
			return '';
		}

		return dayjsValue.utc().toISOString();
	}

	// numbers
	integer(value) {
		const numeralValue = numeral(value);
		if (!numeralValue) {
			this.invalidArgument('integer', value);
			return '';
		}

		return numeralValue.format(FORMATS.integer);
	}

	decimal(value) {
		const numeralValue = numeral(value);
		if (!numeralValue) {
			this.invalidArgument('decimal', value);
			return '';
		}

		return numeralValue.format(FORMATS.decimal);
	}

	sum(value) {
		const numeralValue = numeral(value);
		const decimalStr = value && value.toString().split('.')[1];

		if (decimalStr && decimalStr.length > 2 && decimalStr.slice(-1) > 0) {
			return numeralValue.format(FORMATS.millenial);
		}

		if (!numeralValue) {
			this.invalidArgument('sum', value);
			return '';
		}

		return numeralValue.format(FORMATS.sum);
	}

	finance(value) {
		const numeralValue = numeral(value);
		if (!numeralValue) {
			this.invalidArgument('finance', value);
			return '';
		}

		return numeralValue.format(FORMATS.finance);
	}

	percent(value) {
		const numeralValue = numeral(value);
		if (!numeralValue) {
			this.invalidArgument('percent', value);
			return '';
		}

		return numeralValue.format(FORMATS.percent);
	}

	// ordinals
	ordinals(value) {
		const intValue = toInteger(value);
		const lastDigit = last(String(intValue).split(''));
		let suffix = 'th';
		if (intValue < 10 || intValue > 20) {
			switch (lastDigit) {
			case '1':
				suffix = 'st';
				break;

			case '2':
				suffix = 'nd';
				break;

			case '3':
				suffix = 'rd';
				break;

			default:
			}
		}

		return (
			<span className="formatter-ordinals">
				{intValue}
				<sup> {suffix}</sup>
			</span>
		);
	}
}

export default new Formatter();
