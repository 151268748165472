import { createSelector } from 'reselect';

const language = (state) => state.LanguageSwitcher.get('language');

export const deriveLanguage = createSelector(
	[language],
	(language) => language
);

export const deriveLangID = createSelector(
	[deriveLanguage],
	(language) => language.dataBaseLangID
);
