import EntityAdapter from '../../../../helpers/entityAdapter';


export const fields = {

	limitationID: 'id',
	userID      : 'user_id',
	type        : 'type',
	duration    : 'duration',
	value       : 'value',

};

const limitsAdapter = createLimitsPaymentsListAdapter();


export const adaptActiveLimits = (data = []) => {
	return limitsAdapter.adaptList(data);
};


export function createLimitsPaymentsListAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'limitationID', fields.limitationID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'type', fields.type);
	adapter.addField(rules.string, 'duration', fields.duration);
	adapter.addField(rules.string, 'value', fields.value);

	return adapter;
}


