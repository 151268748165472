import { apiRequest } from './index';

const url = '/menu/specialOffers';

const addOffer = ({ sportId, date, regularPrice, customPrice, items }, params = {}) => {
	const req = {
		method: 'POST',
		url,
		data  : {
			date,
			sport_id     : sportId,
			regular_price: regularPrice,
			offer_price  : customPrice,
			items,
		},
		params,
	};

	return apiRequest(req);
};

const updateOffer = (offerId, { sportId, date, regularPrice, customPrice, items }, params = {}) => {
	const req = {
		method: 'POST',
		url   : `${url}/${offerId}`,
		data  : {
			date,
			sport_id     : sportId,
			regular_price: regularPrice,
			offer_price  : customPrice,
			items,
		},
		params,
	};

	return apiRequest(req);
};

const fetchOffers = (params) => {
	const { sportID } = params;
	const req = {
		method: 'GET',
		url,
		params: {
			sport_id: sportID,
		},
	};

	return apiRequest(req);
};

const removeOffer = (offerId, params = {}) => {
	const req = {
		method: 'DELETE',
		url   : `${url}/${offerId}`,
		params,
	};

	return apiRequest(req);
};

export default {
	addOffer,
	fetchOffers,
	removeOffer,
	updateOffer,
};
