import { Map } from 'immutable';
import { PARTNER_TABS } from '../../../../containers/Partner/Partners/utils';
import { actions } from './actions';
import { fill } from '../../../../helpers/utils';


const initState = new Map({

	baseData: {},
	entities: {},
	list    : [],

	partnersWithWebsites       : [],
	selectedPartnersAndWebsites: [],

	UI: {
		visible       : false,
		loading       : false,
		deletedPartner: false,
		closeModal    : false,
		editMode      : false,
		langID        : null,
		isBaseChanged : false,
		activeTab     : PARTNER_TABS.partner,
	},
});

export default (state = initState, { type, data }) => {
	switch (type) {
	case actions.LIST_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(data, target, true);
		return state.set('UI', result);
	}

	case actions.PARTNERS_LIST_REFRESH: {
		return state.set('list', data);
	}

	case actions.BASE_DATA_REFRESH: {
		return state.set('baseData', data);
	}
	case actions.PARTNERS_SELECTED_REFRESH: {
		return state.set('selectedPartnersAndWebsites', data);
	}

	case actions.PARTNERS_WEBSITE_REFRESH: {
		return state.set('partnersWithWebsites', data);
	}

	case actions.DATA_RESET: {
		const partnersWithWebsites = state.get('partnersWithWebsites');
		return initState.set('partnersWithWebsites', partnersWithWebsites);
	}

	default:
		return state;
	}
};
