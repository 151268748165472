import { normalize, schema } from 'normalizr';
import EntityAdapter from '../../../helpers/entityAdapter';

export const fields = {
	id            : 'id',
	userID        : 'user_id',
	url           : 'url',
	documentType  : 'document_type',
	typeID        : 'type_id',
	statusID      : 'status_id',
	created       : 'created',
	deleted       : 'deleted',
	reviewDate    : 'review_date',
	reviewAdminID : 'review_by',
	description   : 'description',
	documentNumber: 'document_number',
	documentUrl   : 'document_url',
	reason        : 'decline_reason',
	docID         : 'id',
	docDate       : 'created',


	name       : 'name',
	surname    : 'surname',
	nationality: 'nationality',
	dob        : 'date_of_birth',
	passExpDate: 'passport_expiration_date',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptDocumentsList(rawData = []) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	const tempData = {
		items: adaptedData,
	};
	const item           = new schema.Entity('items', {}, { idAttribute: 'docID' });
	const itemsSchema    = { items: [item] };
	const normalizedData = normalize(tempData, itemsSchema);
	const entities       = normalizedData.entities.items || {};
	return entities;
}

export function adaptDocument(document = {}, user = {}, notifID) {

	listAdapter.clearExcludes();

	const adaptedData = listAdapter.adapt(document);

	adaptedData.userName  = user.username;
	adaptedData.userEmail = user.email;
	adaptedData.id        = notifID;
	return adaptedData;
}

export function prepareDocument(document = {}) {
	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('reviewAdminID');
	listAdapter.addExcludeField('reviewDate');
	listAdapter.addExcludeField('statusID');
	listAdapter.addExcludeField('created');
	listAdapter.addExcludeField('deleted');
	listAdapter.addExcludeField('url');
	return listAdapter.prepare(document);
}
export function prepareDocumentUpdate(document = {}) {
	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('reviewAdminID');
	listAdapter.addExcludeField('reviewDate');
	listAdapter.addExcludeField('statusID');
	listAdapter.addExcludeField('created');
	listAdapter.addExcludeField('deleted');
	listAdapter.addExcludeField('url');
	listAdapter.addExcludeField('documentUrl');
	listAdapter.addExcludeField('userName');
	listAdapter.addExcludeField('firstName');
	listAdapter.addExcludeField('userEmail');
	listAdapter.addExcludeField('lastName');
	listAdapter.addExcludeField('decline_reason');
	listAdapter.addExcludeField('description');
	return listAdapter.prepare(document);
}


// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'docID', fields.docID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'typeID', fields.typeID);

	// adapter.addField(rules.id, 'documentType', fields.documentType);
	adapter.addField(rules.string, 'documentNumber', fields.documentNumber);
	adapter.addField(rules.string, 'documentUrl', fields.documentUrl);

	adapter.addField(rules.id, 'reviewAdminID', fields.reviewAdminID);
	adapter.addField(rules.string, 'url', fields.url);

	adapter.addField(rules.fullDate, 'created', fields.created);
	adapter.addField(rules.fullDate, 'deleted', fields.deleted);
	adapter.addField(rules.fullDate, 'reviewDate', fields.reviewDate);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.string, 'reason', fields.reason);
	adapter.addField(rules.dateTime, 'date', fields.docDate);

	adapter.addField(rules.string, 'userName', fields.docUserName);
	adapter.addField(rules.string, 'firstName', fields.docFirstName);
	adapter.addField(rules.string, 'lastName', fields.docLastName);
	adapter.addField(rules.string, 'userEmail', fields.docEmail);

	adapter.addField(rules.dateTime, 'date', fields.docDate);

	adapter.addField(rules.date, 'dob', fields.dob);
	adapter.addField(rules.date, 'passExpDate', fields.passExpDate);
	adapter.addField(rules.string, 'nationality', fields.nationality);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'surname', fields.surname);

	return adapter;
}
