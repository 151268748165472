import { combineReducers } from 'redux';

import FreeSpins from './freeSpins/reducer';
import Bonuses from './bonuses/reducer';
import UserBonus from './userBonus/reducer';
import FirstDeposit from './firstDeposit/reducer';
import WelcomeBonus from './welcomeBonus/reducer';
import Cashback from './cashback/reducer';
import CashbackUsers from './cashbackUsers/reducer';
import UserCasinoBonuses from './userCasinoBonuses/reducer';
import UserCasinoFreeSpins from './userCasinoFreeSpins/reducer';
import FreeBets from './freeBets/reducer';
import UserFreeBets from './userFreeBets/reducer';

export default combineReducers({
	FreeSpins,
	Bonuses,
	UserBonus,
	FirstDeposit,
	WelcomeBonus,
	Cashback,
	CashbackUsers,
	UserCasinoBonuses,
	UserCasinoFreeSpins,
	FreeBets,
	UserFreeBets,
});
