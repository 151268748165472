import EntityAdapter from '../../../../helpers/entityAdapter';

const fields = {
	id              : 'id',
	iconMobile      : 'icon_mobile',
	iconWeb         : 'icon_web',
	name            : 'name',
	sportID         : 'sport_id',
	websiteID       : 'website_id',
	channelID       : 'channel_id',
	langID          : 'lang_id',
	orderID         : 'order_id',
	description     : 'description',
	visibilityStatus: 'visibility_status',
};

const listAdapter = createListAdapter();
const baseDataAdapter = createBaseDataAdapter();
const baseDataEditAdapter = createBaseDataEditAdapter();

const namesPrepare = createNamesAdapter();
const namesAdapter = getNamesAdapter();

const visibilityStatus = visibilityStatusAdapter();

export function adaptList(rawData = {}) {
	listAdapter.clearExcludes();
	return listAdapter.adaptList(rawData);
}

export function prepareData(data = {}, websiteID) {
	const baseData = { ...data, websiteID };
	baseDataAdapter.clearExcludes();
	return baseDataAdapter.prepare(baseData);
}

export function prepareDataEdit(baseData = {}) {
	baseDataEditAdapter.clearExcludes();
	return baseDataEditAdapter.prepare(baseData);
}

function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'iconMobile', fields.sportID);
	adapter.addField(rules.string, 'iconWeb', fields.iconWeb);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.id, 'sportID', fields.sportID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	return adapter;
}

function createBaseDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'sportID', fields.sportID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'iconWeb', fields.iconWeb);
	adapter.addField(rules.string, 'iconMobile', fields.iconMobile);

	return adapter;
}

function createBaseDataEditAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'iconWeb', fields.iconWeb);
	adapter.addField(rules.string, 'iconMobile', fields.iconMobile);

	return adapter;
}

// ------------------- NAMES

export function prepareNames(data = {}) {
	const dataArr = Object.values(data).map(item => Object.values(item)).flat(Infinity);
	return dataArr.map(item => {
		namesPrepare.clearExcludes();
		return namesPrepare.prepare(item);
	});
}

export function adaptNames(data = {}) {
	namesAdapter.clearExcludes();

	let obj = {};
	let adaptItem = {};
	data.forEach(item => {
		adaptItem = namesAdapter.adapt(item);
		obj = {
			...obj,
			[adaptItem.langID]: {
				...obj[adaptItem.langID],
				[adaptItem.channelID]: adaptItem,
			},
		};
	});

	return obj;
}

function createNamesAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.number, 'orderID', fields.orderID);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'description', fields.description);

	return adapter;
}

function getNamesAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.nullOrNumber, 'orderID', fields.orderID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'id', fields.id);

	return adapter;
}


// --------------  visibility Status

export function prepareVisibilityStatus(data = {}) {
	const dataArr = Object.values(data);
	return dataArr.map(item => {
		visibilityStatus.clearExcludes();
		return visibilityStatus.prepare(item);
	});
}

export function adaptVisibility(data = {}) {
	visibilityStatus.clearExcludes();

	let obj = {};
	let adaptItem = {};
	data.forEach(item => {
		adaptItem = visibilityStatus.adapt(item);
		obj = {
			...obj,
			[adaptItem.channelID]: adaptItem,
		};
	});

	return obj;
}

function visibilityStatusAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.bool, 'visibilityStatus', fields.visibilityStatus);
	adapter.addField(rules.id, 'channelID', fields.channelID);

	return adapter;
}
