const actions = {

	PAYMENTS_LIST_RELOAD : 'PAYMENTS_LIST_RELOAD',
	PAYMENTS_LIST_REFRESH: 'PAYMENTS_LIST_REFRESH',

	PAYMENTS_LIST_UI_REFRESH: 'PAYMENTS_LIST_UI_REFRESH',

	listReload: () => ({
		type: actions.PAYMENTS_LIST_RELOAD,
	}),
	listRefresh: entities => ({
		type: actions.PAYMENTS_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	uiRefresh: data => ({
		type: actions.PAYMENTS_LIST_UI_REFRESH,
		data,
	}),
};

export default actions;
