import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../../../../helpers/utils';

const initState = new Map({
	entities: {},
	UI		: {
		loading: false,
	}
});

export default function bettingRulesListReducer(state = initState, action) {

	switch (action.type) {

	case actions.DATA_REFRESH: {
		const { entities } = action.data;
		return state.set('entities', entities);
	}

	case actions.BONUS_UI_REFRESH: {
		const { UI } = action.data;
		const target = state.get('UI');
		const result = fill(UI, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
