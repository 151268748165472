import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	modalUI: {
		visible   : false,
		marketID  : null,
		marketName: '',
		info      : '',
		loading   : false,
	},
});

export default function confirmCancelReducer(state = initState, action) {

	switch (action.type) {

	case actions.MARKET_CONFIRM_CANCEL_SET_MODAL_VALUE_UI: {
		const { valueName, value } = action.data;
		const modalUI           = new Map(state.get('modalUI')).toObject();
		modalUI[valueName]      = value;

		return state.set('modalUI', modalUI);
	}

	case actions.MARKET_CONFIRM_CANCEL_MODAL_UI_REFRESH: {
		return state.set('modalUI', action.data);
	}

	case actions.MARKET_CONFIRM_CANCEL_DATA_RESET: {
		return initState;
	}

	default:
		return state;
	}
}
