import { apiRequest } from './index';

const partnerList = (params = {}) => {

	const req = {
		method: 'GET',
		url   : '/partners',
		params,
	};

	return apiRequest(req);
};
const partnerWithWebsiteList = (params = {}) => {

	const req = {
		method: 'GET',
		url   : '/partner/websites',
		params,
	};

	return apiRequest(req);
};

const createPartner = (body, params = {} ) => {
	const req = {
		method: 'POST',
		url   : '/partners',
		params,
		data  : body,
	};

	return apiRequest(req);
};

const reSendInvitation = (body = {}, userID, params = {} ) => {
	const req = {
		method: 'POST',
		url   : `/partners/reSendPassword/${userID}`,
		params,
		data  : body,
	};

	return apiRequest(req);
};


const updatePartner = (partnerID, body, method, params = {}) => {
	const req = {
		method: method ? 'DELETE' : 'PUT',
		url   : `/partners/${partnerID}`,
		params,
		data  : body,
	};

	return apiRequest(req);
};


const getPartnerById = (partnerID, params = {}) => {

	const req = {
		method: 'GET',
		url   : `/partners/${partnerID}`,
		params,
	};

	return apiRequest(req);
};


const getPartnerSettings = (partnerID, params = {}) => {

	const req = {
		method: 'GET',
		url   : `/partners/${partnerID}/partner_settings`,
		params,
	};

	return apiRequest(req);
};

const updatePartnerSettings = (data, partnerID) => {
	// const { partnerID } = data;
	const req = {
		method: 'PUT',
		url   : `/partners/${partnerID}/partner_settings`,
		data,
	};

	return apiRequest(req);
};

const getPartnerSettingsProviders = (partnerID, params = {}) => {

	const req = {
		method: 'GET',
		url   : `/partners/${partnerID}/partner_settings/providers`,
		params,
	};

	return apiRequest(req);
};


const updatePartnerSettingsProviders = (data, partnerID, params = {}) => {
	// const { partnerID } = data;
	const req = {
		method: 'PUT',
		url   : `/partners/${partnerID}/partner_settings/providers`,
		data,
		params,
	};

	return apiRequest(req);
};

const getPartnerSettingsPayments = (partnerID, params = {}) => {

	const req = {
		method: 'GET',
		url   : `/partners/${partnerID}/partner_settings/payments`,
		params,
	};

	return apiRequest(req);
};

const getAllPayments = (partnerID, params = {}) => {

	const req = {
		method: 'GET',
		url   : '/payments',
		params,
	};

	return apiRequest(req);
};


const getPartnerPayments = (partnerID, params = {}) => {

	const req = {
		method: 'GET',
		url   : `/partner/${partnerID}/payments`,
		params,
	};

	return apiRequest(req);
};
const updatePartnerSettingsPayments = (data, partnerID, params = {}) => {
	// const { partnerID } = data;
	const req = {
		method: 'PUT',
		url   : `/partners/${partnerID}/partner_settings/payments`,
		data,
		params,
	};

	return apiRequest(req);
};

const getPartnerSettingsLanguages = (partnerID, params = {}) => {

	const req = {
		method: 'GET',
		url   : `/partners/${partnerID}/partner_settings/languages`,
		params,
	};

	return apiRequest(req);
};
const getPartnerSettingsMainLanguages = (params = {}) => {

	const req = {
		method: 'GET',
		url   : '/language',
		params,
	};

	return apiRequest(req);
};


const updatePartnerSettingsLanguages = (data, partnerID, params = {}) => {
	// const { partnerID } = data;
	const req = {
		method: 'PUT',
		url   : `/partners/${partnerID}/partner_settings/languages`,
		data,
		params,
	};

	return apiRequest(req);
};


const getPartnerSettingsCurrencies = (partnerID, params = {}) => {
	const req = {
		method: 'GET',
		url   : `/partners/${partnerID}/partner_settings/currencies`,
		params,
	};
	return apiRequest(req);
};

const updatePartnerSettingsCurrencies = (data, partnerID, params = {}) => {
	// const { partnerID } = data;
	const req = {
		method: 'PUT',
		url   : `/partners/${partnerID}/partner_settings/currencies`,
		data,
		params,
	};

	return apiRequest(req);
};


const getPartnerSettingsSMSGateway = (partnerID, params = {}) => {

	const req = {
		method: 'GET',
		url   : `/partners/${partnerID}/partner_settings/sms_gateways`,
		params,
	};

	return apiRequest(req);
};

const getPartnerSettingsCountries = (partnerID, params = {}) => {

	const req = {
		method: 'GET',
		url   : `/partners/${partnerID}/partner_settings/countries`,
		params,
	};

	return apiRequest(req);
};

const updatePartnerSettingsSMSGateway = (data, partnerID, params = {}) => {
	// const { partnerID } = data;
	const req = {
		method: 'PUT',
		url   : `/partners/${partnerID}/partner_settings/sms_gateways`,
		data,
		params,
	};

	return apiRequest(req);
};
const updatePartnerSettingsCountry = (data, partnerID, params = {}) => {
	const req = {
		method: 'PUT',
		url   : `/partners/${partnerID}/partner_settings/countries`,
		data,
		params,
	};
	return apiRequest(req);
};

export const partnerAPI = {
	partnerList,
	partnerWithWebsiteList,
	getPartnerById,
	createPartner,
	updatePartner,
	reSendInvitation,

	getPartnerSettings,
	getPartnerSettingsProviders,
	getPartnerSettingsPayments,
	getPartnerSettingsLanguages,
	getPartnerSettingsMainLanguages,
	getPartnerSettingsCurrencies,
	getPartnerSettingsSMSGateway,
	getPartnerSettingsCountries,

	updatePartnerSettingsSMSGateway,
	updatePartnerSettingsCurrencies,
	updatePartnerSettingsLanguages,
	updatePartnerSettingsPayments,
	updatePartnerSettingsProviders,
	updatePartnerSettingsCountry,

	updatePartnerSettings,
	getPartnerPayments,
	getAllPayments,
};
