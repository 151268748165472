import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	widgetData: {
		total: {},
		data : [],
	},
	UI: {
		loading: false,
	},
});

export default function dashboardBetsByProviderReducer(state = initState, action) {

	switch (action.type) {

	case actions.DASHBOARD_BETS_BY_PROVIDER_DATA_REFRESH: {
		return state.set('widgetData', action.data);
	}
	case actions.DASHBOARD_BETS_BY_PROVIDER_UI_REFRESH: {
		return state.set('UI', action.data);
	}
	default:
		return state;
	}
}
