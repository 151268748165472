import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import { CASINO_GAMES_VIEW_TABS, CHANNEL_TYPES } from '../../../helpers/commonConstants';

import actions from './actions';

const initState = new Map({

	gamesList       : [],
	entities        : {},
	gamesListInModal: [],
	searchValue     : '',
	gamesSearchList : [],

	UI: {
		websiteID       : null,
		providerID      : null,
		customProviderID: null,
		casinoID        : null,
		loading         : false,
		channelID       : CHANNEL_TYPES.mobile,
		currentTab      : CASINO_GAMES_VIEW_TABS.grid,
		categoryID      : 0,
		categories      : [],
		isGamesChanged  : false,
		searchQuery     : '',
	},
});

export default function casinoGamesReducer(state = initState, action) {
	switch (action.type) {
	case actions.CASINO_GAMES_LIST_REFRESH: {
		return state.set('gamesList', action.data);
	}
	case actions.CASINO_GAMES_ENTITIES_REFRESH: {
		return state.set('entities', action.data);
	}
	case actions.CASINO_GAMES_LIST_MODAL_REFRESH: {
		return state.set('gamesListInModal', action.data);
	}
	case actions.CASINO_GAMES_SEARCH_VALUE: {
		return state.set('searchValue', action.data);
	}
	case actions.CASINO_GAMES_SEARCH_LIST_REFRESH: {
		return state.set('gamesSearchList', action.data);
	}
	case actions.ADD_GAME_AS_ELIGIBLE: {
		const gameIDS = state.getIn(['eligability', 'gameIDS']).push(action.data);
		return state.setIn(['eligability', 'gameIDS'], gameIDS);
	}
	case actions.REMOVE_GAME_AS_ELIGIBLE: {
		return state.set('gamesList', action.data);
	}

	case actions.CASINO_GAMES_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.UPDATE_CATEGORIES: {
		const UI = state.get('UI' );
		return  state.set('UI', { ...UI, categories: action.data });
	}
	case actions.UPDATE_SEARCH_QUERY: {
		const UI = state.get('UI' );
		return  state.set('UI', { ...UI, searchQuery: action.data });
	}

	default:
		return state;
	}
}
