import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../tables/actions';

import { bannersAPI } from '../../../../helpers/api/banners';
import notifications from '../../../../helpers/notifications';
import { deriveTablePagination } from '../../../../selectors/tables';

import { getListParams, adaptBannerGroupsList } from './utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';

const prefix = 'banners.groups';

const messages = {
	errorListLoad     : `${prefix}.loading.list.failed`,
	errorGroupDelete  : `${prefix}.deleting.failed`,
	successGroupDelete: `${prefix}.deleted.success`,
};

const tableType     = TABLE_TYPES.bannersGroups;

function getStoreData(state) {
	const { Tables } = state;

	return {
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
	};
}

function* listReload() {

	yield takeEvery(actions.BANNER_GROUPS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { pagination, sorting } = yield select(getStoreData);
		const params = getListParams(pagination, sorting);

		let entities   = {};
		let totalCount = 0;

		try {
			const res = yield call(bannersAPI.bannerGroupsList, params);
			if (res && res.status === 200) {
				const result = adaptBannerGroupsList(res.data.data, res.headers);
				entities     = result.entities;   // eslint-disable-line prefer-destructuring
				totalCount   = result.totalCount; // eslint-disable-line prefer-destructuring
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad);
			console.log(error);
		}

		yield put(actions.listRefresh(entities));
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* deleteItem() {

	yield takeEvery(actions.BANNER_GROUPS_LIST_DELETE_ITEM, function* (action) {

		const { groupID } = action.data;
		try {
			const res = yield call(bannersAPI.bannerGroupDelete, groupID);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successGroupDelete);
				yield put(actions.listReload());
			}
		} catch (error) {
			notifications.showError(messages.errorGroupDelete);
			console.log(error);
		}
	});
}

export default function* bannerGroupsListSaga() {
	yield all([
		fork(listReload),
		fork(deleteItem),
	]);
}
