import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../../helpers/utils';

const initState = new Map({
	overallData: {},
	UI         : {
		loading: false,
	},
});

export default function Overall(state = initState, action) {
	switch (action.type) {
	case actions.PLAYERS_OVERALL_DATA_REFRESH: {
		const target = state.get('overallData');
		const result = fill(action.data.overallData, target, true);
		return state.set('overallData', result);
	}
	case actions.PLAYERS_OVERALL_UI_REFRESH: {
		return state.set('UI', action.data);
	}
	default:
		return state;
	}
}
