import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { emitEvent, createError } from '../../helpers/sockets/socket';
import { showError } from '../../helpers/notifications';
import actions from './actions';

import eventActions from '../sport/events/event/actions';
import { SUBJECT_TYPE_NOTIFICATIONS, SUBJECT_TYPE_RTMS } from '../../constants/notifications';

const prefix = 'socket.internal';

const messages = {
	errorSubscribeRTM                     : `${prefix}.errorSubscribeRTM`,
	errorUnsubscribeRTM                   : `${prefix}.errorUnsubscribeRTM`,
	errorSubscribeNotification            : `${prefix}.errorSubscribeNotification`,
	errorUnsubscribeNotification          : `${prefix}.errorUnsubscribeNotification`,
	errorSubscribeEventMarketsStatistics  : `${prefix}.errorSubscribeEventMarketsStatistics`,
	errorUnsubscribeEventMarketsStatistics: `${prefix}.errorUnsubscribeEventMarketsStatistics`,
};

function* eventMarketsStatisticsSubscribe() {
	yield takeEvery(eventActions.EVENT_SOCKET_SUBSCRIBE, function* (action) {
		const { eventID, subjectType } = action.data;
		const  eventType = 'subscribe';
		const eventBody = {
			ids: [eventID],
			subjectType,
		};

		try {
			const res = yield call(emitEvent, eventType, eventBody);
			if (!res || !res.isSuccess) {
				const err = createError(res);
				throw err;
			}
		} catch (error) {
			showError(messages.errorSubscribeEventMarketsStatistics, error);
		}
	});
}

function* eventMarketsStatisticsUnsubscribe() {
	yield takeEvery(eventActions.EVENT_SOCKET_UNSUBSCRIBE, function* ({ data }) {
		const { type } = data;
		const eventType = 'subscribe';
		const eventBody = {
			ids   : [],
			type,
			status: false,
		};

		try {
			const res = yield call(emitEvent, eventType, eventBody);
			if (!res || !res.isSuccess) {
				const err = createError(res);
				throw err;
			}

		} catch (error) {
			showError(messages.errorUnsubscribeEventMarketsStatistics, error);
		}
	});
}

function* rtmSubscribe() {

	yield takeEvery(actions.SOCKET_RTM_SUBSCRIBE, function* (action) {

		const { rtmType, subscribeIDs } = action.data;
		const eventType = 'subscribe';

		const eventBody = {
			subjectType: SUBJECT_TYPE_RTMS[rtmType],
			ids        : subscribeIDs,
		};
		yield put(actions.rtmSubscribedTypeRefresh(rtmType));

		try {
			const res = yield call(emitEvent, eventType, eventBody);
			if (!res || !res.isSuccess) {
				const err = createError(res);
				throw err;
			}

		} catch (error) {
			showError(messages.errorSubscribeRTM);
		}
	});
}

function* rtmUnsubscribe() {

	yield takeEvery(actions.SOCKET_RTM_UNSUBSCRIBE, function* (action) {

		const { rtmType } = action.data;
		const eventType = 'unsubscribe_all';
		const eventBody = { subjectType: SUBJECT_TYPE_RTMS[rtmType] };

		try {
			const res = yield call(emitEvent, eventType, eventBody);
			if (!res || !res.isSuccess) {
				const err = createError(res);
				throw err;
			}

		} catch (error) {
			showError(messages.errorUnsubscribeRTM);
		}
	});
}

function* notificationSubscribe() {

	yield takeEvery(actions.SOCKET_NOTIFICATION_SUBSCRIBE, function* (action) {
		const { notifType } = action.data;
		const eventType = 'subscribe';
		const eventBody = { notifType };

		try {
			const res = yield call(emitEvent, eventType, eventBody);
			if (!res || !res.isSuccess) {
				const err = createError(res);
				throw err;
			}
			yield put(actions.notificationSubscribedTypeRefresh(notifType));
		} catch (error) {
			showError(messages.errorSubscribeNotification, error);
		}
	});
}

function* notificationUnsubscribe() {

	yield takeEvery(actions.SOCKET_NOTIFICATION_UNSUBSCRIBE, function* (action) {

		const { notifType } = action.data;

		const eventType = 'unsubscribe_all';
		const eventBody = { notifType: SUBJECT_TYPE_NOTIFICATIONS[notifType] };

		try {
			const res = yield call(emitEvent, eventType, eventBody);
			if (!res || !res.isSuccess) {
				const err = createError(res);
				throw err;
			}

		} catch (error) {
			showError(messages.errorUnsubscribeNotification, error);
		}
		yield put(actions.notificationSubscribedTypeRefresh(null));
	});
}

export default function* socketInternalSaga() {
	yield all([
		fork(rtmSubscribe),
		fork(rtmUnsubscribe),

		fork(notificationSubscribe),
		fork(notificationUnsubscribe),

		fork(eventMarketsStatisticsSubscribe),
		fork(eventMarketsStatisticsUnsubscribe),
	]);
}
