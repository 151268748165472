import React from 'react';
import getPathValue from 'lodash/get';
import isArray from 'lodash/isArray';
import { createIntl } from 'react-intl';
import { notification as notifications } from 'antd';
import store from '../redux/store';


import enMessages from '../languageProvider/locales/en_US.json';
import ruMessages from '../languageProvider/locales/ru_RU.json';
import { HTTPStatusCodes } from '../constants/HTTPStatusCodes';
// Need to add all languages.
import { restoreLanguage } from './utility';

const messages = {
	en: enMessages,
	ru: ruMessages,
	//... add language messages here
};


const getStore = (store) => {
	const { App, Auth } = store.getState();
	const modals  = App.get('modals');
	const idToken = Auth.get('idToken');

	return {
		passwordExpiredModal: modals.passwordExpiredModal,
		idToken,
	};
};

const delay = (cb, ms = 500) => {
	setTimeout(cb, ms)
}

export class AppNotification {
	notification;
	static self = null;
	constructor() {
		if (AppNotification.self) return AppNotification.self;
		// Antd Notification global configs
		// it can be overridden at every step of the notification call
		notifications.config({
			top: 50,
			duration: 10,
			maxCount: 10,
			stack: {
				threshold: 10, // this config does not work in AntD current version.
			},
			getContainer: () => {
				return document.querySelector('#notifications-root-node');
			},
		});

		this.notification = notifications

		AppNotification.self = this;
	}


	queue = [];
	isDisplaying = false;

	destroyAll() {
		this.notification.destroy();
	}

	info(data = {}) {
		const { description, message } = data;
		if (this.isDisplaying) {
			this.queue.push(description);
			return;
		}
		this.isDisplaying = true;
		this.notification.info({
			message,
			description,
		});
		delay(() => {
			this.isDisplaying = false;
			this.showQueue((data) => this.info(data));
		});

	}

	warning(data = {}) {
		const { description, message} = data;
		if (this.isDisplaying) {
			this.queue.push(data);
			return;
		}

		this.isDisplaying = true;
		this.notification.warning({
			message,
			description,
		})
		delay(() => {
			this.isDisplaying = false;
			this.showQueue((data) => this.warning(data));
		})

	}

	success(data = {}) {
		const { description, message} = data;
		if (this.isDisplaying) {
			this.queue.push(data);
			return;
		}

		this.isDisplaying = true;
		this.notification.success({
			message,
			description,
		})
		delay(() => {
			this.isDisplaying = false;
			this.showQueue((data) => this.success(data));
		})

	}

	error(data = {}) {
		const { description, message} = data;
		if (this.isDisplaying) {
			this.queue.push(data);
			return;
		}

		this.isDisplaying = true;
		this.notification.error({
			message,
			description,
		})
		delay(() => {
			this.isDisplaying = false;
			this.showQueue((data) => this.error(data));
		})

	}

	showQueue(showNotification) {
		this.queue.forEach(item => {
			showNotification(item);
			this.queue.shift();
		})
	}

	debounce
}


const appNotifications = new AppNotification();

export function showSuccess(id) {
// React intl version 2 doesn't support non-component formatting.
// this functionality should change in case of package update.
	const locale = restoreLanguage() ? restoreLanguage().locale : 'en';
	const intl = new createIntl({ locale, messages: messages[locale], key: locale });

	appNotifications.success({
		message: intl.formatMessage({
			id: 'common.success',
		}),
		description: intl.formatMessage({
			id,
		}),
	});
}

export function showError(description, errorInstance = null) {
	const { passwordExpiredModal, idToken } = getStore(store);
	const errorInstStatus = errorInstance && errorInstance.response && errorInstance.response.status;
	const errUnauthorized = errorInstStatus === HTTPStatusCodes.Unauthorized && !idToken;

	if ((passwordExpiredModal && passwordExpiredModal.visible && passwordExpiredModal.passwordLoading) || errUnauthorized) {
		return;
	}
	const locale = restoreLanguage() ? restoreLanguage().locale : 'en';

	const intl = createIntl({ locale, messages: messages[locale], key: locale });


	let errorMessage = getDescription(description);
	if (errorInstance) {
		const errorPath        = 'response.data.text';
		const errorDescription = getPathValue(errorInstance, errorPath, '');
		errorMessage           = errorDescription ? (
			<div>
				<div>
					<strong>{errorMessage}</strong>
				</div>
				<div>Details:</div>
				<div>{intl.formatMessage({
					id: errorDescription,
				})}
				</div>
			</div>
		) : (
			`${description}`
		);
	}
	try {
		appNotifications.error({
			message    : 'Error',
			description: errorMessage,
		});
	} catch (err) {
		console.error(errorMessage);
	}
}

export function showInfo(description) {
	const locale = restoreLanguage() ? restoreLanguage().locale : 'en';

	const intl = createIntl({ locale, messages: messages[locale], key: locale });

	appNotifications.info({
		message: intl.formatMessage({
			id: 'common.info',
		}),
		description: description,
	});
}

export function showWarning(description) {
	const locale = restoreLanguage() ? restoreLanguage().locale : 'en';

	const intl = createIntl({ locale, messages: messages[locale], key: locale });

	appNotifications.warning({
		message: intl.formatMessage({
			id: 'common.warning',
		}),
		description: intl.formatMessage({
			id: description,
		}),
	});
}


function getDescription(description) {
	const data = [];

	const locale = restoreLanguage() ? restoreLanguage().locale : 'en';

	// const intlProvider = new IntlProvider({ locale, messages: messages[locale], key: locale });
	const intl = new createIntl({ locale, messages: messages[locale], key: locale });

	// const { intl } = intlProvider.getChildContext();


	if (!isArray(description)) {
		data.push(description);
	} else {
		data.push(...description);
	}

	return data.map(messageID => {
		return (
			<div key={messageID}>
				{intl.formatMessage({
					id: messageID,
				})}
			</div>
		);
	});
}

export default {
	showSuccess,
	showError,
	showInfo,
	showWarning,
};


