import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	bonusData: {
		userID: 0,
		titles: [],
		count : {
			providers : {},
			categories: {},
		},
		integratorIDs: [],
		providerIDs  : [],
	},

	checkedProvidersByIntegrator: {},

	/**
   * [{ betID: 0, betPerLine: 1, lines: 20 }]
   */
	bets: [],

	/**
   * {
   *    0.01 : [1, 3, 5],
   *    1    : [3, 5, 7.5, 10, 20, 30, 50],
   *    5    : [100, 200, 300, 500, 750, 1000, 2000, 3000, 5000, 7500, 10000],
   * }
   */
	denominations: {},

	UI: {
		bonusTypeID      : 0,
		visible          : false,
		loading          : false,
		isChanged        : false,
		betID            : null,
		denominationKey  : null,
		denominationValue: null,
		massBonus        : false,
		langID           : 0,
	},
});

export default function userBonusReducer(state = initState, action) {

	switch (action.type) {

	case actions.DATA_REFRESH: {
		const { bonusData } = action.data;
		const target = state.get('bonusData');
		const result = fill(bonusData, target, true);
		return state.set('bonusData', result);
	}

	case actions.BONUS_DATA_RESET: {
		return state.set('bonusData', initState.get('bonusData'));
	}

	case actions.PROVIDERS_LIST_REFRESH: {
		const { checkedProvidersByIntegrator } = action.data;
		const target = state.get('checkedProvidersByIntegrator');
		const result = fill(checkedProvidersByIntegrator, target, true);
		return state.set('checkedProvidersByIntegrator', result);
	}

	case actions.RESET_CHECKED_PROVIDER_BY_INTEGRATION: {
		return state.set('checkedProvidersByIntegrator', {});
	}

	case actions.BETS_REFRESH: {
		const { bets } = action.data;
		return state.set('bets', bets);
	}

	case actions.DENOMINATIONS_REFRESH: {
		const { denominations } = action.data;
		return state.set('denominations', denominations);
	}

	case actions.UI_REFRESH: {
		const { UI } = action.data;
		const target = state.get('UI');
		const result = fill(UI, target);
		return state.set('UI', result);
	}

	case actions.DATA_RESET: {
		return initState;
	}

	default:
		return state;
	}
}
