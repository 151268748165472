import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { CRUD_OPTIONS } from '../../../../../../redux/staff/permissions/utils';
import IntlMessages from '../../../../../../components/utility/intlMessages';
import { InfoCircleTwoTone } from '@ant-design/icons';

export const MAIN_BONUSES_TABS = {
	'admin_loyalty_bonus-engine_deposit-free-spin': 'admin_loyalty_bonus-engine_deposit-free-spin',
	'admin_loyalty_bonus-engine_deposit'          : 'admin_loyalty_bonus-engine_deposit',
	'admin_loyalty_bonus-engine_free-bet'         : 'admin_loyalty_bonus-engine_free-bet',
	'admin_loyalty_bonus-engine_free-spins'       : 'admin_loyalty_bonus-engine_free-spins',
	'admin_loyalty_bonus-engine_pack'             : 'admin_loyalty_bonus-engine_pack',
	'admin_loyalty_bonus-engine_registration'     : 'admin_loyalty_bonus-engine_registration',
};

export const createFreeSpinBonusTypes = (permissions, canReadTabList, innerTabs, bonusType, editMode, depositFreeSpin, nextDepositFreeSpin, registrationFreeSpin) => {

	const registration      = canReadTabList && canReadTabList.find(item => item.name === 'registration');
	const deposit           = canReadTabList && canReadTabList.find(item => item.name === 'depositFreeSpin');
	const nextDeposit       = canReadTabList && innerTabs.find(item => item.name === 'freeSpins');

	// showTabFreeSpins ֊ էս էն պայմանն ա , երբ free Spin֊ին tab-ը պտի երևա կախված permissions֊ից ֊֊» registrationFreeSpin֊ի, depositFreeSpin֊ի, nextDepositFreeSpin֊ի գոնե մեկի 'create', 'delete', 'read', 'update' -ի դեպքում
	const showTabFreeSpins  = (permissions.isSA || [
		(registration       && CRUD_OPTIONS.some(crud => registration[crud])),
		(deposit            && CRUD_OPTIONS.some(crud => deposit[crud])),
		(nextDeposit        && CRUD_OPTIONS.some(crud => nextDeposit[crud])),
	].some(bool => bool)) && (!editMode || (bonusType === depositFreeSpin || bonusType === registrationFreeSpin || bonusType === nextDepositFreeSpin));

	//freeSpinBonusTypes ֊ էս  free Spin tab-ում bonus type select֊ի համար ա , թե որ option-ները երևան կախված permissions֊ի update-ից , եթե չկա update permissions ոչ  մեկի համար, կվերադարձնենք null select֊ի desable համար
	const freeSpinBonusTypes = (permissions.isSA || registration?.create || deposit?.create || nextDeposit?.create)
		? {
			registrationFreeSpin: permissions.isSA || registration?.create,
			depositFreeSpin     : permissions.isSA || nextDeposit?.create,
			nextDepositFreeSpin : permissions.isSA || nextDeposit?.create,
		}
		: null;

	return {
		freeSpinBonusTypes,
		showTabFreeSpins,
	};
};

export const createPackPermissions = (permissions, innerTabs) => {
	if (permissions.isSA) {
		return permissions;
	}
	return innerTabs.find(item => item.name === 'pack');
};
// const showTabFreeSpins      = (permissions.isSA || (freeSpinBonusTypes.depositFreeSpin || freeSpinBonusTypes.nextDepositFreeSpin || freeSpinBonusTypes.registrationFreeSpin)) && (!editMode || (bonusType === depositFreeSpin || bonusType === registrationFreeSpin || bonusType === nextDepositFreeSpin));

export const wayInfo = <IntlMessages id = "bonuses.nextDeposit.wayInfo" />;

export const IconLabel = ({ name, icon, text, placement }) => {
	return (
		<Tooltip title={text} placement={placement}>
			<div>{name}</div>
			<div style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5em' }}>
				{icon}
			</div>
		</Tooltip>
	);
};

IconLabel.propTypes = {
	name     : PropTypes.string.isRequired,
	icon     : PropTypes.element,
	text     : PropTypes.string.isRequired,
	placement: PropTypes.string,
};

IconLabel.defaultProps = {
	placement: 'top',
	icon     : <InfoCircleTwoTone />,
};
