import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({

	baseData    : {},
	settingsData: {},
	UI          : {
		modalVisible          : false,
		loading               : false,
		isChangedSettings     : false,
		isChangedDynamicLimits: false,
	},

	commonData: {
		marketTradingModeID: 0,
	},

	historyData       : [],
	historyDataLoading: false,
});

export default function selectionReducer(state = initState, action) {

	switch (action.type) {

	case actions.EVENT_MARKET_SELECTION_BASE_DATA_REFRESH: {
		return state.set('baseData', action.data);
	}

	case actions.EVENT_MARKET_SELECTION_SETTINGS_DATA_REFRESH: {
		return state.set('settingsData', action.data);
	}

	case actions.EVENT_MARKET_SELECTION_SET_VALUE_UI: {

		const { valueName, value } = action.data;
		const UI                   = new Map(state.get('UI')).toObject();
		UI[valueName]              = value;

		return state.set('UI', UI);
	}

	case actions.EVENT_MARKET_SELECTION_COMMON_DATA_SET_VALUE: {

		const { valueName, value } = action.data;
		const commonData           = new Map(state.get('commonData')).toObject();
		commonData[valueName]      = value;

		return state.set('commonData', commonData);
	}

	case actions.EVENT_MARKET_SELECTION_DATA_RESET: {
		return initState;
	}

	case actions.EVENT_MARKET_SELECTION_HISTORY_REFRESH: {
		const { data } = action;
		return state.set('historyData', data);
	}

	case actions.EVENT_MARKET_SELECTION_SET_HISTORY_VALUE_UI: {
		const { historyDataLoading } = action.data;
		return state.set('historyDataLoading', historyDataLoading);
	}

	default:
		return state;
	}
}
