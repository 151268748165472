import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import notifications from '../../../../helpers/notifications';
import { categoriesAPI } from '../../../../helpers/api/categories';

import actions from './actions';
import {
	adaptMappingList,
	adaptUnmappedList,
} from './utils';


const prefix = 'categories.mapping';

const messages = {
	loadMappingList     : `${prefix}.loadMappingList`,
	loadUnmappedList    : `${prefix}.loadUnmappedList`,
	successMappingUpdate: `${prefix}.successMappingUpdate`,
	errorMappingUpdate  : `${prefix}.errorMappingUpdate`,
};


function getStoreData({ Sport: { Categories } }) {
	return {
		mappingList       : Categories.Mapping.get('mappingList'),
		modalUI           : Categories.Mapping.get('modalUI'),
		selectedProviderID: Categories.Mapping.get('selectedProviderID'),
	};
}

function* mappingListReload() {

	yield takeEvery(actions.CATEGORY_MAPPING_LIST_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { providerID, categoryID } = action.data;
		const { modalUI } = yield select(getStoreData);
		const { categoryName } = modalUI;

		let mappingList = [];
		try {
			const res = yield call(categoriesAPI.getCategoryMappingsByProviderId, categoryID, providerID);
			if (res && res.status === 200) {
				mappingList = adaptMappingList(res.data.data, categoryName);
			}

		} catch (error) {
			notifications.showError(messages.loadMappingList, error);
			console.log(error);
		}

		yield put(actions.mappingListRefresh(mappingList));
		yield put(actions.modalUIRefresh({ loading: false }));
	});
}

function* unmappedListReload() {

	yield takeEvery(actions.CATEGORY_UNMAPPED_LIST_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { typeID, providerID, sportID } = action.data;

		let unmappedList = [];
		try {
			const res = yield call(categoriesAPI.getUnmappedCategoriesByProviderIdAndTypeId, providerID, typeID, sportID);
			if (res && res.status === 200) {
				unmappedList = adaptUnmappedList(res.data.data);
			}

		} catch (error) {
			notifications.showError(messages.loadUnmappedList, error);
			console.log(error);
		}

		yield put(actions.unmappedListRefresh(unmappedList));
		yield put(actions.modalUIRefresh({ loading: false }));
	});
}

function* dataSave() {

	yield takeEvery(actions.CATEGORY_MAPPING_LIST_SAVE, function* () {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { mappingList, modalUI, selectedProviderID } = yield select(getStoreData);
		const { typeID, categoryID, sportID, closeModal } = modalUI;

		let isError = false;
		try {

			const preparedData = mappingList.map(item => {
				return item.providerID;
			});

			const res = yield call(categoriesAPI.mapCategory, categoryID, selectedProviderID, preparedData, typeID);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successMappingUpdate);
				yield put(actions.modalUIRefresh({ isChanged: false }));
			}

		} catch (error) {
			isError = true;
			notifications.showError(messages.errorMappingUpdate, error);
			console.log(error);
		}

		// reload data
		if (!closeModal && !isError) {
			yield put(actions.mappingListReload(selectedProviderID, categoryID));
			yield put(actions.unmappedListReload(typeID, selectedProviderID, sportID));
		}

		yield put(actions.modalUIRefresh({
			loading: false,
			visible: !(closeModal && !isError),
		}));
	});
}

export default function* categoryMappingSaga() {
	yield all([
		fork(mappingListReload),
		fork(unmappedListReload),
		fork(dataSave),
	]);
}
