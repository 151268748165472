const prefix = 'SMS_GATEWAYS_';

export const actions = {

	RELOAD        : `${prefix}RELOAD`,
	LIST_REFRESH  : `${prefix}LIST_REFRESH`,
	ENTITY_REFRESH: `${prefix}ENTITY_REFRESH`,

	GET_BY_ID: `${prefix}GET_BY_ID`,
	UPDATE   : `${prefix}UPDATE`,

	DELETE_BY_ID: `${prefix}DELETE_BY_ID`,

	BASE_DATA_REFRESH: `${prefix}BASE_DATA_REFRESH`,
	UI_REFRESH       : `${prefix}UI_REFRESH`,

	DATA_SAVE : `${prefix}DATA_SAVE`,
	DATA_RESET: `${prefix}DATA_RESET`,

	SEND_TEST_MASSAGE: `${prefix}SEND_TEST_MASSAGE`,

	dataReload: data => ({
		type: actions.RELOAD,
		data,
	}),

	getById: id => ({
		type: actions.GET_BY_ID,
		id,
	}),

	deleteById: id => ({
		type: actions.DELETE_BY_ID,
		id,
	}),

	listRefresh: data => ({
		type: actions.LIST_REFRESH,
		data,
	}),

	entityRefresh: data => ({
		type: actions.ENTITY_REFRESH,
		data,
	}),

	baseDataRefresh: data => ({
		type: actions.BASE_DATA_REFRESH,
		data,
	}),

	uiRefresh: data => ({
		type: actions.UI_REFRESH,
		data,
	}),

	dataSave: data => ({
		type: actions.DATA_SAVE,
		data,
	}),

	dataReset: () => ({
		type: actions.DATA_RESET,
	}),

	testMessageActon: data => ({
		type: actions.SEND_TEST_MASSAGE,
		data,
	}),

};
