import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Popover, Tooltip } from 'antd';
import { connect } from 'react-redux';

import notificationsActions from '../../../redux/notifications/actions';
import socketActions from '../../../redux/socket/actions';
import appTabsActions from '../../../redux/appTabs/actions';
import riskActions from '../../../redux/rtm/riskManagement/actions';
import wdActions from '../../../redux/rtm/userWithdrawals/actions';
import dpActions from '../../../redux/rtm/userDeposits/actions';
import casinoActions from '../../../redux/rtm/userCasino/actions';
import userActions from '../../../redux/users/user/actions';
import messagesActions from '../../../redux/notificationCenter/messages/actions';

import { deriveCountByType, riskCount } from '../../../selectors/notifications';
import { NOTIFICATION_TAB_NAMES, NOTIFICATION_TYPES } from '../../../constants/notifications';
import { USER_INFO_VIEW_TABS } from '../../../helpers/commonConstants';

import { icons, hints, detectTypeId } from './assets';
import DropdownContent  from './DropdownContent';
import { getWebsiteID } from '../../../helpers/utility';
import { getNotificationTypeName } from '../../../redux/notifications/utils';

const websiteID = getWebsiteID();
class NotificationBadge extends Component {

	static propTypes = {
		typeID                 : PropTypes.number.isRequired,
		riskCount              : PropTypes.number.isRequired,
		riskTabIndex           : PropTypes.number.isRequired,
		// Redux
		count                  : PropTypes.number.isRequired,
		dataReload             : PropTypes.func.isRequired,
		dataMarkAll            : PropTypes.func.isRequired,
		dataMarkOne            : PropTypes.func.isRequired,
		openTabMessages        : PropTypes.func.isRequired,
		openTabUserInfo        : PropTypes.func.isRequired,
		userSetValueUI         : PropTypes.func.isRequired,
		messagesSelectMail     : PropTypes.func.isRequired,
		openRTMSubModules      : PropTypes.func.isRequired,
		notificationSubscribe  : PropTypes.func.isRequired,
		notificationUnsubscribe: PropTypes.func.isRequired,
		wdFilterRefresh        : PropTypes.func.isRequired,
		wdListReload           : PropTypes.func.isRequired,
		uiRefresh              : PropTypes.func.isRequired,
		dpFilterRefresh        : PropTypes.func.isRequired,
		dpListReload           : PropTypes.func.isRequired,
		casinoFilterRefresh    : PropTypes.func.isRequired,
		casinoListReload       : PropTypes.func.isRequired,
		resetNotifIDs          : PropTypes.func.isRequired,
		hasMoreMerge           : PropTypes.func.isRequired,
		riskFilterRefresh      : PropTypes.func.isRequired,
		riskListReload         : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = { visible: false };

		this.onVisibleChange  = this.onVisibleChange.bind(this);
		this.hide             = this.hide.bind(this);
		this.onClickViewAll   = this.onClickViewAll.bind(this);
		this.onClickItem      = this.onClickItem.bind(this);
		this.getPopupContainer = this.getPopupContainer.bind(this);

		this.isDocuments      = (props.typeID === NOTIFICATION_TYPES.documents);
		this.isMessages       = (props.typeID === NOTIFICATION_TYPES.messages);
		this.isRisk           = (props.typeID === NOTIFICATION_TYPES.riskManagement);
		this.isWithdraw       = (props.typeID === NOTIFICATION_TYPES.withdrawal);
		this.isDeposits       = (props.typeID === NOTIFICATION_TYPES.deposits);
		this.isCasino         = (props.typeID === NOTIFICATION_TYPES.casino);

		this.containerID = `notif-${props.typeID}`;
	}

	getPopupContainer() {
		return document.getElementById(this.containerID);
	}

	// Events ---------------------------------------------------------------------------------------
	hide() {
		this.setState({ visible: false });
	}

	onVisibleChange() {
		const { typeID, notificationUnsubscribe, uiRefresh, hasMoreMerge } = this.props;
		const { visible } = this.state;
		const newVisible = !visible;

		if (!this.isRisk) notificationUnsubscribe(typeID);
		this.setState({ visible: newVisible });

		uiRefresh({
			visible: newVisible,
			tabName: !visible && NOTIFICATION_TAB_NAMES[typeID],
		});
		if (visible) {
			const sectionName = getNotificationTypeName(typeID);
			const hasMore = {
				[sectionName]: true,
			};
			hasMoreMerge(hasMore);
		}
		this.forceUpdate();
	}

	onClickViewAll() {
		const { typeID, openTabMessages, openRTMSubModules } = this.props;
		const { messages, documents, riskManagement, bets, casino, deposits, withdrawal } = NOTIFICATION_TYPES;

		this.setState({ visible: false });

		switch (typeID) {
		case riskManagement: {
			openRTMSubModules('risk-alerts', 'rtmRiskManagement');
			break;
		}
		case documents: {
			openRTMSubModules('documents', 'rtmDocuments');
			break;
		}
		case bets: {
			openRTMSubModules('bets', 'rtmBets');
			break;
		}
		case casino: {
			openRTMSubModules('casino', 'rtmCasino');
			break;
		}
		case deposits: {
			openRTMSubModules('deposits', 'rtmDeposits');
			break;
		}
		case withdrawal: {
			openRTMSubModules('withdrawals', 'rtmWithdrawals');
			break;
		}
		case messages: {
			openTabMessages();
			break;
		}
		default:
		}
	}

	onClickItem(userID, id, itemID, priority, itemIdByType) {
		const {
			typeID,
			userSetValueUI,
			openTabUserInfo,
			messagesSelectMail,
			openTabMessages,
			dataMarkOne,
			wdFilterRefresh,
			wdListReload,
			dpFilterRefresh,
			dpListReload,
			casinoFilterRefresh,
			casinoListReload,
			riskFilterRefresh,
			riskListReload,
			riskTabIndex,
			notificationUnsubscribe,
		} = this.props;

		const notifTypeID = detectTypeId(typeID, priority);
		dataMarkOne(notifTypeID, id, +websiteID);

		if (!this.isRisk && !this.isDocuments && !this.isMessages && !this.isWithdraw && !this.isDeposits && !this.isCasino) {
			return;
		}

		switch (true) {
		case this.isRisk:
			riskFilterRefresh({ riskID: itemID });
			userSetValueUI('currentTab', USER_INFO_VIEW_TABS.riskManagement);
			openTabUserInfo(userID, itemID);
			riskListReload();
			break;
		case this.isDocuments:
			userSetValueUI('currentTab', USER_INFO_VIEW_TABS.documents);
			openTabUserInfo(userID, itemID);
			break;
		case this.isMessages:
			messagesSelectMail(userID);
			openTabMessages();
			break;
		case this.isWithdraw:
			wdFilterRefresh({ wdID: itemIdByType });
			userSetValueUI('currentTab', USER_INFO_VIEW_TABS.withdrawals);
			openTabUserInfo(userID, true);
			wdListReload();
			break;
		case this.isDeposits:
			dpFilterRefresh({ depositID: itemIdByType });
			userSetValueUI('currentTab', USER_INFO_VIEW_TABS.deposits);
			openTabUserInfo(userID, true);
			dpListReload();
			break;
		case this.isCasino:
			casinoFilterRefresh({ transactionID: itemIdByType });
			userSetValueUI('currentTab', USER_INFO_VIEW_TABS.casino);
			openTabUserInfo(userID, true);
			casinoListReload();
			break;
		default:
			break;
		}

		const currentTab = +riskTabIndex;
		const type = detectTypeId(typeID, currentTab);
		notificationUnsubscribe(type);

		this.setState({ visible: false });
	}

	// Renders --------------------------------------------------------------------------------------
	render() {
		const { typeID, count, riskCount, uiRefresh, dataReload, notificationSubscribe, notificationUnsubscribe, resetNotifIDs, dataMarkAll } = this.props;

		const { visible } = this.state;
		const icon        = icons[typeID];
		const countRender = (count < 100) ? count : '99+';
		const riskCounts  = riskCount < 100 ? riskCount : '99+';
		const containerID = `notifications-list-${typeID}`;

		const hint        = this.isRisk ? `${hints[typeID]}: ${riskCount}` : `${hints[typeID]}: ${count}`;
		const showCount   = this.isRisk ? riskCount > 0 : (count > 0);
		const counts		= this.isRisk ? riskCounts : countRender;

		const dropdownContent = (
			<DropdownContent
				typeID                  = {typeID}
				containerID             = {containerID}
				onClickItem             = {this.onClickItem}
				onClickViewAll          = {this.onClickViewAll}
				dataReload              = {dataReload}
				visible                 = {visible}
				notificationSubscribe   = {notificationSubscribe}
				notificationUnsubscribe = {notificationUnsubscribe}
				resetNotifIDs           = {resetNotifIDs}
				uiRefresh               = {uiRefresh}
				dataMarkAll             = {dataMarkAll}
				websiteID               = {+websiteID}
			/>
		);
		return (
			<Popover
				content={dropdownContent}
				trigger="click"
				onOpenChange={this.onVisibleChange}
				placement="bottomLeft"
				destroyTooltipOnHide={true}
				getPopupContainer={this.getPopupContainer}
				overlayInnerStyle={{ background: 'none', boxShadow: 'none' }}
				open={visible}
			>
				<div className="isoIconWrapper">
					<Tooltip placement="bottom" title={hint}>
						<div title="">
							{icon}
						</div>
					</Tooltip>
					{showCount && <span className='count'>{counts}</span>}
				</div>
			</Popover>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { typeID } = props;
	const { riskTabIndex } = state.Notifications.get('UI');
	return {
		count    : deriveCountByType(typeID)(state),
		riskCount: riskCount(state),
		riskTabIndex,
	};
};

const mapDispatchToprops = {
	dataReload   : notificationsActions.dataReload,
	resetNotifIDs: notificationsActions.resetNotifIDs,
	dataMarkAll  : notificationsActions.dataMarkAll,
	dataMarkOne  : notificationsActions.dataMarkOne,
	uiRefresh    : notificationsActions.uiRefresh,
	hasMoreMerge : notificationsActions.hasMoreMerge,

	openRTMSubModules: appTabsActions.openRTMSubModules,
	openTabMessages  : appTabsActions.openTabMessages,
	openTabUserInfo  : appTabsActions.openTabUserInfo,

	userSetValueUI         : userActions.setValueUI,
	messagesSelectMail     : messagesActions.selectMail,
	notificationSubscribe  : socketActions.notificationSubscribe,
	notificationUnsubscribe: socketActions.notificationUnsubscribe,

	riskFilterRefresh: riskActions.filterRefresh,
	riskListReload   : riskActions.listReload,

	wdFilterRefresh: wdActions.filterRefresh,
	wdListReload   : wdActions.listReload,

	dpFilterRefresh: dpActions.filterRefresh,
	dpListReload   : dpActions.listReload,

	casinoFilterRefresh: casinoActions.filterRefresh,
	casinoListReload   : casinoActions.listReload,
};

export default connect(
	mapStateToProps,
	mapDispatchToprops
)(NotificationBadge);
