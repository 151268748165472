
import React from 'react';
import PropTypes from 'prop-types';
import toInteger from 'lodash/toInteger';

import { ADDITIONAL_TABS } from '../../../../constants/appTabs';

import TabLink from '../TabLink';
import { NavLink } from 'react-router-dom';

const PersonalLink = ({ userID, type, htmlId, children, ...restProps }) => {
	return  <NavLink
		to={htmlId ? `/dashboard/personal/info/${userID}#${htmlId}` : `/dashboard/personal/info/${userID}`}
	>
		{children}
	</NavLink>
};

PersonalLink.propTypes = {
	userID  : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	type    : PropTypes.oneOf(['normal', 'link']),
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]).isRequired,
	htmlId: PropTypes.string.isRequired,
};

PersonalLink.defaultProps = {
	userID: null,
	type  : 'link',
};

export default PersonalLink;
