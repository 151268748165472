
import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { responsibleGamblingAPI } from '../../../../helpers/api/responsibleGambling';
import actions from './actions';
import { showError } from '../../../../helpers/notifications';

const prefix = 'partner.websites.responsibleGambling';
const messages = {
	errorResponsibleGamblingSettings    : `${prefix}.settings.failed`,
	errorResponsibleGamblingSettingsSave: `${prefix}.settings.save.failed`,
};

function* baseDataReload() {
	yield takeEvery(actions.DATA_RELOAD, function* ({ data }) {
		const { websiteID } = data;

		try {
			const res = yield call(responsibleGamblingAPI.settings, websiteID);
			if (res && res.status === 200) { /* empty */ }
		} catch (error) {
			showError(messages.errorResponsibleGamblingSettings);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* dataSave() {
	yield takeEvery(actions.DATA_SAVE, function* ({ data }) {
		const { websiteID } = data;

		try {
			const res = yield call(responsibleGamblingAPI.saveSettings, websiteID);
			if (res && res.status === 200) { /* empty */ }
		} catch (error) {
			showError(messages.errorResponsibleGamblingSettingsSave);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}


export default function* responsibleGamblingSaga() {
	yield all([
		fork(baseDataReload),
		fork(dataSave),
	]);
}
