import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';
import externalSaga from './externalSaga';
import tableActions from '../../tables/actions';

import { getListParams, adaptDepositList } from './utils';

import { depositsAPI } from '../../../helpers/api/deposits';
import { depositsAPI as socketsDepositsAPI } from '../../../helpers/sockets/api/deposits';
import { getHeadersTotalCount } from '../../../helpers/utils';
import notifications from '../../../helpers/notifications';
import { createError } from '../../../helpers/sockets/socket';
import { deriveTablePagination } from '../../../selectors/tables';
import { TABLE_TYPES } from '../../../constants/tableTypes';
import socetActions from '../../socket/actions';

const prefix = 'rtm.deposits';

const messages = {
	errorListReload       : `${prefix}.errorListReload`,
	errorSubscribeUpdate  : `${prefix}.errorSubscribeUpdate`,
	successSubscribeUpdate: `${prefix}.successSubscribeUpdate`,
	errorGetTable         : `${prefix}.errorGetTable`,
	errorPostTable        : `${prefix}.errorPostTable`,
};

const tableType           = TABLE_TYPES.depositRTM;

function getStoreData(state) {
	const { RTM, Tables, AppTabs } = state;
	return {
		filter     : RTM.Deposits.get('filter'),
		sorting    : Tables.get(tableType).sorting,
		pagination : deriveTablePagination(tableType, state),
		activeTabID: AppTabs.get('activeTabID'),

	};
}

function* listReload() {
	yield takeEvery(actions.RTM_DEPOSITS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination, activeTabID } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);
		let depositIDs = [];
		let entities = {};
		let depositTotals = {};
		let totalCount = 0;
		try {
			const response = yield call(depositsAPI.rtmDepositsList, params);
			if (response && response.status === 200) {
				const result = adaptDepositList(response.data.data);
				depositIDs = result.depositIDs; // eslint-disable-line prefer-destructuring
				entities = result.entities; // eslint-disable-line prefer-destructuring
				depositTotals = result.depositTotals; // eslint-disable-line prefer-destructuring
				totalCount = getHeadersTotalCount(response.headers) || depositIDs.length;

				yield put(actions.dataRefresh(depositIDs, entities, depositTotals));
				yield put(socetActions.rtmSubscribe(activeTabID, depositIDs));

			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			notifications.showError(messages.errorListReload, error);
			console.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {
	yield takeEvery(actions.RTM_DEPOSITS_FILTER_APPLY, function* () {
		yield put(actions.listReload());
	});
}

function* subscribeToUpdate() {
	yield takeEvery(actions.RTM_DEPOSITS_SOCKET_SUBSCRIBE_TO_UPDATE, function* (action) {
		const { depositIDs } = action.data;
		try {
			const response = yield call(socketsDepositsAPI.subscribeToUpdate, depositIDs);
			if (response) {
				if (response.isSuccess) {
					//notifications.showSuccess(messages.successSubscribeUpdate);
				} else {
					const error = createError(response);
					throw error;
				}
			}
		} catch (error) {
			notifications.showError(messages.errorSubscribeUpdate, error);
			console.log(error);
		}
	});
}

export default function* rtmDepositsSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
		fork(subscribeToUpdate),

		fork(externalSaga),
	]);
}
