import { getCurrentLanguage } from '../../containers/Topbar/LanguageSwitcher/config';

const actions = {
	CHANGE_LANGUAGE        : 'CHANGE_LANGUAGE',
	CHANGE_LANGUAGE_SUCCESS: 'CHANGE_LANGUAGE_SUCCESS',
	ACTIVATE_LANG_MODAL    : 'ACTIVATE_LANG_MODAL',
	switchActivation       : () => ({
		type: actions.ACTIVATE_LANG_MODAL,
	}),
	changeLanguage: language => {
		return {
			type    : actions.CHANGE_LANGUAGE,
			language: getCurrentLanguage(language),
		};
	},
};
export default actions;
