import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../../../../helpers/utils';

const initState = new Map({

	listIDs  : [],
	entities : {},
	websiteID: 1,
	UI       : { loading: false },
});

export default function infoPagesListReducer(state = initState, action) {

	switch (action.type) {

	case actions.INFO_PAGES_LIST_REFRESH: {

		const { listIDs, entities } = action.data;
		return state.set('listIDs', listIDs).set('entities', entities);
	}

	case actions.INFO_PAGES_LIST_CHANGE_WEBSITE: {
		const { websiteID } = action.data;
		return state.set('websiteID', websiteID);
	}

	case actions.INFO_PAGES_LIST_UI_REFRESH: {
		const { data } = action;
		const target = state.get('UI');
		const result = fill(data, target, true);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
