import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';

import actions from './actions';
import { fill } from '../../helpers/utils';

const initState = new Map({

	// Keys are cardNumber
	cardData: {
		'0000 0000 0000 0000': {
			usersList: [],
		},
	},

	UI: {
		loading: false,
	},
});

export default function customerCardsReducer(state = initState, action) {

	switch (action.type) {

	case actions.DATA_REFRESH: {
		const { cardNumber, cardData } = action.data;
		const resData = cloneDeep(state.get('cardData'));
		resData[cardNumber] = cardData;

		return state.set('cardData', resData);
	}

	case actions.UI_REFRESH: {
		const { UI } = action.data;
		const target = state.get('UI');
		const result = fill(UI, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
