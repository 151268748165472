import { combineReducers } from 'redux';

import List from './list/reducer';
import Website from './website/reducer';
import Scripts from './scripts/reducer';
import SmsGateways from './smsgateways/reducer';
import ResponsibleGambling from './responsibleGambling/reducer';

export default combineReducers({
	List,
	Website,
	Scripts,
	SmsGateways,
	ResponsibleGambling,
});
