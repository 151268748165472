import { Map } from 'immutable';
import { fill } from '../../../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	baseData: {},

	UI: {
		itemID   : null,
		itemName : null,
		editMode : false,
		loading  : false,
		langID   : null,
		isChanged: false,
	},
});

export default function newsArticleReducer(state = initState, action) {

	switch (action.type) {
	case actions.NEWS_ITEM_BASE_DATA_REFRESH: {
		const target = state.get('baseData');
		const result = fill(action.data, target, true);
		return state.set('baseData', result);
	}

	case actions.NEWS_ITEM_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.NEWS_ITEM_DATA_RESET: {
		return initState;
	}

	default:
		return state;
	}
}
