import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';

import './index.css';
import { connect } from 'react-redux';
import Intl from '../../../utility/intlMessages';
import { NOTIFICATION_TYPES } from '../../../../constants/notifications';
import notificationsActions from '../../../../redux/notifications/actions';

import MessagesList from '../MessagesList/MessagesList';
import { Body, Container, Footer, FooterWrap, Header } from '../NotificationBadge.style';
import { borderClass, detectTypeId, lang, titleIDs, TabNameWithCount, riskColors, createContainerID } from '../assets';
import Tabs, { TabPane } from '../../../uielements/tabs';
import { RISK_MANAGEMENT_PRIORITY_TYPES } from '../../../../helpers/commonConstants';
import { deriveNotificationsList } from '../../../../selectors/notifications';

const DropdownContent = ({ riskTabIndex, uiRefresh, typeID, containerID, onClickItem, onClickViewAll, dataReload, dataReset, resetNotifIDs, riskDataReset, visible, notificationUnsubscribe, count, dataMarkAll, websiteID, notificationsList }) => {

	const { riskManagement, messages }	= NOTIFICATION_TYPES;
	const { high, medium, low }			= RISK_MANAGEMENT_PRIORITY_TYPES;
	const titleID						= titleIDs[typeID];
	const tabVersion					= (typeID === riskManagement);
	const disabled						= (!notificationsList.length);
	const messagesList = () => {
		const customContainerID = createContainerID(containerID, tabVersion);
		const notifTypeID = detectTypeId(typeID, +riskTabIndex);
		return (
			<Body id={customContainerID}>
				<MessagesList
					notificationsList={notificationsList}
					typeID={notifTypeID}
					containerID={customContainerID}
					onClickItem={onClickItem}
				/>
			</Body>
		);
	};

	const onChangeTab = (tab) => {
		resetNotifIDs();
		const prevType = detectTypeId(typeID, +riskTabIndex);
		notificationUnsubscribe(prevType);

		const type = detectTypeId(typeID, +tab);
		uiRefresh({ riskTabIndex: +tab });
		dataReload(type);
		riskDataReset(type, tab);
	};

	const onClickFooterViewAll = () => {
		if (disabled) return;
		onClickViewAll();
	};
	
	const onClickDataMarkAll = () => {
		if (disabled) return;
		const notifTypeID = detectTypeId(typeID, +riskTabIndex);
		dataMarkAll(notifTypeID, websiteID);
	};
	
	const renderTabs = () => {
		const { l, m, h } = riskColors;
		return (
			<Tabs
				defaultKey={high}
				type="line"
				className={`tabs ${borderClass[riskTabIndex]}`}
				onTabClick={onChangeTab}
				tabBarStyle={{ margin:'0 auto' }}
			>
				<TabPane tab={<TabNameWithCount color={h} name={lang.high} count={count.riskAlertHigh} />} key={high}>
					{messagesList()}
				</TabPane>
				<TabPane tab={<TabNameWithCount color={m} name={lang.medium} count={count.riskAlertMedium} />} key={medium}>
					{messagesList()}
				</TabPane>
				<TabPane tab={<TabNameWithCount color={l} name={lang.low} count={count.riskAlertLow} />} key={low}>
					{messagesList()}
				</TabPane>
			</Tabs>
		);
	};

	useEffect(() => {
		const currentTab = +riskTabIndex;
		const type = detectTypeId(typeID, currentTab);
		if (!visible) {
			resetNotifIDs();
			notificationUnsubscribe(type);
			riskDataReset(type, currentTab, 'closeModal');
			dataReset(type);
			return;
		}
		dataReload(type);
	}, [visible]);

	return (
		<Container>
			<Header> <Intl id={titleID} /> </Header>
			{ !tabVersion ? messagesList() : renderTabs() }
			<FooterWrap>
				<Footer disabled={disabled} onClick={onClickFooterViewAll}>{lang.viewAll} </Footer>
				{typeID !== messages && <Footer disabled={disabled} onClick={onClickDataMarkAll}> {lang.markAsRead} </Footer>}
			</FooterWrap>
		</Container>
	);
};

DropdownContent.propTypes = {
	riskTabIndex: PropTypes.number.isRequired,
	typeID   			: PropTypes.number.isRequired,
	websiteID			: PropTypes.number.isRequired,
	containerID	: PropTypes.string.isRequired,
	visible					: PropTypes.bool.isRequired,

	notificationsList: PropTypes.array.isRequired,
	count         			: PropTypes.object.isRequired,

	onClickItem         			: PropTypes.func.isRequired,
	onClickViewAll      			: PropTypes.func.isRequired,
	dataReload         				: PropTypes.func.isRequired,
	notificationUnsubscribe: PropTypes.func.isRequired,
	riskDataReset       			: PropTypes.func.isRequired,
	uiRefresh          				: PropTypes.func.isRequired,
	resetNotifIDs       			: PropTypes.func.isRequired,
	dataReset          				: PropTypes.func.isRequired,
	dataMarkAll        				: PropTypes.func.isRequired,
};
const mapStateToProps = (state, { typeID }) => {

	const { riskTabIndex } = state.Notifications.get('UI');
	const notifTypeID = detectTypeId(typeID, +riskTabIndex);

	return {
		count        				: state.Notifications.get('count'),
		notificationsList: deriveNotificationsList(notifTypeID)(state),
		riskTabIndex,
	};
};

export default connect(mapStateToProps, {
	riskDataReset: notificationsActions.riskDataReset,
	dataReset 			: notificationsActions.dataReset,
})(DropdownContent);
