import { normalize, schema } from 'normalizr';
import { isArray } from 'lodash';
import dayjs from 'dayjs';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import Formatter from '../../../../helpers/formatter';

import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';
import { adaptHistoryList } from '../utils';

export const fields = {
	id                : 'id',
	ggrLimit          : 'ggr_limit',            // array of obj;
	minGGR            : 'min_ggr',              // array of obj;
	startDate         : 'start_date',           // Date;
	endDate           : 'end_date',             // Date;
	settlementDate    : 'settlement_date',      // Date;
	statusID          : 'status_id',            // number;
	ggrPercent        : 'ggr_percent',          // number
	casinoGameIDs     : 'casino_game_ids',      // array of numbers;
	casinoProviderIDs : 'casino_provider_ids',  // array of numbers;
	parentID          : 'parent_id',            // number;
	recurring         : 'recurring',            // boolean;
	period            : 'period',               // number;
	subCashBacks      : 'sub_cash_backs',       // number;
	wageringTurnover  : 'wagering_turnover',    // number;
	daysToExpire      : 'days_to_expire',       // number;
	walletType        : 'wallet_type',          // number;
	websiteID         : 'website_id',           // number;
	//ggrLimit and minGGR
	currencyID        : 'currency_id',          // number;
	limit             : 'limit',                // number;
	//filter
	startDateFrom     : 'start_date_from',      // Date;
	startDateTo       : 'start_date_to',        // Date;
	endDateFrom       : 'end_date_from',        // Date;
	endDateTo         : 'end_date_to',          // Date;
	settlementDateFrom: 'settlement_date_from', // Date;
	settlementDateTo  : 'settlement_date_to',   // Date;
	ggrPercentFrom    : 'ggr_percent_from',     // number;
	ggrPercentTo      : 'ggr_percent_to',       // number;
	affiliateReference: 'affiliate_reference',  // array;
	casinoGameIds     : 'casino_game_ids',  // array;
	provider          : 'provider',  // array;
};

const listAdapter = createListAdapter();
const limitAdapter = createLimitAdapter();

export function getListParams(filter, sorting, pagination) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);
	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'id', fields.id);
	builder.addField(rules.isID, 'statusID', fields.statusID);

	builder.addField(rules.isBoolean, 'recurring', fields.recurring);

	builder.addField(rules.isString, 'casinoGameIDs', fields.casinoGameIDs);
	builder.addField(rules.isString, 'casinoProviderIDs', fields.casinoProviderIDs);

	builder.addRangeField(rules.isDateTimeRange, filter.startDate, [
		fields.startDateFrom,
		fields.startDateTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.endDate, [
		fields.endDateFrom,
		fields.endDateTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.settlementDate, [
		fields.settlementDateFrom,
		fields.settlementDateTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.ggrPercent, [
		fields.ggrPercentFrom,
		fields.ggrPercentTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------
export function adaptList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.settlementDelay = ((dayjs(item.settlementDate) - dayjs(item.endDate) ) / 3600000);
		item.ggrLimit = limitAdapter.adaptList(item.ggrLimit);
		item.minGGR = limitAdapter.adaptList(item.minGGR);
	});

	const tempData = {
		items: adaptedData,
	};

	const item           = new schema.Entity('items', {}, { idAttribute: 'id' });
	const itemsSchema    = { items: [item] };
	const normalizedData = normalize(tempData, itemsSchema);
	const entities       = normalizedData.entities.items || {};

	return entities;
}

export function adaptSubList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}
	rawData.forEach(data => {
		data.recurring = false;
		data.period = null;
	});
	rawData.sort((itemA, itemB) => {
		return itemA.start_date > itemB.start_date ? 1 : -1;
	});

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.ggrLimit = limitAdapter.adaptList(item.ggrLimit);
		item.minGGR = limitAdapter.adaptList(item.minGGR);
	});

	return adaptedData;
}

// Prepare ----------------------------------------------------------------------------------------
export function prepareBonusData(rawData = {}) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	if (rawData.subCashBacks && rawData.subCashBacks.length) {
		const preparedSubCashbacks = rawData.subCashBacks.map(sub => {
			sub.ggrLimit = limitAdapter.prepareList(sub.ggrLimit);
			sub.minGGR = limitAdapter.prepareList(sub.minGGR);
			const preparedSub = listAdapter.prepare(sub);
			if (isID(sub.id)) {
				preparedSub.id = sub.id;
			}
			return preparedSub;
		});
		rawData.subCashBacks = preparedSubCashbacks;
	}
	rawData.ggrLimit = limitAdapter.prepareList(rawData.ggrLimit);
	rawData.minGGR = limitAdapter.prepareList(rawData.minGGR);
	const preparedData = listAdapter.prepare(rawData);
	const x = Formatter.fullDateTime(dayjs(preparedData.end_date) + (rawData.settlementDelay * 3600000));
	preparedData.settlement_date = Formatter.isoString(x);
	if (preparedData.sub_cash_backs && preparedData.sub_cash_backs.length) {
		delete preparedData.settlement_date;
		preparedData.ggr_limit = null;
		preparedData.min_ggr = null;
		preparedData.ggr_percent = null;
	}
	if (isID(rawData.id)) {
		preparedData.id = rawData.id;
	}

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.intOrNull, 'id', fields.id);

	adapter.addField(rules.intOrNull, 'statusID', fields.statusID);
	adapter.addField(rules.intOrNull, 'parentID', fields.parentID);
	adapter.addField(rules.intOrNull, 'wageringTurnover', fields.wageringTurnover);
	adapter.addField(rules.intOrNull, 'daysToExpire', fields.daysToExpire);
	adapter.addField(rules.intOrNull, 'walletType', fields.walletType);
	adapter.addField(rules.intOrNull, 'websiteID', fields.websiteID);
	adapter.addField(rules.bool, 'recurring', fields.recurring);

	adapter.addField(rules.arrayObject, 'ggrLimit', fields.ggrLimit);
	adapter.addField(rules.arrayObject, 'minGGR', fields.minGGR);
	adapter.addField(rules.numberOrNull, 'ggrPercent', fields.ggrPercent);
	adapter.addField(rules.intOrNull, 'period', fields.period);

	adapter.addField(rules.arrayID, 'casinoProviderIDs', fields.casinoProviderIDs);
	adapter.addField(rules.arrayID, 'casinoGameIDs', fields.casinoGameIDs);
	adapter.addField(rules.arrayObject, 'subCashBacks', fields.subCashBacks);

	adapter.addField(rules.fullDate, 'startDate', fields.startDate);
	adapter.addField(rules.fullDate, 'endDate', fields.endDate);
	adapter.addField(rules.fullDate, 'settlementDate', fields.settlementDate);
	adapter.addField(rules.fullDate, 'endDate', fields.endDate);
	adapter.addField(rules.arrayString, 'affiliateReference', fields.affiliateReference);


	return adapter;
}

export function createLimitAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.intOrNull, 'currencyID', fields.currencyID);

	adapter.addField(rules.number, 'limit', fields.limit);

	return adapter;
}

export function adaptHistory(rawData) {

	const arrayFields = [
		fields.casinoGameIds,
		fields.casinoProviderIDs,
		fields.affiliateReference,
	];

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id].concat(arrayFields),
	};

	const dataList = adaptHistoryList(rawData, diffOptions, arrayFields);

	return dataList;
}
