import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';
import actions from './actions';
import tableActions from '../../../tables/actions';

import { showError, showSuccess } from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { historyAPI } from '../../../../helpers/api/history';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { deriveTablePagination } from '../../../../selectors/tables';

import {
	getListParams,
	adaptList,
	adaptHistory,
	prepareBonusData,
} from './utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';

const messages = {
	errorListLoad    : 'bonuses.firstDeposit.error.list.load',
	errorDelete      : 'bonuses.firstDeposit.error.delete',
	errorSave        : 'bonuses.firstDeposit.error.save',
	errorHistoryLoad : 'bonuses.firstDeposit.error.history.load',
	errorCurrencyLoad: 'bonuses.firstDeposit.error.currency.load',
	successDelete    : 'bonuses.firstDeposit.success.delete',
	successSave      : 'bonuses.firstDeposit.success.save',
};

const tableType   = TABLE_TYPES.bonusesRirstDeposit;

function getStoreData(state) {
	const { Loyalty: { Bonuses }, Tables, App } = state;
	const { FirstDeposit } = Bonuses;
	const UI = FirstDeposit.get('UI');

	return {
		filter    : FirstDeposit.get('filter'),
		sorting   : Tables.get(tableType).sorting,
		pagination: deriveTablePagination(tableType, state),
		bonusData : FirstDeposit.get('bonusData'),
		editMode  : UI.editMode,
		closeModal: UI.closeModal,
		websiteID : App.get('websiteID'),
	};
}

function* listReload() {

	yield takeEvery(actions.LIST_RELOAD, function* () {

		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);

		let entities   = {};
		let totalCount = 0;
		try {
			const res = yield call(bonusesAPI.firstDepositList, params);
			if (res && res.status === 200) {
				entities   = adaptList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);
			}

		} catch (error) {
			showError(messages.errorListLoad, error);
			console.log(error);
		}

		yield put(actions.listRefresh(entities));
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
	});
}

function* filterApply() {
	yield takeEvery(actions.FILTER_APPLY, function* () {
		yield put(tableActions.paginationRefresh(tableType, { currentPage: 1 }));
		yield put(actions.listReload());
	});
}

function* bonusSave() {

	yield takeEvery(actions.BONUS_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { bonusData, editMode, closeModal, websiteID } = yield select(getStoreData);
		const resData      = cloneDeep(bonusData);
		resData.websiteID  = websiteID;
		const preparedData = prepareBonusData(resData);

		let bonusID = resData.id;
		let isError = false;
		try {
			if (editMode) {
				yield call(bonusesAPI.firstDepositUpdate, bonusID, preparedData);

			} else {
				const res = yield call(bonusesAPI.firstDepositCreate, preparedData);
				bonusID = toInteger(res.data.data.id);
				resData.id = bonusID;

				yield put(actions.dataRefresh(resData));
				yield put(actions.uiRefresh({ editMode: true }));
			}

			showSuccess(messages.successSave);
			yield put(actions.uiRefresh({ isChanged: false }));

		} catch (error) {
			isError = true;
			showError(messages.errorSave, error);
		}

		if (!isError && closeModal) {
			yield put(actions.dataReset());
			yield put(actions.listReload());

		} else if (!isError || !closeModal) {
			yield put(actions.uiRefresh({ loading: false }));
			yield put(actions.listReload());

		} else {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* historyLogsReload() {

	yield takeEvery(actions.HISTORY_LOGS_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { bonusID } = action.data;
		let historyLogs = [];
		try {
			const res = yield call(historyAPI.historyFirstDepositsLogs, bonusID);
			if (res && res.status === 200) {
				historyLogs = adaptHistory(res.data.data);
				yield put(actions.historyLogsRefresh(historyLogs));
			}
		} catch (error) {
			showError(messages.errorHistoryLoad, error);
			console.log(error);
		}

		yield put(actions.historyLogsRefresh(historyLogs));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* firstDepositSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
		fork(bonusSave),
		fork(historyLogsReload),
	]);
}
