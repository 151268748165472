import { all, fork } from 'redux-saga/effects';

import emailsSaga from './emails/emails/saga';
import messagesSaga  from './messages/saga';
import pushNotificationsSaga  from './pushNotifications/saga';
import smsSaga  from './sms/saga';

export default function* () {
	yield all([
		fork(emailsSaga),
		fork(messagesSaga),
		fork(pushNotificationsSaga),
		fork(smsSaga),
	]);
}
