import { normalize, schema } from 'normalizr';
import isArray  from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import toNumber from 'lodash/toNumber';
import merge from 'lodash/merge';

import ParamsBuilder from '../../../helpers/paramsBuilder';
import EntityAdapter from '../../../helpers/entityAdapter';
import FilterComparator from '../../../helpers/filterComparator';
import Formatter from '../../../helpers/formatter';

// import { mockBetsList } from '../../../mocks/rtmBets';

export const fields = {
	// list
	betID        : 'id',
	userID       : 'user_id',
	websiteID    : 'website_id',
	channelID    : 'channel_id',
	userCountryID: 'user_country_id',
	userStatusID : 'user_verification_status_id',
	userGroupID  : 'user_group_id',
	userBetFactor: 'user_bet_factor',
	betNumber    : 'bet_number',
	userName     : 'user_name',
	betCurrencyId: 'bet_currency_id',
	code         : 'code',
	//betPlacementTime                : 'bet_placement_time',
	payout       : 'payout',

	// filter
	websiteIDs                       : 'bet_website_id',
	betChannelIDs                    : 'bet_channel_id',
	selectedSport                    : 'sport_id',
	countryIDs                       : 'country_ids',
	leagueID                         : 'league_ids',
	eventID                          : 'event_id',
	betIPCountry                     : 'bet_ip_country',
	betTypeID                        : 'bet_type_id',
	betTradingModeID                 : 'bet_trading_mode',
	priceProviderTypeID              : 'price_source_type_id',
	priceProviderID                  : 'price_source_id',
	settlementSourceTypeID           : 'settlement_source_type_id',
	settlementProviderID             : 'settlement_source_id',
	betSettlementStatusID            : 'bet_settlement_status_id',
	marketName                       : 'market_name',
	selectionName                    : 'selection_name',
	betIP                            : 'bet_ip',
	userBetFactorFrom                : 'user_bet_factor_from',
	userBetFactorTo                  : 'user_bet_factor_to',
	betTotalPriceFrom                : 'bet_total_price_from',
	betTotalPriceTo                  : 'bet_total_price_to',
	betStakeFrom                     : 'bet_stake_from',
	betStakeTo                       : 'bet_stake_to',
	betStakeUSDFrom                  : 'bet_stake_usd_from',
	betStakeUSDTo                    : 'bet_stake_usd_to',
	maxPossibleWinningFrom           : 'max_possible_winning_from',
	maxPossibleWinningTo             : 'max_possible_winning_to',
	maxPossibleWinningUSDFrom        : 'max_possible_winning_usd_from',
	maxPossibleWinningUSDTo          : 'max_possible_winning_usd_to',
	maxPossiblePayoutFrom            : 'max_possible_payout_from',
	maxPossiblePayoutTo              : 'max_possible_payout_to',
	maxPossiblePayoutUSDFrom         : 'max_possible_payout_usd_from',
	maxPossiblePayoutUSDTo           : 'max_possible_payout_usd_to',
	betPlacementTimeFrom             : 'bet_placement_time_from',
	betPlacementTimeTo               : 'bet_placement_time_to',
	betSettlementTimeFrom            : 'bet_settlement_time_from',
	betSettlementTimeTo              : 'bet_settlement_time_to',
	betSettlementSpeedFrom           : 'bet_settlement_speed_from',
	betSettlementSpeedTo             : 'bet_settlement_speed_to',
	userBalanceBeforeFrom            : 'user_balance_before_from',
	userBalanceBeforeTo              : 'user_balance_before_to',
	userBalanceAfterFrom             : 'user_balance_after_from',
	userBalanceAfterTo               : 'user_balance_after_to',
	userBalanceAfterSettlementFrom   : 'user_balance_after_settlement_from',
	userBalanceAfterSettlementTo     : 'user_balance_after_settlement_to',
	userBalanceBeforeUSDFrom         : 'user_balance_before_usd_from',
	userBalanceBeforeUSDTo           : 'user_balance_before_usd_to',
	userBalanceAfterUSDFrom          : 'user_balance_after_usd_from',
	userBalanceAfterUSDTo            : 'user_balance_after_usd_to',
	userBalanceAfterSettlementUSDFrom: 'user_balance_after_settlement_usd_from',
	userBalanceAfterSettlementUSDTo  : 'user_balance_after_settlement_usd_to',
	betStatusID                      : 'bet_status_id',
	sportId                          : 'sport_id',
	countryId                        : 'country_id',
	leagueId                         : 'league_id',
	marketId                         : 'market_id',
	selectionId                      : 'selection_id',
	currencyID                       : 'currency_id',
	betNumberFrom                    : 'bet_number_from',
	betNumberTo                      : 'bet_number_to',
	dateFrom                         : 'date_from',
	dateTo                           : 'date_to',
	// extra
	betPlacementTime                 : 'bet_placement_time',
	betStake                         : 'bet_stake',
	betStakeUSD                      : 'bet_stake_usd',
	betTotalPrice                    : 'bet_total_price',
	maxPossiblePayout                : 'max_possible_payout',
	maxPossiblePayoutUSD             : 'max_possible_payout_usd',
	maxPossibleWinning               : 'max_possible_winning',
	maxPossibleWinningUSD            : 'max_possible_winning_usd',
	userBalanceBefore                : 'user_balance_before',
	userBalanceAfter                 : 'user_balance_after',
	userBalanceAfterSettlement       : 'user_balance_after_settlement',
	userBalanceBeforeUSD             : 'user_balance_before_usd',
	userBalanceAfterUSD              : 'user_balance_after_usd',
	userBalanceAfterSettlementUSD    : 'user_balance_after_settlement_usd',
	maxCombination                   : 'max_combination',
	minCombination                   : 'min_combination',
	betSettlementSpeed               : 'bet_settlement_speed',
	betSettlementTime                : 'bet_settlement_date',
	betPlacementTimeRelative         : 'bet_placement_time_relative',
	// details
	detailsID                        : 'id',
	details                          : 'details',
	eventName                        : 'event_name',
	sportName                        : 'sport_name',
	countryName                      : 'country_name',
	leagueName                       : 'league_name',
	eventStartingTime                : 'event_starting_time',
	eventStartingTimeFrom            : 'event_starting_time_from',
	eventStartingTimeTo              : 'event_starting_time_to',
	odd                              : 'odd',
	selectionLimit                   : 'selection_limit',
};

const updateFields = {
	// list
	betID           : 'id',
	betTypeID       : 'type_id',
	betTradingModeID: 'trading_mode',
	betStatusID     : 'status_id',
	betStake        : 'stake',
	betStakeUSD     : 'stake_usd',
	betTotalPrice   : 'total_odd',
	// details
	detailsID       : 'id',
	details         : 'details',
	odd             : 'odd',
};

const baseAdapter          = createBaseBetAdapter();
const extraAdapter         = createExtraBetAdapter();
const detailsAdapter       = createDetailsBetAdapter();

const updateBaseAdapter    = createUpdateBaseBetAdapter();
const updateExtraAdapter   = createUpdateExtraBetAdapter();
const updateDetailsAdapter = createUpdateDetailsBetAdapter();

const betComparator        = createBetComparator();
const betDetailComparator  = createBetDetailComparator();

export function getListParams(filter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules   = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isID, 'betID', fields.betID);
	builder.addField(rules.isID, 'userID', fields.userID);
	builder.addField(rules.isID, 'betTradingModeID', fields.betTradingModeID);
	builder.addField(rules.isID, 'priceProviderTypeID', fields.priceProviderTypeID);
	builder.addField(rules.isID, 'settlementSourceTypeID', fields.settlementSourceTypeID);
	builder.addField(rules.isID, 'sportId', fields.sportId);
	builder.addField(rules.isID, 'countryId', fields.countryId);
	builder.addField(rules.isID, 'leagueId', fields.leagueId);
	builder.addField(rules.isID, 'marketId', fields.marketId);
	builder.addField(rules.isID, 'selectionId', fields.selectionId);
	builder.addField(rules.isID, 'currencyID', fields.currencyID);

	builder.addField(rules.isArrayID, 'websiteIDs', fields.websiteIDs);
	builder.addField(rules.isArrayID, 'betChannelIDs', fields.betChannelIDs);
	builder.addField(rules.isArrayID, 'userGroupIDs', fields.userGroupID);
	builder.addField(rules.isArrayID, 'userStatusIDs', fields.userStatusID);
	builder.addField(rules.isID, 'selectedSport', fields.selectedSport);

	builder.addField(rules.isArrayID, 'countryIDs', fields.countryIDs);
	builder.addField(rules.isArrayID, 'leagueIDs', fields.leagueID);
	builder.addField(rules.isArrayID, 'eventIDs', fields.eventID);
	builder.addField(rules.isArrayID, 'betTypeIDs', fields.betTypeID);
	builder.addField(rules.isArrayID, 'priceProviderIDs', fields.priceProviderID);
	builder.addField(rules.isArrayID, 'settlementProviderIDs', fields.settlementProviderID);
	builder.addField(rules.isArrayID, 'betStatusIDs', fields.betStatusID);

	builder.addField(rules.isString, 'userName', fields.userName);
	builder.addField(rules.isString, 'eventName', fields.eventName);
	builder.addField(rules.isString, 'marketName', fields.marketName);
	builder.addField(rules.isString, 'selectionName', fields.selectionName);
	builder.addField(rules.isString, 'betIP', fields.betIP);

	builder.addField(rules.isNumber, 'selectionLimit', fields.selectionLimit);

	builder.addField(rules.isArrayString, 'userCountryIDs', fields.userCountryID);
	builder.addField(rules.isArrayString, 'betIPCountry', fields.betIPCountry);

	builder.addField(rules.isPositiveNumber, 'betPlacementTimeRelative', fields.betPlacementTimeRelative);

	builder.addRangeField(rules.isNumberRange, filter.userBetFactor, [
		fields.userBetFactorFrom,
		fields.userBetFactorTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.betTotalPrice, [
		fields.betTotalPriceFrom,
		fields.betTotalPriceTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.betStake, [
		fields.betStakeFrom,
		fields.betStakeTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.betStakeUSD, [
		fields.betStakeUSDFrom,
		fields.betStakeUSDTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.maxPossibleWinning, [
		fields.maxPossibleWinningFrom,
		fields.maxPossibleWinningTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.maxPossibleWinningUSD, [
		fields.maxPossibleWinningUSDFrom,
		fields.maxPossibleWinningUSDTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.maxPossiblePayout, [
		fields.maxPossiblePayoutFrom,
		fields.maxPossiblePayoutTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.maxPossiblePayoutUSD, [
		fields.maxPossiblePayoutUSDFrom,
		fields.maxPossiblePayoutUSDTo,
	]);

	builder.addRangeField(rules.isNumberRange, filter.userBalanceBefore, [
		fields.userBalanceBeforeFrom,
		fields.userBalanceBeforeTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.userBalanceAfter, [
		fields.userBalanceAfterFrom,
		fields.userBalanceAfterTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.userBalanceAfterSettlement, [
		fields.userBalanceAfterSettlementFrom,
		fields.userBalanceAfterSettlementTo,
	]);

	builder.addRangeField(rules.isNumberRange, filter.userBalanceBeforeUSD, [
		fields.userBalanceBeforeUSDFrom,
		fields.userBalanceBeforeUSDTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.userBalanceAfterUSD, [
		fields.userBalanceAfterUSDFrom,
		fields.userBalanceAfterUSDTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.userBalanceAfterSettlementUSD, [
		fields.userBalanceAfterSettlementUSDFrom,
		fields.userBalanceAfterSettlementUSDTo,
	]);

	builder.addRangeField(rules.isNumberRange, filter.minMaxCombination, [
		fields.minCombination,
		fields.maxCombination,
	]);

	builder.addRangeField(rules.isDateTimeRange, filter.betPlacementTime, [
		fields.betPlacementTimeFrom,
		fields.betPlacementTimeTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.eventStartingTime, [
		fields.eventStartingTimeFrom,
		fields.eventStartingTimeTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.settlementDateTime, [
		fields.betSettlementTimeFrom,
		fields.betSettlementTimeTo,
	]);
	builder.addRangeField(rules.isTimeRange, filter.settlementSpeed, [
		fields.betSettlementSpeedFrom,
		fields.betSettlementSpeedTo,
	]);

	builder.addRangeField(rules.isNumberRange, filter.betNumber, [
		fields.betNumberFrom,
		fields.betNumberTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

export function getEventNameListParams(filter, eventName) {

	const builder = new ParamsBuilder();
	const rules   = builder.RULES;

	builder.addValue('name', eventName);

	builder.addField(rules.isID, 'sportId', fields.sportId);
	builder.addField(rules.isID, 'countryId', fields.countryId);
	builder.addField(rules.isID, 'leagueId', fields.leagueId);

	builder.addRangeField(rules.isDateTimeRange, filter.eventStartingTime, [
		fields.dateFrom,
		fields.dateTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptBetsList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}

	const adaptedData = rawData.map(rawBet => {
		return adaptBet(rawBet);
	});
	// const adaptedData = mockBetsList;

	const tempData = {
		bets: adaptedData,
	};

	const bet = new schema.Entity('bets', {}, { idAttribute: 'betID' });
	const betSchema = { bets: [bet] };

	const normalizedData = normalize(tempData, betSchema);

	const betIDs = normalizedData.result.bets || [];
	const entities = normalizedData.entities.bets || {};

	const result = {
		betIDs,
		entities,
	};

	return result;
}

export function adaptBet(rawData = {}) {
	baseAdapter.clearExcludes();
	extraAdapter.clearExcludes();
	detailsAdapter.clearExcludes();

	const adaptedBet = baseAdapter.adapt(rawData);
	const extra      = extraAdapter.adapt(rawData);
	const details    = detailsAdapter.adaptList(adaptedBet.details);

	adaptedBet.extra   = extra;
	adaptedBet.details = details;

	return adaptedBet;
}

export function adaptBetAfterUpdate(existingBet, rawData = {}) {
	updateBaseAdapter.clearExcludes();
	updateExtraAdapter.clearExcludes();
	updateDetailsAdapter.clearExcludes();

	const adaptedBet = updateBaseAdapter.adapt(rawData);
	const extra = updateExtraAdapter.adapt(rawData);
	const details = updateDetailsAdapter.adaptList(adaptedBet.details);

	const result = merge(existingBet, adaptedBet);
	result.extra = merge(existingBet.extra, extra);

	const resultDetails = [];
	result.details.forEach(detailsItem => {
		const { detailsID } = detailsItem;
		const adaptedDetailsItem = details.find(
			item => item.detailsID === detailsID
		);
		if (adaptedDetailsItem) {
			resultDetails.push(merge(detailsItem, adaptedDetailsItem));
		} else {
			resultDetails.push(detailsItem);
		}
	});

	result.details = resultDetails;

	return result;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareBetForReviewUpdate(betEntity, betStatusID) {
	const details = betEntity.details.map(item => {
		return {
			id : toInteger(item.detailsID),
			odd: Formatter.decimal(toNumber(item.odd)),
		};
	});

	const result = {
		id       : toInteger(betEntity.betID),
		stake    : toNumber(betEntity.extra.betStake),
		status_id: betStatusID,
		details,
	};

	return result;
}

// Compare ----------------------------------------------------------------------------------------

export function compareBetWithFilter(bet, filter) {
	if (!bet) {
		return false;
	}

	const compareBet = {
		...bet,
		...bet.extra,
	};

	const hasMatchesBet = betComparator.compare(compareBet, filter);
	let hasMatchesDetails = false;

	if (!hasMatchesBet && isArray(compareBet.details)) {
		for (let i = 0; i < compareBet.details.lenght; i++) {
			if (hasMatchesDetails) {
				break;
			}
			const detailItem = compareBet.details[i];
			hasMatchesDetails = betDetailComparator.compare(detailItem, filter);
		}
	}

	return hasMatchesBet || hasMatchesDetails;
}

// Adapters ---------------------------------------------------------------------------------------

function createBaseBetAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'betID', fields.betID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'userStatusID', fields.userStatusID);
	adapter.addField(rules.id, 'userGroupID', fields.userGroupID);
	adapter.addField(rules.id, 'betStatusID', fields.betStatusID);
	adapter.addField(rules.id, 'betCurrencyId', fields.betCurrencyId);
	adapter.addField(rules.id, 'betTypeID', fields.betTypeID);
	adapter.addField(rules.id, 'betTradingModeID', fields.betTradingModeID);

	adapter.addField(rules.positiveNumber, 'userBetFactor', fields.userBetFactor);
	adapter.addField(rules.positiveNumber, 'betNumber', fields.betNumber);
	adapter.addField(rules.positiveNumber, 'maxPossiblePayoutUSD', fields.maxPossiblePayoutUSD);
	adapter.addField(rules.positiveNumber, 'maxPossiblePayout', fields.maxPossiblePayout);
	adapter.addField(rules.positiveNumber, 'maxPossibleWinningUSD', fields.maxPossibleWinningUSD);
	adapter.addField(rules.positiveNumber, 'balanceAfterSettlement', fields.balanceAfterSettlement);
	adapter.addField(rules.positiveNumber, 'payout', fields.payout);
	adapter.addField(rules.positiveNumber, 'selectionLimit', fields.selectionLimit);

	adapter.addField(rules.string, 'userName', fields.userName);
	adapter.addField(rules.string, 'userCountryID', fields.userCountryID);
	adapter.addField(rules.string, 'code', fields.code);
	adapter.addField(rules.string, 'betIPCountry', fields.betIPCountry);
	adapter.addField(rules.number, 'betSettlementSpeed', fields.betSettlementSpeed);

	adapter.addField(rules.arrayObject, 'details', fields.details);

	return adapter;
}

function createUpdateBaseBetAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'betID', updateFields.betID);
	adapter.addField(rules.id, 'betStatusID', updateFields.betStatusID);

	adapter.addField(rules.arrayObject, 'details', updateFields.details);

	return adapter;
}

function createExtraBetAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'betTypeID', fields.betTypeID);
	adapter.addField(rules.id, 'betTradingModeID', fields.betTradingModeID);

	adapter.addField(rules.positiveNumber, 'betStake', fields.betStake);
	adapter.addField(rules.positiveNumber, 'betStakeUSD', fields.betStakeUSD);
	adapter.addField(rules.positiveNumber, 'betTotalPrice', fields.betTotalPrice);
	adapter.addField(rules.positiveNumber, 'maxPossiblePayout', fields.maxPossiblePayout);
	adapter.addField(rules.positiveNumber, 'maxPossiblePayoutUSD', fields.maxPossiblePayoutUSD);
	adapter.addField(rules.positiveNumber, 'maxPossibleWinning', fields.maxPossibleWinning);
	adapter.addField(rules.positiveNumber, 'maxPossibleWinningUSD', fields.maxPossibleWinningUSD);

	adapter.addField(rules.number, 'userBalanceBefore', fields.userBalanceBefore);
	adapter.addField(rules.number, 'userBalanceAfter', fields.userBalanceAfter);
	adapter.addField(rules.number, 'userBalanceAfterSettlement', fields.userBalanceAfterSettlement);
	adapter.addField(rules.number, 'userBalanceBeforeUSD', fields.userBalanceBeforeUSD);
	adapter.addField(rules.number, 'userBalanceAfterUSD', fields.userBalanceAfterUSD);
	adapter.addField(rules.number, 'userBalanceAfterSettlementUSD', fields.userBalanceAfterSettlementUSD);

	adapter.addField(rules.string, 'betIP', fields.betIP);
	adapter.addField(rules.string, 'betIPCountry', fields.betIPCountry);

	adapter.addField(rules.dateTime, 'betPlacementTime', fields.betPlacementTime);
	adapter.addField(rules.dateTime, 'betSettlementTime', fields.betSettlementTime);

	return adapter;
}

function createUpdateExtraBetAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'betTypeID', updateFields.betTypeID);
	adapter.addField(rules.id, 'betTradingModeID', updateFields.betTradingModeID);

	adapter.addField(rules.positiveNumber, 'betStake', updateFields.betStake);
	adapter.addField(
		rules.positiveNumber,
		'betStakeUSD',
		updateFields.betStakeUSD
	);
	adapter.addField(
		rules.positiveNumber,
		'betTotalPrice',
		updateFields.betTotalPrice
	);

	return adapter;
}

function createDetailsBetAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'detailsID', fields.detailsID);
	adapter.addField(rules.id, 'countryID', fields.countryID);
	adapter.addField(rules.id, 'eventID', fields.eventID);
	adapter.addField(rules.id, 'leagueID', fields.leagueID);
	adapter.addField(rules.id, 'sportID', fields.sportID);
	adapter.addField(rules.id, 'priceProviderTypeID', fields.priceProviderTypeID);
	adapter.addField(rules.id, 'priceProviderID', fields.priceProviderID);
	adapter.addField(rules.id, 'settlementSourceID', fields.settlementProviderID);
	adapter.addField(rules.id, 'settlementSourceTypeID', fields.settlementSourceTypeID);
	adapter.addField(
		rules.id,
		'betSettlementStatusID',
		fields.betSettlementStatusID
	);

	adapter.addField(rules.string, 'sportName', fields.sportName);
	adapter.addField(rules.string, 'countryName', fields.countryName);
	adapter.addField(rules.string, 'leagueName', fields.leagueName);
	adapter.addField(rules.string, 'eventName', fields.eventName);
	adapter.addField(rules.string, 'marketName', fields.marketName);
	adapter.addField(rules.string, 'selectionName', fields.selectionName);

	adapter.addField(rules.positiveNumber, 'selectionLimit', fields.selectionLimit);

	adapter.addField(rules.positiveNumber, 'odd', fields.odd);
	adapter.addField(
		rules.positiveNumber,
		'betPlacementTimeRelative',
		fields.betPlacementTimeRelative
	);

	adapter.addField(
		rules.fullDate,
		'eventStartingTime',
		fields.eventStartingTime
	);
	adapter.addField(
		rules.positiveNumber,
		'betSettlementSpeed',
		fields.betSettlementSpeed
	);
	adapter.addField(
		rules.fullDate,
		'betSettlementTime',
		fields.betSettlementTime
	);

	adapter.addField(rules.id, 'priceProviderID', fields.priceProviderID);
	adapter.addField(rules.numberOrNull, 'settlementProviderID', fields.settlementProviderID);
	adapter.addField(rules.id, 'betSettlementStatusID', fields.betSettlementStatusID);
	return adapter;
}

function createUpdateDetailsBetAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'detailsID', updateFields.detailsID);
	adapter.addField(rules.positiveNumber, 'odd', updateFields.odd);

	return adapter;
}

function createBetComparator() {
	const comparator = new FilterComparator();
	const rules = comparator.RULES;

	comparator.addField(rules.isEqual, 'betID', 'betID');
	comparator.addField(rules.isEqual, 'userID', 'userID');
	comparator.addField(rules.isEqual, 'userName', 'userName');
	comparator.addField(rules.isEqual, 'betIP', 'betIP');
	comparator.addField(rules.isEqual, 'betTradingModeID', 'betTradingModeID');

	comparator.addField(rules.inArrayID, 'websiteID', 'websiteIDs');
	comparator.addField(rules.inArrayID, 'channelID', 'betChannelIDs');
	comparator.addField(rules.inArrayID, 'userGroupID', 'userGroupIDs');
	comparator.addField(rules.inArrayID, 'userStatusID', 'userStatusIDs');
	comparator.addField(rules.inArrayID, 'betTypeID', 'betTypeIDs');
	comparator.addField(rules.inArrayID, 'betStatusID', 'betStatusIDs');

	comparator.addField(rules.inArrayString, 'userCountryID', 'userCountryIDs');
	comparator.addField(rules.inArrayString, 'betIPCountry', 'betIPCountry');

	comparator.addField(rules.inNumberRange, 'userBetFactor', 'userBetFactor');
	comparator.addField(rules.inNumberRange, 'betTotalPrice', 'betTotalPrice');
	comparator.addField(rules.inNumberRange, 'betStake', 'betStake');
	comparator.addField(rules.inNumberRange, 'betStakeUSD', 'betStakeUSD');
	comparator.addField(
		rules.inNumberRange,
		'maxPossibleWinning',
		'maxPossibleWinning'
	);
	comparator.addField(
		rules.inNumberRange,
		'maxPossibleWinningUSD',
		'maxPossibleWinningUSD'
	);
	comparator.addField(
		rules.inNumberRange,
		'maxPossiblePayout',
		'maxPossiblePayout'
	);
	comparator.addField(
		rules.inNumberRange,
		'maxPossiblePayoutUSD',
		'maxPossiblePayoutUSD'
	);
	comparator.addField(
		rules.inNumberRange,
		'userBalanceBefore',
		'userBalanceBefore'
	);
	comparator.addField(
		rules.inNumberRange,
		'userBalanceAfter',
		'userBalanceAfter'
	);

	comparator.addField(
		rules.inDateRange,
		'betPlacementTime',
		'betPlacementTime'
	);

	return comparator;
}

function createBetDetailComparator() {
	const comparator = new FilterComparator();
	const rules = comparator.RULES;

	comparator.addField(rules.isEqual, 'marketName', 'marketName');
	comparator.addField(rules.isEqual, 'selectionName', 'selectionName');
	comparator.addField(
		rules.isEqual,
		'priceProviderTypeID',
		'priceProviderTypeID'
	);

	comparator.addField(rules.isID, 'sportID', 'sportIDs');
	comparator.addField(rules.inArrayID, 'countryID', 'countryIDs');
	comparator.addField(rules.inArrayID, 'leagueID', 'leagueIDs');
	comparator.addField(rules.inArrayID, 'eventID', 'eventIDs');
	comparator.addField(rules.inArrayID, 'priceProviderID', 'priceProviderIDs');

	comparator.addField(
		rules.inDateRange,
		'eventStartingTime',
		'eventStartingTime'
	);

	return comparator;
}
