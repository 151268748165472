import { normalize, schema } from 'normalizr';
import values from 'lodash/values';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';
import isArray from 'lodash/isArray';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';
import {
	STATISTIC_SORTS,
	SOURCE_TYPES,
} from '../../../../helpers/commonConstants';

export const fields = {
	id             : 'id',
	name           : 'name',
	statistics     : 'statistics',
	scores         : 'scores',
	score          : 'score',
	scopeID        : 'scope_id',
	statisticTypeID: 'statistic_type_id',
	participantID  : 'participant_id',
	orderID        : 'order_id',
	participants   : 'participants',
	sourceID       : 'source_id',
	sourceTypeID   : 'source_type_id',
};

const listAdapter = createListAdapter();
const scoreAdapter = createScoreAdapter();
const scopeListAdapter = createScopeListAdapter();
const statisticTypesListAdapter = createStatisticTypesListAdapter();
const participantListAdapter = createParticipantListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptStatisticsList(rawData = {}) {
	const rawStatistics = rawData[fields.statistics];
	const statisticID = toInteger(rawData.id);
	if (!isArray(rawStatistics)) {
		return {
			statisticID  : null,
			statisticList: [],
		};
	}

	listAdapter.clearExcludes();
	scoreAdapter.clearExcludes();

	const adaptedData = listAdapter.adaptList(rawStatistics);
	adaptedData.forEach(item => {
		item.scores = scoreAdapter.adaptList(item.scores);
	});

	return {
		statisticID,
		statisticList: adaptedData,
	};
}

export function adaptScopes(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	scopeListAdapter.clearExcludes();
	const adaptedData = scopeListAdapter.adaptList(rawData);

	const tempData = {
		scopes: adaptedData,
	};

	const scope = new schema.Entity('scopes', {}, { idAttribute: 'id' });
	const scopeSchema = { scopes: [scope] };

	const normalizedData = normalize(tempData, scopeSchema);

	const scopes = normalizedData.entities.scopes || {};

	return scopes;
}

export function adaptStatisticTypes(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	statisticTypesListAdapter.clearExcludes();
	const adaptedData = statisticTypesListAdapter.adaptList(rawData);

	const tempData = {
		types: adaptedData,
	};

	const type = new schema.Entity('types', {}, { idAttribute: 'id' });
	const typeSchema = { types: [type] };

	const normalizedData = normalize(tempData, typeSchema);

	const types = normalizedData.entities.types || {};

	return types;
}

export function adaptEventParticipants(rawData = {}) {
	participantListAdapter.clearExcludes();

	const adaptedData = participantListAdapter.adapt(rawData);
	const participantList = adaptedData.participants;

	return {
		[STATISTIC_SORTS.home]: participantList[STATISTIC_SORTS.home] || {},
		[STATISTIC_SORTS.away]: participantList[STATISTIC_SORTS.away] || {},
	};
}

export function combineStatisticData({
	statisticList,
	scopes,
	statisticTypes,
	participants,
}) {
	const result = [];

	const scopesList = values(scopes);
	const statisticTypesList = values(statisticTypes);

	scopesList.forEach(scopeItem => {
		const scopeID = scopeItem.id;

		statisticTypesList.forEach(typeItem => {
			const statisticTypeID = typeItem.id;

			const statisticItem = statisticList.find(item => {
				return (
					item.scopeID === scopeID && item.statisticTypeID === statisticTypeID
				);
			});

			if (!statisticItem) {
				result.push(
					createStatisticItem(scopeID, statisticTypeID, participants)
				);
			} else {
				result.push(statisticItem);
			}
		});
	});

	return result;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareStatisticList(statisticID, rawStatisticList, userID) {
	listAdapter.clearExcludes();
	scoreAdapter.clearExcludes();

	const statisticList = cloneDeep(rawStatisticList);

	statisticList.forEach(item => {
		item.scores = scoreAdapter.prepareList(item.scores);
	});

	const preparedList = listAdapter.prepareList(statisticList);

	const result = {
		[fields.sourceID]    : userID,
		[fields.sourceTypeID]: SOURCE_TYPES.manual,
		[fields.statistics]  : preparedList,
	};

	if (isID(statisticID)) {
		result.id = toInteger(statisticID);
	}

	return result;
}

// Adapters ---------------------------------------------------------------------------------------

function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'scopeID', fields.scopeID);
	adapter.addField(rules.id, 'statisticTypeID', fields.statisticTypeID);

	adapter.addField(rules.arrayObject, 'scores', fields.scores);

	return adapter;
}

function createScoreAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'participantID', fields.participantID);
	adapter.addField(rules.positiveNumber, 'score', fields.score);

	return adapter;
}

function createScopeListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}

function createStatisticTypesListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'orderID', fields.orderID);

	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}

function createParticipantListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.arrayID, 'participants', fields.participants);

	return adapter;
}

function createStatisticItem(scopeID, statisticTypeID, participants) {
	const scores = [
		{
			participantID: participants[STATISTIC_SORTS.home],
			score        : null,
		},
		{
			participantID: participants[STATISTIC_SORTS.away],
			score        : null,
		},
	];

	return {
		scopeID,
		statisticTypeID,
		scores,
	};
}
