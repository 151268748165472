import { call, put, takeEvery, all, select, fork } from 'redux-saga/effects';
import actions from './actions';
import notification from '../../../../../helpers/notifications';
import ParamsBuilder from '../../../../../helpers/paramsBuilder';
import {
	fetchMatchesApi,
	addMatchApi,
	removeMatchApi,
	updateMatchApi,
} from '../../../../../helpers/api/matchesOfDay';
import { eventsAPI } from '../../../../../helpers/api/events';
import { adaptMatch, adaptMatchesList, prepareMatch } from './utils';
import { EVENT_STATUS } from '../../../../../constants/events';
import { restoreWebsiteID } from '../../../../../helpers/utility';

const prepareFilter = filter => {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;
	builder.addField(rules.isString, 'name', 'event_name');
	builder.addRangeField(rules.isDateTimeRange, filter.dateRange, [
		'event_start_from',
		'event_start_to',
	]);

	return builder.biuldParams(filter);
};

const prefix = 'landingPage.mathesoftheday';

const messages = {
	errorMatches        : `${prefix}.loading.matches.failed`,
	errorAddMatches     : `${prefix}.adding.match.failed`,
	errorUpdateMatches  : `${prefix}.updating.match.failed`,
	successAddMatches   : `${prefix}.match.added.success`,
	successUpdateMatches: `${prefix}.match.updated.success`,
	successRemoveMatches: `${prefix}.match.removed.success`,
	errorRemoveMatches  : `${prefix}.removing.match.failed`,
	errorLoadMarket     : `${prefix}.loading.event.markets.failed`,
};
function getStoreData({ CMS, App }) {

	return {
		filter     : CMS.LandingPage.MatchesOfDay.Filter.toJS(),
		activeMatch: CMS.LandingPage.MatchesOfDay.List.get('activeMatch'),
		websiteID  : App.get('websiteID'),
	};
}

function* fetchMatchesSaga() {
	yield takeEvery(actions.DAY_MATCHES_FETCH_MATCHES, function* () {
		try {
			const { filter } = yield select(getStoreData);
			const params = prepareFilter(filter);
			const response = yield call(fetchMatchesApi, params);
			if (response && response.status === 200) {
				yield put(actions.fetchMatchesSuccess(adaptMatchesList(response.data.data)));
			}
		} catch (e) {
			notification.showError(messages.errorMatches);
		}
	});
}

function* addMatchSaga() {
	yield takeEvery(actions.DAY_MATCHES_ADD_MATCH, function* () {
		try {

			const { activeMatch } = yield select(getStoreData);
			const websiteID = restoreWebsiteID();
			const match = prepareMatch(activeMatch, false, websiteID);
			const response = yield call(addMatchApi, match);
			if (response && response.status === 200) {
				notification.showSuccess(messages.successAddMatches);
				yield put(actions.toggleUpdating(true));
				yield put(actions.addMatchSuccess(adaptMatch(response.data.data)));
			}
		} catch (e) {
			console.log(e);
			notification.showError(messages.errorAddMatches);
		}
	});
}

function* updateMatchSaga() {
	yield takeEvery(actions.DAY_MATCHES_UPDATE_MATCH, function* () {
		try {
			const { activeMatch, websiteID } = yield select(getStoreData);
			const match = prepareMatch(activeMatch);
			const params = { website_id: websiteID };
			const response = yield call(updateMatchApi, activeMatch.id, match, params);
			if (response && response.status === 200) {
				notification.showSuccess(messages.successUpdateMatches);
				yield put(actions.updateMatchSuccess(adaptMatch(response.data.data)));
			}
		} catch (e) {
			notification.showError(messages.errorUpdateMatches);
		}
	});
}

function* removeMatchSaga() {
	yield takeEvery(actions.DAY_MATCHES_REMOVE_MATCH, function* ({ data }) {
		try {
			const response = yield call(removeMatchApi, data);
			if (response && response.status === 200) {
				notification.showSuccess(messages.successRemoveMatches);
				yield put(actions.removeMatchSuccess(data));
			}
		} catch (e) {
			notification.showError(messages.errorRemoveMatches);
		}
	});
}

function* fetchEventMarketsSaga() {
	yield takeEvery(actions.DAY_MATCHES_FETCH_EVENT_MARKETS, function* ({ data }) {
		try {

			const response = yield call(eventsAPI.eventMarketList, data, true, { status_id: EVENT_STATUS.active });
			if (response && response.status === 200) {
				yield put(actions.fetchEventMarketsSuccess(response.data.data));
			}
		} catch (e) {
			notification.showError(messages.errorLoadMarket);
		}
	});
}

export default function* matchesOfDaySaga() {
	yield all([
		fork(fetchMatchesSaga),
		fork(removeMatchSaga),
		fork(addMatchSaga),
		fork(updateMatchSaga),
		fork(fetchEventMarketsSaga),
	]);
}
