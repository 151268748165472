import { Map } from 'immutable';
import uniq from 'lodash/uniq';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	betIDs                : [],
	entities              : {},
	newBetIDs             : [],
	newBetEntities        : {},
	editedBetIDs          : [],
	editedBetEntities     : {},
	websiteList           : [],
	countryList           : [],
	leagueList            : [],
	filteredLeagues       : [],
	eventList             : [],
	eventNameList         : [],
	loadedCountryParentIDs: [],
	loadedLeagueParentIDs : [],
	loadedEventsParentIDs : [],
	filter                : {},
	UI                    : {
		loading: false,
	},
});

export default function rtmBetsReducer(state = initState, action) {

	switch (action.type) {

	case actions.RTM_BETS_DATA_REFRESH: {
		const { betIDs, entities } = action.data;
		return state.set('betIDs', betIDs).set('entities', entities);
	}

	case actions.RTM_BETS_NEW_BET_LIST_REFRESH: {
		const { newBetIDs, newBetEntities } = action.data;
		return state
			.set('newBetIDs', newBetIDs)
			.set('newBetEntities', newBetEntities);
	}

	case actions.RTM_BETS_EDITED_DATA_REFRESH: {
		const { editedBetIDs, editedBetEntities } = action.data;
		return state
			.set('editedBetIDs', editedBetIDs)
			.set('editedBetEntities', editedBetEntities);
	}

	case actions.RTM_BETS_FILTER_COUNTRY_LIST_REFRESH: {
		return state.set('countryList', action.data);
	}

	case actions.RTM_BETS_FILTER_LEAGUE_LIST_REFRESH: {
		return state.set('leagueList', action.data);
	}

	case actions.RTM_BETS_FILTER_EVENT_LIST_REFRESH: {
		return state.set('eventList', action.data);
	}

	case actions.RTM_BETS_FILTER_EVENT_NAME_LIST_REFRESH: {
		return state.set('eventNameList', action.data);
	}

	case actions.RTM_BETS_FILTER_RESET: {
		return state.set('filter', {});
	}

	case actions.RTM_BETS_FILTER_REFRESH: {
		const target = state.get('filter');
		const result = fill(action.data, target, true);
		return state.set('filter', result);
	}

	case actions.RTM_BETS_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.RTM_BETS_LOADED_COUNTRY_PARENT_ID_REFRESH: {
		const { sportID } = action.data;
		const list = state.get('loadedCountryParentIDs');
		list.push(sportID);

		return state.set('loadedCountryParentIDs', uniq(list));
	}

	case actions.RTM_BETS_LOADED_LEAGUE_PARENT_ID_REFRESH: {
		const { sportID } = action.data;
		const list = state.get('loadedLeagueParentIDs');
		list.push(sportID);

		return state.set('loadedLeagueParentIDs', uniq(list));
	}

	case actions.LEAGUE_FILTERED_LIST: {
		const { filteredLeagues } = action.data;
		return state.set('filteredLeagues', filteredLeagues);
	}

	default:
		return state;
	}
}
