import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import { showError, showSuccess } from '../../helpers/notifications';
import { usersAPI } from '../../helpers/api/users';
import { fill, isEmptyObject } from '../../helpers/utils';

import actions from './actions';
import authActions from '../auth/actions';
import { currencyAPI } from '../../helpers/api/currency';
import { adaptCurrencyList } from '../users/user/utils';
import {
	adaptBaseData,
	prepareBaseData,
	preparePasswordData,
	URL_TYPE,
} from './utils';

const prefix = 'profile';

const messages = {
	errorBaseDataLoad: `${prefix}.errorBaseDataLoad`,
	errorUserUpdate  : `${prefix}.errorUserUpdate`,
	successUserUpdate: `${prefix}.successUserUpdate`,
};
function getStoreData({ Profile, Auth }) {
	const UI = Profile.get('UI');
	const baseData = Profile.get('baseData');
	const user = Auth.get('user');
	const { currencyID } = baseData;
	const { userID, closeModal } = UI;

	return {
		user,
		baseData,
		userID,
		closeModal,
		currencyID,
	};
}

function* baseDataReload() {
	yield takeEvery(actions.PROFILE_BASE_DATA_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const { userID } = action.data;
		const params     = { id: userID };
		let rawBaseData  = {};
		let currencyList;
		try {
			const [res, currency] = yield all([
				call(usersAPI.profileList, params),
				call(currencyAPI.currencyListByAdmin),
			]);
			if (res && res.status === 200 && currency && currency.status === 200) {
				const list = res.data.data;
				if (!isEmptyObject(list)) {
					rawBaseData = list;
				}
				currencyList = adaptCurrencyList(currency.data.data);
			}
		} catch (error) {
			showError(messages.errorBaseDataLoad, error);
			console.log(error);
		}
		const baseData       = adaptBaseData(rawBaseData);
		yield put(actions.profileCurrencyListReload(currencyList));
		yield put(actions.baseDataRefresh(baseData));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* dataSave() {

	yield takeEvery(actions.PROFILE_DATA_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { baseData, userID, closeModal, user, currencyID } = yield select(getStoreData);
		const preparedData = prepareBaseData(baseData);
		preparedData.currency_id = Number(currencyID);
		let isError = false;
		try {
			const res = yield call(usersAPI.updateUserData, userID, preparedData, URL_TYPE.admins);
			if (res && res.status === 200) {
				showSuccess(messages.successUserUpdate);
				const newUser = fill(baseData, user);

				yield put(authActions.userDataRefresh(newUser));
				yield localStorage.setItem('user', JSON.stringify(newUser));
			}

		} catch (error) {
			isError = true;
			showError(messages.errorUserUpdate, error);
			console.log(error);
		}

		if (!isError && !closeModal) {
			yield put(actions.baseDataReload(userID));

		} else if (!isError && closeModal) {
			yield put(actions.dataReset());

		} else {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* changePassword() {

	yield takeEvery(actions.PROFILE_PASSWORD_CHANGE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { baseData, closeModal } = yield select(getStoreData);
		const preparedData                           = preparePasswordData(baseData);

		let isError = false;
		try {
			const res = yield call(usersAPI.userChangePassword, preparedData.oldPassword, preparedData.newPassword);
			if (res && res.status === 200) {
				showSuccess(messages.successUserUpdate);
				// const newUser = fill(baseData, user);

				// yield put(authActions.userDataRefresh(newUser));
				// yield localStorage.setItem('user', JSON.stringify(newUser));
			}

		} catch (error) {
			isError = true;
			showError(messages.errorUserUpdate, error);
			console.log(error);
		}

		if (!isError && !closeModal) {
			// yield put(actions.baseDataReload(userID));

		} else if (!isError && closeModal) {
			yield put(actions.dataReset());

		} else {
			// yield put(actions.uiRefresh({ loading: false }));
		}
		yield put(actions.uiRefresh({ loading: false }));

	});
}

export default function* profileSaga() {
	yield all([
		fork(baseDataReload),
		fork(dataSave),
		fork(changePassword),
	]);
}
