import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	historyLogs: [],

	UI: {
		loading: false,
	},
});

export default function responsibleGambling(state = initState, action) {

	switch (action.type) {

	case actions.UI_REFRESH: {
		const { UI } = action.data;
		const target = state.get('UI');
		const result = fill(UI, target);
		return state.set('UI', result);
	}

	case actions.HISTORY_LOGS_REFRESH: {
		const { historyLogs } = action.data;
		return state.set('historyLogs', historyLogs);
	}

	default:
		return state;
	}
}
