import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';

import { CONTENT_MENU_TYPES } from '../../../../helpers/commonConstants';
import { contentAPI } from '../../../../helpers/api/content';
import notifications from '../../../../helpers/notifications';

import {
	adaptSettingsData,
	prepareSettingsData,
	adaptColumn,
	prepareColumn,
	adaptItem,
	prepareItem,
	prepareColumnsList,
	prepareItemsList,
	adaptRevisionsList,
} from './utils';

const prefix = 'menu.articles';

const messages = {
	errorSettingsLoad   : `${prefix}.loading.settings.failed`,
	errorColumnLoad     : `${prefix}.loading.column.data.failed`,
	errorItemLoad       : `${prefix}.loading.item.data.failed`,
	errorColumnSave     : `${prefix}.saving.column.failed`,
	errorItemSave       : `${prefix}.saving.item.failed`,
	errorItemsOrder     : `${prefix}.updating.items.order.failed`,
	errorColumnsOrder   : `${prefix}.updating.columns.order.failed`,
	errorRevisionsLoad  : `${prefix}.loading.revisions.failed`,
	errorRevisionSave   : `${prefix}.saving.revisions.failed`,
	errorRevisionApply  : `${prefix}.applying.revision.failed`,
	successColumnSave   : `${prefix}.column.saved.success`,
	successColumnsOrder : `${prefix}.columns.order.updated.success`,
	successItemSave     : `${prefix}.item.saved.success`,
	successItemsOrder   : `${prefix}.items.order.updated.success`,
	successRevisionSave : `${prefix}.revision.updated.success`,
	successRevisionApply: `${prefix}.revision.applied.success`,
};

const menuTypeID = CONTENT_MENU_TYPES.articlesMenu;

function getStoreData({ CMS, App }) {

	return {
		columnsIDs       : CMS.Menu.Articles.get('columnsIDs'),
		columnsEntities  : CMS.Menu.Articles.get('columnsEntities'),
		itemsIDs         : CMS.Menu.Articles.get('itemsIDs'),
		itemsEntities    : CMS.Menu.Articles.get('itemsEntities'),
		modalColumnUI    : CMS.Menu.Articles.get('modalColumnUI'),
		modalItemUI      : CMS.Menu.Articles.get('modalItemUI'),
		revisionsEntities: CMS.Menu.Articles.get('revisionsEntities'),
		websiteID        : App.get('websiteID'),
	};
}

function* settingsReload() {

	yield takeEvery(actions.CONTENT_ARTICLES_SETTINGS_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		let columnsIDs      = [];
		let itemsIDs        = [];
		let columnsEntities = {};
		let itemsEntities   = {};
		try {
			const res = yield call(contentAPI.contentMenuLoad, menuTypeID);
			if (res && res.status === 200) {
				const result    = adaptSettingsData(res.data.data);
				columnsIDs      = result.columnsIDs;      // eslint-disable-line prefer-destructuring
				itemsIDs        = result.itemsIDs;        // eslint-disable-line prefer-destructuring
				columnsEntities = result.columnsEntities; // eslint-disable-line prefer-destructuring
				itemsEntities   = result.itemsEntities;   // eslint-disable-line prefer-destructuring
			}
		} catch (error) {
			notifications.showError(messages.errorSettingsLoad, error);
			console.error(error);
		}

		yield put(
			actions.settingsRefresh(
				columnsIDs,
				columnsEntities,
				itemsIDs,
				itemsEntities
			)
		);
		yield put(
			actions.uiRefresh({
				loading      : false,
				isMenuChanged: false,
			})
		);
	});
}

function* columnSave() {

	yield takeEvery(actions.CONTENT_ARTICLES_COLUMN_SAVE, function* () {

		yield put(actions.uiColumnRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const columnsIDs      = cloneDeep(storeData.columnsIDs);
		const columnsEntities = cloneDeep(storeData.columnsEntities);

		const { modalColumnUI, websiteID } = storeData;
		const { editMode, columnItem, closeModal, langID } = modalColumnUI;

		let { columnID } = modalColumnUI;
		let isError = false;

		const preparedData = prepareColumn(columnItem, langID);
		preparedData.website_id = websiteID;

		try {
			if (editMode) {
				const res = yield call(
					contentAPI.contentMenuColumnUpdate,
					columnID,
					preparedData
				);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successColumnSave);
				}
			} else {
				const res = yield call(contentAPI.contentMenuColumnAdd, preparedData);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successColumnSave);
					columnID      = toInteger(res.data.data.id);
					columnItem.id = columnID;
				}
			}
		} catch (error) {
			isError = true;
			notifications.showError(messages.errorColumnSave, error);
			console.error(error);
		}

		if (!isError) {
			columnsEntities[columnID] = columnItem;
			if (!editMode) {
				columnsIDs.push(columnID);
			}
			yield put(actions.columnsListRefresh(columnsIDs, columnsEntities));
			yield put(
				actions.uiRefresh({
					isMenuChanged: true,
					revisionID   : null,
				})
			);
		}

		yield put(
			actions.uiColumnRefresh({
				loading: false,
				visible: !(closeModal && !isError),
			})
		);
	});
}

function* columnReload() {

	yield takeEvery(actions.CONTENT_ARTICLES_COLUMN_RELOAD, function* (action) {

		yield put(actions.uiColumnRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const columnsIDs = cloneDeep(storeData.columnsIDs);
		const columnsEntities = cloneDeep(storeData.columnsEntities);
		const { langID } = storeData.modalColumnUI;

		const { columnID } = action.data;
		const params = { lang_id: langID };

		try {
			const res = yield call(
				contentAPI.contentMenuColumnInfo,
				columnID,
				params
			);
			if (res && res.status === 200) {
				const adaptedData = adaptColumn(res.data.data);
				columnsEntities[columnID] = adaptedData;

				yield put(actions.columnsListRefresh(columnsIDs, columnsEntities));
			}
		} catch (error) {
			notifications.showError(messages.errorColumnLoad, error);
			console.error(error);
		}

		yield put(actions.uiColumnRefresh({ loading: false }));
	});
}

function* columnsOrdersUpdate() {

	yield takeEvery(actions.CONTENT_ARTICLES_COLUMNS_ORDER_UPDATE, function* (action) {

		const { columnsList } = action.data;
		yield put(actions.uiRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const columnsIDs = cloneDeep(storeData.columnsIDs);
		const columnsEntities = cloneDeep(storeData.columnsEntities);

		const preparedData = prepareColumnsList(columnsList);
		preparedData.website_id = storeData.websiteID;

		try {
			const res = yield call(
				contentAPI.contentMenuColumnsOrderUpdate,
				preparedData
			);
			if (res && res.status === 200) {
				columnsList.forEach(item => {
					const columnID = item.id;
					columnsEntities[columnID].orderID = item.orderID;
				});

				yield put(actions.columnsListRefresh(columnsIDs, columnsEntities));
				yield put(
					actions.uiRefresh({
						loading      : false,
						isMenuChanged: true,
						revisionID   : null,
					})
				);

				notifications.showSuccess(messages.successColumnsOrder);
			}
		} catch (error) {
			notifications.showError(messages.errorColumnsOrder, error);
			console.error(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* itemSave() {

	yield takeEvery(actions.CONTENT_ARTICLES_ITEM_SAVE, function* () {

		yield put(actions.uiItemRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const itemsIDs      = cloneDeep(storeData.itemsIDs);
		const itemsEntities = cloneDeep(storeData.itemsEntities);

		const { modalItemUI, websiteID } = storeData;
		const { editMode, item, closeModal, langID } = modalItemUI;

		let { itemID } = modalItemUI;
		let isError = false;

		const preparedData = prepareItem(item, langID);
		preparedData.website_id = websiteID;

		try {
			if (editMode) {
				const res = yield call(contentAPI.contentMenuItemUpdate, itemID, preparedData);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successItemSave);
				}
			} else {
				const res = yield call(contentAPI.contentMenuItemAdd, preparedData);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successItemSave);
					itemID  = toInteger(res.data.data.id);
					item.id = itemID;
				}
			}
		} catch (error) {
			isError = true;
			notifications.showError(messages.errorItemSave, error);
			console.error(error);
		}

		if (!isError) {
			itemsEntities[itemID] = item;
			if (!editMode) {
				itemsIDs.push(itemID);
			}
			yield put(actions.itemsListRefresh(itemsIDs, itemsEntities));
			yield put(actions.uiRefresh({
				isMenuChanged: true,
				revisionID   : null,
			}));
		}

		yield put(actions.uiItemRefresh({
			loading: false,
			visible: !(closeModal && !isError),
		}));
	});
}

function* itemReload() {

	yield takeEvery(actions.CONTENT_ARTICLES_ITEM_RELOAD, function* (action) {

		yield put(actions.uiItemRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const itemsIDs = cloneDeep(storeData.itemsIDs);
		const itemsEntities = cloneDeep(storeData.itemsEntities);
		const { langID } = storeData.modalColumnUI;

		const { itemID } = action.data;
		const params = { lang_id: langID };

		try {
			const res = yield call(contentAPI.contentMenuItemInfo, itemID, params);
			if (res && res.status === 200) {
				const adaptedData = adaptItem(res.data.data);
				itemsEntities[itemID] = adaptedData;

				yield put(actions.itemsListRefresh(itemsIDs, itemsEntities));
			}
		} catch (error) {
			notifications.showError(messages.errorItemLoad, error);
			console.error(error);
		}

		yield put(actions.uiItemRefresh({ loading: false }));
	});
}

function* itemsOrdersUpdate() {

	yield takeEvery(actions.CONTENT_ARTICLES_ITEMS_ORDER_UPDATE, function* (action) {

		const { itemsList } = action.data;
		yield put(actions.uiRefresh({ loading: true }));

		const storeData     = yield select(getStoreData);

		const itemsIDs      = cloneDeep(storeData.itemsIDs);
		const itemsEntities = cloneDeep(storeData.itemsEntities);

		const preparedData  = prepareItemsList(itemsList);
		preparedData.website_id = storeData.websiteID;

		try {
			const res = yield call(contentAPI.contentMenuItemsOrderUpdate, preparedData);
			if (res && res.status === 200) {
				itemsList.forEach(item => {
					const itemID = item.id;
					itemsEntities[itemID].orderID = item.orderID;
					itemsEntities[itemID].colID   = item.colID;
				});

				yield put(actions.itemsListRefresh(itemsIDs, itemsEntities));
				yield put(actions.uiRefresh({
					loading      : false,
					isMenuChanged: true,
					revisionID   : null,
				}));

				notifications.showSuccess(messages.successItemsOrder);
			}

		} catch (error) {
			notifications.showError(messages.errorItemsOrder, error);
			console.error(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* revisionsListReload() {

	yield takeEvery(actions.CONTENT_ARTICLES_REVISIONS_LIST_RELOAD, function* () {

		let revisionsEntities = {};
		let activeRevisionID = null;
		try {
			const res = yield call(contentAPI.contentMenuRevisionsList, menuTypeID);
			if (res && res.status === 200) {
				const result      = adaptRevisionsList(res.data.data);
				revisionsEntities = result.revisionsEntities; // eslint-disable-line prefer-destructuring
				activeRevisionID  = result.activeRevisionID;  // eslint-disable-line prefer-destructuring
			}

		} catch (error) {
			notifications.showError(messages.errorRevisionsLoad, error);
			console.error(error);
		}

		yield put(actions.revisionsListRefresh(revisionsEntities));
		yield put(actions.uiRefresh({ revisionID: activeRevisionID }));
	});
}

function* revisionSave() {

	yield takeEvery(actions.CONTENT_ARTICLES_REVISION_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { columnsEntities, itemsEntities, websiteID } = yield select(getStoreData);
		const preparedData = prepareSettingsData(columnsEntities, itemsEntities);
		preparedData.website_id = websiteID;

		try {
			const res = yield call(contentAPI.contentMenuRevisionAdd, menuTypeID, preparedData);
			if (res && res.status === 200) {
				const revisionID = toInteger(res.data.data.id);

				yield put(actions.revisionsListReload());
				yield put(actions.uiRefresh({
					loading      : false,
					isMenuChanged: false,
					revisionID,
				}));

				notifications.showSuccess(messages.successRevisionSave);
			}

		} catch (error) {
			notifications.showError(messages.errorRevisionSave, error);
			console.error(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* revisionApply() {

	yield takeEvery(actions.CONTENT_ARTICLES_REVISION_APPLY, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { revisionID } = action.data;

		try {
			const res = yield call(contentAPI.contentMenuRevisionApply, menuTypeID, revisionID);
			if (res && res.status === 200) {
				yield put(actions.revisionsListReload());
				yield put(actions.settingsReload());

				notifications.showSuccess(messages.successRevisionApply);
			}

		} catch (error) {
			notifications.showError(messages.errorRevisionApply, error);
			console.error(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

export default function* contentFooterSaga() {
	yield all([
		fork(settingsReload),

		fork(columnSave),
		fork(columnReload),
		fork(columnsOrdersUpdate),

		fork(itemSave),
		fork(itemReload),
		fork(itemsOrdersUpdate),

		fork(revisionsListReload),
		fork(revisionSave),
		fork(revisionApply),
	]);
}
