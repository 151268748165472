import dayjs from 'dayjs';
import { Map } from 'immutable';
import Formatter from '../../../helpers/formatter';
import { fill } from '../../../helpers/utils';
import { DASHBOARD_PERIOD_MODE, DASHBOARD_TABS } from '../../../helpers/commonConstants';
import actions from './actions';

const start  = Formatter.fullDateTime(dayjs().subtract(7, 'days').startOf('day'));
const finish = Formatter.fullDateTime(dayjs().endOf('day'));

const initState = new Map({

	partnersList: [],

	period: {
		mode: DASHBOARD_PERIOD_MODE.lastWeek,
		from: start,
		to  : finish,
	},

	UI: {
		activeTab: DASHBOARD_TABS.players,
		websiteID: 1,
	},
});

export default function dashboardCommonReducer(state = initState, action) {

	switch (action.type) {

	case actions.PARTNERS_LIST_REFRESH: {
		const { partnersList } = action.data;
		return state.set('partnersList', partnersList);
	}

	case actions.PERIOD_REFRESH: {
		const { period } = action.data;
		const target = state.get('period');
		const result = fill(period, target);

		return state.set('period', result);
	}

	case actions.UI_REFRESH: {
		const { UI } = action.data;
		const target = state.get('UI');
		const result = fill(UI, target);

		return state.set('UI', result);
	}

	default:
		return state;
	}
}
