import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../../../../helpers/utils';

const initState = new Map({
	entities: {},

	UI: { loading: false },
});

export default function helpCenterListReducer(state = initState, action) {

	switch (action.type) {

	case actions.TOPICS_LIST_REFRASH: {

		const { entities } = action.data;
		return state.set('entities', entities);
	}

	case actions.TOPICS_LIST_UI_REFRESH: {
		const { data } = action;
		const target = state.get('UI');
		const result = fill(data, target, true);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
