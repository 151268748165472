const actions = {

	RTM_USER_WITHDRAWALS_LIST_RELOAD : 'RTM_USER_WITHDRAWALS_LIST_RELOAD',
	RTM_USER_WITHDRAWALS_DATA_REFRESH: 'RTM_USER_WITHDRAWALS_DATA_REFRESH',

	RTM_USER_WITHDRAWALS_FILTER_REFRESH: 'RTM_USER_WITHDRAWALS_FILTER_REFRESH',
	RTM_USER_WITHDRAWALS_FILTER_APPLY  : 'RTM_USER_WITHDRAWALS_FILTER_APPLY',

	RTM_USER_WITHDRAWALS_UI_REFRESH        : 'RTM_USER_WITHDRAWALS_UI_REFRESH',
	RTM_USER_WITHDRAWALS_PAGINATION_REFRESH: 'RTM_USER_WITHDRAWALS_PAGINATION_REFRESH',

	RTM_USER_WITHDRAWALS_FILTER_RESET: 'RTM_USER_WITHDRAWALS_FILTER_RESET',

	listReload: () => ({
		type: actions.RTM_USER_WITHDRAWALS_LIST_RELOAD,
	}),
	dataRefresh: (withdrawalIDs, entities, withdrawalTotals) => ({
		type: actions.RTM_USER_WITHDRAWALS_DATA_REFRESH,
		data: {
			withdrawalIDs,
			entities,
			withdrawalTotals,
		},
	}),
	filterRefresh: data => ({
		type: actions.RTM_USER_WITHDRAWALS_FILTER_REFRESH,
		data,
	}),
	filterApply: () => ({
		type: actions.RTM_USER_WITHDRAWALS_FILTER_APPLY,
	}),
	uiRefresh: data => ({
		type: actions.RTM_USER_WITHDRAWALS_UI_REFRESH,
		data,
	}),
	paginationRefresh: data => ({
		type: actions.RTM_USER_WITHDRAWALS_PAGINATION_REFRESH,
		data,
	}),
	filterReset: () => ({
		type: actions.RTM_USER_WITHDRAWALS_FILTER_RESET,
	}),
};

export default actions;
