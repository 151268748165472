// in accordance to /CommonJS/src/messaging/CommunicationCodes.ts
export const CommunicationCodes = {
	RTM_GET_BET_SLIPS: 574,
	RTM_GET_BET_SLIP : 575,

	UPDATE_BET_SLIP  : 114,
	BET_REVIEW_UPDATE: 612,
};

export const EVENT_TYPES = {
	betslip               : 5,
	deposit               : 6,
	withdrawal            : 7,
	casino                : 11,
	userDocument          : 12,
	messages              : 14,
	messageRequest        : 15,
	eventMarketsStatistics: 17,
	eventStatistic        : 20,
	document              : 31,
	riskManagement        : 36,
	riskAlertLow          : 37,
	riskAlertMedium       : 38,
	riskAlertHigh         : 39,
	notifBet              : 40,
	notifCasino           : 41,
	notifWithdrawal       : 42,
	notifDeposit          : 43,
};
