import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import { usersAPI } from '../../../helpers/api/users';
import notifications from '../../../helpers/notifications';
import ParamsBuilder from '../../../helpers/paramsBuilder';

import actions from './actions';
import { adaptStatisticData } from './utils';

const prefix = 'user.stats';

const messages = {
	errorLoadStatistic: `${prefix}.errorLoadStatistic`,
};

function getStoreData(state) {
	const UI = state.Users.Statistic.get('UI');
	return {
		UI,
	};
}

function getParams(userID, filter) {
	const builder = new ParamsBuilder();

	builder.addValue('id', userID);
	builder.addValue('date_from', filter.dateFrom);
	builder.addValue('date_to', filter.dateTo);

	const params = builder.biuldParams({});

	return params;
}

function* dataReload() {
	const takeAction = actions.USER_STATISTIC_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		yield put(actions.setValueUI('loading', true));

		const { userID } = action.data;
		let result = {
			betslipTypesList: [],
			marketTypeList  : [],
			countriesList   : [],
			leaguesList     : [],
			sportsList      : [],
			placedAmountList: [],
		};

		const storeData = yield select(getStoreData);

		const params = getParams(userID, storeData.UI);
		try {
			const response = yield call(usersAPI.getUserStatistic, params);
			if (response && response.status === 200) {
				result = adaptStatisticData(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorLoadStatistic, error);
			console.log(error);
		}

		yield put(actions.betslipTypesRefresh(result.betslipTypesList));
		yield put(actions.marketTypesRefresh(result.marketTypeList));
		yield put(actions.countriesListRefresh(result.countriesList));
		yield put(actions.leaguesListRefresh(result.leaguesList));
		yield put(actions.sportsListRefresh(result.sportsList));
		yield put(actions.placedAmountListRefresh(result.placedAmountList));

		yield put(actions.setValueUI('loading', false));
	});
}

export default function* statisticSaga() {
	yield all([fork(dataReload)]);
}
