import find from 'lodash/find';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import EntityAdapter from '../../../../helpers/entityAdapter';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import { isID } from '../../../../helpers/utils';
import { fields, createListAdapter } from '../list/utils';

const listAdapter      = createListAdapter();
const paymentIDAdapter = createPaymentIDAdapter();
const currencyAdapter  = createCurrencyAdapter();
const namesAdapter     = createNamesAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptPayment(rawData = {}) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);

	return adaptedData;
}

export function adaptCurrencyData(rawData = [], currencyList = []) {

	currencyAdapter.clearExcludes();
	paymentIDAdapter.clearExcludes();

	const adaptedList = rawData.map(rawItem => {

		const currency = currencyAdapter.adapt(rawItem.currency);
		const payments = paymentIDAdapter
			.adaptList(rawItem.payments)
			.map(item => item.id);

		return {
			currencyID: currency.id,
			payments,
		};
	});

	const result = currencyList.map(currencyItem => {

		const currencyID = currencyItem.id;
		const adaptedItem = adaptedList.find(item => item.currencyID === currencyID);
		const resultItem = {
			currencyID,
			currencyName: currencyItem.name,
			isChanged   : false,
			payments    : adaptedItem ? adaptedItem.payments : [],
		};

		return resultItem;
	});

	return result;
}

export function adaptNamesData(rawData = [], langList = []) {

	const result = [];

	namesAdapter.clearExcludes();
	const adaptedList = namesAdapter.adaptList(rawData);

	langList.forEach( langItem => {
		const langID   = langItem.id;
		const nameItem = find(adaptedList, { langID }) || { langID, name: '' };

		result.push(nameItem);
	});

	return result;
}

// Prepare ----------------------------------------------------------------------------------------

export function preparePayment(baseData, langID = null, paymentID) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('langID');

	const preparedData = listAdapter.prepare(baseData);
	if (isID(baseData.id)) {
		preparedData.id = toInteger(baseData.id);
	}
	if (isID(langID)) {
		preparedData[fields.langID] = toInteger(langID);
	}

	if (isID(paymentID)) {
		preparedData[fields.paymentID] = toInteger(paymentID);
	}

	return preparedData;
}

export function prepareCurrencyData(rawData = []) {

	if (!isArray(rawData)) {
		return [];
	}

	const result = [];

	rawData.forEach(item => {
		if (!item.isChanged) {
			return;
		}

		result.push({
			[fields.currencyID]: item.currencyID,
			[fields.paymentID] : item.payments,
		});
	});

	return result;
}

export function prepareNames(rawData = [], paymentID = null) {
	if (!isArray(rawData)) {
		return [];
	}

	namesAdapter.clearExcludes();
	namesAdapter.addExcludeField('paymentID');

	const result = [];

	rawData.forEach( item => {
		const preparedItem = namesAdapter.prepare(item);
		preparedItem[fields.paymentID] = paymentID;

		result.push(preparedItem);
	});

	return result;
}

// Adapters ---------------------------------------------------------------------------------------

function createPaymentIDAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);

	return adapter;
}

function createCurrencyAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.arrayObject, 'payments', fields.payments);

	return adapter;
}

function createNamesAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'paymentID', fields.paymentID);
	adapter.addField(rules.string, 'depositName', fields.depositName);
	adapter.addField(rules.string, 'withdrawalName', fields.withdrawalName);
	adapter.addField(rules.string, 'description', fields.description);

	return adapter;
}


export const getParams = (data = {}) => {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;
	builder.addField(rules.isID, 'websiteID', fields.websiteID);
	return builder.biuldParams(data);
};
