import { cloneDeep } from 'lodash';
import { all, call, takeEvery, fork, put, select } from 'redux-saga/effects';
import actions from './actions';
import { usersAPI } from '../../../../../helpers/api/users';
import { adaptBody, createList, prepareLimitsPayments } from './utils';
import notifications, { showError } from '../../../../../helpers/notifications';


const getBaseData = ({ Users }) => {
	return {
		baseData      : Users.UserInfo.UserLimitsAndRestrictions.LimitsPayment.get('baseData'),
		withdrawalData: Users.UserInfo.UserLimitsAndRestrictions.LimitsPayment.get('withdrawalData'),
	};
};

const prefix = 'users.limits.payment';

const messages = {
	errorDepositListReload   : `${prefix}.errorDepositListReload`,
	errorWithdrawalListReload: `${prefix}.errorWithdrawalListReload`,
	errorDepositSave         : `${prefix}.errorDepositSave`,
	errorWithdrawalSave      : `${prefix}.errorWithdrawalSave`,
	errorSmallerDep          : `${prefix}.errorSmallerDep`,
	errorSmallerWid          : `${prefix}.errorSmallerWid`,
	successPaymentSave       : `${prefix}.successPaymentSave`,
};

function* paymentsDepositListReload() {

	const takeAction   = actions.USER_LIMITS_PAYMENTS_DEPOSIT_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		yield put(actions.depositUiRefresh({ loading: true }));

		const userID = action.data;
		const params     = { user_id: userID };
		try {
			let deposit;
			let limits;

			const [resList, resLimitsCurrent] = yield all([call(usersAPI.getUserLimitsPaymentsDepositList), call(usersAPI.getUserLimits, params)]);
			if (resList && resList.status === 200 && resLimitsCurrent && resLimitsCurrent.status === 200 && !!resList.data.data.length ) {
				const { payments } = resList.data.data[0];
				limits = resLimitsCurrent.data.data;
				deposit = adaptBody(payments, limits);
				const depositList = createList(deposit);
				const { sortedArr : depositArr, sortedObj : depositObj } = depositList;

				yield put(actions.depositListRefresh(depositArr));
				yield put(actions.baseDataRefresh(depositObj));
				yield put(actions.depositUiRefresh({ loading: false }));
			}
		} catch (e) {
			showError(messages.errorDepositListReload, e);
			yield put(actions.depositUiRefresh({ loading: false }));
			console.log(e);
		}
	});
}

function* paymentsWithdrawalListReload() {

	const takeAction   = actions.USER_LIMITS_PAYMENTS_WITHDRAWAL_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		yield put(actions.withdrawalUiRefresh({ loading: true }));
		const userID = action.data;
		const params     = { user_id: userID };
		try {
			let withdrawal;
			let limits;

			const [resList, resLimitsCurrent] = yield all([call(usersAPI.getUserLimitsPaymentsWithdrawalList), call(usersAPI.getUserLimits, params)]);
			if (resList && resList.status === 200 && resLimitsCurrent && resLimitsCurrent.status === 200 && !!resList.data.data[0] ) {
				const { payments } = resList.data.data[0];
				limits = resLimitsCurrent.data.data;

				withdrawal = adaptBody(payments, limits);
				const withdrawalList = createList(withdrawal);
				const { sortedArr : withdrawalArr, sortedObj : withdrawalObj } = withdrawalList;

				yield put(actions.withdrawalListRefresh(withdrawalArr));
				yield put(actions.withdrawalDataRefresh(withdrawalObj));
				yield put(actions.withdrawalUiRefresh({ loading: false }));
			}
		} catch (e) {
			showError(messages.errorWithdrawalListReload, e);
			yield put(actions.withdrawalUiRefresh({ loading: false }));
			console.log(e);
		}
	});
}

function* depositSave() {

	yield takeEvery(actions.PAYMENT_DEPOSIT_SAVE, function* ({ data }) {
		const { userID, paymentID } = data;
		const { baseData } = yield select(getBaseData);
		const { minDeposit, maxDeposit } = baseData[paymentID];

		if ((minDeposit && maxDeposit) && (minDeposit > maxDeposit)) {
			showError(messages.errorSmallerDep);
			return;
		}
		let isError = false;

		try {
			const cloned = cloneDeep(baseData);
			cloned[paymentID].disable = true;
			yield put(actions.baseDataRefresh(cloned));
			const preparedData = prepareLimitsPayments(baseData[paymentID], userID);
			yield call(usersAPI.LimitsPaymentsUpdate, preparedData);

			notifications.showSuccess(messages.successPaymentSave);

		} catch (e) {
			isError = true;
			notifications.showError(messages.errorDepositSave, e);
			console.log(e);
			const cloned = cloneDeep(baseData);
			cloned[paymentID].disable = false;
			yield put(actions.baseDataRefresh(cloned));
		}

		if (isError) {
			yield put(actions.depositUiRefresh({ loading: false }));
		}
	});
}

function* withdrawalSave() {

	yield takeEvery(actions.PAYMENT_WITHDRAWAL_SAVE, function* ({ data }) {
		const { userID, paymentID } = data;
		const { withdrawalData } = yield select(getBaseData);

		const { minWithdraw, maxWithdraw } = withdrawalData[paymentID];
		if ((minWithdraw && maxWithdraw) && (minWithdraw > maxWithdraw)) {
			showError(messages.errorSmallerWid);
			return;
		}
		let isError = false;

		try {
			const preparedData = prepareLimitsPayments(withdrawalData[paymentID], userID);
			const res = yield call(usersAPI.LimitsPaymentsUpdate, preparedData);
			if (res && res.status === 200 ) {
				yield put(actions.depositListReload(userID));
				yield put(actions.withdrawalListReload(userID));
			}
			notifications.showSuccess(messages.successPaymentSave);

		} catch (e) {
			isError = true;
			notifications.showError(messages.errorWithdrawalSave, e);
			console.log(e);
		}
		if (isError) {
			yield put(actions.withdrawalUiRefresh({ loading: false }));
		}

	});
}

export default function* userLimitsPaymentsSaga() {

	yield all([
		fork(paymentsDepositListReload),
		fork(paymentsWithdrawalListReload),
		fork(depositSave),
		fork(withdrawalSave),
	]);
}
