import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { cloneDeep, toInteger, isEmpty } from 'lodash';

import actions from './actions';
import tableActions from '../../../tables/actions';
import integratorsActions from '../../../casino/integrators/actions';

import notifications, { showError, showSuccess } from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { casinoAPI } from '../../../../helpers/api/casino';
import { historyAPI } from '../../../../helpers/api/history';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { deriveTablePagination } from '../../../../selectors/tables';
import { BONUS_TYPES } from '../../../../constants/bonuses';
import {
	getListParams,
	adaptList,
	adaptBets,
	adaptHistory,
	prepareBonusData,
} from './utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { adaptGamesList } from '../../../casino/games/utils';


const messages = {
	errorListLoad     : 'bonuses.bonuses.errorListLoad',
	errorDelete       : 'bonuses.bonuses.errorDelete',
	errorSave         : 'bonuses.bonuses.errorSave',
	errorGameLimitLoad: 'bonuses.bonuses.errorGameLimitLoad',
	errorHistoryLoad  : 'bonuses.bonuses.errorHistoryLoad',
	errorCurrencyLoad : 'bonuses.bonuses.errorCurrencyLoad',
	successDelete     : 'bonuses.bonuses.successDelete',
	successSave       : 'bonuses.bonuses.successSave',
};

const tableType   = TABLE_TYPES.bonusesRegistrationFreeSpin;

function getStoreData(state) {
	const { Loyalty: { Bonuses }, Tables, App } = state;
	const { Bonuses : typeBonuses } = Bonuses;
	const UI = typeBonuses.get('UI');
	const providerIDs = Bonuses.UserBonus.get('checkedProvidersByIntegrator');

	return {
		filter           : typeBonuses.get('filter'),
		sorting          : Tables.get(tableType).sorting,
		pagination       : deriveTablePagination(tableType, state),
		bonusData        : typeBonuses.get('bonusData'),
		bets             : typeBonuses.get('bets'),
		betID            : UI.betID,
		editMode         : UI.editMode,
		closeModal       : UI.closeModal,
		denominationKey  : UI.denominationKey,
		denominationValue: UI.denominationValue,
		freeSpinType     : UI.freeSpinType,
		bonusType        : UI.bonusType,
		websiteID        : App.get('websiteID'),
		providerIDs,
	};
}

function* listReload() {

	yield takeEvery(actions.LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);
		if (params.affiliate_reference) {
			params.affiliate_reference = [params.affiliate_reference];
		}
		let entities   = {};
		let totalCount = 0;
		let list = [];
		try {
			const res = yield call(bonusesAPI.bonusesList, params);
			if (res && res.status === 200) {
				const { adaptedData, bounsEntities }   = adaptList(res.data.data);
				entities = bounsEntities;
				list = adaptedData;

				totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.listRefresh(entities));
				yield put(actions.bonusListRefresh(list));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			showError(messages.errorListLoad);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}


function* gamesListLimitedReload() {

	yield takeEvery(actions.NEW_GAMES_BY_PAGE_RELOAD, function* (action) {
		const { bonusData } = yield select(getStoreData);
		const { currencyID } = bonusData;

		// yield put(actions.uiRefresh({ loading: true }));
		const { websiteID, casinoID, channelID, byCategory, name, providerType, page, limit, isUnlimit, isSearch } = action.data;
		let params;
		if (providerType === 'provider') {
			params = {
				unlimit    : isUnlimit,
				channel_id : channelID,
				provider_id: casinoID,
				categories : byCategory,
				name,
			};
		} else {
			params = {
				unlimit           : isUnlimit,
				channel_id        : channelID,
				custom_provider_id: casinoID,
				name,
				currency_id       : currencyID,
			};
		}

		params.page = page;
		params.limit = limit;

		let totalCount;

		let gamesList = [];
		try {
			const res = yield call(casinoAPI.gamesList, websiteID, params);
			if (res && res.status === 200) {
				const { adaptedData } = adaptGamesList(res.data.data);
				gamesList = adaptedData;
				totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.gamesListRefreshLimited(gamesList));
				yield put(actions.uiRefresh({ isGamesChanged: false }));
			}

		} catch (error) {
			notifications.showError(messages.errorListLoad);
			console.log(error);
		}

		if ( isSearch ) {
			yield put(actions.gamesListRefreshFilteredLimited(gamesList));
		} else {
			yield put(actions.gamesListRefreshLimited(gamesList));
		}

		yield put(actions.gamesListHasMoreRefresh(isEmpty(gamesList) ? false : totalCount));
		yield put(actions.uiRefresh({ loading: false }));
	});
}


function* filterApply() {
	yield takeEvery(actions.FILTER_APPLY, function* () {
		yield put(tableActions.paginationRefresh(tableType, { currentPage: 1 }));
		yield put(actions.listReload());
	});
}

function* bonusSave() {

  yield takeEvery(actions.BONUS_SAVE, function* () {
    yield put(actions.uiRefresh({ loading: true }));

    const {
      bonusData,
      editMode,
      closeModal,
      denominationKey,
      denominationValue,
      websiteID,
      freeSpinType,
      bonusType,
      bets,
      betID,
    } = yield select(getStoreData);
    const limits = {
      denominationKey,
      denominationValue,
      bets,
      betID,
    };
    const resData      = cloneDeep(bonusData);
    resData.websiteID  = websiteID;

    //prepare
    const preparedData = prepareBonusData(resData, limits, freeSpinType, bonusType, editMode);
    let bonusID = resData.id;
    // preparedData.provider_ids = providers.map(id => Number(id));
    let isError = false;

    try {
      if (editMode) {
        if (bonusType === BONUS_TYPES.registrationFreeSpin) {
          yield call(bonusesAPI.registrationFreeSpinUpdate, bonusID, preparedData);
        } else if (bonusType === BONUS_TYPES.depositFreeSpin) {
          yield call(bonusesAPI.depositFreeSpinUpdate, bonusID, preparedData);
        } else if (bonusType === BONUS_TYPES.nextDepositFreeSpin) {
          yield call(bonusesAPI.nextDepositFreeSpinUpdate, bonusID, preparedData);
        } else if (bonusType === BONUS_TYPES.deposit) {
          yield call(bonusesAPI.depositUpdate, bonusID, preparedData);
        } else if (bonusType === BONUS_TYPES.nextDepositBonus) {
          yield call(bonusesAPI.nextDepositUpdate, bonusID, preparedData);
        } else {
          yield call(bonusesAPI.packUpdate, bonusID, preparedData);
        }
      } else {
        let res;
        if (bonusType === BONUS_TYPES.depositFreeSpin) {
            res = yield call(bonusesAPI.depositFreeSpinCreate, preparedData);
        } else if (bonusType === BONUS_TYPES.registrationFreeSpin) {
            res = yield call(bonusesAPI.registrationFreeSpinCreate, preparedData);
        } else if (bonusType === BONUS_TYPES.deposit) {
          res = yield call(bonusesAPI.depositCreate, preparedData);
        } else if (bonusType === BONUS_TYPES.nextDepositFreeSpin) {
          res = yield call(bonusesAPI.nextDepositFreeSpinCreate, preparedData);
        } else if (bonusType === BONUS_TYPES.nextDepositBonus) {
          res = yield call(bonusesAPI.nextDepositCreate, preparedData);
        } else {
          res = yield call(bonusesAPI.packCreate, preparedData);
        }
        bonusID = toInteger(res.data.data.id);
        resData.id = bonusID;
        yield put(actions.dataRefresh(resData));
        yield put(actions.historyLogsReload(bonusID));
        yield put(actions.uiRefresh({ editMode: true }));
      }

			showSuccess(messages.successSave);
			yield put(actions.uiRefresh({ isChanged: false }));

		} catch (error) {
			isError = true;
			showError(messages.errorSave);
		}

		if (!isError && closeModal) {
			yield put(actions.dataReset());
			yield put(actions.listReload());

		} else if (!isError || !closeModal) {
			yield put(actions.uiRefresh({ loading: false }));
			yield put(actions.listReload());

    } else {
      yield put(actions.uiRefresh({ loading: false }));
    }

    const { currencyID } = resData;
    yield put(integratorsActions.listReload({ withProvider: true, currencyID }));
  });
}

function* gameLimitsReload() {

	yield takeEvery(actions.GAME_LIMITS_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { casinoGameID, currencyCode, withRefresh } = action.data;

		const params      = { currency_code: currencyCode };
		let bets          = [];
		let denominations = {};

		try {
			const res = yield call(casinoAPI.gameBetLimits, casinoGameID, params);
			if (res && res.status === 200) {
				const { data } = res.data;
				bets = adaptBets(data.bets);
				denominations = data.denominations; // eslint-disable-line prefer-destructuring
			}

		} catch (error) {
			showError(messages.errorGameLimitLoad);
		}

		yield put(actions.betsRefresh(bets));
		yield put(actions.denominationsRefresh(denominations));
		if (withRefresh) {
			yield put(actions.uiRefresh({
				betID            : null,
				denominationKey  : null,
				denominationValue: null,
				loading          : false,
			}));

		} else {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* historyLogsReload() {

	yield takeEvery(actions.HISTORY_LOGS_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const {  bonusType } = yield select(getStoreData);
		const { bonusID } = action.data;
		let historyLogs = [];
		let res;
		try {
			if (bonusType === BONUS_TYPES.registrationFreeSpin) {
				res = yield call(historyAPI.historyRegistrationFreeSpinLogs, bonusID);
			} else if (bonusType === BONUS_TYPES.depositFreeSpin) {
				res = yield call(historyAPI.historyDepositFreeSpinLogs, bonusID);
			} else if (bonusType === BONUS_TYPES.deposit) {
				res = yield call(historyAPI.historyDepositsLogs, bonusID);
			} else {
				res = yield call(historyAPI.historyPacksLogs, bonusID);
			}
			if (res && res.status === 200) {
				historyLogs = adaptHistory(res.data.data);
				yield put(actions.historyLogsRefresh(historyLogs));
			}
		} catch (error) {
			showError(messages.loadData);
			console.log(error);
		}

		yield put(actions.historyLogsRefresh(historyLogs));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* bonusesSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
		fork(bonusSave),
		fork(gameLimitsReload),
		fork(historyLogsReload),
		fork(gamesListLimitedReload),
	]);
}
