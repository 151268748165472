import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	reportFields: [],

	UI: {
		loading: false,
	},
});

export default function reportUsersReducer(state = initState, action) {

	switch (action.type) {

	case actions.REPORT_USERS_DATA_REFRESH: {
		return state.set('reportData', action.data);
	}

	case actions.REPORT_USERS_FIELDS_REFRESH: {
		return state.set('reportFields', action.data);
	}

	case actions.REPORT_USERS_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
