import EntityAdapter from '../../../../helpers/entityAdapter';

const prefix = 'smsTemplates.';

export const messages = {
	successUpdateTemplate: `${prefix}successUpdateTemplate`,
	successSaveTemplate  : `${prefix}successSaveTemplate`,
	errorListReload      : `${prefix}errorListReload`,
};

export const fields = {
	id     : 'id',
	name   : 'name',
	content: 'content',
	slug   : 'slug',
	status : 'status',
};

const smsTemplatesAdapter = createSMSTemplatesAdapter();
// const namesAdapter = createNamesAdapter();

// Adapt ------------------------------------------------------------------------------------------

export const adaptSMSTemplates = (data = []) => {
	smsTemplatesAdapter.clearExcludes();
	const adapted = smsTemplatesAdapter.adaptList(data);
	return adapted.map(item => ({
		...item,
		status: item.status ? '1' : '0',
	}));
};

export const adaptSMSTemplate = (data = {}) => {
	smsTemplatesAdapter.clearExcludes();
	return smsTemplatesAdapter.adapt(data);
};

export const prepareSMSTemplate = (data = {}) => {
	smsTemplatesAdapter.clearExcludes();
	smsTemplatesAdapter.addExcludeField('id');
	return smsTemplatesAdapter.prepare(data);
};
export const prepareSMSTemplateUpdate = (data = {}) => {
	smsTemplatesAdapter.clearExcludes();
	return smsTemplatesAdapter.prepare(data);
};

export const adaptSMSVariables = (data = {}) => {
	return  data.map(item => `{{${item.toUpperCase()}}}`);
};

//
// export function adaptNames(rawData) {
//   // namesAdapter.clearExcludes();
//   const adaptedNames = namesAdapter.adaptList(rawData);
//
//   const result = languagesList.map(langItem => {
//     const langID = langItem.id;
//     const nameItem = adaptedNames.find(item => item.langID === langID);
//
//     if (nameItem) {
//       return nameItem;
//     }
//     return {
//       langID,
//       translation: '',
//     };
//
//   });
//
//   return result;
// }

// Prepare ----------------------------------------------------------------------------------------
//
// export function prepareQuestion(rawNames, questionID) {
//   const names = prepareNames(rawNames, questionID);
//   const preparedData = {
//     names,
//   };
//
//   if (isID(questionID)) {
//     preparedData.id = toInteger(questionID);
//   }
//
//   return preparedData;
// }

// Adapters ---------------------------------------------------------------------------------------

export function createSMSTemplatesAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'content', fields.content);
	adapter.addField(rules.string, 'slug', fields.slug);
	adapter.addField(rules.bool, 'status', fields.status);

	return adapter;
}

