import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({

	baseData         : {},
	settingsData     : {},
	dynamicLimitsData: [],

	UI: {
		modalVisible          : false,
		loading               : false,
		isChangedSettings     : false,
		isChangedDynamicLimits: false,
	},
});

export default function marketReducer(state = initState, action) {

	switch (action.type) {

	case actions.EVENT_MARKET_BASE_DATA_REFRESH: {
		return state.set('baseData', action.data);
	}

	case actions.EVENT_MARKET_SETTINGS_DATA_REFRESH: {
		return state.set('settingsData', action.data);
	}

	case actions.EVENT_MARKET_DYNAMIC_LIMITS_DATA_REFRESH: {
		// const data = new List(action.data).toArray();
		return state.set('dynamicLimitsData', action.data);
	}

	case actions.EVENT_MARKET_SET_VALUE_UI: {

		const { valueName, value } = action.data;
		const UI                   = new Map(state.get('UI')).toObject();
		UI[valueName]              = value;

		return state.set('UI', UI);
	}

	case actions.EVENT_MARKET_DATA_RESET: {
		return initState;
	}

	default:
		return state;
	}
}
