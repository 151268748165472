import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../../tables/actions';

import { newsAPI } from '../../../../../helpers/api/news';
import notifications from '../../../../../helpers/notifications';
import { getHeadersTotalCount } from '../../../../../helpers/utils';
import { deriveTablePagination } from '../../../../../selectors/tables';

import { getListParams, adaptNewsList } from './utils';
import { TABLE_TYPES } from '../../../../../constants/tableTypes';

const prefix = 'news.list';

const messages = {
	errorListLoad    : `${prefix}.errorListLoad`,
	errorItemDelete  : `${prefix}.errorItemDelete`,
	successItemDelete: `${prefix}.successItemDelete`,
};

const tableType     = TABLE_TYPES.news;

function getStoreData(state) {
	const { Tables } = state;

	return {
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
	};
}

function* listReload() {

	yield takeEvery(actions.NEWS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { pagination, sorting } = yield select(getStoreData);
		const params = getListParams(pagination, sorting);

		let listIDs    = [];
		let entities   = {};
		let totalCount = 0;
		try {
			const res = yield call(newsAPI.newsList, params);
			if (res && res.status === 200) {
				const result = adaptNewsList(res.data.data);
				listIDs      = result.listIDs;  // eslint-disable-line prefer-destructuring
				entities     = result.entities; // eslint-disable-line prefer-destructuring
				totalCount   = getHeadersTotalCount(res.headers) || listIDs.length;
			}

		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			console.log(error);
		}

		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.listRefresh(listIDs, entities));
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* deleteItem() {

	yield takeEvery(actions.NEWS_LIST_DELETE_ITEM, function* (action) {

		const { itemID } = action.data;
		try {
			const res = yield call(newsAPI.newsItemDelete, itemID);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successItemDelete);
			}
		} catch (error) {
			notifications.showError(messages.errorItemDelete, error);
			console.log(error);
		}

		yield put(actions.listReload());
	});
}

export default function* newsListSaga() {
	yield all([
		fork(listReload),
		fork(deleteItem),
	]);
}
