import { Map } from 'immutable';
import { fill } from '../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData    : {},
	currencyList: [],

	UI: {
		userID              : null,
		visible             : false,
		loading             : false,
		closeModal          : false,
		passwordModalVisible: false,
	},
});

export default function profileReducer(state = initState, action) {

	switch (action.type) {

	case actions.PROFILE_BASE_DATA_REFRESH: {
		const { baseData } = action.data;
		const target = state.get('baseData');
		const result = fill(baseData, target, true);
		return state.set('baseData', result);
	}

	case actions.PROFILE_UI_REFRESH: {
		const { uiData } = action.data;
		const target = state.get('UI');
		const result = fill(uiData, target);
		return state.set('UI', result);
	}

	case actions.PROFILE_DATA_RESET: {
		return initState;
	}

	case  actions.PROFILE_CURRENCY_LIST_RELOAD: {
		return state.set('currencyList', action.data);
	}

	default:
		return state;
	}
}
