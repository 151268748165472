const actions = {

	CASINO_GAMES_LIST_RELOAD          : 'CASINO_GAMES_LIST_RELOAD',
	CASINO_GAMES_LIST_RELOAD_GAMES    : 'CASINO_GAMES_LIST_RELOAD_GAMES',
	CASINO_GAMES_LIST_REFRESH         : 'CASINO_GAMES_LIST_REFRESH',
	CASINO_GAMES_ENTITIES_REFRESH     : 'CASINO_GAMES_ENTITIES_REFRESH',
	CASINO_GAMES_SEARCH_LIST_REFRESH  : 'CASINO_GAMES_SEARCH_LIST_REFRESH',
	CASINO_GAMES_LIST_REFRESH_FROM_API: 'CASINO_GAMES_LIST_REFRESH_FROM_API',
	CASINO_GAMES_LIST_MODAL_REFRESH   : 'CASINO_GAMES_LIST_MODAL_REFRESH',

	CASINO_GAMES_UI_REFRESH  : 'CASINO_GAMES_UI_REFRESH',
	CASINO_GAMES_SEARCH_VALUE: 'CASINO_GAMES_SEARCH_VALUE',
	SET_HAS_MORE             : 'SET_HAS_MORE',

	UPDATE_PAGE_NUM  : 'UPDATE_PAGE_NUM',
	UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',

	UPDATE_SEARCH_QUERY: 'UPDATE_SEARCH_QUERY',
	SET_TOTAL_COUNT    : 'SET_TOTAL_COUNT',


	/**
	 *
	 * @param websiteID
	 * @param casinoID
	 * @param channelID
	 * @param byCategory
	 * @param name is string
	 * @param providerType is string
	 * @param limit is number
	 * @param page  is number
	 * @param isUnlimit
	 * @returns {{data: {websiteID, byCategory: null, name, limit: number, casinoID, page: number, channelID, providerType: string}, type: string}}
	 */
	gamesListReload: (websiteID, casinoID, channelID, byCategory  = null, name, providerType = 'provider', limit, page, isUnlimit = true, byCurrency, sorting) => {

		return  {
			type: actions.CASINO_GAMES_LIST_RELOAD,
			data:	{
				websiteID,
				casinoID,
				channelID,
				byCategory,
				providerType,
				name,
				limit,
				page,
				isUnlimit,
				byCurrency,
				sorting,
			},
		};
	},

	casinoGameListReload: (data) => {
		return  {
			type: actions.CASINO_GAMES_LIST_RELOAD_GAMES,
			data,
		};
	},

	gamesListRefresh: list => ({
		type: actions.CASINO_GAMES_LIST_REFRESH,
		data: list,
	}),

	gamesEntitiesRefresh: data => ({
		type: actions.CASINO_GAMES_ENTITIES_REFRESH,
		data,
	}),

	gamesListModalRefresh: list => ({
		type: actions.CASINO_GAMES_LIST_MODAL_REFRESH,
		data: list,
	}),
	gamesSearchListRefresh: list => ({
		type: actions.CASINO_GAMES_SEARCH_LIST_REFRESH,
		data: list,
	}),
	setSearchvalue: value => ({
		type: actions.CASINO_GAMES_SEARCH_VALUE,
		data: value,
	}),
	gamesListRefreshFromAPI: casinoID => ({
		type: actions.CASINO_GAMES_LIST_REFRESH_FROM_API,
		data: {
			casinoID,
		},
	}),
	uiRefresh: data => ({
		type: actions.CASINO_GAMES_UI_REFRESH,
		data,
	}),
	setHasMore: (data) => {
		return {
			type: actions.SET_HAS_MORE,
			data,
		};
	},
	updatePageNum: (data) => {
		return {
			type: actions.UPDATE_PAGE_NUM,
			data,
		};
	},
	updateCategories: data => {
		return {
			type: actions.UPDATE_CATEGORIES,
			data,
		};
	},
	updateSearchQuery: data => {
		return {
			type: actions.UPDATE_SEARCH_QUERY,
			data,
		};
	},
	setTotalCount: data => {
		return 	{
			type: actions.SET_TOTAL_COUNT,
			data,
		};
	},

};

export default actions;
