import { makeActionCreator } from '../../../../helpers';

const prefix = 'BETTING_RULES_LIST';

const TYPES = {
	DATA_RELOAD : `${prefix}_DATA_RELOAD`,
	DATA_REFRESH: `${prefix}_DATA_REFRESH`,
	ITEM_REMOVE : `${prefix}_ITEM_REMOVE`,
	UI_REFRESH	: `${prefix}_UI_REFRESH`
};

const actions = {
	...TYPES,

	dataReload : makeActionCreator(TYPES.DATA_RELOAD),
	dataRefresh: makeActionCreator(TYPES.DATA_REFRESH, 'entities'),
	itemRemove : makeActionCreator(TYPES.ITEM_REMOVE, 'ruleID'),
	itemRemove : makeActionCreator(TYPES.ITEM_REMOVE, 'ruleID'),
	uiRefresh	: makeActionCreator(TYPES.UI_REFRESH, 'UI'),
};

export default actions;
