import { all, takeLatest, put, fork, takeEvery, call, select } from 'redux-saga/effects';
import { reportsListByProviderAPI } from '../../../helpers/api/reportByProvider';
import actions from './actions';

import { showError } from '../../../helpers/notifications';
import { downloadReport, getHeadersTotalCount } from '../../../helpers/utils';
import { deriveTablePagination } from '../../../selectors/tables';
import tableActions from '../../tables/actions';


import {
	adaptReportsList,
	checkSortReportByGame,
	getListParams,
	createReportParams,
	availableFields,
} from './utils';
import { TABLE_TYPES } from '../../../constants/tableTypes';
import { reportsListByGameAPI } from '../../../helpers/api/reportsByGame';

const prefix = 'reports.byprovider';

const messages = {
	errorListLoad: `${prefix}.errorListLoad`,
	errorDataLoad: `${prefix}.errorDataLoad`,
};
const tableType       = TABLE_TYPES.reportByProvider;

function getStoreData(state) {
	const { Reports: { Provider }, Tables } = state;
	return {
		filter      : Provider.get('filter'),
		pagination  : deriveTablePagination(tableType, state),
		sorting     : checkSortReportByGame(Tables.get(tableType).sorting),
		reportFields: Provider.get('reportFields'),
	};
}

function* listReload() {

	yield takeLatest(actions.LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));
		const { filter, pagination, sorting } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);

		let list   = [];
		let totalCount = 0;
		try {
			const res = yield call(reportsListByProviderAPI.reportsListByProvider, params);
			if (res && res.status === 200) {
				list   = adaptReportsList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);
			}

		} catch (error) {
			showError(messages.errorListLoad, error);
		}

		yield put(actions.listRefresh(list));
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* dataReload() {

	yield takeEvery(actions.DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		yield put(actions.dataRefresh(''));

		const { filter, sorting, reportFields, userFilter  } = yield select(getStoreData);
		const { useUserFilter, listBy } = action.data;
		const actualFilter = useUserFilter ? userFilter : filter;

		const params = createReportParams(actualFilter, sorting, reportFields, availableFields);

		try {
			const res = yield call(reportsListByGameAPI.reportsListByCSV, params, listBy);
			if (res) {
				downloadReport(res);
			}

		} catch (error) {
			showError(messages.errorDataLoad, error);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {
	yield takeLatest(actions.FILTER_APPLY, function* () {
		yield put(tableActions.paginationRefresh({ currentPage: 1 }));
		yield put(actions.listReload());
	});
}

export default function* reportsByProviderSaga() {
	yield all([
		fork(listReload),
		fork(dataReload),
		fork(filterApply),
	]);
}
