import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';
import tableActions from '../../tables/actions';

import { getListParams } from './utils';
import { adaptWithdrawalList } from '../withdrawals/utils';

import { withdrawalsAPI } from '../../../helpers/api/withdrawals';
import { getHeadersTotalCount } from '../../../helpers/utils';
import notifications from '../../../helpers/notifications';
import { deriveTablePagination } from '../../../selectors/tables';
import { TABLE_TYPES } from '../../../constants/tableTypes';

const prefix = 'rtm.userWithdrawals';

const messages = {
	errorListReload: `${prefix}.errorListReload`,
};

const tableType = TABLE_TYPES.withdrawProfile;

function getStoreData(state) {
	const { RTM, Tables } = state;

	return {
		filter    : RTM.UserWithdrawals.get('filter'),
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
	};
}

function* listReload() {

	yield takeEvery(actions.RTM_USER_WITHDRAWALS_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);
		let withdrawalIDs    = [];
		let entities         = {};
		let withdrawalTotals = {};
		let totalCount       = 0;
		try {
			const response = yield call(withdrawalsAPI.rtmWithdrawalsList, params);
			if (response && response.status === 200) {
				const result     = adaptWithdrawalList(response.data.data);
				withdrawalIDs    = result.withdrawalIDs;    // eslint-disable-line prefer-destructuring
				entities         = result.entities;         // eslint-disable-line prefer-destructuring
				withdrawalTotals = result.withdrawalTotals; // eslint-disable-line prefer-destructuring
				totalCount       = getHeadersTotalCount(response.headers) || withdrawalIDs.length;
			}

		} catch (error) {
			notifications.showError(messages.errorListReload, error);
			console.log(error);
		}

		yield put(actions.dataRefresh(withdrawalIDs, entities, withdrawalTotals));
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {
	yield takeEvery(actions.RTM_USER_WITHDRAWALS_FILTER_APPLY, function* () {
		yield put(actions.listReload());
	});
}

export default function* rtmUserWithdrawalsSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
	]);
}
