import { Map } from 'immutable';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { fill } from '../../../helpers/utils';
import actions from './actions';


const initState = new Map({

	messagesIDs        : [],
	entities           : {},
	newMessagesIDs     : [],
	newMessagesEntities: {},

	UI: {
		loading: false,
	},

	selectedMail: -1,
	filterAttr  : { bucket: 'Inbox' },
	composeMail : false,
	replyMail   : false,
	searchString: '',

	newMessage: {
		to         : [],
		body       : '',
		subject    : '',
		bucket     : 'Sent',
		no_reply   : false,
		attachments: [],
	},
});

export default function messagesReducer(state = initState, action) {

	switch (action.type) {

	case actions.MESSAGES_DATA_REFRESH: {
		const { messagesIDs, entities } = action.data;
		return state
			.set('messagesIDs', messagesIDs)
			.set('entities', entities);
	}

	case actions.MESSAGES_NEW_MESSAGES_LIST_REFRESH: {
		const { newMessagesIDs, newMessagesEntities } = action.data;
		return state
			.set('newMessagesIDs', newMessagesIDs)
			.set('newMessagesEntities', newMessagesEntities);
	}

	case actions.MESSAGES_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.MESSAGES_FILTER_ATTRIBUTE: {
		const filterAttr = state.get('filterAttr');
		if (action.data) {
			if (action.data.bucket) {
				filterAttr.bucket = action.data.bucket;
				filterAttr.tag = action.data.tag;
			} else if (action.data.tag) {
				filterAttr.tag = action.data.tag;
			}
		}
		return state
			.set('filterAttr', { ...filterAttr })
			.set('selectedMail', -1)
			.set('composeMail', false)
			.set('replyMail', false);
	}

	case actions.MESSAGES_SELECTED_MAIL: {
		const entities = state.get('entities');
		if (entities[action.data]) {
			entities[action.data].messages[0].seen = true;
		}
		return state
			.set('selectedMail', action.data)
			.set('entities', entities)
			.set('replyMail', false);
	}

	case actions.MESSAGES_COMPOSE_MAIL:
		return state
			.set('composeMail', action.composeMail)
			.set('replyMail', false);

	case actions.MESSAGES_REPLY_MAIL:
		return state.set('replyMail', action.replyMail);

	case actions.MESSAGES_SEARCH_STRING:
		return state.set('searchString', action.searchString);

	case actions.MESSAGES_NEW_MESSAGE_MERGE: {
		const newMessage = state.get('newMessage');
		const result = cloneDeep(merge(newMessage, action.data));
		const { attachments } = action.data;
		if (isEmpty(attachments)) {
			result.attachments = [];
		}
		return state.set('newMessage', result);
	}

	default:
		return state;
	}
}
