import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../../../helpers/utils';

const initState = new Map({
	list: [],
	UI: {
		loading: false,
	}
});

export default function marketsListReducer(state = initState, action) {

	switch (action.type) {

	case actions.MARKETS_LIST_REFRESH: {
		return state.set('list', action.data);
	}

	case actions.UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
