const actions = {

	PROMOTIONS_LIST_RELOAD : 'PROMOTIONS_LIST_RELOAD',
	PROMOTIONS_LIST_REFRESH: 'PROMOTIONS_LIST_REFRESH',

	PROMOTIONS_LIST_DELETE_PROMOTION: 'PROMOTIONS_LIST_DELETE_PROMOTION',

	PROMOTIONS_LIST_CHANGE_WEBSITE: 'PROMOTIONS_LIST_CHANGE_WEBSITE',
	FILTER_REFRESH        					   : 'FILTER_REFRESH',
	PROMOTIONS_LIST_UI_REFRESH 			: 'PROMOTIONS_LIST_UI_REFRESH',

	uiRefresh: data => ({
		type: actions.PROMOTIONS_LIST_UI_REFRESH,
		data,
	}),

	listReload: () => ({
		type: actions.PROMOTIONS_LIST_RELOAD,
	}),

	listRefresh: (listIDs, entities) => ({
		type: actions.PROMOTIONS_LIST_REFRESH,
		data: {
			listIDs,
			entities,
		},
	}),

	filterRefresh: data => ({
		type: actions.FILTER_REFRESH,
		data,
	}),

	deletePromotion: promotionID => ({
		type: actions.PROMOTIONS_LIST_DELETE_PROMOTION,
		data: {
			promotionID,
		},
	}),
	changeWebsite: websiteID => ({
		type: actions.PROMOTIONS_LIST_CHANGE_WEBSITE,
		data: {
			websiteID,
		},
	}),
};

export default actions;
