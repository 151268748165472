import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../../../helpers/utils';

const initState = new Map({
	listIDs : [],
	entities: {},
	UI		: {
		loading: false,
	}
});

export default function resultsListReducer(state = initState, action) {
	switch (action.type) {
	case actions.RESULTS_LIST_REFRESH: {
		const { listIDs, entities } = action.data;
		return state.set('listIDs', listIDs).set('entities', entities);
	}
	case actions.UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
