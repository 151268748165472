import { normalize, schema } from 'normalizr';
import orderBy from 'lodash/orderBy';
import isArray from 'lodash/isArray';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';

export const fields = {
	ID            : 'id',
	expireDate    : 'expire_date',
	givenDate     : 'created',
	bonusTypeID   : 'bonus_type_id',
	typeID        : 'type_id',
	userID        : 'user_id',
	wonAmount     : 'won_amount',
	wager         : 'wagering_turnover',
	casinoGameName: 'casino_game_name',
	currencyCode  : 'currency_code',
	leftSpins     : 'left_spins',
	personalID    : 'personal_id',
	spinsTotal    : 'spins_total',
	statusID      : 'status_id',
};

const listAdapter = createListAdapter();

export function getListParams(pagination) {

	const builder = new ParamsBuilder();

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	const params = builder.biuldParams({});

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptFreeSpinsList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = orderBy(listAdapter.adaptList(rawData), ['ID'], ['desc']);

	const tempData = {
		items: adaptedData,
	};

	const item           = new schema.Entity('items', {}, { idAttribute: 'ID' });
	const itemsSchema    = { items: [item] };
	const normalizedData = normalize(tempData, itemsSchema);
	const entities       = normalizedData.entities.items || {};

	return entities;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'ID', fields.ID);
	adapter.addField(rules.id, 'bonusTypeID', fields.bonusTypeID);
	adapter.addField(rules.id, 'typeID', fields.typeID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'statusID', fields.statusID);

	adapter.addField(rules.dateTime, 'expireDate', fields.expireDate);
	adapter.addField(rules.dateTime, 'givenDate', fields.givenDate);

	adapter.addField(rules.number, 'wonAmount', fields.wonAmount);

	adapter.addField(rules.number, 'wager', fields.wager);
	adapter.addField(rules.number, 'leftSpins', fields.leftSpins);
	adapter.addField(rules.number, 'spinsTotal', fields.spinsTotal);

	adapter.addField(rules.string, 'casinoGameName', fields.casinoGameName);
	adapter.addField(rules.string, 'currencyCode', fields.currencyCode);

	return adapter;
}
