import { Map } from 'immutable';
import config, {
	getCurrentLanguage,
} from '../../containers/Topbar/LanguageSwitcher/config';

import actions from './actions';

import { restoreLanguage } from '../../helpers/utility';

const storedLanguage = restoreLanguage();
const appLanguage = storedLanguage
	? storedLanguage.dataBaseLangID
	: config.defaultLanguage;

const initState = new Map({
	isActivated: false,
	language   : getCurrentLanguage(appLanguage),
});

export default function (state = initState, action) {
	switch (action.type) {
	case actions.ACTIVATE_LANG_MODAL:
		return state.set('isActivated', !state.get('isActivated'));

	case actions.CHANGE_LANGUAGE_SUCCESS:
		return state.set('language', action.language);
	default:
		return state;
	}
}
