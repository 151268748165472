import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../../tables/actions';

import { helpCenterAPI } from '../../../../../helpers/api/helpCenter';
import notifications from '../../../../../helpers/notifications';
import { getHeadersTotalCount } from '../../../../../helpers/utils';
import { deriveTablePagination } from '../../../../../selectors/tables';

import { getListParams, adaptTopicsList } from './utils';

const prefix = 'pages.helpcenter';

const messages = {
	errorListLoad     : `${prefix}.loading.list.failed`,
	errorTopicDelete  : `${prefix}.deleting.topic.failed`,
	successTopicDelete: `${prefix}.topic.deleted.success`,
};

const tableType     = 'HELP_CENTER';

function getStoreData(state) {
	const { Tables, App, Partner: { Websites } } = state;
	const websiteID = App.get('websiteID');
	const { List } = Websites;
	const { langID } = List.get('entities')[+websiteID] || 1;
	return {
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
		langID,
		websiteID,
	};
}

function* listReload() {

	yield takeEvery(actions.TOPICS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { pagination, sorting, websiteID, langID } = yield select(getStoreData);
		const params = getListParams(pagination, sorting, websiteID);
		let entities   = {};
		let totalCount = 0;
		try {
			const res = yield call(helpCenterAPI.topicList, params);
			if (res && res.status === 200) {
				const result = adaptTopicsList(res.data.data, langID);
				entities     = result.entities; // eslint-disable-line prefer-destructuring
				totalCount   = getHeadersTotalCount(res.headers);
				yield put(actions.listRefresh(entities));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* deleteTopic() {

	yield takeEvery(actions.TOPICS_LIST_DELETE_TOPIC, function* (action) {

		const { topicID } = action.data;
		try {
			const res = yield call(helpCenterAPI.topicDelete, topicID);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successTopicDelete);
			}
		} catch (error) {
			notifications.showError(messages.errorTopicDelete, error);
			console.log(error);
		}

		yield put(actions.listReload());
	});
}

export default function* helpCenterListSaga() {
	yield all([
		fork(listReload),
		fork(deleteTopic),
	]);
}
