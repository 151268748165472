import { all, call, takeEvery, fork, put } from 'redux-saga/effects';
import { responsibleGamblingAPI } from '../../../../helpers/api/responsibleGambling';
import actions from './actions';
import { mockLimitsData } from './mockData';
import { adaptActiveLimits } from './utils';


const prefix = 'users.limits.payment';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const messages = {
	errorDepositListReload   : `${prefix}.errorDepositListReload`,
	errorWithdrawalListReload: `${prefix}.errorWithdrawalListReload`,
	errorDepositSave         : `${prefix}.errorDepositSave`,
	errorWithdrawalSave      : `${prefix}.errorWithdrawalSave`,
	errorSmallerDep          : `${prefix}.errorSmallerDep`,
	errorSmallerWid          : `${prefix}.errorSmallerWid`,
	successPaymentSave       : `${prefix}.successPaymentSave`,
};

function* responsibleGamblingReload() {

	yield takeEvery(actions.DATA_RELOAD, function* () {

		try {
			const res = yield call(responsibleGamblingAPI.activeLimitations);
			if (res && res.status === 200) {
				yield put(actions.dataRefresh(res.data.data));

			}

		} catch (e) {
			const data = adaptActiveLimits(mockLimitsData);
			yield put(actions.dataRefresh(data));
			console.log(e, 'error');
		}
	});
}


function* removeLimitation() {

	yield takeEvery(actions.LIMITATION_REMOVE, function* ({ data }) {
		const { limitationID } = data;
		try {
			const res = yield call(responsibleGamblingAPI.removeLimitations, limitationID );
			if (res && res.status === 200) {
				yield put(actions.dataRefresh(res.data.data));
			}

		} catch (e) {
			const data = adaptActiveLimits(mockLimitsData);
			yield put(actions.dataRefresh(data));
			console.log(e, 'error');
		}
	});
}

export default function* userLimitsPaymentsSaga() {

	yield all([
		fork(responsibleGamblingReload),
		fork(removeLimitation),
	]);
}
