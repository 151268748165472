import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';

import { paymentsAPI } from '../../../../helpers/api/payments';
import notifications from '../../../../helpers/notifications';

import { adaptPaywaysList } from './utils';

const prefix = 'settings.payways';

const messages = {
	errorListLoad      : `${prefix}.errorListLoad`,
	errorApiListRefresh: `${prefix}.errorApiListRefresh`,
};

function* listReload() {

	yield takeEvery(actions.PAYMENTS_PAYWAYS_LIST_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { paymentID } = action.data;
		let entities = {};
		try {
			const res = yield call(paymentsAPI.paywaysList, paymentID);
			if (res && res.status === 200) {
				entities = adaptPaywaysList(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			console.log(error);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* apiListRefresh() {

	yield takeEvery(actions.PAYMENTS_PAYWAYS_API_REFRESH, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { paymentID } = action.data;
		try {
			const res = yield call(paymentsAPI.paywaysListRefresh, paymentID);
			if (res && res.status === 200) {
				yield put(actions.listReload(paymentID));
			}
		} catch (error) {
			notifications.showError(messages.errorApiListRefresh, error);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* paywaysListSaga() {
	yield all([
		fork(listReload),
		fork(apiListRefresh),
	]);
}
