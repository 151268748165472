import { Map } from 'immutable';
import invert from 'lodash/invert';
import cloneDeep from 'lodash/cloneDeep';
import { fill } from '../../../../helpers/utils';
import { CATEGORY_LOG_MODELS } from '../../../../helpers/commonConstants';

import actions from './actions';

const modelsMap = invert(cloneDeep(CATEGORY_LOG_MODELS));

const initState = new Map({
	all               : [],
	category          : [],
	settings          : [],
	cms               : [],
	dynamicLimits     : [],
	markets           : [],
	names             : [],
	orders            : [],
	leagueRestrictions: [],
	leagueTournaments : [],
	sportRegulations  : [],
	marketsMargin     : [],

	UI: {
		loading: false,
	},
});

export default function categoryHistoryReducer(state = initState, action) {
	switch (action.type) {
	case actions.CATEGORY_HISTORY_MODEL_DATA_REFRESH: {
		const { logData, logModel } = action.data;
		const stateDataName = modelsMap[logModel];
		if (!stateDataName) {
			console.error(`CategoryHistoryReducer: Unknown log model ${logModel}`);
			return state;
		}

		return state.set(stateDataName, logData);
	}

	case actions.CATEGORY_HISTORY_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
