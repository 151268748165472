import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../../../helpers/utils';

const initState = new Map({
	entities: {},
	filter  : {},

	UI: { loading: false },
});

export default function freeBetListReducer(state = initState, action) {
	switch (action.type) {

	case actions.BONUSES_FREE_BETS_FILTER_REFRESH: {
		const { filter } = action.data;
		const target = state.get('filter');
		const result = fill(filter, target, true);
		return state.set('filter', result);
	}

	case actions.BONUSES_FREE_BETS_LIST_REFRESH: {
		const { entities } = action.data;
		return state.set('entities', entities);
	}

	case actions.BONUSES_FREE_BETS_UI_REFRESH: {
		const { UI } = action.data;
		const target = state.get('UI');
		const result = fill(UI, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
