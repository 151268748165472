import { sortBy, values } from 'lodash';
import EntityAdapter from '../../../../../../helpers/entityAdapter';


export const adaptBody = (customProviders, limits, userID) => {
	const sortedProvidersArr = sortBy(values(customProviders), ['name']);
	const providersObj       = sortedProvidersArr.reduce((next, provider) => {
		const { id } = provider;
		return {
			...next,
			[id]: {
				...provider,
				block     : false,
				maxStake  : null,
				minStake  : null,
				type      : 3,
				providerID: id,
				userID,
				disable   : true,
			},
		};
	}, {});
	limits.length && limits.map(limit => {
		if (!providersObj[limit.service_id]) return;

		providersObj[limit.service_id] = {
			...providersObj[limit.service_id],
			block     : limit.block,
			maxStake  : limit.max_stake,
			minStake  : limit.min_stake,
			type      : limit.type,
			providerID: limit.service_id,
			userID    : limit.user_id,
		};
		return providersObj[limit.service_id];
	});

	const result = Object.values(providersObj).map(item => item);
	return {
		providersArr: result,
		providersObj,
	};
};

export const fields                      = {
	userID     : 'user_id',
	minStake   : 'min_stake',
	maxStake   : 'max_stake',
	block      : 'block',
	type       : 'type',
	name       : 'name',
	limitTypeID: 'type',
	limitID    : 'id',
	providerID : 'service_id',
	categoryID : 'service_id',
	tagID      : 'service_id',
	gameID     : 'service_id',

};
export const createCustomProviderAdapter = () => {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.number, 'userID', fields.userID);
	adapter.addField(rules.nullOrNumber, 'minStake', fields.minStake);
	adapter.addField(rules.nullOrNumber, 'maxStake', fields.maxStake);
	adapter.addField(rules.number, 'type', fields.type);
	adapter.addField(rules.number, 'providerID', fields.providerID);
	adapter.addField(rules.bool, 'block', fields.block);

	return adapter;
};

export const prepareCustomProvider = (sendData = {}) => {
	const listAdapter  = createCustomProviderAdapter();
	const preparedData = listAdapter.prepare(sendData);
	return preparedData;
};


export const createCategoryAdapter = () => {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'limitTypeID', fields.limitTypeID);
	adapter.addField(rules.id, 'limitID', fields.limitID);
	adapter.addField(rules.id, 'categoryID', fields.categoryID);
	adapter.addField(rules.nullOrNumber, 'minStake', fields.minStake);
	adapter.addField(rules.nullOrNumber, 'maxStake', fields.maxStake);
	adapter.addField(rules.bool, 'block', fields.block);
	adapter.addField(rules.string, 'name', fields.name);


	return adapter;
};


export const createTagAdapter = () => {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'limitTypeID', fields.limitTypeID);
	adapter.addField(rules.id, 'limitID', fields.limitID);
	adapter.addField(rules.id, 'tagID', fields.tagID);
	adapter.addField(rules.nullOrNumber, 'minStake', fields.minStake);
	adapter.addField(rules.nullOrNumber, 'maxStake', fields.maxStake);
	adapter.addField(rules.bool, 'block', fields.block);
	adapter.addField(rules.string, 'name', fields.name);


	return adapter;
};


export const createGameAdapter = () => {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'limitTypeID', fields.limitTypeID);
	adapter.addField(rules.id, 'limitID', fields.limitID);
	adapter.addField(rules.id, 'gameID', fields.gameID);
	adapter.addField(rules.number, 'minStake', fields.minStake);
	adapter.addField(rules.number, 'maxStake', fields.maxStake);
	adapter.addField(rules.bool, 'block', fields.block);
	adapter.addField(rules.string, 'name', fields.name);


	return adapter;
};
export const prepareCategoryData = (sendData = {}) => {
	const listAdapter = createCategoryAdapter();
	return listAdapter.prepare(sendData);
};

export const prepareTagsData = (sendData = {}) => {
	const listAdapter = createTagAdapter();
	return listAdapter.prepare(sendData);
};

export const prepareGamesData = (sendData = {}) => {
	const listAdapter = createGameAdapter();
	return listAdapter.prepare(sendData);
};
