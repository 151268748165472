const actions = {

	FILTER_REFRESH                  : 'PERSONAL_FILTER_REFRESH',
	AUTOCOMPLETE_DATA_SOURCE_RELOAD : 'PERSONAL_FILTER_AUTOCOMPLETE_DATA_SOURCE_RELOAD',
	AUTOCOMPLETE_DATA_SOURCE_REFRESH: 'PERSONAL_FILTER_AUTOCOMPLETE_DATA_SOURCE_REFRESH',

	filterRefresh: (filterData) => ({
		type: actions.FILTER_REFRESH,
		data: {
			filterData,
		},
	}),
	dataSourceReload: (value, type) => ({
		type: actions.AUTOCOMPLETE_DATA_SOURCE_RELOAD,
		data: {
			value,
			type,
		},
	}),
	dataSourceRefresh: (dataSource, type) => ({
		type: actions.AUTOCOMPLETE_DATA_SOURCE_REFRESH,
		data: {
			dataSource,
			type,
		},
	}),
};

export default actions;
