import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	data: [],
	UI  : {
		loading: false,
	},
});

export default function MarketingChart(state = initState, action) {
	switch (action.type) {
	case actions.DASHBOARD_MARKETING_DATA_REFRESH: {
		return state.set('data', action.data);
	}
	case actions.DASHBOARD_MARKETING_CHART_UI_REFRESH: {
		return state.set('UI', action.data);
	}
	default:
		return state;
	}
}
