import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import notifications from '../../../../helpers/notifications';
import { historyAPI } from '../../../../helpers/api/history';
import { CATEGORY_LOG_MODELS } from '../../../../helpers/commonConstants';

import actions from './actions';
import { adaptData, adaptAllData } from './utils';

export const prefix = 'sport.categories.history';

export const messages = {
	loadData: `${prefix}.loadData`,
};
function* dataReload() {

	yield takeEvery(actions.CATEGORY_HISTORY_MODEL_DATA_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		let data = [];
		const { categoryID, logModel } = action.data;

		if (logModel === CATEGORY_LOG_MODELS.all) {
			data = yield allDataReload(categoryID);
		} else {
			data = yield modelDataReload(categoryID, logModel);
		}

		yield put(actions.dataRefresh(data, logModel));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* modelDataReload(categoryID, logModel) {
	let data = [];
	try {
		const response = yield call(
			historyAPI.historyCategoryLogs,
			categoryID,
			logModel
		);
		if (response && response.status === 200) {
			data = adaptData(response.data.data, logModel);
		}
	} catch (error) {
		notifications.showError(messages.loadData, error);
		console.log(error);
	}

	return data;
}

function* allDataReload(categoryID) {
	const category = yield modelDataReload(
		categoryID,
		CATEGORY_LOG_MODELS.category
	);
	const settings = yield modelDataReload(
		categoryID,
		CATEGORY_LOG_MODELS.settings
	);
	const cms = yield modelDataReload(categoryID, CATEGORY_LOG_MODELS.cms);
	const dynamicLimits = yield modelDataReload(
		categoryID,
		CATEGORY_LOG_MODELS.dynamicLimits
	);
	const markets = yield modelDataReload(
		categoryID,
		CATEGORY_LOG_MODELS.markets
	);
	const names = yield modelDataReload(categoryID, CATEGORY_LOG_MODELS.names);
	const orders = yield modelDataReload(categoryID, CATEGORY_LOG_MODELS.orders);
	const leagueRestrictions = yield modelDataReload(
		categoryID,
		CATEGORY_LOG_MODELS.leagueRestrictions
	);
	const leagueTournaments = yield modelDataReload(
		categoryID,
		CATEGORY_LOG_MODELS.leagueTournaments
	);
	const sportRegulations = yield modelDataReload(
		categoryID,
		CATEGORY_LOG_MODELS.sportRegulations
	);
	const marketsMargin = yield modelDataReload(
		categoryID,
		CATEGORY_LOG_MODELS.marketsMargin
	);

	const allData = adaptAllData({
		category,
		settings,
		cms,
		dynamicLimits,
		markets,
		names,
		orders,
		leagueRestrictions,
		leagueTournaments,
		sportRegulations,
		marketsMargin,
	});

	return allData;
}

export default function* categoryHistorySaga() {
	yield all([fork(dataReload)]);
}
