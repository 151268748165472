import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import { downloadReport } from '../../../helpers/utils';
import { depositsAPI } from '../../../helpers/api/deposits';
import { showError, showSuccess } from '../../../helpers/notifications';

import { createReportParams } from './utils';
import { TABLE_TYPES } from '../../../constants/tableTypes';

const prefix = 'rtm.reportDeposit';

const messages = {
	errorDataLoad     : `${prefix}.errorDataLoad`,
	errorRecalculate  : `${prefix}.errorRecalculate`,
	successRecalculate: `${prefix}.successRecalculate`,
};

const tableType      = TABLE_TYPES.depositRTM;

function getStoreData({ RTM, Tables }) {

	return {
		filter      : RTM.Deposits.get('filter'),
		userFilter  : RTM.UserDeposits.get('filter'),
		sorting     : Tables.get(tableType).sorting,
		reportFields: RTM.ReportDeposits.get('reportFields'),
	};
}

function* dataReload() {

	yield takeEvery(actions.REPORT_DEPOSITS_DATA_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		yield put(actions.dataRefresh(''));

		const { filter, sorting, reportFields, userFilter } = yield select(getStoreData);
		const { useUserFilter } = action.data;
		const actualFilter = useUserFilter ? userFilter : filter;
		const params = createReportParams(actualFilter, sorting, reportFields);
		try {
			const res = yield call(depositsAPI.rtmCSVLoad, params);
			if (res) {
				downloadReport(res);
			}

		} catch (error) {
			showError(messages.errorDataLoad, error);
			console.log(error);
		}

		yield put(actions.uiRefresh({
			isRecalculated: false,
			loading       : false,
		}));
	});
}

function* dataRecalculate() {

	yield takeEvery(actions.REPORT_DEPOSITS_RECALCULATE, function* () {

		yield put(actions.uiRefresh({ loading: true }));
		yield put(actions.dataRefresh(''));

		const { filter, sorting, visibleColumns } = yield select(getStoreData);
		const params = createReportParams(filter, sorting, visibleColumns);

		try {
			const res = yield call(depositsAPI.rtmCSVRecalculate, params);
			if (res && res.status === 200) {
				showSuccess(messages.successRecalculate);
				yield put(actions.uiRefresh({ isRecalculated: true }));
			}

		} catch (error) {
			showError(messages.errorRecalculate, error);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* reportDepositsSaga() {
	yield all([
		fork(dataReload),
		fork(dataRecalculate),
	]);
}
