import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	list: [],

	baseData: {
		subject: '',
		message: '',
		time   : null,
		date   : null,
		userID : null,
	},

	template: {
		content: '',
		name   : '',
		slug   : '',
		status : '1',
	},

	UI: {
		loading         : false,
		modalVisible    : false,
		modalCalendar   : false,
		modalTemplate   : false,
		addTemplate     : false,
		templateEditMode: false,
		modalWidth      : '40%',
	},

});

export default function userSMSReducer(state = initState, action) {

	switch (action.type) {

	case actions.USER_SEND_SMS_LIST_REFRESH: {
		return state.set('list', action.data);
	}

	case actions.USER_SEND_SMS_UI_REFRESH: {
		const UI  = action.data;
		const target = state.get('UI');
		const result = fill(UI, target, true);
		return state.set('UI', result);
	}

	case actions.USER_SEND_SMS_NEW_REFRESH: {
		const target = state.get('baseData');
		const result = fill(action.data, target, true);
		return state.set('baseData', result);
	}
	case actions.USER_SEND_SMS_TEMPLATE_REFRESH: {
		const target = state.get('template');
		const result = fill(action.data, target, true);
		return state.set('template', result);
	}

	case actions.BASE_DATA_RESET: {
		const initBase = initState.get('baseData');
		return  state.set('baseData', initBase);
	}

	default:
		return state;

	}

}
