import {cloneDeep, isArray, isEmpty, isPlainObject, keys, toInteger, toNumber} from 'lodash';
import {saveAs} from 'file-saver';
import {LIMIT_TYPES, TIME_UNITS} from './commonConstants';

// check if value is ID: integer number > 0
export function isID(value) {
	return Boolean(toInteger(value));
}

export function isZeroOrID(value) {
	return Boolean(toInteger(value)) || (value === 0);
}

export function isEqualID(id01, id02) {
	return toInteger(id01) === toInteger(id02);
}

// calculate an absolute limit value
export function calculateAbsoluteLimit(parentLimit = 0, percent = 0, format = true) {
	const result = (toNumber(parentLimit) * toNumber(percent)) / 100;
	if (format) {
		return result.toLocaleString('en-US', { minimumFractionDigits: 2 });
	}

	return result;
}

export function calculateLimit({ override, limit, limitTypeID, parentAbsolute }) {
	if (!override) {
		return 0;
	}

	const isPercent = toInteger(limitTypeID) === LIMIT_TYPES.percent;
	if (!isPercent) {
		return limit;
	}

	return calculateAbsoluteLimit(parentAbsolute, limit, false);
}

// filling object
export function fill(source, target, withCreatingKeys = false) {
	const sourceKeys = keys(source);
	const targetKeys = keys(target);
	const result = cloneDeep(target);
	sourceKeys.forEach(key => {
		if (!targetKeys.includes(key) && !withCreatingKeys) {
			return;
		}
		result[key] = source[key];
	});
	return result;
}

// map enum
export function mapEnum(enumList) {
	const result = {};
	enumList.forEach(item => {
		const key = item.id;
		result[key] = item.name;
	});

	return result;
}

//cut enum
export function cutEnum(enumList, IDs, field = 'id') {
	if (!isArray(enumList) || isEmpty(enumList)) {
		return [];
	}
	if (!isArray(IDs) || isEmpty(IDs)) {
		return enumList;
	}

	return enumList.filter(item => !IDs.includes(item[field]));
}

// entities map to list
export function entitiesToEnumList(entities = {}, nameField = null) {

	const IDs = Object.keys(entities);
	if (IDs.length === 0) {
		return [];
	}

	const enumList = IDs.map(ID => {
		const entity = cloneDeep(entities[ID]);
		const entityName = entity.name;

		entity.id = toInteger(ID);
		if (!entityName && nameField) {
			entity.name = entity[nameField];
		}

		return entity;
	});

	return enumList;
}

// list convert to entities
export function listToEntities(list = [], nameKey = 'id') {
	if (!isArray(list) || isEmpty(list)) {
		return {};
	}

	const result = {};

	list.forEach(item => {
		const keyValue = item[nameKey];
		if (!keyValue) {
			return;
		}

		result[keyValue] = cloneDeep(item);
	});

	return result;
}

// create columns for Tables
export function createColumn(dataKey, title, render = null, sorter = null, width = null, className = null, key = null) {
	const column = {
		title,
		dataIndex: dataKey,
		key      : key || dataKey,
	};
	if (render) {
		column.render = (text, record) => render(record, dataKey);
	}
	if (sorter) {
		column.sorter = (a, b) => sorter(a, b);
	}
	if (width) {
		column.width = width;
	}
	if (className) {
		column.className = className;
	}

	return column;
}

export function createColumnNoSort(dataKey, title,  render = null, withKey = null) {

	const column = withKey ? {
		title,
		dataIndex: dataKey,
		key      : dataKey,
		sorter   : true,
	} : {
		title,
		dataIndex: dataKey,
	};

	if (render) {
		column.render = (text, record) => render(record, dataKey);
	}

	return column;
}

export function adjustLanguages(list) {
	return list.map(({ text, dataBaseLangID }) => ({
		name: text,
		id  : dataBaseLangID,
	}));
}

export function isEmptyObject(obj = null) {
	if (!obj) {
		return true;
	}

	try {
		return (keys(obj) > 0);

	} catch (error) {
		return true;
	}
}

export function getHeadersTotalCount(headers = {}) {
	const targetName = 'x-total-count';
	const headerKeys = keys(headers);
	for (let i = 0; i < headerKeys.length; i++) {
		const key = headerKeys[i];
		if (String(key).toLowerCase() === targetName) {
			return toInteger(headers[key]);
		}
	}

	return 0;
}

// detect is JSON
export function isJSON(rawData) {
	try {
		const parsed = JSON.parse(rawData);
		return isPlainObject(parsed);
	} catch (err) {
		return false;
	}
}

//allows also dot numbers
export function allowNumberDot(value) {
	return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '').replace(/^0+(\d)/gm, '$1');
}

//allows only numbers
export function allowNumber(value) {
	return value.replace(/-[^0-9]/g, '');
}

/**
 * Creates debounced function with possibility to revoke last invocation
 * @param func
 * @param delay
 * @param timeoutRef
 */
export function debounce(func, ms = 0) {
	let timer = null;

	return function (...args) {
		const onComplete = () => {
			func.apply(this, args);
			timer = null;
		};

		if (timer) {
			clearTimeout(timer);
		}

		timer = setTimeout(onComplete, ms);
	};
}

export function downloadReport(data) {
	let filename = '';
	const disposition = data.headers['content-disposition'];
	if (disposition && disposition.indexOf('attachment') !== -1) {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		const matches = filenameRegex.exec(disposition);
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	}
	const blobData = new Blob([data.data], { type: 'text/csv' });
	saveAs(blobData, filename);
}

export function allowOnlyNumbers(val) {
	const reg = /^\d+$/;
	let value;
	if (val === undefined || val === null) {
		value = '';
	} else if (reg.test(val)) {
		value = val;
	} else if (!reg.test(val[0])) {
		value = val.substr(1);
	} else {
		value = val.slice(0, -1);
	}
	return value;
}

export function allowOnlyFloatNumbers(val) {
	const reg = /^-?\d*[.,]?\d*$/;
	let value;
	if (val === undefined || val === null) {
		value = '';
	} else if (reg.test(val)) {
		value = val;
	} else if (!reg.test(val[0])) {
		value = val.substr(1);
	} else {
		value = val.slice(0, -1);
	}
	return value;
}

export function alphaOnly(event) {
	const key = event.keyCode;
	return ((key >= 65 && key <= 90) || key === 8);
}

export function resetGamesInCategory(arr1, arr2) {
	if (arr1 && arr2) {
		const uniqueSet = new Set([...arr1, ...arr2]);
		const uniqueArray = Array.from(uniqueSet);

		return uniqueArray.filter((value) => {
			return arr1.includes(value) !== arr2.includes(value);
		});
	}
	return [];
}

export function resetTheSameValue(arr1, arr2) {
	if (arr1 && arr2) {
		const uniqueSet = [...new Set([...arr1, ...arr2])];

		return uniqueSet.filter((value) => {
			return arr1.some(item => item.id === value.id) !== arr2.some(item => item.id === value.id);
		});
	}
	return [];
}

export function theSameValue(arr1, arr2) {
	if (arr1 && arr2) {
		const uniqueSet = arr1.length <= arr2.length ? arr1 : [...new Set([...arr1, ...arr2])];

		return uniqueSet.filter((value) => {
			return arr1.some(item => item.id === value.id) === arr2.some(item => item.id === value.id);
		});
	}
	return [];
}

export function setAllCheckedCategories({ bonusData, checkedAllGames, categoryList, gameIDs }) {
	const { categoryIDs } = bonusData;
	categoryIDs.map(item => checkedAllGames({ categoryID: item, games: [], checkedAll: true, into: true } ));
	if (bonusData.count.categories) {
		const ids = Object.keys(bonusData.count.categories);
		const checkedGamesByCategory = [];
		const readyGames = {};

		categoryList.forEach(item => {
			return ids.forEach(itemId => {
				if (item.id === +itemId) {
					checkedGamesByCategory.push({ games: item.games.map(it => it.id), categoryID: item.id });
				}
			});
		});

		checkedGamesByCategory.forEach(item => {
			item.games.forEach(gameID => gameIDs.forEach(it => {
				if (gameID === it) {
					readyGames[item.categoryID] = readyGames[item.categoryID] ? [...readyGames[item.categoryID], gameID] : [gameID];
				}
			} ));
		});
		Object.entries(readyGames).forEach(item => checkedAllGames({ categoryID: item[0], games: item[1], checkedAll: false, into: true } ));
	}
}

export function formatMillisecondsToDuration(date) {
	const items = [];

	items.push({ timeUnit: TIME_UNITS.days, value: date.days() });
	items.push({ timeUnit: TIME_UNITS.hours, value: date.hours() });
	items.push({ timeUnit: TIME_UNITS.minutes, value: date.minutes() });
	items.push({ timeUnit: TIME_UNITS.seconds, value: date.seconds() });

	return items.reduce((accumulator, { value, timeUnit }) => {
		if (accumulator.length === 0 && value === 0) {
			return accumulator;
		}

		accumulator.push({ value, timeUnit });

		return accumulator;
	}, []);
}


export const formatMilliseconds = (milliseconds) => {
	const msPerSecond = 1000;
	const msPerMinute = 60 * msPerSecond;
	const msPerHour = 60 * msPerMinute;
	const msPerDay = 24 * msPerHour;

	const days = Math.floor(milliseconds / msPerDay);
	milliseconds %= msPerDay;

	const hours = Math.floor(milliseconds / msPerHour);
	milliseconds %= msPerHour;

	const minutes = Math.floor(milliseconds / msPerMinute);
	milliseconds %= msPerMinute;

	const seconds = Math.floor(milliseconds / msPerSecond);
	milliseconds %= msPerSecond;

	let formattedTime = '';

	if (days > 0) {
		formattedTime += `${days.toString().padStart(2, '0')} `;
	}

	formattedTime += `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds.toString().padStart(3, '0')}`;

	return formattedTime;
};


export const formatAlias = (alias = '') => {
	return alias.replace(/[^\p{L}\p{N}]/gu, '_');
};

export  function capitalizeFirstLetter(str = '') {
	return str.charAt(0).toUpperCase() + str.slice(1);
}
