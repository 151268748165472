import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';
import actions from './actions';

import { securityAPI } from '../../../../helpers/api/security';
import notifications from '../../../../helpers/notifications';

import { adaptQuestionsList, prepareQuestion } from './utils';

const prefix = 'security.questions';

const messages = {
	errorListLoad        : `${prefix}.errorListLoad`,
	errorQuestionSave    : `${prefix}.errorQuestionSave`,
	errorQuestionDelete  : `${prefix}.errorQuestionDelete`,
	successQuestionSave  : `${prefix}.successQuestionSave`,
	successQuestionDelete: `${prefix}.successQuestionDelete`,
};

const getStoreData = ({ App, Settings }) => ({
	entities           : Settings.Security.Questions.get('entities'),
	UI                 : Settings.Security.Questions.get('UI'),
	websiteId          : App.get('websiteID'),
	contentLanguageList: App.get('websiteAttachedLanguages'),
});

function* listReload() {
	yield takeEvery(actions.SECURITY_QUESTIONS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { contentLanguageList } = yield select(getStoreData);

		let entities = {};
		try {
			const res = yield call(securityAPI.questionsList);
			if (res && res.status === 200) {
				entities = adaptQuestionsList(res.data.data, contentLanguageList);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* saveQuestion() {

	yield takeEvery(actions.SECURITY_QUESTIONS_LIST_SAVE_ITEM, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const storeData = yield select(getStoreData);

		let { questionID } = action.data;
		const { editMode, closeModal, names } = storeData.UI;
		const { websiteId } = storeData;


		const params = {
			website_id: websiteId,
		};

		let isError = false;
		try {
			const preparedData = prepareQuestion(names, questionID);

			if (editMode) {
				const res = yield call(securityAPI.questionUpdate, questionID, preparedData, params);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successQuestionSave);
					yield put(actions.uiRefresh({ isChanged: false }));
				}

			} else {
				const res = yield call(securityAPI.questionAdd, preparedData, params);
				if (res && res.status === 200) {
					questionID = toInteger(res.data.data.id);
					notifications.showSuccess(messages.successQuestionSave);
					yield put(actions.uiRefresh({
						questionID,
						editMode : true,
						isChanged: false,
					}));
				}
			}

		} catch (error) {
			isError = true;
			notifications.showError(messages.errorQuestionSave);
		}

		if (!isError) {
			yield put(actions.listReload());
		}
		yield put(actions.uiRefresh({
			loading: false,
			visible: !(closeModal && !isError),
		}));
	});
}

function* deleteQuestion() {
	yield takeEvery(actions.SECURITY_QUESTIONS_LIST_DELETE_ITEM, function* (
		action
	) {
		yield put(actions.uiRefresh({ loading: true }));

		const { questionID } = action.data;
		try {
			const res = yield call(securityAPI.questionDelete, questionID);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successQuestionDelete);
			}
		} catch (error) {
			notifications.showError(messages.errorQuestionDelete);
			console.log(error);
		}

		yield put(actions.listReload());
	});
}

export default function* websitesListSaga() {
	yield all([
		fork(listReload),
		fork(deleteQuestion),
		fork(saveQuestion),
	]);
}
