import { all, call, takeEvery, put, fork, select } from 'redux-saga/effects';
import { TABLE_TYPES } from '../../../constants/tableTypes';
import { usersAPI } from '../../../helpers/api/users';
import notifications, { showError, showSuccess } from '../../../helpers/notifications';
import { deriveTablePagination } from '../../../selectors/tables';
import actions from './actions';
import { adaptDocuments, getListParams } from './utils';
import { documentAPI  } from '../../../helpers/sockets/api/documents';
import { createError } from '../../../helpers/sockets/socket';
import tableActions from '../../tables/actions';
import socetActions from '../../socket/actions';

import externalSaga from './externalSaga';
import { getHeadersTotalCount } from '../../../helpers/utils';

const prefix = 'rtm.documents';

const messages = {
	errorListReload       : `${prefix}.errorListReload`,
	errorStatusUpdate     : `${prefix}.errorStatusUpdate`,
	errorSubscribeUpdate  : `${prefix}.errorSubscribeUpdate`,
	successStatusUpdate   : `${prefix}.successStatusUpdate`,
	successSubscribeUpdate: `${prefix}.successSubscribeUpdate`,
};
const tableType = TABLE_TYPES.documentsRTM;

const getBaseData = (state) => {
	return {
		baseData   : state.RTM.Documents.get('baseData'),
		pagination : deriveTablePagination(tableType, state),
		sorting    : state.Tables.get(tableType).sorting,
		filter     : state.RTM.Documents.get('filter'),
		activeTabID: state.AppTabs.get('activeTabID'),
	};
};

function* documentsListReload() {

	const takeAction = actions.RELOAD;

	yield takeEvery(takeAction, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { pagination, sorting, filter, activeTabID } = yield select(getBaseData);

		const params = getListParams(pagination, sorting, filter);
		let totalCount = 0;

		try {
			const res = yield call(usersAPI.documentsList, params);
			if ( res && res.status === 200) {
				totalCount = getHeadersTotalCount(res.headers);
				const result = adaptDocuments(res.data.data);
				const { documentsIDs, entities } = result;
				yield put(actions.dataRefresh(documentsIDs, entities));
				yield put(socetActions.rtmSubscribe(activeTabID, documentsIDs));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));

		} catch (e) {
			showError(messages.errorListReload, e);
			console.log(e);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* statusUpdate() {

	yield takeEvery(actions.ITEM_STATUS_UPDATE, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { userId, statusId, documentId } = action.data;
		const data = {
			id       : documentId,
			status_id: statusId,
		};

		try {
			const res = yield call(usersAPI.userDocumentUpdate, userId, documentId, data);
			if (res && res.status === 200) {
				showSuccess(messages.successStatusUpdate);
			}
		} catch (error) {
			showError(messages.errorStatusUpdate, error);
			console.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {

	yield takeEvery(actions.RTM_DOCUMENTS_FILTER_APPLY, function* () {
		yield put(actions.listReload());
	});
}


function* subscribeToUpdate() {
	yield takeEvery(actions.RTM_DOCUMENTS_SOCKET_SUBSCRIBE_TO_UPDATE, function* (action) {
		const { documentIDs } = action.data;
		try {
			const response = yield call(documentAPI.subscribeToUpdate, documentIDs);
			if (response) {
				if (response.isSuccess) {
					//notifications.showSuccess(messages.successSubscribeUpdate);
				} else {
					const error = createError(response);
					throw error;
				}
			}
		} catch (error) {
			notifications.showError(messages.errorSubscribeUpdate, error);
			console.log(error);
		}
	});
}


export default function* rtmDocumentsSaga() {
	yield all([
		fork(documentsListReload),
		fork(statusUpdate),
		fork(filterApply),
		fork(subscribeToUpdate),

		fork(externalSaga),
	]);
}
