import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	systemSelections             : [],
	mappedMarketList             : [],
	unmappedMarketList           : [],
	mappedAndUnmappedMarketList  : [],
	outcomesData  			     : {},

	modalUI: {
		marketID                 : null,
		marketName  			 : '',
		tradingMode 			 : null,
		providerID               : null,
		providerMarketID         : null,
		visible                  : false,
		loading                  : false,
		closeModal               : false,
		isChanged                : false,
	},
});

export default function marketMappingReducer(state = initState, action) {

	switch (action.type) {

	case actions.MARKET_MAPPING_MAPPED_LIST_REFRESH: {
		return state.set('mappedMarketList', action.data);
	}

	case actions.MARKET_MAPPING_UNMAPPED_LIST_REFRESH: {
		return state.set('unmappedMarketList', action.data);
	}

	case actions.MARKET_MAPPING_MAPPED_AND_UNMAPPED_LIST_REFRESH: {
		return state.set('mappedAndUnmappedMarketList', action.data);
	}

	case actions.MARKET_MAPPING_SYSTEM_SELECTIONS_REFRESH: {
		return state.set('systemSelections', action.data);
	}

	case actions.MARKET_MAPPING_OUTCOMES_DATA_REFRESH: {
		return state.set('outcomesData', action.data);
	}

	case actions.MARKET_MAPPING_MODAL_UI_REFRESH: {
		const target = state.get('modalUI');
		const result = fill(action.data, target);

		return state.set('modalUI', result);
	}

	case actions.MARKET_MAPPING_MODAL_UI_RESET: {
		return initState;
	}

	default:
		return state;
	}
}
