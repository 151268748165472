const actions = {
	CASINO_GAME_BULK_DATA_REFRESH: 'CASINO_GAME_BULK_DATA_REFRESH',
	CASINO_GAME_BULK_DATA        : 'CASINO_GAME_BULK_DATA',
	CASINO_GAME_BULK_DATA_GAMES  : 'CASINO_GAME_BULK_DATA_GAMES',
	BASE_DATA_REFRESH            : 'BASE_DATA_REFRESH',
	GET_SEARCH_TAG               : 'GET_SEARCH_TAG',
	GET_TAGS_SUCCESS             : 'GET_TAGS_SUCCESS',

	CASINO_GAME_BULK_UI_REFRESH: 'CASINO_GAME_BULK_UI_REFRESH',
	UNSELECT_BULK_DATA         : 'UNSELECT_BULK_DATA',
	BULK_UPDATE                : 'BULK_UPDATE',
	GAME_WITH_PAGE_CASE        : 'GAME_WITH_PAGE_CASE',

	bulkDataRefresh: data => ({
		type: actions.CASINO_GAME_BULK_DATA_REFRESH,
		data,
	}),

	unselectBulk: () => ({
		type: actions.UNSELECT_BULK_DATA,
	}),

	bulkDataGames: data => ({
		type: actions.CASINO_GAME_BULK_DATA_GAMES,
		data,
	}),

	bulkData: data => ({
		type: actions.CASINO_GAME_BULK_DATA,
		data,
	}),

	baseDataRefresh: data => ({
		type: actions.BASE_DATA_REFRESH,
		data,
	}),


	getLoadTags: (data) => ({
		type: actions.GET_SEARCH_TAG,
		data,
	}),

	putTagsCase: data => ({
		type: actions.GET_TAGS_SUCCESS,
		data,
	}),

	updateBulk: data => ({
		type: actions.BULK_UPDATE,
		data,
	}),

	currentPageIDsRefresh: data => ({
		type: actions.GAME_WITH_PAGE_CASE,
		data,
	}),

	bulkUIRefresh: data => ({
		type: actions.CASINO_GAME_BULK_UI_REFRESH,
		data,
	}),
};

export default actions;
