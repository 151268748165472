import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import listActions from '../list/actions';

import { bannersAPI } from '../../../../helpers/api/banners';
import notifications from '../../../../helpers/notifications';

import { adaptBaseData, adaptDescData, prepareBaseData, prepareDescData } from './utils';

const prefix = 'cms.banners.';
const messages = {
	errorBaseDataReload   : `${prefix}loading.data.failed`,
	errorDescDataReload   : `${prefix}loading.description.failed`,
	errorBannerSave       : `${prefix}saving.data.failed`,
	errorDescriptionSave  : `${prefix}saving.description.failed`,
	successBannerSave     : `${prefix}saved.success`,
	successDescriptionSave: `${prefix}saved.description.success`,
};


function getStoreData({ CMS, App }) {

	return {
		baseData : CMS.Banners.Banner.get('baseData'),
		descData : CMS.Banners.Banner.get('descData'),
		UI       : CMS.Banners.Banner.get('UI'),
		websiteID: App.get('websiteID'),
	};
}

function* baseDataReload() {

	yield takeEvery(actions.BANNER_BASE_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { UI } = yield select(getStoreData);
		const { bannerID } = action.data;
		const { editMode } = UI;

		let baseData = {};

		if (!editMode) {

			yield put(actions.baseDataRefresh(baseData));
			yield put(actions.uiRefresh({
				loading      : false,
				isBaseChanged: false,
			}));

			return;
		}

		try {
			const res = yield call(bannersAPI.bannerInfo, bannerID);
			if (res && res.status === 200) {
				baseData = adaptBaseData(res.data.data);
			}

		} catch (error) {
			notifications.showError(messages.errorBaseDataReload);
			console.log(error);
		}

		yield put(actions.baseDataRefresh(baseData));
		yield put(actions.uiRefresh({
			loading      : false,
			isBaseChanged: false,
		}));
	});
}

function* descDataReload() {

	yield takeEvery(actions.BANNER_DESC_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { UI } = yield select(getStoreData);
		const { bannerID, websiteID } = action.data;
		const { editMode, langID, channelID } = UI;

		let descData = adaptDescData({});

		if (!editMode) {
			yield put(actions.descDataRefresh(descData));
			yield put(actions.uiRefresh({ loading: false }));

			return;
		}

		const params = {
			lang_id   : langID,
			channel_id: channelID,
		};
		try {
			const res = yield call(bannersAPI.bannerDescInfo, bannerID, websiteID, params);
			if (res && res.status === 200) {
				descData = adaptDescData(res.data.data);
			}

		} catch (error) {
			notifications.showError(messages.errorDescDataReload);
			console.log(error);
		}

		yield put(actions.descDataRefresh(descData));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* dataSave() {

	yield takeEvery(actions.BANNER_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { baseData, descData, UI, websiteID } = yield select(getStoreData);
		const { closeModal, editMode, langID, channelID, isDescChanged } = UI;

		let { bannerID } = UI;

		const bannerBaseData = cloneDeep(baseData);
		const bannerDescData = cloneDeep(descData);

		let isError = false;
		bannerBaseData.websiteID = websiteID;
		try {
			// base data
			const preparedData = prepareBaseData(bannerBaseData);
			if (editMode) {
				yield call(bannersAPI.bannerUpdate, bannerID, preparedData);

			} else {
				const res = yield call(bannersAPI.bannerAdd, preparedData);
				bannerID = toInteger(res.data.data.id);
				yield put(actions.uiRefresh({ editMode: true, bannerID }));
			}

			yield put(actions.uiRefresh({ isBaseChanged: false }));

			// description
			if (isDescChanged) {
				bannerDescData.bannerID = bannerID;
				const preparedData = prepareDescData(bannerDescData, websiteID, langID, channelID);
				yield call(bannersAPI.bannerDescUpdate, bannerID, preparedData);
			}

			notifications.showSuccess(messages.successBannerSave);

		} catch (error) {
			isError = true;
			notifications.showError(messages.errorBannerSave, error);
		}

		if (!isError && !closeModal) {
			yield put(actions.baseDataReload(bannerID, true));
			yield put(actions.descDataReload(bannerID, websiteID));
		}

		yield put(listActions.listReload());
		yield put(
			actions.uiRefresh({
				loading: false,
				visible: !(closeModal && !isError),
			})
		);
	});
}

export default function* bannerModalSaga() {
	yield all([
		fork(baseDataReload),
		fork(descDataReload),
		fork(dataSave),
	]);
}
