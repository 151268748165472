import { all, call, takeEvery, fork, put, select } from 'redux-saga/effects';
import actions from './actions';
import { usersAPI } from '../../../../../../helpers/api/users';
import { casinoAPI } from '../../../../../../helpers/api/casino';
import { LIMITS_CASINO_OPTIONS_TYPES } from '../../../../../../constants/users';
import { adaptIntegratorWProviderListLimits, adaptGameListLimits } from './utils';
import { showError, showSuccess } from '../../../../../../helpers/notifications';


const prefix = 'users.limits.game';

const messages = {
	successLimitSave: `${prefix}.successLimitSave`,
	errorSave       : `${prefix}.errorSave`,
};

function getStoreData({ Users }) {

	return {
		tree            : Users.UserInfo.UserLimitsAndRestrictions.Casino.Game.get('intProvGamLimits'),
		gamesLimits     : Users.UserInfo.UserLimitsAndRestrictions.Casino.Game.get('gamesLimits'),
		providersWlimits: Users.UserInfo.UserLimitsAndRestrictions.Casino.Game.get('providersWlimits'),
	};
}

function* treeIntegratorAndProviderReload() {

	yield takeEvery(actions.LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_INTEGRATOR_PROVIDER_RELOAD, function* (action) {

		const integratorOptionID = LIMITS_CASINO_OPTIONS_TYPES.integrator;

		const { userID }              = action.data;
		const integratorLimitsParams = { userID, typeID: integratorOptionID };

		let entities = [];

		try {
			const responds = yield all([
				call(casinoAPI.integratorsListWithProviders),
				call(usersAPI.userLimitsCasinoSelect, integratorLimitsParams),
			]);

			if (responds.filter(resItem => resItem && resItem.status === 200)) {
				entities = adaptIntegratorWProviderListLimits(responds);
			}
		} catch (e) {
			console.log(e);
		}

		yield put(actions.treeIntProvGamLimitsRefresh(entities.integrators));
		yield put(actions.providersWlimitsObjectRefresh(entities.providers));
		yield put(actions.gamesLimitsRefresh(entities.gamesLimits));

	});

}


function* treeGamesReload() {

	yield takeEvery(actions.LIMIT_AND_RESTRICTION_CASINO_TREE_GAME_GAMES_RELOAD, function* ({ data }) {

		const { providerID } = data;

		const { tree, gamesLimits, providersWlimits } = yield select(getStoreData);

		let entities = {};

		try {
			const res = yield call(casinoAPI.gamesListByProvider, providerID);

			if (res && res.status === 200) {
				entities = adaptGameListLimits(res.data.data, gamesLimits, tree, providersWlimits, providerID);
			}

		} catch (e) {
			console.log(e);
		}

		yield put(actions.treeIntProvGamLimitsRefresh(entities));

	});

}

function* saveLimits() {

	yield takeEvery(actions.LIMIT_AND_RESTRICTION_CASINO_LIMIT_SAVE_RELOAD, function* (action) {

		const { userID, typeID, limitsObject, serviceID } = action.data;

		const data = {
			min_stake : limitsObject.minStake > 0 ? limitsObject.minStake :  null,
			max_stake : limitsObject.maxStake > 0 ? limitsObject.maxStake :   null,
			block     : limitsObject.block,
			user_id   : userID,
			service_id: serviceID,
			type      : typeID,
		};

		const ids = { userID, type: typeID };

		try {
			const res = yield call(usersAPI.userLimitsCasinoUpdate, data, ids);

			if (res && res.status === 200) {
				showSuccess(messages.successLimitSave);

				yield put(actions.treeIntProvGamLimitsReload({ userID }));
				yield put(actions.uiRefresh({ loading: false }));
			}

		} catch (e) {
			showError(messages.errorSave);
			console.log(e);
		}

	});

}

export default function* userGroupSaga() {
	yield all([
		fork(treeIntegratorAndProviderReload),
		fork(treeGamesReload),
		fork(saveLimits),
	]);
}
