import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	settingsList: [],
	savingData  : [],

	modalUI: {
		visible     : false,
		loading     : false,
		sportID     : null,
		categoryID  : null,
		categoryName: null,
		closeModal  : false,
		isChanged   : false,
	},
});

export default function marketSettingsReducer(state = initState, action) {

	switch (action.type) {

	case actions.DATA_RESET: {
		return initState;
	}

	case actions.DATA_REFRESH: {
		const { settingsList } = action.data;
		return state.set('settingsList', settingsList);
	}

	case actions.SAVING_DATA_REFRESH: {
		const { savingData } = action.data;
		return state.set('savingData', savingData);
	}

	case actions.MODAL_UI_REFRESH: {
		const target = state.get('modalUI');
		const { uiData } = action.data;
		const result = fill(uiData, target);
		return state.set('modalUI', result);
	}

	default:
		return state;
	}
}
