import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './sagas';
import { configureStore, Tuple } from "@reduxjs/toolkit";

const sagaMiddleware  = createSagaMiddleware();
const appReducer = combineReducers({
	...reducers,
});

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT') {
		return appReducer({ LanguageSwitcher: state.LanguageSwitcher }, action);
	}
	return appReducer(state, action);
};

const store = configureStore({
	reducer: rootReducer,
	middleware: () => new Tuple(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
