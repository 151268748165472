import { Map } from 'immutable';
import invert from 'lodash/invert';
import cloneDeep from 'lodash/cloneDeep';
import { fill } from '../../../helpers/utils';
import { SELECTION_LOG_MODELS } from '../../../helpers/commonConstants';

import actions from './actions';

const modelsMap = invert(cloneDeep(SELECTION_LOG_MODELS));

const initState = new Map({
	all          : [],
	selection    : [],
	names        : [],
	orders       : [],
	settings     : [],
	dynamicLimits: [],

	UI: {
		loading: false,
	},
});

export default function selectionHistoryReducer(state = initState, action) {
	switch (action.type) {
	case actions.SELECTION_HISTORY_MODEL_DATA_REFRESH: {
		const { logData, logModel } = action.data;
		const stateDataName = modelsMap[logModel];
		if (!stateDataName) {
			console.error(`SelectionHistoryReducer: Unknown log model ${logModel}`);
			return state;
		}

		return state.set(stateDataName, logData);
	}

	case actions.SELECTION_HISTORY_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
