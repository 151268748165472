const actions = {

	SETTINGS_STATISTIC_TYPES_LIST_RELOAD : 'SETTINGS_STATISTIC_TYPES_LIST_RELOAD',
	SETTINGS_STATISTIC_TYPES_LIST_REFRESH: 'SETTINGS_STATISTIC_TYPES_LIST_REFRESH',

	SETTINGS_STATISTIC_TYPES_SAVE_ITEM  : 'SETTINGS_STATISTIC_TYPES_SAVE_ITEM',
	SETTINGS_STATISTIC_TYPES_SAVE_ALL   : 'SETTINGS_STATISTIC_TYPES_SAVE_ALL',
	SETTINGS_STATISTIC_TYPES_DELETE_ITEM: 'SETTINGS_STATISTIC_TYPES_DELETE_ITEM',

	SETTINGS_STATISTIC_TYPES_FILTER_REFRESH: 'SETTINGS_STATISTIC_TYPES_FILTER_REFRESH',
	SETTINGS_STATISTIC_TYPES_UI_REFRESH	   : 'SETTINGS_STATISTIC_TYPES_UI_REFRESH',

	SETTINGS_STATISTIC_TYPES_CHANGED_ID_ADD   : 'SETTINGS_STATISTIC_TYPES_CHANGED_ID_ADD',
	SETTINGS_STATISTIC_TYPES_CHANGED_IDS_RESET: 'SETTINGS_STATISTIC_TYPES_CHANGED_IDS_RESET',

	listReload: () => ({
		type: actions.SETTINGS_STATISTIC_TYPES_LIST_RELOAD,
	}),
	listRefresh: list => ({
		type: actions.SETTINGS_STATISTIC_TYPES_LIST_REFRESH,
		data: list,
	}),

	saveItem: data => ({
		type: actions.SETTINGS_STATISTIC_TYPES_SAVE_ITEM,
		data,
	}),
	saveAll: list => ({
		type: actions.SETTINGS_STATISTIC_TYPES_SAVE_ALL,
		data: list,
	}),
	deleteItem: typeID => ({
		type: actions.SETTINGS_STATISTIC_TYPES_DELETE_ITEM,
		data: {
			typeID,
		},
	}),

	filterRefresh: data => ({
		type: actions.SETTINGS_STATISTIC_TYPES_FILTER_REFRESH,
		data,
	}),
	uiRefresh: data => ({
		type: actions.SETTINGS_STATISTIC_TYPES_UI_REFRESH,
		data,
	}),

	changedIDAdd: typeID => ({
		type: actions.SETTINGS_STATISTIC_TYPES_CHANGED_ID_ADD,
		data: {
			typeID,
		},
	}),
	changedIDsReset: () => ({
		type: actions.SETTINGS_STATISTIC_TYPES_CHANGED_IDS_RESET,
	}),
};

export default actions;
