import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	list    : [],
	entities: {},

	UI: { loading: false },
});

export default function currencyReducer(state = initState, action) {

	switch (action.type) {

	// Currency List ------------------------------------------------------------------------------
	case actions.LANGUAGE_REFRESH_LIST: {
		return state.set('list', action.data);
	}
	case actions.UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target, fill);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
