import React, { Component, createRef } from 'react';
import * as PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import isEqual from 'lodash/isEqual';
import { Select } from 'antd';
import  { SelectOption } from './select';
import {
	ContainerItem, SelectIconSpan,

	Wrapper,
} from './styles/selectList.style';

/**
 * Additional props:
 *
 * optionsList - array of Object. Example: [{ id: 1, name: 'One' }, { id: 2,
 * name: 'Two' }] keyID - key of Object for getting ID value. Default: 'id'
 * keyName - key of Object for getting Name value. Default: 'name' dataName -
 * name of element Select
 *
 * onChange() invoke function from props & pass to it values:
 *
 * value - selected value. String
 * dataName - name of element Select. String
 * dataref - selected object from optionsList. String
 * dataindex - index of selected object from optionsList. Number
 * option - selected Option. React-Component
 */

class SelectList extends Component {

	static propTypes = {
		optionsList: PropTypes.arrayOf(PropTypes.shape({
			id       : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name     : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
			className: PropTypes.string,
		})),

		keyID            : PropTypes.string,
		title            : PropTypes.string,
		name             : PropTypes.string,
		value            : PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		keyName          : PropTypes.string,
		dataName         : PropTypes.string,
		wrapperClass     : PropTypes.string,
		wrapperID        : PropTypes.string,
		onChange         : PropTypes.func,
		onSelect         : PropTypes.func,
		usePopupContainer: PropTypes.bool,
		showSearch       : PropTypes.bool,
		atOnceSearch     : PropTypes.bool,
		onSearch         : PropTypes.func,

		preFixIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),

	};

	static defaultProps = {
		onSearch    					: () => {},
		optionsList      : [],
		keyID            : 'id',
		title            : '',
		keyName          : 'name',
		dataName         : 'selectList',
		wrapperClass     : null,
		wrapperID        : null,
		onChange         : null,
		onSelect         : null,
		value            : '',
		name             : '',
		usePopupContainer: true,
		showSearch   		 : true,
		atOnceSearch     : false,

		preFixIcon: null,
	};

	constructor(props) {
		super(props);
		this.renderOptions     = this.renderOptions.bind(this);
		this.handleChange      = this.handleChange.bind(this);
		this.getPopupContainer = this.getPopupContainer.bind(this);

		this.state = {
			lastValue : null,
			lastOption: null,

		};
		this.optRef = createRef();
		this.uid = uuid();
	}

	renderOptions() {
		const { optionsList, keyID, keyName, title, preFixIcon } = this.props;
		const result = optionsList.map((item, index) => {
			const key  = item[keyID];
			const name = item[keyName];
			const { icon, logo } = item;
			let optionDisabled = false;

			if ( item.optionDisabled ) {
				optionDisabled = true;
			}
			const path = window.location.origin;
			const tempIcon = logo ? `${path}${logo}` : icon;
			return (
				<SelectOption disabled={optionDisabled} value={String(key)} key={key} dataref={item} dataindex={index} title={title}>
					<ContainerItem>
						{icon && (
							<img
								style={{
									marginRight: 6,
									width      : 18,
								}}
								src={tempIcon}
								alt="icon"
							/>
						)
						}
						<SelectIconSpan withIcon={!!preFixIcon}>
							{name}
						</SelectIconSpan>
					</ContainerItem>
				</SelectOption>
			);
		});
		return result;
	}

	handleChange(value, option) {
		const { dataName, onChange, onSelect } = this.props;
		if (!onChange && !onSelect) {
			return;
		}

		// because event 'onChange' rises two time in this component
		const { lastValue, lastOption } = this.state;
		if (isEqual(lastValue, value) && isEqual(lastOption, option)) {
			return;
		}

		const { dataref, dataindex } = option.props;

		if (onChange) {

			this.setState({
				lastValue: value,
				lastOption,
			}, () => {
				onChange(value, dataName, dataref, dataindex, option);
			});

		} else if (onSelect) {

			this.setState({
				lastValue: value,
				lastOption,
			}, () => {
				onSelect(value, dataName, dataref, dataindex, option);
			});
		}
	}

	getPopupContainer() {
		const { wrapperID } = this.props;
		const id = wrapperID || this.uid;

		return document.getElementById(id);
	}


	render() {
		const { wrapperClass, atOnceSearch, wrapperID, usePopupContainer, name, showSearch, preFixIcon, ...restProps } = this.props;
		const options = this.renderOptions();
		return (
			<Wrapper withIcon={!!preFixIcon} className={wrapperClass} name={name} id={wrapperID || this.uid}>
				{preFixIcon}
				<Select
					{...restProps}
					onPopupScroll={this.onScroll}
					showSearch={showSearch}
					onSelect={this.handleChange}
					onChange={() => null}
					filterOption={(input, option) => {
						if (!atOnceSearch) {
							if (input.length >= 3) {
								return option.props.dataref.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
							}
							return option;
						}
						return option.props.dataref.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
					}
					}
					getPopupContainer={usePopupContainer ? this.getPopupContainer : null}
				>
					{options}
				</Select>
			</Wrapper>
		);
	}
}

export default SelectList;
