import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	entities: {},
	filter  : {},
	UI      : {
		loading: false,
	},

});

export default function emailsListReducer(state = initState, action) {

	switch (action.type) {

	case actions.LIST_REFRESH: {
		const { entities } = action.data;
		return state.set('entities', entities);
	}

	case actions.UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target, true);
		return state.set('UI', result);
	}

	case actions.LIST_FILTER_REFRESH: {
		const target = state.get('filter');
		const result = fill(action.data, target, true);
		return state.set('filter', result);
	}

	case actions.DATA_RESET: {
		return  initState;
	}

	default:
		return state;
	}
}
