import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import find from 'lodash/find';
import { Tabs } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import tabsActions from '../../redux/appTabs/actions';
import socketActions from '../../redux/socket/actions';
import userActions from '../../redux/users/user/actions';
import notifActions from '../../redux/notifications/actions';

import { deriveActiveTabID, deriveActiveTabIsSearch, deriveActiveUserID, deriveAppTabs } from '../../selectors/appTabs';
import { deriveSubscribedTypeRTM } from '../../selectors/socket';
import { APP_TABS } from '../../constants/appTabs';
import { CloseIcon, Wrapper } from './AppTabsManager.style';
import { deriveUserUI } from '../../selectors/users';
import { rtmTabIDs } from './utils';
import { deriveNotifBadgeUI } from '../../selectors/notifications';
import { withRouter } from '../../components/hoc/WithRouter/withRouter.tsx';
import { useLocation, useNavigate } from 'react-router';
import { getSidebarTabsKey } from "../../helpers/locationUtils";
import { restoreAppTabs } from "../../helpers/utility";
import TabTitle from './components/TabTitle';

const AppTabsManager = (props) => {
	const {
		tabs,
		activeTabID,
		activeTabIsSearch,
		rtmSubscribedType,
		userInnerCurrentTab,
		notifBadgeVisible,
		activeTabSet,
		closeTab,
		closeOtherTabs,
		closeRightTabs,
		closeAllTabs,
		rtmUnsubscribe,
		rtmSubscribedTypeRefresh,
		notifBadgeUiRefresh,
		activeUserID,
	} = props;

	const navigate = useNavigate();
	const location = useLocation();

	const onChange = (tabKey) => {
		const tab = find(tabs, { id: tabKey });
		activeTabSet(tabKey, tab.titleID);

		navigate(`${tab.location}`);
	};

	const onEdit = (tabKey, action, closeContext) => {
		if (action === 'add') {
			onAddTab(tabKey);
		} else if (action === 'remove') {
			onRemoveTab(tabKey, closeContext);
		}
	};

	const onAddTab = (tabKey) => {
		const tab = find(tabs, { id: tabKey });
		if (!tab) {
			return;
		}
		activeTabSet(tabKey);
		navigate(`${tab.location}`);
	};

	const onRemoveTab = (tabKey, closeContext) => {
		closeTab(tabKey, closeContext);
	};

	const onCloseAllTabs = () => {
		closeAllTabs();
		navigate(`/${APP_TABS.dashboard}`);
	}

	useEffect(() => {
		const activeTabIDbyPath = getSidebarTabsKey();
		onAddTab(activeTabIDbyPath)
	}, []);

	useEffect(() => {
		if (activeUserID) {
			navigate(`/${APP_TABS.dashboard}/${APP_TABS.userInfo}/${activeUserID}`);
		}
	}, [activeUserID]);

	useEffect(() => {
		const appTabs = restoreAppTabs();

		if (activeTabID !== APP_TABS.dashboard) {
			const { pathname } = location;
			const newPathname = `/${APP_TABS.dashboard}/${activeTabID}`
			const activeTabIDbyPath = getSidebarTabsKey();
			const queryString = pathname.replace(`/${APP_TABS.dashboard}/${activeTabIDbyPath}`, '').replace('/','');

			if (pathname.includes(newPathname)) {
				const finalUrl = queryString ? `${newPathname}/${queryString}` : newPathname;
				navigate(finalUrl);
			} else {
				onAddTab(activeTabID);
			}
		} else if (appTabs && appTabs.length === 1) {
			navigate(`/${APP_TABS.dashboard}`);
		}
		else if(activeTabID === APP_TABS.dashboard && activeTabIsSearch) {
			navigate(`/${APP_TABS.dashboard}`);
		}
	}, [activeTabID]);

	useEffect(() => {
		if (notifBadgeVisible) {
			notifBadgeUiRefresh({ visible: !notifBadgeVisible });
		}
	}, [userInnerCurrentTab]);

	useEffect(() => {
		const isRTM = rtmTabIDs.includes(activeTabID);
		const wasSubscribed = Boolean(rtmSubscribedType);

		if (isRTM && wasSubscribed && activeTabID !== rtmSubscribedType) {
			rtmUnsubscribe(rtmSubscribedType);
		} else if (!isRTM && wasSubscribed) {
			rtmUnsubscribe(rtmSubscribedType);
			rtmSubscribedTypeRefresh(null);
		}
	}, [activeTabID, rtmSubscribedType, rtmUnsubscribe, rtmSubscribedTypeRefresh]);

	const renderPanes = () => {
		return tabs.map((tab) => ({
			label: (
				<TabTitle
					tabs={tabs}
					id={tab.id}
					titleID={tab.titleID}
					closeTab={onEdit}
					closeOtherTabs={closeOtherTabs}
					closeRightTabs={closeRightTabs}
				/>
			),
			key     : tab.id,
			closable: tab.id !== APP_TABS.dashboard,
		}));
	};

	const renderButtonClose = () => (
		<CloseIcon onClick={onCloseAllTabs} title="Close all">
			<CloseOutlined />
		</CloseIcon>
	);

	const panes = renderPanes();
	const btnClose = renderButtonClose();

	return (
		<Wrapper className='app tabs manager'>
			<Tabs
				type="editable-card"
				onChange={onChange}
				activeKey={activeTabID}
				onEdit={onEdit}
				items={panes}
				tabBarExtraContent={btnClose}
				hideAdd
			/>
		</Wrapper>
	);
};

AppTabsManager.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			id            : PropTypes.string.isRequired,
			titleID       : PropTypes.string.isRequired,
			componentName : PropTypes.string.isRequired,
			componentProps: PropTypes.object,
			location      : PropTypes.string.isRequired,
		})
	).isRequired,
	userInnerCurrentTab     : PropTypes.string.isRequired,
	activeTabSet            : PropTypes.func.isRequired,
	closeTab                : PropTypes.func.isRequired,
	closeAllTabs            : PropTypes.func.isRequired,
	rtmUnsubscribe          : PropTypes.func.isRequired,
	closeOtherTabs          : PropTypes.func.isRequired,
	closeRightTabs          : PropTypes.func.isRequired,
	rtmSubscribedTypeRefresh: PropTypes.func.isRequired,
	changeUserInnerTabs     : PropTypes.func.isRequired,
	notifBadgeUiRefresh     : PropTypes.func.isRequired,
	notifBadgeVisible       : PropTypes.bool.isRequired,
	activeTabID             : PropTypes.string,
	activeUserID            : PropTypes.string,
	activeTabIsSearch       : PropTypes.bool,
	rtmSubscribedType       : PropTypes.string,
	location                : PropTypes.object.isRequired,
};

AppTabsManager.defaultProps = {
	activeTabID      : APP_TABS.dashboard,
	activeTabIsSearch: false,
	rtmSubscribedType: null,
};

function mapStateToProps(state) {
	return {
		tabs               : deriveAppTabs(state),
		activeTabID        : deriveActiveTabID(state),
		activeUserID       : deriveActiveUserID(state),
		activeTabIsSearch  : deriveActiveTabIsSearch(state),
		rtmSubscribedType  : deriveSubscribedTypeRTM(state),
		userInnerCurrentTab: deriveUserUI(state).currentTab,
		notifBadgeVisible  : deriveNotifBadgeUI(state).visible,
	};
}

export default withRouter(
	connect(mapStateToProps, {
		activeTabSet            : tabsActions.activeTabSet,
		closeTab                : tabsActions.closeTab,
		closeOtherTabs          : tabsActions.closeOtherTabs,
		closeRightTabs          : tabsActions.closeRightTabs,
		closeAllTabs            : tabsActions.closeAllTabs,
		rtmUnsubscribe          : socketActions.rtmUnsubscribe,
		rtmSubscribedTypeRefresh: socketActions.rtmSubscribedTypeRefresh,
		changeUserInnerTabs     : userActions.setValueUI,
		notifBadgeUiRefresh     : notifActions.uiRefresh,
	})(AppTabsManager)
);
