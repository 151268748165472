import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	entities: {},

	UI: {
		loading: false,
	},
});

export default function casinoCustomProvidersListReducer(state = initState, action) {

	switch (action.type) {

	case actions.CASINO_CUSTOM_PROVIDERS_LIST_REFRESH: {
		const { entities } = action.data;
		return state.set('entities', entities);
	}

	case actions.CASINO_CUSTOM_PROVIDERS_LIST_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
