import { EVENT_TYPES } from '../helpers/sockets/codes';

export const NOTIFICATION_TYPES = {
	documents      : 12,
	messages       : 25,
	riskManagement : 37,
	riskAlertLow   : 40,
	riskAlertMedium: 41,
	riskAlertHigh  : 42,
	bets           : 43,
	casino         : 44,
	withdrawal     : 45,
	deposits       : 46,
};

export const NOTIFICATION_TAB_NAMES = {
	[NOTIFICATION_TYPES.riskManagement]: 'RiskManagement',
	[NOTIFICATION_TYPES.documents]     : 'Documents',
	[NOTIFICATION_TYPES.casino]        : 'Casino',
	[NOTIFICATION_TYPES.deposits]      : 'Deposits',
	[NOTIFICATION_TYPES.withdrawal]    : 'Withdrawals',
	[NOTIFICATION_TYPES.bets]          : 'Bets',
};
export const SUBJECT_TYPE_NOTIFICATIONS = {
	[NOTIFICATION_TYPES.bets]           : EVENT_TYPES.notifBet,
	[NOTIFICATION_TYPES.deposits]       : EVENT_TYPES.notifDeposit,
	[NOTIFICATION_TYPES.withdrawal]     : EVENT_TYPES.notifWithdrawal,
	[NOTIFICATION_TYPES.casino]         : EVENT_TYPES.notifCasino,
	[NOTIFICATION_TYPES.documents]      : EVENT_TYPES.userDocument,
	[NOTIFICATION_TYPES.messages]       : EVENT_TYPES.messageRequest,
	[NOTIFICATION_TYPES.riskAlertLow]   : EVENT_TYPES.riskAlertLow,
	[NOTIFICATION_TYPES.riskAlertMedium]: EVENT_TYPES.riskAlertMedium,
	[NOTIFICATION_TYPES.riskAlertHigh]  : EVENT_TYPES.riskAlertHigh,
};

export const SUBJECT_TYPE_RTMS = {
	'rtm/bets'       : EVENT_TYPES.betslip,
	'rtm/deposits'   : EVENT_TYPES.deposit,
	'rtm/withdrawals': EVENT_TYPES.withdrawal,
	'rtm/casino'     : EVENT_TYPES.casino,
	'rtm/documents'  : EVENT_TYPES.document,
	'rtm/risk-alerts': EVENT_TYPES.riskManagement,
};
/*
export enum NotificationType {
  1 EVENT = 1,
  2 CATEGORY,
  3 MARKET,
  4 EVENT_MARKET,
  5 BET,
  6 DEPOSIT,
  7 WITHDRAWAL,
  8 USER,
  9 TODAY_SPECIAL_OFFER,
  10 MATCH_OF_THE_DAY,
  11 CASINO,
  12 UPLOADED_DOCUMENT,
  13 UPDATE_WINNERS,
  14 BIG_WIN,
  15 RESTRICTED_IP,
  16 MARKET_ALERT,
  17 MARKET_LIABILITY,
  18 BIG_WITHDRAW,
  19 OVERASK,
  20 NEW_BET,
  21 WITHDRAW,
  22 BET_UPDATE,
  23 USER_UPDATE_BALANCE,
  24 NEW_MESSAGE,
  25 NEW_REQUEST,
}
*/
