import { call, fork, takeEvery, all, put, select } from 'redux-saga/effects';
import toNumber from 'lodash/toNumber';
import { actions } from './actions';
import { integratorSelectAPI } from '../../../../../helpers/api/integrator';
import { adaptIntegratorsList } from '../../utils';
import { adaptProvidersWithGames } from './utils';
import notifications from '../../../../../helpers/notifications';


const prefix = 'partner.partners.modal';

const messages = {
	errorLoadIntegrators: `${prefix}.errorLoadIntegrators`,
	errorLoadProviders  : `${prefix}.errorLoadProviders`,
};


const getStore = ({ Partner }) => {
	return {
		integratorsWithProviders: Partner.Partner.Modal.Partner.get('integratorsWithProviders'),
		gameIDs                 : Partner.Partner.Modal.Partner.get('baseData').gameIDs,
	};
};

function* getIntegratorsWithProviders() {
	yield takeEvery(actions.INTEGRATORS_WITH_PROVIDERS, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		try {
			const res = yield call(integratorSelectAPI.integratorsWithProvidersNew);
			if (res && res.status === 200 && res.data.status === 200) {
				const adapted = adaptIntegratorsList(res.data.data);
				yield put(actions.integratorsWithProvidersRefresh(adapted));
			}
		} catch (e) {
			notifications.showError(messages.errorLoadIntegrators);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* getProvidersWithGames() {
	yield takeEvery(actions.PROVIDERS_WITH_GAMES_RELOAD, function* (action) {
		const { data }                              = action;
		const { providerID, unlimit, cb: fallBack } = data;


		const { integratorsWithProviders, gameIDs } = yield select(getStore);

		yield put(actions.providersWithGamesRefresh({ id: providerID, gameDataByProvider: [], isLoading: true }));

		try {
			const res         = yield call(integratorSelectAPI.providersGames, {
				provider_id: providerID,
				unlimit,
			});

			const provider = integratorsWithProviders.filter(item => {
				return item.providers.some(prov => {

					if (toNumber(providerID) === toNumber(prov.providerID)) {
						return prov.checked;
					}
					return false;
				});
			});

			if (res && res.status && res.data.status) {
				const adapted = adaptProvidersWithGames(res.data.data, !!provider.length, gameIDs);

				yield put(
					actions.providersWithGamesRefresh({
						id                : providerID,
						gameDataByProvider: adapted,
						isLoading         : false,
					})
				);

				if (typeof fallBack === 'function') {
					yield fallBack();
				}
			}
		} catch (e) {
			console.log(e, 'error');
			notifications.showError(messages.errorLoadProviders);
		}
	});
}


export default function* partnerIntegratorsSaga() {
	yield all([
		fork(getIntegratorsWithProviders),
		fork(getProvidersWithGames),
	]);
}
