import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	leagueIDs     : [],
	restrictionIDs: [],
	leagueEntities: {},

	selectedLeagueIDs     : [],
	selectedRestrictionIDs: [],

	modalUI: {
		visible     : false,
		loading     : false,
		sportID     : null,
		categoryID  : null,
		categoryName: null,
		closeModal  : false,
		isChanged   : false,
	},
});

export default function restrictionReducer(state = initState, action) {

	switch (action.type) {

	case actions.CATEGORY_RESTRICTION_DATA_RESET: {
		return initState;
	}

	case actions.CATEGORY_RESTRICTION_LEAGUE_IDS_REFRESH: {
		return state.set('leagueIDs', action.data);
	}

	case actions.CATEGORY_RESTRICTION_LEAGUE_ENTITIES_REFRESH: {
		return state.set('leagueEntities', action.data);
	}

	case actions.CATEGORY_RESTRICTION_RESTRICTION_IDS_REFRESH: {
		return state.set('restrictionIDs', action.data);
	}

	case actions.CATEGORY_RESTRICTION_SELECTED_LEAGUE_IDS_REFRESH: {
		return state.set('selectedLeagueIDs', action.data);
	}

	case actions.CATEGORY_RESTRICTION_SELECTED_RESTRICTION_IDS_REFRESH: {
		return state.set('selectedRestrictionIDs', action.data);
	}

	case actions.CATEGORY_RESTRICTION_DATA_REFRESH: {
		const {
			leagueIDs,
			restrictionIDs,
			leagueEntities,
			selectedLeagueIDs,
			selectedRestrictionIDs,
		} = action.data;

		const result = state.merge({
			leagueIDs,
			restrictionIDs,
			leagueEntities,
			selectedLeagueIDs,
			selectedRestrictionIDs,
		});

		return new Map(result.toJS());
	}

	case actions.CATEGORY_RESTRICTION_MODAL_UI_REFRESH: {
		const target = state.get('modalUI');
		const result = fill(action.data, target);
		return state.set('modalUI', result);
	}

	default:
		return state;
	}
}
