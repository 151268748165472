import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';
import actions from './actions';
import { adaptScopesList, prepareBaseData, prepareScopesList } from './utils';

import { settingsAPI } from '../../../helpers/api/settings';
import notifications from '../../../helpers/notifications';
import ParamsBuilder from '../../../helpers/paramsBuilder';

const prefix = 'settings.scopes';

const messages = {
	errorListReload : `${prefix}.errorListReload`,
	errorUpdate     : `${prefix}.errorUpdate`,
	errorUpdateAll  : `${prefix}.errorUpdateAll`,
	errorDelete     : `${prefix}.errorDelete`,
	successUpdate   : `${prefix}.successUpdate`,
	successUpdateAll: `${prefix}.successUpdateAll`,
	successDelete   : `${prefix}.successDelete`,
};

function getStoreData({ Settings, App }) {

	return {
		filter   : Settings.Scopes.get('filter'),
		websiteID: App.get('websiteID'),
	};
}

function getParams(filter) {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addField(rules.isID, 'sportID', 'sport_id');
	builder.addField(rules.isString, 'name', 'name');

	const params = builder.biuldParams(filter);

	return params;
}

function* listReload() {
	yield takeEvery(actions.SETTINGS_SCOPES_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { filter } = yield select(getStoreData);
		const { sportID } = filter;
		const params = getParams(filter);

		let list = [];
		try {
			const response = yield call(settingsAPI.scopeList, sportID, params);
			if (response && response.status === 200) {
				list = adaptScopesList(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorListReload, error);
			console.log(error);
		}

		yield put(actions.listRefresh(list));
		yield put(actions.changedIDsReset());
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* deleteItem() {
	yield takeEvery(actions.SETTINGS_SCOPES_DELETE_ITEM, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { scopeID } = action.data;
		try {
			const response = yield call(settingsAPI.scopeDelete, scopeID);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successDelete);
			}
		} catch (error) {
			notifications.showError(messages.errorDelete, error);
			console.log(error);
		}

		yield put(actions.listReload());
	});
}

function* saveItem() {
	yield takeEvery(actions.SETTINGS_SCOPES_SAVE_ITEM, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const scopeData = action.data;
		const scopeID = toInteger(scopeData.id);
		const { websiteID } = yield select(getStoreData);
		const params = { website_id: websiteID };
		const preparedData = prepareBaseData(scopeData);
		try {
			// update
			if (scopeID) {
				const response = yield call(
					settingsAPI.scopeUpdate,
					scopeID,
					preparedData,
					params
				);
				if (response && response.status === 200) {
					notifications.showSuccess(messages.successUpdate);
				}

				// add
			} else {
				preparedData.id = null;
				const response = yield call(settingsAPI.scopeAdd, preparedData, params);
				if (response && response.status === 200) {
					notifications.showSuccess(messages.successUpdate);
				}
			}
		} catch (error) {
			notifications.showError(messages.errorUpdate, error);
			console.log(error);
		}

		yield put(actions.listReload());
	});
}

function* saveAll() {
	yield takeEvery(actions.SETTINGS_SCOPES_SAVE_ALL, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const { websiteID } = yield select(getStoreData);
		const params = { website_id: websiteID };
		const scopeList = action.data;
		const preparedList = prepareScopesList(scopeList);
		try {
			const response = yield call(settingsAPI.scopeUpdateAll, preparedList, params);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successUpdateAll);
			}
		} catch (error) {
			notifications.showError(messages.errorUpdateAll, error);
			console.log(error);
		}

		yield put(actions.listReload());
	});
}

export default function* settingsScopesSaga() {
	yield all([
		fork(listReload),
		fork(deleteItem),
		fork(saveItem),
		fork(saveAll),
	]);
}
