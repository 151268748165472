import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Spin } from 'antd';

import notificationsActions from '../../../../redux/notifications/actions';
import {
	deriveHasMoreByType,
} from '../../../../selectors/notifications';

import { ListItem, ListItemBody, ListItemHeader, LayoutContentStyle } from '../NotificationBadge.style';
import { lang, itemComponents } from '../assets';

class MessagesList extends Component {

	static propTypes = {
		typeID           : PropTypes.number.isRequired,
		containerID      : PropTypes.string.isRequired,
		hasMore          : PropTypes.bool.isRequired,
		loading          : PropTypes.bool.isRequired,
		onClickItem      : PropTypes.func.isRequired,
		notificationsList: PropTypes.array.isRequired,
		pageChange       : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.onLoadMore  = this.onLoadMore.bind(this);
		this.renderItems = this.renderItems.bind(this);
	}

	// Events ---------------------------------------------------------------------------------------
	onLoadMore(page) {
		const { typeID, pageChange } = this.props;
		pageChange(typeID, page);
	}

	// Renders --------------------------------------------------------------------------------------
	renderItems() {
		const { typeID, notificationsList, onClickItem } = this.props;
		return notificationsList.map(item => {
			const Component = itemComponents[typeID];
			const className = (!item.seen) ? 'not-read' : '';
			return (
				<Component
					key={item.id}
					className={className}
					onClick={onClickItem}
					documentType={item.typeID}
					docID={item.docID}
					itemIdByType={item.itemIdByType}
					{...item}
				/>
			);
		});
	}

	render() {
		const { containerID, hasMore, notificationsList, loading } = this.props;
		if (!isArray(notificationsList) || isEmpty(notificationsList)) {
			return (
				<ListItem key={0}>
					<ListItemHeader>
						{
							loading
								? (
									<LayoutContentStyle>
										<Spin size="small" />
									</LayoutContentStyle>
								)
								: lang.noData
						}
					</ListItemHeader>
					<ListItemBody />
				</ListItem>
			);
		}
		const items = this.renderItems();
		return (
			<InfiniteScroll
				useWindow={false}
				initialLoad={false}
				hasMore={hasMore}
				pageStart={1}
				loadMore={this.onLoadMore}
				threshold={50}
				loader={loading && <LayoutContentStyle> <Spin size="small" /> </LayoutContentStyle>}
				getScrollParent={() => document.getElementById(containerID)}
			>
				{items}
			</InfiniteScroll>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { typeID } = props;
	const { loading } = state.Notifications.get('UI');
	return {
		hasMore: deriveHasMoreByType(typeID)(state),
		loading,
	};
};

const mapDispatchToprops = {
	pageChange: notificationsActions.pageChange,
};

export default React.memo(connect(
	mapStateToProps,
	mapDispatchToprops
)(MessagesList));
