import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	sportId    : null,
	filter     : {},
	countryList: [],
	leagueList : [],
});

export default function eventsFilterReducer(state = initState, action) {

	switch (action.type) {

	case actions.PRIOR_EVENTS_FILTER_SET_VALUE: {
		const { valueName, value } = action.data;
		const filter               = new Map(state.get('filter')).toObject();
		filter[valueName]          = value;

		return state.set('filter', filter);
	}

	case actions.PRIOR_EVENTS_FILTER_REFRESH: {
		return state.set('filter', action.data);
	}

	case actions.PRIOR_EVENTS_FILTER_COUNTRY_LIST_REFRESH: {
		return state.set('countryList', action.data);
	}

	case actions.PRIOR_EVENTS_FILTER_LEAGUE_LIST_REFRESH: {
		return state.set('leagueList', action.data);
	}

	default:
		return state;
	}
}
