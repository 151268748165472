import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../tables/actions';

import notifications from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { deriveTablePagination } from '../../../../selectors/tables';

import { getListParams, adaptFreeSpinsList } from './utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';

const messages = {
	errorListLoad: 'bonusesfreespins.error.list.load',
};

const tableType   = TABLE_TYPES.bonusesFreeSpins;

function getStoreData(state) {
	const { Loyalty: { Bonuses }, Tables } = state;
	const { FreeSpins } = Bonuses;

	return {
		filter    : FreeSpins.get('filter'),
		sorting   : Tables.get(tableType).sorting,
		pagination: deriveTablePagination(tableType, state),
	};
}

function* listReload() {

	yield takeEvery(actions.BONUSES_FREE_SPINS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);

		let list   = [];
		let totalCount = 0;
		try {
			const res = yield call(bonusesAPI.freeSpinsList, params);
			if (res && res.status === 200) {
				list   = adaptFreeSpinsList(res.data.data, res.headers);
				totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.listRefresh(list));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));

		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {
	yield takeEvery(actions.BONUSES_FREE_SPINS_LIST_FILTER_APPLY, function* () {
		yield put(tableActions.paginationRefresh(tableType, { currentPage: 1 }));
		yield put(actions.listReload());
	});
}

export default function* freeSpinsListSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
	]);
}
