import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import notifications from '../../../helpers/notifications';
import { usersAPI } from '../../../helpers/api/users';
import { getHeadersTotalCount } from '../../../helpers/utils';

import actions from './actions';
import tableActions from '../../tables/actions';
import { getListParams, adaptUsersList } from './utils';
import { deriveTablePagination } from '../../../selectors/tables';
import { TABLE_TYPES } from '../../../constants/tableTypes';

const tableType       = TABLE_TYPES.customersSearch;

const messages = {
	errorLoadList: 'Loading users list failed',
};

function getStoreData(state) {
	const { Users, Tables, Loyalty: { Bonuses } } = state;
	return {
		filter    : Users.Filter.get('filter'),
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
		massBonus : Bonuses.UserBonus.get('UI').massBonus,
	};
}

function* listReload() {

	yield takeEvery(actions.USERS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		let list = [];
		let totalCount = 0;

		const { filter, sorting, pagination, massBonus } = yield select(getStoreData);

		const params = getListParams(filter, sorting, pagination, massBonus);
		try {
			const res = yield call(usersAPI.usersList, null, params);
			if (res && res.status === 200) {
				list = adaptUsersList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.listRefresh(list));
			}

			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			notifications.showError(messages.errorLoadList, error);
			yield put(actions.uiRefresh({ loading: true }));
			yield put(actions.listRefresh([]));

			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));

	});
}

export default function* listSaga() {
	yield all([
		fork(listReload),
	]);
}
