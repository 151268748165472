import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	filter: {
		userID    : null,
		userName  : '',
		userEmail : '',
		firstName : '',
		lastName  : '',
		statusID  : null,
		birthday  : [], // Range of dates : from-to
		role      : [],
		partnerIDs: [],
		websiteIDs: [],
	},

	// dataSource for Inputs with autocomplete function
	dataSource: {
		userName     : [],
		userEmail    : [],
		userFirstName: [],
		userLastName : [],
	},
});

export default function personalFilterReducer(state = initState, action) {

	switch (action.type) {

	case actions.FILTER_REFRESH: {
		const { filterData } = action.data;
		const target = state.get('filter');
		const result = fill(filterData, target, true);

		return state.set('filter', result);
	}

	case actions.AUTOCOMPLETE_DATA_SOURCE_REFRESH: {
		const { dataSource, type } = action.data;
		const result = cloneDeep(state.get('dataSource'));
		result[type] = dataSource;

		return state.set('dataSource', result);
	}

	default:
		return state;
	}
}
