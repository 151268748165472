import { all, takeLatest, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { defaultWebsiteID } from '../../config';
import { websitesAPI } from '../../helpers/api/websites';
import {
	storeWebsiteID,
	storePartnerID, restoreWebsite,
} from '../../helpers/utility';
import { showError, showWarning } from '../../helpers/notifications';
import actions from './actions';
import { adaptWebsiteSettings, API_STATUS } from './utils';
import { authApi } from '../../helpers/api/auth';

const messageTitle = 'common.error';

const getStore = ({ Auth, App }) => {
	const user = Auth.get('user');
	const partnerEntity  = App.get('partnerEntity');
	return {
		user,
		partnerEntity,
	};
};

function* websiteRestore() {
	yield takeEvery(actions.APP_WEBSITE_RESTORE, function* () {

		let websiteID = yield call(restoreWebsite);
		if (!websiteID) {
			websiteID = defaultWebsiteID;
			yield call(storeWebsiteID, websiteID);
		}

		yield put(actions.websiteRefresh(websiteID));
	});
}

function* partnerRestore() {
	yield takeLatest(actions.APP_PARTNER_RESTORE, function* () {

		// let partnerID = yield call(restorePartnerID);
		// if (!partnerID) {
		// partnerID = defaultPartnerID;
		// yield call(storePartnerID, partnerID);
		// }

		// yield put(actions.partnerRefresh(partnerID));
	});
}


function* websiteChange() {
	yield takeLatest(actions.APP_WEBSITE_CHANGE, function* (action) {

		const { websiteID } = action.data;
		yield call(storeWebsiteID, websiteID);
		yield put(actions.websiteRefresh(websiteID));

		window.location.reload();
	});
}

function* partnerChange() {
	yield takeLatest(actions.APP_PARTNER_CHANGE, function* (action) {
		const { partnerEntity } = yield select(getStore);
		const { partnerID } = action.data;

		const { websites } = partnerEntity[partnerID];
		if (websites && websites.length) {
			const [firstWebsite] = websites;
			storeWebsiteID(firstWebsite.id);
			// yield put(actions.websiteRefresh(firstWebsite.id));

		} else {
			storeWebsiteID(0);
		}

		// yield put(actions.websiteListRefresh(entities));
		yield call(storePartnerID, partnerID);
		yield put(actions.partnerRefresh(partnerID));

		window.location.reload();
	});
}


function* websiteSettingsReload() {
	yield takeLatest(actions.APP_WEBSITE_SETTINGS_RELOAD, function* () {
		try {
			// const res      = yield call(websitesAPI.websiteSettings);
			// const settings = yield call(adaptWebsiteSettings, res.data.data);
			// yield put(actions.websiteSettingsRefresh(settings));
		} catch (err) {
			showWarning('Unable load Website settings from server. It will be set by default');
		}
	});
}


function* newPasswordReload() {
	yield takeLatest(actions.NEW_PASSWORD_RELOAD, function* (action) {
		const { data }                  = action;
		const { password, oldPassword } = data;
		const { user } = yield select(getStore);
		const { id: userID } = user;
		yield put(actions.passwordExpiredModalRefresh({ passwordLoading: true }));

		const preparedData = {
			old_password: oldPassword,
			new_password: password,
			token_id    : userID,

		};
		try {
			const res = yield call(authApi.newPasswordSet, preparedData);


			if (res && res.status === 200) {
				yield put(actions.passwordExpiredModalRefresh({ success: API_STATUS.success }));
			}
		} catch (err) {
			yield put(actions.passwordExpiredModalRefresh({ passwordLoading: false }));
			yield put(actions.passwordExpiredModalRefresh({ success: API_STATUS.failure }));
			showError(messageTitle, err);

		}

		yield put(actions.passwordExpiredModalRefresh({ passwordLoading: false }));

	});
}

export default function* appSaga() {
	yield all([
		fork(websiteRestore),
		fork(partnerRestore),
		fork(websiteChange),
		fork(partnerChange),
		fork(websiteSettingsReload),
		fork(newPasswordReload),
	]);
}
