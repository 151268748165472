import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	filter: {
		roleType: '',
		roleName: '',
	},
});

const clonedState = cloneDeep(initState);
export default function personalFilterRolesReducer(state = initState, action) {

	switch (action.type) {

	case actions.FILTER_ROLE_REFRESH: {
		const { filterData } = action.data;
		const target = state.get('filter');
		const result = fill(filterData, target);

		return state.set('filter', result);
	}

	case actions.RESET: {
		return  clonedState;
	}

	default:
		return state;
	}
}
