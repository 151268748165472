import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import listActions from '../list/actions';

import { marketsAPI } from '../../../helpers/api/markets';
import notifications from '../../../helpers/notifications';

import {
	adaptBaseData,
	prepareBaseData,
	adaptNamesData,
	prepareNamesData,
	adaptOrdersData,
	prepareOrdersData,
	adaptSettingsData,
	prepareSettingsData,
} from './utils';


const prefix = 'selections.selection';

const messages = {
	errorLoadSelection        : `${prefix}.errorLoadSelection`,
	errorLoadSelectionNames   : `${prefix}.errorLoadSelectionNames`,
	errorLoadSelectionOrders  : `${prefix}.errorLoadSelectionOrders`,
	errorLoadSelectionSettings: `${prefix}.errorLoadSelectionSettings`,
	errorUpdateSelectionData  : `${prefix}.errorUpdateSelectionData`,
	errorUpdateSelection      : `${prefix}.errorUpdateSelection`,
	errorAddSelection         : `${prefix}.errorAddSelection`,
	errorUpdateNames          : `${prefix}.errorUpdateNames`,
	errorUpdateOrders         : `${prefix}.errorUpdateOrders`,
	errorUpdateSettings       : `${prefix}.errorUpdateSettings`,
	errorSelectionDelete      : `${prefix}.errorSelectionDelete`,
	errorCheckingDelete       : `${prefix}.errorCheckingDelete`,
	successSelectionSave      : `${prefix}.successSelectionSave`,
	successDelete             : `${prefix}.successDelete`,
	errorActivateSelection    : `${prefix}.errorActivateSelection`,
	successActivate           : `${prefix}.successActivate`,
};

function getStoreData({ Selections }) {

	return {
		baseData    : Selections.Selection.get('baseData'),
		namesData   : Selections.Selection.get('namesData'),
		ordersData  : Selections.Selection.get('ordersData'),
		settingsData: Selections.Selection.get('settingsData'),
		modalUI     : Selections.Selection.get('modalUI'),
	};
}

function* baseDataReload() {
	const takeAction = actions.SELECTION_BASE_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		yield put(actions.setModalValueUI('loading', true));

		const { selectionID } = action.data;
		const params = { include_parent_limit: true };
		try {
			const response = yield call(
				marketsAPI.selectionInfo,
				selectionID,
				params
			);
			if (response && response.status === 200) {
				const data = adaptBaseData(response.data.data);
				yield put(actions.baseDataRefresh(data));
			}
		} catch (error) {
			notifications.showError(messages.errorLoadSelection, error);
			console.log(error);
		}

		yield put(actions.setModalValueUI('loading', false));
	});
}

function* namesDataReload() {
	const takeAction = actions.SELECTION_NAMES_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		yield put(actions.setModalValueUI('loading', true));

		const { selectionID } = action.data;

		const storeData = yield select(getStoreData);
		const { modalUI } = storeData;
		const { langID, selectionName } = modalUI;

		const params = { lang_id: langID };
		let data = adaptNamesData([], selectionName);
		try {
			const response = yield call(
				marketsAPI.selectionNamesList,
				selectionID,
				params
			);
			if (response && response.status === 200) {
				data = adaptNamesData(response.data.data, selectionName);
			}
		} catch (error) {
			notifications.showError(messages.errorLoadSelectionNames, error);
			console.log(error);
		}

		yield put(actions.namesDataRefresh(data));
		yield put(actions.setModalValueUI('loading', false));
	});
}

function* ordersDataReload() {
	const takeAction = actions.SELECTION_ORDERS_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		yield put(actions.setModalValueUI('loading', true));

		const { selectionID } = action.data;
		let data = adaptOrdersData([]);
		try {
			const response = yield call(marketsAPI.selectionOrdersList, selectionID);
			if (response && response.status === 200) {
				data = adaptOrdersData(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorLoadSelectionOrders, error);
			console.log(error);
		}

		yield put(actions.ordersDataRefresh(data));
		yield put(actions.setModalValueUI('loading', false));
	});
}

function* settingsDataReload() {
	const takeAction = actions.SELECTION_SETTINGS_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		yield put(actions.setModalValueUI('loading', true));

		const { selectionID } = action.data;
		let data = adaptSettingsData([]);
		try {
			const response = yield call(
				marketsAPI.selectionSettingsList,
				selectionID
			);
			if (response && response.status === 200) {
				data = adaptSettingsData(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorLoadSelectionSettings, error);
			console.log(error);
		}

		yield put(actions.settingsDataRefresh(data));
		yield put(actions.setModalValueUI('loading', false));
	});
}

function* selectionUpdate() {
	const takeAction = actions.SELECTION_UPDATE;
	let errorMessage = messages.errorUpdateSelectionData;

	yield takeEvery(takeAction, function* () {
		yield put(actions.setModalValueUI('loading', true));

		const storeData = yield select(getStoreData);
		const {
			baseData,
			namesData,
			ordersData,
			settingsData,
			modalUI,
		} = storeData;

		const { marketID, tradingModeID, editMode, langID, closeModal } = modalUI;

		const { selectionName } = modalUI;
		let { selectionID }     = modalUI;
		let isError             = false;
		try {
			// base data
			const preparedBaseData = prepareBaseData(baseData, selectionID);
			if (editMode) {
				errorMessage = messages.errorUpdateSelection;
				yield call(marketsAPI.selectionUpdate, selectionID, preparedBaseData);

			} else {
				errorMessage = messages.errorAddSelection;
				preparedBaseData.name = selectionName;
				const response = yield call(marketsAPI.marketSelectionsAdd, marketID, preparedBaseData);
				selectionID = toInteger(response.data.data.id);

				yield put(actions.setModalValueUI('selectionID', selectionID));
				yield put(actions.setModalValueUI('editMode', true));
			}

			// names
			if (modalUI.isNamesChanged) {
				errorMessage = messages.errorUpdateNames;
				const preparedNames = prepareNamesData(namesData, langID);
				const params = { lang_id: langID };
				yield call(marketsAPI.selectionNamesUpdate, selectionID, preparedNames, params);
				yield put(actions.setModalValueUI('isNamesChanged', false));
			}

			// orders
			if (modalUI.isOrdersChanged) {
				errorMessage = messages.errorUpdateOrders;
				const preparedOrders = prepareOrdersData(ordersData, selectionID);
				yield call(marketsAPI.selectionOrdersUpdate, selectionID, preparedOrders);
				yield put(actions.setModalValueUI('isOrdersChanged', false));
			}

			// settings
			if (modalUI.isSettingsChanged) {
				errorMessage = messages.errorUpdateSettings;
				const preparedData = prepareSettingsData(settingsData, tradingModeID);
				yield call(marketsAPI.selectionSettingsUpdate, selectionID, preparedData);
				yield put(actions.setModalValueUI('isSettingsChanged', false));
			}

			notifications.showSuccess(messages.successSelectionSave);

		} catch (error) {
			notifications.showError(errorMessage, error);
			isError = true;
			console.log(error);
		}

		yield put(listActions.listReload(marketID));

		if (!closeModal && !isError) {
			yield put(actions.baseDataReload(selectionID));

			if (modalUI.isNamesChanged) {
				yield put(actions.namesDataReload(selectionID));
			}

			if (modalUI.isOrdersChanged) {
				yield put(actions.ordersDataReload(selectionID));
			}

			if (modalUI.isSettingsChanged) {
				yield put(actions.settingsDataReload(selectionID));
			}
		}

		yield put(actions.setModalValueUI('loading', false));
		if (closeModal && !isError) {
			yield put(actions.selectionDataReset());
		}
	});
}

function* selectionCheckDelete() {
	const takeAction = actions.SELECTION_CHECK_DELETE;
	const errorMessage = messages.errorCheckingDelete;

	yield takeEvery(takeAction, function* (action) {
		yield put(actions.setModalValueUI('loading', true));

		const storeData = yield select(getStoreData);
		const { modalUI } = storeData;
		const { marketID } = modalUI;

		const { selectionID, selectionName } = action.data;
		let isError = false;
		try {
			const response = yield call(marketsAPI.selectionCheckDelete, selectionID);
			if (response && response.status === 200) {
				const result = response.data.data;
				const { canceled, reason } = result;
				if (!canceled) {
					const confirmUI = {
						selectionID,
						selectionName,
						info   : reason,
						visible: true,
						loading: false,
					};
					yield put(actions.setModalValueUI('loading', false));
					yield put(actions.confirmUIRefresh(confirmUI));
					return;
				}
				notifications.showSuccess(messages.successDelete);

			}
		} catch (error) {
			notifications.showError(errorMessage, error);
			isError = true;
			console.log(error);
		}

		yield put(actions.setModalValueUI('loading', false));
		if (!isError) {
			yield put(actions.selectionDataReset());
			yield put(listActions.listReload(marketID));
		}
	});
}

function* selectionDelete() {
	const takeAction = actions.SELECTION_DELETE;
	const errorMessage = messages.errorSelectionDelete;

	yield takeEvery(takeAction, function* (action) {
		yield put(actions.setModalValueUI('loading', true));

		const storeData = yield select(getStoreData);
		const { modalUI } = storeData;
		const { marketID } = modalUI;

		const { selectionID } = action.data;
		let isError = false;
		try {
			const response = yield call(marketsAPI.selectionDelete, selectionID);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successDelete);
			}
		} catch (error) {
			notifications.showError(errorMessage, error);
			isError = true;
			console.log(error);
		}

		yield put(actions.setModalValueUI('loading', false));
		yield put(actions.setConfirmValueUI('loading', false));
		if (!isError) {
			yield put(actions.selectionDataReset());
			yield put(listActions.listReload(marketID));
		}
	});
}

function* selectionRestore() {
	const takeAction = actions.SELECTION_RESTORE;
	const errorMessage = messages.errorActivateSelection;

	yield takeEvery(takeAction, function* (action) {
		yield put(actions.setModalValueUI('loading', true));

		const { selectionID } = action.data;
		let isError = false;
		try {
			const response = yield call(marketsAPI.selectionRestore, selectionID);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successActivate);
			}
		} catch (error) {
			notifications.showError(errorMessage, error);
			isError = true;
			console.log(error);
		}

		yield put(actions.setModalValueUI('loading', false));
		if (!isError) {
			yield put(actions.baseDataReload(selectionID));
		}
	});
}

export default function* selectionSaga() {
	yield all([
		fork(baseDataReload),
		fork(namesDataReload),
		fork(ordersDataReload),
		fork(settingsDataReload),
		fork(selectionUpdate),
		fork(selectionCheckDelete),
		fork(selectionDelete),
		fork(selectionRestore),
	]);
}
