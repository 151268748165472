import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	entities: {},
	UI      : { Loading: false },
});

export default function bannerGroupsListReducer(state = initState, action) {
	switch (action.type) {
	case actions.BANNER_GROUPS_LIST_REFRESH: {
		const { entities } = action.data;
		return state.set('entities', entities);
	}
	case actions.BANNER_GROUPS_UI_REFRESH: {
		const { data } = action;
		const target = state.get('UI');
		const result = fill(data, target, true);
		return state.set('UI', result);
	}
	default:
		return state;
	}
}
