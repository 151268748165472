import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';

import actions from './actions';
import { fill } from '../../helpers/utils';

const initState = new Map({

	// For every IP here will be saved users that had been logged in with such IP
	ipData: {
		'127.0.0.1': {
			usersList: [],
		},
	},

	UI: {
		loading: false,
	},
});

export default function customerIPReducer(state = initState, action) {

	switch (action.type) {

	case actions.DATA_REFRESH: {
		const { ip, ipData } = action.data;
		const resData = cloneDeep(state.get('ipData'));
		resData[ip] = ipData;

		return state.set('ipData', resData);
	}

	case actions.UI_REFRESH: {
		const { UI } = action.data;
		const target = state.get('UI');
		const result = fill(UI, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
