import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	entities: {},
});

export default function emailTemplatesListReducer(state = initState, action) {

	switch (action.type) {

	case actions.EMAIL_TEMPLATES_LIST_REFRESH: {
		const { entities } = action.data;
		return state.set('entities', entities);
	}

	default:
		return state;
	}
}
