import { Map } from 'immutable';
import uniq from 'lodash/uniq';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	list      : [],
	changedIDs: [],

	UI: {
		loading: false,
	},
});

export default function participantsAgesReducer(state = initState, action) {

	switch (action.type) {

	case actions.PARTICIPANTS_AGES_LIST_REFRESH: {
		return state.set('list', action.data);
	}

	case actions.PARTICIPANTS_AGES_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.PARTICIPANTS_AGES_CHANGED_ID_ADD: {
		const { ageID } = action.data;
		const changedIDs = state.get('changedIDs');
		const result = uniq([...changedIDs, ageID]);

		return state.set('changedIDs', result);
	}

	case actions.PARTICIPANTS_AGES_CHANGED_IDS_RESET: {
		return state.set('changedIDs', []);
	}

	default:
		return state;
	}
}
