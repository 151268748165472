import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';
import notifications from '../../../../helpers/notifications';
import { categoriesAPI } from '../../../../helpers/api/categories';

import actions from './actions';
import { getListParams, adaptSettingsList, adaptSavingData } from './utils';

const prefix = 'sport.settings';

const messages = {
	loadList     : `${prefix}.loadList`,
	successUpdate: `${prefix}.successUpdate`,
	errorUpdate  : `${prefix}.errorUpdate`,
};

function getStoreData({ Sport: { Categories } }) {

	return {
		savingData: Categories.MarketSettings.get('savingData'),
		modalUI   : Categories.MarketSettings.get('modalUI'),
	};
}

function* dataReload() {

	yield takeEvery(actions.DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { sportID, categoryID } = action.data;
		const params = getListParams(toInteger(sportID), toInteger(categoryID));

		let settingsList = [];
		try {
			const res = yield call(categoriesAPI.marketSettings, params);
			if (res && res.status === 200) {
				settingsList = adaptSettingsList(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.loadList, error);
			console.log(error);
		}

		yield put(actions.dataRefresh(settingsList));
		yield put(actions.modalUIRefresh({
			loading  : false,
			isChanged: false,
		}));
	});
}

function* dataSaveClose() {
	yield takeEvery(actions.DATA_SAVE_CLOSE, function* () {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { modalUI, savingData } = yield select(getStoreData);
		const { sportID, categoryID, closeModal  } = modalUI;
		const params = getListParams(toInteger(sportID), toInteger(categoryID));
		const data = adaptSavingData(savingData);
		try {
			const res = yield call(categoriesAPI.updateMarketSettings, params, data);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successUpdate);
			}
		} catch (error) {
			notifications.showError(messages.errorUpdate, error);
		}

		yield put(actions.savingDataRefresh([]));
		yield put(
			actions.modalUIRefresh({
				loading  : false,
				visible  : closeModal,
				isChanged: false,
			})
		);
	});
}

export default function* marketSettingsSaga() {
	yield all([
		fork(dataReload),
		fork(dataSaveClose),
	]);
}
