import { normalize, schema } from 'normalizr';
import ParamsBuilder from '../../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../../helpers/entityAdapter';

export const fields = {
	id             : 'id',
	typeID         : 'type_id',
	url            : 'url',
	imageURL       : 'image_url',
	videoURL       : 'video_url',
	langID         : 'lang_id',
	name           : 'name',
	linkTitle      : 'link_title',
	contentTitle   : 'content_title',
	contentSubtitle: 'content_subtitle',
	contentShort   : 'content_short',
	contentMain    : 'content_main',

	seoTitle      : 'seo_title',
	seoDescription: 'seo_description',
	seoKeywords   : 'seo_keywords',
	OGTitle       : 'og_title',
	OGDescription : 'og_description',
	OGImage       : 'og_image',

	websiteID: 'website_id',

	// revision
	pageID: 'page_id',
	page  : 'page',
	active: 'is_active',
	userID: 'created_by',
	date  : 'created_at',
};

const listAdapter = createListAdapter();

export function getListParams(pagination, sorting = null, websiteID) {

	const builder = new ParamsBuilder();
	//const rules   = builder.RULES;

	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	builder.addValue('website_id', websiteID);

	builder.addValue('limit', pagination.itemsPerPage);
	builder.addValue('page', pagination.currentPage);

	const params = builder.biuldParams({});

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptInfoPagesList(rawData = []) {

	listAdapter.clearExcludes();
	const adaptedData = [];

	rawData.forEach(item => {
		const adaptedItem = item.page
			? listAdapter.adapt(item.page)
			: listAdapter.adapt(item);

		adaptedData.push(adaptedItem);
	});

	const tempData = {
		pages: adaptedData,
	};

	const page = new schema.Entity('pages', {}, { idAttribute: 'id' });
	const pageSchema = { pages: [page] };

	const normalizedData = normalize(tempData, pageSchema);

	const listIDs = normalizedData.result.pages || [];
	const entities = normalizedData.entities.pages || {};

	const result = {
		listIDs,
		entities,
	};

	return result;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'typeID', fields.typeID);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	adapter.addField(rules.string, 'url', fields.url);
	adapter.addField(rules.string, 'imageURL', fields.imageURL);
	adapter.addField(rules.string, 'videoURL', fields.videoURL);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'linkTitle', fields.linkTitle);
	adapter.addField(rules.string, 'contentTitle', fields.contentTitle);
	adapter.addField(rules.string, 'contentSubtitle', fields.contentSubtitle);
	adapter.addField(rules.string, 'contentShort', fields.contentShort);
	adapter.addField(rules.string, 'contentMain', fields.contentMain);

	adapter.addField(rules.string, 'SEOTitle', fields.seoTitle);
	adapter.addField(rules.string, 'SEODescription', fields.seoDescription);
	adapter.addField(rules.string, 'SEOKeywords', fields.seoKeywords);

	adapter.addField(rules.string, 'OGTitle', fields.OGTitle);
	adapter.addField(rules.string, 'OGDescription', fields.OGDescription);
	adapter.addField(rules.string, 'OGImage', fields.OGImage);

	return adapter;
}
