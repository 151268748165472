import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	transactionIDs: [],
	entities      : {},

	filter: {},

	UI: {
		loading     : false,
		visible     : false,
		modalLoading: false,
	},
	websiteList: [],

	dataModalInner: {},
});

export default function transactionsReducer(state = initState, action) {

	switch (action.type) {

	case actions.TRANSACTIONS_DATA_REFRESH: {
		const { transactionIDs, entities } = action.data;
		return state
			.set('transactionIDs', transactionIDs)
			.set('entities', entities);
	}

	case actions.TRANSACTIONS_NEW_TRANSACTION_LIST_REFRESH: {
		const { newTransactionIDs, newTransactionEntities } = action.data;
		return state
			.set('newTransactionIDs', newTransactionIDs)
			.set('newTransactionEntities', newTransactionEntities);
	}

	case actions.TRANSACTIONS_FILTER_RESET: {
		return state
			.set('filter', action.data);
	}

	case actions.TRANSACTIONS_FILTER_REFRESH: {
		const target = state.get('filter');
		const result = fill(action.data, target, true);
		return state.set('filter', result);
	}

	case actions.TRANSACTIONS_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.TRANSACTIONS_WEBSITES_LIST_REFRESH: {
		return state.set('websiteList', action.data);
	}

	case actions.TRANSACTIONS_DATA_MODAL_REFRESH: {
		return state.set('dataModalInner', action.data);
	}

	case actions.RESET_TRANSACTIONS: {
		return  initState;
	}

	default:
		return state;
	}
}
