import React, { useState, useEffect, SyntheticEvent, FC } from 'react';
import Fuse, { FuseResult } from 'fuse.js';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import cn from 'classnames';
import tabsActions from '../../../redux/appTabs/actions';
import { RootState } from '../../../redux/store';
import { Wrapper } from './TopbarSearch.style';
import { fuseOptions, madeOptions } from './utils';

interface TopbarSearchProps {
}

interface ISidebarMenuItem { // TODO move to file type after sidebar interface implementation
	id: string,
	componentName: string,
	componentProps: object,
	location: string,
	titleID: string,
	name: string
}

const TopbarSearch: FC<TopbarSearchProps> = () => {
	const dispatch = useDispatch();
	const sidebarMenu = useSelector((state: RootState) => state.Sidebar.get('searchMenu'));
	const [searchString, setSearchString] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [dataSearch, setDataSearch] = useState([]);
	const [fuse, setFuse] = useState(new Fuse([], fuseOptions));
	const onChange = (value: string) => {
		setSearchString(value);
	};

	const onSubmit = (searchString: string) => {
		if (!isOpen) {
			return;
		}
		const tab = dataSearch.filter((item: ISidebarMenuItem) => item.name === searchString)[0];
		tab && dispatch(tabsActions.openTab(tab));
		setSearchString('');
	};

	const onClick = <T extends SyntheticEvent>(e: T) => {
		if ((e.target as HTMLElement).tagName.toLowerCase().includes('input')) {
			return;
		}
		setIsOpen(!isOpen);
		setSearchString('');
	};

	const fuseSearch = () => {
		const res: Array<FuseResult<string>> = fuse.search(searchString);
		return madeOptions(res);
	};

	useEffect(() => {
		if (sidebarMenu && sidebarMenu.length) {
			const dataSearch = sidebarMenu.flat(Infinity);
			setDataSearch(dataSearch);
			setFuse(new Fuse(dataSearch, fuseOptions));
		}
	}, [sidebarMenu, searchString]);

	return (
		<Wrapper
			options={fuseSearch()}
			onSelect={onSubmit}
			onSearch={onChange}
			onClick={onClick}
			value={searchString}
			className={cn({ 'opened': isOpen })}
		>
			<Input.Search
				size="middle"
				placeholder="input here"
			/>
		</Wrapper>
	);
};
export default TopbarSearch;
