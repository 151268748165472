import { Map } from 'immutable';
import actions from './actions';

const initialState = new Map({
	dateRange: [],
	name     : '',
});

export default (state = initialState, { type, data }) => {
	switch (type) {
	case actions.DAY_MATCHES_FILTER_SET_VALUE:
		return state.set(data.field, data.value);
	case actions.DAY_MATCHES_FILTER_RESET_VALUES:
		return initialState;
	default:
		return state;
	}
};
