import { Form } from 'antd';
import styled from 'styled-components';

const { Item } = Form;

export const FormItemWrapper = styled(Item)`
  display: flex;
  .ant-form-item-label {
    min-width: 14em;
    text-align: left;
  }
  .ant-form-item-control-wrapper{
    flex: 1;
  }
`;


export const SpanRequired = styled.span`
    &:before {
      content: '${props => props.mandatory ? '*' : ''}';
      color: red;
      padding-right: 6px;
    }
`;
