import { normalize, schema } from 'normalizr';
import toInteger from 'lodash/toInteger';
import { isUndefined, find, isEmpty, isArray } from 'lodash';
import { isID } from '../../../../helpers/utils';
import {
	fields,
	createListAdapter,
	createPaymentsListAdapter,
	createPaymentLimitsAdapter,
	createCasinoListAdapter,
	createCurrencyListAdapter,
	createPaymentDescriptionsAdapter,
	createLanguageListAdapter,
	createCountryListAdapter,
	createAttachedLanguageListAdapter,
	createAttachedCountryListAdapter,
	createCountryListPreparer,
	createSeoAdapter,
} from '../list/utils';
import { WEBSITE_INTEGRATION_TABS, websiteDomainTypes } from '../../../../constants/website';

const listAdapter                 = createListAdapter();
const paymentsListAdapter         = createPaymentsListAdapter();
const paymentLimitsAdapter        = createPaymentLimitsAdapter();
const paymentDescriptionsAdapter  = createPaymentDescriptionsAdapter();
const casinoListAdapter           = createCasinoListAdapter();
const currencyListAdapter         = createCurrencyListAdapter();
const languageListAdapter         = createLanguageListAdapter();
const attachedLanguageListAdapter = createAttachedLanguageListAdapter();
const countryListAdapter          = createCountryListAdapter();
const countryListPreparer         = createCountryListPreparer();
const attachedCountryListAdapter  = createAttachedCountryListAdapter();
const seoAdapter                  = createSeoAdapter();


// Adapt ------------------------------------------------------------------------------------------

export function adaptWebsite(rawData = {}) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);

	return adaptedData;
}

export function adaptPayments(rawData = []) {

	paymentsListAdapter.clearExcludes();
	const adaptedData = paymentsListAdapter.adaptList(rawData);

	const tempData = {
		items: adaptedData,
	};

	const item = new schema.Entity('items', {}, { idAttribute: 'id' });
	const itemSchema = { items: [item] };
	const normalizedData = normalize(tempData, itemSchema);
	const entities = normalizedData.entities.items || {};

	return entities;
}

export function adaptPaymentLimits(rawData = [], currencyList = []) {
	if (!isArray(currencyList) || isEmpty(currencyList)) {
		return [];
	}

	paymentLimitsAdapter.clearExcludes();
	const adaptedList = paymentLimitsAdapter.adaptList(rawData);
	const result = currencyList.map(currency => {

		const currencyID = currency.id;
		const limitItem  = find(adaptedList, { currencyID }) || { currencyID };
		return {
			currencyID,
			...limitItem,
		};
	});

	return result;
}

export function adaptPaymentDescriptions(rawData = [], currencyList = []) {
	if (!isArray(currencyList) || isEmpty(currencyList)) {
		return [];
	}

	paymentDescriptionsAdapter.clearExcludes();
	const adaptedList = paymentDescriptionsAdapter.adaptList(rawData);

	const result = currencyList.map(currency => {

		const currencyID = currency.id;
		const descriptionItem  = find(adaptedList, { currencyID }) || { currencyID };

		return {
			currencyID,
			description: '',
			...descriptionItem,
		};

	});

	return result;
}


export function adaptCasino(rawData = []) {

	casinoListAdapter.clearExcludes();
	const adaptedData = casinoListAdapter.adaptList(rawData);

	const tempData = {
		items: adaptedData,
	};

	const item = new schema.Entity('items', {}, { idAttribute: 'id' });
	const itemSchema = { items: [item] };
	const normalizedData = normalize(tempData, itemSchema);
	const entities = normalizedData.entities.items || {};

	return entities;
}

export function adaptAdditionalDomains(rawData = [], baseDataAdditionalDomains, create) {

	const entities = {};
	const allreadyCreatedDomains = [];

	if (create) {

		Object.values(baseDataAdditionalDomains).forEach( domain => {
			if ( !isUndefined(domain.id) ) {
				allreadyCreatedDomains.push(domain);
			}
		});
	}

	[...allreadyCreatedDomains, ...rawData].forEach( item => {

		entities[item.id] = {
			id       : item.id,
			websiteID: item.website_id,
			domain   : item.domain,
			redirect : item.domain_type === websiteDomainTypes.redirect || item.redirect,
		};

	});
	return entities;
}

export function adaptNewDomainsForSend(rawData = {}, websiteID, withoutArray) {

	let adaptedData = [];

	if (withoutArray) {
		adaptedData = {
			id         : rawData.id,
			website_id : rawData.websiteID,
			domain     : rawData.domain,
			domain_type: rawData.redirect ? 1 : 2,
		};

	} else {

		Object.values(rawData).forEach( domain => {
			if ( isUndefined(domain.id) ) {
				adaptedData.push({
					website_id : websiteID,
					domain     : domain.domain,
					domain_type: domain.redirect ? 1 : 2,
				});
			}
		});
	}

	return adaptedData;

}

export function adaptCurrencies(rawData = [], currencyList = []) {

	currencyListAdapter.clearExcludes();
	const adaptedList = currencyListAdapter.adaptList(rawData);
	const adaptedData = currencyList.map(currencyItem => {

		const currencyID  = currencyItem.id;
		const adaptedItem = find(adaptedList, { currencyID }) || {};

		return {
			...adaptedItem,
			currencyID,
			name   : `${currencyItem.description || currencyItem.name} (${currencyItem.code})`,
			isExist: Boolean(adaptedItem.currencyID),
		};
	});

	const tempData = { items: adaptedData };
	const item           = new schema.Entity('items', {}, { idAttribute: 'currencyID' });
	const itemSchema     = { items: [item] };
	const normalizedData = normalize(tempData, itemSchema);
	const entities       = normalizedData.entities.items || {};

	return entities;
}

// Prepare ----------------------------------------------------------------------------------------


export function adaptLanguages(rawData, attachedData, attachedLangCount = 0) {
	languageListAdapter.clearExcludes();
	attachedLanguageListAdapter.clearExcludes();
	const adaptedList = languageListAdapter.adaptList(rawData).map(item => ({
		...item,
		name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
	}));
	const adaptedAttachedList = attachedLanguageListAdapter.adaptList(attachedData);
	const adaptedData = adaptedList.map(languageItem => {

		const attachedValue  = find(adaptedAttachedList, { languageID: languageItem.languageID });
		if (attachedValue) {
			attachedLangCount +=  1;
			return {
				...languageItem,
				orderID: attachedValue.orderID,
				isExist: Boolean(attachedValue),
			};
		}
		return  languageItem;

	});

	const tempData = {
		items: adaptedData,
	};

	const item           = new schema.Entity('items', {}, { idAttribute: 'languageID' });
	const itemSchema     = { items: [item] };
	const normalizedData = normalize(tempData, itemSchema);
	const entities       = normalizedData.entities.items || {};

	return { entities, adaptedData, attachedLangCount };
}

export function prepareLanguages(rawData) {
	attachedLanguageListAdapter.clearExcludes();
	const prepared = attachedLanguageListAdapter.prepare(rawData);
	return prepared;
}

export function prepareCountryData(rawData) {
	countryListPreparer.clearExcludes();
	const prepared = countryListPreparer.prepare(rawData);
	return prepared;
}


export function adaptCountries(rawData, attachedData) {
	countryListAdapter.addExcludeField('website_id');
	attachedCountryListAdapter.clearExcludes();
	const adaptedList = countryListAdapter.adaptList(rawData);
	const adaptedAttachedData = attachedCountryListAdapter.adaptList(attachedData);

	const adaptedData = adaptedList.map(countryItem => {
		const attachedValue = find(adaptedAttachedData, { countryID: countryItem.countryID });

		if (attachedValue) {
			return  {
				...countryItem,
				isExist: Boolean(attachedValue),
			};
		}

		return countryItem;
	});

	const tempData = {
		items: adaptedData,
	};

	const item           = new schema.Entity('items', {}, { idAttribute: 'countryID' });
	const itemSchema     = { items: [item] };
	const normalizedData = normalize(tempData, itemSchema);
	const entities       = normalizedData.entities.items || {};

	return { entities, adaptedData };
}


export function prepareWebsite(baseData, changedFields, currentTab, activeTab) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	if (changedFields.suspendPrematch && changedFields.suspendInplay) {
		listAdapter.addExcludeField('suspendAll');
	} else if (changedFields.suspendPrematch && changedFields.suspendAll) {
		listAdapter.addExcludeField('suspendInplay');
	} else if (changedFields.suspendInplay && changedFields.suspendAll) {
		listAdapter.addExcludeField('suspendPrematch');
	} else if (changedFields.suspendAll) {
		if (baseData.suspendPrematchByPlatform || baseData.suspendPrematchInPartner) {
			listAdapter.addExcludeField('suspendPrematch');
		}
		if (baseData.suspendInPlayByPlatform || baseData.suspendInPlayInPartner) {

			listAdapter.addExcludeField('suspendInplay');
		}
	} else if (changedFields.suspendPrematch) {
		listAdapter.addExcludeField('suspendAll');
		listAdapter.addExcludeField('suspendInplay');
	} else if (changedFields.suspendInplay) {
		listAdapter.addExcludeField('suspendAll');
		listAdapter.addExcludeField('suspendPrematch');
	} else if (!changedFields.suspendAll && !changedFields.suspendPrematch && !changedFields.suspendInplay) {
		listAdapter.addExcludeField('suspendAll');
		listAdapter.addExcludeField('suspendPrematch');
		listAdapter.addExcludeField('suspendInplay');
	}

	listAdapter.addExcludeField('suspendPrematchInPartner');
	listAdapter.addExcludeField('suspendInPlayInPartner');

	listAdapter.addExcludeField('suspendAllByPlatform');
	listAdapter.addExcludeField('suspendInPlayByPlatform');
	listAdapter.addExcludeField('suspendPrematchByPlatform');
	listAdapter.addExcludeField('changedStatusByPlatform');

	activeTab !== 'integration' &&  listAdapter.addExcludeField('website_mailing');
	currentTab !== WEBSITE_INTEGRATION_TABS.cdn &&  listAdapter.addExcludeField('website_cdn');
	const preparedData = listAdapter.prepare(baseData);
	if (isID(baseData.id)) {
		preparedData.id = toInteger(baseData.id);
	}
	if (!Object.keys(preparedData.website_cdn || {}).length) {
		delete preparedData.website_cdn;
	}
	return preparedData;
}

export function preparePayments(rawData = {}, websiteID) {
	paymentsListAdapter.clearExcludes();
	paymentsListAdapter.addExcludeField('id');

	rawData.websiteID = websiteID;

	const preparedData = paymentsListAdapter.prepare(rawData);
	if (isID(rawData.id)) {
		preparedData.id = toInteger(rawData.id);
	}

	return preparedData;
}

export function preparePaymentLimits(rawData = [], websitePaymentID = null) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}

	paymentLimitsAdapter.clearExcludes();

	const result = rawData.map(rawItem => {

		const preparedItem = paymentLimitsAdapter.prepare(rawItem);
		preparedItem[fields.websitePaymentID] = websitePaymentID;

		return preparedItem;
	});

	return result;
}

export function preparePaymentDescriptions(rawData = [], websitePaymentID = null, langID = null) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}

	paymentDescriptionsAdapter.clearExcludes();

	const result = rawData.map(rawItem => {

		const preparedItem = paymentDescriptionsAdapter.prepare(rawItem);
		preparedItem[fields.websitePaymentID] = websitePaymentID;
		preparedItem[fields.langID] = langID;

		return preparedItem;
	});

	return result;
}

export function prepareCasino(rawData = {}) {
	casinoListAdapter.clearExcludes();
	casinoListAdapter.addExcludeField('id');
	casinoListAdapter.addExcludeField('websiteID');

	const preparedData = casinoListAdapter.prepare(rawData);
	if (isID(rawData.id)) {
		preparedData.id = toInteger(rawData.id);
	}

	return preparedData;
}

export function prepareCurrency(rawData = {}) {

	currencyListAdapter.clearExcludes();
	currencyListAdapter.addExcludeField('id');

	const preparedData = currencyListAdapter.prepare(rawData);
	if (isID(rawData.id)) {
		preparedData.id = toInteger(rawData.id);
	}
	delete preparedData.description;
	return [preparedData];
}

export function prepareSeoData(rawData = {}, casinoGameID) {
	const langIDs = Object.keys(rawData);
	seoAdapter.clearExcludes();

	return langIDs.reduce((acc, langID) => {
		const data                = Object.assign({}, rawData[langID]);
		data[fields.langID]       = langID;
		data[fields.casinoGameID] = casinoGameID;
		const adapted             = seoAdapter.prepare(data);

		acc.push(adapted);

		return acc;
	}, []);

}
