import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	filter           : {},
	groupList        : [],
	scopeList        : [],
	statisticTypeList: [],
	displayTypeList  : [],
});

export default function marketsFilterReducer(state = initState, action) {

	switch (action.type) {

	case actions.MARKETS_FILTER_SET_VALUE: {
		const { valueName, value } = action.data;
		const filter               = new Map(state.get('filter')).toObject();
		filter[valueName]          = value;

		return state.set('filter', filter);
	}

	case actions.MARKETS_FILTER_REFRESH: {
		return state.set('filter', action.data);
	}

	case actions.MARKETS_FILTER_GROUP_LIST_REFRESH: {
		return state.set('groupList', action.data);
	}

	case actions.MARKETS_FILTER_SCOPE_LIST_REFRESH: {
		return state.set('scopeList', action.data);
	}

	case actions.MARKETS_FILTER_STATISTIC_TYPE_LIST_REFRESH: {
		return state.set('statisticTypeList', action.data);
	}

	case actions.MARKETS_FILTER_DISPLAY_TYPE_LIST_REFRESH: {
		return state.set('displayTypeList', action.data);
	}

	default:
		return state;
	}
}
