import { normalize, schema } from 'normalizr';
import merge from 'lodash/merge';
import isArray from 'lodash/isArray';

import EntityAdapter from '../../../helpers/entityAdapter';

export const fields = {
	// requests
	requestID           : 'id',
	assigneeID          : 'assignee_id',
	requestPlacementTime: 'created',
	authorID            : 'author_id',
	lastMessage         : 'last_message',
	lastMessageBy       : 'last_message_by',
	priority            : 'priority',
	state               : 'state',
	websiteID           : 'website_id',
	messages            : 'messages',
	author              : 'author',
	//messages
	messageID           : 'id',
	created             : 'created',
	attachments         : 'attachments',
	subject             : 'subject',
	body                : 'body',
	noReply             : 'no_reply',
	replyTo             : 'reply_to',
	seen                : 'seen_by_admin',
	bucket              : 'bucket',
	//author
	firstName           : 'first_name',
	lastName            : 'last_name',
	email               : 'email',
	avatar              : 'avatar',
};

const baseAdapter              = messagesCreateRequestAdapter();
const authorAdapter            = createAuthorAdapter();
const messagesAdapter          = createMessageAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptMessagesList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}

	const adaptedData = [];
	const newAdaptedData = [];

	rawData.forEach(data => {
		const adaptedRequest = adaptMessage(data);
		adaptedRequest.messages.sort((a, b) => {
			return a.messageID - b.messageID;
		});
		adaptedData.push(adaptedRequest);
		adaptedRequest.messages.forEach(message => {
			message = merge(message, adaptedRequest, adaptedRequest.author);
			delete message.messages;
			delete message.author;
			message.name = `${message.firstName} ${message.lastName}`;
			message.bucket = message.bucket ? message.bucket : 'Inbox';
			if (message.bucket !== 'Sent' && !message.seen) newAdaptedData.push(message);
		});
	});
	adaptedData.sort((a, b) => {
		const indexA = a.messages.length - 1;
		const indexB = b.messages.length - 1;
		return b.messages[indexB].messageID - a.messages[indexA].messageID;
	});

	const tempData = {
		requests: adaptedData,
	};
	const newTempData = {
		messages: newAdaptedData,
	};

	const request = new schema.Entity('requests', {}, { idAttribute: 'requestID' });
	const requestSchema = { requests: [request] };

	const message = new schema.Entity('messages', {}, { idAttribute: 'messageID' });
	const messageSchema = { messages: [message] };

	const normalizedData = normalize(tempData, requestSchema);
	const newNormalizedData = normalize(newTempData, messageSchema);

	const messagesIDs = normalizedData.result.requests || [];
	const entities = normalizedData.entities.requests || {};
	const newMessagesIDs = newNormalizedData.result.messages || [];
	const newEntities = newNormalizedData.entities.messages || {};

	const result = {
		messagesIDs,
		entities,
		newMessagesIDs,
		newEntities,
	};

	return result;
}

export function adaptMessage(rawData = {}) {
	baseAdapter.clearExcludes();
	messagesAdapter.clearExcludes();
	authorAdapter.clearExcludes();

	const adaptedRequest = baseAdapter.adapt(rawData);
	if (!rawData.messages) {
		return messagesAdapter.adapt(rawData);
	}
	const messages = messagesAdapter.adaptList(adaptedRequest.messages);
	const author = authorAdapter.adapt(adaptedRequest.author);

	adaptedRequest.messages = messages;
	adaptedRequest.author = author;

	return adaptedRequest;
}

// Adapters ---------------------------------------------------------------------------------------

function messagesCreateRequestAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'requestID', fields.requestID);
	adapter.addField(rules.id, 'assigneeID', fields.assigneeID);
	adapter.addField(rules.id, 'authorID', fields.authorID);
	adapter.addField(rules.id, 'lastMessageBy', fields.lastMessageBy);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	adapter.addField(rules.positiveNumber, 'priority', fields.priority);
	adapter.addField(rules.positiveNumber, 'state', fields.state);

	adapter.addField(rules.string, 'lastMessage', fields.lastMessage);
	adapter.addField(rules.string, 'requestPlacementTime', fields.requestPlacementTime);

	adapter.addField(rules.arrayObject, 'messages', fields.messages);
	adapter.addField(rules.object, 'author', fields.author);

	return adapter;
}

function createMessageAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'messageID', fields.messageID);

	adapter.addField(rules.string, 'created', fields.created);
	adapter.addField(rules.string, 'subject', fields.subject);
	adapter.addField(rules.string, 'body', fields.body);
	adapter.addField(rules.string, 'replyTo', fields.replyTo);
	adapter.addField(rules.string, 'bucket', fields.bucket);

	adapter.addField(rules.bool, 'noReply', fields.noReply);
	adapter.addField(rules.bool, 'seen', fields.seen);

	adapter.addField(rules.array, 'attachments', fields.attachments);

	return adapter;
}

function createAuthorAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'firstName', fields.firstName);
	adapter.addField(rules.string, 'lastName', fields.lastName);
	adapter.addField(rules.string, 'email', fields.email);
	adapter.addField(rules.string, 'avatar', fields.avatar);

	return adapter;
}
