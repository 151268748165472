const actions = {

	PAYMENT_DATA_RESET       : 'PAYMENT_DATA_RESET',
	PAYMENT_BASE_DATA_RELOAD : 'PAYMENT_BASE_DATA_RELOAD',
	PAYMENT_BASE_DATA_REFRESH: 'PAYMENT_BASE_DATA_REFRESH',

	PAYMENT_CURRENCY_DATA_RELOAD : 'PAYMENT_CURRENCY_DATA_RELOAD',
	PAYMENT_CURRENCY_DATA_REFRESH: 'PAYMENT_CURRENCY_DATA_REFRESH',

	PAYMENT_NAMES_DATA_RELOAD : 'PAYMENT_NAMES_DATA_RELOAD',
	PAYMENT_NAMES_DATA_REFRESH: 'PAYMENT_NAMES_DATA_REFRESH',

	PAYMENT_SAVE      : 'PAYMENT_SAVE',
	PAYMENT_UI_REFRESH: 'PAYMENT_UI_REFRESH',

	dataReset: () => ({
		type: actions.PAYMENT_DATA_RESET,
	}),
	baseDataReload: (paymentID, forced = false) => ({
		type: actions.PAYMENT_BASE_DATA_RELOAD,
		data: {
			paymentID,
			forced,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.PAYMENT_BASE_DATA_REFRESH,
		data,
	}),

	currencyDataReload: () => ({
		type: actions.PAYMENT_CURRENCY_DATA_RELOAD,
	}),
	currencyDataRefresh: data => ({
		type: actions.PAYMENT_CURRENCY_DATA_REFRESH,
		data,
	}),

	namesDataReload: (paymentID) => ({
		type: actions.PAYMENT_NAMES_DATA_RELOAD,
		data: {
			paymentID,
		},
	}),
	namesDataRefresh: data => ({
		type: actions.PAYMENT_NAMES_DATA_REFRESH,
		data,
	}),

	paymentSave: () => ({
		type: actions.PAYMENT_SAVE,
	}),

	uiRefresh: data => ({
		type: actions.PAYMENT_UI_REFRESH,
		data,
	}),
};

export default actions;
