import { Map } from 'immutable';
import uniq from 'lodash/uniq';
import { fill, isID } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	list      : [],
	changedIDs: [],

	filter: {
		sportID: '',
		name   : '',
	},

	UI: {
		loading  : false,
		showModal: false,
		id       : null,
	},
});

export default function marketsDisplayTypesReducer(state = initState, action) {

	switch (action.type) {

	case actions.MARKETS_DISPLAY_TYPES_LIST_REFRESH: {
		return state.set('list', action.data);
	}

	case actions.MARKETS_DISPLAY_TYPES_FILTER_REFRESH: {
		const target = state.get('filter');
		const result = fill(action.data, target);
		return state.set('filter', result);
	}

	case actions.MARKETS_DISPLAY_TYPES_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.MARKETS_DISPLAY_TYPES_CHANGED_ID_ADD: {
		const { typeID } = action.data;
		const changedIDs = state.get('changedIDs');
		const result = uniq([...changedIDs, typeID]);

		return state.set('changedIDs', result);
	}

	case actions.MARKETS_DISPLAY_TYPES_CHANGED_IDS_RESET: {
		return state.set('changedIDs', []);
	}
	case actions.MARKETS_DISPLAY_TYPES_MODAL_REFRESH: {
		return state.set('UI', action.data);
	}
	case actions.MARKETS_DISPLAY_TYPES_REMOVE_CANCELED_ITEM: {
		const newList = state.get('list').filter(item => isID(item.id));
		return state.set('list', newList);
	}

	default:
		return state;
	}
}
