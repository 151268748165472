import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import websiteSaga from './website/saga';
import scriptsSaga from './scripts/saga';
import websiteSmsGatewaysSaga from './smsgateways/saga';
import responsibleGamblingSaga from './responsibleGambling/saga';

export default function* websitesSaga() {
	yield all([
		fork(listSaga),
		fork(websiteSaga),
		fork(scriptsSaga),
		fork(websiteSmsGatewaysSaga),
		fork(responsibleGamblingSaga),
	]);
}
