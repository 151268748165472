import { apiRequest } from './index';

const smsGatewaysCreate = (data, params = {}) => {
	const req = {
		method: 'POST',
		url   : '/sms_gateway',
		data,
		params,
	};
	return apiRequest(req);
};

const smsGatewaysList = (partnerID, params = {}) => {
	// TODO set Conditionally Partner id for query and params
	// const withoutPartnerID = !!partnerID;
	const url = partnerID ? `/partners/${partnerID}/sms_gateways` : '/sms_gateway';
	const req = {
		method: 'GET',
		url,
		params,
		// withoutPartnerID,
	};

	return apiRequest(req);
};

const smsGatewaysUpdate = (data, id) => {
	const req = {
		method: 'PUT',
		url   : `/sms_gateway/${id}`,
		data,
	};
	return apiRequest(req);
};

const smsGatewaysGetByID = (id) => {
	const req = {
		method: 'GET',
		url   : `/sms_gateway/${id}`,
	};
	return apiRequest(req);
};

const smsGatewaysDelete = (id) => {
	const req = {
		method: 'DELETE',
		url   : `/sms_gateway/${id}`,
	};
	return apiRequest(req);
};

const smsGatewayTestMessage = (data) => {
	const req = {
		method: 'POST',
		url   : '/userSms/testMessage',
		data,
	};

	return apiRequest(req);
};

export const smsGatewaysAPI = {
	smsGatewaysCreate,
	smsGatewaysList,
	smsGatewaysUpdate,
	smsGatewaysGetByID,
	smsGatewaysDelete,
	smsGatewayTestMessage,
};
