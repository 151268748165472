import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../tables/actions';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { getListParams, adaptList } from './utils';
import { deriveTablePagination } from '../../../../selectors/tables';
import { showError } from '../../../../helpers/notifications';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';

const messages = {
	errorListReload: 'bonuses.freebets.error.list.reload',
};

const tableType   = TABLE_TYPES.bonusesFreeBets;

function getStoreData(state) {
	const { Loyalty: { Bonuses: { FreeBets } }, Tables } = state;
	return {
		filter    : FreeBets.get('filter'),
		sorting   : Tables.get(tableType).sorting,
		pagination: deriveTablePagination(tableType, state),
	};
}

function* listReload() {

	yield takeEvery(actions.BONUSES_FREE_BETS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);

		let entities = [];
		let totalCount = 0;

		try {
			const res = yield call(bonusesAPI.freeBetsList, params);
			if (res && res.status === 200) {
				entities = adaptList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.listRefresh(entities));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			showError(messages.errorListReload, error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* freeBetsSaga() {
	yield all([
		fork(listReload),
	]);
}
