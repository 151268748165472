import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import uploadsActions from '../../../redux/images/single/actions';

import { Wrapper, Link, Button, CloseBtn } from './SingleUploader.style';
import { showError } from '../../../helpers/notifications';
import { CloseOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';

const prefix = 'uploaders.single';

const messages = {
	errorFileFormat: `${prefix}.errorFileFormat`,
};

class SingleUploader extends Component {

	static propTypes = {
		id           : PropTypes.string.isRequired,
		profileAvatar: PropTypes.bool.isRequired,

		defaultFileLink: PropTypes.string,
		accept         : PropTypes.string,
		disabled       : PropTypes.bool,
		onUploadSuccess: PropTypes.func,
		onUploadError  : PropTypes.func,
		onProgress     : PropTypes.func,
		onClear        : PropTypes.func,
		newMessageMerge: PropTypes.func,
		onRemove       : PropTypes.func,
		// Redux
		fileLink       : PropTypes.string,
		validationType : PropTypes.string,
		loading        : PropTypes.bool,
		init           : PropTypes.func.isRequired,
		setFileLink    : PropTypes.func.isRequired,
		uploadFile     : PropTypes.func.isRequired,
		beforeUpload   : PropTypes.func,
		minImageWidth  : PropTypes.string,
		minImageHeight : PropTypes.string,
	};

	static defaultProps = {
		defaultFileLink: '',
		accept         : '',
		validationType : '',

		disabled       : false,
		onUploadSuccess: () => {},
		onUploadError  : () => {},
		onProgress     : () => {},
		onClear        : () => {},
		newMessageMerge: () => {},
		onRemove       : () => {},
		beforeUpload   : () => {},

		fileLink      : '',
		loading       : false,
		minImageWidth : '',
		minImageHeight: '',
	};

	constructor(props) {
		super(props);
		this.state = {
			uploading: false,
		};

		this.onClickChooseFile = this.onClickChooseFile.bind(this);
		this.onChangeFile      = this.onChangeFile.bind(this);
		this.onProgress        = this.onProgress.bind(this);
		this.onClose           = this.onClose.bind(this);

		this.fileInputRef = createRef();
	}
	componentDidMount() {
		const fileInput = this.fileInputRef;
	}

	UNSAFE_componentWillMount() {
		const { id, defaultFileLink, init } = this.props;
		init(id, defaultFileLink);
	}


	UNSAFE_componentWillReceiveProps(nextProps) {
		const { id, fileLink, setFileLink } = this.props;
		if (fileLink !== nextProps.defaultFileLink) {
			setFileLink(id, nextProps.defaultFileLink);
		}
	}

	// Events ---------------------------------------------------------------------------------------

	onClickChooseFile(event) {
		const { id } = this.props;
		event.preventDefault();
		const fileInput = this.fileInputRef.current;
		if (!fileInput) {
			console.log(`Uploader ${id}: there is no <input type="file">`);
			return;
		}

		fileInput.click();
	}

	onChangeFile() {
		const { id, fileLink, uploadFile, onUploadSuccess, onUploadError, minImageHeight, minImageWidth, validationType, profileAvatar, beforeUpload } = this.props;
		const fileInput = this.fileInputRef.current;

		const { files } = fileInput;
		if (files.length === 0) {
			console.log(`Uploader ${id}: there are no selected files`);
			return;
		}

		beforeUpload(files[0])

		const file = files[0];
		if (validationType && !file.type.split('/')[1].startsWith(validationType)) {
			showError(messages.errorFileFormat);
			return;
		}
		const onProgress = this.onProgress();

		uploadFile(id, file, onUploadSuccess, onUploadError, fileLink, onProgress, minImageHeight, minImageWidth, profileAvatar);

		this.setState({ uploading: false });
	}

	onProgress() {
		this.setState({ uploading: true });
	}

	onClose(event) {
		const { id, setFileLink, newMessageMerge, onClear, onRemove } = this.props;
		event.preventDefault();

		this.setState({ uploading: false });

		onRemove();
		setFileLink(id, '');
		newMessageMerge({ attachments: [] });
		onClear(id);
	}

	// Renders -------------------------------------------------------------------------------------

	render() {
		const { id, fileLink, disabled, accept, loading } = this.props;
		const { uploading } = this.state;
		const isLink    = Boolean(fileLink);
		const linkHref  = isLink && fileLink;
		const linkTitle = isLink ? fileLink : 'No selected file';
		const acceptProp = accept ? { accept } : '';
		const btnClass = (uploading || disabled) ? 'disabled' : '';

		return (
			<Wrapper>
				<Link href={linkHref} target="_blank" disabled={disabled || !isLink}>
					{loading && (<LoadingOutlined />)}
					<span>{linkTitle}</span>
					{(uploading || isLink) && <CloseBtn onClick={this.onClose}><CloseOutlined /></CloseBtn>}
				</Link>
				<Button
					className={btnClass}
					disabled={uploading || disabled}
					title="Select file"
					onClick={this.onClickChooseFile}
				>
					<UploadOutlined />
				</Button>
				<input type="file" id={id} ref={this.fileInputRef} style={{ display: 'none' }} onChange={this.onChangeFile} {...acceptProp} />
			</Wrapper>
		);
	}
}

export default connect(
	({ Images }, { id }) => ({
		fileLink: deriveFileLink(Images, id),
		loading : deriveLoading(Images, id),
	}), {
		init       : uploadsActions.init,
		setFileLink: uploadsActions.setFileLink,
		uploadFile : uploadsActions.uploadFile,
	}
)(SingleUploader);

// selectors
function deriveFileLink(FileStore, id) {
	const uploaders = FileStore.Single.get('uploaders');
	const uploader  = uploaders[id] || {};

	return uploader.fileLink;
}

function deriveLoading(FileStore, id) {
	const uploaders = FileStore.Single.get('uploaders');
	const uploader  = uploaders[id] || { UI: {} };

	return uploader.UI.loading;
}
