import { fromJS } from 'immutable';
import actions from './actions';

const initialState = fromJS({
	sports: fromJS([]),
});


export default (state = initialState, { type, data }) => {
	switch (type) {
	case actions.PRIOR_GET_SPORTS_LIST_SUCCESS:
		return state
			.set(
				'sports',
				fromJS(data).sort((a, b) => {
					if (a.get('home_order_id') < b.get('home_order_id')) {
						return -1;
					}
					if (a.get('home_order_id') > b.get('home_order_id')) {
						return 1;
					}

					return 0;

				})
			);
	case actions.PRIOR_REORDER_SPORT_SUCCESS:
		return state.set('sports', data);
	default:
		return state;
	}
};
