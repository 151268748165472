import { normalize, schema } from 'normalizr';
import toInteger from 'lodash/toInteger';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import toNumber from 'lodash/toNumber';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import uniq from 'lodash/uniq';
import {
	CHANNEL_TYPES,
	TRADING_MODE,
	LIMIT_TYPES,
	ACCEPT_BETS_TIMES,
	CATEGORIES_TYPES,
	DURATIONS,
	DYNAMIC_LIMITS_TIMES,
} from '../../../../helpers/commonConstants';
import { channelTypeList } from '../../../../helpers/commonEnums';
import { isID } from '../../../../helpers/utils';
import {
	getTimeOfMilliseconds,
	getMillisecondsOfTime,
} from '../../../../helpers/utility';
import EntityAdapter from '../../../../helpers/entityAdapter';

const fields = {
	id                  : 'id',
	name                : 'name',
	typeID              : 'type_id',
	parentID            : 'parent_id',
	statusID            : 'status_id',
	iconURL             : 'icon_url',
	iconSmallURL        : 'icon_small_url',
	code                : 'code',
	bgURL               : 'bg_url',
	sourceTypeID        : 'source_type_id',
	sourceID            : 'source_id',
	resultSourceID      : 'result_source_id',
	autoCreate          : 'auto_create',
	clockAvailability   : 'clock_availability',
	allowMixedMultiplies: 'allow_mixed_multiplies',
	displayStatusID     : 'display_status_id',
	alias               : 'alias',
	categoryColor       : 'bg_color',

	userWonLimitParentAbsolute: 'parent_user_won_limit',
	userWonLimitParent        : 'user_won_limit_Parent',
	userWonLimit              : 'user_won_limit',
	userWonLimitTypeID        : 'user_won_limit_value_type',
	userWonLimitOverride      : 'override_user_won_limit',

	userStakeLimitParentAbsolute: 'parent_user_stake_limit',
	userStakeLimitParent        : 'user_stake_limit_parent',
	userStakeLimit              : 'user_stake_limit',
	userStakeLimitTypeID        : 'user_stake_limit_value_type',
	userStakeLimitOverride      : 'override_user_stake_limit',

	combinationMin: 'combination_min',
	combinationMax: 'combination_max',

	notes                : 'notes',
	restrictedBetTypesIDs: 'restricted_bet_types',

	generalStatusID       : 'general_status_id',
	tradingStatusID       : 'trading_status_id',
	promotionParticipation: 'promotion_participation',

	// names & orders
	categoryID   : 'category_id',
	channelID    : 'channel_id',
	channel      : 'channel',
	altName1     : 'alt_name_1',
	altName2     : 'alt_name_2',
	info         : 'info',
	tradingModeID: 'trading_mode',
	orderID      : 'order_id',
	langID       : 'lang_id',

	// settings
	stakeLimitParent        : 'bet_stake_limit_parent',
	stakeLimit              : 'bet_stake_limit',
	stakeLimitTypeID        : 'bet_stake_limit_value_type',
	stakeLimitOverride      : 'override_stake_limit',
	stakeLimitParentAbsolute: 'parent_bet_stake_limit',

	wonLimitParent        : 'bet_won_limit_parent',
	wonLimit              : 'bet_won_limit',
	wonLimitTypeID        : 'bet_won_limit_value_type',
	wonLimitOverride      : 'override_won_limit',
	wonLimitParentAbsolute: 'parent_bet_won_limit',

	betDelay: 'bet_delay',

	margin        : 'odds_key',
	marginTypeID  : 'odds_key_value_type',
	marginOverride: 'override_odds_key',

	acceptBetsBeforeStart         : 'accept_bets_before_start',
	acceptBetsBeforeStartTimeValue: 'acceptBetsBeforeStartTimeValue',
	acceptBetsBeforeStartTimeID   : 'acceptBetsBeforeStartTimeValue',

	overask             : 'overask',
	overaskStakeLimitMin: 'overask_stake_min_limit',
	overaskStakeLimitMax: 'overask_stake_max_limit',
	overaskWonLimitMin  : 'overask_won_min_limit',
	overaskWonLimitMax  : 'overask_won_max_limit',

	// dynamic limits
	minutesBefore: 'minutes_before',
	limit        : 'limit',

	// CMS
	description1: 'description_1',
	description2: 'description_2',

	// markets
	liveMarketIDs    : 'live_markets_id',
	prematchMarketIDs: 'prematch_markets_id',

	// margins
	leagueID: 'league_id',
	marketID: 'market_id',
	oddsKey : 'odds_key',

	// history                     : TODO - no in server response
	creationTime     : 'creationTime',
	createdBy        : 'createdBy',
	pricesProvider   : 'pricesProvider',
	scoreProvider    : 'scoreProvider',
	editedBy         : 'editedBy',
	marketsEditing   : 'marketsEditing',
	selectionsEditing: 'selectionsEditing',
};

const baseAdapter = createBaseDataAdapter();
const settingsAdapter = createSettingsDataAdapter();
const historyAdapter = createHistoryAdapter();
const generalMarketsAdapter = createGeneralMarketListAdapter();
const leagueMarketsAdapter = createLeagueMarketListAdapter();
const leagueMarginsAdapter = createLeagueMarginListAdapter();

// Adapting ---------------------------------------------------------------------------------------
export function adaptBaseData(rawData = {}) {
	baseAdapter.clearExcludes();
	return baseAdapter.adapt(rawData);
}

export function adaptNamesData(rawData = [], defaultName = null) {
	const result = [];

	channelTypeList.forEach(channelItem => {
		const channelID = channelItem.id;
		const channelName = channelItem.name;

		const namesItem =      rawData.find(item => toInteger(item[fields.channelID]) === channelID)
      || {};

		const record = {
			channelID,
			channel   : channelName,
			categoryID: namesItem[fields.categoryID],
			name      : namesItem[fields.name],
			altName1  : namesItem[fields.altName1],
			altName2  : namesItem[fields.altName2],
			info      : namesItem[fields.info],
		};

		if (channelID === CHANNEL_TYPES.backend && !record.name && defaultName) {
			record.name = defaultName;
		}

		result.push(record);
	});

	return result;
}

export function adaptSettingsData(rawData = [], categoryType = CATEGORIES_TYPES.sport) {
	settingsAdapter.clearExcludes();
	const adaptedData = settingsAdapter.adaptList(rawData);

	const prematchItem = adaptedData.find(
		item => item.tradingModeID === TRADING_MODE.prematch
	);
	const liveItem = adaptedData.find(
		item => item.tradingModeID === TRADING_MODE.live
	);

	if (!prematchItem) {
		const item = settingsAdapter.adapt({});
		item.tradingModeID = TRADING_MODE.prematch;
		adaptedData.push(item);
	}

	if (!liveItem) {
		const item = settingsAdapter.adapt({});
		item.tradingModeID = TRADING_MODE.live;
		adaptedData.push(item);
	}

	adaptedData.forEach(item => {
		const { acceptBetsBeforeStart } = item;

		item.acceptBetsBeforeStartTimeValue = acceptBetsBeforeStart > 0
			? getTimeOfMilliseconds(acceptBetsBeforeStart).count
			: 0;
		item.acceptBetsBeforeStartTimeID = acceptBetsBeforeStart > 0
			? getTimeOfMilliseconds(acceptBetsBeforeStart).id
			: ACCEPT_BETS_TIMES.always;

		if (categoryType === CATEGORIES_TYPES.sport) {
			item.stakeLimitOverride = true;
			item.wonLimitOverride = true;
			item.marginOverride = true;

			item.stakeLimitTypeID = LIMIT_TYPES.absolute;
			item.wonLimitTypeID = LIMIT_TYPES.absolute;
			item.marginTypeID = LIMIT_TYPES.absolute;
		}
	});

	return adaptedData;
}

export function adaptDynamiLimits(rawData) {
	let limits = cloneDeep(rawData);
	if (!isArray(limits)) {
		limits = [];
	}

	const adaptedLimits = [];

	const dayDuration = DURATIONS.minutesInDay;
	const hourDuration = DURATIONS.minutesInHour;

	limits.forEach(item => {
		const newItem = {
			id           : item.id,
			tradingModeID: item[fields.tradingModeID],
			limit        : item[fields.limit],
		};
		const minutes = toInteger(item[fields.minutesBefore]);

		const daysCount = minutes / dayDuration;
		const hoursCount = minutes / hourDuration;

		let timeTypeID = DYNAMIC_LIMITS_TIMES.minutes;
		let minutesBefore = minutes;

		// days
		if (daysCount >= 1 && Math.round(daysCount) === daysCount) {
			timeTypeID = DYNAMIC_LIMITS_TIMES.days;
			minutesBefore = daysCount;

			// hours
		} else if (hoursCount >= 1 && Math.round(hoursCount) === hoursCount) {
			timeTypeID = DYNAMIC_LIMITS_TIMES.hours;
			minutesBefore = hoursCount;
		}

		newItem.timeTypeID = timeTypeID;
		newItem.minutesBefore = minutesBefore;

		adaptedLimits.push(newItem);
	});

	return adaptedLimits;
}

export function adaptHistoryData(rawData = {}) {
	historyAdapter.clearExcludes();

	historyAdapter.addValue('marketsEditing', rawData[fields.marketsEditing]);
	historyAdapter.addValue('selectionsEditing', rawData[fields.selectionsEditing]);

	const adaptedData = historyAdapter.adapt(rawData);
	const result = [];

	result.push({ id: 1, name: 'creationTime', data: adaptedData.creationTime });
	result.push({ id: 2, name: 'createdBy', data: adaptedData.createdBy });
	result.push({
		id  : 3,
		name: 'pricesProvider',
		data: adaptedData.pricesProvider,
	});
	result.push({
		id  : 4,
		name: 'scoreProvider',
		data: adaptedData.scoreProvider,
	});
	result.push({ id: 5, name: 'editedBy', data: adaptedData.editedBy });
	result.push({
		id  : 6,
		name: 'marketsEditing',
		data: adaptedData.marketsEditing,
	});
	result.push({
		id  : 7,
		name: 'selectionsEditing',
		data: adaptedData.selectionsEditing,
	});

	return result;
}

export function adaptCMSData(serverData = [], categoryID = null) {
	let rawData = cloneDeep(serverData);

	const result = [];

	if (isEmpty(rawData)) {
		rawData = [];
	}
	if (isPlainObject(rawData)) {
		rawData = [rawData];
	}

	channelTypeList.forEach(channelItem => {
		const channelID = channelItem.id;
		const cmsItem =      rawData.find(item => toInteger(item[fields.channelID]) === channelID)
      || {};

		result.push({
			channelID,
			categoryID,
			description1: cmsItem[fields.description1],
			description2: cmsItem[fields.description2],
		});
	});

	return result;
}

export function adaptGeneralMarketList(rawData) {
	generalMarketsAdapter.clearExcludes();
	const adaptedList = generalMarketsAdapter.adaptList(rawData);

	const tempData = {
		markets: adaptedList,
	};

	const market = new schema.Entity('markets');
	const marketSchema = { markets: [market] };

	const normalizedData = normalize(tempData, marketSchema);

	const marketIDs = normalizedData.result.markets || [];
	const marketEntities = normalizedData.entities.markets || {};

	return {
		marketIDs,
		marketEntities,
	};
}

export function adaptLeagueMarketList(rawData) {
	leagueMarketsAdapter.clearExcludes();
	const adaptedList = leagueMarketsAdapter.adaptList(rawData);

	// define whole lists of markets
	let prematchList = [];
	let liveList = [];

	adaptedList.forEach(item => {
		prematchList = prematchList.concat(item.prematchMarketIDs);
		liveList = liveList.concat(item.liveMarketIDs);
	});

	prematchList = uniq(prematchList);
	liveList = uniq(liveList);

	// build data structure with channels & trading modes
	const result = [];

	channelTypeList.forEach(channelItem => {
		const channelID = channelItem.id;
		const channelMarketItem = adaptedList.find(
			item => item.channelID === channelID
		);

		let prematchSource = prematchList;
		let liveSource = liveList;

		if (channelMarketItem) {
			const itemPrematchMarketIDs = channelMarketItem.prematchMarketIDs;
			if (
				isArray(itemPrematchMarketIDs)
        && itemPrematchMarketIDs.length >= prematchList.length
			) {
				prematchSource = itemPrematchMarketIDs;
			}
			const itemLiveMarketIDs = channelMarketItem.liveMarketIDs;
			if (
				isArray(itemLiveMarketIDs)
        && itemLiveMarketIDs.length >= liveList.length
			) {
				liveSource = itemLiveMarketIDs;
			}
		}

		prematchSource.forEach((item, index) => {
			result.push({
				channelID,
				tradingModeID: TRADING_MODE.prematch,
				marketID     : item,
				orderID      : index + 1,
			});
		});
		liveSource.forEach((item, index) => {
			result.push({
				channelID,
				tradingModeID: TRADING_MODE.live,
				marketID     : item,
				orderID      : index + 1,
			});
		});
	});

	return {
		liveMarketIDs    : liveList,
		prematchMarketIDs: prematchList,
		channelMarketList: result,
	};
}

export function adaptLeagueMarginList(rawData, liveMarketIDs, prematchMarketIDs) {
	leagueMarginsAdapter.clearExcludes();
	const adaptedList = leagueMarginsAdapter.adaptList(rawData);

	if (!isEmpty(adaptedList)) {
		return adaptedList;
	}

	const allIDs = uniq([].concat(liveMarketIDs).concat(prematchMarketIDs));
	const result = [];

	allIDs.forEach(marketID => {
		let tradingModeID = 0;
		if (
			liveMarketIDs.includes(marketID)
      && prematchMarketIDs.includes(marketID)
		) {
			tradingModeID = TRADING_MODE.both;
		} else if (liveMarketIDs.includes(marketID)) {
			tradingModeID = TRADING_MODE.live;
		} else if (prematchMarketIDs.includes(marketID)) {
			tradingModeID = TRADING_MODE.prematch;
		}

		result.push({
			marketID,
			tradingModeID,
			oddsKey: 0,
		});
	});

	return result;
}

// Preparing --------------------------------------------------------------------------------------
export function prepareBaseData(rawData = {}) {
	baseAdapter.clearExcludes();
	baseAdapter.addExcludeField('id');
	baseAdapter.addExcludeField('parentID');
	const stakeOverride = rawData.parentID ? rawData.userStakeLimitOverride : true;
	const wonOverride = rawData.parentID ? rawData.userWonLimitOverride : true;
	// calculate real absolute limits
	rawData.userStakeLimit = calculateLimit({
		override      : stakeOverride,
		limit         : rawData.userStakeLimit,
		limitTypeID   : rawData.userStakeLimitTypeID,
		parentAbsolute: rawData.userStakeLimitParentAbsolute,
	});
	rawData.userWonLimit = calculateLimit({
		override      : wonOverride,
		limit         : rawData.userWonLimit,
		limitTypeID   : rawData.userWonLimitTypeID,
		parentAbsolute: rawData.userWonLimitParentAbsolute,
	});

	const result = baseAdapter.prepare(rawData);
	result[fields.parentID] = isID(rawData.parentID) ? rawData.parentID : null;

	return result;
}

export function prepareNamesData(rawData = [], categoryID, langID) {
	if (!isArray(rawData)) {
		return [];
	}

	const result = [];

	rawData.forEach(namesItem => {
		if (!namesItem.name) {
			return;
		}
		result.push({
			[fields.channelID] : namesItem.channelID,
			[fields.name]      : namesItem.name,
			[fields.altName1]  : namesItem.altName1,
			[fields.altName2]  : namesItem.altName2,
			[fields.info]      : namesItem.info,
			[fields.categoryID]: categoryID,
			[fields.langID]    : langID,
		});
	});

	return result;
}

export function prepareSettingsData(settings) {
	if (!isArray(settings)) {
		return [];
	}
	settingsAdapter.clearExcludes();
	const preparedSettings = [];

	settings.forEach(item => {
		const newItem = settingsAdapter.prepare(item);

		const acceptBetsBeforeStart = getMillisecondsOfTime(
			item.acceptBetsBeforeStartTimeValue,
			item.acceptBetsBeforeStartTimeID
		);
		newItem[fields.acceptBetsBeforeStart] = acceptBetsBeforeStart;

		preparedSettings.push(newItem);
	});

	return preparedSettings;
}

export function prepareDynamicLimits(limits, categoryID) {
	if (!isArray(limits)) {
		return [];
	}

	const dayDuration = DURATIONS.minutesInDay;
	const hourDuration = DURATIONS.minutesInHour;

	const preparedLimits = limits.map(item => {
		const newItem = {
			[fields.tradingModeID]: toInteger(item.tradingModeID),
			[fields.limit]        : toNumber(item.limit),
			[fields.categoryID]   : categoryID,
		};
		newItem.id = isID(item.id) ? toInteger(item.id) : null;

		const minutes = toInteger(item.minutesBefore);
		const timeTypeID = toInteger(item.timeTypeID);

		let minutesBefore = minutes;
		if (timeTypeID === DYNAMIC_LIMITS_TIMES.hours) {
			minutesBefore = minutes * hourDuration;
		} else if (timeTypeID === DYNAMIC_LIMITS_TIMES.days) {
			minutesBefore = minutes * dayDuration;
		}

		newItem[fields.minutesBefore] = minutesBefore;

		return newItem;
	});

	return preparedLimits;
}

export function prepareCMSData(rawData = [], categoryID = null) {
	if (!isArray(rawData)) {
		return [];
	}

	return rawData.map(item => {
		return {
			[fields.channelID]   : item.channelID,
			[fields.categoryID]  : categoryID,
			[fields.description1]: item.description1,
			[fields.description2]: item.description2,
		};
	});
}

export function prepareMarketsData(channelMarketList, categoryID) {
	if (!isArray(channelMarketList) || isEmpty(channelMarketList)) {
		return [];
	}

	const result = [];
	const sorter = (itemA, itemB) => {
		return itemA.orderID > itemB.orderID ? 1 : -1;
	};
	const mapper = item => {
		return item.marketID;
	};

	channelTypeList.forEach(channel => {
		const channelID = channel.id;
		const channelItems = channelMarketList.filter(
			item => item.channelID === channelID
		);
		let liveItems = channelItems.filter(
			item => item.tradingModeID === TRADING_MODE.live
		);
		let prematchItems = channelItems.filter(
			item => item.tradingModeID === TRADING_MODE.prematch
		);

		liveItems = liveItems.sort(sorter).map(mapper);
		prematchItems = prematchItems.sort(sorter).map(mapper);

		result.push({
			[fields.channelID]        : channelID,
			[fields.categoryID]       : categoryID,
			[fields.liveMarketIDs]    : liveItems,
			[fields.prematchMarketIDs]: prematchItems,
		});
	});

	return result;
}

export function prepareMarginsData(rawData = [], categoryID) {
	leagueMarginsAdapter.clearExcludes();
	const adaptedList = leagueMarginsAdapter.prepareList(rawData);

	return adaptedList.map(item => {
		return {
			...item,
			[fields.leagueID]: categoryID,
		};
	});
}

// Adapters ---------------------------------------------------------------------------------------
function createBaseDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'typeID', fields.typeID);
	adapter.addField(rules.id, 'parentID', fields.parentID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'sourceTypeID', fields.sourceTypeID);
	adapter.addField(rules.id, 'sourceID', fields.sourceID);
	adapter.addField(rules.id, 'resultSourceID', fields.resultSourceID);
	adapter.addField(rules.id, 'displayStatusID', fields.displayStatusID);
	adapter.addField(rules.id, 'generalStatusID', fields.generalStatusID);
	adapter.addField(rules.id, 'tradingStatusID', fields.tradingStatusID);

	adapter.addField(rules.string, 'code', fields.code);

	adapter.addField(rules.bool, 'autoCreate', fields.autoCreate);
	adapter.addField(rules.bool, 'allowMixedMultiplies', fields.allowMixedMultiplies);

	adapter.addField(rules.positiveNumber, 'clockAvailability', fields.clockAvailability);
	adapter.addField(rules.positiveNumber, 'combinationMin', fields.combinationMin);
	adapter.addField(rules.positiveNumber, 'combinationMax', fields.combinationMax);

	adapter.addField(rules.string, 'iconURL', fields.iconURL);
	adapter.addField(rules.alias, 'alias', fields.alias);
	adapter.addField(rules.string, 'iconSmallURL', fields.iconSmallURL);
	adapter.addField(rules.string, 'bgURL', fields.bgURL);
	adapter.addField(rules.string, 'promotionParticipation', fields.promotionParticipation);
	adapter.addField(rules.string, 'categoryColor', fields.categoryColor);

	adapter.addField(rules.arrayString, 'notes', fields.notes);

	adapter.addField(rules.arrayID, 'restrictedBetTypesIDs', fields.restrictedBetTypesIDs);

	adapter.addField(rules.positiveNumber, 'userWonLimitParentAbsolute', fields.userWonLimitParentAbsolute);
	adapter.addField(rules.positiveNumber, 'userWonLimitParent', fields.userWonLimitParent);
	adapter.addField(rules.positiveNumber, 'userWonLimit', fields.userWonLimit);
	adapter.addField(rules.id, 'userWonLimitTypeID', fields.userWonLimitTypeID);
	adapter.addField(rules.bool, 'userWonLimitOverride', fields.userWonLimitOverride);

	adapter.addField(rules.positiveNumber, 'userStakeLimitParentAbsolute', fields.userStakeLimitParentAbsolute);
	adapter.addField(rules.positiveNumber, 'userStakeLimit', fields.userStakeLimit);
	adapter.addField(rules.positiveNumber, 'userStakeLimitParent', fields.userStakeLimitParent);
	// adapter.addField(rules.positiveNumber, 'userStakeLimitLocal', fields.userStakeLimitLocal);
	adapter.addField(rules.id, 'userStakeLimitTypeID', fields.userStakeLimitTypeID);
	adapter.addField(rules.bool, 'userStakeLimitOverride', fields.userStakeLimitOverride);

	return adapter;
}

function createHistoryAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'scoreProvider', fields.scoreProvider);
	adapter.addField(rules.id, 'editedBy', fields.editedBy);

	adapter.addField(rules.fullDate, 'creationTime', fields.creationTime);
	adapter.addField(rules.fullDate, 'pricesProvider', fields.pricesProvider);

	adapter.addField(rules.string, 'createdBy', fields.createdBy);

	return adapter;
}

function createSettingsDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'tradingModeID', fields.tradingModeID);

	adapter.addField(rules.id, 'stakeLimitTypeID', fields.stakeLimitTypeID);
	adapter.addField(rules.positiveNumber, 'stakeLimit', fields.stakeLimit);
	adapter.addField(rules.positiveNumber, 'stakeLimitParent', fields.stakeLimitParent);
	adapter.addField(rules.positiveNumber, 'stakeLimitParentAbsolute', fields.stakeLimitParentAbsolute);
	adapter.addField(rules.bool, 'stakeLimitOverride', fields.stakeLimitOverride);

	adapter.addField(rules.id, 'wonLimitTypeID', fields.wonLimitTypeID);
	adapter.addField(rules.positiveNumber, 'wonLimit', fields.wonLimit);
	adapter.addField(rules.positiveNumber, 'wonLimitParent', fields.wonLimitParent);
	adapter.addField(rules.positiveNumber, 'wonLimitParentAbsolute', fields.wonLimitParentAbsolute);
	adapter.addField(rules.bool, 'wonLimitOverride', fields.wonLimitOverride);

	adapter.addField(rules.positiveNumber, 'betDelay', fields.betDelay);

	adapter.addField(rules.positiveNumber, 'margin', fields.margin);
	adapter.addField(rules.id, 'marginTypeID', fields.marginTypeID);
	adapter.addField(rules.bool, 'marginOverride', fields.marginOverride);

	adapter.addField(rules.positiveNumber, 'acceptBetsBeforeStart', fields.acceptBetsBeforeStart);

	adapter.addField(rules.bool, 'overask', fields.overask);
	adapter.addField(rules.positiveNumber, 'overaskStakeLimitMin', fields.overaskStakeLimitMin);
	adapter.addField(rules.positiveNumber, 'overaskStakeLimitMax', fields.overaskStakeLimitMax);
	adapter.addField(rules.positiveNumber, 'overaskWonLimitMin', fields.overaskWonLimitMin);
	adapter.addField(rules.positiveNumber, 'overaskWonLimitMax', fields.overaskWonLimitMax);

	return adapter;
}

function createGeneralMarketListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'tradingModeID', fields.tradingModeID);

	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}

function createLeagueMarketListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'channelID', fields.channelID);

	adapter.addField(rules.arrayID, 'liveMarketIDs', fields.liveMarketIDs);
	adapter.addField(rules.arrayID, 'prematchMarketIDs', fields.prematchMarketIDs);

	return adapter;
}

function createLeagueMarginListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'marketID', fields.marketID);
	adapter.addField(rules.id, 'tradingModeID', fields.tradingModeID);

	adapter.addField(rules.positiveNumber, 'oddsKey', fields.oddsKey);

	return adapter;
}

// Others -----------------------------------------------------------------------------------------
function calculateLimit({ override, limit }) {
	if (!override) {
		return 0;
	}
	return  limit;
	// const isPercent = toInteger(limitTypeID) === LIMIT_TYPES.percent;
	// if (!isPercent) {
	//   return limit;
	// }
	//
	// return calculateAbsoluteLimit(parentAbsolute, limit, false);
}

export function changeCode(dataSource, result) {
	const dataCopy = dataSource.map(item => {
		if (item.id === result.id) {
			item.code = result.code;
			return item;
		}
		if (item.children) {
			changeCode(item.children, result);
		}
		return item;
	});

	return dataCopy;
}
