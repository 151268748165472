import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import { fill } from '../../../../helpers/utils';
import { actions } from './actions';

const initState = new Map({

	smsTemplates: [],
	entity      : {},
	variables   : [],

	baseData: {
		id     : 0,
		name   : '',
		content: '',
		slug   : '',
		status : '1',
	},

	UI: {
		loading      : false,
		editMode     : false,
		visible      : false,
		isBaseChanged: false,
	},
});

const clonedState = cloneDeep(initState);

export default (state = clonedState, { type, data }) => {
	switch (type) {
	case actions.BASE_DATA_REFRESH: {
		const target = state.get('baseData');
		const result = fill(data, target);
		return state.set('baseData', result);
	}

	case actions.LIST_REFRESH: {
		return state.set('smsTemplates', data);
	}
	case actions.VARIABLES_REFRESH: {
		return state.set('variables', data);
	}

	case actions.ENTITY_REFRESH: {
		return state.set('entity', data);
	}

	case actions.UI_REFRESH: {
		const UI  = data;
		const target = state.get('UI');
		const result = fill(UI, target);
		return state.set('UI', result);
	}

	case actions.DATA_RESET: {
		return initState;
	}

	case actions.BASE_DATA_RESET: {
		const initial = initState.get('baseData');
		return state.set('baseData', initial);
	}

	default: {
		return state;
	}
	}
};
