import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import listActions from '../list/actions';

import { marketsAPI } from '../../../../helpers/api/markets';
import notifications from '../../../../helpers/notifications';

import {
	adaptBaseData,
	prepareBaseData,
	adaptNamesData,
	prepareNamesData,
	adaptOrdersData,
	prepareOrdersData,
	adaptCMSData,
	prepareCMSData,
	adaptDynamiLimits,
	prepareDynamicLimits,
	adaptSettingsData,
	prepareSettingsData, adaptRulesList, prepareRulesList,
} from './utils';

const prefix = 'sport.market.market';

const messages = {
	errorBaseLoad      : `${prefix}.errorBaseLoad`,
	errorNamesLoad     : `${prefix}.errorNamesLoad`,
	errorOrdersLoad    : `${prefix}.errorOrdersLoad`,
	errorCMSLoad       : `${prefix}.errorCMSLoad`,
	errorDynamicLoad   : `${prefix}.errorDynamicLoad`,
	errorSettingsLoad  : `${prefix}.errorSettingsLoad`,
	errorMappingsLoad  : `${prefix}.errorMappingsLoad`,
	errorRulesLoad     : `${prefix}.errorRulesLoad`,
	errorSelectionsLoad: `${prefix}.errorSelectionsLoad`,

	errorBaseUpdate    : `${prefix}.errorBaseUpdate`,
	errorBaseAdd       : `${prefix}.errorBaseAdd`,
	errorNamesUpdate   : `${prefix}.errorNamesUpdate`,
	errorOrdersUpdate  : `${prefix}.errorOrdersUpdate`,
	errorCMSUpdate     : `${prefix}.errorCMSUpdate`,
	errorDynamicUpdate : `${prefix}.errorDynamicUpdate`,
	errorSettingsUpdate: `${prefix}.errorSettingsUpdate`,
	errorRulesUpdate   : `${prefix}.errorRulesUpdate`,

	successBaseUpdate    : `${prefix}.successBaseUpdate`,
	successBaseAdd       : `${prefix}.successBaseAdd`,
	successNamesUpdate   : `${prefix}.successNamesUpdate`,
	successOrdersUpdate  : `${prefix}.successOrdersUpdate`,
	successCMSUpdate     : `${prefix}.successCMSUpdate`,
	successDynamicUpdate : `${prefix}.successDynamicUpdate`,
	successSettingsUpdate: `${prefix}.successSettingsUpdate`,
	successRulesUpdate   : `${prefix}.successRulesUpdate`,
};

function getStoreData({ Sport: { Markets } }) {

	return {
		filter         : Markets.Filter.get('filter'),
		baseData       : Markets.Market.get('baseData'),
		namesData      : Markets.Market.get('namesData'),
		ordersData     : Markets.Market.get('ordersData'),
		cmsData        : Markets.Market.get('cmsData'),
		settingsData   : Markets.Market.get('settingsData'),
		dynamicData    : Markets.Market.get('dynamicData'),
		resultRulesData: Markets.Market.get('rulesData'),
		modalUI        : Markets.Market.get('modalUI'),
	};
}

function* baseDataReload() {

	yield takeEvery(actions.MARKET_BASE_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { marketID } = action.data;

		const storeData = yield select(getStoreData);
		const { modalUI } = storeData;
		const { langID } = modalUI;

		const params = { lang_id: langID };
		try {
			const response = yield call(marketsAPI.marketGet, marketID, params);
			if (response && response.status === 200) {
				const data = adaptBaseData(response.data.data);
				yield put(actions.baseDataRefresh(data));
			}
		} catch (error) {
			notifications.showError(messages.errorBaseLoad, error);
			console.log(error);
		}

		yield put(actions.modalUIRefresh({
			loading      : false,
			isBaseChanged: false,
		}));
	});
}

function* namesDataReload() {

	yield takeEvery(actions.MARKET_NAMES_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { marketID } = action.data;

		const storeData = yield select(getStoreData);
		const { modalUI } = storeData;
		const { langID, marketName } = modalUI;

		const params = { lang_id: langID };
		let data = adaptNamesData([], marketName);
		try {
			const response = yield call(marketsAPI.marketNamesList, marketID, params);
			if (response && response.status === 200) {
				data = adaptNamesData(response.data.data, marketName);
			}
		} catch (error) {
			notifications.showError(messages.errorNamesLoad, error);
			console.log(error);
		}

		yield put(actions.namesDataRefresh(data));
		yield put(actions.modalUIRefresh({
			loading       : false,
			isNamesChanged: false,
		}));
	});
}

function* ordersDataReload() {

	yield takeEvery(actions.MARKET_ORDERS_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { marketID } = action.data;
		let data = adaptOrdersData([]);
		try {
			const response = yield call(marketsAPI.marketOrdersList, marketID);
			if (response && response.status === 200) {
				data = adaptOrdersData(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorOrdersLoad, error);
			console.log(error);
		}

		yield put(actions.ordersDataRefresh(data));
		yield put(actions.modalUIRefresh({
			loading        : false,
			isOrdersChanged: false,
		}));
	});
}

function* cmsDataReload() {

	yield takeEvery(actions.MARKET_CMS_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { marketID } = action.data;

		const storeData = yield select(getStoreData);
		const { modalUI } = storeData;
		const { langID } = modalUI;

		const params = { lang_id: langID };
		let data = adaptCMSData([]);
		try {
			const response = yield call(
				marketsAPI.marketDescriptionsList,
				marketID,
				params
			);
			if (response && response.status === 200) {
				data = adaptCMSData(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorCMSLoad, error);
			console.log(error);
		}

		yield put(actions.cmsDataRefresh(data));
		yield put(actions.modalUIRefresh({
			loading     : false,
			isCMSChanged: false,
		}));
	});
}

function* dynamicDataReload() {

	yield takeEvery(actions.MARKET_DYNAMIC_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { marketID } = action.data;
		let data = adaptDynamiLimits([]);
		try {
			const response = yield call(marketsAPI.marketDynamicLimitsList, marketID);
			if (response && response.status === 200) {
				data = adaptDynamiLimits(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorDynamicLoad, error);
			console.log(error);
		}

		yield put(actions.dynamicDataRefresh(data));
		yield put(actions.modalUIRefresh({
			loading         : false,
			isDynamicChanged: false,
		}));
	});
}

function* settingsDataReload() {

	yield takeEvery(actions.MARKET_SETTINGS_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { marketID } = action.data;
		let data = adaptSettingsData({});
		try {
			const response = yield call(marketsAPI.marketSettingsList, marketID);
			if (response && response.status === 200) {
				data = adaptSettingsData(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorSettingsLoad, error);
			console.log(error);
		}
		yield put(actions.settingsDataRefresh(data));
		yield put(actions.modalUIRefresh({
			loading          : false,
			isSettingsChanged: false,
		}));
	});
}

function* mappingsDataReload() {

	yield takeEvery(actions.MARKET_MAPPINGS_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { marketID } = action.data;
		let data = [];
		const params = {
			id   : marketID,
			page : 1,
			limit: 100,
		};
		try {
			const response = yield call(marketsAPI.marketMappingList, params);
			if (response && response.status === 200) {
				data = response.data.data; // eslint-disable-line prefer-destructuring
			}
		} catch (error) {
			notifications.showError(messages.errorMappingsLoad, error);
			console.log(error);
		}

		yield put(actions.mappingsDataRefresh(data));
		yield put(actions.modalUIRefresh({ loading: false }));
	});
}

function* resultRulesDataReload() {

	yield takeEvery(actions.MARKET_RESULT_RULES_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { marketID } = action.data;
		let selectionsList = [];
		let rulesList      = [];
		let errorMessage   = '';
		try {

			errorMessage = messages.errorSelectionsLoad;
			const resSelections = yield call(marketsAPI.marketSelectionsList, marketID);
			if (resSelections && resSelections.status === 200) {
				selectionsList = resSelections.data.data;
			}

			errorMessage = messages.errorRulesLoad;
			const response = yield call(marketsAPI.marketResultRulesList, marketID);
			if (response && response.status === 200) {
				rulesList = adaptRulesList(selectionsList, response.data.data, marketID);
			}
		} catch (error) {
			notifications.showError(errorMessage, error);
			console.log(error);
		}

		yield put(actions.rulesDataRefresh(rulesList));
		yield put(actions.modalUIRefresh({
			loading       : false,
			isRulesChanged: false,
		}));
	});
}

function* marketCopy() {
	yield takeEvery(actions.MARKET_DATA_COPY, function* (action) {
		const { data: marketID } = action;
		try {
			yield call(marketsAPI.marketCopy, marketID);
			notifications.showSuccess(messages.successMarketCopy);
		} catch (error) {
			notifications.showError(messages.errorMarketCopy);
		}
	});
}

function* marketUpdate() {

	yield takeEvery(actions.MARKET_UPDATE, function* () {

		yield put(actions.modalUIRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const {
			baseData,
			namesData,
			ordersData,
			cmsData,
			settingsData,
			dynamicData,
			resultRulesData,
			modalUI,
		} = storeData;

		const { editMode, langID, closeModal, marketName } = modalUI;

		let { marketID } = modalUI;
		let isError      = false;
		let errorMessage = '';

		try {
			// base data
			const preparedBaseData = prepareBaseData(baseData, marketID);
			if (editMode) {
				errorMessage = messages.errorBaseUpdate;
				yield call(marketsAPI.marketUpdate, preparedBaseData);
				yield put(actions.modalUIRefresh({ isBaseChanged: false }));

			} else {
				errorMessage = messages.errorBaseAdd;
				preparedBaseData.name = marketName;
				const response = yield call(marketsAPI.marketAdd, preparedBaseData);
				marketID = toInteger(response.data.data.id);

				yield put(actions.modalUIRefresh({
					marketID,
					editMode     : true,
					isBaseChanged: false,
				}));
			}

			// names
			if (modalUI.isNamesChanged) {
				errorMessage = messages.errorNamesUpdate;
				const preparedNames = prepareNamesData(namesData, marketID, langID);
				const params = { lang_id: langID };
				yield call(marketsAPI.marketNamesUpdate, marketID, preparedNames, params);
				yield put(actions.modalUIRefresh({ isNamesChanged: false }));
			}

			// orders
			if (modalUI.isOrdersChanged) {
				errorMessage = messages.errorOrdersUpdate;
				const preparedOrders = prepareOrdersData(ordersData, marketID);
				yield call(marketsAPI.marketOrdersUpdate, marketID, preparedOrders);
				yield put(actions.modalUIRefresh({ isOrdersChanged: false }));
			}

			// CMS
			if (modalUI.isCMSChanged) {
				errorMessage = messages.errorCMSUpdate;
				const preparedData = prepareCMSData(cmsData, marketID);
				const params = { lang_id: langID };
				yield call(marketsAPI.marketDescriptionsUpdate, marketID, preparedData, params);
				yield put(actions.modalUIRefresh({ isCMSChanged: false }));
			}

			// settings
			if (modalUI.isSettingsChanged) {
				errorMessage = messages.errorSettingsUpdate;
				// const { tradingModeID } = baseData;
				const preparedData = prepareSettingsData(settingsData);
				yield call(marketsAPI.marketSettingsUpdate, marketID, preparedData);
				yield put(actions.modalUIRefresh({ isSettingsChanged: false }));
			}

			// dynamic limits
			if (modalUI.isDynamicChanged) {
				errorMessage = messages.errorDynamicUpdate;
				const preparedData = prepareDynamicLimits(dynamicData, marketID);
				yield call(marketsAPI.marketDynamicLimitsUpdate, marketID, preparedData);
				yield put(actions.modalUIRefresh({ isDynamicChanged: false }));
			}

			// rules
			if (modalUI.isRulesChanged) {
				errorMessage = messages.errorRulesUpdate;
				const preparedData = prepareRulesList(resultRulesData, marketID);
				yield call(marketsAPI.marketResultRulesUpdate, preparedData, { market_id: marketID });
				yield put(actions.modalUIRefresh({ isRulesChanged: false }));
			}

			notifications.showSuccess(messages.successBaseUpdate);

		} catch (error) {
			notifications.showError(errorMessage, error);
			isError = true;
			console.log(error);
		}

		yield put(listActions.listReload());

		if (!closeModal && !isError) {
			yield put(actions.baseDataReload(marketID));

			if (modalUI.isNamesChanged) {
				yield put(actions.namesDataReload(marketID));
			}

			if (modalUI.isOrdersChanged) {
				yield put(actions.ordersDataReload(marketID));
			}

			if (modalUI.isCMSChanged) {
				yield put(actions.cmsDataReload(marketID));
			}

			if (modalUI.isSettingsChanged) {
				yield put(actions.settingsDataReload(marketID));
			}

			if (modalUI.isDynamicChanged) {
				yield put(actions.dynamicDataReload(marketID));
			}

			if (modalUI.isRulesChanged) {
				yield put(actions.rulesDataReload(marketID));
			}
		}

		yield put(actions.modalUIRefresh({ loading: false }));
		if (closeModal && !isError) {
			yield put(actions.marketDataReset());
		}
	});
}

export default function* marketSaga() {
	yield all([
		fork(baseDataReload),
		fork(namesDataReload),
		fork(ordersDataReload),
		fork(cmsDataReload),
		fork(dynamicDataReload),
		fork(settingsDataReload),
		fork(mappingsDataReload),
		fork(resultRulesDataReload),
		fork(marketUpdate),
		fork(marketCopy),
	]);
}
