import { Map } from 'immutable';
import invert from 'lodash/invert';
import cloneDeep from 'lodash/cloneDeep';
import { fill } from '../../../../helpers/utils';
import { MARKET_LOG_MODELS } from '../../../../helpers/commonConstants';

import actions from './actions';

const modelsMap = invert(cloneDeep(MARKET_LOG_MODELS));

const initState = new Map({
	all          : [],
	market       : [],
	names        : [],
	cms          : [],
	orders       : [],
	settings     : [],
	dynamicLimits: [],

	UI: {
		loading: false,
	},
});

export default function marketHistoryReducer(state = initState, action) {
	switch (action.type) {
	case actions.MARKET_HISTORY_MODEL_DATA_REFRESH: {
		const { logData, logModel } = action.data;
		const stateDataName = modelsMap[logModel];
		if (!stateDataName) {
			console.error(`MarketHistoryReducer: Unknown log model ${logModel}`);
			return state;
		}

		return state.set(stateDataName, logData);
	}

	case actions.MARKET_HISTORY_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
