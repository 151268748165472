import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
	entities        : {},
	gamesByProviders: {},
});

export default function casinoProvidersListReducer(state = initState, action) {

	switch (action.type) {

	case actions.CASINO_PROVIDERS_LIST_REFRESH: {
		const { entities } = action.data;
		return state.set('entities', entities);
	}

	case actions.CASINO_INTEGRATORS_PROVIDERS_LIST_REFRESH_GAMES: {
		const target = state.get('gamesByProviders');
		const result = { ...target, ...action.data };
		return state.set('gamesByProviders', result);
	}

	default:
		return state;
	}
}
