import React from 'react'
import { IDashboardContainerProps } from './types'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import Loader from '../components/utility/loader'


export const DashboardContainer: React.FC<IDashboardContainerProps> = (props) => {
    const { children } = props;
    const partnerID = useSelector((state: RootState)  => state.App.get('partnerID'));

    if (partnerID) {
      return <>
        {children}
      </>
    }

    return <Loader />

}
