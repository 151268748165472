import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';

import actions from './actions';
// import freeBetsSaga from '../userFreeBets/saga';
import { showError, showSuccess } from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { casinoAPI } from '../../../../helpers/api/casino';
import { BONUS_TYPES } from '../../../../constants/bonuses';
import { adaptBets, prepareFreeSpin, prepareFreeBet, adaptFotSend, prepareManualBonus } from './utils';
import { getListParams } from '../../../users/list/utils';
import balanceActions from '../../../users/userBalance/actions';

const messages = {
	errorDataSave     : 'bonus.data.save.fail',
	errorGameLimitLoad: 'game.bet.limits.load.fail',
	successDataSave   : 'bonus.data.save.success',
};

function getStoreData(state) {
	const { Loyalty: { Bonuses }, Users, App, Casino } = state;
	const { UserBonus, UserFreeBets } = Bonuses;
	const { Filter, User } = Users;
	const providerIDs = Bonuses.UserBonus.get('checkedProvidersByIntegrator');
	const integratorsList = Casino.Integrators.get('entities');
	const UI = UserBonus.get('UI');
	return {
		bonusData  : cloneDeep(UserBonus.get('bonusData')),
		balanceData: cloneDeep(Users.UserBalance.get('balanceData')),

		baseData         : User.get('baseData'),
		bets             : UserBonus.get('bets'),
		bonusTypeID      : UI.bonusTypeID,
		betID            : UI.betID,
		denominationKey  : UI.denominationKey,
		denominationValue: UI.denominationValue,
		filter           : Filter.get('filter'),
		freeBets         : UserFreeBets.get('entities'),
		massBonus        : UI.massBonus,
		websiteID        : App.get('websiteID'),
		providerIDs,
		integratorsList,
	};
}

function* gameLimitsReload() {

	yield takeEvery(actions.GAME_LIMITS_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { casinoGameID, userID, currencyCode = null } = action.data;

		let params      = { user_id: userID };
		if (currencyCode) {
			params = { currency_code: currencyCode };
		} else {
			params  = { user_id: userID };
		}
		let bets          = [];
		let denominations = {};

		try {
			const res = yield call(casinoAPI.gameBetLimits, casinoGameID, params);
			if (res && res.status === 200) {
				const { data } = res.data;
				bets = adaptBets(data.bets);
				denominations = data.denominations; // eslint-disable-line prefer-destructuring
			}

		} catch (error) {
			showError(messages.errorGameLimitLoad, error);
		}

		yield put(actions.betsRefresh(bets));
		yield put(actions.denominationsRefresh(denominations));
		yield put(actions.uiRefresh({
			betID            : null,
			denominationKey  : null,
			denominationValue: null,
			loading          : false,
		}));
	});
}

function* dataSave() {

	yield takeEvery(actions.DATA_SAVE, function* ({ data }) {
		yield put(actions.uiRefresh({ loading: true }));

		const {
			bonusData,
			baseData,
			bonusTypeID,
			bets,
			betID,
			denominationKey,
			denominationValue,
			massBonus,
			balanceData,
		} = yield select(getStoreData);
		bonusData.bonusTypeID = bonusTypeID;
		bonusData.currencyID = baseData.currencyID;
		let success = false;

		switch (bonusTypeID) {
		case BONUS_TYPES.freeSpin: {
			const limits = {
				bets,
				betID,
				denominationKey,
				denominationValue,
			};
			success = yield call(makeBonusFreeSpin, bonusData, limits, data.isMassBonusPath);
			break;
		}
		case BONUS_TYPES.freeBet: {
			success = yield call(makeBonusFreeBet, bonusData);
			break;
		}
		case BONUS_TYPES.balanceAdjustment: {
			const sendData = Object.assign(bonusData, balanceData);
			success = yield call(makeBonusManualBonus, sendData);
			break;
		}
		default: {
			console.log('Unknown bonus type: ', bonusTypeID);
		}
		}

		if (success) {
			if (massBonus && data.isMassBonusPath) {
				yield put(actions.cancelUserBonusData());
			} else {
				yield put(actions.dataReset());
			}
		} else {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* makeBonusFreeSpin(bonusData, limits, isMassBonusPath) {

	const { userID } = bonusData;
	const { filter, massBonus, websiteID, providerIDs, integratorsList } = yield select(getStoreData);
	const preparedData = yield call(prepareFreeSpin, bonusData, limits, massBonus);
	let res;

	const { providerIDsToArray, integratorIDs } = adaptFotSend(providerIDs, integratorsList);

	try {
		if (isMassBonusPath && massBonus) {
			const userFilter = getListParams({ ...filter, affiliateReference: bonusData.affiliateReference }); // fix affiliate reference to get from filter
			preparedData.website_id = websiteID;
			userFilter.currency_id = filter.currency;
			userFilter.website_id = websiteID;
			if (userFilter.affiliate_reference) {
				userFilter.affiliate_reference = [...userFilter.affiliate_reference]; // fix affiliate reference to get from filter
			}
			if ( integratorIDs ) {
				preparedData.integrator_ids = integratorIDs;
			}
			if ( providerIDsToArray ) {
				preparedData.provider_ids = providerIDsToArray;
			}
			const data = {
				data  : preparedData,
				filter: userFilter,
			};
			res = yield call(bonusesAPI.massFreeSpinCreate, data);
		} else {
			res = yield call(bonusesAPI.freeSpinCreate, userID, preparedData);
		}
		if (res && res.status === 200) {
			showSuccess(messages.successDataSave);
			return true;
		}

	} catch (error) {
		showError(messages.errorDataSave, error);
	}

	return false;
}

function* makeBonusFreeBet(bonusData) {
	const { userID } = bonusData;
	const preparedData = yield call(prepareFreeBet, bonusData);
	// const { freeBets } = yield select(getStoreData);
	try {
		const res = yield call(bonusesAPI.freeBetCreate, userID, preparedData);
		if (res && res.status === 200) {
			showSuccess(messages.successDataSave);
			// yield put(freeBetsActions.listRefresh({ data: [...freeBets, res.data.data] }));
			return true;
		}
	} catch (error) {
		showError(messages.errorDataSave, error);
	}

	return false;
}

function* makeBonusManualBonus(bonusData) {
	const { userID } = bonusData;
	const preparedData = yield call(prepareManualBonus, bonusData);
	try {
		const res = yield call(bonusesAPI.manualBonusCreate, userID, preparedData);
		if (res && res.status === 200) {
			showSuccess(messages.successDataSave);
			yield put(balanceActions.dataReset());
			return true;
		}
	} catch (error) {
		showError(messages.errorDataSave, error);
	}

	return false;
}

function* cancelUserBonusData() {
	yield takeEvery(actions.CANCEL_USER_BONUS_DATA, function* () {
		yield put(actions.bonusDataReset());
		yield put(actions.betsRefresh([]));
		yield put(actions.denominationsRefresh({}));
		yield put(actions.uiRefresh({
			bonusTypeID      : 0,
			visible          : false,
			loading          : false,
			isChanged        : false,
			betID            : null,
			denominationKey  : null,
			denominationValue: null,
			massBonus        : true,
		}));
	});
}


export default function* userBonusSaga() {
	yield all([
		fork(dataSave),
		fork(gameLimitsReload),
		fork(cancelUserBonusData),
	]);
}
