import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import { showError, showSuccess } from '../../../../helpers/notifications';
import { participantsAPI } from '../../../../helpers/api/participants';

import { adaptList, prepareList } from './utils';

const prefix = 'participants.mapping';

const messages = {
	errorInnerListLoad  : `${prefix}.errorInnerListLoad`,
	errorOuterListLoad  : `${prefix}.errorOuterListLoad`,
	errorInnerListSave  : `${prefix}.errorInnerListSave`,
	successInnerListSave: `${prefix}.successInnerListSave`,
};

function getStoreData(state) {
	const { Sport: { Participants } } = state;
	const { Mapping } = Participants;

	return {
		innerList    : Mapping.get('innerList'),
		outerList    : Mapping.get('outerList'),
		closeModal   : Mapping.get('UI').closeModal,
		sportID      : Mapping.get('UI').sportID,
		participantID: Mapping.get('UI').participantID,
	};
}

function* innerListReload() {

	yield takeEvery(actions.INNER_LIST_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { participantID } = action.data;
		let innerList = [];
		try {
			const res = yield call(participantsAPI.participantMappingsList, participantID);
			if (res && res.status === 200) {
				innerList = adaptList(res.data.data);
			}

		} catch (error) {
			showError(messages.errorInnerListLoad, error);
			console.log(error);
		}

		yield put(actions.innerListRefresh(innerList));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* outerListReload() {

	yield takeEvery(actions.OUTER_LIST_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { sportID, providerID } = action.data;
		let outerList = [];
		const params = {
			sport_id   : sportID,
			provider_id: providerID,
			unmapped   : true,
		};
		try {
			const res = yield call(participantsAPI.participantProviderMappingsList, providerID, params);
			if (res && res.status === 200) {
				outerList = adaptList(res.data.data);
			}

		} catch (error) {
			showError(messages.errorOuterListLoad, error);
			console.log(error);
		}

		yield put(actions.outerListRefresh(outerList));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* innerListSave() {

	yield takeEvery(actions.INNER_LIST_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { innerList, closeModal, participantID } = yield select(getStoreData);
		const data = prepareList(innerList);
		let isError = false;

		try {
			const res = yield call(participantsAPI.participantMappingsUpdate, participantID, data);
			if (res && res.status === 200) {
				showSuccess(messages.successInnerListSave);
				yield put(actions.uiRefresh({ isChanged: false }));
			}

		} catch (error) {
			isError = true;
			showError(messages.errorInnerListSave, error);
			console.log(error);
		}

		if (!isError && !closeModal) {
			yield put(actions.innerListReload(participantID));

		} else if (!isError && closeModal) {
			yield put(actions.dataReset());

		} else {
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

export default function* participantsMappingSaga() {
	yield all([
		fork(innerListReload),
		fork(outerListReload),
		fork(innerListSave),
	]);
}
