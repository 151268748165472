const actions = {

	PAYMENTS_PAYWAYS_LIST_RELOAD    : 'PAYMENTS_PAYWAYS_LIST_RELOAD',
	PAYMENTS_PAYWAYS_LIST_REFRESH   : 'PAYMENTS_PAYWAYS_LIST_REFRESH',
	PAYMENTS_PAYWAYS_LIST_UI_REFRESH: 'PAYMENTS_PAYWAYS_LIST_UI_REFRESH',

	PAYMENTS_PAYWAYS_API_REFRESH: 'PAYMENTS_PAYWAYS_API_REFRESH',

	listReload: (paymentID) => ({
		type: actions.PAYMENTS_PAYWAYS_LIST_RELOAD,
		data: {
			paymentID,
		},
	}),
	listRefresh: entities => ({
		type: actions.PAYMENTS_PAYWAYS_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	uiRefresh: data => ({
		type: actions.PAYMENTS_PAYWAYS_LIST_UI_REFRESH,
		data,
	}),

	apiListRefresh: (paymentID) => ({
		type: actions.PAYMENTS_PAYWAYS_API_REFRESH,
		data: {
			paymentID,
		},
	}),
};

export default actions;
