import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData: {
		statusIDs          : [],
		categoryIDs        : [],
		customProviderIDs  : [],
		tagIDs             : [],
		currencyIDs        : [],
		restrictedCountries: [],
		bulkGameIDs        : [],
	},
	bulkGames        : [],
	tags             : [],
	gamesListWithPage: {},

	UI: {
		closeModal           : false,
		loading              : false,
		multipleCustomProvide: false,
		multipleCurrency     : false,
		multiplyCountry      : false,
		checked              : false,
		indeterminate        : false,
	},
});

export default function casinoGameBulk(state = initState, action) {


	switch (action.type) {
	case actions.CASINO_GAME_BULK_DATA_GAMES: {
		return state.set('bulkGames', action.data);
	}
	case actions.CASINO_GAME_BULK_DATA: {
		const target = state.get('baseData');
		const res = { ...target, ...action.data };
		return state.set('baseData', res);
	}

	case actions.CASINO_GAME_BULK_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target, true);
		return state.set('UI', result);
	}

	case actions.GET_TAGS_SUCCESS:  {
		return state.set('tags', action.data);
	}

	case actions.GAME_WITH_PAGE_CASE:  {
		const target = state.get('gamesListWithPage');
		const res = { ...target, ...action.data };
		const val = !Object.keys(action.data).length ? {} : res;
		return state.set('gamesListWithPage', val);
	}

	default:
		return state;
	}
}
