import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import { casinoAPI } from '../../../helpers/api/casino';
import notifications from '../../../helpers/notifications';

import { adaptTagsList } from './utils';

const messages = {
	errorListLoad: 'casinoTags.error.list.load',
};

function getStoreData(state) {
	const { App, Partner: { Websites } } = state;
	const websiteID =  App.get('websiteID');
	const { langID } = Websites.List.get('entities')[+websiteID];
	return {
		websiteID,
		langID,
	};
}

function* listReload() {

	yield takeEvery(actions.CASINO_TAGS_LIST_RELOAD, function* (currencyId) {
		const { websiteID, langID } = yield select(getStoreData);
		const params = {
			website_id : websiteID,
			lang_id    : langID,
			currency_id: currencyId.data,
		};
		let entities = {};
		try {
			const res = yield call(casinoAPI.casinoGamesbyTags, params);
			if (res && res.status === 200) {
				entities = adaptTagsList(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad);
		}

		yield put(actions.listRefresh(entities));
	});
}

export default function* casinoListSaga() {
	yield all([
		fork(listReload),
	]);
}
