import { makeActionCreator } from '../helpers';

const prefix = 'AFFILIATES';

const TYPES = {
	LIST_RELOAD       : `${prefix}_LIST_RELOAD`,
	LIST_REFRESH      : `${prefix}_LIST_REFRESH`,
	FILTER_REFRESH    : `${prefix}_FILTER_REFRESH`,
	FILTER_APPLY      : `${prefix}_FILTER_APPLY`,
	PAGINATION_REFRESH: `${prefix}_PAGINATION_REFRESH`,
	DATA_RESEND       : `${prefix}_DATA_RESEND`,
	UI_REFRESH        : `${prefix}_UI_REFRESH`,
};

const actions = {
	...TYPES,

	listReload       : makeActionCreator(TYPES.LIST_RELOAD),
	listRefresh      : makeActionCreator(TYPES.LIST_REFRESH, 'entities'),
	filterRefresh    : makeActionCreator(TYPES.FILTER_REFRESH, 'filter'),
	filterApply      : makeActionCreator(TYPES.FILTER_APPLY),
	paginationRefresh: makeActionCreator(TYPES.PAGINATION_REFRESH, 'pagination'),
	dataResend       : makeActionCreator(TYPES.DATA_RESEND),
	uiRefresh        : makeActionCreator(TYPES.UI_REFRESH, 'UI'),
};

export default actions;
