import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { calculateLimit } from '../../../../helpers/utils';


export const fields = {
	id             : 'id',
	name           : 'name',
	feedEventID    : 'feed_event_id',
	feedName       : 'feed_name',
	startDate      : 'start_date',
	statusID       : 'status_id',
	generalStatusID: 'general_status_id',
	sourceID       : 'source_id',
	sourceTypeID   : 'source_type_id',
	sportID        : 'sport_id',
	countryID      : 'country_id',
	leagueID       : 'league_id',
	providers      : 'providers',

	userStakeLimitOverride      : 'override_user_stake_limit',
	userStakeLimit              : 'user_stake_limit',
	userStakeLimitParent        : 'user_stake_limit_parent',
	userStakeLimitParentAbsolute: 'parent_user_stake_limit',
	userStakeLimitTypeID        : 'user_stake_limit_value_type',

	userWonLimitOverride      : 'override_user_won_limit',
	userWonLimit              : 'user_won_limit',
	userWonLimitParent        : 'user_won_limit_parent',
	userWonLimitParentAbsolute: 'parent_user_won_limit',
	userWonLimitTypeID        : 'user_won_limit_value_type',
	betDelay                  : 'bet_delay',
	regulationTypeID          : 'regulation_type_id',
	tradingModeID             : 'trading_mode',
	tournamentTypeID          : 'tournament_type_id',
	argument                  : 'argument',
	// market
	marketID                  : 'market_id',
	eventID                   : 'event_id',
	matchOfTheDay             : 'match_of_the_day',
	orderID                   : 'order_id',
	// odd selections
	providerID                : 'provider_id',
	selections                : 'selections',
	odd                       : 'odd',
	oddStatusID               : 'status',
	rowIndex                  : 'row_index',
	colIndex                  : 'column_index',
	settlementStatus          : 'resolution_status_id',
	seoKeywords               : 'seo_keywords',
	seoTitle                  : 'seo_title',
	seoDescription            : 'seo_description',
	OGDescription             : 'og_description',
	OGImage                   : 'og_image',
	OGTitle                   : 'og_title',
	alias                     : 'alias',
};

const baseDataAdapter         = createBaseDataAdapter();
const marketListAdapter       = createMarketListAdapter();
const selectionListAdapter    = createSelectionsListAdapter();
const oddListAdapter          = createOddListAdapter();
const oddSelectionListAdapter = createOddSelectionListAdapter();
const seoDataAdapter          = createSeoDataAdapter();

// Adapting ---------------------------------------------------------------------------------------

export const prepareSeo = (baseData = {}) => {
	const flatBaseData = Object.entries(baseData).reduce((acc, [langID, item]) => {
		const normalized = cloneDeep(item);
		normalized.langID = langID;
		acc.push(normalized);
		return acc;
	}, []);
	return seoDataAdapter.prepareList(flatBaseData);

};

export const adaptSeoData = (rawData = []) => {
	const flatBaseData = seoDataAdapter.adaptList(rawData);
	return flatBaseData.reduce((acc, item) => {
		acc[item.langID] = item;
		return acc;
	}, {});
};

export function adaptBaseData(rawData = {}) {
	baseDataAdapter.clearExcludes();
	const adaptedData = baseDataAdapter.adapt(rawData);

	adaptedData.providerName    = '';
	adaptedData.providerIconURL = '';
	if (isArray(adaptedData.providers) && !isEmpty(adaptedData.providers)) {
		const provider = adaptedData.providers[0];
		adaptedData.providerName    = provider.name || '';
		adaptedData.providerIconURL = provider.icon_url || '';
	}

	return adaptedData;
}

export function adaptMarketList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}

	marketListAdapter.clearExcludes();
	selectionListAdapter.clearExcludes();

	const adaptedData = sortBy( marketListAdapter.adaptList(rawData), ['orderID'] );
	adaptedData.forEach(item => {
		const adaptedSelections = sortBy( selectionListAdapter.adaptList(item.selections), ['rowIndex', 'colIndex'] );
		item.selections = adaptedSelections;
	});

	return adaptedData;
}

export function adaptOddList(rawData = []) {
	if (isEmpty(rawData)) {
		return {
			data     : [],
			providers: [],
		};
	}

	oddListAdapter.clearExcludes();
	oddSelectionListAdapter.clearExcludes();
	const providers = Object.keys(rawData);
	const data = [];
	providers.forEach(provider => {
		data.push(oddListAdapter.adaptList(rawData[provider]));
	});
	const newData = [];
	data.forEach(item => newData.push(...item));
	return {
		data: newData,
		providers,
	};
}

// Preparing --------------------------------------------------------------------------------------

export function prepareBaseData(rawData = {}) {
	baseDataAdapter.clearExcludes();
	baseDataAdapter.addExcludeField('name');

	// calculate real absolute limits
	rawData.userStakeLimitParent = calculateLimit({
		override      : rawData.userStakeLimitOverride,
		limit         : rawData.userStakeLimit,
		limitTypeID   : rawData.userStakeLimitTypeID,
		parentAbsolute: rawData.userStakeLimitParentAbsolute,
	});
	rawData.userWonLimitParent = calculateLimit({
		override      : rawData.userWonLimitOverride,
		limit         : rawData.userWonLimit,
		limitTypeID   : rawData.userWonLimitTypeID,
		parentAbsolute: rawData.userWonLimitParentAbsolute,
	});

	const adaptedData = baseDataAdapter.prepare(rawData);

	return adaptedData;
}

// Adapters ---------------------------------------------------------------------------------------

export function createBaseDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);

	adapter.addField(rules.numberOrNull, 'feedEventID', fields.feedEventID);
	adapter.addField(rules.string,       'feedName',    fields.feedName);

	adapter.addField(rules.intOrNull, 'statusID', fields.statusID);
	adapter.addField(rules.intOrNull, 'generalStatusID', fields.generalStatusID);
	adapter.addField(rules.intOrNull, 'sourceID', fields.sourceID);
	adapter.addField(rules.intOrNull, 'sourceTypeID', fields.sourceTypeID);
	adapter.addField(rules.intOrNull, 'sportID', fields.sportID);
	adapter.addField(rules.intOrNull, 'countryID', fields.countryID);
	adapter.addField(rules.intOrNull, 'leagueID', fields.leagueID);
	adapter.addField(rules.intOrNull, 'userStakeLimitTypeID', fields.userStakeLimitTypeID);
	adapter.addField(rules.intOrNull, 'userWonLimitTypeID', fields.userWonLimitTypeID);
	adapter.addField(rules.intOrNull, 'regulationTypeID', fields.regulationTypeID);
	adapter.addField(rules.intOrNull, 'tradingModeID', fields.tradingModeID);
	adapter.addField(rules.intOrNull, 'tournamentTypeID', fields.tournamentTypeID);

	adapter.addField(rules.bool, 'userStakeLimitOverride', fields.userStakeLimitOverride);
	adapter.addField(rules.bool, 'userWonLimitOverride', fields.userWonLimitOverride);

	adapter.addField(rules.positiveNumber, 'userStakeLimit', fields.userStakeLimit);
	adapter.addField(rules.positiveNumber, 'userStakeLimitParent', fields.userStakeLimitParent);
	adapter.addField(rules.positiveNumber, 'userStakeLimitParentAbsolute', fields.userStakeLimitParentAbsolute);
	adapter.addField(rules.positiveNumber, 'userWonLimit', fields.userWonLimit);
	adapter.addField(rules.positiveNumber, 'userWonLimitParent', fields.userWonLimitParent);
	adapter.addField(rules.positiveNumber, 'userWonLimitParentAbsolute', fields.userWonLimitParentAbsolute);

	adapter.addField(rules.dateTime, 'startDate', fields.startDate);
	adapter.addField(rules.string, 'feedName', fields.feedName);
	adapter.addField(rules.string, 'name', fields.name);

	adapter.addField(rules.noCondition, 'providers', fields.providers);

	return adapter;
}

export function createMarketListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'marketID', fields.marketID);
	adapter.addField(rules.id, 'eventID', fields.eventID);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.intOrNull, 'sourceID', fields.sourceID);
	adapter.addField(rules.intOrNull, 'sourceTypeID', fields.sourceTypeID);

	adapter.addField(rules.intOrNull, 'statusID', fields.statusID);
	adapter.addField(rules.intOrNull, 'tradingModeID', fields.tradingModeID);
	adapter.addField(rules.intOrNull, 'userStakeLimitTypeID', fields.userStakeLimitTypeID);
	adapter.addField(rules.intOrNull, 'userWonLimitTypeID', fields.userWonLimitTypeID);

	adapter.addField(rules.bool, 'userStakeLimitOverride', fields.userStakeLimitOverride);
	adapter.addField(rules.bool, 'userWonLimitOverride', fields.userWonLimitOverride);
	adapter.addField(rules.bool, 'matchOfTheDay', fields.matchOfTheDay);

	adapter.addField(rules.positiveNumber, 'userStakeLimit', fields.userStakeLimit);
	adapter.addField(rules.positiveNumber, 'userStakeLimitParent', fields.userStakeLimitParent);
	adapter.addField(rules.positiveNumber, 'userStakeLimitParentAbsolute', fields.userStakeLimitParentAbsolute);
	adapter.addField(rules.positiveNumber, 'userWonLimit', fields.userWonLimit);
	adapter.addField(rules.positiveNumber, 'userWonLimitParent', fields.userWonLimitParent);
	adapter.addField(rules.positiveNumber, 'userWonLimitParentAbsolute', fields.userWonLimitParentAbsolute);
	adapter.addField(rules.intOrNull, 'settlementStatus', fields.settlementStatus);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'argument', fields.argument);
	adapter.addField(rules.arrayObject, 'selections', fields.selections);

	return adapter;
}

export function createSelectionsListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'marketID', fields.marketID);
	adapter.addField(rules.id, 'eventID', fields.eventID);

	adapter.addField(rules.intOrNull, 'statusID', fields.statusID);
	adapter.addField(rules.intOrNull, 'userStakeLimitTypeID', fields.userStakeLimitTypeID);
	adapter.addField(rules.intOrNull, 'userWonLimitTypeID', fields.userWonLimitTypeID);

	adapter.addField(rules.bool, 'userStakeLimitOverride', fields.userStakeLimitOverride);
	adapter.addField(rules.bool, 'userWonLimitOverride', fields.userWonLimitOverride);
	adapter.addField(rules.string, 'argument', fields.argument);

	adapter.addField(rules.positiveNumber, 'userStakeLimit', fields.userStakeLimit);
	adapter.addField(rules.positiveNumber, 'userStakeLimitParent', fields.userStakeLimitParent);
	adapter.addField(rules.positiveNumber, 'userStakeLimitParentAbsolute', fields.userStakeLimitParentAbsolute);
	adapter.addField(rules.positiveNumber, 'userWonLimit', fields.userWonLimit);
	adapter.addField(rules.positiveNumber, 'userWonLimitParent', fields.userWonLimitParent);
	adapter.addField(rules.positiveNumber, 'userWonLimitParentAbsolute', fields.userWonLimitParentAbsolute);
	adapter.addField(rules.positiveNumber, 'odd', fields.odd);
	adapter.addField(rules.positiveNumber, 'rowIndex', fields.rowIndex);
	adapter.addField(rules.positiveNumber, 'colIndex', fields.colIndex);

	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}

export function createOddListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'providerID', fields.providerID);
	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.intOrNull, 'statusID', fields.statusID);
	adapter.addField(rules.intOrNull, 'settlementStatus', fields.settlementStatus);
	adapter.addField(rules.number, 'odd', fields.odd);

	return adapter;
}

export function createOddSelectionListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.intOrNull, 'oddStatusID', fields.oddStatusID);
	adapter.addField(rules.positiveNumber, 'odd', fields.odd);

	return adapter;
}

export function createSeoDataAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.string, 'SEOTitle', fields.seoTitle);
	adapter.addField(rules.string, 'SEODescription', fields.seoDescription);
	adapter.addField(rules.string, 'SEOKeywords', fields.seoKeywords);

	adapter.addField(rules.string, 'OGTitle', fields.OGTitle);
	adapter.addField(rules.string, 'OGDescription', fields.OGDescription);
	adapter.addField(rules.string, 'OGImage', fields.OGImage);
	adapter.addField(rules.string, 'alias', fields.alias);
	adapter.addField(rules.string, 'langID', fields.langID);

	return adapter;

}
