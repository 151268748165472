import {
	all,
	takeEvery,
	put,
	fork,
	call,
	select,
} from 'redux-saga/effects';
import { Map } from 'immutable';
import { isEmpty, invert } from 'lodash';

import actions from './actions';

import { savedTablesAPI } from '../../helpers/api/savedTables';
import notifications from '../../helpers/notifications';
import { ADMIN_TABLE_TYPES } from '../../helpers/commonConstants';
import { adaptTableData } from './utils';

const prefix = 'tables';

const messages = {
	errorGetTable : `${prefix}.errorGetTable`,
	errorPostTable: `${prefix}.errorPostTable`,
};
const invertedTables = invert(ADMIN_TABLE_TYPES);

function getStoreData({ Tables }) {
	return {
		storeTables: Tables,
	};
}

// eslint-disable-next-line
function* firstGetSavedTables() {
	yield takeEvery(actions.FIRST_GET_SAVED_TABLES, function* () {
		try {

			const { storeTables } = yield select(getStoreData);

			const response = yield call(savedTablesAPI.getSavedTables);
			if (response && response.status === 200) {

				const { data } = response.data;
				const adapted = adaptTableData(data, storeTables);

				const mapTable = new Map(adapted);
				yield put(actions.setTables(mapTable));
			}

		} catch (error) {
			notifications.showError(messages.errorGetTable, error);
			console.log(error);
		}


	});
}
function* getSavedTables() {
	yield takeEvery(actions.TAKE_SAVED_TABLES, function* (action) {

		const { type, listReload } = action.data;

		yield put(actions.loadingRefresh(type, true));

		const { storeTables } = yield select(getStoreData);
		const typeID = ADMIN_TABLE_TYPES[type];
		const table = storeTables.get(type);
		let result = [];
		try {

			if (isEmpty(table.savedColumns)) {
				const response = yield call(savedTablesAPI.getSavedTables);
				if (response && response.status === 200) {
					const { data } = response.data;

					result = data.find(table => table.type_id === typeID);
					result = result ? result.settings.columns : [];
					yield put(actions.columnsRefresh(type, result));

					const adapted = adaptTableData(data, storeTables);

					const mapTable = new Map(adapted);
					yield put(actions.setTables(mapTable));
				}
			}
			/**
       * As the redux-saga does not support built-in completion event,
       * we forced to wait to update the store then call the
       * dataReload ( listReload ) function. This ensures that the call is
       * done using the  the most up-to-date value from the Redux store.
       */

		} catch (error) {
			notifications.showError(messages.errorGetTable, error);
			console.log(error);
		}

		yield call(listReload, type);

		yield put(actions.loadingRefresh(type, false));

	});
}

function* saveTables() {
	yield takeEvery(actions.POST_TABLES, function* (action) {
		const { settings } = action.data;
		const [settingsItem] = settings;
		const { type_id : typeID, settings : innerSettings } = settingsItem;
		const { itemsPerPage, columns } = innerSettings;

		const type = invertedTables[typeID];
		yield put(actions.paginationRefresh(type, { itemsPerPage }));

		try {
			const response = yield call(savedTablesAPI.saveTables, settings);
			if (response && response.status === 200) {
				yield put(actions.columnsRefresh(type, columns));
			}
		} catch (error) {
			notifications.showError(messages.errorPostTable, error);
			console.log(error);
		}
	});
}

export default function* tablesSaga() {
	yield all([
		fork(getSavedTables),
		fork(saveTables),
		/**
     * Initially this was implemented to load table settings
     * at the first render, but the logic moved to getSavedTable saga.
     */
		// fork(firstGetSavedTables),
	]);
}
