import { all } from 'redux-saga/effects';
import appSagas from './app/saga';
import authSagas from './auth/saga';
import appTabsSagas from './appTabs/saga';
import usersSagas from './users/saga';
import commonSagas from './common/saga';
import languageSagas from './languageSwitcher/saga';

import selectionsSagas from './selections/saga';
import settingsSagas from './settings/saga';
import rtmSagas from './rtm/saga';
import sidebarSagas from './sidebar/saga';
import websitesSagas from './partner/websites/saga';
import autoWithDrawSettingsSagas from './autoWithdrawalSettings/saga';
import casinoSagas from './casino/saga';
import imagesSaga from './images/saga';
import blockedIpsSaga from './settings/security/blockedIps/saga';
// import bonusesSaga from './loyalty/bonuses/saga';
import loyaltyRootSaga from './loyalty/saga';
import transactionsSaga from './reports/transactions/saga';
import personalSaga from './staff/saga';
import profileSaga from './profile/saga';
import bettingRulesSaga from './sport/settings/bettingRules/saga';
import integratorSaga from './integrator/saga';
import customerIPSaga from './customerIP/saga';
import customerCardsSaga from './customerCards/saga';
import notificationsSaga from './notifications/saga';
import tablesSaga from './tables/saga';
import affiliatesSaga from './affiliates/saga';
import reportsByPlayerReducer from './reports/reportsByPlayer/saga';
import reportsByGameSaga from './reports/reportsByGame/saga';
import reportsByProviderSaga from './reports/reportsByProvider/saga';
import reportsByIntegratorSaga from './reports/reportsByIntegrator/saga';

import socketSagas from './socket/sagas';
import dashboardSaga from './dashboard/saga';
import partnerSaga from './partner/partners/saga';
import permissionsSaga from './staff/permissions/saga';
import setPasswordSaga from './newAuth/saga';
import websiteModalRiskManagementSaga from './riskManagement/saga';
import cmsSaga from './cms/saga';
import sportSaga from './sport/saga';
import notificationsCenterSaga from './notificationCenter/saga';

export default function* rootSaga() {
	yield all([
		appSagas(),
		authSagas(),
		appTabsSagas(),
		usersSagas(),
		commonSagas(),
		languageSagas(),
		selectionsSagas(),
		settingsSagas(),
		rtmSagas(),
		sidebarSagas(),
		websitesSagas(),
		casinoSagas(),
		imagesSaga(),
		blockedIpsSaga(),
		loyaltyRootSaga(),
		transactionsSaga(),
		personalSaga(),
		profileSaga(),
		bettingRulesSaga(),
		integratorSaga(),
		customerIPSaga(),
		customerCardsSaga(),
		notificationsSaga(),
		tablesSaga(),
		affiliatesSaga(),
		autoWithDrawSettingsSagas(),
		socketSagas(),
		dashboardSaga(),
		reportsByPlayerReducer(),
		reportsByGameSaga(),
		reportsByProviderSaga(),
		reportsByIntegratorSaga(),
		partnerSaga(),
		permissionsSaga(),
		setPasswordSaga(),
		websiteModalRiskManagementSaga(),


		cmsSaga(),
		sportSaga(),
		notificationsCenterSaga(),
	]);
}
