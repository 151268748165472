import { all, takeEvery, put, fork, call, select, delay } from 'redux-saga/effects';
import  isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import notifications from '../../../../helpers/notifications';
import { eventsAPI } from '../../../../helpers/api/events';
import { categoriesAPI } from '../../../../helpers/api/categories';
import { isID } from '../../../../helpers/utils';
import actions from './actions';
import {
	OWNERS,
	adaptSettings,
	prepareSettings,
	adaptDynamiLimits,
	prepareDynamicLimits,
} from '../utils';

import {
	adaptBaseData,
	prepareBaseData,
	adaptMarketList,
	adaptOddList,
	prepareSeo,
	adaptSeoData,
} from './utils';


const prefix = 'sport.events.event';

const messages = {
	errorEventData         : `${prefix}.errorEventData`,
	errorUpdateEvent       : `${prefix}.errorUpdateEvent`,
	errorEventCommon       : `${prefix}.errorEventCommon`,
	errorEventOdds         : `${prefix}.errorEventOdds`,
	errorEventSettings     : `${prefix}.errorEventSettings`,
	errorEventNames        : `${prefix}.errorEventNames`,
	errorEventDynamicLimits: `${prefix}.errorEventDynamicLimits`,
	successEventUpdate     : `${prefix}.successEventUpdate`,
	errorEventOrders       : `${prefix}.errorEventOrders`,
	errorSeoData           : `${prefix}.errorSeoData`,
	errorSeoDataSave       : `${prefix}.errorSeoDataSave`,
};

function getStoreData({ Sport: { Events } }) {

	return {
		selectedEvent    : Events.Event.get('selectedEvent'),
		baseData         : Events.Event.get('baseData'),
		commonData       : Events.Event.get('commonData'),
		namesData        : Events.Event.get('namesData'),
		ordersData       : Events.Event.get('ordersData'),
		settingsData     : Events.Event.get('settingsData'),
		dynamicLimitsData: Events.Event.get('dynamicLimitsData'),
		UI               : Events.Event.get('UI'),
		marketsStatistics: Events.Event.get('marketsStatistics'),
		eventStatistics  : Events.Event.get('eventStatistics'),
		seoBaseData      : Events.Event.get('seoData').baseData,
		seoData          : Events.Event.get('seoData'),
	};
}

function* baseDataReload() {
	const takeAction = actions.EVENT_BASE_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		const { eventID } = action.data;
		const params = {
			include_parent_limit: true,
			active_only         : false,
		};
		try {
			const response = yield call(eventsAPI.eventGet, eventID, params);

			if (response && response.status === 200) {
				const event = adaptBaseData(response.data.data);

				yield put(actions.baseDataRefresh(event));
				yield put(actions.setValueUI('isChangedBase', false));
			}
		} catch (error) {
			notifications.showError(messages.errorEventData, error);
			console.log(error);
		}
	});
}

function* eventUpdate() {
	const takeAction = actions.EVENT_UPDATE;

	yield takeEvery(takeAction, function* (action) {
		const { eventID } = action.data;
		const {
			baseData,
			namesData,
			seoBaseData,
			ordersData,
			settingsData,
			dynamicLimitsData,
			UI,
		} = yield select(getStoreData);

		try {
			// Base data
			const preparedData = prepareBaseData(baseData);
			yield call(eventsAPI.eventUpdate, eventID, preparedData);
			yield put(actions.setValueUI('isChangedBase', false));

			// Names
			if (UI.isChangedNames) {
				const params = { lang_id: namesData.langID };
				const { names } = namesData;

				yield call(eventsAPI.eventNamesUpdate, eventID, names, params);
				yield put(actions.setValueUI('isChangedNames', false));
			}

			if (UI.isChangedSeo) {
				const adaptedSeo = prepareSeo(seoBaseData);
				yield call(eventsAPI.seoDataSave, eventID, adaptedSeo);
				yield put(actions.setValueUI('isChangedSeo', false));
			}

			// Orders
			if (UI.isChangedOrders) {
				yield call(eventsAPI.eventOrdersUpdate, eventID, ordersData);
				yield put(actions.setValueUI('isChangedOrders', false));
			}

			// Settings
			if (UI.isChangedSettings) {
				const eventTradingModeID = baseData.tradingModeID;
				const preparedSettings = prepareSettings(settingsData, OWNERS.event, eventTradingModeID);
				// accept_bets_before_start: 0
				// autoSendLog: true
				// bet_delay: 0
				// bet_stake_limit: 0
				// bet_stake_limit_value_type: 2
				// bet_won_limit: 0
				// bet_won_limit_value_type: 2
				// id: 196
				// overask: true
				// override_accept_bets_before_start: false
				// override_bet_delay: false
				// override_stake_limit: false
				// override_won_limit: false


				// accept_bets_before_start: 0
				// autoSendLog: true
				// bet_delay: 55
				// bet_stake_limit: 15
				// bet_stake_limit_value_type: 1
				// bet_won_limit: 15
				// bet_won_limit_value_type: 2
				// id: 196
				// overask: true
				// override_accept_bets_before_start: false
				// override_bet_delay: false
				// override_stake_limit: true
				// override_won_limit: true
				yield call(eventsAPI.eventSettingsUpdate, eventID, preparedSettings);
				yield put(actions.setValueUI('isChangedSettings', false));
			}

			// Dynamic Limits
			if (UI.isChangedDynamicLimits) {
				const preparedLimits = prepareDynamicLimits(dynamicLimitsData, OWNERS.event);
				yield call(eventsAPI.eventDynamicLimitsUpdate, eventID, preparedLimits);
				yield put(actions.setValueUI('isChangedDynamicLimits', false));
			}

			notifications.showSuccess(messages.successEventUpdate);

		} catch (error) {
			notifications.showError(messages.errorUpdateEvent, error);
			console.log(error);
		}

		yield put(actions.baseDataReload(eventID));
		if (UI.isChangedNames) {
			yield put(actions.namesDataReload(eventID, namesData.langID));
		}
		if (UI.isChangedOrders) {
			yield put(actions.ordersDataReload(eventID));
		}
		if (UI.isChangedSettings) {
			yield put(actions.settingsDataReload(eventID));
		}
		if (UI.isChangedDynamicLimits) {
			yield put(actions.dynamicLimitsDataReload(eventID));
		}
		yield put(actions.setValueUI('loading', false));
	});
}

function* commonDataReload() {
	const takeAction = actions.EVENT_COMMON_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		const { sportID, countryID, leagueID, sportList } = action.data;
		yield put(actions.setValueUI('commonDataLoading', true ));
		let sport = {};
		let country = {};
		let league = {};
		let regulationTypeList = [];

		const categoryParams = { model_type: 1 };

		try {
			// sport
			if (isID(sportID) && isArray(sportList)) {
				sport = sportList.find(
					item => toInteger(item.id) === toInteger(sportID)
				);
				if (!sport) {
					sport = {};
				}
			}

			// country
			if (isID(countryID)) {
				const response = yield call(
					categoriesAPI.categoryInfo,
					countryID,
					categoryParams
				);
				if (response && response.status === 200) {
					country = response.data.data;
				}
			}

			// league
			if (isID(leagueID)) {
				const response = yield call(
					categoriesAPI.categoryInfo,
					leagueID,
					categoryParams
				);
				if (response && response.status === 200) {
					league = response.data.data;
				}
			}

			// regulation types
			if (isID(sportID)) {
				const response = yield call(categoriesAPI.regulationTypesList, sportID);
				if (response && response.status === 200) {
					regulationTypeList = response.data.data;
				}
			}

			const resultData = {
				sport,
				country,
				league,
				regulationTypeList,
			};
			yield put(actions.commonDataRefresh(resultData));
			yield call(delay, 400);
			yield put(actions.setValueUI('commonDataLoading', false ));

		} catch (error) {
			notifications.showError(messages.errorEventCommon, error);
			console.log(error);
		}
	});
}

function* oddsDataReload() {
	const takeAction = actions.EVENT_ODDS_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {

		yield put(actions.setValueUI('loading', true));
		let marketList = [];
		let oddList = [];
		let providersList = [];
		yield put(actions.oddsDataRefresh({ marketList, oddList, providersList }));

		const { eventID } = action.data;
		try {
			// event markets
			const params = { include_parent_limit: true };
			const marketResponse = yield call(
				eventsAPI.eventMarketList,
				eventID,
				true,
				params
			);
			if (marketResponse && marketResponse.status === 200) {
				marketList = adaptMarketList(marketResponse.data.data);
			}

			// odds
			const oddsResponse = yield call(eventsAPI.eventProviderOddsList, eventID);
			if (oddsResponse && oddsResponse.status === 200) {
				oddList = adaptOddList(oddsResponse.data.data).data;
				providersList = adaptOddList(oddsResponse.data.data).providers;
			}
		} catch (error) {
			notifications.showError(messages.errorEventOdds, error);
			console.log(error);
		}

		const resultData = {
			marketList,
			oddList,
			providersList,
		};

		yield put(actions.oddsDataRefresh(resultData));
		yield put(actions.setValueUI('loading', false));
	});
}

function* settingsDataReload() {
	const takeAction = actions.EVENT_SETTINGS_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		const { eventID } = action.data;
		const params = { include_parent_limit: true };
		let settings = [];

		try {
			const response = yield call(eventsAPI.eventSettingsList, eventID, params);
			if (response && response.status === 200) {
				settings = adaptSettings(response.data.data, OWNERS.event);
			}
		} catch (error) {
			notifications.showError(messages.errorEventSettings, error);
			console.log(error);
		}

		yield put(actions.settingsDataRefresh(settings));
		yield put(actions.setValueUI('isChangedSettings', false));
	});
}

function* namesDataReload() {
	const takeAction = actions.EVENT_NAMES_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		const { eventID, langID } = action.data;
		const params = { lang_id: langID };
		let names = [];

		try {
			const response = yield call(eventsAPI.eventNamesList, eventID, params);
			if (response && response.status === 200) {
				names = response.data.data;
				if (!isArray(names)) {
					names = [];
				}
			}
		} catch (error) {
			notifications.showError(messages.errorEventNames, error);
			console.log(error);
		}

		yield put(actions.namesDataRefresh({ langID, names }));
		yield put(actions.setValueUI('isChangedNames', false));
	});
}

function* seoDataReload() {
	const takeAction = actions.EVENT_SEO_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		const { eventID } = action.data;
		const { seoData } = yield select(getStoreData);

		try {
			const response = yield call(eventsAPI.seoData, eventID);
			if (response && response.status === 200) {
				const adapted = adaptSeoData(response.data.data);
				const cloned = cloneDeep(seoData);
				cloned.baseData = adapted;
				yield put(actions.seoDataRefresh(cloned));
			}
		} catch (error) {
			notifications.showError(messages.errorSeoData);
			console.log(error);
		}

		yield put(actions.setValueUI('isChangedSeo', false));
	});
}

// function* seoDataSave() {
//   const takeAction = actions.EVENT_SEO_DATA_SAVE;
//
//   yield takeEvery(takeAction, function* (action) {
//     const { eventID } = action.data;
//
//     try {
//       const response = yield call(eventsAPI.seoDataSave, eventID);
//       if (response && response.status === 200) {
//
//       }
//     } catch (error) {
//       notifications.showError(messages.errorSeoDataSave);
//       console.log(error);
//     }
//
//     yield put(actions.setValueUI('isChangedSeo', false));
//   });
// }

function* ordersDataReload() {
	const takeAction = actions.EVENT_ORDERS_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		const { eventID } = action.data;
		let orders = [];

		try {
			const response = yield call(eventsAPI.eventOrdersList, eventID);
			if (response && response.status === 200) {
				orders = response.data.data;
				if (!isArray(orders)) {
					orders = [];
				}
			}
		} catch (error) {
			notifications.showError(messages.errorEventOrders, error);
			console.log(error);
		}

		yield put(actions.ordersDataRefresh(orders));
		yield put(actions.setValueUI('isChangedOrders', false));
	});
}

function* dynamicLimitsDataReload() {
	const takeAction = actions.EVENT_DYNAMIC_LIMITS_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		const { eventID } = action.data;
		let limits = [];

		try {
			const response = yield call(eventsAPI.eventDynamicLimitsList, eventID);
			if (response && response.status === 200) {
				limits = adaptDynamiLimits(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorEventDynamicLimits, error);
			console.log(error);
		}

		yield put(actions.dynamicLimitsDataRefresh(limits));
		yield put(actions.setValueUI('isChangedDynamicLimits', false));
	});
}

function* changeSelectedEvent() {
	yield takeEvery(actions.EVENT_CHANGE_SELECTED, function* (payload) {
		const { id } = payload.data;
		try {
			const response = yield call(eventsAPI.fetchEventData, id);
			const eventStatisticData = yield call(eventsAPI.fetchEventStatisticData, id);
			if (!isEmpty(response)) {
				// const  eventMarketData = adaptEventMarketsStatistics(response.data, 'event_market_id');
				yield put(actions.marketsStatisticsRefresh(response.data.data));
				//yield put(actions.updateEventMarketStatistics(response.data.data));
			}
			if (!isEmpty(eventStatisticData)) {
				yield put(actions.eventStatisticRefresh(eventStatisticData.data.data));
			}

			yield put(actions.setSelectedEvent(payload.data));

		} catch (error) {
			console.log(error);
		}

	});
}

function* onNotifyUpdateEventMarketsStatistics() {
	const takeAction = actions.EVENT_NOTIFY_MARKETS_STATISTICS_UPDATE;
	yield takeEvery(takeAction, function* (action) {
		const updatedData = action.data;

		const storeData = yield select(getStoreData);

		const marketsStatistics = cloneDeep(storeData.marketsStatistics);
		const objIndex = marketsStatistics.findIndex(obj => obj.id === updatedData.id);
		const data = [
			...marketsStatistics.slice(0, objIndex),
			updatedData,
			...marketsStatistics.slice(objIndex + 1),
		];
		yield put(actions.marketsStatisticsRefresh(data));
	});
}

function* onNotifyUpdateEventStatistic() {
	const  takeAction = actions.EVENT_NOTIFY_STATISTIC_UPDATE;
	yield  takeEvery(takeAction, function* (action) {
		const  updatedData = action.data;
		const storeData = yield select(getStoreData);

		const eventStatistics = cloneDeep(storeData.eventStatistics);
		const  data = Object.assign({}, eventStatistics, updatedData);

		yield put(actions.eventStatisticRefresh(data));
	});
}


export default function* eventsSaga() {
	yield all([
		fork(baseDataReload),
		fork(seoDataReload),
		// fork(seoDataSave),
		fork(eventUpdate),
		fork(changeSelectedEvent),
		fork(commonDataReload),
		fork(oddsDataReload),
		fork(settingsDataReload),
		fork(namesDataReload),
		fork(ordersDataReload),
		fork(dynamicLimitsDataReload),
		fork(onNotifyUpdateEventMarketsStatistics),
		fork(onNotifyUpdateEventStatistic),
	]);
}
