import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';
import cloneDeep from 'lodash/cloneDeep';

import notifications from '../../../../helpers/notifications';
import { eventsAPI } from '../../../../helpers/api/events';
import { resultsAPI } from '../../../../helpers/api/results';
import { marketsAPI } from '../../../../helpers/api/markets';

import actions from './actions';
import {
	adaptEvent,
	adaptEventMarkets,
	adaptSelectionsWithResults,
	prepareResultsList,
	adaptResultedEventMarketsAdapter,
	adaptDisplayTypes,
} from './utils';

const prefix = 'participants.results.result';

const messages = {
	errorEventLoad       : `${prefix}.errorEventLoad`,
	errorMarketsLoad     : `${prefix}.errorMarketsLoad`,
	errorResultsLoad     : `${prefix}.errorResultsLoad`,
	errorDisplayTypesLoad: `${prefix}.errorDisplayTypesLoad`,
	errorDataUpdate      : `${prefix}.errorDataUpdate`,
	errorDataUnsettle    : `${prefix}.errorDataUnsettle`,
	successDataUpdate    : `${prefix}.successDataUpdate`,
	successDataUnsettle  : `${prefix}.successDataUnsettle`,
};

function getStoreData({ Sport: { Results }, Auth }) {

	return {
		markets   : Results.Result.get('markets'),
		selections: Results.Result.get('selections'),
		UI        : Results.Result.get('UI'),
		marketsIDs: Results.Result.get('marketsIDs'),
		userID    : toInteger(Auth.get('user').id),
	};
}

function* dataReload() {

	yield takeEvery(actions.DATA_RELOAD, function* (action) {

		let errorMessage = '';

		const { eventID } = action.data;
		const params = {
			active_only           : false,
			include_category_names: true,
		};

		let event        = {};
		let markets      = {};
		let selections   = {};
		let marketsIDs   = [];
		let displayTypes = {};

		try {
			// Event data
			errorMessage = messages.errorEventLoad;
			const resEvent = yield call(eventsAPI.eventGet, eventID, params);

			if (resEvent && resEvent.status === 200) {
				event = adaptEvent(resEvent.data.data);
			}

			// Markets
			errorMessage = messages.errorMarketsLoad;
			const resMarkets = yield call(eventsAPI.eventMarketList, eventID);
			if (resMarkets && resMarkets.status === 200) {
				const adaptedResults = adaptEventMarkets(resMarkets.data.data);
				marketsIDs           = adaptedResults.marketsIDs; // eslint-disable-line prefer-destructuring
				markets              = adaptedResults.markets;    // eslint-disable-line prefer-destructuring
				selections           = adaptedResults.selections; // eslint-disable-line prefer-destructuring
			}

			// Results
			errorMessage = messages.errorResultsLoad;
			const resResults = yield call(resultsAPI.resultInfo, eventID);
			if (resResults && resResults.status === 200) {
				selections = adaptSelectionsWithResults(selections, resResults.data.data);
			}

			// Display Types
			errorMessage = messages.errorDisplayTypesLoad;
			const dtParams = { sport_id: event.sportID };
			const resTypes = yield call(marketsAPI.displayTypeList, dtParams);
			if (resTypes && resTypes.status === 200) {
				displayTypes = adaptDisplayTypes(resTypes.data.data);
			}

		} catch (error) {
			notifications.showError(errorMessage, error);
		}

		yield put(actions.typesRefresh(displayTypes));
		yield put(actions.uiRefresh({
			event,
			eventID  : event.id,
			isChanged: false,
		}));
		yield put(actions.dataRefresh({
			marketsIDs,
			markets,
			selections,
		}));
	});
}

function* dataUpdate() {

	yield takeEvery(actions.DATA_UPDATE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { selections, UI, userID, markets, marketsIDs } = yield select(getStoreData);
		const { eventID } = UI;

		const preparedData = prepareResultsList(selections, userID);

		try {
			const res = yield call(resultsAPI.resultsMarketsListUpdate, eventID, preparedData);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successDataUpdate);
				const responseData = res.data.data;
				const adaptedResponse = adaptResultedEventMarketsAdapter(responseData);
				const clonedMarkets = cloneDeep(markets);

				adaptedResponse.forEach((item) => {
					clonedMarkets[item.id].resolutionStatusID = item.resolutionStatusID;
				});

				yield put(
					actions.dataRefresh({
						marketsIDs: [...marketsIDs],
						markets   : clonedMarkets,
						selections: cloneDeep(selections),
					})
				);
				yield put(actions.uiRefresh({
					isChanged : false,
					changedIDs: [],
				}));
			}

		} catch (error) {
			notifications.showError(messages.errorDataUpdate, error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* doUnsettle() {

	yield takeEvery(actions.DO_UNSETTLE, function* (action) {
		const { selections,   markets, marketsIDs } = yield select(getStoreData);
		let errorMessage = '';

		const { name, id, onSuccess } = action.data;
		const params = {
			[name]: id,
		};
		const newSelections = cloneDeep(selections);
		const clonedMarkets = cloneDeep(markets);

		for (const key in newSelections) {
			if ( newSelections[key].eventMarketID === id || newSelections[key].eventID === id) {
				newSelections[key].won = false;
				newSelections[key].lost = false;
				newSelections[key].void = false;
			}
		}

		try {
			errorMessage = messages.errorDataUnsettle;
			const resEvent = yield call(eventsAPI.doUnsettle, params);

			if (resEvent && resEvent.status === 200) {
				yield put(
					actions.dataRefresh({
						marketsIDs: [...marketsIDs],
						markets   : clonedMarkets,
						selections: newSelections,
					})
				);
				onSuccess(name, id);
				notifications.showSuccess(messages.successDataUnsettle);
			}

		} catch (error) {
			notifications.showError(errorMessage, error);
		}
	});
}

function* marketUpdate() {

	yield takeEvery(actions.MARKET_UPDATE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { selections, UI, userID, markets, marketsIDs } = yield select(getStoreData);
		const { eventID, changedIDs } = UI;
		const { marketID, onSuccess } = action.data;

		const Selections = cloneDeep(selections);
		const newMarkets = cloneDeep(markets);

		const preparedData = prepareResultsList(selections, userID, marketID);

		try {
			const res = yield call(resultsAPI.resultsMarketsListUpdate, eventID, preparedData);
			if (res && res.status === 200) {
				const  marketResponseData = res.data;
				if (marketResponseData.length) {
					// eslint-disable-next-line guard-for-in
					for (const key in newMarkets) {
						if (+key === +marketResponseData[0].id) {
							newMarkets[key].resolutionStatusID = marketResponseData[0].resolution_status_id;
						}
					}
				}

				notifications.showSuccess(messages.successDataUpdate);
				onSuccess(marketID);

				yield put(
					actions.dataRefresh({
						marketsIDs: [...marketsIDs],
						markets   : newMarkets,
						selections: Selections,
					})
				);

				const resChangedIDs = changedIDs.filter(ID => ID !== marketID);
				yield put(actions.uiRefresh({
					changedIDs: resChangedIDs,
					isChanged : resChangedIDs.length > 0,
				}));
			}

		} catch (error) {
			console.log(error);
			notifications.showError(messages.errorDataUpdate, error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* resultsSaga() {
	yield all([
		fork(dataReload),
		fork(dataUpdate),
		fork(doUnsettle),
		fork(marketUpdate),
	]);
}
