
const prefix = 'LOYALTY_TOURNAMENTS';

const TYPES = {
	LIST_RELOAD: `${prefix}_LIST_RELOAD`,

	TOURNAMENT_BY_ID: `${prefix}_TOURNAMENT_BY_ID`,

	LIST_REFRESH           : `${prefix}_LIST_REFRESH`,
	FILTER_REFRESH         : `${prefix}_FILTER_REFRESH`,
	BASE_DATA_REFRESH      : `${prefix}_BASE_DATA_REFRESH`,
	PRIZE_POOL_REFRESH     : `${prefix}_PRIZE_POOL_REFRESH`,
	PRIZE_POOL_BASE_REFRESH: `${prefix}_PRIZE_POOL_BASE_REFRESH`,
	ACTIVE_PRIZE_REFRESH   : `${prefix}_ACTIVE_PRIZE_REFRESH`,
	UI_REFRESH             : `${prefix}_UI_REFRESH`,
	MODAL_DATA_RESET       : `${prefix}_MODAL_DATA_RESET`,

	INTEGRATORS_RELOAD       : `${prefix}_INTEGRATORS_RELOAD`,
	GAMES_PER_PROVIDER_RELOAD: `${prefix}_GAMES_PER_PROVIDER_RELOAD`,
	INTEGRATORS_LIST_REFRESH : `${prefix}_INTEGRATORS_LIST_REFRESH`,
	INTEGRATORS_BASE_REFRESH : `${prefix}_INTEGRATORS_BASE_REFRESH`,

	RELOAD_PARTNERS: `${prefix}_RELOAD_PARTNERS`,
	REFRESH_PARTNER: `${prefix}_REFRESH_PARTNER`,

	REFRESH_POINT_GENERATION: `${prefix}_REFRESH_POINT_GENERATION`,
	POINT_GENERATION_DELETE : `${prefix}_POINT_GENERATION_DELETE`,
	POINT_GENERATION_SAVE   : `${prefix}_POINT_GENERATION_SAVE`,
};

const actions = {
	...TYPES,

	listReload(data) {
		return {
			type: TYPES.LIST_RELOAD,
			data,
		};
	},
	tournamentReload(data) {
		return {
			type: TYPES.TOURNAMENT_BY_ID,
			data,
		};
	},
	partnersForTournamentsReload(data) {
		return {
			type: TYPES.RELOAD_PARTNERS,
			data,
		};
	},

	integratorsReload(data) {
		return {
			type: TYPES.INTEGRATORS_RELOAD,
			data,
		};
	},
	gamesPerProvider(data) {
		return {
			type: TYPES.GAMES_PER_PROVIDER_RELOAD,
			data,
		};
	},

	partnersForTournamentsRefresh(data) {
		return {
			type: TYPES.REFRESH_PARTNER,
			data,
		};
	},

	filterRefresh(data) {
		return {
			type: TYPES.FILTER_REFRESH,
			data,
		};
	},

	listRefresh(data) {
		return {
			type: TYPES.LIST_REFRESH,
			data,
		};
	},

	baseDataRefresh(data, section) {
		return {
			type: TYPES.BASE_DATA_REFRESH,
			data: {
				data,
				section,
			},
		};
	},
	prizePoolRefresh(data) {
		return {
			type: TYPES.PRIZE_POOL_REFRESH,
			data,
		};
	},

	integratorsListRefresh(data) {
		return {
			type: TYPES.INTEGRATORS_LIST_REFRESH,
			data,
		};
	},

	integratorsBaseRefresh(data) {
		return {
			type: TYPES.INTEGRATORS_BASE_REFRESH,
			data,
		};
	},

	prizePoolBaseRefresh(data) {
		return {
			type: TYPES.PRIZE_POOL_BASE_REFRESH,
			data,
		};
	},

	activePrizeRefresh(data) {
		return {
			type: TYPES.ACTIVE_PRIZE_REFRESH,
			data,
		};
	},

	uiRefresh(data) {
		return {
			type: TYPES.UI_REFRESH,
			data,
		};
	},
	modalDataReset(data) {
		return {
			type: TYPES.MODAL_DATA_RESET,
			data,
		};
	},

	pointGenerationRefresh: data => ({
		type: actions.REFRESH_POINT_GENERATION,
		data,
	}),
};

export default actions;
