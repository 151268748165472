import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData            : {},
	namesData           : [],
	similars            : [],
	categoriesForSimilar: [],

	UI: {
		visible         : false,
		editMode        : false,
		gameCategoryID  : null,
		loading         : false,
		closeModal     	: false,
		isBaseChanged  	: false,
		isNamesChanged  : false,
		isSimilarChanged: false,
		isAliasChanged  : false,
		isSeoChanged   	: false,
		langID			: null,
		activeTab		: 'main',
	},
});

export default function casinoGameCategoryModalReducer(state = initState, action) {

	switch (action.type) {

	case actions.CASINO_GAME_CATEGORY_BASE_DATA_REFRESH: {
		const target = state.get('baseData');
		const result = fill(action.data, target, true);
		return state.set('baseData', result);
	}

	case actions.CASINO_GAME_CATEGORY_NAMES_DATA_REFRESH: {
		return state.set('namesData', action.data);
	}

	case actions.CASINO_GAME_CATEGORY_SIMILARS_LIST_REFRESH: {
		return state.set('similars', action.data);
	}

	case actions.CASINO_CATEGORY_LIST_FOR_SIMILAR_REFRASH: {
		return state.set('categoriesForSimilar', action.data);
	}

	case actions.CASINO_GAME_CATEGORY_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.CASINO_GAME_CATEGORY_DATA_RESET: {
		return initState;
	}

	default:
		return state;
	}
}
