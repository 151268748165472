import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import groupsActions from '../groups/actions';

import { bannersAPI } from '../../../../helpers/api/banners';
import notifications from '../../../../helpers/notifications';

import { adaptBannerGroup, prepareBannerGroup } from './utils';

const prefix = 'banners.bannerGroup';

const messages = {
	errorBaseDataReload: `${prefix}.loading.data.failed`,
	errorBannerSave    : `${prefix}.saving.data.failed`,
	successBannerSave  : `${prefix}.saved.success`,
};

function getStoreData({ CMS, App }) {

	return {
		baseData : CMS.Banners.BannerGroup.get('baseData'),
		UI       : CMS.Banners.BannerGroup.get('UI'),
		websiteID: App.get('websiteID'),
	};
}

function* baseDataReload() {

	yield takeEvery(actions.BANNER_GROUP_BASE_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { UI } = yield select(getStoreData);
		const { groupID } = action.data;
		const { editMode } = UI;

		let baseData = adaptBannerGroup({});
		if (!editMode) {

			yield put(actions.baseDataRefresh(baseData));
			yield put(actions.uiRefresh({
				loading  : false,
				isChanged: false,
			}));

			return;
		}

		// loading data
		try {
			const res = yield call(bannersAPI.bannerGroupInfo, groupID);
			if (res && res.status === 200) {
				baseData = adaptBannerGroup(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorBaseDataReload);
			console.log(error);
		}

		yield put(actions.baseDataRefresh(baseData));
		yield put(actions.uiRefresh({
			loading  : false,
			isChanged: false,
		}));
	});
}

function* dataSave() {

	yield takeEvery(actions.BANNER_GROUP_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { baseData, UI, websiteID } = yield select(getStoreData);
		const { closeModal, editMode } = UI;
		let { groupID } = UI;
		let isError = false;
		baseData.websiteID = Number(websiteID);

		try {
			// base data
			const preparedData = prepareBannerGroup(baseData);
			if (editMode) {
				yield call(bannersAPI.bannerGroupUpdate, groupID, preparedData);

			} else {
				const res = yield call(bannersAPI.bannerGroupAdd, preparedData);
				groupID = toInteger(res.data.data.id);
				yield put(actions.uiRefresh({ editMode: true, groupID }));
			}

			yield put(actions.uiRefresh({ isChanged: false }));
			notifications.showSuccess(messages.successBannerSave);

		} catch (error) {
			isError = true;
			notifications.showError(messages.errorBannerSave);
		}

		if (!isError && !closeModal) {
			yield put(actions.baseDataReload(groupID, true));
		}

		yield put(groupsActions.listReload());
		yield put(
			actions.uiRefresh({
				loading: false,
				visible: !(closeModal && !isError),
			})
		);
	});
}

export default function* bannerGroupModalSaga() {
	yield all([
		fork(baseDataReload),
		fork(dataSave),
	]);
}
