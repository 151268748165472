import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import { categoriesAPI } from '../../../../helpers/api/categories';
import { eventsAPI } from '../../../../helpers/api/events';
import notifications from '../../../../helpers/notifications';
import { DISPLAY_STATUS } from '../../../../helpers/commonConstants';
import { isID } from '../../../../helpers/utils';
import { prepareListParams } from '../utils';

const prefix = 'sport.events.filter';

const messages = {
	errorLoadCountries : `${prefix}.errorLoadCountries`,
	errorLoadLeagueList: `${prefix}.errorLoadLeagueList`,
	errorLoadNameList  : `${prefix}.errorLoadNameList`,
};
function* countryListReload() {
	const takeAction = actions.EVENTS_FILTER_COUNTRY_LIST_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		const { sportID } = action.data;
		if (!isID(sportID)) {
			yield put(actions.countryListRefresh([]));
			return;
		}

		const params = {
			parent_id        : sportID,
			display_status_id: DISPLAY_STATUS.visible,
		};
		try {
			const response = yield call(categoriesAPI.getCategories, params);
			if (response && response.status === 200) {
				const list = response.data.data;
				yield put(actions.countryListRefresh(list));
			}
		} catch (error) {
			notifications.showError(messages.errorLoadCountries, error);
			console.log(error);
		}
	});
}

function* leagueListReload() {
	const takeAction = actions.EVENTS_FILTER_LEAGUE_LIST_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		const { countryID } = action.data;
		if (!isID(countryID)) {
			yield put(actions.leagueListRefresh([]));
			return;
		}

		const params = {
			display_status_id: DISPLAY_STATUS.visible,
		};
		try {
			const response = yield call(categoriesAPI.getCategoriesOfSport, countryID, params);
			if (response && response.status === 200) {
				const list = response.data.data;
				yield put(actions.leagueListRefresh(list));
			}
		} catch (error) {
			notifications.showError(messages.errorLoadLeagueList, error);
			console.log(error);
		}
	});
}

function* nameListReload() {
	const takeAction = actions.EVENTS_FILTER_NAME_LIST_RELOAD;
	yield takeEvery(takeAction, function* (action) {
		const filter = yield select(({ Sport: { Events } }) => Events.Filter.get('filter'));
		const params = prepareListParams(filter, {});
		params.name = action.data.value;

		try {
			const response = yield call(eventsAPI.eventsNamesAutocomplete, params);
			if (response && response.status === 200) {
				const list = response.data.data;
				yield put(actions.nameListRefresh(list));
			}
		} catch (error) {
			notifications.showError(messages.errorLoadNameList, error);
			console.log(error);
		}
	});
}

export default function* eventsFilterSaga() {
	yield all([
		fork(countryListReload),
		fork(leagueListReload),
		fork(nameListReload),
	]);
}
