import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import listActions from '../list/actions';
import appTabsActions from '../../../../appTabs/actions';

import { newsAPI } from '../../../../../helpers/api/news';
import notifications from '../../../../../helpers/notifications';
import { isID } from '../../../../../helpers/utils';

import { adaptNewsArticle, prepareNewsArticle } from './utils';

const prefix = 'news.article';

const messages = {
	errorItemReload   : `${prefix}.errorItemReload`,
	errorItemSave     : `${prefix}.errorItemSave`,
	errorDetailsSave  : `${prefix}.errorDetailsSave`,
	errorUpload       : `${prefix}.errorUpload`,
	successItemSave   : `${prefix}.successItemSave`,
	successDetailsSave: `${prefix}.successDetailsSave`,
	successUpload     : `${prefix}.successUpload`,
};
function getStoreData({ CMS, App }) {

	return {
		listIDs  : CMS.Pages.News.List.get('listIDs'),
		entities : CMS.Pages.News.List.get('entities'),
		baseData : CMS.Pages.News.Article.get('baseData'),
		UI       : CMS.Pages.News.Article.get('UI'),
		websiteID: App.get('websiteID'),
	};
}

function* baseDataReload() {

	yield takeEvery(actions.NEWS_ITEM_BASE_DATA_RELOAD, function* (action) {

		const storeData = yield select(getStoreData);
		const { UI } = storeData;
		const { itemID } = action.data;
		const params = { lang_id: UI.langID };

		const listIDs = cloneDeep(storeData.listIDs);
		const entities = cloneDeep(storeData.entities);
		let baseData = cloneDeep(storeData.baseData);
		try {
			const res = yield call(newsAPI.newsItemInfo, itemID, params);
			if (res && res.status === 200) {
				baseData = adaptNewsArticle(res.data.data);
				entities[itemID] = baseData;
			}
		} catch (error) {
			notifications.showError(messages.errorItemReload, error);
			console.log(error);
		}

		yield put(actions.baseDataRefresh(baseData));
		yield put(actions.uiRefresh({ isChanged: false }));
		yield put(listActions.listRefresh(listIDs, entities));
	});
}

function* itemSave() {

	yield takeEvery(actions.NEWS_ITEM_SAVE, function* (action) {

		let errorMessage = '';

		const { baseData, UI, websiteID } = yield select(getStoreData);
		const params = { website_id: websiteID };
		yield put(actions.uiRefresh({ loading: true }));

		const { editMode, itemName, langID } = UI;
		let { itemID } = action.data;
		let isError = false;

		const preparedData = prepareNewsArticle(baseData, itemName, langID);
		const { mainData, detailData } = preparedData;

		try {
			// base
			errorMessage = messages.errorItemSave;
			if (isID(itemID)) {
				yield call(newsAPI.newsItemUpdate, itemID, mainData);

			} else {
				const res = yield call(newsAPI.newsItemAdd, mainData, params);
				itemID = toInteger(res.data.data.id);
			}

			// details
			errorMessage = messages.errorDetailsSave;
			yield call(newsAPI.newsItemDetailsUpdate, itemID, detailData);

			yield put(actions.uiRefresh({ isChanged: false }));
			notifications.showSuccess(messages.successItemSave);

		} catch (error) {
			isError = true;
			notifications.showError(errorMessage, error);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
		if (!editMode && !isError) {
			yield put(appTabsActions.openTabNewsArticlePage(itemID));
		}
	});
}

export default function* newsArticleSaga() {
	yield all([
		fork(itemSave),
		fork(baseDataReload),
	]);
}
