import { v4 as uuid } from 'uuid';
import sortBy from 'lodash/sortBy';
import toInteger from 'lodash/toInteger';
import take from 'lodash/take';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { CASINO_GAMES_GRID_MODE, CASINO_GAMES_WEIGHT, CASINO_GAMES_CATEGORY, CHANNEL_TYPES } from '../../../../helpers/commonConstants';

export const fields = {
	casinoGameID: 'casino_game_id',
	websiteID   : 'website_id',
	channelID   : 'channel_id',
	posX        : 'position_x',
	posY        : 'position_y',
	weight      : 'weight',
	page        : 'page',
};

export const defaultSizes = {
	[CHANNEL_TYPES.web]    : { x: 5, y: 5, max: 36 }, // 6 x 6
	[CHANNEL_TYPES.mobile] : { x: 1, y: 4, max: 10 }, // 2 x 5
	[CHANNEL_TYPES.tablet] : { x: 5, y: 5, max: 36 }, // 6 x 6
	[CHANNEL_TYPES.desktop]: { x: 5, y: 5, max: 36 }, // 6 x 6
	[CHANNEL_TYPES.backend]: { x: 5, y: 5, max: 36 }, // 6 x 6
};

const gridAdapter = createGridAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptDefaultGrid(rawData = []) {

	gridAdapter.clearExcludes();
	const adaptedData = sortBy(gridAdapter.adaptList(rawData), ['posY', 'posX']);

	const maxCells = defaultSizes[CHANNEL_TYPES.backend].max;
	const maxData = take(adaptedData, maxCells);
	return maxData;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareGridLayouts(gridLayouts, changedLayouts, websiteID, channelID) {

	const results = [];
	changedLayouts.forEach(categoryID => {

		const gridLayout = gridLayouts[categoryID];
		const preparedLayout = (categoryID === CASINO_GAMES_CATEGORY.all)
			? prepareDefaultGridLayout(gridLayout, websiteID, channelID)
			: prepareCategoryGridLayout(gridLayout, websiteID, channelID);

		results.push(preparedLayout);
	});

	return results;
}

function prepareDefaultGridLayout(layout, websiteID, channelID) {

	gridAdapter.clearExcludes();

	const grid = layout.map(layoutItem => {
		const { x, y } = layoutItem;
		return {
			websiteID,
			channelID,
			casinoGameID: -1,
			posX        : x,
			posY        : y,
			weight      : getWeight(layoutItem),
			page        : 1,
		};
	});

	const sortedGrid = sortBy(grid, ['posY', 'posX']);
	const result = gridAdapter.prepareList(sortedGrid);

	return result;
}

function prepareCategoryGridLayout(layout, websiteID, channelID) {

	const grid = layout.map(layoutItem => {
		const { x, y, i } = layoutItem;
		return {
			websiteID,
			channelID,
			casinoGameID: toInteger(i),
			posX        : x,
			posY        : y,
			weight      : getWeight(layoutItem),
		};
	});

	const sortedGrid = sortBy(grid, ['posY', 'posX']);
	const sizes      = defaultSizes[channelID];
	const maxY       = sizes.y;
	const perPage    = maxY + 1;

	sortedGrid.forEach(item => {
		item.page = toInteger(item.posY / perPage) + 1;
	});

	gridAdapter.clearExcludes();
	const result = gridAdapter.prepareList(sortedGrid);

	return result;
}

// Adapters ---------------------------------------------------------------------------------------

export function createGridAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'casinoGameID', fields.casinoGameID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'channelID', fields.channelID);

	adapter.addField(rules.positiveNumber, 'posX', fields.posX);
	adapter.addField(rules.positiveNumber, 'posY', fields.posY);
	adapter.addField(rules.positiveNumber, 'weight', fields.weight);
	adapter.addField(rules.positiveNumber, 'page', fields.page);

	return adapter;
}

// Service ----------------------------------------------------------------------------------------

export function validateDefaultGrid(defaultGrid = [], channelID, resultType = 'both') {

	const sourceGrid = (!isArray(defaultGrid) || isEmpty(defaultGrid))
		? createDefaultGrid(channelID)
		: defaultGrid;

	const gridLayout    = createDefaultGridLayout(sourceGrid, channelID);
	const correctLayout = validateDefaultGridLayout(gridLayout, channelID);
	const resultGrid    = deriveDefaultGridFromLayout(correctLayout);

	if (resultType === 'both') {
		return {
			defaultGrid      : resultGrid,
			defaultGridLayout: correctLayout,
		};
	}

	return defaultGrid;
}

export function validateDefaultGridLayout(layout, channelID) {

	const matrix = createMatrix(channelID);
	const sizes  = defaultSizes[channelID];
	const maxX   = sizes.x;
	const maxY   = sizes.y;

	const resLayout = [];
	layout.forEach(gridItem => {
		const { x, y, w, h, i } = gridItem;
		if (y > maxY || x > maxX) {
			return;
		}

		if (y === maxY && h > 1) {
			gridItem.w = 2;
			gridItem.h = 1;
		}
		if (x === maxX && w > 1) {
			gridItem.w = 1;
		}

		matrix[y][x] = i;
		if (gridItem.w === 2) {
			matrix[y][x + 1] = i;
		}
		if (gridItem.h === 2) {
			matrix[y + 1][x] = i;
			matrix[y + 1][x + 1] = i;
		}

		resLayout.push(gridItem);
	});

	for (let i = 0; i < matrix.length; i++) {
		const row = matrix[i];
		for (let j = 0; j < row.length; j++) {
			if (!row[j]) {
				resLayout.push({
					x: j,
					y: i,
					w: 1,
					h: 1,
					i: String(uuid()),
				});
			}
		}
	}

	return resLayout;
}

export function deriveDefaultGridFromLayout(layout) {

	const result = layout.map(layoutItem => {
		const { x, y } = layoutItem;
		return {
			posX  : x,
			posY  : y,
			weight: getWeight(layoutItem),
		};
	});

	return result;
}

// Check
export function fillDefaultGridLayout(defaultGrid, channelID) {

	const sizes    = defaultSizes[channelID];
	const maxX     = sizes.x;
	const maxY     = sizes.y;
	const result   = [];

	/*
    0: [ 0, 1, 2, 3, 4, 5 ]
    1: [ 0, 1, 2, 3, 4, 5 ]
    2: [ 0, 1, 2, 3, 4, 5 ]
    3: [ 0, 1, 2, 3, 4, 5 ]
    4: [ 0, 1, 2, 3, 4, 5 ]
    5: [ 0, 1, 2, 3, 4, 5 ]
  */
	const matrix = createMatrix(channelID);
	for (let i = 0; i < defaultGrid.length; i++) {
		const isFilled = isMatrixFilled(matrix);
		if (isFilled) {
			break;
		}

		const item = defaultGrid[i];
		const { posX, posY, weight } = item;
		let realWeight = weight;
		if (posY === maxY && realWeight > CASINO_GAMES_WEIGHT.medium) {
			realWeight = CASINO_GAMES_WEIGHT.medium;
		}
		if (posX === maxX && realWeight > CASINO_GAMES_WEIGHT.small) {
			realWeight = CASINO_GAMES_WEIGHT.small;
		}

		result.push({
			posX,
			posY,
			weight: realWeight,
		});

		matrix[posY][posX] = true;
		if (realWeight > CASINO_GAMES_WEIGHT.small) {
			matrix[posY][posX + 1] = true;
		}
		if (realWeight > CASINO_GAMES_WEIGHT.medium) {
			matrix[posY + 1][posX] = true;
			matrix[posY + 1][posX + 1] = true;
		}
	}

	for (let i = 0; i < matrix.length; i++) {
		const row = matrix[i];
		for (let j = 0; j < row.length; j++) {
			if (!row[j]) {
				result.push({
					posX  : j,
					posY  : i,
					weight: 1,
				});
			}
		}
	}

	return sortBy(result, ['posY', 'posX']);
}

// Check
export function createGridLayouts(gamesList, categoriesList, defaultGrid, channelID) {

	const grids = {};

	categoriesList.forEach(category => {

		const categoryID = category.id;
		let grid         = [];

		if (categoryID === CASINO_GAMES_CATEGORY.all) {
			grid = createGamesGridLayout(gamesList, CASINO_GAMES_GRID_MODE.byDefault, defaultGrid, channelID);

		} else {
			const filteredGamesList = gamesList.filter( game => game.categoryID === categoryID);
			grid = createGamesGridLayout(filteredGamesList, CASINO_GAMES_GRID_MODE.byGames, [], channelID);
		}

		grids[categoryID] = grid;
	});

	return grids;
}

export function createGamesGridLayout(gamesList) {

	const sourceGamesList = sortBy(gamesList, ['posY', 'posX']);

	const result = sourceGamesList.map( gameItem => {

		return {
			i: String(gameItem.id),
			x: gameItem.posX,
			y: gameItem.posY,
			w: getW(gameItem.weight),
			h: getH(gameItem.weight),
		};
	});

	return result;
}

// Check
export function createMobileGamesGridLayout(gridLayout) {

	const sortedLayout = sortBy(gridLayout, ['y', 'x']);
	const result = [];

	let x = 0;
	let y = 0;

	sortedLayout.forEach( layoutItem => {

		result.push({
			...layoutItem,
			x,
			y,
		});
		if (layoutItem.w === 2) { x += 1; }
		if (layoutItem.h === 2) { y += 1; }

		x += 1;
		if (x > 1) {
			x = 0;
			y += 1;
		}
	});

	return result;
}

export function createDefaultGrid(channelID) {

	const grid = [];
	const size = defaultSizes[channelID];
	const maxX = size.x;
	const maxY = size.y;

	for (let i = 0; i <= maxY; i++) {
		for (let j = 0; j <= maxX; j++) {
			grid.push({
				posX  : j,
				posY  : i,
				weight: CASINO_GAMES_WEIGHT.small,
			});
		}
	}

	return grid;
}

export function createDefaultGridLayout(defaultGrid) {

	const result = [];
	defaultGrid.forEach(gridItem => {

		result.push({
			i: String(uuid()),
			x: gridItem.posX,
			y: gridItem.posY,
			w: getW(gridItem.weight),
			h: getH(gridItem.weight),
		});
	});

	return result;
}

export function getW(weight) {
	return (weight === CASINO_GAMES_WEIGHT.small) ? 1 : 2;
}

export function getH(weight) {
	return (weight === CASINO_GAMES_WEIGHT.big) ? 2 : 1;
}

export function getWeight(gridItem = null) {
	if (!gridItem) {
		return CASINO_GAMES_WEIGHT.small;
	}

	if (gridItem.w === 2 && gridItem.h === 2) {
		return CASINO_GAMES_WEIGHT.big;

	} if (gridItem.w === 2 && gridItem.h === 1) {
		return CASINO_GAMES_WEIGHT.medium;

	}

	return CASINO_GAMES_WEIGHT.small;
}

// Others -----------------------------------------------------------------------------------------

function createMatrix(channelID) {
	const sizes    = defaultSizes[channelID];
	const maxX     = sizes.x;
	const maxY     = sizes.y;

	const matrix = new Array(maxY + 1).fill(false).map(() => new Array(maxX + 1).fill(false));

	return matrix;
}

function isMatrixFilled(matrix) {

	for (let i = 0; i < matrix.length; i++) {
		const row = matrix[i];
		for (let j = 0; j < row.length; j++) {
			if (!row[j]) {
				return false;
			}
		}
	}

	return true;
}
