import { apiRequest } from './index';

function activeLimitations(data = {}, params = {}) {

	const req = {
		method: 'GET',
		url   : '/responsible_gambling', // TODO change this after BE implementation
		data,
		params,
	};

	return apiRequest(req);
}

function removeLimitations(limitationID, data = {}, params = {}) {

	const req = {
		method: 'POST',
		url   : `/responsible_gambling/remove/${limitationID}`, // TODO change this after BE implementation
		data,
		params,
	};

	return apiRequest(req);
}

function settings(websiteID, params = {}) {
	const req = {
		method: 'get',
		url   : `/responsible_gambling/settings/${websiteID}`, // TODO change this after BE implementation
		params,
	};
	return apiRequest(req);
}

function saveSettings(websiteID, data = {}, params = {}) {
	const req = {
		method: 'post',
		url   : `/responsible_gambling/settings/${websiteID}`, // TODO change this after BE implementation
		data,
		params,
	};
	return apiRequest(req);
}


export const responsibleGamblingAPI = {
	activeLimitations,
	removeLimitations,
	settings,
	saveSettings,
};
