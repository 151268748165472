import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import actions from './actions';

import { uploadsAPI } from '../../../helpers/api/uploads';
import { showError, showSuccess, showWarning } from '../../../helpers/notifications';

const prefix = 'images.single';

const messages = {
	errorUpload  : `${prefix}.errorUpload`,
	successUpload: `${prefix}.successUpload`,
	warningNoFile: `${prefix}.warningNoFile`,
};


function getStoreData({ App }) {

	return {
		websiteID: App.get('websiteID'),
	};
}
function* uploadFile() {

	yield takeEvery(actions.IMAGES_SINGLE_UPLOAD_FILE, function* (action) {

		const { id, file, cbSuccess, cbError, oldFileLink, onProgress, minImageHeight, minImageWidth, profileAvatar } = action.data;
		const { websiteID } = yield select(getStoreData);

		const websiteParams = profileAvatar ? {} : {  website_id: websiteID };
		if (!file) {
			showWarning(messages.warningNoFile);
			if (cbError) {
				cbError(new Error(messages.warningNoFile));
			}
			return;
		}

		yield put(actions.uiRefresh(id, { loading: true }));
		try {
			const params = { min_height: minImageHeight, min_width: minImageWidth };
			let res;
			if (minImageHeight && minImageWidth) {
				res = yield call(uploadsAPI.uploadSingleFile, file, onProgress, { ...params, ...websiteParams });
			} else {
				res = yield call(uploadsAPI.uploadSingleFile, file, onProgress, websiteParams);
			}
			if (res && res.status === 200) {
				const filesList = res.data.data;
				if (isArray(filesList) && !isEmpty(filesList)) {

					const fileLink = filesList[0];

					yield put(actions.init(id, fileLink));
					yield put(actions.uploadFileSuccess(id, fileLink));

					showSuccess(messages.successUpload);

					if (cbSuccess) {
						cbSuccess(fileLink);
					}

				} else {
					yield put(actions.init());
					yield put(actions.uploadFileSuccess(id, ''));
				}
			} else {
				yield put(actions.init());
				yield put(actions.uploadFileSuccess(id, ''));
			}

			// delete old file from remote server
			if (oldFileLink) {
				try {
					// yield call(uploadsAPI.unloadSingleFile, oldFileLink);
				} catch (error) {
					console.log(error);
				}
			}

		} catch (error) {

			yield put(actions.uploadFileError(id));

			showError(messages.errorUpload, error);
			console.log(error);

			if (cbError) {
				cbError(error);
			}
		}

		yield put(actions.uiRefresh(id, { loading: false }));
	});
}

export default function* imageSingleSaga() {
	yield all([
		fork(uploadFile),
	]);
}
