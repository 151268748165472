import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import { actions } from './actions';
import { showError, showSuccess } from '../../../../../../helpers/notifications';
import { usersAPI } from '../../../../../../helpers/api/users';
import { adaptTags } from './utils';
import { casinoAPI } from '../../../../../../helpers/api/casino';
import {  prepareTagsData } from '../customProvider/utils';
import { LIMITS_CASINO_OPTIONS_TYPES } from '../../../../../../constants/users';

const prefix = 'users.limits.tags';

const messages = {
	listReload    : `${prefix}.listReload`,
	dataSaveFailed: `${prefix}.dataSaveFailed`,
	dataSaved     : `${prefix}.dataSaved`,
};


const getStore = ({ Users }) => {
	const { associativeList, list } = Users.UserInfo.UserLimitsAndRestrictions.Casino.Tags.get('baseData');
	return {
		associativeList,
		list,
	};
};

function* tagsListReload() {
	yield takeEvery(actions.RESTRICT_BY_TAGS_RELOAD, function* ({ data }) {
		yield put(actions.uiRefresh({ loading: true }));

		try {
			const res = yield all([
				call(casinoAPI.casinoGamesbyTags),
				call(usersAPI.userLimitsCasinoSelect, data),
			]);

			const successStatus = res.every(item => item && item.status === 200);

			const [tagsResponse, tagsLimitsResponse] = res;

			if (successStatus) {
				const {
					list,
					associativeList,
				} = adaptTags(tagsResponse.data.data, tagsLimitsResponse.data.data, data);

				yield put(actions.tagsListRefresh({ list, associativeList }));
			}
			yield put(actions.uiRefresh({ loading: false }));

		} catch (e) {
			showError(messages.listReload, e);
			yield put(actions.uiRefresh({ loading: false }));
			showError(messages.listReload);
			console.log(e);
		}
	});
}


function* dataSave() {
	yield takeEvery(actions.RESTRICT_BY_TAGS_SAVE, function* ({ data }) {
		const typeID                    = LIMITS_CASINO_OPTIONS_TYPES.tag;
		data.typeID                     = typeID;
		const { tagID, userID }    = data;
		const { associativeList, list } = yield select(getStore);
		const row                       = associativeList[tagID];

		const clonedData = cloneDeep(list);
		clonedData.forEach(item => {
			if (item.tagID === tagID) {
				item.loading = true;
			}
		});

		yield put(actions.tagsListRefresh({ list: clonedData }));
		const preparedData = prepareTagsData(row);

		try {

			const res = yield call(usersAPI.userLimitsCasinoUpdate, preparedData, { userID, type: typeID });

			if (res && res.status === 200) {
				showSuccess(messages.dataSaved);
			}

			yield put(actions.uiRefresh({ loading: false }));

		} catch (e) {
			showError(messages.dataSaveFailed);
			yield put(actions.uiRefresh({ loading: false }));
			console.log(e);
		}

		yield put(actions.dataReload(data));

	});
}


export default function* restrictByTagsSaga() {
	yield all([
		fork(tagsListReload),
		fork(dataSave),
	]);
}
