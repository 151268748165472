import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import { showError, showSuccess } from '../../../helpers/notifications';
import { usersAPI } from '../../../helpers/api/users';
import { getHeadersTotalCount } from '../../../helpers/utils';

import actions from './actions';
import tableActions from '../../tables/actions';
import { deriveTablePagination } from '../../../selectors/tables';
import { getListParams, adaptAdminList } from './utils';
import { TABLE_TYPES } from '../../../constants/tableTypes';

const prefix = 'staff.list';

const messages = {
	errorListLoad      : `${prefix}.errorListLoad`,
	errorStatusUpdate  : `${prefix}.errorStatusUpdate`,
	successStatusUpdate: `${prefix}.successStatusUpdate`,
};


const tableType     = TABLE_TYPES.personal;

function getStoreData(state) {
	const { Staff, Tables } = state;
	const { Filter } = Staff;

	return {
		filter    : Filter.get('filter'),
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
	};
}

function* listReload() {

	yield takeEvery(actions.LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));
		let list = [];
		let totalCount = 0;

		const { filter, pagination, sorting } = yield select(getStoreData);
		const params = getListParams(filter, pagination, sorting);
		try {
			const res = yield call(usersAPI.adminsList, params);
			if (res && res.status === 200) {

				list = adaptAdminList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);

				yield put(actions.listRefresh(list));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			showError(messages.errorListLoad);
			console.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* statusUpdate() {

	yield takeEvery(actions.ITEM_STATUS_UPDATE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { userID, statusID } = action.data;
		const data = {
			id       : userID,
			status_id: statusID,
		};
		try {
			const res = yield call(usersAPI.updateAdminData, userID, data);
			if (res && res.status === 200) {
				showSuccess(messages.successStatusUpdate);
				yield put(actions.listReload());
			}

		} catch (error) {
			showError(messages.errorStatusUpdate);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* personalListSaga() {
	yield all([
		fork(listReload),
		fork(statusUpdate),
	]);
}
