import React from 'react';
import PropTypes from 'prop-types';
import Intl from '../../utility/intlMessages';
import { NOTIFICATION_TYPES } from '../../../constants/notifications';
import './DropdownContent/index.css';
import {
	ItemBets,
	ItemCasino,
	ItemDeposits,
	ItemDocuments,
	ItemMessages,
	ItemWithdrawal,
	ItemRiskManagement,
} from './components';
import { RISK_MANAGEMENT_PRIORITY_TYPES } from '../../../helpers/commonConstants';
import { BetsIcon } from './icons/bets';
import { DocumnetsIcon } from './icons/documnets';
import { CasinoIcon } from './icons/casino';
import { DepositsIcon } from './icons/deposits';
import { WithdrawalsIcon } from './icons/withdrawals';
import { MessagesIcon } from './icons/messages';
import { RiskIcon } from './icons/risk';

export const defaultPropsIcons = {
	color  : '#000',
	width  : '18',
	height : '18',
	viewBox: '0 0 18 18',
};

export const propTypesIcons = {
	color  : PropTypes.string,
	width  : PropTypes.string,
	height : PropTypes.string,
	viewBox: PropTypes.string,
};

export const lang = {
	viewAll  	: <Intl id="common.viewAll" />,
	clear   		: <Intl id="common.clear" />,
	markAsRead: <Intl id="common.notifications.markAsRead" />,
	noData   	: <Intl id="common.noData" />,
	high    		: <Intl id="notification.risk.high" />,
	medium  		: <Intl id="notification.risk.medium" />,
	low    			: <Intl id="notification.risk.low" />,
};
export const titleIDs = {
	[NOTIFICATION_TYPES.bets]         	: 'notifications.rtmBets',
	[NOTIFICATION_TYPES.casino]       	: 'notifications.rtmCasino',
	[NOTIFICATION_TYPES.deposits]     	: 'notifications.rtmDeposits',
	[NOTIFICATION_TYPES.withdrawal]   	: 'notifications.rtmWithdrawals',
	[NOTIFICATION_TYPES.documents]    	: 'notifications.userDocuments',
	[NOTIFICATION_TYPES.messages]     	: 'notifications.messages',
	[NOTIFICATION_TYPES.riskManagement]: 'notifications.riskManagement',

};

export const icons = {
	[NOTIFICATION_TYPES.bets]         	: <BetsIcon />,
	[NOTIFICATION_TYPES.casino]       	: <CasinoIcon />,
	[NOTIFICATION_TYPES.deposits]     	: <DepositsIcon />,
	[NOTIFICATION_TYPES.withdrawal]   	: <WithdrawalsIcon />,
	[NOTIFICATION_TYPES.documents]    	: <DocumnetsIcon />,
	[NOTIFICATION_TYPES.messages]     	: <MessagesIcon />,
	[NOTIFICATION_TYPES.riskManagement]: <RiskIcon />,

};

export const itemComponents = {
	[NOTIFICATION_TYPES.bets]          	: ItemBets,
	[NOTIFICATION_TYPES.casino]        	: ItemCasino,
	[NOTIFICATION_TYPES.deposits]      	: ItemDeposits,
	[NOTIFICATION_TYPES.withdrawal]    	: ItemWithdrawal,
	[NOTIFICATION_TYPES.documents]     	: ItemDocuments,
	[NOTIFICATION_TYPES.messages]      	: ItemMessages,
	[NOTIFICATION_TYPES.riskManagement]	: ItemRiskManagement,
	[NOTIFICATION_TYPES.riskAlertLow]  	: ItemRiskManagement,
	[NOTIFICATION_TYPES.riskAlertMedium]: ItemRiskManagement,
	[NOTIFICATION_TYPES.riskAlertHigh] 	: ItemRiskManagement,
};

export const hints = {
	[NOTIFICATION_TYPES.bets]        		: 'New Bets',
	[NOTIFICATION_TYPES.casino]      		: 'New Casino Transactions',
	[NOTIFICATION_TYPES.deposits]    		: 'New Deposits',
	[NOTIFICATION_TYPES.withdrawal]  		: 'New Withdrawals',
	[NOTIFICATION_TYPES.documents]   		: 'New User Documents',
	[NOTIFICATION_TYPES.messages]    		: 'New Messages',
	[NOTIFICATION_TYPES.riskManagement]: 'New Risk',
};

export const borderClass = {
	3: 'high',
	2: 'medium',
	1: 'low',
};

export const detectTypeId = (typeID, priority) => {
	const { riskManagement, riskAlertLow, riskAlertMedium, riskAlertHigh } = NOTIFICATION_TYPES;
	const { high, medium, low } = RISK_MANAGEMENT_PRIORITY_TYPES;
	let notifTypeID;
	if (typeID === riskManagement) {
		switch (priority) {
		case low:
			notifTypeID = riskAlertLow;
			break;
		case medium:
			notifTypeID = riskAlertMedium;
			break;
		case high:
			notifTypeID = riskAlertHigh;
			break;
		default:
		}
	} else {
		notifTypeID = typeID;
	}
	return notifTypeID;
};
export const riskIconStyle = {
	verticalAlign: 'middle',
	marginTop    : '-4px',
	color        : '#323332',
};
export const riskColors = {
	l: '#4e4e4e',
	m: '#ebb000dc',
	h: '#b91a1a',
};

export const createContainerID = (containerID, version) => {
	if (version) {
		return containerID + Math.random();
	}
	return containerID;
};

export const TabNameWithCount = ({ color, name, count }) => {
	const showCount     = (count > 0);
	const countRender   = (count < 100) ? count : '99+';
	return (
		<div style={{ display: 'flex' }}>
			<span style={{ color, width: '80px', textAlign: 'center' }}>{name}</span>
			{showCount && <span style={{ background: color }} className="riskAlertsCount"> {countRender} </span>}
		</div>
	);
};
TabNameWithCount.propTypes = {
	count: PropTypes.number.isRequired,
	name	: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	color: PropTypes.string.isRequired,
};
TabNameWithCount.defaultProps = {
	name: null,
};
