import { FuseResult } from 'fuse.js';

export const fuseOptions = {
	fieldNormWeight: 1,
	keys: [
		'name',
	],
};
function madeOptions(list: Array<FuseResult<string>>) {
	if (!list && !list.length) {
		return [
			{
				label: '',
				value: '',
			},
		];
	}

	return list.reduce((acc: any, item: any) => {
		acc.push({
			label: item.item.name,
			value: item.item.name,
		});
		return acc;
	}, []);
}

export {
	madeOptions,
}
