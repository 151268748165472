import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	balanceData: {
		amount          : '',
		comment         : '',
		userComment     : '',
		wageringTurnover: '',
		daysToExpire    : '',
		forced          : '',
		logoURL         : '',
		titles          : [{
			title      : '',
			description: '',
		}],
	},

	UI: {
		userID    : null,
		visible   : false,
		loading   : false,
		closeModal: false,
		isChanged : false,
	},
});

export default function userBalanceReducer(state = initState, action) {

	switch (action.type) {

	case actions.USER_BALANCE_DATA_RESET: {
		return initState;
	}

	case actions.USER_BALANCE_DATA_REFRESH: {
		const target = state.get('balanceData');
		const result = fill(action.data, target);
		return state.set('balanceData', result);
	}

	case actions.USER_BALANCE_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
