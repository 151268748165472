import { normalize, schema } from 'normalizr';
import isArray from 'lodash/isArray';

import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';
import { adaptHistoryList } from '../utils';

export const fields = {
	id                : 'id',
	name              : 'name',                 // string;
	statusID          : 'status_id',            // BonusStatus;
	amount            : 'amount',               // number;
	currencyID        : 'currency_id',          // number;
	startDate         : 'start_date',           // Date;
	endDate           : 'end_date',             // Date;
	websiteID         : 'website_id',           // number
	period            : 'period',               // number;
	minWageringOdd    : 'min_wagering_odd',     // number;
	expressWageringOdd: 'express_wagering_odd', // number;
	wageringTurnover  : 'wagering_turnover',    // number;
	maxDepositAmount  : 'max_deposit_amount',   // number;
	daysToExpire      : 'days_to_expire',       // number;
	//filter
	startDateFrom     : 'start_date_from',     // Date;
	startDateTo       : 'start_date_to',        // Date;
	endDateFrom       : 'end_date_from',        // Date;
	endDateTo         : 'end_date_to',          // Date;
	gameIDs           : 'game_ids',            // array;
	allow             : 'allow',               // bool;
	forced            : 'forced',               // bool;
	logoURL           : 'logo_url',             // bool;
	titles            : 'titles',             // bool;
	title             : 'title',             // bool;
	description       : 'description',             // bool;
	langID            : 'lang_id',             // bool;
	affiliateReference: 'affiliate_reference', // arrayString;
};

const listAdapter = createListAdapter();
const titlesAdapter = createTitleAdapter();

export function getListParams(filter, sorting, pagination) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'id', fields.id);
	builder.addField(rules.isID, 'statusID', fields.statusID);
	builder.addField(rules.isID, 'currencyID', fields.currencyID);

	builder.addField(rules.isPositiveNumber, 'amount', fields.amount);
	builder.addField(rules.isPositiveNumber, 'period', fields.period);
	builder.addField(rules.isPositiveNumber, 'minWageringOdd', fields.minWageringOdd);
	builder.addField(rules.isPositiveNumber, 'expressWageringOdd', fields.expressWageringOdd);
	builder.addField(rules.isPositiveNumber, 'wageringTurnover', fields.wageringTurnover);
	builder.addField(rules.isPositiveNumber, 'maxDepositAmount', fields.maxDepositAmount);
	builder.addField(rules.isBoolean, 'forced', fields.forced);
	builder.addField(rules.isString, 'logoURL', fields.logoURL);

	builder.addField(rules.isString, 'name', filter.startDate, fields.name);

	builder.addRangeField(rules.isDateTimeRange, filter.startDate, [
		fields.startDateFrom,
		fields.startDateTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.endDate, [
		fields.endDateFrom,
		fields.endDateTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------
export function adaptList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.titles = titlesAdapter.adaptList(item.titles);
	});
	const tempData = {
		items: adaptedData,
	};

	const item           = new schema.Entity('items', {}, { idAttribute: 'id' });
	const itemsSchema    = { items: [item] };
	const normalizedData = normalize(tempData, itemsSchema);
	const entities       = normalizedData.entities.items || {};

	return entities;
}

// Prepare ----------------------------------------------------------------------------------------
export function prepareBonusData(rawData = {}, editMode) {
	const prepareTitlesAdapter = createTitleAdapter(editMode);

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	const preparedData = listAdapter.prepare(rawData);
	preparedData.titles = prepareTitlesAdapter.prepareList(rawData.titles);

	if (isID(rawData.id)) {
		preparedData.id = rawData.id;
	}

	return preparedData;
}

//For history logs
export function adaptHistory(rawData) {

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id],
	};

	const dataList = adaptHistoryList(rawData, diffOptions);

	return dataList;
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);

	adapter.addField(rules.intOrNull, 'statusID', fields.statusID);
	adapter.addField(rules.intOrNull, 'currencyID', fields.currencyID);
	adapter.addField(rules.intOrNull, 'websiteID', fields.websiteID);

	adapter.addField(rules.string, 'name', fields.name);

	adapter.addField(rules.positiveNumber, 'amount', fields.amount);
	adapter.addField(rules.positiveNumber, 'period', fields.period);
	adapter.addField(rules.positiveNumber, 'minWageringOdd', fields.minWageringOdd);
	adapter.addField(rules.positiveNumber, 'expressWageringOdd', fields.expressWageringOdd);
	adapter.addField(rules.positiveNumber, 'wageringTurnover', fields.wageringTurnover);
	adapter.addField(rules.positiveNumber, 'maxDepositAmount', fields.maxDepositAmount);
	adapter.addField(rules.positiveNumber, 'daysToExpire', fields.daysToExpire);

	adapter.addField(rules.fullDate, 'startDate', fields.startDate);
	adapter.addField(rules.fullDate, 'endDate', fields.endDate);
	adapter.addField(rules.arrayNumber, 'gameIDs', fields.gameIDs);
	adapter.addField(rules.bool, 'allow', fields.allow);
	adapter.addField(rules.bool, 'forced', fields.forced);
	adapter.addField(rules.string, 'logoURL', fields.logoURL);
	adapter.addField(rules.arrayObject, 'titles', fields.titles);
	adapter.addField(rules.arrayString, 'affiliateReference', fields.affiliateReference);

	return adapter;
}

export function createTitleAdapter(editMode) {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'title', fields.title);
	adapter.addField(rules.string, 'description', fields.description);
	!editMode && adapter.addField(rules.number, 'langID', fields.langID);


	return adapter;
}
