import { combineReducers } from 'redux';

import UserLimitsAndRestrictions from './userLimitsAndRestrictions/reducer';
import userSMS from './sendSMS/reducer';
import ResponsibleGambling from './responsibleGambling/reducer';

export default combineReducers({
	UserLimitsAndRestrictions,
	userSMS,
	ResponsibleGambling,
});
