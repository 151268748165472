import { PERMISSIONS } from '../../../../../helpers/permissions/constants';
import { superAdminPermissions } from '../../../../../helpers/permissions/utils';

export const DATE_FORMATS = [
	{ id: 1, name: 'DD.MM.YYYY HH:mm:ss' },
	{ id: 2, name: 'MM/DD/YYYY hh:mm:ss A' },
	{ id: 3, name: 'YYYY-MM-DD HH:mm:ss' },
	{ id: 4, name: 'YYYY/MM/DD HH:mm:ss' },
	{ id: 5, name: 'HH:mm DD MMM YYYY' },
];

export const canReadTabs = (permissions, editMode) => {
	const superAdmin = permissions.super_admin;
	const showTabPayments       = editMode && (superAdmin || permissions[PERMISSIONS.accessWebsitePaymentsTab]?.read);
	const showTabProvider       = editMode && (superAdmin || permissions[PERMISSIONS.accessWebsiteProviderTab]?.read);
	const showTabCurrency       = editMode && (superAdmin || permissions[PERMISSIONS.accessWebsiteCurrencyTab]?.read);
	const showTabScripts        = editMode && (superAdmin || permissions[PERMISSIONS.accessWebsiteScriptsTab]?.read);
	const showTabAutoWithdraw   = editMode && (superAdmin || permissions[PERMISSIONS.accessWebsiteAutoWithdrawTab]?.read);
	const showTabSmsGateway     = editMode && (superAdmin || permissions[PERMISSIONS.accessWebsiteSmsGatewayTab]?.read);
	const showTabRiskManagement = editMode && (superAdmin || permissions[PERMISSIONS.accessWebsiteRiskManagementTab]?.read);
	const showTabCountry        = editMode && (superAdmin || permissions[PERMISSIONS.accessWebsiteCountryTab]?.read);
	const showTabLanguages      = editMode && (superAdmin || permissions[PERMISSIONS.accessWebsiteLanguagesTab]?.read);

	return {
		showTabs: {
			showTabPayments,
			showTabProvider,
			showTabCurrency,
			showTabScripts,
			showTabAutoWithdraw,
			showTabSmsGateway,
			showTabRiskManagement,
			showTabCountry,
			showTabLanguages,
		},
		tabsCRUD: {
			crudTabPayments      : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsitePaymentsTab]        || {},
			crudTabProvider      : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteProviderTab]        || {},
			crudTabCurrency      : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteCurrencyTab]        || {},
			crudTabScripts       : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteScriptsTab]         || {},
			crudTabAutoWithdraw  : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteAutoWithdrawTab]    || {},
			crudTabSmsGateway    : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteSmsGatewayTab]      || {},
			crudTabRiskManagement: superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteRiskManagementTab]  || {},
			crudTabCountry       : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteCountryTab]         || {},
			crudTabLanguages     : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteLanguagesTab]       || {},
		},
	};
};
