import { Map } from 'immutable';
import { actions } from './actions';
import { fill } from '../../../../../helpers/utils';
import { getClientCurrentTimeZoneCode } from '../../../../../helpers/timezone';

const initState = new Map({
	baseData: {
		email     : '',
		firstName : '',
		lastName  : '',
		status    : 1,
		roles     : [],
		dob       : null,
		// langID    : LANGUAGES.en,
		currencyID: 1,
		websiteID : 1,
		timezoneID: getClientCurrentTimeZoneCode(),
	},


	UI: {
		visible      : false,
		loading      : false,
		closeModal   : false,
		editMode     : false,
		langID       : null,
		isBaseChanged: false,
	},

});

export default (state = initState, { type, data }) => {
	switch (type) {
	case actions.OPERATOR_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(data, target, true);
		return state.set('UI', result);
	}
	case actions.OPERATOR_BASE_DATA_REFRESH: {
		const target = state.get('baseData');
		const result = fill(data, target, true);
		return state.set('baseData', result);
	}

	case actions.RESET_STORE: {
		return initState;
	}

	default: {
		return state;
	}
	}
};
