import isEmpty from 'lodash/isEmpty';
import EntityAdapter from '../../../helpers/entityAdapter';
import { CASINO_GAMES_WEIGHT } from '../../../helpers/commonConstants';
import ParamsBuilder from '../../../helpers/paramsBuilder';

export const fields = {
	id                 : 'id',
	casinoGameID       : 'casino_game_id',
	gameID             : 'game_id',
	websiteID          : 'website_id',
	categoryID         : 'category',
	name               : 'name',
	absoluteName       : 'absolute_name',
	imageLgURL         : 'image_lg_url',
	imageMdURL         : 'image_md_url',
	imageSmURL         : 'image_sm_url',
	thumbURL           : 'thumb_url',
	posX               : 'position_x',
	posY               : 'position_y',
	weight             : 'weight',
	playCount          : 'play_count',
	statusID           : 'status_id',
	isFreeSpins        : 'free_spins', // boolean;
	freeSpinsCount     : 'free_spins_count', // number[];
	isBonusSpins       : 'bonus_spins', // boolean;
	tags               : 'game_tags', // array;
	currencyIDs        : 'currency_ids', // number[];
	isChanged          : 'isChanged',
	providerID         : 'provider_id',
	customProviderIDs  : 'custom_provider_ids',
	restrictedCountries: 'restricted_countries',
	value              : 'name',
	alias              : 'alias',
};

const gamesListAdapter   = createGamesListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptGamesList(rawData = []) {

	gamesListAdapter.clearExcludes();
	const adaptedData = gamesListAdapter.adaptList(rawData);

	adaptedData.forEach(item => {
		if (!item.weight) {
			// eslint-disable-next-line no-param-reassign
			item.weight = CASINO_GAMES_WEIGHT.small;
		}
	});

	const entities = adaptedData.reduce((acc, game) => {
		acc[game.id] = game;

		return acc;
	}, {});

	return { entities, adaptedData };
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareGamesList(rawData = []) {
	const gamesList = rawData.filter(item => item.isChanged);
	if (isEmpty(gamesList)) {
		return [];
	}
	gamesListAdapter.clearExcludes();
	gamesListAdapter.addExcludeField('isChanged');
	gamesListAdapter.addExcludeField('weight');
	gamesListAdapter.addExcludeField('posX');
	gamesListAdapter.addExcludeField('posY');
	gamesListAdapter.addExcludeField('isFreeSpins');
	gamesListAdapter.addExcludeField('freeSpinsCount');
	gamesListAdapter.addExcludeField('isBonusSpins');
	// gamesListAdapter.addExcludeField('websiteID');

	const preparedData = gamesListAdapter.prepareList(gamesList);

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------

export function createGamesListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'gameID', fields.gameID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'categoryID', fields.categoryID);
	adapter.addField(rules.id, 'statusID', fields.statusID);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'absoluteName', fields.absoluteName);
	adapter.addField(rules.string, 'imageLgURL', fields.imageLgURL);
	adapter.addField(rules.string, 'imageMdURL', fields.imageMdURL);
	adapter.addField(rules.string, 'imageSmURL', fields.imageSmURL);
	adapter.addField(rules.string, 'thumbURL', fields.thumbURL);

	adapter.addField(rules.positiveNumber, 'weight', fields.weight);
	adapter.addField(rules.positiveNumber, 'posX', fields.posX);
	adapter.addField(rules.positiveNumber, 'posY', fields.posY);
	adapter.addField(rules.positiveNumber, 'playCount', fields.playCount);

	adapter.addField(rules.bool, 'isFreeSpins', fields.isFreeSpins);
	adapter.addField(rules.bool, 'isBonusSpins', fields.isBonusSpins);
	adapter.addField(rules.bool, 'isChanged', fields.isChanged);

	adapter.addField(rules.arrayNumber, 'freeSpinsCount', fields.freeSpinsCount);
	adapter.addField(rules.arrayNumber, 'currencyIDs', fields.currencyIDs);
	adapter.addField(rules.arrayNumber, 'customProviderIDs', fields.customProviderIDs);
	adapter.addField(rules.arrayNumber, 'restrictedCountries', fields.restrictedCountries);
	adapter.addField(rules.number, 'providerID', fields.providerID);
	adapter.addField(rules.string, 'value', fields.value);
	adapter.addField(rules.string, 'alias', fields.alias);

	return adapter;
}


export function getListParams(filter, sorting, pagination) {

	const builder = new ParamsBuilder();

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);
	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);
	//
	// builder.addField(rules.isID, 'id', fields.id);
	// builder.addField(rules.isID, 'statusID', fields.statusID);
	//
	// builder.addField(rules.isBoolean, 'recurring', fields.recurring);
	//
	// builder.addField(rules.isString, 'casinoGameIDs', fields.casinoGameIDs);
	// builder.addField(rules.isString, 'casinoProviderIDs', fields.casinoProviderIDs);
	//
	// builder.addRangeField(rules.isDateTimeRange, filter.startDate, [
	//   fields.startDateFrom,
	//   fields.startDateTo,
	// ]);
	// builder.addRangeField(rules.isDateTimeRange, filter.endDate, [
	//   fields.endDateFrom,
	//   fields.endDateTo,
	// ]);
	// builder.addRangeField(rules.isDateTimeRange, filter.settlementDate, [
	//   fields.settlementDateFrom,
	//   fields.settlementDateTo,
	// ]);
	// builder.addRangeField(rules.isNumberRange, filter.ggrPercent, [
	//   fields.ggrPercentFrom,
	//   fields.ggrPercentTo,
	// ]);

	const params = builder.biuldParams(filter);

	return params;
}
