import { normalize, schema } from 'normalizr';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import Formatter from '../../../../helpers/formatter';
import EntityAdapter from '../../../../helpers/entityAdapter';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import { BET_HISTORY_TYPES } from '../../../../helpers/commonConstants';
import { betStatusList, betTypesList } from '../../../../helpers/commonEnums';
import { mapEnum, getHeadersTotalCount } from '../../../../helpers/utils';

const fields = {
	id       : 'id',
	userID   : 'user_id',
	statusID : 'status_id',
	date     : 'place_date',
	stake    : 'stake',
	odd      : 'total_odd',
	total    : 'possible_won_amount_usd',
	typeID   : 'type_id',
	fullCount: 'full_count',
	details  : 'details',

	// Bet Details
	sportName    : 'sport_name',
	countryName  : 'country_name',
	leagueName   : 'league_name',
	eventName    : 'event_name',
	marketName   : 'market_name',
	selectionName: 'selection_name',

	userName: 'username',
	email   : 'email',

	// void bets
	eventID         : 'event_id',
	eventMarketID   : 'event_market_id',
	eventSelectionID: 'event_selection_id',
	placeDateFrom   : 'place_date_from',
	placeDateTo     : 'place_date_to',
};

const betslipsAdapter = createBetslipsAdapter();
const usersAdapter    = createUsersAdapter();
const detailsAdapter  = createDetailsAdapter();

const betStatusMap    = mapEnum(betStatusList);
const betTypesMap     = mapEnum(betTypesList);

// Filter
export function createListParams(filter = {}, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	if (pagination) {
		builder.addValue('page', pagination.currentPage);
		builder.addValue('limit', pagination.itemsPerPage);
	}

	builder.addField(rules.isDateUTC, 'placeDateFrom', fields.placeDateFrom);
	builder.addField(rules.isDateUTC, 'placeDateTo', fields.placeDateTo);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapting ---------------------------------------------------------------------------------------

export function adaptData(rawData = [], responseHeaders = {}) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return {
			bets      : {},
			totalCount: 0,
			usersIDs  : [],
		};
	}

	const usersIDs = [];

	betslipsAdapter.clearExcludes();
	const adaptedList = betslipsAdapter.adaptList(rawData);
	adaptedList.forEach(item => {
		item.stake  = Formatter.sum(item.stake);
		item.odd    = Formatter.decimal(item.odd);
		item.total  = Formatter.sum(item.total);

		item.status = betStatusMap[item.statusID] || 'No data';
		item.type   = betTypesMap[item.typeID]    || 'No data';

		if (!usersIDs.includes(item.userID)) {
			usersIDs.push(item.userID);
		}

		const betDetails = adaptDetails(item.details);
		item.details = { ...betDetails };
	});

	const tempData = {
		bets: adaptedList,
	};

	const bet = new schema.Entity('bets', {}, { idAttribute: 'id' });
	const betSchema = { bets: [bet] };
	const normalizedData = normalize(tempData, betSchema);
	const bets = normalizedData.entities.bets || {};
	const totalCount =    getHeadersTotalCount(responseHeaders)
		|| (adaptedList.length > 0
			? toInteger(adaptedList[0].fullCount)
			: adaptedList.length);

	return {
		bets,
		totalCount,
		usersIDs,
	};
}

export function adaptUsers(rawData = []) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return {};
	}

	usersAdapter.clearExcludes();
	const adaptedList = usersAdapter.adaptList(rawData);
	adaptedList.forEach(item => {
		if (!item.userName) {
			item.userName = item.email;
		}
	});

	const tempData = {
		users: adaptedList,
	};

	const user = new schema.Entity('users', {}, { idAttribute: 'id' });
	const userSchema = { users: [user] };
	const normalizedData = normalize(tempData, userSchema);
	const users = normalizedData.entities.users || {};

	return users;
}

export function combineData(rawBets, users) {
	const bets = cloneDeep(rawBets);
	Object.keys(bets).forEach(betID => {
		const betItem = bets[betID];
		const user = users[betItem.userID];
		betItem.userName = user ? user.userName : `No User Name (user ID: ${betItem.userID})`;
	});

	return bets;
}

function adaptDetails(rawData = []) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return {};
	}

	detailsAdapter.clearExcludes();
	const adaptedList = detailsAdapter.adaptList(rawData);

	const sports     = uniq( adaptedList.map(item => item.sportName) ).join(', ');
	const countries  = uniq( adaptedList.map(item => item.countryName) ).join(', ');
	const leagues    = uniq( adaptedList.map(item => item.leagueName) ).join(', ');
	const events     = uniq( adaptedList.map(item => item.eventName) ).join(', ');
	const markets    = uniq( adaptedList.map(item => item.marketName) ).join(', ');
	const selections = uniq( adaptedList.map(item => item.selectionName) ).join(', ');

	return {
		sports,
		countries,
		leagues,
		events,
		markets,
		selections,
	};
}

// Adapters ---------------------------------------------------------------------------------------

function createBetslipsAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'typeID', fields.typeID);
	adapter.addField(rules.id, 'fullCount', fields.fullCount);

	adapter.addField(rules.fullDate, 'date', fields.date);

	adapter.addField(rules.positiveNumber, 'stake', fields.stake);
	adapter.addField(rules.positiveNumber, 'odd', fields.odd);
	adapter.addField(rules.positiveNumber, 'total', fields.total);

	adapter.addField(rules.noCondition, 'details', fields.details);

	return adapter;
}

function createUsersAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'userName', fields.userName);
	adapter.addField(rules.string, 'email', fields.email);

	return adapter;
}

function createDetailsAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'sportName', fields.sportName);
	adapter.addField(rules.string, 'countryName', fields.countryName);
	adapter.addField(rules.string, 'leagueName', fields.leagueName);
	adapter.addField(rules.string, 'eventName', fields.eventName);
	adapter.addField(rules.string, 'marketName', fields.marketName);
	adapter.addField(rules.string, 'selectionName', fields.selectionName);

	return adapter;
}

// Preparing --------------------------------------------------------------------------------------
export function prepareVoidBetsData(betHistoryType, data) {

	const result = {};
	if (data.placeDateFrom) {
		result[fields.placeDateFrom] = Formatter.dateUTC(data.placeDateFrom);
	}
	if (data.placeDateTo) {
		result[fields.placeDateTo] = Formatter.dateUTC(data.placeDateTo);
	}

	switch (betHistoryType) {
	case BET_HISTORY_TYPES.event:
		result[fields.eventID] = data.id;
		break;
	case BET_HISTORY_TYPES.market:
		result[fields.eventMarketID] = data.id;
		break;
	case BET_HISTORY_TYPES.selection:
		result[fields.eventSelectionID] = data.id;
		break;
	default:
	}

	return result;
}

// Service ----------------------------------------------------------------------------------------
export function getHistoryTypeName(betHistoryType) {

	switch (betHistoryType) {
	case BET_HISTORY_TYPES.event:
		return 'event';
	case BET_HISTORY_TYPES.market:
		return 'market';
	case BET_HISTORY_TYPES.selection:
		return 'selection';
	default:
		return null;
	}
}
