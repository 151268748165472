export const mockLimitsData = [
	{
		user_id : 11111,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 3333,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 2222,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 55555,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 11111,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 3333,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 2222,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 55555,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 11111,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 3333,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 2222,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 55555,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 11111,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 3333,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 2222,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},
	{
		user_id : 55555,
		type    : 'type',
		duration: 'duration',
		value   : 'value',
	},

];
