import { isEmpty } from 'lodash';
import { all, takeEvery, select, put, fork, call } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { deriveTablePagination } from '../../../../selectors/tables';
import { getFilterParams } from '../../partners/filter/utils';
import actions from './actions';
import tableActions from '../../../tables/actions';
import { actions as partnerActions } from '../../partners/list/actions';
import { websitesAPI } from '../../../../helpers/api/websites';
import notifications from '../../../../helpers/notifications';

import { adaptWebsitesList } from './utils';
import { DATE_FORMATS } from '../../../../containers/Partner/Websites/modals/Websites/utils';

const tableType = TABLE_TYPES.websites;
const prefix = 'partner.websites';

const messages = {
	errorListLoad       : `${prefix}.errorListLoad`,
	errorWebsiteSave    : `${prefix}.errorWebsiteSave`,
	errorWebsiteDelete  : `${prefix}.errorWebsiteDelete`,
	successWebsiteSave  : `${prefix}.successWebsiteSave`,
	successWebsiteDelete: `${prefix}.successWebsiteDelete`,
};

function getStoreData(state) {
	return {
		pagination: deriveTablePagination(tableType, state),
	};
}

function* listReload() {
	yield takeEvery(actions.WEBSITES_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { pagination } = yield select(getStoreData);
		const params = getFilterParams(null, null, pagination);
		let entities = {};
		let totalCount = 0;

		try {
			const res = yield call(websitesAPI.websitesList, params);
			yield put(partnerActions.partnerAttachedLanguages());
			if (res && res.status === 200) {
				entities = adaptWebsitesList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);
				const noWebsite = isEmpty(entities);
				if (noWebsite) {
					// yield put(appActions.websiteRefresh(null));
					// yield put(removeWebsiteID(null));
				}
				let equal = false;
				let activeID = 0;
				let dateFormatName = '';
				Object.keys(entities).forEach((key) => {
					if (entities[key].dateFormat) {
						const dateFormats = cloneDeep(DATE_FORMATS);
						dateFormats.forEach((item) => {
							if (item.name.toUpperCase() === entities[key].dateFormat.toUpperCase()) {
								activeID = item.id;
								dateFormatName = item.name;
								equal = true;
							}
						});
						if (!equal) {
							activeID = dateFormats.length + 1;
							dateFormatName = entities[key].dateFormat;
							dateFormats.push({ id: dateFormats.length + 1, name: entities[key].dateFormat });
						}
						entities[key].dateFormatID = activeID;
						entities[key].dateFormat = dateFormatName;
						entities[key].dateFormatList = dateFormats;
					}
				});
			}
			yield put(actions.listRefresh(entities));
		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			console.log(error);
		}
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* deleteWebsite() {
	yield takeEvery(actions.WEBSITES_LIST_DELETE_ITEM, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { websiteID } = action.data;
		try {
			const res = yield call(websitesAPI.websiteDelete, websiteID);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successWebsiteDelete);
			}
		} catch (error) {
			notifications.showError(messages.errorWebsiteDelete, error);
			console.log(error);
		}

		yield put(actions.listReload());
	});
}

export default function* websitesListSaga() {
	yield all([fork(listReload), fork(deleteWebsite)]);
}
