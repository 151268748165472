import isArray from 'lodash/isArray';

const fields = {
	betslipType : 'betslip_type',
	marketType  : 'market_type',
	country     : 'country',
	league      : 'league',
	sport       : 'sport',
	placedAmount: 'placed_amount',
};

export function adaptStatisticData(rawData) {

	const betslipTypesList = isArray(rawData[fields.betslipType])   ? rawData[fields.betslipType]  : [];
	const marketTypeList   = isArray(rawData[fields.marketType])    ? rawData[fields.marketType]   : [];
	const countriesList    = isArray(rawData[fields.country])       ? rawData[fields.country]      : [];
	const leaguesList      = isArray(rawData[fields.league])        ? rawData[fields.league]       : [];
	const sportsList       = isArray(rawData[fields.sport])         ? rawData[fields.sport]        : [];
	const placedAmountList = isArray(rawData[fields.placedAmount])  ? rawData[fields.placedAmount] : [];

	const result = {
		betslipTypesList,
		marketTypeList,
		countriesList,
		leaguesList,
		sportsList,
		placedAmountList,
	};

	return result;
}
