import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({

	depositsData: [],
	UI          : {
		loading: false,
		visible: false,
	},

});

export default function dashboardDepositsReducer(state = initState, action) {

	switch (action.type) {

	case actions.DASHBOARD_DEPOSITS_DATA_REFRESH: {
		return state.set('depositsData', action.data);
	}
	case actions.DASHBOARD_DEPOSITS_UI_REFRESH: {
		return state.set('UI', action.data);
	}

	default:
		return state;
	}
}
