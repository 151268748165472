import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	marketsIDs  : [],
	markets     : {},
	selections  : {},
	displayTypes: {},

	UI: {
		loading              : false,
		event                : {},
		eventID              : null,
		selectedSelectionID  : null,
		selectedDisplayTypeID: 0,
		isChanged            : false,
		onlyUnsettled        : false,
		changedIDs           : [],
	},
});

export default function resultsDetailsReducer(state = initState, action) {

	switch (action.type) {

	case actions.DATA_REFRESH: {
		const { resultData } = action.data;
		const { marketsIDs, markets, selections } = resultData;

		return state
			.set('marketsIDs', marketsIDs)
			.set('markets', markets)
			.set('selections', selections);
	}

	case actions.DATA_RESET: {
		return initState;
	}

	case actions.UI_REFRESH: {
		const { UI } = action.data;
		const target = state.get('UI');
		const result = fill(UI, target, true);

		return state.set('UI', result);
	}

	case actions.TYPES_REFRESH: {
		const { displayTypes } = action.data;
		return state.set('displayTypes', displayTypes);
	}

	default:
		return state;
	}
}
