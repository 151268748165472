import { getDomain } from './helpers/getDomain';

const { hostname }       = window.location;

const DEV_API_PATH       = process.env.REACT_APP_DEV_API_PATH    || 'https://dev-api.bet-makers.com/v1/';
const DEV_SOCKET_PATH    = process.env.REACT_APP_DEV_SOCKET_PATH || 'https://dev-wsapi.bmakers.site';

const PROD_API_PATH      = process.env.REACT_APP_PROD_API_PATH  ||  `https://api.${getDomain()}/v1`;
const PROD_SOCKET_PATH   = process.env.REACT_APP_PROD_SOCKET_PATH || `https://wsapi.${getDomain()}`;

const CHECK_NOTIFICATION_TIMEOUT = process.env.REACT_APP_CHECK_NOTIFICATION_TIMEOUT || 15000;

export const API_URL     = process.env.NODE_ENV === 'development' ? DEV_API_PATH : PROD_API_PATH;
export const SOCKET_URL  = process.env.NODE_ENV === 'development' ? DEV_SOCKET_PATH : PROD_SOCKET_PATH;

export const socketConfig = {
	apiProtocol: 'http',
	apiPort    : 5002,
	apiVersion : 'api/v1',
	debugInfo  : (process.env.NODE_ENV === 'development'),
};

export const apiAdminConfig = {
	apiProtocol: 'http',
	apiPort    : 3001,
};
export const apiAdminUploadPath = `${apiAdminConfig.apiProtocol}://${hostname}:${apiAdminConfig.apiPort}`;

export const siteConfig = {
	siteName  : 'OleoBet',
	siteIcon  : 'ion-flash',
	footerText: 'OleoBet ©2018 Created by BetMakers',
};

export const themeConfig = {
	topbar : 'themedefault',
	sidebar: 'themedefault',
	layout : 'themedefault',
	theme  : 'themedefault',
};

export const language = 1; // English
export const defaultWebsiteID = 1;
export const defaultPartnerID = 1;
export const checkNotificationTimeout = CHECK_NOTIFICATION_TIMEOUT; // It's used in src/containers/Topbar/Topbar.js
