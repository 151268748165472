import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;

  .ant-table-wrapper {
    width: 100%;
    box-sizing: border-box;
  }

  .ant-table-thead {
    tr {
      th {
        word-wrap: normal;
        white-space: normal;
        word-break: normal;
        vertical-align: top;

        &.ant-table-column-sort {
          color: #1864fd;
        }
      }
    }
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    word-break: normal;
  }

  .ant-table-row {
    td {
      padding: 8px;
    }
  }

  .anticon-caret-up,
  .anticon-caret-down {
    font-size: 18px;
  }
`;

export const Empty = styled.h4`
  padding: 20px 0;
  text-align: center;
`;

export const IconWrapper = styled.div`
  width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 30px;
	i {
		font-size: 40px;
	}
`;
