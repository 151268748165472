import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';
import tableActions from '../../tables/actions';

import { getListParams } from './utils';
import { adaptTransactionList } from '../casino/utils';

import { casinoAPI } from '../../../helpers/api/casino';
import { getHeadersTotalCount } from '../../../helpers/utils';
import notifications from '../../../helpers/notifications';
import { deriveTablePagination } from '../../../selectors/tables';
import { TABLE_TYPES } from '../../../constants/tableTypes';

const prefix = 'rtm.userCasino';

const messages = {
	errorListReload: `${prefix}.errorListReload`,
};

const tableType = TABLE_TYPES.casinoProfile;

function getStoreData(state) {
	const { RTM, Tables } = state;

	return {
		filter    : RTM.UserCasino.get('filter'),
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
	};
}

function* listReload() {

	yield takeEvery(actions.RTM_USER_CASINO_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);
		let transactionIDs = [];
		let entities       = {};
		let casinoTotals   = {};
		let totalCount     = 0;
		try {
			const res = yield call(casinoAPI.rtmCasinoList, params);
			if (res && res.status === 200) {
				const result   = adaptTransactionList(res.data.data);
				transactionIDs = result.transactionIDs; // eslint-disable-line prefer-destructuring
				entities       = result.entities;       // eslint-disable-line prefer-destructuring
				casinoTotals   = result.casinoTotals;   // eslint-disable-line prefer-destructuring
				totalCount     = getHeadersTotalCount(res.headers) || transactionIDs.length;
			}

		} catch (error) {
			notifications.showError(messages.errorListReload, error);
			console.log(error);
		}
		yield put(actions.dataRefresh(transactionIDs, entities, casinoTotals));
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {
	yield takeEvery(actions.RTM_USER_CASINO_FILTER_APPLY, function* () {
		yield put(actions.listReload());
	});
}

export default function* rtmUserCasinoSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
	]);
}
