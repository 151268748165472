import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import { categoriesAPI } from '../../../../helpers/api/categories';
import { participantsAPI } from '../../../../helpers/api/participants';
import { DISPLAY_STATUS } from '../../../../helpers/commonConstants';
import notifications from '../../../../helpers/notifications';


const prefix = 'participants.filter';

const messages = {
	errorLoadCountries: `${prefix}.errorLoadCountries`,
	errorLoadLeagues  : `${prefix}.errorLoadLeagues`,
	errorLoadAges     : `${prefix}.errorLoadAges`,
};

function* countryListReload() {

	const takeAction   = actions.PARTICIPANTS_FILTER_COUNTRY_LIST_RELOAD;

	yield takeEvery(takeAction, function* (action) {

		const { sportID } = action.data;
		const params = {
			parent_id        : sportID,
			display_status_id: DISPLAY_STATUS.visible,
		};
		try {
			const response = yield call(categoriesAPI.getCategories, params);
			if (response && response.status === 200) {
				const list = response.data.data;
				yield put(actions.countryListRefresh(list));
			}

		} catch (error) {
			notifications.showError(messages.errorLoadCountries, error);
			console.log(error);
		}
	});
}

function* leagueListReload() {

	const takeAction   = actions.PARTICIPANTS_FILTER_LEAGUE_LIST_RELOAD;

	yield takeEvery(takeAction, function* (action) {

		const { sportID } = action.data;
		const params = {
			display_status_id: DISPLAY_STATUS.visible,
		};
		try {
			const response = yield call(categoriesAPI.getCategoriesOfSport, sportID, params);
			if (response && response.status === 200) {
				const list = response.data.data;
				yield put(actions.leagueListRefresh(list));
			}

		} catch (error) {
			notifications.showError(messages.errorLoadLeagues, error);
			console.log(error);
		}
	});
}

function* ageListReload() {

	const takeAction   = actions.PARTICIPANTS_FILTER_AGE_LIST_RELOAD;

	yield takeEvery(takeAction, function* () {

		let ageList = [];

		try {
			const response = yield call(participantsAPI.participantAgeList);
			if (response && response.status === 200) {
				ageList = response.data.data || [];
			}

		} catch (error) {
			notifications.showError(messages.errorLoadAges, error);
			console.log(error);
		}

		yield put(actions.ageListRefresh(ageList));
	});
}

export default function* participantsFilterSaga() {
	yield all([
		fork(countryListReload),
		fork(leagueListReload),
		fork(ageListReload),
	]);
}
