import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';

import { marketsAPI } from '../../../../helpers/api/markets';
import notifications from '../../../../helpers/notifications';

import { isInSystemSelections } from './utils';

const prefix = 'sport.market.mapping';

const messages = {
	errorMappingLoad    : `${prefix}.errorMappingLoad`,
	errorOutcomeLoad    : `${prefix}.errorOutcomeLoad`,
	errorMappingUpdate  : `${prefix}.errorMappingUpdate`,
	successMappingUpdate: `${prefix}.successMappingUpdate`,
};

function getStoreData({ Sport: { Markets } }) {

	return {
		systemSelections: Markets.Mapping.get('systemSelections'),
		outcomesData    : Markets.Mapping.get('outcomesData'),
		mappedMarketList: Markets.Mapping.get('mappedMarketList'),
		modalUI   					 : Markets.Mapping.get('modalUI'),
	};
}

function* reloadMappings() {

	yield takeEvery(actions.MARKET_MAPPING_RELOAD_MAPPINGS, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { marketID, marketName, providerID, sportID, tradingMode } = action.data;
		// const { outcomesData } = yield select(getStoreData);

		let systemSelections   = [];
		let mappedMarketList   = [];
		let unmappedMarketList = [];
		let providerMarketID	 = null;
		let response					 = null;

		try {

			// system selections
			response = yield call(marketsAPI.marketSelectionsList, marketID);
			if (response && response.status === 200) {
				systemSelections = response.data.data;
			}

			// mapping
			response = yield call(marketsAPI.marketMappingByProvider, { marketID, providerID, tradingMode });
			if (response && response.status === 200) {
				mappedMarketList = response.data.data.map(item => {
					return {
						...item,
						system: marketName,
					};
				});
				providerMarketID = mappedMarketList.length > 0 ? mappedMarketList[0].provider_market_id : null;
			}

			// unmappings
			response = yield call(marketsAPI.marketUnMappingByProviderAndSport, providerID, sportID, tradingMode);
			if (response && response.status === 200) {
				unmappedMarketList = response.data.data.map(item => {
					return {
						...item,
						system: marketName,
					};
				});
			}

			// selections
			if (providerMarketID) {
				response = yield call(marketsAPI.marketSelectionMappingByProvider, { providerID, providerMarketID, sportID, tradingMode });
				if (response && response.status === 200) {
					const tableData = response.data.data.map(selection => {
						return {
							...selection,
							isSelected: isInSystemSelections(selection, systemSelections),
						};
					});
					yield put(actions.outcomesDataRefresh({ [providerMarketID]: tableData }));
				}
			}

		} catch (error) {
			notifications.showError(messages.errorMappingLoad, error);
			console.log(error);
		}

		yield put(actions.systemSelectionsRefresh(systemSelections));
		yield put(actions.mappedListRefresh(mappedMarketList));
		yield put(actions.unmappedListRefresh(unmappedMarketList));
		yield put(actions.mappedAndUnmappedListRefresh([...mappedMarketList, ...unmappedMarketList]));

		yield put(actions.modalUIRefresh({
			providerMarketID,
			loading: false,
		}));
	});
}

function* outcomesDataReload() {

	yield takeEvery(actions.MARKET_MAPPING_OUTCOMES_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { providerID, providerMarketID, sportID, tradingMode } = action.data;
		const { systemSelections } = yield select(getStoreData);

		try {
			const response = yield call(marketsAPI.marketSelectionMappingByProvider, { providerID, providerMarketID, sportID, tradingMode });
			if (response && response.status === 200) {
				const tableData = response.data.data.map(selection => {
					return {
						...selection,
						isSelected: isInSystemSelections(selection, systemSelections),
					};
				});
				// outcomesData[providerMarketID] = tableData;
				yield put(actions.outcomesDataRefresh({ [providerMarketID]: tableData }));
			}

		} catch (error) {
			notifications.showError(messages.errorOutcomeLoad, error);
			console.log(error);
		}

		yield put(actions.modalUIRefresh({ loading: false }));
	});
}

function* mappingUpdate() {

	yield takeEvery(actions.MARKET_MAPPING_UPDATE, function* () {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { mappedMarketList, outcomesData, modalUI } = yield select(getStoreData);
		const { marketID, providerID, closeModal } = modalUI;

		const marketMappings = [];
		const selectionMappings = [];

		mappedMarketList.forEach(map => {
			if (map.id) {
				marketMappings.push(map.id);
			}

			const providerID = map.provider_market_id;
			const selections = outcomesData[providerID];
			if (selections) {
				selections.forEach(selection => {
					if (selection.isSelected) {
						const item = {
							id                 : selection.id,
							system_selection_id: selection.system_selection_id,
						};
						selectionMappings.push(item);
					}
				});
			}
		});

		try {
			const response = yield call(marketsAPI.mapMarketWithSelections, providerID, marketID, marketMappings, selectionMappings);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successMappingUpdate);
			}

		} catch (error) {
			notifications.showError(messages.errorMappingUpdate, error);
			console.log(error);
		}

		yield put(actions.modalUIRefresh({
			loading  : false,
			isChanged: false,
		}));
		if (closeModal) {
			yield put(actions.modalUIReset());
		}
	});
}

export default function* mappingSaga() {
	yield all([
		fork(reloadMappings),
		fork(outcomesDataReload),
		fork(mappingUpdate),
	]);
}
