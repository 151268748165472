import { call } from 'redux-saga/effects';
import { casinoAPI } from '../../../../helpers/api/casino';
import { contentAPI } from '../../../../helpers/api/content';

import {
	CASINO_GAMES_STATUS,
	CHANNEL_TYPES,
} from '../../../../helpers/commonConstants';
import { adaptGamesList } from '../../../casino/games/utils';
import { adaptDefaultGrid } from './utils';

export function* makeRequestDefaultGrid(channelID = CHANNEL_TYPES.backend) {

	let defaultGrid = [];
	const params = {
		casino_game_id: -1,
		channel_id    : channelID,
	};

	try {
		const res   = yield call(contentAPI.gamesDefaultGridList, params);
		defaultGrid = yield call(adaptDefaultGrid, res.data.data);

	} catch (error) {
		console.log('error ', error);

	}

	return defaultGrid;
}

export function* makeRequestGamesList(websiteID, params) {

	let gamesList = [];
	const reqParams = {
		unlimit  : true,
		status_id: CASINO_GAMES_STATUS.active,
	};


	if (params.channelID) {
		reqParams.channel_id = params.channelID;
	}
	if (params.categoryID) {
		reqParams.category = params.categoryID;
	}


	try {
		const res = yield call(casinoAPI.gamesList, websiteID, reqParams);
		gamesList = yield call(adaptGamesList, res.data.data);

	} catch (error) {
		console.log('error ', error);

	}

	return gamesList;
}


export function* makeRequestGridsSave(grids) {

	try {
		yield call(contentAPI.gamesDefaultGridUpdate, grids);
		return true;

	} catch (error) {
		console.log(error);
	}
}
