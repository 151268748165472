//import { normalize, schema } from 'normalizr';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import toInteger from 'lodash/toInteger';
import { LANGUAGES } from '../../../helpers/commonConstants';
import EntityAdapter from '../../../helpers/entityAdapter';
import { isID } from '../../../helpers/utils';
import { createListAdapter, fields } from '../customProviders/utils';


const listAdapter  = createListAdapter();
const namesAdapter = createNamesAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptCustomProvider(rawData = []) {

	listAdapter.clearExcludes();

	if (!isArray(rawData) || isEmpty(rawData)) {
		const adaptedData = listAdapter.adapt({});
		return adaptedData;
	}

	const adaptedData = listAdapter.adapt(rawData[0]);

	return adaptedData;
}

export function adaptNamesData(rawData = [], defaultName = null, langList = []) {

	namesAdapter.clearExcludes();

	const result = [];
	const adaptedList = namesAdapter.adaptList(rawData);

	langList.forEach(languageItem => {
		const langID    = languageItem.id;
		const namesItem = find(adaptedList, { langID }) || {};

		const record = {
			langID,
			name : namesItem.name,
			alias: namesItem.alias,

			OGTitle      : StringOrNull(namesItem.OGTitle),
			OGDescription: StringOrNull(namesItem.OGDescription),
			OGImage      : StringOrNull(namesItem.OGImage),

			SEOTitle      : StringOrNull(namesItem.SEOTitle),
			SEODescription: StringOrNull(namesItem.SEODescription),
			SEOKeywords   : StringOrNull(namesItem.SEOKeywords),
		};

		if (langID === LANGUAGES.en && !record.name) {
			record.name = defaultName;
		}

		result.push(record);
	});

	return result;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareCustomProvider(baseData) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	const preparedData = listAdapter.prepare(baseData);
	if (isID(baseData.id)) {
		preparedData.id   = toInteger(baseData.id);
	}

	return preparedData;
}

export function prepareNamesData(rawData = [], id) {

	namesAdapter.clearExcludes();
	namesAdapter.addExcludeField('id');

	return rawData.map(rawItem => {

		const item      = namesAdapter.prepare(rawItem);
		item[fields.id] = id;
		if(!item.name) {
			item.name = null;
		};
		return item;
	});
}


// Adapters ---------------------------------------------------------------------------------------

export function createNamesAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'subCategoryID', fields.subCategoryID);
	adapter.addField(rules.id, 'langID', fields.langID);

	adapter.addField(rules.stringOrNull, 'name', fields.name);
	adapter.addField(rules.stringOrNull, 'alias', fields.alias);

	adapter.addField(rules.stringOrNull, 'SEOTitle', fields.seoTitle);
	adapter.addField(rules.stringOrNull, 'SEODescription', fields.seoDescription);
	adapter.addField(rules.stringOrNull, 'SEOKeywords', fields.seoKeywords);

	adapter.addField(rules.stringOrNull, 'OGTitle', fields.OGTitle);
	adapter.addField(rules.stringOrNull, 'OGDescription', fields.OGDescription);
	adapter.addField(rules.stringOrNull, 'OGImage', fields.OGImage);

	return adapter;
}


// utility functions

const StringOrNull = (value) => String(value) ? value : null;
