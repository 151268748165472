import { Map } from 'immutable';
import { fill } from '../../../../../helpers/utils';
import actions from './actions';
import { PROMOTION_STATUS, CHANNEL_TYPES } from '../../../../../helpers/commonConstants';

const initState = new Map({

	baseData: {
		statusID: PROMOTION_STATUS.inactive,
	},
	revisionsEntities: {},

	UI: {
		promotionID  : null,
		promotionName: null,
		editMode     : false,
		loading      : false,
		langID       : 1,
		channelID    : CHANNEL_TYPES.web,
		isDataChanged: false,
		revisionID   : null,
	},
});

export default function promotionReducer(state = initState, action) {

	switch (action.type) {

	case actions.PROMOTION_BASE_DATA_REFRESH: {
		const target = state.get('baseData');
		const result = fill(action.data, target, true);
		const tempGames = [];
		result?.gameIDs?.length && result.gameIDs.forEach(item => {
			typeof item === 'object' ? tempGames.push(item.id) : tempGames.push(item);
		});

		result.gameIDs = tempGames;
		return state.set('baseData', result);
	}

	case actions.PROMOTION_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.PROMOTIONS_REVISIONS_LIST_REFRESH: {
		return state.set('revisionsEntities', action.data);
	}

	case actions.PROMOTION_DATA_RESET: {
		return initState;
	}

	default:
		return state;
	}
}
