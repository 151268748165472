const actions = {

	USER_DATA_RELOAD: 'USER_DATA_RELOAD',

	USER_BASE_DATA_RELOAD : 'USER_BASE_DATA_RELOAD',
	USER_BASE_DATA_REFRESH: 'USER_BASE_DATA_REFRESH',

	USER_ADMIN_DATA_RELOAD : 'USER_ADMIN_DATA_RELOAD',
	USER_ADMIN_DATA_REFRESH: 'USER_ADMIN_DATA_REFRESH',

	USER_FINANCE_DATA_RELOAD : 'USER_FINANCE_DATA_RELOAD',
	USER_FINANCE_DATA_REFRESH: 'USER_FINANCE_DATA_REFRESH',

	USER_PERMISSIONS_REFRESH     : 'USER_PERMISSIONS_REFRESH',
	USER_PERMISSIONS_REMOVE_ITEMS: 'USER_PERMISSIONS_REMOVE_ITEMS',
	USER_PERMISSIONS_ADD_ITEMS   : 'USER_PERMISSIONS_ADD_ITEMS',

	USER_SET_VALUE_UI  : 'USER_SET_VALUE_UI',
	USER_UPDATE        : 'USER_UPDATE',
	USER_RESET_PASSWORD: 'USER_RESET_PASSWORD',

	USER_CURRENCY_LIST_RELOAD : 'USER_CURRENCY_LIST_RELOAD',
	USER_CURRENCY_LIST_REFRESH: 'USER_CURRENCY_LIST_REFRESH',

	USER_NOTES_RELOAD : 'USER_NOTES_RELOAD',
	USER_NOTES_REFRESH: 'USER_NOTES_REFRESH',
	USER_NEW_NOTE_SET : 'USER_NEW_NOTE_SET',
	USER_NOTE_DELETE  : 'USER_NOTE_DELETE',

	USER_QUICK_SEARCH       			   : 'USER_QUICK_SEARCH',
	USER_QUICK_SEARCH_DATA_REFRASH: 'USER_QUICK_SEARCH_DATA_REFRASH',
	USER_QUICK_SEARCH_DATA_RESET  : 'USER_QUICK_SEARCH_DATA_RESET',

	USER_DEPOSIT_AND_WITHDRAW_BLOCK: 'USER_DEPOSIT_AND_WITHDRAW_BLOCK',

	userQuickSearch: () => ({
		type: actions.USER_QUICK_SEARCH,
	}),

	userQuickSearchDataRefrash: data => ({
		type: actions.USER_QUICK_SEARCH_DATA_REFRASH,
		data,
	}),

	userQuickSearchDataReset: () => ({
		type: actions.USER_QUICK_SEARCH_DATA_RESET,
	}),

	userDataReload: (userID, type ) => ({
		type: actions.USER_DATA_RELOAD,
		data: {
			userID,
			type,
		},
	}),

	baseDataReload: userID => ({
		type: actions.USER_BASE_DATA_RELOAD,
		data: {
			userID,
		},
	}),
	// role base data refresh
	baseDataRefresh: data => ({
		type: actions.USER_BASE_DATA_REFRESH,
		data,
	}),
	adminDataReload: userID => ({
		type: actions.USER_ADMIN_DATA_RELOAD,
		data: {
			userID,
		},
	}),
	adminDataRefresh: data => ({
		type: actions.USER_ADMIN_DATA_REFRESH,
		data,
	}),
	financeDataReload: userID => ({
		type: actions.USER_FINANCE_DATA_RELOAD,
		data: {
			userID,
		},
	}),
	finaneDataRefresh: data => ({
		type: actions.USER_FINANCE_DATA_REFRESH,
		data,
	}),

	userNotesReload: userID => ({
		type: actions.USER_NOTES_RELOAD,
		data: {
			userID,
		},
	}),
	userNotesRefresh: data => ({
		type: actions.USER_NOTES_REFRESH,
		data,
	}),
	newNoteSet: data => ({
		type: actions.USER_NEW_NOTE_SET,
		data,
	}),
	userNoteDelete: (userID, noteID) => ({
		type: actions.USER_NOTE_DELETE,
		data: {
			userID,
			noteID,
		},
	}),

	permissionsRefresh: list => ({
		type: actions.USER_PERMISSIONS_REFRESH,
		data: list,
	}),
	permissionsRemoveItems: roleNamesList => ({
		type: actions.USER_PERMISSIONS_REMOVE_ITEMS,
		data: roleNamesList,
	}),
	permissionsAddItems: roleNamesList => ({
		type: actions.USER_PERMISSIONS_ADD_ITEMS,
		data: roleNamesList,
	}),

	setValueUI: (valueName, value) => ({
		type: actions.USER_SET_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),
	userUpdate: (userID, type) => ({
		type: actions.USER_UPDATE,
		data: {
			userID,
			type,
		},
	}),
	resetPassword: (email, admin) => ({
		type: actions.USER_RESET_PASSWORD,
		data: {
			email,
			admin,
		},
	}),

	currencyListReload: (websiteID) => ({
		type: actions.USER_CURRENCY_LIST_RELOAD,
		data: {
			websiteID,
		},
	}),
	currencyListRefresh: (currencyList) => ({
		type: actions.USER_CURRENCY_LIST_REFRESH,
		data: {
			currencyList,
		},
	}),
	depositAndWithdrawBlockUpdateAction: data => ({
		type: actions.USER_DEPOSIT_AND_WITHDRAW_BLOCK,
		data,
	}),
};

export default actions;
