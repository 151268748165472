import { Map } from 'immutable';
import actions from './actions';

import { EVENT_STATUS } from '../../../../constants/events';

const initState = new Map({

	filter: {
		eventName      : '',
		eventID        : '',
		sportID        : 0,
		countryID      : 0,
		leagueID       : 0,
		dateRange      : [],
		tradingMode    : '',
		providerListIDs: [],
		statusID       : [EVENT_STATUS.active],
	},

	countryList  : [],
	leagueList   : [],
	eventNameList: [],
});

export default function eventsFilterReducer(state = initState, action) {

	switch (action.type) {

	case actions.EVENTS_FILTER_SET_VALUE: {
		const { valueName, value } = action.data;
		const filter = new Map(state.get('filter')).toObject();
		filter[valueName] = value;

		return state.set('filter', filter);
	}

	case actions.EVENTS_FILTER_REFRESH: {
		return state.set('filter', action.data);
	}

	case actions.EVENTS_FILTER_COUNTRY_LIST_REFRESH: {
		return state.set('countryList', action.data);
	}

	case actions.EVENTS_FILTER_LEAGUE_LIST_REFRESH: {
		return state.set('leagueList', action.data);
	}

	case actions.EVENTS_FILTER_NAME_LIST_REFRESH: {
		return state.set('eventNameList', action.data);
	}

	case actions.EVENTS_CLEAR_FILTERS: {
		return state.set('filter', initState.get('filter'))
			.set('countryList',  initState.get('countryList'))
			.set('leagueList',  initState.get('leagueList'))
			.set('eventNameList',  initState.get('eventNameList'));
	}

	default:
		return state;
	}
}
