import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';

const fields = {
	id                  : 'id',
	name                : 'name',
	statusID            : 'status_id',
	sportID             : 'category_id',
	tradingModeID       : 'trading_mode',
	channelID           : 'channel_id',
	scopeID             : 'scope',
	resolutionRulesID   : 'resolution_rules',
	altName1            : 'alternative_name_1',
	altName2            : 'alternative_name_2',
	selectionName       : 'selection_name',
	altSelectionName    : 'alt_selection_name',
	marginPrematch      : 'margin',
	marginLive          : 'margin_in_play',
	overaskLive         : 'live_overask',
	overaskPrematch     : 'prematch_overask',
	groupIDs            : 'groups',
	restrictionsGroupIDs: 'restrictions_groups',
	timeFrom            : 'time_from',
	timeTo              : 'time_to',
	combinationMin      : 'combination_min',
	combinationMax      : 'combination_max',
	code                : 'code',
	periodName          : 'period_name',
	periodMinuteFrom    : 'period_minute_from',
	periodMinuteTo      : 'period_minute_to',
	showArgument        : 'show_argument',
};

const listAdapter = createListAdapter();

// Params -----------------------------------------------------------------------------------------
export function getListParams(filter) {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('include_selections', false);

	builder.addField(rules.isID,               'marketID',                fields.id);
	builder.addField(rules.isID,               'sportID',                 fields.sportID);
	builder.addField(rules.isID,               'statusID',                fields.statusID);
	builder.addField(rules.isID,               'tradingModeID',           fields.tradingModeID);
	builder.addField(rules.isID,               'channelID',               fields.channelID);
	builder.addField(rules.isID,               'scopeID',                 fields.scopeID);

	builder.addField(rules.isString,           'name',                    fields.name);
	builder.addField(rules.isString,           'code',                    fields.code);
	builder.addField(rules.isString,           'altName1',                fields.altName1);
	builder.addField(rules.isString,           'altName2',                fields.altName2);
	builder.addField(rules.isString,           'selectionName',           fields.selectionName);
	builder.addField(rules.isString,           'altSelectionName',        fields.altSelectionName);
	builder.addField(rules.isString,           'periodName',              fields.periodName);

	builder.addField(rules.isPositiveNumber,   'marginPrematch',          fields.marginPrematch);
	builder.addField(rules.isPositiveNumber,   'marginLive',              fields.marginLive);

	builder.addField(rules.isTrue,             'overaskLive',             fields.overaskLive);
	builder.addField(rules.isTrue,             'overaskPrematch',         fields.overaskPrematch);

	builder.addField(rules.isArrayID,          'groupIDs',                fields.groupIDs);
	builder.addField(rules.isArrayID,          'restrictionsGroupIDs',    fields.restrictionsGroupIDs);

	builder.addRangeField(rules.isTimeRange,   filter.timeRange,          [fields.timeFrom,         fields.timeTo]);
	builder.addRangeField(rules.isNumberRange, filter.combinationsMinMax, [fields.combinationMin,   fields.combinationMax]);
	builder.addRangeField(rules.isNumberRange, filter.periodMinute,       [fields.periodMinuteFrom, fields.periodMinuteTo]);

	return builder.biuldParams(filter);
}

// Adapt ------------------------------------------------------------------------------------------
export function adaptMarketList(rawData = []) {

	listAdapter.clearExcludes();
	const adaptedList = listAdapter.adaptList(rawData);

	return adaptedList;
}

// Adapters ---------------------------------------------------------------------------------------
function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id,        'id',           fields.id);
	adapter.addField(rules.intOrNull, 'statusID',     fields.statusID);
	adapter.addField(rules.string,    'name',         fields.name);
	adapter.addField(rules.number,    'tradingMode',  fields.tradingModeID);
	adapter.addField(rules.bool,      'showArgument', fields.showArgument);

	return adapter;
}
