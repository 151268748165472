import { Map } from 'immutable';
import { fill } from '../../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	entities: {},
	filter  : {},

});

export default function emailsListReducer(state = initState, action) {

	switch (action.type) {

	case actions.EMAILS_LIST_REFRESH: {
		const { entities } = action.data;
		return state.set('entities', entities);
	}

	case actions.EMAILS_LIST_FILTER_REFRESH: {
		const target = state.get('filter');
		const result = fill(action.data, target, true);
		return state.set('filter', result);
	}

	default:
		return state;
	}
}
