import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import paymentSaga from './payment/saga';
import paywaysSaga from './payways/saga';
import paywaySaga from './payway/saga';

export default function* paymentsSaga() {
	yield all([
		fork(listSaga),
		fork(paymentSaga),
		fork(paywaysSaga),
		fork(paywaySaga),
	]);
}
