import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	uploaders: {},
});

function initUploader(fileLink) {

	return {
		fileLink,
		UI: {
			loading: false,
		},
	};
}

export default function imagesSingleReducer(state = initState, action) {

	switch (action.type) {

	case actions.IMAGES_SINGLE_INIT: {
		const { id, fileLink } = action.data;
		const uploaders = { ...state.get('uploaders') };

		uploaders[id] = initUploader(fileLink);

		return state.set('uploaders', uploaders);
	}

	case actions.IMAGES_SINGLE_SET_FILE: {
		const { id, file } = action.data;
		const uploaders = { ...state.get('uploaders') };

		uploaders[id].file = file;

		return state.set('uploaders', uploaders);
	}

	case actions.IMAGES_SINGLE_SET_FILE_LINK: {
		const { id, fileLink } = action.data;
		const uploaders = { ...state.get('uploaders') };

		uploaders[id].fileLink = fileLink;

		return state.set('uploaders', uploaders);
	}

	case actions.IMAGES_SINGLE_UI_REFRESH: {
		const { id, uiData } = action.data;
		const uploaders = { ...state.get('uploaders') };

		const uploader = uploaders[id];
		uploader.UI    = fill(uiData, uploader.UI);

		return state.set('uploaders', uploaders);
	}

	default:
		return state;
	}
}
