import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import notifications from '../../../../helpers/notifications';
import { historyAPI } from '../../../../helpers/api/history';

import actions from './actions';
import { adaptData } from './utils';

const prefix = 'participants.results.history';

const messages = {
	loadData: `${prefix}.loadData`,
};

function* dataReload() {
	yield takeEvery(actions.RESULT_HISTORY_MODEL_DATA_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		let data = [];
		const { eventSelectionID } = action.data;

		try {
			const res = yield call(
				historyAPI.historyResultSelectionLogs,
				eventSelectionID
			);
			if (res && res.status === 200) {
				data = adaptData(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.loadData, error);
			console.log(error);
		}

		yield put(actions.dataRefresh(data));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* resultHistorySaga() {
	yield all([fork(dataReload)]);
}
