import styled from 'styled-components';

export const DestroyAllWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 2050;
  padding-bottom: 14px;
  transition: all 0.3s;
  opacity: 0;
  button {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5em;
    background-color: #ffffff;
    border: none;
    border-radius: 6px;
    box-shadow: 0 3px 10px rgba(0,0,0,0.16);
    padding: 3px 10px;
    cursor: pointer;
	display: flex;
	align-items: center;
    transition: all 0.3s;
    svg {
	  display: block;
	  margin-left: 8px;
	  margin-top: -2px;
	}
    &:hover {
      color: #1677ff;
    }
  }
  

  
`;


export const NotificationRootNode = styled.div`
  &:hover .destroy_all {
	opacity: 1;
  }
  	&:has(.ant-notification:empty) .destroy_all,
	.destroy_all:last-child {
	  opacity: 0 !important;
	  pointer-events: none;
	}
`
