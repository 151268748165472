import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';
import EntityAdapter from '../../../helpers/entityAdapter';
import { getHeadersTotalCount } from '../../../helpers/utils';

export const fields = {
	id           : 'id',
	typeID       : 'type_id',
	statusID     : 'status_id',
	userID       : 'user_id',
	moneyTypeID  : 'money_type_id',
	amount       : 'amount',
	comment      : 'data',
	balanceBefore: 'balance_before',
	balanceAfter : 'balance_after',
	created      : 'created',
	adminID      : 'source_id',

	// currency
	currency   : 'currency',
	code       : 'code',
	description: 'description',
	name       : 'name',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptTransactionsList(rawData = [], responseHeaders) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	adaptedData.forEach(item => {
		const currency = item.currency || {};
		const comment  = item.comment  || {};

		item.currencyName = `${currency.description} (${currency.code})`;
		if (isPlainObject(comment)) {
			item.comment = (
				get(comment, 'data.data.data', null)
        || get(comment, 'data.data', null)
        || get(comment, 'data', null)
			);
		}
		item.comment = String(item.comment);
		if (item.comment.includes('data')) {
			try {
				const commentObject = JSON.parse(item.comment);
				item.comment = String(commentObject.data);
			} catch (err) {
				console.log('It is impossible to derive comment: ', item.comment);
			}
		}
	});

	const totalCount = getHeadersTotalCount(responseHeaders) || adaptedData.length;

	return {
		totalCount,
		list: adaptedData,
	};
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'typeID', fields.typeID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'moneyTypeID', fields.moneyTypeID);
	adapter.addField(rules.id, 'adminID', fields.adminID);

	adapter.addField(rules.fullDate, 'created', fields.created);

	adapter.addField(rules.number, 'amount', fields.amount);
	adapter.addField(rules.number, 'balanceBefore', fields.balanceBefore);
	adapter.addField(rules.number, 'balanceAfter', fields.balanceAfter);

	adapter.addField(rules.noCondition, 'currency', fields.currency);
	adapter.addField(rules.noCondition, 'comment', fields.comment);

	return adapter;
}
