import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

  baseData: { name: undefined },
  namesData: [],
  checkedList: [],
  entities: [],
  tempEntities: [],
  filteredEntities: [],

	UI: {
		visible     : false,
		integratorID: null,
		langID      : null,
		loading     : false,
		editMode    : false,
		onDragEnd   : false,
	},
});

export default function casinoIntegratorsListReducer(state = initState, action) {

	switch (action.type) {

	case actions.CASINO_INTEGRATORS_LIST_REFRESH: {
		const { entities } = action.data;
		return state.set('entities', entities);
	}

	case actions.CASINO_INTEGRATORS_LIST_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.CASINO_INTEGRATORS_BASE_DATA_REFRESH: {
		const target = state.get('baseData');
		const result = fill(action.data, target, true);
		return state.set('baseData', result);
	}

	case actions.CASINO_INTEGRATORS_TEMP_LIST_REFRESH: {
		const { tempEntities } = action.data;
		return state.set('tempEntities', tempEntities);
	}

    case actions.CASINO_INTEGRATORS_LIST_SET_FILTERED_ITEMS: {
      const { filteredEntities } = action.data;
      return state.set('filteredEntities', filteredEntities);
    }

    default:
      return state;
  }
}
