import { normalize, schema } from 'normalizr';
import EntityAdapter from '../../../../helpers/entityAdapter';

const fields = {
	id               : 'id',
	name             : 'name',
	categoryID       : 'category_id',
	restrictedLeagues: 'restricted_leagues',
};

// Adapting ---------------------------------------------------------------------------------------

export function adaptLeagueList(rawData = []) {
	const adapter = createLeagueListAdapter();
	const adaptedList = adapter.adaptList(rawData);

	const tempData = {
		leagues: adaptedList,
	};

	const league = new schema.Entity('leagues');
	const leagueSchema = { leagues: [league] };

	const normalizedData = normalize(tempData, leagueSchema);

	const leagueIDs = normalizedData.result.leagues || [];
	const leagueEntities = normalizedData.entities.leagues || {};

	return {
		leagueIDs,
		leagueEntities,
	};
}

export function adaptRestrictionList(rawData = {}) {
	const adapter = createRestrictionListAdapter();
	const adaptedData = adapter.adapt(rawData);

	return adaptedData.restrictedLeagues;
}

// Adapters ---------------------------------------------------------------------------------------

function createLeagueListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}

function createRestrictionListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(
		rules.arrayID,
		'restrictedLeagues',
		fields.restrictedLeagues
	);

	return adapter;
}
