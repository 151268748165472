import styled from 'styled-components';


export const StepsContentWrapper = styled.div`
`;

export const ImageUploaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
   > div:first-child {
     width: 10%;
     flex: 1;
     a {
       padding-right: 32px
     }
   }
`;

export const FlexRow = styled.label`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  & div {
    max-width: 70% !important;
  }
  &:nth-child(2) {
    white-space: nowrap;
  }
  
`;

export const Image = styled.img`
  width: 100%;
  //margin-top: 16px;
`;


export const StepSettingsContainer = styled.div`
  overflow-y: auto;
  
`;

export const StepPartnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  .input {
    margin-left: 16px;
    padding: 4px 8px 0 8px;
  }
`;

export const RadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .ant-radio-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    span:first-child {
      margin-right: 4px;
    };
   span:last-child {
     display: flex;
     flex-direction: row;
     align-items: center;
   }
  }
  .ant-select-selection--multiple {
    min-width: 250px;
  }
`;

export const StepMainContainer = styled.div`
  overflow-y: auto;
  
`;
export const StepGamesContainer = styled.div`
  overflow-y: auto;
  
`;

export const StepTermContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
`;

export const FormItemsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 1em;
  width: 100%;
`;


export const InputDateWrapper = styled.div`
  display: flex;
  
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
