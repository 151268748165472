import React, { lazy } from 'react';
import { Routes } from './routesData';
import MainLayout from '../layouts/MainLayout/MainLayout';
import Spinner from "../containers/LoyaltySystem/Tournaments/modals/components/Spinner";
import { DashboardContainer } from '../layouts/DashboardContainer'

const NotFound = lazy(() => import('../containers/Page/404'));
const ServerError = lazy(() => import('../containers/Page/forgotPassword'));
const ResetPass = lazy(() => import('../containers/Page/resetPassword'));
const SetPartnerPass = lazy(() => import('../containers/Page/setPartnerPassword'));
const SignIn = lazy(() => import('../containers/Page/signin'));
const SignUp = lazy(() => import('../containers/Page/signup'));
const PartnerPassHash = lazy(() => import('../containers/Page/setPartnerPassword'));
const PartnersWebsite = lazy(() => import('../containers/Partner/Websites/List'));
const PartnersPartners = lazy(() => import('../containers/Partner/Partners'));
const Dashboard = lazy(() => import('../containers/Dashboard'));
const RTMBets = lazy(() => import('../containers/RealTimeMonitor/Bets'));
const RTMDeposits = lazy(() => import('../containers/RealTimeMonitor/Deposits'));
const RTMCasino = lazy(() => import('../containers/RealTimeMonitor/Casino'));
const RTMWithdrawal = lazy(() => import('../containers/RealTimeMonitor/Withdrawals'));
const RTMDocuments = lazy(() => import('../containers/RealTimeMonitor/Documents'));
const RTMRisk = lazy(() => import('../containers/RealTimeMonitor/RiskManagement'));
const StaffPersonnel = lazy(() => import('../containers/StaffManagement/List'));
const PersonnelInner = lazy(() => import('../containers/CustomerManagement/UserInfo'));
const StaffRoles = lazy(() => import('../containers/StaffManagement/Roles'));
const UsersList = lazy(() => import('../containers/CustomerManagement'));
const UserInner = lazy(() => import('../containers/CustomerManagement/UserInfo'));
const UserGroups = lazy(() => import('../containers/CustomerManagement/UserGroups'));
const Bonuses = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/Bonuses'));
const FreeSpins = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/FreeSpins'));
const FreeBets = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/FreeBets'));
const MassBonus = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/MassBonus'));
const WelcomeBonus = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/WelcomeBonus'));
const CashbackUsers = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/CashbackUsers'));
const CasinoCashback = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/CasinoCashback'));
const Tournaments = lazy(() => import('../containers/LoyaltySystem/Tournaments'));
const CasinoList = lazy(() => import('../containers/Casino/List'));
const GameCategories = lazy(() => import('../containers/Casino/GameCategories'));
const CustomProviders = lazy(() => import('../containers/Casino/CustomProviders'));
const CasinoGames = lazy(() => import('../containers/Casino/Games'));
const CasinoIntegrators = lazy(() => import('../containers/Casino/Integrators'));
const SportCategories = lazy(() => import('../containers/Sport/Categories/Sports/Tree'));
const SportEvents = lazy(() => import('../containers/Sport/Events'));
const EventDetails = lazy(() => import('../containers/Sport/Events/EventDetails'));
const SportResults = lazy(() => import('../containers/Sport/Results'));
const ResultDetails = lazy(() => import('../containers/Sport/Results/ResultDetails'));
const SportLiveStatisticTypes = lazy(() => import('../containers/Sport/LiveStatisticTypes'));
const SportMarketTemplate = lazy(() => import('../containers/Sport/Markets'));
const SportMarketsGroups = lazy(() => import('../containers/Sport/Markets/MarketsGroups'));
const SportMarketsStatisticTypes = lazy(() => import('../containers/Sport/Markets/StatisticTypes/StatisticTypesList'));
const SportMarketsDisplayTypes = lazy(() => import('../containers/Sport/Markets/MarketsDisplayTypes'));
const SportParticipants = lazy(() => import('../containers/Sport/Participants'));
const SportParticipantsAges = lazy(() => import('../containers/Sport/Participants/ParticipantAges'));
const SportBettingRules = lazy(() => import('../containers/Sport/Settings/BettingRules/List'));
const SportScopes = lazy(() => import('../containers/Sport/Settings/Periods/List'));
const Ladders = lazy(() => import('../containers/Sport/Markets/MarketsLadders'));
const SidebarEditor = lazy(() => import('../containers/Settings/SidebarEditor'));
const SMSGateways = lazy(() => import('../containers/Settings/SMS/SMSGateways'));
const SMSTemplates = lazy(() => import('../containers/Settings/SMS/SMSTemplates'));
const Currencies = lazy(() => import('../containers/Settings/Currencies/List'));
const PaymentsList = lazy(() => import('../containers/Settings/Payments/List'));
const PaymentPayways = lazy(() => import('../containers/Settings/Payments/PaywaysList'));
const SecurityQuestions = lazy(() => import('../containers/Settings/Security/Questions/List'));
const BlockedIps = lazy(() => import('../containers/Settings/Security/BlockedIps'));
const Whitelist = lazy(() => import('../containers/Settings/Security/Whitelist'));
const CmsHeader = lazy(() => import('../containers/Cms/Menu/Header'));
const CmsFooter = lazy(() => import('../containers/Cms/Menu/Footer'));
const CmsArticles = lazy(() => import('../containers/Cms/Menu/Articles'));
const CmsSportsPriorities = lazy(() => import('../containers/Cms/LandingPage/SportsPriorities'));
const CmsSpecialOffers = lazy(() => import('../containers/Cms/LandingPage/SpecialOffers'));
const CmsUpcomingEvents = lazy(() => import('../containers/Cms/LandingPage/UpcomingEvents'));
const CmsMatchesOfDay = lazy(() => import('../containers/Cms/LandingPage/MatchesOfDay'));
const CmsGameGrid = lazy(() => import('../containers/Cms/LandingPage/GameGrid'));
const CmsInfoPages = lazy(() => import('../containers/Cms/Pages/InfoPages/InfoPagesList'));
const CmsInfoPage = lazy(() => import('../containers/Cms/Pages/InfoPages/InfoPage'));
const CmsNewsList = lazy(() => import('../containers/Cms/Pages/News/NewsList'));
const NewsArticle = lazy(() => import('../containers/Cms/Pages/News/NewsArticle'));
const CmsPromotionList = lazy(() => import('../containers/Cms/Pages/Promotions/PromotionList'));
const CmsPromotion = lazy(() => import('../containers/Cms/Pages/Promotions/PromotionPage'));
const CmsHelpCenter = lazy(() => import('../containers/Cms/Pages/HelpCenter'));
const CmsBannersList = lazy(() => import('../containers/Cms/Banners/List'));
const CmsBannersGroups = lazy(() => import('../containers/Cms/Banners/Groups/List'));
const SportsPriorities = lazy(() => import('../containers/Cms/LandingPage/SportsPriorities/Events'));
const ReportByPlayer = lazy(() => import('../containers/Reports/ReportByPlayer'));
const ReportByGame = lazy(() => import('../containers/Reports/ReportByGame'));
const ReportByProvider = lazy(() => import('../containers/Reports/ReportByProvider'));
const ReportByIntegrator = lazy(() => import('../containers/Reports/ReportByIntegrator'));
const Transactions = lazy(() => import('../containers/Reports/Transactions'));
const NotificationsPush = lazy(() => import('../containers/NotificationCenter/Notifications'));
const NotificationsMessages = lazy(() => import('../containers/NotificationCenter/Messages'));
const SMSListing = lazy(() => import('../containers/NotificationCenter/SMSListing/List'));
const EmailList = lazy(() => import('../containers/NotificationCenter/Emails/Emails/List'));
const EmailTemplates = lazy(() => import('../containers/NotificationCenter/Emails/EmailTemplates/List'));
const Affiliates = lazy(() => import('../containers/Affiliates'));

interface AsyncPrivateProps {
	route: Routes;
}

export const AsyncPublic: React.FC<AsyncPrivateProps> = (props) => {
	const { route } = props;

	switch (route) {
	case Routes.notFound: {
		return (
			<React.Suspense fallback={<Spinner size="large" />}>
				<NotFound />
			</React.Suspense>
		);
	}
	case Routes.serverError: {
		return (
			<React.Suspense fallback={<Spinner size="large" />}>
				<ServerError />
			</React.Suspense>
		);
	}
	case Routes.resetPassword: {
		return (
			<React.Suspense fallback={<Spinner size="large" />}>
				<ResetPass />
			</React.Suspense>
		);
	}
	case Routes.setPartnerPassword: {
		return (
			<React.Suspense fallback={<Spinner size="large" />}>
				<SetPartnerPass />
			</React.Suspense>
		);
	}
	case Routes.signup: {
		return (
			<React.Suspense fallback={<Spinner size="large" />}>
				<SignUp />
			</React.Suspense>
		);
	}
	case Routes.signin: {
		return (
			<React.Suspense fallback={<Spinner size="large" />}>
				<SignIn />
			</React.Suspense>
		);
	}
	case Routes.partnerHash: {
		return (
			<React.Suspense fallback={<Spinner size="large" />}>
				<PartnerPassHash />
			</React.Suspense>
		);
	}

	default: {
		return <div />;
	}
	}
};

export const AsyncPrivate = (props) => {

	const { route } = props;
	const renderContent = (route) => {
		switch (route) {
		case Routes.dashboard: {
				return (
					<DashboardContainer >
						<React.Suspense fallback={<Spinner size="large" />}>
							<Dashboard />
						</React.Suspense>
					</DashboardContainer>
				);
		}
		case Routes.partnersWebsite: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<PartnersWebsite />
				</React.Suspense>
			);
		}
		case Routes.partnersPartner: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<PartnersPartners />
				</React.Suspense>
			);
		}
		case Routes.rtmBets: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<RTMBets />
				</React.Suspense>
			);
		}
		case Routes.rtmDeposits: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<RTMDeposits />
				</React.Suspense>
			);
		}
		case Routes.rtmWithdrawals: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<RTMWithdrawal />
				</React.Suspense>
			);
		}
		case Routes.rtmCasino: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<RTMCasino />
				</React.Suspense>
			);
		}
		case Routes.rtmDocuments: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<RTMDocuments />
				</React.Suspense>
			);
		}
		case Routes.rtmRiskAlerts: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<RTMRisk />
				</React.Suspense>
			);
		}
		case Routes.staffPersonnel: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<StaffPersonnel />
				</React.Suspense>
			);
		}
		case Routes.staffInner: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<PersonnelInner />
				</React.Suspense>
			);
		}
		case Routes.staffRoles: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<StaffRoles />
				</React.Suspense>
			);
		}
		case Routes.usersList: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<UsersList />
				</React.Suspense>
			);
		}
		case Routes.userInner: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<UserInner />
				</React.Suspense>
			);
		}
		case Routes.usersGroups: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<UserGroups />
				</React.Suspense>
			);
		}
		case Routes.bonuses: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<Bonuses />
				</React.Suspense>
			);
		}
		case Routes.freeSpins: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<FreeSpins />
				</React.Suspense>
			);
		}
		case Routes.freeBets: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<FreeBets />
				</React.Suspense>
			);
		}
		case Routes.massBonus: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<MassBonus />
				</React.Suspense>
			);
		}
		case Routes.welcome: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<WelcomeBonus />
				</React.Suspense>
			);
		}
		case Routes.cashback: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CasinoCashback />
				</React.Suspense>
			);
		}
		case Routes.usersWithCashback: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CashbackUsers />
				</React.Suspense>
			);
		}
		case Routes.tournaments: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<Tournaments />
				</React.Suspense>
			);
		}
		case Routes.casinoList: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CasinoList />
				</React.Suspense>
			);
		}
		case Routes.casinoGameCategories: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<GameCategories />
				</React.Suspense>
			);
		}
		case Routes.casinoCustomProviders: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CustomProviders />
				</React.Suspense>
			);
		}
		case Routes.casinoGames: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CasinoGames />
				</React.Suspense>
			);
		}
		case Routes.casinoIntegrators: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CasinoIntegrators />
				</React.Suspense>
			);
		}
		case Routes.sportCategories: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportCategories />
				</React.Suspense>
			);
		}
		case Routes.sportEvents: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportEvents />
				</React.Suspense>
			);
		}
		case Routes.sportEventsInner: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<EventDetails />
				</React.Suspense>
			);
		}
		case Routes.sportResults: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportResults />
				</React.Suspense>
			);
		}
			case Routes.sportResultsInner: {
				return (
					<React.Suspense fallback={<Spinner size="large" />}>
						<ResultDetails />
					</React.Suspense>
				);
			}
		case Routes.sportLiveStatisticTypes: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportLiveStatisticTypes />
				</React.Suspense>
			);
		}
		case Routes.sportTemplates: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportMarketTemplate />
				</React.Suspense>
			);
		}
		case Routes.sportGroups: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportMarketsGroups />
				</React.Suspense>
			);
		}
		case Routes.sportMarketsStatisticTypes: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportMarketsStatisticTypes />
				</React.Suspense>
			);
		}
		case Routes.sportDisplayTypes: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportMarketsDisplayTypes />
				</React.Suspense>
			);
		}
		case Routes.sportParticipants: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportParticipants />
				</React.Suspense>
			);
		}
		case Routes.sportAges: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportParticipantsAges />
				</React.Suspense>
			);
		}
		case Routes.sportBettingRules: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportBettingRules />
				</React.Suspense>
			);
		}
		case Routes.sportScopes: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportScopes />
				</React.Suspense>
			);
		}
		case Routes.sportLadders: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<Ladders />
				</React.Suspense>
			);
		}
		case Routes.settingsEditor: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SidebarEditor />
				</React.Suspense>
			);
		}
		case Routes.settingsSmsGateway: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SMSGateways />
				</React.Suspense>
			);
		}
		case Routes.settingsTemplates: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SMSTemplates />
				</React.Suspense>
			);
		}
		case Routes.settingsCurrencies: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<Currencies />
				</React.Suspense>
			);
		}
		case Routes.settingsPayments: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<PaymentsList />
				</React.Suspense>
			);
		}
		case Routes.settingsPaymentPayways: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<PaymentPayways />
				</React.Suspense>
			);
		}
		case Routes.settingsQuestions: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SecurityQuestions />
				</React.Suspense>
			);
		}
		case Routes.settingsBlockedIPs: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<BlockedIps />
				</React.Suspense>
			);
		}
		case Routes.settingsWhitelist: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<Whitelist />
				</React.Suspense>
			);
		}
		case Routes.cmsHeader: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsHeader />
				</React.Suspense>
			);
		}
		case Routes.cmsFooter: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsFooter />
				</React.Suspense>
			);
		}
		case Routes.cmsArticles: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsArticles />
				</React.Suspense>
			);
		}
		case Routes.cmsPriorities: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsSportsPriorities />
				</React.Suspense>
			);
		}
		case Routes.cmsSpecialOffers: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsSpecialOffers />
				</React.Suspense>
			);
		}
		case Routes.cmsUpcoming: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsUpcomingEvents />
				</React.Suspense>
			);
		}
		case Routes.cmsMatchesOfDay: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsMatchesOfDay />
				</React.Suspense>
			);
		}
		case Routes.cmsLandingPageGameGrid: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsGameGrid />
				</React.Suspense>
			);
		}
		case Routes.cmsInfoPages: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsInfoPages />
				</React.Suspense>
			);
		}
		case Routes.cmsInfoPagesInner: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsInfoPage />
				</React.Suspense>
			);
		}
		case Routes.cmsNews: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsNewsList />
				</React.Suspense>
			);
		}
		case Routes.newsArticlePage: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<NewsArticle />
				</React.Suspense>
			);
		}
		case Routes.cmsPromotions: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsPromotionList />
				</React.Suspense>
			);
		}
		case Routes.cmsPromotionsInner: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsPromotion />
				</React.Suspense>
			);
		}
		case Routes.cmsPromotionsAddNew: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsPromotion />
				</React.Suspense>
			);
		}
		case Routes.cmsHelpCenter: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsHelpCenter />
				</React.Suspense>
			);
		}
		case Routes.cmsBanners: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsBannersList />
				</React.Suspense>
			);
		}
		case Routes.cmsBannersGroup: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<CmsBannersGroups />
				</React.Suspense>
			);
		}
		case Routes.cmsSportPriorities: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SportsPriorities />
				</React.Suspense>
			);
		}
		case Routes.reportsByPlayer: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<ReportByPlayer />
				</React.Suspense>
			);
		}
		case Routes.reportsByGame: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<ReportByGame />
				</React.Suspense>
			);
		}
		case Routes.reportsByProvider: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<ReportByProvider />
				</React.Suspense>
			);
		}
		case Routes.reportsByIntegrator: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<ReportByIntegrator />
				</React.Suspense>
			);
		}
		case Routes.reportsTransactions: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<Transactions />
				</React.Suspense>
			);
		}
		case Routes.notificationPush: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<NotificationsPush />
				</React.Suspense>
			);
		}
		case Routes.notificationMessages: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<NotificationsMessages />
				</React.Suspense>
			);
		}
		case Routes.notificationSMS: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<SMSListing />
				</React.Suspense>
			);
		}
		case Routes.notificationEmails: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<EmailList />
				</React.Suspense>
			);
		}
		case Routes.notificationTemplates: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<EmailTemplates />
				</React.Suspense>
			);
		}
		case Routes.affiliates: {
			return (
				<React.Suspense fallback={<Spinner size="large" />}>
					<Affiliates />
				</React.Suspense>
			);
		}

		default: {
			return <div />;
		}
		}
	};

	return (
		<MainLayout>
			{renderContent(route)}
		</MainLayout>
	);
};

