import styled  from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;
export const ContainerUnique = styled.div`
    height: 100px;
    color: red;
    width: 100px;
    background-color: #b91a1a;
`;

export const MainContentWrapper = styled(Content)`
  
`;


export const MainOutlet = styled.div`
    //padding: 16px ;
`;

export const LayoutPublic =styled.div`
	
`
