import { normalize, schema } from 'normalizr';
import isArray from 'lodash/isArray';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { getHeadersTotalCount } from '../../../../helpers/utils';

export const fields = {
	id         : 'id',
	statusID   : 'status_id',
	count      : 'max_banner_count',
	websiteID  : 'website_id',
	name       : 'name',
	isBaseGroup: 'is_base_group',
};

const listAdapter = createListAdapter();

export function getListParams(pagination, sorting = null) {
	const builder = new ParamsBuilder();

	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	builder.addValue('limit', pagination.itemsPerPage);
	builder.addValue('page', pagination.currentPage);

	const params = builder.biuldParams({});

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptBannerGroupsList(rawData = [], responseHeaders) {

	if (!isArray(rawData)) {
		return {
			entities  : {},
			totalCount: 0,
		};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	const tempData = {
		items: adaptedData,
	};

	const item = new schema.Entity('items', {}, { idAttribute: 'id' });
	const itemsSchema = { items: [item] };

	const normalizedData = normalize(tempData, itemsSchema);

	const listIDs  = normalizedData.result.items   || [];
	const entities = normalizedData.entities.items || {};

	const totalCount = getHeadersTotalCount(responseHeaders) || listIDs.length;

	return {
		entities,
		totalCount,
	};
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'statusID', fields.statusID);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'websiteID', fields.websiteID);

	adapter.addField(rules.positiveNumber, 'count', fields.count);

	adapter.addField(rules.bool, 'isBaseGroup', fields.isBaseGroup);

	return adapter;
}
