import { makeActionCreator } from '../../helpers';

const prefix = 'REPORTS_GAMES';

const TYPES = {
	LIST_RELOAD    			: `${prefix}_LIST_RELOAD`,
	LIST_REFRESH    		: `${prefix}_LIST_REFRESH`,
	FILTER_REFRESH  		: `${prefix}_FILTER_REFRESH`,
	FILTER_APPLY    		: `${prefix}_FILTER_APPLY`,
	PAGINATION_REFRESH: `${prefix}_PAGINATION_REFRESH`,
	DATA_RESEND    			: `${prefix}_DATA_RESEND`,
	UI_REFRESH     			: `${prefix}_UI_REFRESH`,
	DATA_RELOAD    			: `${prefix}_DATA_RELOAD`,
	DATA_REFRESH    		: `${prefix}_DATA_REFRESH`,
	FIELDS_REFRESH  		: `${prefix}_FIELDS_REFRESH`,
};


const actions = {
	...TYPES,

	listReload    			: makeActionCreator(TYPES.LIST_RELOAD),
	listRefresh   			: makeActionCreator(TYPES.LIST_REFRESH, 'list'),
	filterRefresh  		: makeActionCreator(TYPES.FILTER_REFRESH, 'filter'),
	filterApply   			: makeActionCreator(TYPES.FILTER_APPLY),
	paginationRefresh: makeActionCreator(TYPES.PAGINATION_REFRESH, 'pagination'),
	uiRefresh     			: makeActionCreator(TYPES.UI_REFRESH, 'UI'),
	dataReload    			: makeActionCreator(TYPES.DATA_RELOAD, 'useUserFilter', 'listBy'),
	dataRefresh   			: makeActionCreator(TYPES.DATA_REFRESH, 'reportData'),
	fieldsRefresh  		: makeActionCreator(TYPES.FIELDS_REFRESH, 'reportFields'),
};

export default actions;
