import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { websitesAPI } from '../../../helpers/api/websites';
import notifications from '../../../helpers/notifications';

import actions from './actions';
import { adaptLanguages } from './utils';


const prefix = 'currency';

const messages = {
	errorLanguageListReload: `${prefix}.errorLanguageListReload`,

};

// Currency ---------------------------------------------------------------------------------------
function* languageListReload() {

	yield takeEvery(actions.LANGUAGE_RELOAD_LIST, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		try {
			const res = yield call(websitesAPI.allLanguages);
			if (res && res.status === 200) {
				const adapted = adaptLanguages(res.data.data);
				yield put(actions.currencyListRefresh(adapted));
			}

		} catch (error) {
			notifications.showError(messages.errorCurrencyLoad);
			console.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));

	});
}

export default function* currenciesSaga() {
	yield all([
		fork(languageListReload),
	]);
}
