import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	baseData    : {},
	currencyData: [],
	namesData   : [],

	UI: {
		visible          : false,
		paymentID        : null,
		loading          : false,
		closeModal       : false,
		isBaseChanged    : false,
		isCurrencyChanged: false,
		isNamesChanged   : false,
		langID           : null,
		paymentID_new    : null,
	},
});

export default function paymentsModalReducer(state = initState, action) {

	switch (action.type) {

	case actions.PAYMENT_BASE_DATA_REFRESH: {
		const target = state.get('baseData');
		const result = fill(action.data, target, true);
		return state.set('baseData', result);
	}

	case actions.PAYMENT_CURRENCY_DATA_REFRESH: {
		return state.set('currencyData', action.data);
	}

	case actions.PAYMENT_NAMES_DATA_REFRESH: {
		return state.set('namesData', action.data);
	}

	case actions.PAYMENT_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.PAYMENT_DATA_RESET: {
		return initState;
	}

	default:
		return state;
	}
}
