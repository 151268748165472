import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	filter     : {},
	countryList: [],
	leagueList : [],
});

export default function resultsFilterReducer(state = initState, action) {
	switch (action.type) {
	case actions.RESULTS_FILTER_REFRESH: {
		const target = state.get('filter');
		const result = fill(action.data, target, true);
		return state.set('filter', result);
	}

	case actions.RESULTS_FILTER_COUNTRY_LIST_REFRESH: {
		return state.set('countryList', action.data);
	}

	case actions.RESULTS_FILTER_LEAGUE_LIST_REFRESH: {
		return state.set('leagueList', action.data);
	}

	default:
		return state;
	}
}
