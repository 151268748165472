import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import notifications from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { adaptList } from '../freeBets/utils';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { getListParams } from './utils';

const messages = {
	errorListReload: 'list.load.fail',
};

function getStoreData({ Loyalty: { Bonuses } }) {
	const { UserFreeBets } = Bonuses;

	return {
		pagination: UserFreeBets.get('pagination'),
	};
}

function* listReload() {
	yield takeEvery(actions.LIST_RELOAD, function* (action) {
		yield put(actions.uiMerge({ loading: true }));

		const { pagination } = yield select(getStoreData);
		const filter = action.data;
		const params = getListParams(filter, pagination);
		let entities = {};
		let totalCount = 0;

		try {
			const res = yield call(bonusesAPI.freeBetsList, params);
			if (res && res.status === 200) {
				entities   = adaptList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);
			}
		} catch (error) {
			notifications.showError(messages.errorListReload);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.paginationRefresh({ totalCount }));
		yield put(actions.uiMerge({ loading: false }));

	});
}

export default function* userFreeBetsSagas() {
	yield all([
		fork(listReload),
	]);
}
