export const liveStatisticTypesFilterActions = {

	LST_FILTER_SET_VALUE: 'LST_FILTER_SET_VALUE',
	LST_FILTER_REFRESH  : 'LST_FILTER_REFRESH',
	LST_CLEAR_FILTERS   : 'LST_CLEAR_FILTERS',

	clearFilters: () => ({
		type: liveStatisticTypesFilterActions.LST_CLEAR_FILTERS,
	}),

	filterSetValue: (valueName, value) => ({
		type: liveStatisticTypesFilterActions.LST_FILTER_SET_VALUE,
		data: {
			valueName,
			value,
		},
	}),
	filterRefresh: data => ({
		type: liveStatisticTypesFilterActions.LST_FILTER_REFRESH,
		data,
	}),
};

