import { apiRequest } from './index';

// Payments ---------------------------------------------------------------------------------------

function paymentsList(websiteID, params = {}) {
	const req = {
		method: 'GET',
		url   : `/websites/${websiteID}/payments/list`,
		//withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function paymentInfo(paymentID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/payments/${paymentID}`,
		//withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function paymentUpdate(paymentID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/payments/${paymentID}`,
		data,
		params,
	};

	return apiRequest(req );
}

function paymentNamesList(paymentID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/payments/${paymentID}/names`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function paymentNamesUpdate(paymentID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/payments/${paymentID}/names`,
		data,
		params,
	};

	return apiRequest(req);
}

// Payways ----------------------------------------------------------------------------------------

function paywaysListRefresh(paymentID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/payments/${paymentID}/payways/refresh`,
		params,
	};

	return apiRequest(req);
}

function paywaysList(paymentID, params = {}) {

	const req = {
		method: 'GET',
		url   : '/payways',
		params: {
			...params,
			payment_id: paymentID,
		},
	};

	return apiRequest(req);
}

function paywayNamesList(paymentID, paywayID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/payments/${paymentID}/payways/${paywayID}`,
		params,
	};

	return apiRequest(req);
}

function paywayNamesUpdate(paymentID, paywayID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/payments/${paymentID}/payways/${paywayID}`,
		data,
		params,
	};

	return apiRequest(req);
}

export const paymentsAPI = {
	paymentsList,
	paymentInfo,
	paymentUpdate,
	paymentNamesList,
	paymentNamesUpdate,

	paywaysListRefresh,
	paywaysList,
	paywayNamesList,
	paywayNamesUpdate,
};
