import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import { currencyAPI } from '../../../helpers/api/currency';
import { paymentsAPI } from '../../../helpers/api/payments';
import { historyAPI } from '../../../helpers/api/history';
import { getWebsiteID } from '../../../helpers/utility';
import { isID } from '../../../helpers/utils';
import notifications from '../../../helpers/notifications';

import actions from './actions';
import {
	convertToEntities,
	adaptRateList,
	adaptHistoryList,
	adaptPayments,
	preparePaymentsList,
	adaptWebsiteCurrencyList,
} from './utils';

const prefix = 'currency';

const messages = {
	errorCurrencyLoad    : `${prefix}.errorCurrencyLoad`,
	errorCurrencyRemove  : `${prefix}.errorCurrencyRemove`,
	errorCurrencyUpdate  : `${prefix}.errorCurrencyUpdate`,
	successCurrencyUpdate: `${prefix}.successCurrencyUpdate`,
	errorRatesLoad       : `${prefix}.errorRatesLoad`,
	errorRatesUpdate     : `${prefix}.errorRatesUpdate`,
	successRatesUpdate   : `${prefix}.successRatesUpdate`,
	errorPaymentsLoad    : `${prefix}.errorPaymentsLoad`,
	errorPaywaysLoad     : `${prefix}.errorPaywaysLoad`,
	errorPaymentsUpdate  : `${prefix}.errorPaymentsUpdate`,
	successPaymentsUpdate: `${prefix}.successPaymentsUpdate`,
	errorHistoryLoad     : `${prefix}.errorHistoryLoad`,
};

function getStoreData({ Settings, App }) {

	return {
		currencyList    : Settings.CurrencyModule.get('list'),
		currencyEntities: Settings.CurrencyModule.get('entities'),
		paymentsList    : Settings.CurrencyModule.get('paymentsList'),
		websiteID       : App.get('websiteID'),
	};
}

// Currency ---------------------------------------------------------------------------------------
function* currencyListReload() {

	yield takeEvery(actions.CURRENCY_RELOAD_LIST, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		let currencyList = [];
		let entities     = {};
		const params     = {
			unlimit            : true,
			default_currency_id: 1,
		};

		try {
			const res = yield call(currencyAPI.currencyList, params);
			if (res && res.status === 200) {
				currencyList = res.data.data;
				entities     = convertToEntities(currencyList);
			}

		} catch (error) {
			notifications.showError(messages.errorCurrencyLoad, error);
			console.log(error);
		}

		yield put(actions.currencyListRefresh(currencyList));
		yield put(actions.currencyRefreshEntities(entities));
		yield put(actions.changedIDsReset());
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* websiteCurrencyListReload() {

	yield takeEvery(actions.WEBSITE_CURRENCY_RELOAD_LIST, function* () {
		yield put(actions.modalUIRefresh({ loading: true }));
		const { websiteID } = yield select(getStoreData);
		let currencyList    = [];
		try {
			const res = yield call(currencyAPI.currencyListByWebsite, websiteID);
			if (res && res.status === 200) {
				currencyList = adaptWebsiteCurrencyList(res.data.data);
			}

		} catch (error) {
			notifications.showError(messages.errorCurrencyLoad, error);
			console.log(error);
		}

		yield put(actions.websiteCurrencyListRefresh(currencyList));
		yield put(actions.modalUIRefresh({ loading: false }));

	});
}

function* currencyListRemoveItem() {

	yield takeEvery(actions.CURRENCY_LIST_REMOVE_ITEM, function* (action) {

		const itemID = action.data;

		// delete currency from DB
		if (isID(itemID)) {
			try {
				const axiosResponse = yield call(currencyAPI.currencyDelete, itemID);

				if (axiosResponse && axiosResponse.status === 200) {
					yield put(actions.currencyListReload());

				} else {
					notifications.showError(messages.errorCurrencyRemove);
				}
			} catch (error) {
				notifications.showError(messages.errorCurrencyRemove, error);
				console.log(error);
			}

			// just reload list
		} else {
			yield put(actions.currencyListReload());
		}
	});
}

function* currencyUpdateItem() {

	yield takeEvery(actions.CURRENCY_UPDATE_ITEM, function* (action) {
		const item     = action.data;
		const itemID   = item.id;
		let updateMode = true;

		// prepare data for saving
		if (!isID(itemID)) {
			item.id    = null;
			updateMode = false;
		}

		try {
			const axiosResponse = updateMode
				? yield call(currencyAPI.currencyUpdate, itemID, item)
				: yield call(currencyAPI.currencyAdd, item);

			if (axiosResponse && axiosResponse.status === 200) {
				notifications.showSuccess(messages.successCurrencyUpdate);

				// reload list
				yield put(actions.currencyListReload());

			} else {
				notifications.showError(messages.errorCurrencyUpdate);
			}
		} catch (error) {
			notifications.showError(messages.errorCurrencyUpdate, error);
			console.log(error);
		}
	});
}

// Rates ------------------------------------------------------------------------------------------
function* currencyReloadRatesList() {

	yield takeEvery(actions.CURRENCY_RELOAD_RATES_LIST, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { currencyID, currencyList } = action.data;

		try {
			const axiosResponse = yield call(currencyAPI.exchangeRatesList, currencyID);

			if (axiosResponse && axiosResponse.status === 200) {
				const rateList = adaptRateList(axiosResponse.data.data, currencyID, currencyList);
				yield put(actions.currencyRefreshRatesList(currencyID, rateList));

			} else {
				notifications.showError(messages.errorRatesLoad);
			}
		} catch (error) {
			notifications.showError(messages.errorRatesLoad, error);
			console.log(error);
		}

		yield put(actions.modalUIRefresh({
			loading  : false,
			isChanged: false,
		}));
	});
}

function* currencyUpdateRatesList() {

	yield takeEvery(actions.CURRENCY_UPDATE_RATES_LIST, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));
		const { currencyID, ratesList, withClose } = action.data;

		let isError = false;
		try {
			yield call(currencyAPI.exchangeRatesUpdate, currencyID, ratesList);
			yield put(actions.modalUIRefresh({ isChanged: false }));
			yield put(actions.historyListReload(currencyID));

			notifications.showSuccess(messages.successRatesUpdate);

		} catch (error) {
			isError = true;
			notifications.showError(messages.errorRatesUpdate, error);
		}

		yield put(actions.modalUIRefresh({
			currencyID,
			loading: false,
			visible: !(
				withClose && !isError
			),
		}));
	});
}

// Payments ---------------------------------------------------------------------------------------
function* paymentsListReload() {

	yield takeEvery(actions.CURRENCY_PAYMENTS_LIST_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { currencyID } = action.data;
		const params         = { currency_id: currencyID };

		let paymentsList = [];
		let paywaysList  = [];
		let resultList   = [];
		let errorMessage = '';
		const websiteID = getWebsiteID();

		try {
			// payments
			errorMessage      = messages.errorPaymentsLoad;
			const paymentsRes = yield call(paymentsAPI.paymentsList, websiteID);
			if (paymentsRes && paymentsRes.status === 200) {
				paymentsList = paymentsRes.data.data;
			}

			// payways
			errorMessage     = messages.errorPaywaysLoad;
			const paywaysRes = yield call(currencyAPI.currencyPaywaysList, params);
			if (paywaysRes && paywaysRes.status === 200) {
				paywaysList = paywaysRes.data.data;
			}

			// combine data
			resultList = yield call(adaptPayments, paymentsList, paywaysList);
		} catch (error) {
			notifications.showError(errorMessage, error);
			console.log(error);
		}

		yield put(actions.paymentsListRefresh(resultList));
		yield put(actions.modalUIRefresh({
			loading  : false,
			isChanged: false,
		}));
	});
}

function* paymentsListSave() {

	yield takeEvery(actions.CURRENCY_PAYMENTS_LIST_SAVE, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { paymentsList } = yield select(getStoreData);
		const { currencyID }   = action.data;

		try {
			const preparedData = preparePaymentsList(paymentsList, currencyID);
			const res          = yield call(currencyAPI.currencyPaywaysUpdate, preparedData);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successPaymentsUpdate);
			}
		} catch (error) {
			notifications.showError(messages.errorPaymentsUpdate, error);
			console.log(error);
		}

		yield put(actions.modalUIRefresh({
			loading  : false,
			isChanged: false,
		}));
	});
}

// History ----------------------------------------------------------------------------------------
function* historyListReload() {

	yield takeEvery(actions.CURRENCY_HISTORY_LIST_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { currencyEntities } = yield select(getStoreData);

		let historyList      = [];
		const { currencyID } = action.data;
		try {
			const res = yield call(historyAPI.historyCurrencyLogs, currencyID);
			if (res && res.status === 200) {
				historyList = adaptHistoryList(res.data.data, currencyEntities);
			}
		} catch (error) {
			notifications.showError(messages.errorHistoryLoad, error);
			console.log(error);
		}

		yield put(actions.historyListRefresh(historyList));
		yield put(actions.modalUIRefresh({ loading: false }));
	});
}

export default function* currenciesSaga() {
	yield all([
		fork(currencyListReload),
		fork(websiteCurrencyListReload),
		fork(currencyListRemoveItem),
		fork(currencyUpdateItem),

		fork(currencyReloadRatesList),
		fork(currencyUpdateRatesList),

		fork(paymentsListReload),
		fork(paymentsListSave),

		fork(historyListReload),
	]);
}
