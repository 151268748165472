import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	withdrawalIDs   : [],
	entities        : {},
	withdrawalTotals: {},

	newWithdrawalIDs     : [],
	newWithdrawalEntities: {},

	websiteList: [],

	filter: {},

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},

	UI: {
		loading: false,
	},

});

export default function rtmWithdrawalReducer(state = initState, action) {

	switch (action.type) {

	case actions.RTM_WITHDRAWALS_DATA_REFRESH: {
		const { withdrawalIDs, entities, withdrawalTotals } = action.data;
		return state
			.set('withdrawalIDs', withdrawalIDs)
			.set('entities', entities)
			.set('withdrawalTotals', withdrawalTotals);
	}

	case actions.RTM_WITHDRAWALS_NEW_ITEM_DATA_REFRESH: {
		const { newWithdrawalIDs, newWithdrawalEntities } = action.data;
		return state
			.set('newWithdrawalIDs', newWithdrawalIDs)
			.set('newWithdrawalEntities', newWithdrawalEntities);
	}

	// case actions.RTM_WITHDRAWALS_FILTER_RISK_GROUP_LIST_REFRESH: {
	//   return state.set('riskGroupList', action.data);
	// }

	case actions.RTM_WITHDRAWALS_FILTER_RESET: {
		return state.set('filter', {});
	}

	case actions.RTM_WITHDRAWALS_FILTER_REFRESH: {
		const target = state.get('filter');
		const result = fill(action.data, target, true);
		return state.set('filter', result);
	}

	case actions.RTM_WITHDRAWALS_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
