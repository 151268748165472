import { all, fork } from 'redux-saga/effects';

import userLimitsAndRestrictionsSaga from './userLimitsAndRestrictions/saga';
import userSMSsaga from './sendSMS/saga';
import responsibleGamblingSaga from './responsibleGambling/saga';

export default function* usersRootSaga() {
	yield all([
		fork(userLimitsAndRestrictionsSaga),
		fork(userSMSsaga),
		fork(responsibleGamblingSaga),
	]);
}
