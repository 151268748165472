import styled from 'styled-components';
import Tabs from "antd/es/tabs";

export const Wrapper = styled.div`
  position: sticky;
  top: 70px;
  z-index: 1000;
  background-color: #fff;
  .ant-tabs {
    padding: 0 6px;
    
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        padding: 8px 10px;
        
        .ant-tabs-tab-remove {
          visibility: hidden;
          margin-left: -2px;
        }

        &:hover {
          .ant-tabs-tab-remove {
            visibility: visible;
          }
        }
      }
    }
  }

  .ant-tabs-nav {
    margin: 2px;
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  margin-right: 8px;
  margin-top: 1px;

  .anticon.anticon-close {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const Content = styled.div`

  //.ant-tabs {
  //  .ant-tabs-bar {
  //    margin-bottom: 8px;
  //
  //    .ant-tabs-tab {
  //      margin: 0 2px 0 0;
  //      padding: 5px 12px 4px;
  //
  //      &.ant-tabs-tab-active {
  //        background: transparent;
  //        border-bottom: 2px solid #4482FF;
  //        padding-bottom: 2px;
  //      }
  //    }
  //  }
  //  .ant-tabs-content {
  //    height: auto;
  //    overflow-y: visible !important;
  //  }
  //}
`;
Tabs
