import { all, takeEvery, put, fork, select, call } from 'redux-saga/effects';
import actions from './actions';

import { showError, showSuccess } from '../../../../helpers/notifications';
import { CONTENT_GAMES_CATEGORY } from '../../../../helpers/commonConstants';

import {
	makeRequestDefaultGrid,
	makeRequestGamesList,
	makeRequestGridsSave,
} from './functions';
import {
	createGamesGridLayout,
	prepareGridLayouts,
	validateDefaultGrid,
} from './utils';

const prefix = 'landingpage.gamegrid';

const messages = {
	errorGridsLoad : `${prefix}.loading.grid.failed`,
	errorGamesLoad : `${prefix}.loading.list.failed`,
	errorGridsSave : `${prefix}.saving.grid.failed`,
	successGridSave: `${prefix}.grids.saved.success`,
};

function getStoreData({ CMS, App }) {

	const GamesGrids = CMS.LandingPage.GameGrid;

	return {
		defaultGrid   : GamesGrids.get('defaultGrid'),
		gamesList     : GamesGrids.get('gamesList'),
		categoryList  : GamesGrids.get('categoryList'),
		gridLayouts   : GamesGrids.get('gridLayouts'),
		changedLayouts: GamesGrids.get('changedLayouts'),
		channelID     : GamesGrids.get('UI').channelID,
		categoryID    : GamesGrids.get('UI').categoryID,
		gridMode      : GamesGrids.get('UI').gridMode,
		isGridsChanged: GamesGrids.get('UI').isGridsChanged,
		websiteID     : App.get('websiteID'),
	};
}

function* defaultGridReload() {

	yield takeEvery(actions.GAMES_GRIDS_DEFAULT_GRID_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { channelID } = yield select(getStoreData);
		try {
			const rawDefaultGrid = yield call(makeRequestDefaultGrid, channelID);
			const { defaultGrid, defaultGridLayout } = yield call(validateDefaultGrid, rawDefaultGrid, channelID);

			yield put(actions.defaultGridRefresh(defaultGrid));
			yield put(actions.gridLayoutSet(CONTENT_GAMES_CATEGORY.all, defaultGridLayout));
			yield put(actions.clearChangedLayouts());
			yield put(actions.uiRefresh({
				isGridsChanged: false,
				gridLoaded    : true,
			}));

		} catch (error) {
			showError(messages.errorGridsLoad);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}


function* gamesListReload() {

	yield takeEvery(actions.GAMES_GRIDS_GAMES_LIST_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { websiteID, categoryID, channelID } = action.data;
		const params = {
			channelID,
			categoryID,
		};
		try {
			const gamesList  = yield call(makeRequestGamesList, websiteID, params);
			const gridLayout = yield call(createGamesGridLayout, gamesList);

			yield put(actions.gamesListMerge(gamesList));
			yield put(actions.gridLayoutSet(categoryID, gridLayout));

		} catch (error) {
			showError(messages.errorGamesLoad);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}


function* gamesGridsSave() {

	yield takeEvery(actions.GAMES_GRIDS_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const {
			gridLayouts,
			changedLayouts,
			websiteID,
			channelID,
			categoryID,
			isGridsChanged,
		} = yield select(getStoreData);

		if (!isGridsChanged) {
			return;
		}
		const isDefaultGridChanged = changedLayouts.includes(CONTENT_GAMES_CATEGORY.all);

		const preparedGrids = prepareGridLayouts(gridLayouts, changedLayouts, websiteID, channelID);
		try {
			for (let i = 0; i < preparedGrids.length; i++) {
				const preparedData = preparedGrids[i];
				yield call(makeRequestGridsSave, preparedData);
			}

			showSuccess(messages.successGridSave);
			if (isDefaultGridChanged) {
				yield put(actions.defaultGridReload());
			}
			if (categoryID) {
				yield put(actions.gridLayoutsClear());
				yield put(actions.gamesListClear());
				yield put(actions.gamesListReload({
					websiteID,
					channelID,
					categoryID,
				}));
			}

			yield put(actions.clearChangedLayouts());
			yield put(actions.uiRefresh({ isGridsChanged: false }));

		} catch (error) {
			console.log(error);
			showError(messages.errorGridsSave, error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* gamesGridSaga() {
	yield all([
		fork(defaultGridReload),
		fork(gamesListReload),
		fork(gamesGridsSave),
	]);
}
