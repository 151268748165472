import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	entities   : {},
	documentIDs: [],

	dataModalInner: {},

	newDocumentIDs     : [],
	newDocumentEntities: {},

	filter: {},

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},

	UI: {
		loading     : false,
		visible     : false,
		modalLoading: false,
	},

});

export default (state = initState, action) => {

	switch (action.type) {

	case actions.RTM_DOCUMENTS_DATA_REFRESH: {
		const { documentIDs, entities } = action.data;
		return state
			.set('documentIDs', documentIDs)
			.set('entities', entities);
	}

	case actions.RTM_DOCUMENTS_NEW_DOCUMENT_LIST_REFRESH: {
		const { newDocumentIDs, newDocumentEntities } = action.data;
		return state
			.set('newDocumentIDs', newDocumentIDs)
			.set('newDocumentEntities', newDocumentEntities);
	}

	case actions.RTM_DOCUMENTS_PAGINATION_REFRESH: {
		const { pagination } = action.data;
		const target = state.get('pagination');
		const result = fill(pagination, target);
		return state.set('pagination', result);
	}

	case actions.BASE_DATA_REFRESH: {
		return state.set('baseData', action.data);
	}

	case actions.UI_REFRESH: {
		const UI  = action.data;
		const target = state.get('UI');
		const result = fill(UI, target);
		return state.set('UI', result);
	}

	case actions.DATA_MODAL_REFRESH: {
		return state.set('dataModalInner', action.data);
	}

	case actions.RTM_DOCUMENTS_FILTER_RESET: {
		const target = state.get('pagination');
		const result = fill({ currentPage: 1 }, target);
		return state
			.set('filter', {})
			.set('pagination', result);
	}

	case actions.RTM_DOCUMENTS_FILTER_REFRESH: {
		const target = state.get('filter');
		const result = fill(action.data, target, true);
		return state.set('filter', result);
	}

	case actions.DATA_RESET: {
		return initState;
	}

	default:
		return state;

	}
};
