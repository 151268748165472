const actions = {

	CASINO_LIST_RELOAD     : 'CASINO_LIST_RELOAD',
	CASINO_LIST_REFRESH    : 'CASINO_LIST_REFRESH',
	CASINO_LIST_DELETE_ITEM: 'CASINO_LIST_DELETE_ITEM',

	CASINO_LIST_UI_REFRESH: 'CASINO_LIST_UI_REFRESH',
	PROVIDERS_REORDER   		: 'PROVIDERS_REORDER',

	listReload: (data) => ({
		type: actions.CASINO_LIST_RELOAD,
		data,
	}),
	listRefresh: list => ({
		type: actions.CASINO_LIST_REFRESH,
		data: {
			list,
		},
	}),
	deleteItem: casinoID => ({
		type: actions.CASINO_LIST_DELETE_ITEM,
		data: {
			casinoID,
		},
	}),
	uiRefresh: data => ({
		type: actions.CASINO_LIST_UI_REFRESH,
		data,
	}),

	providersReordering: (oldIndex, newIndex ) => ({
		type: actions.PROVIDERS_REORDER,
		data: {
			oldIndex,
			newIndex,
		},
	}),
};

export default actions;
