import { normalize, schema } from 'normalizr';
import isArray from 'lodash/isArray';
import ParamsBuilder from '../../../helpers/paramsBuilder';
import EntityAdapter from '../../../helpers/entityAdapter';
import { getHeadersTotalCount } from '../../../helpers/utils';

export const fields = {
	id          : 'id',
	userID      : 'user_id',
	statusID    : 'status',
	createdDate : 'created',
	phone       : 'phone',
	senderID    : 'sender_id',
	gatewayID   : 'gateway',
	message     : 'message',
	sendDate    : 'date',
	deliveryDate: 'delivery_date',
	statusText  : 'details',
	subject     : 'subject',
	sender      : 'sender',

	sendDateFrom: 'date_from',
	sendDateTo  : 'date_to',

};

const listAdapter = createListAdapter();

export function getListParams(filter, pagination, sorting = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isString, 'statusID', fields.statusID);
	builder.addField(rules.isString, 'userID', fields.userID);
	builder.addField(rules.isString, 'sender', fields.sender);
	builder.addField(rules.isString, 'message', fields.message);
	builder.addRangeField(rules.isDateTimeRange, filter.sendDate, [
		fields.sendDateFrom,
		fields.sendDateTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.readDate, [
		fields.readDateFrom,
		fields.readDateTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptSMSList(rawData = [], responseHeaders) {
	if (!isArray(rawData)) {
		return {
			entities  : {},
			totalCount: 0,
		};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	const tempData = {
		items: adaptedData,
	};

	const item = new schema.Entity('items', {}, { idAttribute: 'id' });
	const itemsSchema = { items: [item] };

	const normalizedData = normalize(tempData, itemsSchema);

	const listIDs  = normalizedData.result.items   || [];
	const entities = normalizedData.entities.items || {};

	const totalCount = getHeadersTotalCount(responseHeaders) || listIDs.length;

	return {
		entities,
		totalCount,
	};
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'senderID', fields.senderID);
	adapter.addField(rules.id, 'gatewayID', fields.gatewayID);
	adapter.addField(rules.number, 'statusID', fields.statusID);
	adapter.addField(rules.string, 'phone', fields.phone);
	adapter.addField(rules.string, 'message', fields.message);
	adapter.addField(rules.string, 'statusText', fields.statusText);
	adapter.addField(rules.string, 'subject', fields.subject);
	adapter.addField(rules.string, 'sender', fields.sender);
	adapter.addField(rules.dateTime, 'createdDate', fields.createdDate);
	adapter.addField(rules.dateTime, 'sendDate', fields.sendDate);
	adapter.addField(rules.dateTime, 'deliveryDate', fields.deliveryDate);

	return adapter;
}
