import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import { fill } from '../../../../helpers/utils';
import { getHistoryTypeName } from '../betHistoryUtils/utils';
import actions from './actions';

const template = {
	id           : null,
	placeDateFrom: null,
	placeDateTo  : null,
};

const initState = new Map({

	toolbarData: {
		event    : { ...template },
		market   : { ...template },
		selection: { ...template },
	},

	UI: {
		loading: false,
	},
});

export default function betHistoryToolbarReducer(state = initState, action) {

	switch (action.type) {

	case actions.DATA_REFRESH: {
		const { betHistoryType, toolbarData } = action.data;

		const dataHolder  = cloneDeep(state.get('toolbarData'));
		const sectionName = getHistoryTypeName(betHistoryType);

		const target = dataHolder[sectionName];
		const result = fill(toolbarData, target);

		dataHolder[sectionName] = result;

		return state.set('toolbarData', dataHolder);
	}

	case actions.UI_REFRESH: {
		const { UI } = action.data;
		const target = state.get('UI');
		const result = fill(UI, target);
		return state.set('UI', result);
	}

	case actions.DATA_RESET: {
		return initState;
	}

	default:
		return state;
	}
}
