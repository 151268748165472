import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	smsGatewaysList: [],
	UI             : { loading: false },
});

export default function websiteModalSmsGatewaysReducer(state = initState, action) {

	switch (action.type) {
	case actions.WEBSITE_MODAL_SMS_GATEWAYS_REFRESH: {

		return state.set('smsGatewaysList', action.data);
	}

	case actions.WEBSITE_MODAL_SMS_GATEWAYS_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.RESET: {
		return initState;
	}

	default:
		return state;
	}
}
