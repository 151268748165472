import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';
import sortBy from 'lodash/sortBy';

import actions from './actions';

import { participantsAPI } from '../../../../helpers/api/participants';
import notifications from '../../../../helpers/notifications';

const prefix = 'participants.ages';

const messages = {
	errorListReload: `${prefix}.errorListReload`,
	errorUpdate    : `${prefix}.errorUpdate`,
	errorDelete    : `${prefix}.errorDelete`,
	successUpdate  : `${prefix}.successUpdate`,
	successDelete  : `${prefix}.successDelete`,
};
function* listReload() {

	yield takeEvery(actions.PARTICIPANTS_AGES_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		let list = [];
		try {
			const response = yield call(participantsAPI.participantAgeList);
			if (response && response.status === 200) {
				list = sortBy(response.data.data, 'id');
			}

		} catch (error) {
			notifications.showError(messages.errorListReload);
			console.log(error);
		}

		yield put(actions.listRefresh(list));
		yield put(actions.changedIDsReset());
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* deleteAge() {

	yield takeEvery(actions.PARTICIPANTS_AGES_DELETE_AGE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { ageID } = action.data;
		try {
			const response = yield call(participantsAPI.participantAgeDelete, ageID);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successDelete);
			}

		} catch (error) {
			notifications.showError(messages.errorDelete);
			console.log(error);
		}

		yield put(actions.listReload());
	});
}

function* saveAge() {

	yield takeEvery(actions.PARTICIPANTS_AGES_SAVE_AGE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const ageData = action.data;
		const ageID = toInteger(ageData.id);

		try {

			// update
			if (ageID) {
				const response = yield call(participantsAPI.participantAgeUpdate, ageID, ageData);
				if (response && response.status === 200) {
					notifications.showSuccess(messages.successUpdate);
				}

			// add
			} else {
				ageData.id = null;
				const response = yield call(participantsAPI.participantAgeAdd, ageData);
				if (response && response.status === 200) {
					notifications.showSuccess(messages.successUpdate);
				}
			}

		} catch (error) {
			notifications.showError(messages.errorUpdate);
			console.log(error);
		}

		yield put(actions.listReload());
	});
}

export default function* participantAgesSaga() {
	yield all([
		fork(listReload),
		fork(deleteAge),
		fork(saveAge),
	]);
}
