import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	entities: {},

	UI: {
		loading: false,
	},

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},
});

export default function userCasinoFreeSpinsReducer(state = initState, action) {

	switch (action.type) {

	case actions.LIST_REFRESH: {
		const { entities } = action.data;
		return state.set('entities', entities);
	}

	case actions.UI_MERGE: {
		const target = state.get('UI');
		const { data } = action.data;
		const result = fill(data, target, true);
		return state.set('UI', result);
	}

	case actions.PAGINATION_REFRESH: {
		const target = state.get('pagination');
		const { data } = action.data;
		const result = fill(data, target);
		return state.set('pagination', result);
	}

	default:
		return state;
	}
}
