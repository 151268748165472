import { normalize, schema } from 'normalizr';
import orderBy from 'lodash/orderBy';
import isArray from 'lodash/isArray';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';

export const fields = {
	marketID        : 'market_id',
	maxBonusAmount  : 'amount',
	period          : '?????',
	startDate       : '?????',
	endDate         : '?????',
	minWageringOdd  : 'min_wagering_odd',
	wageringTurnover: 'wagering_turnover',
	partnerWebsite  : '?????',
	currencyID      : 'currency_id',
	userID          : 'user_id',
};

const listAdapter = createListAdapter();

/**
 * *Adapt
 */
export function adaptFreeSpinsList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = orderBy(listAdapter.adaptList(rawData), ['ID'], ['desc']);

	const tempData = {
		items: adaptedData,
	};

	const item           = new schema.Entity('items', {}, { idAttribute: 'ID' });
	const itemsSchema    = { items: [item] };
	const normalizedData = normalize(tempData, itemsSchema);
	const entities       = normalizedData.entities.items || {};

	return entities;
}

export function getListParams(filter, pagination) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isNumber, 'userID', fields.userID);

	const params = builder.biuldParams(filter);

	return params;
}

export function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'marketID', fields.marketID);
	adapter.addField(rules.numberOrNull, 'maxBonusAmount', fields.maxBonusAmount);
	adapter.addField(rules.string, 'period', fields.period);
	adapter.addField(rules.dateTime, 'startDate', fields.startDate);
	adapter.addField(rules.dateTime, 'endDate', fields.endDate);
	adapter.addField(rules.numberOrNull, 'minWageringOdd', fields.minWageringOdd);
	adapter.addField(rules.numberOrNull, 'wageringTurnover', fields.wageringTurnover);
	adapter.addField(rules.id, 'partnerWebsite', fields.partnerWebsite);
	adapter.addField(rules.id, 'currencyID', fields.currencyID);

	return adapter;
}
