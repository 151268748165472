import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import notifications from '../../../helpers/notifications';
import { historyAPI } from '../../../helpers/api/history';
import { SELECTION_LOG_MODELS } from '../../../helpers/commonConstants';

import actions from './actions';
import { adaptData, adaptAllData } from './utils';

const prefix = 'selections.history';

const messages = {
	loadData: `${prefix}.loadData`,
};

function* dataReload() {
	yield takeEvery(actions.SELECTION_HISTORY_MODEL_DATA_RELOAD, function* (
		action
	) {
		yield put(actions.uiRefresh({ loading: true }));

		let data = [];
		const { selectionID, logModel } = action.data;

		if (logModel === SELECTION_LOG_MODELS.all) {
			data = yield allDataReload(selectionID);
		} else {
			data = yield modelDataReload(selectionID, logModel);
		}

		yield put(actions.dataRefresh(data, logModel));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* modelDataReload(selectionID, logModel) {
	let data = [];
	try {
		const response = yield call(
			historyAPI.historySelectionLogs,
			selectionID,
			logModel
		);
		if (response && response.status === 200) {
			data = adaptData(response.data.data, logModel);
		}
	} catch (error) {
		notifications.showError(messages.loadData, error);
		console.log(error);
	}

	return data;
}

function* allDataReload(categoryID) {
	const selection = yield modelDataReload(
		categoryID,
		SELECTION_LOG_MODELS.selection
	);
	const settings = yield modelDataReload(
		categoryID,
		SELECTION_LOG_MODELS.settings
	);
	const dynamicLimits = yield modelDataReload(
		categoryID,
		SELECTION_LOG_MODELS.dynamicLimits
	);
	const names = yield modelDataReload(categoryID, SELECTION_LOG_MODELS.names);
	const orders = yield modelDataReload(categoryID, SELECTION_LOG_MODELS.orders);

	const allData = adaptAllData({
		selection,
		settings,
		dynamicLimits,
		names,
		orders,
	});

	return allData;
}

export default function* selectionHistorySaga() {
	yield all([fork(dataReload)]);
}
