import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import { USER_LOG_MODELS } from '../../../../helpers/commonConstants';
import Formatter from '../../../../helpers/formatter';
import { DiffMapper } from '../../../../helpers/diff';
import { HistoryEnums } from '../../results/enums';

const fields = {
	id          : 'id',
	objectBefore: 'object_before',
	objectAfter : 'object_after',
	userID      : 'source_id',
	sourceTypeID: 'source_type',
	actionTypeID: 'action_type',
	date        : 'date',
	customerID  : 'user_id',
	channelID   : 'channel_id',
	websiteID   : 'website_id',
	langID      : 'lang_id',

	// array fields
	roles: 'user_roles',
};

// Adapting ---------------------------------------------------------------------------------------

export function adaptData(rawData = [], logModel = USER_LOG_MODELS.user) {

	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}

	switch (logModel) {
	case USER_LOG_MODELS.user:
		return adaptUser(rawData);

	default:
		return [];
	}
}

export function adaptAllData(allData) {
	let result = [];

	result = result.concat(addSectionName(allData.user, 'Main'));

	result.sort((a, b) => {
		return a.date > b.date ? -1 : 1;
	});

	result.forEach((item, index) => {
		item.id = index;
	});

	return result;
}

// Adapters ---------------------------------------------------------------------------------------

function adaptUser(rawData) {

	const arrayFields = [
		fields.roles,
	];

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id].concat(arrayFields),
	};

	const dataList = adaptHistoryList(rawData, diffOptions, arrayFields);

	return dataList;
}

// Common -----------------------------------------------------------------------------------------

function adaptHistoryList(rawData, diffOptions, arrayFields = []) {

	const historyEnums = new HistoryEnums();

	const dataList = [];

	const mapper = new DiffMapper();
	mapper.init(diffOptions);

	rawData.forEach((historyItem, historyItemIndex) => {
		let itemDiffList = [];

		const objectBefore = historyItem[fields.objectBefore] || {};
		const objectAfter  = historyItem[fields.objectAfter]  || {};

		// main data
		const diffMap  = mapper.map(objectBefore, objectAfter);
		const diffList = mapper.toList(diffMap);

		itemDiffList = itemDiffList.concat(diffList);

		// arrays
		arrayFields.forEach(fieldName => {
			const diffList = mapper.mapList(
				objectBefore,
				objectAfter,
				fieldName,
				true
			);
			itemDiffList = itemDiffList.concat(diffList);
		});

		if (isEmpty(itemDiffList)) {
			return;
		}

		// adding id for detail records
		itemDiffList.forEach((item, index) => {
			item.id = index;
		});

		// prepare result item
		const userID       = toInteger(historyItem[fields.userID]);
		const sourceTypeID = toInteger(historyItem[fields.sourceTypeID]);
		const actionTypeID = toInteger(historyItem[fields.actionTypeID]);

		const dataItem = {
			id         : historyItemIndex,
			userID,
			date       : Formatter.fullDateTime(historyItem[fields.date]),
			sourceType : historyEnums.sourceType(sourceTypeID),
			actionType : historyEnums.actionType(actionTypeID),
			detailsList: itemDiffList,
		};

		dataList.push(dataItem);
	});

	return dataList;
}

/*
function adaptHistoryListOfArrays(rawData, diffOptions) {

  const historyEnums = new HistoryEnums();
  const dataList = [];

  const mapper = new DiffMapper();
  mapper.init(diffOptions);

  rawData.forEach((historyItem, historyItemIndex) => {
    let itemDiffList = [];

    const objectBefore = historyItem[fields.objectBefore] || {};
    const objectAfter  = historyItem[fields.objectAfter] || {};

    const tempObjectBefore = {
      data: objectBefore
    };
    const tempObjectAfter = {
      data: objectAfter
    };

    const diffList = mapper.mapList(
      tempObjectBefore,
      tempObjectAfter,
      'data',
      true
    );
    itemDiffList = itemDiffList.concat(diffList);

    if (isEmpty(itemDiffList)) {
      return;
    }

    // adding id for detail records
    itemDiffList.forEach((item, index) => {
      item.id = index;
    });

    // prepare result item
    const userID       = toInteger(historyItem[fields.userID]);
    const sourceTypeID = toInteger(historyItem[fields.sourceTypeID]);
    const actionTypeID = toInteger(historyItem[fields.actionTypeID]);

    const dataItem = {
      id          : historyItemIndex,
      userID      : userID,
      date        : Formatter.fullDateTime(historyItem[fields.date]),
      sourceType  : historyEnums.sourceType(sourceTypeID),
      actionType  : historyEnums.actionType(actionTypeID),
      detailsList : itemDiffList
    };

    dataList.push(dataItem);
  });

  return dataList;
}
*/

function addSectionName(rawList, sectionName) {
	if (!isArray(rawList) || isEmpty(rawList)) {
		return [];
	}

	return rawList.map(item => {
		return {
			...item,
			section: sectionName,
		};
	});
}
