import { Map } from 'immutable';
import { liveStatisticTypesModalActions } from './actions';
import { fill } from '../../../../helpers/utils';

const initState = new Map({
	baseData: {
		sportID: 150592,
	},
	names     : {},
	visibility: {},
	UI        : {
		lstID    : 0,
		langID   : 0,
		loading  : false,
		isChanged: false,
	},
});

export default function liveStatisticTypesModalReducer(state = initState, action) {

	switch (action.type) {
	case liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_BASE_DATA_RELOAD_CASE: {
		return state.set('baseData', action.data);
	}

	case liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_NAME_RELOAD_CASE: {
		return state.set('names', action.data);
	}

	case liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_VISIBILITY_CASE: {
		return state.set('visibility', action.data);
	}

	case liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_CLEAR_MODAL: {
		return state.set('baseData', initState.get('baseData'))
			.set('names', initState.get('names'))
			.set('visibility', initState.get('visibility'));
	}

	case liveStatisticTypesModalActions.LIVE_STATISTIC_TYPES_MODAL_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target, true);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
