import { normalize, schema } from 'normalizr';
import orderBy from 'lodash/orderBy';
import isArray from 'lodash/isArray';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';

export const fields = {
	ID                : 'id',
	expireDate        : 'expire_date',
	givenDate         : 'given_date',
	bonusTypeID       : 'bonus_type_id',
	balance           : 'balance',
	wager             : 'wager',
	initialBalance    : 'initial_balance',
	maxBonus          : 'maximum_bonus',
	stakeLeftToConvert: 'stake_left_to_convert',
	stakeToConvert    : 'stake_to_convert',
	statusID          : 'status_id',
	period            : 'period',
	comment           : 'comment',
};

const listAdapter = createListAdapter();

export function getListParams(pagination) {

	const builder = new ParamsBuilder();

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	const params = builder.biuldParams({});

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptCasinoBonusesList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = orderBy(listAdapter.adaptList(rawData), ['ID'], ['desc']);

	const tempData = {
		items: adaptedData,
	};

	const item           = new schema.Entity('items', {}, { idAttribute: 'ID' });
	const itemsSchema    = { items: [item] };
	const normalizedData = normalize(tempData, itemsSchema);
	const entities       = normalizedData.entities.items || {};

	return entities;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'ID', fields.ID);
	adapter.addField(rules.id, 'bonusTypeID', fields.bonusTypeID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.dateTime, 'expireDate', fields.expireDate);
	adapter.addField(rules.dateTime, 'givenDate', fields.givenDate);
	adapter.addField(rules.number, 'balance', fields.balance);
	adapter.addField(rules.number, 'initialBalance', fields.initialBalance);
	adapter.addField(rules.number, 'stakeLeftToConvert', fields.stakeLeftToConvert);
	adapter.addField(rules.number, 'stakeToConvert', fields.stakeToConvert);
	adapter.addField(rules.number, 'maxBonus', fields.maxBonus);
	adapter.addField(rules.number, 'wager', fields.wager);
	adapter.addField(rules.string, 'comment', fields.comment);

	return adapter;
}
