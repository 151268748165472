import { cloneDeep } from 'lodash';
import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { TABLE_TYPES } from '../../../constants/tableTypes';
import { TOUR_STEPS } from '../../../containers/LoyaltySystem/Tournaments/modals/utils';
import { casinoAPI } from '../../../helpers/api/casino';
import { tournamentsAPI } from '../../../helpers/api/tournaments';
import { showError } from '../../../helpers/notifications';
import { deriveTablePagination } from '../../../selectors/tables';
import { getListParams } from '../bonuses/bonuses/utils';

import actions from './actions';
import { adaptIntegrators, adaptList, adaptTournament, delay } from './utils';

const prefix = 'tournaments.';

const messages = {
	listReloadError: `${prefix}listReloadError`,
	reloadError    : `${prefix}reloadError`,
};

const tableType = TABLE_TYPES.tournaments;
const getStoreData = (state) => {
	const { Loyalty: { Tournaments }, Tables } = state;
	return {
		filter     : Tournaments.get('filter'),
		sorting    : Tables.get(tableType).sorting,
		pagination : deriveTablePagination(tableType, state),
		partnerData: Tournaments.get('partners'),
	};
};

function* listReload() {
	yield takeEvery(actions.LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);

		try {
			const res = yield call(tournamentsAPI.tournaments, params);
			const tournamentsList = adaptList(res.data.data);
			yield put(actions.listRefresh(tournamentsList));
		} catch (e) {
			console.log(e, 'errror tournament');
			showError(messages.listReloadError);
		}

		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* tournamentReload() {
	yield takeEvery(actions.TOURNAMENT_BY_ID, function* (action) {
		const { data } = action;
		yield put(actions.uiRefresh({ tournamentLoading: true }));


		try {
			const res = yield call(tournamentsAPI.tournament, data);
			const { settings, main } = adaptTournament(res.data.data);
			yield put(actions.baseDataRefresh(settings, TOUR_STEPS.SETTINGS));
			yield put(actions.baseDataRefresh(main, TOUR_STEPS.MAIN));
			// yield put(actions.listRefresh(tournamentsList));
		} catch (e) {
			console.log(e, 'error');
			showError(messages.reloadError);
		}

		yield put(actions.uiRefresh({ tournamentLoading: false }));

	});
}

function* integratorsReload() {
	yield takeEvery(actions.INTEGRATORS_RELOAD, function* (action) {
		const { data } = action;
		yield put(actions.uiRefresh({ integratorsLoading: true }));


		try {
			const res = yield call(casinoAPI.integratorsListWithProviders, data);
			const adapted = adaptIntegrators(res.data.data);
			yield put(actions.integratorsListRefresh(adapted));
		} catch (e) {
			console.log(e, 'error');
			showError(messages.reloadError);
		}

		yield put(actions.uiRefresh({ integratorsLoading: false }));

	});
}


function* gamesPerProvider() {
	yield takeEvery(actions.GAMES_PER_PROVIDER_RELOAD, function* (action) {
		const { data } = action;
		// yield put(actions.uiRefresh({ integratorsLoading: true }));


		try {
			const res = yield call(casinoAPI.gamesListByProvider, data);
		} catch (e) {
			console.log(e, 'error');
			showError(messages.reloadError);
		}

		// yield put(actions.uiRefresh({ integratorsLoading: false }));

	});
}

function* partnersForTournaments() {
	yield takeEvery(actions.RELOAD_PARTNERS, function* (action) {
		const { data } = action;
		const { partnerData } = yield select(getStoreData);

		const cloned = cloneDeep(partnerData);
		cloned.list = [];
		yield put(actions.uiRefresh({ partnersLoading: true }));
		yield put(actions.partnersForTournamentsRefresh(cloned));

		try {
			// yield call(tournamentsAPI.partners, data);
			yield call(delay, 2000);
			const cloned = cloneDeep(partnerData);
			const chars = data.split('');

			cloned.list = chars.map((char, idx) => ({
				id  : idx,
				name: `${char  } ${  char}`,
			}));
			yield put(actions.partnersForTournamentsRefresh(cloned));
		} catch (e) {
			showError(messages.listReloadError);
		}

		yield put(actions.uiRefresh({ partnersLoading: false }));

	});
}

export default function* bonusesSaga() {
	yield all([
		fork(listReload),
		fork(tournamentReload),
		fork(partnersForTournaments),
		fork(integratorsReload),
		fork(gamesPerProvider),
	]);
}
