import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import difference from 'lodash/difference';
import toInteger from 'lodash/toInteger';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import actions from './actions';
import externalSaga from './externalSaga';
import tableActions from '../../tables/actions';
import { deriveTablePagination } from '../../../selectors/tables';
import socetActions from '../../socket/actions';

import {
	getListParams,
	adaptBetsList,
	prepareBetForReviewUpdate,
	getEventNameListParams,
} from './utils';

import { categoriesAPI } from '../../../helpers/api/categories';
import { eventsAPI } from '../../../helpers/api/events';
import { betsAPI } from '../../../helpers/api/bets';
import { betsAPI as socketsBetsAPI } from '../../../helpers/sockets/api/bets';
import { createError } from '../../../helpers/sockets/socket';

import { BET_STATUS, DISPLAY_STATUS } from '../../../helpers/commonConstants';
import { getHeadersTotalCount } from '../../../helpers/utils';
import notifications from '../../../helpers/notifications';
import { TABLE_TYPES } from '../../../constants/tableTypes';

const prefix = 'rtm.bets';

const messages = {
	errorSocket              : `${prefix}.errorSocket`,
	errorListReload          : `${prefix}.errorListReload`,
	errorCountryListReload   : `${prefix}.errorCountryListReload`,
	errorLeagueListReload    : `${prefix}.errorLeagueListReload`,
	errorEventListReload     : `${prefix}.errorEventListReload`,
	errorEventNamesListReload: `${prefix}.errorEventNamesListReload`,
	errorSubscribeUpdate     : `${prefix}.errorSubscribeUpdate`,
	errorSubscribeDelete     : `${prefix}.errorSubscribeDelete`,
	errorSubscribeCreate     : `${prefix}.errorSubscribeCreate`,
	errorAcceptBet           : `${prefix}.errorAcceptBet`,
	errorRejectBet           : `${prefix}.errorRejectBet`,
	errorUpdateBet           : `${prefix}.errorUpdateBet`,
	successAcceptBet         : `${prefix}.successAcceptBet`,
	successRejectBet         : `${prefix}.successRejectBet`,
	successUpdateBet         : `${prefix}.successUpdateBet`,
	successSubscribeUpdate   : `${prefix}.successSubscribeUpdate`,
	errorGetTable            : `${prefix}.errorGetTable`,
	errorPostTable           : `${prefix}.errorPostTable`,
};

const tableType          = TABLE_TYPES.betRTM;

function getStoreData(state) {
	const { RTM, Tables, AppTabs } = state;

	return {
		betIDs                : RTM.Bets.get('betIDs'),
		entities              : RTM.Bets.get('entities'),
		filter                : RTM.Bets.get('filter'),
		sorting               : Tables.get(tableType).sorting,
		pagination            : deriveTablePagination(tableType, state),
		countryList           : RTM.Bets.get('countryList'),
		leagueList            : RTM.Bets.get('leagueList'),
		filteredLeagues       : RTM.Bets.get('filteredLeagues'),
		eventList             : RTM.Bets.get('eventList'),
		loadedCountryParentIDs: RTM.Bets.get('loadedCountryParentIDs'),
		loadedLeagueParentIDs : RTM.Bets.get('loadedLeagueParentIDs'),
		loadedEventsParentIDs : RTM.Bets.get('loadedEventsParentIDs'),
		editedBetIDs          : RTM.Bets.get('editedBetIDs'),
		editedBetEntities     : RTM.Bets.get('editedBetEntities'),
		activeTabID           : AppTabs.get('activeTabID'),

	};
}

function* listReload() {

	yield takeEvery(actions.RTM_BETS_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination, countryList, filteredLeagues, activeTabID } = yield select(getStoreData);
		const filterCopy =  { ...filter };
		if ( filterCopy.leagueIDs && filteredLeagues && filterCopy.leagueIDs.length === filteredLeagues.length) {
			delete filterCopy.leagueIDs;
		}
		if ( filterCopy.countryIDs && countryList && filterCopy.countryIDs.length === countryList.length  ) {
			delete filterCopy.countryIDs;
		}
		const params = getListParams(filterCopy, sorting, pagination);
		let betIDs     = [];
		let entities   = {};
		let totalCount = 0;

		try {
			const response = yield call(betsAPI.rtmBetslipsList, params);

			if (response && response.status === 200) {
				const result = adaptBetsList(response.data.data);
				betIDs       = result.betIDs;   // eslint-disable-line prefer-destructuring
				entities     = result.entities; // eslint-disable-line prefer-destructuring
				totalCount   = getHeadersTotalCount(response.headers) || betIDs.length;

				yield put(actions.dataRefresh(betIDs, entities));
				yield put(socetActions.rtmSubscribe(activeTabID, betIDs));
			}

			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			notifications.showError(messages.errorListReload);
			console.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* countryListReload() {

	yield takeEvery(actions.RTM_BETS_FILTER_COUNTRY_LIST_RELOAD, function* (action) {

		const { selectedSport, oldSelectedSport } = action.data;
		const isSelectedSportChanged = selectedSport === oldSelectedSport;
		if (isSelectedSportChanged) {
			return;
		}

		try {
			const params = {
				parent_id        : selectedSport,
				display_status_id: DISPLAY_STATUS.visible,
			};
			const response = yield call(categoriesAPI.getCategories, params);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(actions.countryListRefresh(data));
			}
		} catch (error) {
			notifications.showError(messages.errorCountryListReload);
			console.log(error);
		}
	});
}

function* leagueListReload() {

	yield takeEvery(actions.RTM_BETS_FILTER_LEAGUE_LIST_RELOAD, function* (action) {

		const { countryIDs, oldCountryIDs } = action.data;
		const parentIDs = difference(countryIDs, oldCountryIDs);
		if (!isArray(parentIDs) || !parentIDs.length) {
			return;
		}
		const storeData                 = yield select(getStoreData);
		const  { leagueList, countryList }           = storeData;

		const leagListCopy = [...leagueList];
		try {
			const params = {
				display_status_id: DISPLAY_STATUS.visible,
			};

			if (countryIDs.length !== countryList.length) {
				params.parents_id = parentIDs;
			}

			const response = yield call(categoriesAPI.getCategoriesOfSport, params);
			if (response && response.status === 200) {
				const { data } = response.data;
				leagListCopy.push(...data);
				yield put(actions.leagueListRefresh(leagListCopy));
				yield put(actions.loadedLeagueParentIDsRefresh(parentIDs));
			}
		} catch (error) {
			notifications.showError(messages.errorLeagueListReload);
			console.log(error);
		}
	});
}

function* eventListReload() {

	yield takeEvery(actions.RTM_BETS_FILTER_EVENT_LIST_RELOAD, function* (action) {

		const { newLeagueIDs, oldLeagueIDs } = action.data;
		const parentIDs = difference(newLeagueIDs, oldLeagueIDs);
		if (!isArray(parentIDs) || !parentIDs.length) {
			return;
		}
		const { eventList, filteredLeagues } = yield select(getStoreData);
		const eventListCopy = [...eventList];

		try {
			const params = {};
			if ( newLeagueIDs.length !== filteredLeagues.length ) {
				params.league_ids = parentIDs;
			}
			const response = yield call(eventsAPI.eventList, params);
			if (response && response.status === 200) {
				const { data } = response.data;
				eventListCopy.push(data);
				yield put(actions.loadedEventParentIDsRefresh(parentIDs));
				yield put(actions.eventListRefresh(eventList));

			}
		} catch (error) {
			notifications.showError(messages.errorEventListReload);
			console.log(error);
		}

	});
}

function* nameListReload() {

	yield takeEvery(actions.RTM_BETS_FILTER_EVENT_NAME_LIST_RELOAD, function* (action) {

		const { eventName } = action.data;
		const filter = yield select(getStoreData);
		const params = getEventNameListParams(filter, eventName);

		try {
			const response = yield call(eventsAPI.eventsNamesAutocomplete, params);
			if (response && response.status === 200) {
				const list = response.data.data;
				yield put(actions.eventNameListRefresh(list));
			}
		} catch (error) {
			notifications.showError(messages.errorEventNamesListReload);
		}
	});
}

function* filterApply() {
	yield takeEvery(actions.RTM_BETS_FILTER_APPLY, function* () {
		yield put(actions.listReload());
	});
}

function* acceptBet() {

	yield takeEvery(actions.RTM_BETS_ACCEPT_BET, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const betIDs = cloneDeep(storeData.betIDs);
		const entities = cloneDeep(storeData.entities);

		const newBetStatusID = BET_STATUS.open;

		const { betID } = action.data;
		const betEntity = entities[betID];
		const betData = prepareBetForReviewUpdate(betEntity, newBetStatusID);

		try {
			const response = yield call(socketsBetsAPI.updateBetReview, betData);
			if (response) {
				if (response.isSuccess) {
					notifications.showSuccess(messages.successAcceptBet);

					const bet = entities[betID];
					bet.betStatusID = newBetStatusID;

					yield put(actions.dataRefresh(betIDs, entities));
				} else {
					const error = createError(response);
					throw error;
				}
			}
		} catch (error) {
			notifications.showError(messages.errorAcceptBet);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* rejectBet() {

	yield takeEvery(actions.RTM_BETS_REJECT_BET, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const betIDs = cloneDeep(storeData.betIDs);
		const entities = cloneDeep(storeData.entities);

		const newBetStatusID = BET_STATUS.adminRejected;

		const { betID } = action.data;
		const betEntity = entities[betID];
		const betData = prepareBetForReviewUpdate(betEntity, newBetStatusID);

		try {
			const response = yield call(socketsBetsAPI.updateBetReview, betData);
			if (response) {
				if (response.isSuccess) {
					notifications.showSuccess(messages.successRejectBet);

					const bet = entities[betID];
					bet.betStatusID = newBetStatusID;

					yield put(actions.dataRefresh(betIDs, entities));
				} else {
					const error = createError(response);
					throw error;
				}
			}
		} catch (error) {
			notifications.showError(messages.errorRejectBet);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* subscribeToUpdate() {

	yield takeEvery(actions.RTM_BETS_SOCKET_SUBSCRIBE_TO_UPDATE, function* (action) {

		const { betIDs } = action.data;
		try {
			const response = yield call(socketsBetsAPI.subscribeToUpdate, betIDs);
			if (response) {
				if (response.isSuccess) {
					//notifications.showSuccess(messages.successSubscribeUpdate);
				} else {
					const error = createError(response);
					throw error;
				}
			}
		} catch (error) {
			notifications.showError(messages.errorSubscribeUpdate);
			console.log(error);
		}
	});
}

function* editedBetSave() {

	yield takeEvery(actions.RTM_BETS_EDITED_BET_SAVE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const betIDs = cloneDeep(storeData.betIDs);
		const entities = cloneDeep(storeData.entities);
		let editedBetIDs = cloneDeep(storeData.editedBetIDs);
		let editedBetEntities = cloneDeep(storeData.editedBetEntities);

		const { betID } = action.data;
		const betEntity = editedBetEntities[betID];
		const betData = prepareBetForReviewUpdate(betEntity, betEntity.betStatusID);

		try {
			const response = yield call(socketsBetsAPI.updateBetReview, betData);
			if (response) {
				if (response.isSuccess) {
					notifications.showSuccess(messages.successUpdateBet);

					entities[betID] = betEntity;

					editedBetIDs = editedBetIDs.filter(ID => {
						return toInteger(ID) !== toInteger(betID);
					});
					editedBetEntities = editedBetIDs.map(ID => {
						return editedBetEntities[ID];
					});

					yield put(actions.editedDataRefresh(editedBetIDs, editedBetEntities));
					yield put(actions.dataRefresh(betIDs, entities));
				} else {
					const error = createError(response);
					throw error;
				}
			}
		} catch (error) {
			notifications.showError(messages.errorUpdateBet);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* rtmBetsSaga() {
	yield all([
		fork(listReload),
		fork(countryListReload),
		fork(leagueListReload),
		fork(eventListReload),
		fork(nameListReload),
		fork(filterApply),
		fork(acceptBet),
		fork(rejectBet),
		fork(subscribeToUpdate),
		fork(editedBetSave),

		fork(externalSaga),
	]);
}
