import { Map } from 'immutable';
import { fill } from '../../../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	depositList   : [],
	withdrawalList: [],

	baseData      : {},
	withdrawalData: {},

	depositUI: {
		loading: false,
	},

	withdrawalUI: {
		loading: false,
	},
});

export default function userLimitsPaymentsReducer(state = initState, action) {

	switch (action.type) {

	case actions.USER_LIMITS_PAYMENTS_DEPOSIT_REFRESH: {
		return state.set('depositList', action.data);
	}
	case actions.USER_LIMITS_PAYMENTS_BASEDATA_REFRESH: {
		return state.set('baseData', action.data);
	}

	case actions.USER_LIMITS_PAYMENTS_WITHDRAWAL_REFRESH: {
		return state.set('withdrawalList', action.data);
	}

	case actions.USER_LIMITS_PAYMENTS_WITHDRAWAL_DATA_REFRESH: {
		return state.set('withdrawalData', action.data);
	}

	case actions.USER_LIMITS_PAYMENTS_DEPOSIT_UI_REFRESH: {
		const UI  = action.data;
		const target = state.get('depositUI');
		const result = fill(UI, target);
		return state.set('depositUI', result);
	}

	case actions.USER_LIMITS_PAYMENTS_WITHDRAWAL_UI_REFRESH: {
		const UI  = action.data;
		const target = state.get('withdrawalUI');
		const result = fill(UI, target);
		return state.set('withdrawalUI', result);
	}

	case actions.USER_LIMITS_PAYMENTS_RESET: {
		return initState;
	}

	default:
		return state;

	}

}
