import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import whitelistActions from './actions';

const initState = new Map({
	entities: {},

	UI: {
		domainID  : null,
		domainData: {
			domain: '',
		},
		visible   : false,
		loading   : false,
		editMode  : false,
		closeModal: false,
		isChanged : false,
	},
});

export default function securityWhiteListReducer(state = initState, action) {

	switch (action.type) {

	case whitelistActions.SECURITY_WHITELIST_REFRESH: {
		const { entities } = action.data;
		return state.set('entities', entities);
	}

	case whitelistActions.SECURITY_WHITELIST_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case whitelistActions.SECURITY_WHITELIST_UI_RESET: {
		const UI = initState.get('UI');
		return state.set('UI', UI);
	}

	default:
		return state;
	}
}
