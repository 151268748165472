import {
	all,
	takeEvery,
	put,
	fork,
	call,
	select,
} from 'redux-saga/effects';
import { usersAPI } from '../../../helpers/api/users';
import { actions as smsGatewayActions } from '../../settings/sms/smsGateway/actions';
import actions from './actions';
import tableActions from '../../tables/actions';

import { showError } from '../../../helpers/notifications';
import { deriveTablePagination } from '../../../selectors/tables';

import { getListParams, adaptSMSList } from './utils';
import { TABLE_TYPES } from '../../../constants/tableTypes';

const prefix = 'notifcenter.sms.list';

const messages = {
	errorListLoad: `${prefix}.errorListLoad`,
};

const tableType        = TABLE_TYPES.smsListing;

function getStoreData(state) {
	const { Tables, NotificationsCenter } = state;

	return {
		filter    : NotificationsCenter.SMS.get('filter'),
		pagination: deriveTablePagination(tableType, state),
		sorting   : Tables.get(tableType).sorting,
	};
}

function* listReload() {

	yield takeEvery(actions.LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { filter, pagination, sorting } = yield select(getStoreData);
		const params   = getListParams(filter, pagination, sorting);

		yield put(smsGatewayActions.dataReload());
		try {
			const res = yield call(usersAPI.getUserSMSList, params);
			if (res && res.status === 200) {
				const { entities, totalCount } = adaptSMSList(res.data.data, res.headers);
				yield put(actions.listRefresh(entities));
				yield put(tableActions.paginationRefresh(tableType, { totalCount }));
			}

		} catch (error) {
			showError(messages.errorListLoad, error);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));

	});
}


function* filterApply() {

	yield takeEvery(actions.LIST_FILTER_APPLY, function* () {

		yield put(tableActions.paginationRefresh(tableType, { currentPage: 1 }));
		yield put(actions.listReload());
	});
}

export default function* smsSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
	]);
}
