import dayjs from 'dayjs';
import toInteger from 'lodash/toInteger';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import { isID } from './utils';

export default class FilterComparator {
	RULES = {
		isEqual      : 'isEqual',
		inNumberRange: 'inNumberRange',
		inDateRange  : 'inDateRange',
		inArrayID    : 'inArrayID',
		inArrayNumber: 'inArrayNumber',
		inArrayString: 'inArrayString',
	};

	fields = [];

	matches = [];

	valids = [];

	addField(rule, objectName, filterName = null) {
		this.fields.push({
			rule,
			objectName,
			filterName: filterName || objectName,
		});
	}

	compare(initObject, filter) {
		this.matches = [];
		this.valids = [];

		this.fields.forEach(field => {
			const { rule, objectName, filterName } = field;

			switch (rule) {
			case this.RULES.isEqual: {
				const objectValue = initObject[objectName];
				const filterValue = filter[filterName];
				const isValid = this.isValidFilterValue(rule, filterValue);
				if (isValid) {
					this.valids.push({
						filterName: filterValue,
					});
				}

				if (this.isLikeNumber(filterValue)) {
					if (isValid && toNumber(objectValue) === toNumber(filterValue)) {
						this.matches.push({
							value : objectValue,
							filter: filterValue,
						});
					}
				} else if (isValid && isEqual(objectValue, filterValue)) {
					this.matches.push({
						value : objectValue,
						filter: filterValue,
					});
				}
				break;
			}

			case this.RULES.inNumberRange: {
				const objectValue = toNumber(initObject[objectName]);
				const filterValue = filter[filterName];
				const isValid = this.isValidFilterValue(rule, filterValue);
				if (isValid) {
					this.valids.push({
						filterName: filterValue,
					});
				}

				let valueFrom = null;
				let valueTo = null;
				if (isValid && filterValue.length === 2) {
					valueFrom = toNumber(filterValue[0]);
					valueTo = toNumber(filterValue[1]);
				}

				if (
					(Boolean(valueFrom) || Boolean(valueTo))
            && (objectValue >= valueFrom || objectValue <= valueTo)
				) {
					this.matches.push({
						value : objectValue,
						filter: filterValue,
					});
				}
				break;
			}

			case this.RULES.inDateRange: {
				const objectValue = dayjs(initObject[objectName]);
				const filterValue = filter[filterName];
				const isValid = this.isValidFilterValue(rule, filterValue);
				if (isValid) {
					this.valids.push({
						filterName: filterValue,
					});
				}

				let valueFrom = null;
				let valueTo = null;
				if (isValid && filterValue.length === 2) {
					valueFrom = dayjs(filterValue[0]);
					valueTo = dayjs(filterValue[1]);
				}

				if (
					(Boolean(valueFrom) || Boolean(valueTo))
            && objectValue.isBetween(valueFrom, valueTo)
				) {
					this.matches.push({
						value : objectValue,
						filter: filterValue,
					});
				}
				break;
			}

			case this.RULES.inArrayID: {
				const objectValue = toInteger(initObject[objectName]);
				const filterValue = this.getArrayID(filter[filterName]);
				const isValid = this.isValidFilterValue(rule, filterValue);
				if (isValid) {
					this.valids.push({
						filterName: filterValue,
					});
				}

				if (isValid && filterValue.includes(objectValue)) {
					this.matches.push({
						value : objectValue,
						filter: filterValue,
					});
				}
				break;
			}

			case this.RULES.inArrayNumber: {
				const objectValue = toNumber(initObject[objectName]);
				const filterValue = this.getArrayNumber(filter[filterName]);
				const isValid = this.isValidFilterValue(rule, filterValue);
				if (isValid) {
					this.valids.push({
						filterName: filterValue,
					});
				}

				if (isValid && filterValue.includes(objectValue)) {
					this.matches.push({
						value : objectValue,
						filter: filterValue,
					});
				}
				break;
			}

			case this.RULES.inArrayString: {
				const objectValue = String(initObject[objectName]);
				const filterValue = this.getArrayString(filter[filterName]);
				const isValid = this.isValidFilterValue(rule, filterValue);
				if (isValid) {
					this.valids.push({
						filterName: filterValue,
					});
				}

				if (isValid && filterValue.includes(objectValue)) {
					this.matches.push({
						value : objectValue,
						filter: filterValue,
					});
				}
				break;
			}

			default:
			}
		});

		if (isEmpty(this.valids)) {
			return true;
		}

		const hasMatches = !isEmpty(this.matches);

		return hasMatches;
	}

	isValidFilterValue(rule, filterValue) {
		let isValid = false;

		switch (rule) {
		case this.RULES.isEqual: {
			if (this.isLikeNumber(filterValue)) {
				isValid = true;
			} else {
				isValid = Boolean(filterValue);
			}
			break;
		}

		default:
			if (isArray(filterValue) && !isEmpty(filterValue)) {
				isValid = true;
			}
		}

		return isValid;
	}

	isLikeNumber(value) {
		return Boolean(Number(value));
	}

	getArrayID(rawArrayID) {
		if (!isArray(rawArrayID)) {
			return [];
		}

		const arrayID = rawArrayID.filter(item => {
			return isID(item);
		});

		return arrayID;
	}

	getArrayNumber(rawArray) {
		if (!isArray(rawArray)) {
			return [];
		}

		const arrayNumber = rawArray.filter(item => {
			return toNumber(item);
		});

		return arrayNumber;
	}

	getArrayString(rawArrayString) {
		if (!isArray(rawArrayString)) {
			return [];
		}

		const arrayString = rawArrayString.filter(item => {
			return Boolean(item);
		});

		return arrayString;
	}
}
