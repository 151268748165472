import { normalize, schema } from 'normalizr';
import EntityAdapter from '../../../helpers/entityAdapter';

export const fields = {
	id   : 'id',
	name : 'name',
	games: 'games',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------
export function adaptTagsList(rawData = []) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	const tempData = {
		items: adaptedData,
	};

	const item           = new schema.Entity('items', {}, { idAttribute: 'id' });
	const itemSchema     = { items: [item] };
	const normalizedData = normalize(tempData, itemSchema);
	const entities       = normalizedData.entities.items || {};

	return entities;
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.arrayObject, 'games', fields.games);

	return adapter;
}
