import { socketConfig } from '../../config';
import { EVENT_TYPES } from '../../helpers/sockets/codes';
import { createError } from '../../helpers/sockets/socket';
import { SOCKET_STATUS } from '../../helpers/commonConstants';
import socketActions from './actions';

const { debugInfo } = socketConfig;

function subscribe(socket, emit) {

	socket.on('connect', () => {
		if (debugInfo) {
			console.log('Connection to websocket established');
		}
		emit(socketActions.setStatus(SOCKET_STATUS.connected));
	});

	socket.on('disconnect', reason => {

		emit(socketActions.setStatus(SOCKET_STATUS.disconnected));
		if (reason === 'io server disconnect') {
			if (debugInfo) {
				console.log('Websocket diconnected. Trying reconnect...');
			}
			socket.connect();
		}
	});

	socket.on('error', error => {

		if (debugInfo) {
			console.log(`Connection to websocket failed with error ${error}`);
			console.dir(error);
		}
		emit(socketActions.setStatus(SOCKET_STATUS.disconnected));
	});

	socket.on('create', event => {

		if (debugInfo) {
			console.log('Incoming event "Create": ', event);
		}

		const { type, data } = event;
		if (!data) {
			console.error(createError(event));
			return;
		}
		switch (type) {
		case EVENT_TYPES.betslip:
			emit(socketActions.notifyCreateBet(data));
			break;

		case EVENT_TYPES.deposit:
			emit(socketActions.notifyCreateDeposit(data));
			break;

		case EVENT_TYPES.withdrawal:
			emit(socketActions.notifyCreateWithdrawal(data));
			break;

		case EVENT_TYPES.casino:
			emit(socketActions.notifyCreateCasinoTransaction(data));
			break;

		case EVENT_TYPES.document:
			emit(socketActions.notifyCreateDocument(data));
			break;
		case EVENT_TYPES.riskManagement:
			emit(socketActions.notifyCreateRisk(data));
			break;
		case EVENT_TYPES.riskAlertLow:
		case EVENT_TYPES.riskAlertMedium:
		case EVENT_TYPES.riskAlertHigh:
			emit(socketActions.notifyCreateNotifRisk({ ...data, type }));
			break;

		case EVENT_TYPES.userDocument:
			emit(socketActions.notifyCreateUserDocument(data));
			break;

		case EVENT_TYPES.notifDeposit:
			emit(socketActions.notifyCreateNotifDeposit({ ...data, type }));
			break;

		case EVENT_TYPES.notifCasino:
			emit(socketActions.notifyCreateNotifCasino({ ...data, type }));
			break;

		case EVENT_TYPES.notifWithdrawal:
			emit(socketActions.notifyCreateNotifWithdrawal({ ...data, type }));
			break;

		case EVENT_TYPES.messageRequest:
			emit(socketActions.notifyCreateMessages(data));
			break;

		case EVENT_TYPES.messages:
			emit(socketActions.notifyUpdateMessages(data));
			break;

		default:
			if (debugInfo) {
				console.error(`Incoming event "Create": Unknown event type: ${type}`);
			}
		}
	});

	socket.on('update', event => {

		if (debugInfo) {
			console.log('Incoming event "Update": ', event);
		}

		const { type, data } = event;
		if (!data) {
			console.error(createError(event));
			return;
		}

		switch (type) {
		case EVENT_TYPES.betslip:
			emit(socketActions.notifyUpdateBet(data));
			break;

		case EVENT_TYPES.deposit:
			emit(socketActions.notifyUpdateDeposit(data));
			break;

		case EVENT_TYPES.document:
			emit(socketActions.notifyUpdateDocument(data));
			break;
		case EVENT_TYPES.riskManagement:
			emit(socketActions.notifyUpdateRisk(data));
			break;

		case EVENT_TYPES.riskAlertLow:
		case EVENT_TYPES.riskAlertMedium:
		case EVENT_TYPES.riskAlertHigh:
			emit(socketActions.notifyUpdateNotifRisk({ ...data, type }));
			break;

		case EVENT_TYPES.userDocument:
			emit(socketActions.notifyUpdateUserDocument(data));
			break;

		case EVENT_TYPES.withdrawal:
			emit(socketActions.notifyUpdateWithdrawal(data));
			break;
		case EVENT_TYPES.notifWithdrawal:
			emit(socketActions.notifyUpdateNotifWithdrawal({ ...data, type }));
			break;

		case EVENT_TYPES.casino:
			emit(socketActions.notifyUpdateCasinoTransaction(data));
			break;

		case EVENT_TYPES.eventMarketsStatistics:
			emit(socketActions.notifyUpdateEventMarketsStatistics(data));
			break;

		case EVENT_TYPES.eventStatistic:
			emit(socketActions.notifyUpdateEventStatistic(data));
			break;

		default:
			console.error(`Incoming event "Create": Unknown event type: ${type}`);
		}
	});

	socket.on('connect_error', () => {
		if (debugInfo) {
			console.log('Socket connection error...');
		}
		emit(socketActions.setStatus(SOCKET_STATUS.disconnected));
	});
}

export default subscribe;
