import EntityAdapter from '../../../helpers/entityAdapter';
import ParamsBuilder from '../../../helpers/paramsBuilder';

const fields = {
	userId      : 'user_id',
	statusId    : 'status_id',
	created     : 'created',
	userName    : 'username',
	documentType: 'type_id',
	statusIDs   : 'status_ids',

	docType      : 'document_type',
	documentId   : 'id',
	url          : 'url',
	declineReason: 'decline_reason',

	dateFrom   : 'date_from',
	dateTo     : 'date_to',
	userComment: 'description',
};
const documentsListAdapter = createListAdapter();

export const adaptDocuments = (data = []) => {
	const adaptedData = documentsListAdapter.adaptList(data);
	const documentsIDs = [];
	const entities = adaptedData.reduce((next, document) => {
		const { documentId } = document;
		documentsIDs.push(documentId);
		return { ...next, [documentId]: document };
	}, {});
	return {
		documentsIDs,
		entities,
	};
};

function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'userId', fields.userId);
	adapter.addField(rules.id, 'statusId', fields.statusId);
	adapter.addField(rules.id, 'documentId', fields.documentId);
	adapter.addField(rules.string, 'created', fields.created);
	adapter.addField(rules.string, 'url', fields.url);
	adapter.addField(rules.string, 'userComment', fields.userComment);
	adapter.addField(rules.string, 'userName', fields.userName);
	adapter.addField(rules.string, 'declineReason', fields.declineReason);
	adapter.addField(rules.number, 'documentType', fields.documentType);

	return adapter;
}
export function getListParams(pagination = null, sorting, filter) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;
	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isID, 'userID', fields.userId);
	builder.addField(rules.isArrayID, 'docType', fields.docType);
	builder.addField(rules.isArrayID, 'docStatusIDs', fields.statusIDs);
	builder.addRangeField(rules.isDateTimeRange, filter.docTime, [
		fields.dateFrom,
		fields.dateTo,
	]);
	const params = builder.biuldParams(filter);
	return params;
}

export function adaptDocument(rawData = {}) {
	const adaptedData = documentsListAdapter.adapt(rawData);
	return adaptedData;
}
