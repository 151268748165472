const actions = {
	DAY_MATCHES_FILTER_SET_VALUE   : 'DAY_MATCHES_FILTER_SET_VALUE',
	DAY_MATCHES_FILTER_RESET_VALUES: 'DAY_MATCHES_FILTER_RESET_VALUES',

	setValue: (field, value) => ({
		type: actions.DAY_MATCHES_FILTER_SET_VALUE,
		data: {
			field,
			value,
		},
	}),

	resetValues: () => ({
		type: actions.DAY_MATCHES_FILTER_RESET_VALUES,
	}),
};

export default actions;
