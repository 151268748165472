import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';
import externalSaga from './externalSaga';

import tableActions from '../../tables/actions';
import { deriveTablePagination } from '../../../selectors/tables';

import { getListParams, adaptTransactionList } from './utils';

import { casinoAPI } from '../../../helpers/api/casino';
import { casinoAPI as socketsCasinoAPI } from '../../../helpers/sockets/api/casino';
import { getHeadersTotalCount } from '../../../helpers/utils';
import notifications from '../../../helpers/notifications';
import { createError } from '../../../helpers/sockets/socket';
import { TABLE_TYPES } from '../../../constants/tableTypes';
import socetActions from '../../socket/actions';

const prefix = 'rtm.casino';

const messages = {
	errorListReload       : `${prefix}.errorListReload`,
	errorSubscribeUpdate  : `${prefix}.errorSubscribeUpdate`,
	successSubscribeUpdate: `${prefix}.successSubscribeUpdate`,
	errorGetTable         : `${prefix}.errorGetTable`,
	errorPostTable        : `${prefix}.errorPostTable`,
};


const tableType          = TABLE_TYPES.casinoRTM;

function getStoreData(state) {

	const { RTM, Tables, AppTabs } = state;

	return {
		filter     : RTM.Casino.get('filter'),
		sorting    : Tables.get(tableType).sorting,
		pagination : deriveTablePagination(tableType, state),
		activeTabID: AppTabs.get('activeTabID'),

	};
}

function* listReload() {

	yield takeEvery(actions.RTM_CASINO_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));
		const { filter, sorting, pagination, activeTabID } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);

		let transactionIDs = [];
		let entities       = {};
		let casinoTotals   = {};
		let totalCount     = 0;
		try {
			const res = yield call(casinoAPI.rtmCasinoList, params);
			if (res && res.status === 200) {
				const result   = adaptTransactionList(res.data.data);
				transactionIDs = result.transactionIDs; // eslint-disable-line prefer-destructuring
				entities       = result.entities;       // eslint-disable-line prefer-destructuring
				casinoTotals   = result.casinoTotals;   // eslint-disable-line prefer-destructuring
				totalCount     = getHeadersTotalCount(res.headers) || transactionIDs.length;

				yield put(actions.dataRefresh(transactionIDs, entities, casinoTotals));
				yield put(socetActions.rtmSubscribe(activeTabID, transactionIDs));
			}

			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			notifications.showError(messages.errorListReload);
			console.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {

	yield takeEvery(actions.RTM_CASINO_FILTER_APPLY, function* () {
		yield put(actions.listReload());
	});
}

function* subscribeToUpdate() {

	yield takeEvery(actions.RTM_CASINO_SOCKET_SUBSCRIBE_TO_UPDATE, function* (action) {

		const { transactionIDs } = action.data;
		try {
			const response = yield call(socketsCasinoAPI.subscribeToUpdate, transactionIDs);
			if (response) {
				if (response.isSuccess) {
					//notifications.showSuccess(messages.successSubscribeUpdate);
				} else {
					const error = createError(response);
					throw error;
				}
			}
		} catch (error) {
			notifications.showError(messages.errorSubscribeUpdate);
			console.log(error);
		}
	});
}

export default function* rtmCasinoSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
		fork(subscribeToUpdate),

		fork(externalSaga),
	]);
}
