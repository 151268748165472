import React from 'react';
import { Icon } from 'react-icons-kit';
import { Icons } from './icons';
// Ionic
// import { list as categoriesMenu } from 'react-icons-kit/iconic/list';
// import { monitor as rtmMenu } from 'react-icons-kit/iconic/monitor';
// import { tags as rtmBets } from 'react-icons-kit/iconic/tags';
// import { target as rtmCasino } from 'react-icons-kit/iconic/target';
// import { pound as rtmDeposits } from 'react-icons-kit/iconic/pound';
// import { eject as rtmWithdrawals } from 'react-icons-kit/iconic/eject';
// import { flag as marketsMenu } from 'react-icons-kit/iconic/flag';
// import { document as marketsTemplates } from 'react-icons-kit/iconic/document';
// import { rich as marketGroups } from 'react-icons-kit/iconic/rich';
// import { book as contentMenu } from 'react-icons-kit/iconic/book';
// import { cog as settingsMenu } from 'react-icons-kit/iconic/cog';
// import { shield as securityMenu } from 'react-icons-kit/iconic/shield';
// import { image as bannersMenu } from 'react-icons-kit/iconic/image';
// import { closed as emailsMenu } from 'react-icons-kit/iconic/closed';
// import { aperture as casinoMenu } from 'react-icons-kit/iconic/aperture';
// import { home as homeManageMenu } from 'react-icons-kit/iconic/home';
// import { chat as messages } from 'react-icons-kit/iconic/chat';
// // Material Design
// import { ic_directions_run as categoriesTree } from 'react-icons-kit/md/ic_directions_run';
// import { ic_av_timer as settingsScopes } from 'react-icons-kit/md/ic_av_timer';
// import { ic_view_agenda as settingsStaticticTypes } from 'react-icons-kit/md/ic_view_agenda';
// import { ic_pan_tool as participantsMenu } from 'react-icons-kit/md/ic_pan_tool';
// import { ic_motorcycle as participantsList } from 'react-icons-kit/md/ic_motorcycle';
// import { ic_call_made as participantsAges } from 'react-icons-kit/md/ic_call_made';
// import { ic_filter_9_plus as marketsLadders } from 'react-icons-kit/md/ic_filter_9_plus';
// import { ic_laptop_windows as marketsDisplayTypes } from 'react-icons-kit/md/ic_laptop_windows';
// import { ic_event as events } from 'react-icons-kit/md/ic_event';
// import { ic_grade as results } from 'react-icons-kit/md/ic_grade';
// import { ic_euro_symbol as currencies } from 'react-icons-kit/md/ic_euro_symbol';
// import { ic_people as usersMenu } from 'react-icons-kit/md/ic_people';
// import { ic_people_outline as UsersGroups } from 'react-icons-kit/md/ic_people_outline';
// import { ic_school as UsersRoles } from 'react-icons-kit/md/ic_school';
// import { ic_work as transactions } from 'react-icons-kit/md/ic_work';
// import { ic_description as contentInfoPages } from 'react-icons-kit/md/ic_description';
// import { ic_extension as contentNews } from 'react-icons-kit/md/ic_extension';
// import { ic_receipt as contentArticles } from 'react-icons-kit/md/ic_receipt';
// import { ic_border_top as contentHeader } from 'react-icons-kit/md/ic_border_top';
// import { ic_border_bottom as contentFooter } from 'react-icons-kit/md/ic_border_bottom';
// import { ic_filter_b_and_w as settingsSidebarEditor } from 'react-icons-kit/md/ic_filter_b_and_w';
// import { ic_public as websites } from 'react-icons-kit/md/ic_public';
// import { ic_payment as payments } from 'react-icons-kit/md/ic_payment';
// import { ic_help as securityQuestions } from 'react-icons-kit/md/ic_help';
// import { ic_lock as securityBlockedIP } from 'react-icons-kit/md/ic_lock';
// import { ic_storage as securityWhitelist } from 'react-icons-kit/md/ic_storage';
// import { ic_perm_media as bannersList } from 'react-icons-kit/md/ic_perm_media';
// import { ic_burst_mode as bannersGroups } from 'react-icons-kit/md/ic_burst_mode';
// import { ic_email as emailsList }  from 'react-icons-kit/md/ic_email';
// import { ic_message as emailsTemplates } from 'react-icons-kit/md/ic_message';
// import { iosList as casinoCustomProviders } from 'react-icons-kit/ionicons/iosList';
// import { ic_toys as casinoList } from 'react-icons-kit/md/ic_toys';
// import { ic_rotate_90_degrees_ccw_twotone as casinoIntegrators } from 'react-icons-kit/md/ic_rotate_90_degrees_ccw_twotone';

// import { ic_videogame_asset as casinoGameCategories } from 'react-icons-kit/md/ic_videogame_asset';
// import { ic_swap_vert as homeManageSportPriorities } from 'react-icons-kit/md/ic_swap_vert';
// import { ic_update as homeManageSpecialOffers } from 'react-icons-kit/md/ic_update';
// import { ic_date_range as homeManageUpcomingEvents } from 'react-icons-kit/md/ic_date_range';
// import { ic_touch_app as homeManageMatchesOfDay } from 'react-icons-kit/md/ic_touch_app';
// import { ic_wb_sunny as bonusesFreeSpins } from 'react-icons-kit/md/ic_wb_sunny';
// import { ic_gps_fixed as bonusesRegFreeSpins } from 'react-icons-kit/md/ic_gps_fixed';
// import { ic_accessibility as bonusesWelcomeBonus } from 'react-icons-kit/md/ic_accessibility';
// import { ic_account_balance_wallet as bonusesFirstDeposit } from 'react-icons-kit/md/ic_account_balance_wallet';
// import { cash as bonusesCashback } from 'react-icons-kit/ionicons/cash';
// import { users as cashbackUsers } from 'react-icons-kit/entypo/users';
// import { ic_assignment_ind as userInfo } from 'react-icons-kit/md/ic_assignment_ind';
// import { ic_assignment_turned_in as resultInfo } from 'react-icons-kit/md/ic_assignment_turned_in';
// import { ic_picture_in_picture as eventInfo } from 'react-icons-kit/md/ic_picture_in_picture';
// import { ic_insert_drive_file as contentPage } from 'react-icons-kit/md/ic_insert_drive_file';
// import { ic_turned_in_not as contentNewArticle } from 'react-icons-kit/md/ic_turned_in_not';
// import { ic_grid_on_twotone as contentLandingPageGameGrid } from 'react-icons-kit/md/ic_grid_on_twotone';
// import { ic_monetization_on as paymentPayways } from 'react-icons-kit/md/ic_monetization_on';
// import { ic_gamepad as casinoGames } from 'react-icons-kit/md/ic_gamepad';
// import { ic_done_all as bettingRules } from 'react-icons-kit/md/ic_done_all';
// import { ic_notifications as pushNotifications } from 'react-icons-kit/md/ic_notifications';
// import { ic_card_giftcard as freeBets } from 'react-icons-kit/md/ic_card_giftcard';
// import { streetView as reportByPlayer } from 'react-icons-kit/fa/streetView';
// import { gamepad as reportByGame } from 'react-icons-kit/fa/gamepad';
// import { ic_bar_chart as reportByProvider } from  'react-icons-kit/md/ic_bar_chart';
// import { ic_collections_bookmark as reportByIntegrator } from 'react-icons-kit/md/ic_collections_bookmark';
// import { ic_textsms as smsGateways } from 'react-icons-kit/md/ic_textsms';

/* eslint-disable */
const md = 16; // standart
const sm = 14; // for Ionic in App Tabs
const lg = 18; // for Material in Sidebar
const style = { marginRight: '10px' };

export const createIcon = (icon, size = md, className = 'sidebar-icon', rotateAngle = 0) => {

	return (
		<Icon icon={icon} size={size} className={className} style={{ transform: `rotate(${rotateAngle}deg)` }} />
	);
};

export const sidebarIcons = {
	'rtm'               : <Icons.RtmIcon style={style} />,
	'dashboard'         : <Icons.DashboardIcon style={style} />,
	'staff'             : <Icons.StaffIcon style={style} />,
	'partners'          : <Icons.PartnersIcon style={style} />,
	'users'             : <Icons.CustomerIcon style={style} />,
	'loyalty'           : <Icons.BonusIcon style={style} />,
	// 'bonusingSystem'                : <Icons.BonusIcon style={style} />,
	'casino'            : <Icons.CasinoIcon style={style} />,
	'sport'             : <Icons.SportIcon style={style} />,
	'settings'          : <Icons.SettingsIcon style={style} />,
	'cms'               : <Icons.CmsIcon style={style} />,
	'reports'           : <Icons.ReportsIcon style={style} />,
	'notificationCenter': <Icons.NotificationCenterIcon style={style} />,
	'affiliates'        : <Icons.AffiliatesIcon style={style} />,
	// 'categoriesMenu'             : createIcon(categoriesMenu),
	// 'categories/tree'            : createIcon(categoriesTree),
	// 'settings/scopes'            : createIcon(settingsScopes),
	// 'settings/statisticTypes'    : createIcon(settingsStaticticTypes),
	// 'bettingRules'               : createIcon(bettingRules),

	// 'rtm'                        : createIcon(rtmMenu),
	// 'rtm/bets'                   : createIcon(rtmBets),
	// 'rtm/deposits'               : createIcon(rtmDeposits, sm),
	// 'rtm/withdrawals'            : createIcon(rtmWithdrawals, sm),
	// 'rtm/casino'                 : createIcon(rtmCasino, sm),
	// 'rtm/documents'              : createIcon(marketsTemplates, sm),

	// 'participantsMenu'           : createIcon(participantsMenu),
	// 'participants/list'          : createIcon(participantsList),
	// 'participants/ages'          : createIcon(participantsAges),

	// 'marketsMenu'                : createIcon(marketsMenu),
	// 'markets/templates'          : createIcon(marketsTemplates, sm),
	// 'markets/groups'             : createIcon(marketGroups, sm),
	// 'markets/ladders'            : createIcon(marketsLadders),
	// 'markets/displayTypes'       : createIcon(marketsDisplayTypes),

	// 'events'                     : createIcon(events, lg),
	// 'results'                    : createIcon(results, lg),
	// 'currencies'                 : createIcon(currencies, lg),

	// 'users'                   : createIcon(usersMenu, lg),
	// 'users/list'                 : createIcon(usersMenu),
	// 'users/groups'               : createIcon(UsersGroups),
	// 'users/roles'                : createIcon(UsersRoles),

	// 'staff'                   : createIcon(staff, lg),
	// 'transactions'               : createIcon(transactions),

	// 'contentMenu'                : createIcon(contentMenu),
	// 'content/infoPages'          : createIcon(contentInfoPages),
	// 'content/news'               : createIcon(contentNews),
	// 'content/articles'           : createIcon(contentArticles),
	// 'content/header'             : createIcon(contentHeader),
	// 'content/footer'             : createIcon(contentFooter),

	// // 'settings'                : createIcon(settingsMenu),
	// 'settings/sidebarEditor'     : createIcon(settingsSidebarEditor),

	// 'websites'                   : createIcon(websites, lg),
	// 'payments'                   : createIcon(payments, lg),

	// 'security'                   : createIcon(securityMenu),
	// 'security/questions'         : createIcon(securityQuestions),
	// 'security/blockedIps'        : createIcon(securityBlockedIP),
	// 'security/whitelist'         : createIcon(securityWhitelist),

	// 'banners'                    : createIcon(bannersMenu),
	// 'banners/list'               : createIcon(bannersList),
	// 'banners/groups'             : createIcon(bannersGroups),

	// 'emailsMenu'                 : createIcon(emailsMenu),
	// 'emails/list'                : createIcon(emailsList),
	// 'emails/templates'           : createIcon(emailsTemplates),

	// 'casino'                  : createIcon(casinoMenu),
	// 'casino/list'                : createIcon(casinoList),
	// 'casino/gameCategories'      : createIcon(casinoGameCategories),
	// 'casino/games'               : createIcon(casinoGames),
	// 'casino/customProviders'     : createIcon(casinoCustomProviders),
	// 'casino/integrators'         : createIcon(casinoIntegrators, undefined, 180),

	// 'sport'                    :  createIcon(sportMenu, lg),
	// 'cms'                      : createIcon(cmsMain, lg),
	// 'notificationCenter'       : createIcon(pushNotifications, lg),

	// 'homeManage'                 : createIcon(homeManageMenu),
	// 'homeManage/priorities'      : createIcon(homeManageSportPriorities),
	// 'homeManage/specialOffers'   : createIcon(homeManageSpecialOffers),
	// 'homeManage/upcoming'        : createIcon(homeManageUpcomingEvents),
	// 'homeManage/matchesOfDay'    : createIcon(homeManageMatchesOfDay),

	// 'bonusingSystem'          : createIcon(bonusesMenu, lg),
	// 'bonuses/freeSpins'          : createIcon(bonusesFreeSpins),
	// 'bonuses/registration'       : createIcon(bonusesRegFreeSpins),
	// 'bonuses/welcome'            : createIcon(bonusesWelcomeBonus),
	// 'bonuses/firstDeposit'       : createIcon(bonusesFirstDeposit),
	// 'bonuses/cashback'           : createIcon(bonusesCashback),
	// 'bonuses/usersWithCashback'  : createIcon(cashbackUsers),
	// 'bonuses/freeBets'           : createIcon(freeBets),

	// 'reports'                    : createIcon(reports, lg),
	// 'reports/reportByPlayer'     : createIcon(reportByPlayer, lg),
	// 'reports/reportByGame'       : createIcon(reportByGame, lg),
	// 'reports/reportByProvider'   : createIcon(reportByProvider, lg),
	// 'reports/reportByIntegrator' : createIcon(reportByIntegrator, lg),

	// 'messages'                   : createIcon(messages, lg),
	// 'affiliates'                 : createIcon(affiliates, lg),
	// 'pushNotifications'          : createIcon(pushNotifications, lg),

	// 'Partners'                   : createIcon(partner, lg),
	// 'smsGateways'                : createIcon(smsGateways, lg),
	// Independent pages, non-sidebar
	// 'dashboard'                  : createIcon(dashboard),
	// 'users/info'                 : createIcon(userInfo),
	// 'personal/info'              : createIcon(userInfo),
	// 'results/info'               : createIcon(resultInfo),
	// 'events/info'                : createIcon(eventInfo),
	// 'content/page'               : createIcon(contentPage),
	// 'content/news/article'       : createIcon(contentNewArticle),
	// 'content/landingPageGameGrid': createIcon(contentLandingPageGameGrid),
	// 'payment/payways'            : createIcon(paymentPayways),
	// 'priorities/sport'           : createIcon(homeManageSportPriorities),
	// 'specialOffers/sports'       : createIcon(homeManageSpecialOffers),

};
