import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';

const fields = {
	id        : 'id',
	name      : 'name',
	sportID   : 'sport_id',
	orderID   : 'order_id',
	langID    : 'lang_id',
	categoryID: 'category_id',
};

export function adaptBaseData(rawData = {}) {
	const adapter = createBaseDataAdapter();
	const result = adapter.adapt(rawData);

	return result;
}

export function adaptGroupsList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}
	const adapter = createBaseDataAdapter();

	const result = rawData.map(item => {
		return adapter.adapt(item);
	});

	return result;
}

export function prepareGroupsList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}
	const adapter = createBaseDataAdapter();
	adapter.addExcludeField('id');

	const result = rawData.map(item => {
		const newItem = adapter.prepare(item);
		if (isID(item.id)) {
			newItem.id = toInteger(item.id);
		}

		return newItem;
	});

	return result;
}

function createBaseDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'sportID', fields.sportID);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'categoryID', fields.categoryID);

	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}
