import { makeActionCreator } from '../../../helpers';

const prefix = 'USER_BONUS';

const TYPES = {
	DATA_SAVE   : `${prefix}_DATA_SAVE`,
	DATA_REFRESH: `${prefix}_DATA_REFRESH`,
	DATA_RESET  : `${prefix}_DATA_RESET`,
	UI_REFRESH  : `${prefix}_UI_REFRESH`,

	GAME_LIMITS_RELOAD                   : `${prefix}_GAME_LIMITS_RELOAD`,
	BETS_REFRESH                         : `${prefix}_BETS_REFRESH`,
	DENOMINATIONS_REFRESH                : `${prefix}_DENOMINATIONS_REFRESH`,
	CANCEL_USER_BONUS_DATA               : `${prefix}_CANCEL_USER_BONUS_DATA`,
	BONUS_DATA_RESET                 	   : `${prefix}_BONUS_DATA_RESET`,
	PROVIDERS_LIST_REFRESH               : `${prefix}_PROVIDERS_LIST_REFRESH`,
	RESET_CHECKED_PROVIDER_BY_INTEGRATION: `${prefix}_RESET_CHECKED_PROVIDER_BY_INTEGRATION`,
};

const actions = {
	...TYPES,

	dataSave        : makeActionCreator(TYPES.DATA_SAVE, 'isMassBonusPath'),
	dataRefresh     : makeActionCreator(TYPES.DATA_REFRESH, 'bonusData'),
	providersRefresh: makeActionCreator(TYPES.PROVIDERS_LIST_REFRESH, 'checkedProvidersByIntegrator'),
	dataReset       : makeActionCreator(TYPES.DATA_RESET),
	bonusDataReset  : makeActionCreator(TYPES.BONUS_DATA_RESET),
	uiRefresh       : makeActionCreator(TYPES.UI_REFRESH, 'UI'),

	gameLimitsReload    : makeActionCreator(TYPES.GAME_LIMITS_RELOAD, 'casinoGameID', 'userID', 'currencyCode'),
	betsRefresh         : makeActionCreator(TYPES.BETS_REFRESH, 'bets'),
	denominationsRefresh: makeActionCreator(TYPES.DENOMINATIONS_REFRESH, 'denominations'),
	cancelUserBonusData : makeActionCreator(TYPES.CANCEL_USER_BONUS_DATA),
	resetCheckedData    : makeActionCreator(TYPES.RESET_CHECKED_PROVIDER_BY_INTEGRATION),
};

export default actions;
