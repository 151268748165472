import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import { restoreWebsiteID } from '../../../../helpers/utility';

import actions from './actions';
import listActions from '../list/actions';

import { paymentsAPI } from '../../../../helpers/api/payments';
import { currencyAPI } from '../../../../helpers/api/currency';
import notifications from '../../../../helpers/notifications';
import { isID, entitiesToEnumList } from '../../../../helpers/utils';

import {
	adaptPayment,
	preparePayment,
	adaptCurrencyData,
	prepareCurrencyData,
	adaptNamesData,
	prepareNames, getParams,
} from './utils';

const prefix = 'settings.payments.payment';

const messages = {
	errorPaymentReload : `${prefix}.errorPaymentReload`,
	errorPaymentSave   : `${prefix}.errorPaymentSave`,
	errorCurrencyReload: `${prefix}.errorCurrencyReload`,
	errorCurrencySave  : `${prefix}.errorCurrencySave`,
	errorNamesReload   : `${prefix}.errorNamesReload`,
	errorNamesSave     : `${prefix}.errorNamesSave`,
	successPaymentSave : `${prefix}.successPaymentSave`,
	successCurrencySave: `${prefix}.successCurrencySave`,
	successNamesSave   : `${prefix}.successNamesSave`,
};

function getStoreData({ App, Settings }) {

	return {
		baseData           : Settings.Payments.Payment.get('baseData'),
		namesData          : Settings.Payments.Payment.get('namesData'),
		currencyData       : Settings.Payments.Payment.get('currencyData'),
		UI                 : Settings.Payments.Payment.get('UI'),
		currencyList       : entitiesToEnumList(Settings.CurrencyModule.get('entities')),
		contentLanguageList: App.get('websiteAttachedLanguages'),
	};
}

function* baseDataReload() {

	yield takeEvery(actions.PAYMENT_BASE_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const { paymentID, forced } = action.data;

		// data from list
		let baseData = cloneDeep(storeData.baseData) || {};
		if (isID(baseData.id) && !forced) {
			yield put(actions.currencyDataReload(paymentID));
			return;
		}

		// loading data
		const params = { lang_id: storeData.UI.langID };
		try {
			const res = yield call(paymentsAPI.paymentInfo, paymentID, params);
			if (res && res.status === 200) {
				baseData = adaptPayment(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorPaymentReload, error);
		}

		yield put(actions.baseDataRefresh(baseData));
		yield put(actions.currencyDataReload());
		yield put(actions.uiRefresh({ isBaseChanged: false }));
	});
}

function* currencyDataReload() {

	yield takeEvery(actions.PAYMENT_CURRENCY_DATA_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { currencyList } = yield select(getStoreData);
		let currencyData = [];

		try {
			const res = yield call(currencyAPI.currencyPaywaysList);
			if (res && res.status === 200) {
				currencyData = adaptCurrencyData(res.data.data, currencyList);
			}
		} catch (error) {
			notifications.showError(messages.errorCurrencyReload, error);
			console.log(error);
		}

		yield put(actions.currencyDataRefresh(currencyData));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* namesDataReload() {

	yield takeEvery(actions.PAYMENT_NAMES_DATA_RELOAD, function* (action) {
		const { contentLanguageList } = yield select(getStoreData);

		yield put(actions.uiRefresh({ loading: true }));

		const { paymentID } = action.data;

		let namesData = adaptNamesData([], contentLanguageList);
		try {
			const res = yield call(paymentsAPI.paymentNamesList, paymentID);
			if (res && res.status === 200) {
				namesData = adaptNamesData(res.data.data, contentLanguageList);
			}
		} catch (error) {
			notifications.showError(messages.errorNamesReload, error);
			console.log(error);
		}

		yield put(actions.namesDataRefresh(namesData));
		yield put(actions.uiRefresh({
			loading       : false,
			isNamesChanged: false,
		}));
	});
}

function* paymentSave() {

	yield takeEvery(actions.PAYMENT_SAVE, function* () {
		const websiteID = restoreWebsiteID();
		yield put(actions.uiRefresh({ loading: true }));

		const { baseData, namesData, currencyData, UI } = yield select(getStoreData);
		const {
			paymentID,
			closeModal,
			langID,
			isBaseChanged,
			isCurrencyChanged,
			isNamesChanged,
		} = UI;

		const paymentBaseData = cloneDeep(baseData);
		let isError = false;
		let errorMessage = '';


		try {
			// base data
			errorMessage = messages.errorPaymentSave;
			const params = getParams({ websiteID });
			if (isBaseChanged) {
				const preparedData = preparePayment(paymentBaseData, langID, paymentID);
				yield call(paymentsAPI.paymentUpdate, paymentID, preparedData, params);
				yield put(actions.uiRefresh({ isBaseChanged: false }));
			}

			// currency
			if (isCurrencyChanged) {
				errorMessage = messages.errorCurrencySave;
				const paywayData = prepareCurrencyData(currencyData);
				for (let i = 0; i < paywayData.length; i++) {
					yield call(currencyAPI.currencyPaywaysUpdate, paywayData[i]);
				}

				yield put(actions.uiRefresh({ isCurrencyChanged: false }));
			}

			// names
			if (isNamesChanged) {
				errorMessage = messages.errorNamesSave;
				const preparedData = prepareNames(namesData, paymentID);
				yield call(paymentsAPI.paymentNamesUpdate, paymentID, preparedData, params);
				yield put(actions.uiRefresh({ isNamesChanged: false }));
			}

			notifications.showSuccess(messages.successPaymentSave);

		} catch (error) {
			isError = true;
			notifications.showError(errorMessage, error);
			console.log(error);
		}

		if (!isError && !closeModal) {
			yield put(actions.baseDataReload(paymentID, true));
		}

		yield put(listActions.listReload());
		yield put(actions.uiRefresh({
			loading: false,
			visible: !(closeModal && !isError),
		}));
	});
}

export default function* paymentModalSaga() {
	yield all([
		fork(baseDataReload),
		fork(currencyDataReload),
		fork(namesDataReload),
		fork(paymentSave),
	]);
}
