// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd'

import appActions from '../../redux/app/actions';
import appTabsActions from '../../redux/appTabs/actions';
import commonActions from '../../redux/common/actions';
import currencyActions from '../../redux/settings/currency/actions';
import sidebarActions from '../../redux/sidebar/actions';
import websitesActions from '../../redux/partner/websites/list/actions';
import { actions as partnerActions } from '../../redux/partner/partners/list/actions';
import { deriveToken } from '../../selectors/auth';
import { deriveAllNotificationsCount } from '../../selectors/notifications';
import { deriveLanguage } from '../../selectors/language';
import { deriveTheme } from '../../selectors/theme';
import { deriveActiveTabTitle } from '../../selectors/appTabs';
import { siteConfig } from '../../config';
import '../../../src/containers/App/global.css';
import Topbar from '../../containers/Topbar/Topbar';
import { IMainLayout } from './types';
import SidebarFC from "../../containers/Sidebar/SidebarFC";
import { MainContentWrapper } from "./layout.styles.js";
import { Navigate } from "react-router";
import { Routes } from "../../routes/routesData.tsx";
import UserSearch from "../../containers/CustomerManagement/UserSearch";
import { deriveApp } from "../../selectors/app";
import AppTabsManagerFC from "../../containers/AppTabsManager/AppTabsManagerFC";
import { NotificationsRoot } from '../../components/NotificationPortal/NotificationsRoot';

const { Footer } = Layout;

export const MainLayout: React.FC<IMainLayout> = (props) => {
	const { idToken, partnersWithWebsitesReload, tabsReload } = props;

	if (!idToken) {
		return <Navigate to={Routes.signin} />;
	}

	useEffect(() => {
		partnersWithWebsitesReload();
		tabsReload();
	},[])

	return (
		<Layout hasSider>
				<NotificationsRoot />
				<SidebarFC />
			<Layout className="site-layout" >
				<Topbar />
				<MainContentWrapper >
						<AppTabsManagerFC />
						{props.children}
				</MainContentWrapper>

				<Footer style={{ textAlign: 'center', display: 'flex' }}>
					{siteConfig.footerText}
					<UserSearch />
				</Footer>
			</Layout>
		</Layout>
	);
};


function mapStateToProps(state: { App: any; Staff: any; }) {
	const { locale }           = deriveLanguage(state);
	const { themeName }        = deriveTheme(state);
	const { App, Staff }              = state;
	const passwordExpiredModal = App.get('modals').passwordExpiredModal.visible;
	const { partnerLoading } = Staff.Person.get('UI');
	return {
		locale,
		selectedTheme     : themeName,
		idToken           : deriveToken(state),
		activeTabTitle    : deriveActiveTabTitle(state),
		notificationsCount: deriveAllNotificationsCount(state),
		passwordExpiredModal,
		partnerLoading,
		match             : {},
		collapsed         : deriveApp(state).collapsed,
	};
}


const mapActions = {
	toggleAll                 : appActions.toggleAll,
	websiteRestore            : appActions.websiteRestore,
	partnerRestore            : appActions.partnerRestore,
	websiteSettingsReload     : appActions.websiteSettingsReload,
	currencyListReload        : currencyActions.currencyListReload,
	commonSportListReload     : commonActions.commonSportListReload,
	commonProviderListReload  : commonActions.commonProviderListReload,
	commonRiskGroupListReload : commonActions.commonRiskGroupListReload,
	commonAdminListReload     : commonActions.commonAdminListReload,
	sidebarReload             : sidebarActions.sidebarReload,
	websitesListReload        : websitesActions.listReload,
	tabsReload                : appTabsActions.tabsReload,
	partnersWithWebsitesReload: partnerActions.partnersWithWebsitesReload,
}


export default connect(mapStateToProps, mapActions)(MainLayout);
