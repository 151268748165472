const actions = {
	BONUSES_FREE_BETS_FILTER_REFRESH: 'BONUSES_FREE_BETS_FILTER_REFRESH',
	BONUSES_FREE_BETS_LIST_RELOAD   : 'BONUSES_FREE_BETS_LIST_RELOAD',
	BONUSES_FREE_BETS_LIST_REFRESH  : 'BONUSES_FREE_BETS_LIST_REFRESH',
	BONUSES_FREE_BETS_UI_REFRESH    : 'BONUSES_FREE_BETS_UI_REFRESH',

	filterRefresh: (filter) => ({
		type: actions.BONUSES_FREE_BETS_FILTER_REFRESH,
		data: { filter },
	}),

	listReload: () => ({
		type: actions.BONUSES_FREE_BETS_LIST_RELOAD,
	}),
	uiRefresh: (UI) => ({
		type: actions.BONUSES_FREE_BETS_UI_REFRESH,
		data: { UI },
	}),

	listRefresh: entities => ({
		type: actions.BONUSES_FREE_BETS_LIST_REFRESH,
		data: {
			entities,
		},
	}),
};

export default actions;
