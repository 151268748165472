//import { normalize, schema } from 'normalizr';
import toInteger from 'lodash/toInteger';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import EntityAdapter from '../../../helpers/entityAdapter';
import { isID } from '../../../helpers/utils';
import { IMAGE_LINK_TYPES, CHANNEL_TYPES } from '../../../helpers/commonConstants';
import { channelTypeList } from '../../../helpers/commonEnums';
import { fields, createListAdapter } from '../list/utils';

const listAdapter  = createListAdapter();
const authAdapter  = createAuthAdapter();
const namesAdapter = createNamesAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptCasino(rawData = []) {
	listAdapter.clearExcludes();

	if (!isArray(rawData) || isEmpty(rawData)) {
		const adaptedData = listAdapter.adapt(rawData);
		return adaptedData;
	}

	const adaptedData = listAdapter.adapt(rawData[0]);

	adaptedData.type = adaptedData.logo.includes('http')
		? IMAGE_LINK_TYPES.external
		: IMAGE_LINK_TYPES.internal;

	return adaptedData;
}

export function adaptAuthData(rawData = []) {

	authAdapter.clearExcludes();

	const adaptedData = authAdapter.adaptList(rawData);

	return adaptedData;
}

export function adaptNamesData(rawData = [], defaultName = null) {

	namesAdapter.clearExcludes();

	const result = [];
	const adaptedList = namesAdapter.adaptList(rawData);

	channelTypeList.forEach( channelItem => {

		const channelID   = channelItem.id;
		// const channelName = channelItem.name;

		const namesItem = find(adaptedList, { channelID }) || {};

		const record = {
			channelID,
			// channel     : channelName,
			name: namesItem.name,
			// altName     : namesItem.altName,
			// description : namesItem.description,
		};

		if (channelID === CHANNEL_TYPES.backend && !record.name && defaultName) {
			record.name = defaultName;
		}

		result.push(record);
	});

	return result;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareCasino(baseData) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	const preparedData = listAdapter.prepare(baseData);
	if (isID(baseData.id)) {
		preparedData.id = toInteger(baseData.id);
	}

	return preparedData;
}

export function prepareAuthData(rawData = [], casinoID) {

	authAdapter.clearExcludes();
	authAdapter.addExcludeField('id');
	authAdapter.addExcludeField('casinoID');

	const preparedData = rawData.map( rawItem => {
		const item = authAdapter.prepare(rawItem);

		item[fields.casinoID] = casinoID;
		if (isID(rawItem.id)) {
			item.id = toInteger(rawItem.id);
		}

		return item;
	});

	return preparedData;
}

export function prepareNamesData(rawData = [], casinoID, langID) {

	namesAdapter.clearExcludes();

	const preparedData = rawData.map( rawItem => {
		const item = namesAdapter.prepare(rawItem);

		item[fields.casinoID] = casinoID;
		item[fields.langID]   = langID;

		return item;
	});

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------

export function createAuthAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'casinoID', fields.casinoID);

	adapter.addField(rules.string, 'authIP', fields.authIP);

	return adapter;
}

export function createNamesAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'casinoID', fields.casinoID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'langID', fields.langID);

	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}
