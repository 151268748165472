import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';

import { showError, showSuccess } from '../../../helpers/notifications';
import { usersAPI } from '../../../helpers/api/users';

import actions from './actions';
import { actions as partnerActions } from '../../partner/partners/list/actions';
import listActions from '../list/actions';
import appTabsActions from '../../appTabs/actions';
import { prepareAdminPerson } from '../list/utils';
import { normalizePartnerWebsiteData } from './utils';

const prefix = 'staff.person';

const messages = {
	errorBaseDataSave  : `${prefix}.errorBaseDataSave`,
	successBaseDataSave: `${prefix}.successBaseDataSave`,
};

function getStoreData({ Staff, Partner }) {
	const { Person } = Staff;
	const UI         = Person.get('UI');
	const partnersWithWebsites = Partner.Partner.List.get('partnersWithWebsites');

	const selectedPartnersAndWebsites = Partner.Partner.List.get('selectedPartnersAndWebsites');

	return {
		baseData  : Person.get('baseData'),
		closeModal: UI.closeModal,
		goToUser  : UI.goToUser,
		selectedPartnersAndWebsites,
		partnersWithWebsites,
	};
}

function* baseDataSave() {

	yield takeEvery(actions.BASE_DATA_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const {
			baseData,
			closeModal,
			goToUser,
			selectedPartnersAndWebsites,
			partnersWithWebsites,
		} = yield select(getStoreData);
		const { partnerIDs, websiteIDs } = normalizePartnerWebsiteData(selectedPartnersAndWebsites, partnersWithWebsites);
		baseData.partnerIDs = partnerIDs;
		baseData.websiteIDs = websiteIDs;
		const adminData = prepareAdminPerson(baseData);

		let userID  = null;
		let isError = false;
		try {
			const res = yield call(usersAPI.adminCreate, adminData);
			if (res && res.status === 200) {
				showSuccess(messages.successBaseDataSave);
				userID = toInteger(res.data.data.id);
			}

		} catch (error) {
			isError = true;
			showError(messages.errorBaseDataSave, error);
			console.log(error);
		}

		if (goToUser && !isError) {
			yield put(actions.dataReset());
			yield put(partnerActions.dataReset());
			yield put(appTabsActions.openTabUserInfo(userID));

		} else if (!isError && closeModal) {
			yield put(actions.dataReset());
			yield put(partnerActions.dataReset());


		} else if (!isError && !closeModal) {
			yield put(actions.dataReset());
			yield put(partnerActions.dataReset());

			yield put(actions.uiRefresh({ visible: true }));

		} else {
			yield put(actions.uiRefresh({ loading: false }));
		}

		yield put(listActions.listReload());
	});
}

export default function* personSaga() {
	yield all([
		fork(baseDataSave),
	]);
}
