import { availableFields } from '../../../../redux/reports/reportsByGame/utils';


const lang                       = {
	providerName  : 'reportByGame.provider',
	wonAmountTotal: 'reportByGame.wonAmountTotal',
	ggr           : 'reportByGame.ggr',
	casinoGame    : 'reportByGame.casinoGame',
	spinsCount    : 'reportByGame.spinsCount',
	stakeTotal    : 'reportByGame.stakeTotal',
	integrator    : 'reportByGame.integrator',
	profitability : 'reportByGame.profitability',
};
export const defaultReportFields = availableFields.map(fieldKey => {
	return {
		id     : fieldKey,
		intlID : lang[fieldKey],
		checked: true,
	};
});
