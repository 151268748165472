import { Map } from 'immutable';
import dayjs from 'dayjs';
import actions from './actions';
import { FORMATS } from '../../../helpers/commonConstants';

const yearAgo = dayjs()
	.subtract(12, 'months')
	.format(FORMATS.date);
const yearNow = dayjs()
	.add(1, 'days')
	.format(FORMATS.date);

const initState = new Map({
	betslipTypesList: [],
	marketTypeList  : [],
	countriesList   : [],
	leaguesList     : [],
	sportsList      : [],
	placedAmountList: [],

	UI: {
		dateFrom: yearAgo,
		dateTo  : yearNow,
		loading : false,
	},
});

export default function statisticReducer(state = initState, action) {
	switch (action.type) {
	case actions.USER_STATISTIC_BETSLIPS_REFRESH: {
		return state.set('betslipTypesList', action.data);
	}

	case actions.USER_STATISTIC_MARKETS_REFRESH: {
		return state.set('marketTypeList', action.data);
	}

	case actions.USER_STATISTIC_COUNTRIES_REFRESH: {
		return state.set('countriesList', action.data);
	}

	case actions.USER_STATISTIC_LEAGUES_REFRESH: {
		return state.set('leaguesList', action.data);
	}

	case actions.USER_STATISTIC_SPORTS_REFRESH: {
		return state.set('sportsList', action.data);
	}

	case actions.USER_STATISTIC_PLACED_AMOUNTS_REFRESH: {
		return state.set('placedAmountList', action.data);
	}

	case actions.USER_STATISTIC_SET_VALUE_UI: {
		const { valueName, value } = action.data;
		const UI = new Map(state.get('UI')).toObject();
		UI[valueName] = value;

		return state.set('UI', UI);
	}

	default:
		return state;
	}
}
