const actions = {

	EMAILS_LIST_RELOAD : 'EMAILS_LIST_RELOAD',
	EMAILS_LIST_REFRESH: 'EMAILS_LIST_REFRESH',

	EMAILS_LIST_RESEND_EMAIL: 'EMAILS_LIST_RESEND_EMAIL',

	EMAILS_LIST_FILTER_REFRESH: 'EMAILS_LIST_FILTER_REFRESH',
	EMAILS_LIST_FILTER_APPLY  : 'EMAILS_LIST_FILTER_APPLY',

	listReload: () => ({
		type: actions.EMAILS_LIST_RELOAD,
	}),
	listRefresh: entities => ({
		type: actions.EMAILS_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	resendEmail: emailID => ({
		type: actions.EMAILS_LIST_RESEND_EMAIL,
		data: {
			emailID,
		},
	}),
	filterRefresh: data => ({
		type: actions.EMAILS_LIST_FILTER_REFRESH,
		data,
	}),
	filterApply: () => ({
		type: actions.EMAILS_LIST_FILTER_APPLY,
	}),
};

export default actions;
