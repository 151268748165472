import isArray from 'lodash/isArray';
import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import notifications from '../../../../helpers/notifications';
import { eventsAPI } from '../../../../helpers/api/events';

import actions from './actions';
import eventActions from '../event/actions';

import {
	OWNERS,
	adaptSettings,
	prepareSettings,
	adaptDynamiLimits,
	prepareDynamicLimits,
} from '../utils';
import { prepareBaseData } from './utils';


const prefix = 'sport.event.market';

const messages = {
	errorUpdateEventMarket      : `${prefix}.errorUpdateEventMarket`,
	errorCancelEventMarket      : `${prefix}.errorCancelEventMarket`,
	errorSettingsEventMarket    : `${prefix}.errorSettingsEventMarket`,
	errorDynamicLimits          : `${prefix}.errorDynamicLimits`,
	errorUpdateEventMarketStatus: `${prefix}.errorUpdateEventMarketStatus`,
	successStatusUpdate         : `${prefix}.successStatusUpdate`,
	successCancelEventMarket    : `${prefix}.successCancelEventMarket`,
	successUpdateEventMarket    : `${prefix}.successUpdateEventMarket`,
};

function getStoreData({ Sport: { Events } }) {

	return {
		eventID          : Events.Event.get('baseData').id,
		baseData         : Events.Market.get('baseData'),
		settingsData     : Events.Market.get('settingsData'),
		dynamicLimitsData: Events.Market.get('dynamicLimitsData'),
		UI               : Events.Market.get('UI'),
	};
}

function* marketUpdate() {
	const takeAction = actions.EVENT_MARKET_UPDATE;

	yield takeEvery(takeAction, function* (action) {
		const { eventMarketID, closeModal } = action.data;
		const {
			eventID,
			baseData,
			settingsData,
			dynamicLimitsData,
			UI,
		} = yield select(getStoreData);

		const preparedData = prepareBaseData(baseData);
		let isError = false;
		try {
			// Base data
			yield call(eventsAPI.eventMarketUpdate, eventMarketID, preparedData);

			// Settings
			if (UI.isChangedSettings) {
				const marketTradingModeID = baseData.tradingModeID;
				const preparedSettings = prepareSettings(
					settingsData,
					OWNERS.eventMarket,
					marketTradingModeID
				);

				yield call(eventsAPI.eventMarketSettingsUpdate, eventMarketID, preparedSettings);
				yield put(actions.setValueUI('isChangedSettings', false));
			}

			// Dynamic Limits
			if (UI.isChangedDynamicLimits) {
				const preparedLimits = prepareDynamicLimits(
					dynamicLimitsData,
					OWNERS.eventMarket,
					eventMarketID
				);
				yield call(eventsAPI.eventMarketDynamicLimitsUpdate, eventMarketID, preparedLimits);
				yield put(actions.setValueUI('isChangedDynamicLimits', false));
			}

			notifications.showSuccess(messages.successUpdateEventMarket);

		} catch (error) {
			isError = true;
			notifications.showError(messages.errorUpdateEventMarket, error);
			console.log(error);
		}

		if (!isError) {
			yield put(eventActions.oddsDataReload(eventID));
			if (!closeModal) {
				if (UI.isChangedSettings) {
					yield put(actions.settingsDataReload(eventMarketID));
				}
				if (UI.isChangedDynamicLimits) {
					yield put(actions.dynamicLimitsDataReload(eventMarketID));
				}
			}
		}

		yield put(actions.setValueUI('loading', false));

		if (closeModal && !isError) {
			yield put(actions.dataReset());
		}
	});
}

function* marketCancel() {
	const takeAction = actions.EVENT_MARKET_CANCEL;

	yield takeEvery(takeAction, function* (action) {
		const { eventID, eventMarketID } = action.data;
		try {
			const response = yield call(eventsAPI.eventMarketCancel, eventMarketID);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successCancelEventMarket);
				yield put(eventActions.oddsDataReload(eventID));
			}
		} catch (error) {
			notifications.showError(messages.errorCancelEventMarket, error);
			console.log(error);
		}
	});
}

function* settingsDataReload() {
	const takeAction = actions.EVENT_MARKET_SETTINGS_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		const { eventMarketID } = action.data;
		let settings = adaptSettings([], OWNERS.eventMarket);
		const params = { include_parent_limit: true };

		try {
			const response = yield call(
				eventsAPI.eventMarketSettingsList,
				eventMarketID,
				params
			);
			if (response && response.status === 200) {
				settings = adaptSettings(response.data.data, OWNERS.eventMarket);
			}
		} catch (error) {
			notifications.showError(messages.errorSettingsEventMarket, error);
			console.log(error);
		}
		yield put(actions.settingsDataRefresh(settings));
	});
}

function* dynamicLimitsDataReload() {
	const takeAction = actions.EVENT_MARKET_DYNAMIC_LIMITS_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		const { eventMarketID } = action.data;
		let limits = [];

		try {
			const response = yield call(
				eventsAPI.eventMarketDynamicLimitsList,
				eventMarketID
			);
			if (response && response.status === 200) {
				limits = adaptDynamiLimits(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorDynamicLimits, error);
			console.log(error);
		}

		yield put(actions.dynamicLimitsDataRefresh(limits));
	});
}

function* marketStatusUpdate() {
	const takeAction = actions.EVENT_MARKET_STATUS_UPDATE;

	yield takeEvery(takeAction, function* (action) {

		yield put(actions.setValueUI('loading', true));

		const { eventMarketID, statusID, onSuccess } = action.data;
		const preparedData = {
			id       : eventMarketID,
			status_id: statusID,
		};

		try {
			const res = yield call(eventsAPI.eventMarketUpdate, eventMarketID, preparedData);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successStatusUpdate);
				if (onSuccess) {
					onSuccess();
				}
			}

		} catch (error) {
			notifications.showError(messages.errorUpdateEventMarketStatus, error);
			console.log(error);
		}

		yield put(actions.setValueUI('loading', false));
	});
}

function* marketStatusUpdateWithSelections() {
	const takeAction = actions.EVENT_MARKET_STATUS_UPDATE_WITH_SELECTIONS;

	yield takeEvery(takeAction, function* (action) {

		yield put(actions.setValueUI('loading', true));

		const { marketID, statusID, selections, onSuccess } = action.data;
		const preparedData = {
			id       : marketID,
			status_id: statusID,
		};

		try {
			// first selections
			if (!isArray(selections)) {
				throw new Error('Selections are not specified');
			}
			for (let i = 0; i < selections.length; i++) {
				const { id } = selections[i];
				const data = { id, status_id: statusID };

				yield call(eventsAPI.eventMarketSelectionUpdate, id, data);
			}

			// then market
			const res = yield call(eventsAPI.eventMarketUpdate, marketID, preparedData);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successStatusUpdate);
				if (onSuccess) {
					onSuccess();
				}
			}

		} catch (error) {
			notifications.showError(messages.errorUpdateEventMarketStatus, error);
			console.log(error);
		}

		yield put(actions.setValueUI('loading', false));
	});
}

export default function* eventsMarketsSaga() {
	yield all([
		fork(marketUpdate),
		fork(marketCancel),
		fork(settingsDataReload),
		fork(dynamicLimitsDataReload),
		fork(marketStatusUpdate),
		fork(marketStatusUpdateWithSelections),
	]);
}
