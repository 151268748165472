import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { actions } from './actions';
import { showError, showSuccess } from '../../../../../helpers/notifications';
import { usersAPI } from '../../../../../helpers/api/users';
import { adaptSportLimits, prepareSportLimits } from './utils';

const prefix = 'users.limits.sport';

const messages = {
	listReload    : `${prefix}.listReload`,
	dataSaveFailed: `${prefix}.dataSaveFailed`,
	dataSaved     : `${prefix}.dataSaved`,
};

const getStore = ({ Users }) => {
	const baseData = Users.UserInfo.UserLimitsAndRestrictions.Sport.get('baseData');
	const user = Users.User.get('baseData');
	return {
		baseData,
		userID: user.id,
	};
};

function* baseDataReload() {
	yield takeEvery(actions.DATA_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { userID } = yield select(getStore);
		try {
			const res =  yield  call(usersAPI.getUserSportLimits, userID );

			if (res && res.status === 200) {
				const [rawData] = res.data.data;
				const adaptedData = adaptSportLimits(rawData);
				yield put(actions.baseDataRefresh(adaptedData));
			}

		} catch (e) {
			showError(messages.listReload, e);
			showError(messages.listReload);
			console.log(e);
		}
		yield put(actions.uiRefresh({ loading: false }));

	});
}


function* dataSave() {
	yield takeEvery(actions.DATA_SAVE, function* () {
		const { userID, baseData } = yield select(getStore);
		yield put(actions.uiRefresh({ loading: true }));

		const preparedData = prepareSportLimits(baseData);
		try {

			const res = yield call(usersAPI.saveUserSportLimits, userID, preparedData);
			if (res && res.status === 200) {
				showSuccess(messages.dataSaved);
			}
		} catch (e) {
			showError(messages.dataSaveFailed);
			console.log(e);
		}

		yield put(actions.uiRefresh({ loading: false,  baseChanged: false }));

	});
}


export default function* sportLimits() {
	yield all([
		fork(baseDataReload),
		fork(dataSave),
	]);
}
