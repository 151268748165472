import { createSelector } from 'reselect';

const status            = (state) => state.Socket.get('status');
const rtmSubscribedType = (state) => state.Socket.get('rtmSubscribedType');

export const deriveSocketStatus = createSelector(
	[status],
	(status) => status
);

export const deriveSubscribedTypeRTM = createSelector(
	[rtmSubscribedType],
	(rtmSubscribedType) => rtmSubscribedType
);
