import ParamsBuilder from '../../../helpers/paramsBuilder';
import EntityAdapter from '../../../helpers/entityAdapter';

export const fields = {
	id           : 'id',
	userName     : 'username',
	firstName    : 'first_name',
	lastName     : 'last_name',
	email        : 'email',
	statusID     : 'status_id',
	roles        : 'roles',
	userRoles    : 'user_roles',
	partnerIDs   : 'partner_ids',
	websiteIDs   : 'website_ids',
	birthday     : 'birth',
	langID       : 'language_id',
	websiteID    : 'website_id',
	// Filter
	birthDateFrom: 'birth_date_from',
	birthDateTo  : 'birth_date_to',
	role         : 'user_roles',
};

const listAdapter = createListAdapter();

// Params -----------------------------------------------------------------------------------------

export function getListParams(filter, pagination = null, sorting = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	if (pagination) {
		builder.addValue('page', pagination.currentPage);
		builder.addValue('limit', pagination.itemsPerPage);
	}

	builder.addField(rules.isID, 'userID', fields.id);
	builder.addField(rules.isID, 'statusID', fields.statusID);

	builder.addField(rules.isString, 'userName', fields.userName);
	builder.addField(rules.isString, 'userEmail', fields.email);
	builder.addField(rules.isString, 'firstName', fields.firstName);
	builder.addField(rules.isString, 'lastName', fields.lastName);
	builder.addField(rules.isArrayID, 'role', fields.roles);
	builder.addField(rules.isArrayID, 'partnerIDs', fields.partnerIDs);
	builder.addField(rules.isArrayID, 'websiteIDs', fields.websiteIDs);

	builder.addRangeField(rules.isDateTimeRange, filter.birthday, [fields.birthDateFrom, fields.birthDateTo]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapting ---------------------------------------------------------------------------------------

export function adaptAdminList(rawUsersList = []) {

	listAdapter.clearExcludes();
	const adaptedList = listAdapter.adaptList(rawUsersList);

	return adaptedList;
}

// Preparing --------------------------------------------------------------------------------------

export function prepareAdminPerson(rawData = {}) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('userRoles');

	const preparedData = listAdapter.prepare(rawData);
	preparedData.password = rawData.password;

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'statusID', fields.statusID);

	adapter.addField(rules.intOrNull, 'langID', fields.langID);
	adapter.addField(rules.intOrNull, 'websiteID', fields.websiteID);

	adapter.addField(rules.string, 'userName', fields.userName);
	adapter.addField(rules.string, 'firstName', fields.firstName);
	adapter.addField(rules.string, 'lastName', fields.lastName);
	adapter.addField(rules.string, 'email', fields.email);

	adapter.addField(rules.date, 'birthday', fields.birthday);

	adapter.addField(rules.arrayID, 'roles', fields.roles);
	adapter.addField(rules.arrayString, 'userRoles', fields.userRoles);
	adapter.addField(rules.arrayNumber, 'partnerIDs', fields.partnerIDs);
	adapter.addField(rules.arrayNumber, 'websiteIDs', fields.websiteIDs);

	return adapter;
}
