import { Map } from 'immutable';
import dayjs from 'dayjs';
import actions from './actions';
import { calcRegularPrice } from './utils';

const initialState = new Map({
	isAdding        : false,
	isUpdating      : false,
	currentPage     : 0,
	eventMarkets    : [],
	selections      : [],
	customPrice     : '',
	regularPrice    : '',
	activeOffer     : null,
	date            : dayjs(new Date()),
	queryDate       : null,
	eventName       : '',
	currentEventName: '',
});

const removeEvent = (state, data) => {
	const updatedState = state.update('selections', selections => selections.filter(selection => selection.event_id !== data));
	return updatedState.set(
		'regularPrice',
		calcRegularPrice(updatedState.get('selections'))
	);
};

const addSelection = (state, data) => {
	const updatedState = state.update('selections', selections => selections.filter(selection => selection.event_id !== data.event_id).concat(data));
	return updatedState.set(
		'regularPrice',
		calcRegularPrice(updatedState.get('selections'))
	);
};

const removeSelection = (state, selectionID) => {
	const updatedState = state.update('selections', selections => selections.filter(
		(selection) =>  +selection.event_id !== +selectionID
	));
	return updatedState.set(
		'regularPrice',
		calcRegularPrice(updatedState.get('selections'))
	);
};

const setSelectionsSuccess = (state, data) => {
	const updatedState = state.set('selections', data);
	return updatedState.set(
		'regularPrice',
		calcRegularPrice(updatedState.get('selections'))
	);
};

export default (state = initialState, { type, data }) => {
	switch (type) {
	case actions.OFFERS_SET_OFFER_DATE:
		return state.set('date', data);

	case actions.OFFERS_SHOW_ADDING_MODAL:
		return state.set('isAdding', true);

	case actions.OFFERS_HIDE_ADDING_MODAL:
		return state.set('isAdding', false);

	case actions.OFFERS_FETCH_EVENT_MARKETS_SUCCESS:
		return state.set('eventMarkets', data);

	case actions.OFFERS_SET_MARKETS_PAGE:
		return state.set('currentPage', data);

	case actions.OFFERS_REMOVE_EVENT:
		return removeEvent(state, data);

	case actions.OFFERS_ADD_SELECTION:
		return addSelection(state, data);

	case actions.OFFERS_REMOVE_SELECTION:
		return removeSelection(state, data);

	case actions.OFFERS_SET_CUSTOM_PRICE:
		return state.set('customPrice', data);

	case actions.OFFERS_SET_SELECTIONS_SUCCESS:
		return setSelectionsSuccess(state, data);

	case actions.OFFERS_SHOW_UPDATING_MODAL:
		return state.set('isUpdating', true);

	case actions.OFFERS_HIDE_UPDATING_MODAL:
		return state.set('isUpdating', false);

	case actions.OFFERS_RESET_OFFER:
		return state.set('eventMarkets', [])
			.set('selections', [])
			.merge({
				customPrice : '',
				regularPrice: '',
				activeOffer : null,
			});

	case actions.OFFERS_CHANGE_EVENT_QUERY:
		return state.set('eventName', data);

	case actions.OFFERS_CHANGE_EVENT_QUERY_BY_DATE:
		return state.set('queryDate', data);

	case actions.OFFERS_CHANGE_CURRENT_EVENT_NAME:
		return state.set('currentEventName', data.eventName);

	default:
		return state;
	}
};
