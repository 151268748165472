const actions = {

	SOCKET_SET_CONNECTION_STATUS: 'SOCKET_SET_CONNECTION_STATUS',
	SOCKET_RECONNECT          		: 'SOCKET_RECONNECT',

	SOCKET_RTM_SUBSCRIBE            		: 'SOCKET_RTM_SUBSCRIBE',
	SOCKET_RTM_UNSUBSCRIBE          		: 'SOCKET_RTM_UNSUBSCRIBE',
	SOCKET_RTM_SUBSCRIBED_TYPE_REFRESH: 'SOCKET_RTM_SUBSCRIBED_TYPE_REFRESH',

	SOCKET_NOTIFICATION_SUBSCRIBE         					: 'SOCKET_NOTIFICATION_SUBSCRIBE',
	SOCKET_NOTIFICATION_UNSUBSCRIBE       					: 'SOCKET_NOTIFICATION_UNSUBSCRIBE',
	SOCKET_NOTIFICATION_SUBSCRIBED_TYPE_REFRESH: 'SOCKET_NOTIFICATION_SUBSCRIBED_TYPE_REFRESH',

	SOCKET_NOTIFY_CREATE_BET             		: 'SOCKET_NOTIFY_CREATE_BET',
	SOCKET_NOTIFY_CREATE_DEPOSIT         		: 'SOCKET_NOTIFY_CREATE_DEPOSIT',
	SOCKET_NOTIFY_CREATE_DOCUMENT     					: 'SOCKET_NOTIFY_CREATE_DOCUMENT',
	SOCKET_NOTIFY_CREATE_RISK        						: 'SOCKET_NOTIFY_CREATE_RISK',
	SOCKET_NOTIFY_CREATE_WITHDRAWAL      		: 'SOCKET_NOTIFY_CREATE_WITHDRAWAL',
	SOCKET_NOTIFY_CREATE_CASINO_TRANSACTION: 'SOCKET_NOTIFY_CREATE_CASINO_TRANSACTION',
	SOCKET_NOTIFY_CREATE_USER_DOCUMENT   		: 'SOCKET_NOTIFY_CREATE_USER_DOCUMENT',
	SOCKET_NOTIFY_CREATE_MESSAGES        		: 'SOCKET_NOTIFY_CREATE_MESSAGES',

	SOCKET_NOTIFY_CREATE_NOTIFICATION_RISK   			: 'SOCKET_NOTIFY_CREATE_NOTIFICATION_RISK',
	SOCKET_NOTIFY_CREATE_NOTIFICATION_DEPOSIT 		: 'SOCKET_NOTIFY_CREATE_NOTIFICATION_DEPOSIT',
	SOCKET_NOTIFY_CREATE_NOTIFICATION_CASINO  		: 'SOCKET_NOTIFY_CREATE_NOTIFICATION_CASINO',
	SOCKET_NOTIFY_CREATE_NOTIFICATION_WITHDRAWAL: 'SOCKET_NOTIFY_CREATE_NOTIFICATION_WITHDRAWAL',

	SOCKET_NOTIFY_UPDATE_BET                   		: 'SOCKET_NOTIFY_UPDATE_BET',
	SOCKET_NOTIFY_UPDATE_DEPOSIT               		: 'SOCKET_NOTIFY_UPDATE_DEPOSIT',
	SOCKET_NOTIFY_UPDATE_DOCUMENT           					: 'SOCKET_NOTIFY_UPDATE_DOCUMENT',
	SOCKET_NOTIFY_UPDATE_RISK              						: 'SOCKET_NOTIFY_UPDATE_RISK',
	SOCKET_NOTIFY_UPDATE_WITHDRAWAL            		: 'SOCKET_NOTIFY_UPDATE_WITHDRAWAL',
	SOCKET_NOTIFY_UPDATE_CASINO_TRANSACTION    		: 'SOCKET_NOTIFY_UPDATE_CASINO_TRANSACTION',
	SOCKET_NOTIFY_UPDATE_MESSAGES              		: 'SOCKET_NOTIFY_UPDATE_MESSAGES',
	SOCKET_NOTIFY_UPDATE_EVENT_STATISTIC      			: 'SOCKET_NOTIFY_UPDATE_EVENT_STATISTIC',
	SOCKET_NOTIFY_UPDATE_EVENT_MARKETS_STATISTICS: 'SOCKET_NOTIFY_UPDATE_EVENT_MARKETS_STATISTICS',
	SOCKET_NOTIFY_UPDATE_USER_DOCUMENT       				: 'SOCKET_NOTIFY_UPDATE_USER_DOCUMENT',

	SOCKET_NOTIFY_UPDATE_NOTIFICATION_WITHDRAWAL: 'SOCKET_NOTIFY_UPDATE_NOTIFICATION_WITHDRAWAL',
	SOCKET_NOTIFY_UPDATE_NOTIFICATION_RISK   			: 'SOCKET_NOTIFY_UPDATE_NOTIFICATION_RISK',

	setStatus: status => ({
		type: actions.SOCKET_SET_CONNECTION_STATUS,
		data: {
			status,
		},
	}),
	reconnect: () => ({
		type: actions.SOCKET_RECONNECT,
	}),

	rtmSubscribe: (rtmType, subscribeIDs) => ({
		type: actions.SOCKET_RTM_SUBSCRIBE,
		data: {
			rtmType,
			subscribeIDs,
		},
	}),
	rtmUnsubscribe: rtmType => ({
		type: actions.SOCKET_RTM_UNSUBSCRIBE,
		data: {
			rtmType,
		},
	}),
	rtmSubscribedTypeRefresh: rtmType => ({
		type: actions.SOCKET_RTM_SUBSCRIBED_TYPE_REFRESH,
		data: {
			rtmType,
		},
	}),
	notificationSubscribe: notifType => ({
		type: actions.SOCKET_NOTIFICATION_SUBSCRIBE,
		data: {
			notifType,
		},
	}),
	notificationUnsubscribe: notifType => ({
		type: actions.SOCKET_NOTIFICATION_UNSUBSCRIBE,
		data: {
			notifType,
		},
	}),
	notificationSubscribedTypeRefresh: notifType => ({
		type: actions.SOCKET_NOTIFICATION_SUBSCRIBED_TYPE_REFRESH,
		data: {
			notifType,
		},
	}),

	notifyCreateBet: data => ({
		type: actions.SOCKET_NOTIFY_CREATE_BET,
		data,
	}),
	notifyCreateDeposit: data => ({
		type: actions.SOCKET_NOTIFY_CREATE_DEPOSIT,
		data,
	}),
	notifyCreateNotifDeposit: data => ({
		type: actions.SOCKET_NOTIFY_CREATE_NOTIFICATION_DEPOSIT,
		data,
	}),
	notifyCreateNotifCasino: data => ({
		type: actions.SOCKET_NOTIFY_CREATE_NOTIFICATION_CASINO,
		data,
	}),
	notifyCreateNotifWithdrawal: data => ({
		type: actions.SOCKET_NOTIFY_CREATE_NOTIFICATION_WITHDRAWAL,
		data,
	}),
	notifyCreateDocument: data => ({
		type: actions.SOCKET_NOTIFY_CREATE_DOCUMENT,
		data,
	}),
	notifyCreateRisk: data => ({
		type: actions.SOCKET_NOTIFY_CREATE_RISK,
		data,
	}),
	notifyCreateNotifRisk: (data) => ({
		type: actions.SOCKET_NOTIFY_CREATE_NOTIFICATION_RISK,
		data,
	}),
	notifyCreateWithdrawal: data => ({
		type: actions.SOCKET_NOTIFY_CREATE_WITHDRAWAL,
		data,
	}),
	notifyCreateCasinoTransaction: data => ({
		type: actions.SOCKET_NOTIFY_CREATE_CASINO_TRANSACTION,
		data,
	}),
	notifyCreateUserDocument: data => ({
		type: actions.SOCKET_NOTIFY_CREATE_USER_DOCUMENT,
		data,
	}),
	notifyUpdateUserDocument: data => ({
		type: actions.SOCKET_NOTIFY_UPDATE_USER_DOCUMENT,
		data,
	}),
	notifyCreateMessages: data => ({
		type: actions.SOCKET_NOTIFY_CREATE_MESSAGES,
		data,
	}),
	notifyUpdateBet: data => ({
		type: actions.SOCKET_NOTIFY_UPDATE_BET,
		data,
	}),
	notifyUpdateDeposit: data => ({
		type: actions.SOCKET_NOTIFY_UPDATE_DEPOSIT,
		data,
	}),
	notifyUpdateDocument: data => ({
		type: actions.SOCKET_NOTIFY_UPDATE_DOCUMENT,
		data,
	}),
	notifyUpdateRisk: data => ({
		type: actions.SOCKET_NOTIFY_UPDATE_RISK,
		data,
	}),
	notifyUpdateNotifRisk: data => ({
		type: actions.SOCKET_NOTIFY_UPDATE_NOTIFICATION_RISK,
		data,
	}),
	notifyUpdateWithdrawal: data => ({
		type: actions.SOCKET_NOTIFY_UPDATE_WITHDRAWAL,
		data,
	}),
	notifyUpdateNotifWithdrawal: data => ({
		type: actions.SOCKET_NOTIFY_UPDATE_NOTIFICATION_WITHDRAWAL,
		data,
	}),
	notifyUpdateCasinoTransaction: data => ({
		type: actions.SOCKET_NOTIFY_UPDATE_CASINO_TRANSACTION,
		data,
	}),
	notifyUpdateMessages: data => ({
		type: actions.SOCKET_NOTIFY_UPDATE_MESSAGES,
		data,
	}),
	notifyUpdateEventMarketsStatistics: data => ({
		type: actions.SOCKET_NOTIFY_UPDATE_EVENT_MARKETS_STATISTICS,
		data,
	}),
	notifyUpdateEventStatistic: data => ({
		type: actions.SOCKET_NOTIFY_UPDATE_EVENT_STATISTIC,
		data,
	}),
};

export default actions;
