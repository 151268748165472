import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';

import { paymentsAPI } from '../../../../helpers/api/payments';
import notifications from '../../../../helpers/notifications';

import { adaptPaymentsList } from './utils';
import { getWebsiteID } from '../../../../helpers/utility';

const prefix = 'settings.payments.list';

const messages = {
	errorListLoad     : `${prefix}.errorListLoad`,
	errorPaymentSave  : `${prefix}.errorPaymentSave`,
	errorImageUpload  : `${prefix}.errorImageUpload`,
	successImageUpload: `${prefix}.successImageUpload`,
	successPaymentSave: `${prefix}.successPaymentSave`,
};

function* listReload() {
	yield takeEvery(actions.PAYMENTS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const websiteID = getWebsiteID();
		let entities = {};
		try {
			const res = yield call(paymentsAPI.paymentsList, websiteID);
			if (res && res.status === 200) {
				entities = adaptPaymentsList(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			console.log(error);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* paymentsListSaga() {
	yield all([fork(listReload)]);
}
