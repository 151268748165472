import { normalize, schema } from 'normalizr';
import isArray from 'lodash/isArray';

import ParamsBuilder from '../../helpers/paramsBuilder';
import EntityAdapter from '../../helpers/entityAdapter';

export const fields = {
	requestUUID     : 'request_uuid',      // string;
	playerID        : 'player_id',         // number;
	dateTime        : 'datetime',          // Date;
	amountRaw       : 'amount_raw',        // number;
	amountUSD       : 'amount_usd',        // number;
	currency        : 'currency',          // string;
	postbackType    : 'postback_type',     // AffiliatePostbackType;
	campaign        : 'campaign',          // string;
	userInfo        : 'user_info',         // string;
	playerIP        : 'player_ip',         // string;
	withdrawalReason: 'withdrawal_reason', // string;
	clickid         : 'clickid',           // string;
	subid           : 'subid',             // string;
	subid1          : 'subid1',            // string;
	subid2          : 'subid2',            // string;
	subid3          : 'subid3',            // string;
	// filter
	dateTimeFrom    : 'datetime_from',     // Date;
	dateTimeTo      : 'datetime_to',       // Date;
	amountRawFrom   : 'amount_raw_from',   // number;
	amountRawTo     : 'amount_raw_to',     // number;
	amountUSDFrom   : 'amount_usd_from',   // number;
	amountUSDTo     : 'amount_usd_to',     // number;
};

const listAdapter = createListAdapter();

export function getListParams(filter, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	if (pagination) {
		builder.addValue('page', pagination.currentPage);
		builder.addValue('limit', pagination.itemsPerPage);
	}

	builder.addField(rules.isID, 'playerID', fields.playerID);

	builder.addField(rules.isString, 'requestUUID', fields.requestUUID);
	builder.addField(rules.isString, 'currency', fields.currency);
	builder.addField(rules.isString, 'postbackType', fields.postbackType);
	builder.addField(rules.isString, 'campaign', fields.campaign);
	builder.addField(rules.isString, 'userInfo', fields.userInfo);
	builder.addField(rules.isString, 'playerIP', fields.playerIP);
	builder.addField(rules.isString, 'withdrawalReason', fields.withdrawalReason);
	builder.addField(rules.isString, 'clickid', fields.clickid);
	builder.addField(rules.isString, 'subid', fields.subid);
	builder.addField(rules.isString, 'subid1', fields.subid1);
	builder.addField(rules.isString, 'subid2', fields.subid2);
	builder.addField(rules.isString, 'subid3', fields.subid3);

	builder.addRangeField(rules.isDateTimeRange, filter.dateTime, [fields.dateTimeFrom, fields.dateTimeTo]);
	builder.addRangeField(rules.isNumberRange, filter.amountRaw, [fields.amountRawFrom, fields.amountRawTo]);
	builder.addRangeField(rules.isNumberRange, filter.amountUSD, [fields.amountUSDFrom, fields.amountUSDTo]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------
export function adaptList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	const tempData = {
		items: adaptedData,
	};

	const item           = new schema.Entity('items', {}, { idAttribute: 'requestUUID' });
	const itemsSchema    = { items: [item] };
	const normalizedData = normalize(tempData, itemsSchema);
	const entities       = normalizedData.entities.items || {};

	return entities;
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'playerID', fields.playerID);

	adapter.addField(rules.string, 'requestUUID', fields.requestUUID);
	adapter.addField(rules.string, 'currency', fields.currency);
	adapter.addField(rules.string, 'postbackType', fields.postbackType);
	adapter.addField(rules.string, 'campaign', fields.campaign);
	adapter.addField(rules.string, 'userInfo', fields.userInfo);
	adapter.addField(rules.string, 'playerIP', fields.playerIP);
	adapter.addField(rules.string, 'withdrawalReason', fields.withdrawalReason);
	adapter.addField(rules.string, 'clickid', fields.clickid);
	adapter.addField(rules.string, 'subid', fields.subid);
	adapter.addField(rules.string, 'subid1', fields.subid1);
	adapter.addField(rules.string, 'subid2', fields.subid2);
	adapter.addField(rules.string, 'subid3', fields.subid3);

	adapter.addField(rules.number, 'amountRaw', fields.amountRaw);
	adapter.addField(rules.number, 'amountUSD', fields.amountUSD);

	adapter.addField(rules.fullDate, 'dateTime', fields.dateTime);

	return adapter;
}
