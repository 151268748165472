import { Map } from 'immutable';
import actions from './actions';
import { SOCKET_STATUS } from '../../helpers/commonConstants';

const initState = new Map({
	status                    : SOCKET_STATUS.disconnected,
	rtmSubscribedType         : null,
	notificationSubscribedType: null,
});

export default function socketReducer(state = initState, action) {

	switch (action.type) {

	case actions.SOCKET_SET_CONNECTION_STATUS: {
		const { status } = action.data;
		return state.set('status', status);
	}
	case actions.SOCKET_RTM_SUBSCRIBED_TYPE_REFRESH: {
		const { rtmType } = action.data;
		return state.set('rtmSubscribedType', rtmType);
	}
	case actions.SOCKET_NOTIFICATION_SUBSCRIBED_TYPE_REFRESH: {
		const { notifType } = action.data;
		return state.set('notificationSubscribedType', notifType);
	}

	default:
		return state;
	}
}
