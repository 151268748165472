import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import { participantsAPI } from '../../../../helpers/api/participants';
import notifications from '../../../../helpers/notifications';
import { getHeadersTotalCount } from '../../../../helpers/utils';

import { FILTER_TYPES } from '../../../../constants/participants';

import { getListParams, adaptParticipantsList, adaptMemberssList } from './utils';

const prefix = 'participant.list';

const messages = {
	errorLoadParticipants  : `${prefix}.errorLoadParticipants`,
	errorDeleteParticipants: `${prefix}.errorDeleteParticipants`,
	errorDeleteMember      : `${prefix}.errorDeleteMember`,
	successDelete          : `${prefix}.successDelete`,
	successDeleteMember    : `${prefix}.successDeleteMember`,
};

function getStoreData(state) {

	return {
		filter    : state.Sport.Participants.Filter.get('filter'),
		pagination: state.Sport.Participants.List.get('pagination'),
		sorting   : state.Sport.Participants.List.get('sorting'),
		UI        : state.Sport.Participants.List.get('UI'),
		websiteID : state.App.get('websiteID'),
	};
}

function* listReload() {

	const takeAction   = actions.PARTICIPANTS_LIST_RELOAD;

	yield takeEvery(takeAction, function* () {

		yield put(actions.setValueUI('loading', true));

		const storeData = yield select(getStoreData);
		const { filter, pagination, sorting, websiteID } = storeData;
		filter.websiteID = websiteID;

		const params = getListParams(filter, pagination, sorting);

		if ( filter.verifiedID === '2') {
			params.verified = true;
		} else if (filter.verifiedID === '3') {
			params.verified = false;
		}

		const apiFunction = (Number(filter.typeID) === FILTER_TYPES.TEAM)
			? participantsAPI.participantList
			: participantsAPI.membersList;

		try {
			const response = yield call(apiFunction, params);
			if (response && response.status === 200) {
				const list = (filter.typeID === FILTER_TYPES.TEAM)
					? adaptParticipantsList(response.data.data)
					: adaptMemberssList(response.data.data);
				const totalCount = getHeadersTotalCount(response.headers) || list.length;

				yield put(actions.listRefresh(list));
				yield put(actions.paginationRefresh({ totalCount }));
			}
		} catch (error) {
			notifications.showError(messages.errorLoadParticipants, error);
			console.log(error);
		}

		yield put(actions.setValueUI('loading', false));
	});
}

function* participantDelete() {

	const takeAction   = actions.PARTICIPANTS_LIST_PARTICIPANT_DELETE;

	yield takeEvery(takeAction, function* (action) {

		const { participantID } = action.data;
		try {
			const response = yield call(
				participantsAPI.participantDelete,
				participantID
			);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successDelete);
				yield put(actions.listReload());
			}
		} catch (error) {
			notifications.showError(messages.errorDeleteParticipants, error);
			console.log(error);
		}
	});
}

function* memberDelete() {

	const takeAction   = actions.PARTICIPANTS_LIST_MEMBER_DELETE;

	yield takeEvery(takeAction, function* (action) {

		const { memberID } = action.data;
		try {
			const response = yield call(participantsAPI.memberDelete, memberID);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successDeleteMember);
				yield put(actions.listReload());
			}
		} catch (error) {
			notifications.showError(messages.errorDeleteMember, error);
			console.log(error);
		}
	});
}

export default function* participantsListSaga() {
	yield all([
		fork(listReload),
		fork(participantDelete),
		fork(memberDelete),
	]);
}
