import { all, takeEvery, put, fork, call, select, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import tableActions from '../../../tables/actions';

import { eventsAPI } from '../../../../helpers/api/events';
import notifications from '../../../../helpers/notifications';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import { getHeadersTotalCount } from '../../../../helpers/utils';

import { adaptEventsList, prepareListParams } from '../utils';
import { apiRequest } from '../../../../helpers/api';
import formatError from '../../../../helpers/formaErrorString';
import { TRADING_MODE } from '../../../../helpers/commonConstants';
import { deriveTablePagination } from '../../../../selectors/tables';
import { TABLE_TYPES } from '../../../../constants/tableTypes';

const prefix = 'sport.events.list';

const messages = {
	errorListLoad      : `${prefix}.errorListLoad`,
	errorDateUpdate    : `${prefix}.errorDateUpdate`,
	errorStatusUpdate  : `${prefix}.errorStatusUpdate`,
	successDateUpdate  : `${prefix}.successDateUpdate`,
	successStatusUpdate: `${prefix}.successStatusUpdate`,
};
const TABLE_TYPE   = TABLE_TYPES.events;

function getStoreData(state, type) {
	const { Sport: { Events }, Tables } = state;
	const { Filter } = Events;
	return {
		filter    : Filter.get('filter'),
		sorting   : Tables.get(type).sorting,
		pagination: type => deriveTablePagination(type, state),
	};
}

function* listReload() {
	yield takeLatest(actions.EVENTS_LIST_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		let type;
		const { tableType } = action;
		if (tableType === TABLE_TYPES.homepageSpecialOffers || tableType === TABLE_TYPES.homepageMatchOfTheDay || tableType === TABLE_TYPES.homepageUpcomingEents) {
			type = tableType;
		} else {
			type = TABLE_TYPE;
		}
		const { filter, pagination, sorting } = yield select(getStoreData, TABLE_TYPE);
		const params = prepareListParams(filter, pagination(type), sorting);
		try {
			if (type === TABLE_TYPES.homepageMatchOfTheDay || type === TABLE_TYPES.homepageUpcomingEents) {
				params.trading_mode = TRADING_MODE.prematch;
			}
			const response = yield call(eventsAPI.eventList, params);
			if (response && response.status === 200) {
				const list = adaptEventsList(response.data.data);
				const totalCount = getHeadersTotalCount(response.headers) || list.length;
				yield put(actions.listRefresh(list));
				yield put(tableActions.paginationRefresh(type, { totalCount }));
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			console.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* priorListReloadSaga() {

	yield takeEvery(actions.EVENTS_PRIOR_LIST_RELOAD, function* ({ data }) {

		const filter = data;
		const builder = new ParamsBuilder();
		const rules = builder.RULES;

		builder.addValue('unLimit', true);

		builder.addField(rules.isID, 'sportID', 'sport_id');
		builder.addField(rules.isID, 'countryID', 'country_id');
		builder.addField(rules.isID, 'leagueID', 'league_id');
		builder.addField(rules.isString, 'eventName', 'name');

		const params = builder.biuldParams(filter);

		try {
			const response = yield call(apiRequest, {
				method: 'GET',
				url   : '/events',
				params,
			});
			if (response && response.status === 200) {
				const list = adaptEventsList(response.data.data);

				yield put(actions.listRefresh(list));
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad, error);
			console.log(error);
		}
	});
}

function* updateEventPrioritySaga() {
	yield takeEvery(actions.EVENT_UPDATE_EVENT_PRIORITY, function* ({ data }) {
		try {
			yield call(apiRequest, {
				method: 'POST',
				url   : `/events/${data.id}/priority`,
				data  : {
					home_priority_type_id: data.priority,
				},
			});
			yield put(actions.updateEventPrioritySuccess(data));
		} catch (e) {
			notifications.showError(formatError(e.response.data.text));
		}
	});
}

function* editedDateUpdate() {

	yield takeEvery(actions.EVENTS_LIST_EDITED_DATE_UPDATE, function* (action) {

		const { filter } = yield select(getStoreData, TABLE_TYPE);
		const { eventID, startDate } = action.data;
		const data = {
			event_id  : eventID,
			start_date: startDate,
		};
		try {
			const response = yield call(eventsAPI.eventUpdate, eventID, data);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successDateUpdate);
				yield put(actions.listReload(filter));
			}
		} catch (error) {
			notifications.showError(messages.errorDateUpdate, error);
			console.log(error);
		}
	});
}

function* eventStatusUpdate() {

	yield takeEvery(actions.EVENTS_LIST_EVENT_STATUS_UPDATE, function* (action) {

		const { filter } = yield select(getStoreData, TABLE_TYPE);
		const { eventID, statusID } = action.data;
		yield put(actions.eventStatusUpdateSuccess(eventID, +statusID));
		const data = {
			event_id : eventID,
			status_id: statusID,
		};
		try {
			const response = yield call(eventsAPI.eventUpdate, eventID, data);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successStatusUpdate);
				yield put(actions.listReload(filter));
			}
		} catch (error) {
			notifications.showError(messages.errorStatusUpdate, error);
			console.log(error);
		}
	});
}

function* fetchEventsBySportSaga() {
	yield takeEvery(actions.EVENT_FETCH_EVENTS_BY_SPORT, function* ({ data }) {
		try {
			const { filter, pagination, sorting } = yield select(getStoreData, TABLE_TYPE);
			const { id : sportID, dateFrom, tradingMode } = data;
			const page = pagination('HOMEPAGE_SPORTS_PRIORITIES');
			const params = prepareListParams(filter, page, sorting, tradingMode, sportID);
			params.unLimit = true;
			params.date_from = dateFrom;
			params.active_only = true;
			const response = yield call(eventsAPI.fetchEventBySportID, params);
			const list = adaptEventsList(response.data.data);
			yield put(actions.listRefresh(list));
		} catch (e) {
			notifications.showError(formatError(e.response.data.text));
		}
	});
}

export default function* eventsListSaga() {
	yield all([
		fork(listReload),
		fork(editedDateUpdate),
		fork(priorListReloadSaga),
		fork(updateEventPrioritySaga),
		fork(fetchEventsBySportSaga),
		fork(eventStatusUpdate),
	]);
}
