import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';

import { USER_INFO_VIEW_TABS } from '../../../helpers/commonConstants';
import actions from './actions';

const initState = new Map({

	baseData: {
		count: {},
	},
	adminData          : {},
	financeData        : {},
	permissions        : [],
	currencyList       : [],
	notes              : [],
	newNote            : '',
	userQuickSearchData: {},
	UI                 : {
		loading               : false,
		isBaseDataChanged     : false,
		isRolesChanged        : false,
		isNotesChanged        : false,
		isLimitsAndRestrict   : false,
		currentTab            : USER_INFO_VIEW_TABS.personal,
		selectedRoleID        : null,
		modalVisible          : false,
		partnerOrWebsiteChange: false,
		logout                : false,
		statusID              : null,
	},
});

export default function userReducer(state = initState, action) {

	switch (action.type) {

	case actions.USER_BASE_DATA_REFRESH: {
		return state.set('baseData', action.data);
	}

	case actions.USER_QUICK_SEARCH_DATA_REFRASH: {
		return state.set('userQuickSearchData', action.data);
	}

	case actions.USER_QUICK_SEARCH_DATA_RESET: {
		return state.set('userQuickSearchData', {});
	}

	case actions.USER_ADMIN_DATA_REFRESH: {
		return state.set('adminData', action.data);
	}

	case actions.USER_FINANCE_DATA_REFRESH: {
		return state.set('financeData', action.data);
	}

	case actions.USER_PERMISSIONS_REFRESH: {
		return state.set('permissions', action.data);
	}

	case actions.USER_PERMISSIONS_REMOVE_ITEMS: {
		const roleNamesList = action.data;
		const permissions = state.get('permissions');
		const result = permissions.filter( roleName => !roleNamesList.includes(roleName));

		return state.set('permissions', result);
	}

	case actions.USER_PERMISSIONS_ADD_ITEMS: {
		const roleNamesList = action.data;
		const permissions = cloneDeep(state.get('permissions'));

		roleNamesList.forEach( roleName => {
			if (!permissions.includes(roleName)) {
				permissions.push(roleName);
			}
		});

		return state.set('permissions', permissions);
	}

	case actions.USER_SET_VALUE_UI: {
		const { valueName, value } = action.data;
		const UI                   = new Map(state.get('UI')).toObject();
		UI[valueName]              = value;

		return state.set('UI', UI);
	}

	case actions.USER_CURRENCY_LIST_REFRESH: {
		const { currencyList } = action.data;
		return state.set('currencyList', currencyList);
	}

	case actions.USER_NOTES_REFRESH: {
		return state.set('notes', action.data);
	}

	case actions.USER_NEW_NOTE_SET: {
		return state.set('newNote', action.data);
	}

	default:
		return state;
	}
}
