import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	transactionIDs: [],
	entities      : {},
	casinoTotals  : {},

	filter: {
		userID: 0,
	},

	UI: {
		loading: false,
	},
});

export default function rtmUserCasinoReducer(state = initState, action) {

	switch (action.type) {

	case actions.RTM_USER_CASINO_DATA_REFRESH: {
		const { transactionIDs, entities, casinoTotals } = action.data;
		return state
			.set('transactionIDs', transactionIDs)
			.set('entities', entities)
			.set('casinoTotals', casinoTotals);
	}

	case actions.RTM_USER_CASINO_FILTER_REFRESH: {
		const target = state.get('filter');
		const result = fill(action.data, target, true);
		return state.set('filter', result);
	}

	case actions.RTM_USER_CASINO_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
