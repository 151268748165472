const actions = {

	USER_DOCUMENTS_LIST_RELOAD    : 'USER_DOCUMENTS_LIST_RELOAD',
	USER_DOCUMENTS_LIST_REFRESH   : 'USER_DOCUMENTS_LIST_REFRESH',
	USER_DOCUMENTS_LIST_UI_REFRESH: 'USER_DOCUMENTS_LIST_UI_REFRESH',
	USER_DOCUMENTS_SAVE_DOCUMENT  : 'USER_DOCUMENTS_SAVE_DOCUMENT',

	USER_DOCUMENTS_SOCKET_NOTIFY_CREATE_DOCUMENT: 'USER_DOCUMENTS_SOCKET_NOTIFY_CREATE_DOCUMENT',
	USER_DOCUMENTS_SOCKET_NOTIFY_UPDATE_DOCUMENT: 'USER_DOCUMENTS_SOCKET_NOTIFY_UPDATE_DOCUMENT',
	USER_DOCUMENTS_NEW_DOCS_LIST_REFRESH        : 'USER_DOCUMENTS_NEW_DOCS_LIST_REFRESH',

	USER_DOCUMENTS_MODAL_DATA_REFRESH: 'USER_DOCUMENTS_MODAL_DATA_REFRESH',
	USER_DOCUMENTS_MODAL_DATA_RESET  : 'USER_DOCUMENTS_MODAL_DATA_RESET',
	USER_DOCUMENTS_MODAL_DATA_SAVE   : 'USER_DOCUMENTS_MODAL_DATA_SAVE',
	USER_DOCUMENTS_NUMBER_SAVE       : 'USER_DOCUMENTS_NUMBER_SAVE',
	USER_DOCUMENT_NUMBER_CHANGE      : 'USER_DOCUMENT_NUMBER_CHANGE',
	USER_MRZ_DATA_CHANGE             : 'USER_MRZ_DATA_CHANGE',
	UPDATE_DOCUMENT                  : 'UPDATE_DOCUMENT',
	USER_DOCUMENTS_NUMBER            : 'USER_DOCUMENTS_NUMBER',

	CAN_SAVE_DOCUMENT_NUMBER: 'CAN_SAVE_DOCUMENT_NUMBER',
	USER_DOCUMENTS_REASON   : 'USER_DOCUMENTS_REASON',

	listReload: (userID) => ({
		type: actions.USER_DOCUMENTS_LIST_RELOAD,
		data: {
			userID,
		},
	}),
	listRefresh: entities => ({
		type: actions.USER_DOCUMENTS_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	uiRefresh: data => ({
		type: actions.USER_DOCUMENTS_LIST_UI_REFRESH,
		data,
	}),
	saveDocument: (userID, documentID, statusID, reason) => ({
		type: actions.USER_DOCUMENTS_SAVE_DOCUMENT,
		data: {
			userID,
			documentID,
			statusID,
			reason,
		},
	}),

	mrzDataChange: (docID, data) => ({
		type: actions.USER_MRZ_DATA_CHANGE,
		data: {
			docID,
			data,
		},
	}),

	notifyCreateDocument: data => ({
		type: actions.USER_DOCUMENTS_SOCKET_NOTIFY_CREATE_DOCUMENT,
		data,
	}),
	notifyUpdateDocument: data => ({
		type: actions.USER_DOCUMENTS_SOCKET_NOTIFY_UPDATE_DOCUMENT,
		data,
	}),
	newDocsListRefresh: newDocs => ({
		type: actions.USER_DOCUMENTS_NEW_DOCS_LIST_REFRESH,
		data: {
			newDocs,
		},
	}),

	modalDataRefresh: data => ({
		type: actions.USER_DOCUMENTS_MODAL_DATA_REFRESH,
		data,
	}),
	modalDataReset: () => ({
		type: actions.USER_DOCUMENTS_MODAL_DATA_RESET,
	}),
	modalDataSave: () => ({
		type: actions.USER_DOCUMENTS_MODAL_DATA_SAVE,
	}),
	updateDocument: data => ({
		type: actions.UPDATE_DOCUMENT,
		data,
	}),
	setDocumentReason: reasonData => ({
		type: actions.USER_DOCUMENTS_REASON,
		reasonData,
	}),
	setDocNumber: docNumberData => ({
		type: actions.USER_DOCUMENTS_NUMBER,
		docNumberData,
	}),
	changeDocumentNumber: (documentNumber, docID) => ({
		type   : actions.USER_DOCUMENT_NUMBER_CHANGE,
		payload: {
			documentNumber,
			docID,
		},
	}),
	canSaveDocNumber: (payload) => ({
		type: actions.CAN_SAVE_DOCUMENT_NUMBER,
		payload,
	}),
};

export default actions;
