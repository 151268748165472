import { Map } from 'immutable';
import { FORMATS } from '../../helpers/commonConstants';
import {
	restorePartnerID,
	restoreWebsite,
} from '../../helpers/utility';
import { fill } from '../../helpers/utils';
import actions from './actions';
import { API_STATUS, getView } from './utils';

const initState = new Map({
	collapsed : !(window.innerWidth > 1220),
	view      : getView(window.innerWidth),
	height    : window.innerHeight,
	openDrawer: false,
	openKeys  : [],
	websiteID : restoreWebsite(),
	partnerID : restorePartnerID(),
	// allLanguageList         : [],

	partnerEntity: {},
	websiteEntity: {},

	partnerAttachedLanguages: [],
	websiteAttachedLanguages: [],

	websiteSettings: {
		dateFormat : FORMATS.fullDateTime,
		customerAge: 18,
	},

	modals: {
		passwordExpiredModal: {
			visible        : false,
			loading        : false,
			passwordLoading: false,
			success        : API_STATUS.initial,
		},

		baseData: {
			oldPassword: '',
			password   : '',
			confirm    : '',
		},
	},
});

export default function appReducer(state = initState, action) {

	switch (action.type) {

	case actions.COLLPSE_CHANGE: {
		return state.set('collapsed', !state.get('collapsed'));
	}
	case actions.COLLPSE_OPEN_DRAWER: {
		return state.set('openDrawer', !state.get('openDrawer'));
	}
	case actions.TOGGLE_ALL: {
		if (state.get('view') !== action.view || action.height !== state.height) {
			const height = action.height ? action.height : state.height;
			return state
				.set('collapsed', action.collapsed)
				.set('view', action.view)
				.set('height', height);
		}
		break;
	}
	case actions.CHANGE_OPEN_KEYS: {
		return state.set('openKeys', action.openKeys);
	}
	case actions.APP_WEBSITE_REFRESH: {
		const { websiteID } = action.data;
		return state.set('websiteID', websiteID);
	}
	case actions.APP_PARTNER_REFRESH: {
		const { partnerID } = action.data;
		return state.set('partnerID', partnerID);
	}

	case actions.APP_WEBSITE_SETTINGS_REFRESH: {
		const { websiteSettings } = action.data;
		const target              = state.get('websiteSettings');
		const result              = fill(websiteSettings, target);
		return state.set('websiteSettings', result);
	}
	case actions.PASSWORD_EXPIRED_MODAL_REFRESH: {
		const target = state.get('modals');
		const result = fill(action.data, target.passwordExpiredModal);
		const modals = state.get('modals');
		return state.set('modals', { ...modals, passwordExpiredModal: result });
	}
	case actions.MODAL_BASE_DATA_REFRESH: {
		const target = state.get('modals');
		const result = fill(action.data, target.baseData);
		const modals = state.get('modals');

		return state.set('modals', { ...modals, baseData: result });
	}
	case actions.APP_PARTNER_LIST_REFRESH: {
		return state.set('partnerEntity', action.data);
	}
	case actions.APP_WEBSITE_LIST_REFRESH: {
		return state.set('websiteEntity', action.data);
	}
	// case actions.APP_MAIN_LANGUAGES_LIST_REFRESH: {
	//     return state.set('allLanguageList', action.data);
	// }
	case actions.APP_PARTNER_ATTACHED_LANGUAGES_LIST_REFRESH: {
		return state.set('partnerAttachedLanguages', action.data);
	}
	case actions.APP_WEBSITE_ATTACHED_LANGUAGES_LIST_REFRESH: {
		return state.set('websiteAttachedLanguages', action.data);
	}
	case actions.APP_DATA_RESET: {
		return state.set('partnerEntities', {}).set('websiteEntity', {}).set('websiteID', null).set('partnerID', null);
	}

	case actions.APP_MODAL_RESET: {
		return state.set('modals', initState.get('modals'));
	}


	default:
		return state;
	}
	return state;
}
