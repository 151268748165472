import { Map } from 'immutable';
import { cloneDeep } from 'lodash';
import { fill } from '../../helpers/utils';
import { actions } from './actions';

const initState = new Map({
	baseData: {},

	UI: {
		loading    : true,
		isValid    : false,
		success    : false,
		isValidPass: false,
	},

});
const clonedState = cloneDeep(initState);

export default (state = clonedState, { type, data }) =>  {

	switch (type) {

	case actions.BASE_DATA_REFRESH: {
		const target = state.get('baseData');
		const result = fill(data, target, true);
		return state.set('baseData', result);
	}

	case actions.UI_REFRESH: {
		const UI  = data;
		const target = state.get('UI');
		const result = fill(UI, target);
		return state.set('UI', result);
	}

	case actions.DATA_RESET: {
		return state.set('baseData', {});
	}

	default:
		return state;
	}
};
