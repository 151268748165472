import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';
import listActions from '../list/actions';

import { helpCenterAPI } from '../../../../../helpers/api/helpCenter';
import notifications from '../../../../../helpers/notifications';

import {
	prepareNames,
	prepareQuestions,
} from './utils';

const prefix = 'pages.helpcenter.modal';

const messages = {
	errorTopicReload  : `${prefix}.errorTopicReload`,
	errorTopicSave    : `${prefix}.errorTopicSave`,
	successTopicSave  : `${prefix}.successTopicSave`,
	successTopicUpdate: `${prefix}.successTopicUpdate`,
};


function getStoreData({ Content, App }) {
	const { HelpCenter } = Content;
	return {
		names    : HelpCenter.Topic.get('names'),
		questions: HelpCenter.Topic.get('questions'),
		UI       : HelpCenter.Topic.get('UI'),
		websiteID: App.get('websiteID'),
	};
}

function* topicSave() {
	yield takeEvery(actions.TOPIC_SAVE, function* (action) {
		const { questions, names, UI, websiteID } = yield select(getStoreData);
		const { visible } = action.data;
		yield put(actions.uiRefresh({ loading: true }));
		const { editMode, topicID } = UI;
		let isError = false;
		const preparedNames = prepareNames(names);
		const preparedQuestions = prepareQuestions(questions);
		const preparedData = {
			website_id: websiteID,
			names     : preparedNames,
			questions : preparedQuestions,
		};
		try {
			if (editMode) {
				const res = yield call(
					helpCenterAPI.topicUpdate,
					topicID,
					preparedData
				);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successTopicSave);
					if (visible) {
						yield put(actions.uiRefresh({ visible }));
					}
				}
			} else {
				const res = yield call(helpCenterAPI.topicAdd, preparedData);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successTopicSave);
					yield put(actions.dataReset());
				}
			}
		} catch (error) {
			isError = true;
			notifications.showError(messages.errorTopicSave, error);
		}
		yield put(actions.uiRefresh({ loading: false }));
		if (!isError) {
			yield put(listActions.listReload());
		}
	});
}


export default function* topicSaga() {
	yield all([
		fork(topicSave),
	]);
}
