import ParamsBuilder from '../../../../../helpers/paramsBuilder';

export function getListParamsDelete(params) {
	const builder = new ParamsBuilder();

	builder.addValue('website_id', params.websiteID);

	return builder.biuldParams({});

}
