import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../../../../helpers/utils';

const initState = new Map({

	listIDs  : [],
	filter   : {},
	entities : {},
	websiteID: 1,

	UI: { loading: false },
});

export default function promotionsListReducer(state = initState, action) {

	switch (action.type) {

	case actions.PROMOTIONS_LIST_REFRESH: {

		const { listIDs, entities } = action.data;
		return state.set('listIDs', listIDs).set('entities', entities);
	}

	case actions.PROMOTIONS_LIST_CHANGE_WEBSITE: {

		const { websiteID } = action.data;
		return state.set('websiteID', websiteID);
	}

	case actions.FILTER_REFRESH: {
		const { data } = action;
		const target = state.get('filter');
		const result = fill(data, target, true);
		return state.set('filter', result);
	}

	case actions.PROMOTIONS_LIST_UI_REFRESH: {
		const { data } = action;
		const target = state.get('UI');
		const result = fill(data, target, true);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
