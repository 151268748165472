import { all, takeEvery, put, fork, call, select, takeLatest } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import actions from './actions';
import { usersAPI } from '../../../helpers/api/users';
import { showError, showSuccess } from '../../../helpers/notifications';

import externalSaga from './externalSaga';
import {
	adaptDocumentsList,
	prepareDocument,
	prepareDocumentList, prepareDocumentUpdate
} from './utils';

const prefix = 'users.documents';

const messages = {
	errorListLoad          : `${prefix}.errorListLoad`,
	errorDocumentSave      : `${prefix}.errorDocumentSave`,
	successDocumentSave    : `${prefix}.successDocumentSave`,
	successDocumentDeclined: `${prefix}.successDocumentDeclined`,
};

const getStoreData = ({ Users }) => ({ modalData: Users.Documents.get('modalData') });

function* listReload() {

	yield takeEvery(actions.USER_DOCUMENTS_LIST_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { userID } = action.data;

		let entities = {};
		try {
			const res = yield call(usersAPI.userDocumentsList, userID);
			if (res && res.status === 200) {
				entities = adaptDocumentsList(res.data.data);
			}
		} catch (error) {
			showError(messages.errorListLoad, error);
			console.log(error);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* saveDocument() {

	yield takeEvery(actions.USER_DOCUMENTS_SAVE_DOCUMENT, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { userID, documentID, statusID, reason } = action.data;

		const preparedData = {
			id            : documentID,
			status_id     : statusID,
			decline_reason: reason,
		};

		try {
			const res = yield call(usersAPI.userDocumentUpdate, userID, documentID, preparedData);
			if (res && res.status === 200) {
				showSuccess(messages.successDocumentDeclined);

				yield put(actions.listReload(userID));
				return;
			}

		} catch (error) {
			showError(messages.errorDocumentSave, error);
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}
function* modalDataSave() {

	yield takeEvery(actions.USER_DOCUMENTS_MODAL_DATA_SAVE, function* () {

		yield put(actions.modalDataRefresh({ loading: true }));
		const { modalData } = yield select(getStoreData);

		const { userID } = modalData;
		const id = uuid();
		const preparedData = prepareDocument({
			userID,
			...modalData,
		});

		try {
			const res = yield call(usersAPI.userDocumentUpload, userID, {...preparedData, id });
			if (res && res.status === 200) {
				showSuccess(messages.successDocumentSave);
				yield put(actions.listReload(userID));
				yield put(actions.modalDataReset());
				return;
			}

		} catch (error) {
			showError(messages.errorDocumentSave, error);
			console.log(error);
		}

		yield put(actions.modalDataRefresh({ loading: false }));
	});
}

function* saveDocumentNumber() {
	yield takeLatest(actions.USER_DOCUMENTS_NUMBER_SAVE, function* (action) {
		const { docID, documentNumber } = action.payload;
		try {
			const res = yield call(usersAPI.updateDocument, docID, documentNumber);
			if (res && res.status === 200) {
				showSuccess(messages.successDocumentSave);
				yield put(actions.canSaveDocNumber({ id: docID, canSavedocNumber: false }));
				return;
			}

		} catch (error) {
			showError(messages.errorDocumentSave, error);
			console.log(error);
		}
	});
}

function* updateDocument() {
	yield takeLatest(actions.UPDATE_DOCUMENT, function* (action) {
		const { data } = action;
		const { docID } = data;
		try {
			const prepared = prepareDocumentUpdate(data);
			const res = yield call(usersAPI.updateDocument, docID,  prepared);
			if (res && res.status === 200) {
				showSuccess(messages.successDocumentSave);
				yield put(actions.canSaveDocNumber({ id: doelcID, canSavedocNumber: false }));
				return;
			}

		} catch (error) {
			showError(messages.errorDocumentSave, error);
			console.log(error);
		}
	});
}

export default function* userDocumentsSaga() {
	yield all([
		fork(listReload),
		fork(saveDocument),
		fork(modalDataSave),
		fork(saveDocumentNumber),
		fork(updateDocument),

		fork(externalSaga),
	]);
}
