const actions = {

	GAMES_GRIDS_DEFAULT_GRID_RELOAD : 'GAMES_GRIDS_DEFAULT_GRID_RELOAD',
	GAMES_GRIDS_DEFAULT_GRID_REFRESH: 'GAMES_GRIDS_DEFAULT_GRID_REFRESH',

	GAMES_GRIDS_GAMES_LIST_RELOAD: 'GAMES_GRIDS_GAMES_LIST_RELOAD',
	GAMES_GRIDS_GAMES_LIST_MERGE : 'GAMES_GRIDS_GAMES_LIST_MERGE',
	GAMES_GRIDS_GAMES_LIST_CLEAR : 'GAMES_GRIDS_GAMES_LIST_CLEAR',

	GAMES_GRIDS_CATEGORY_LIST_REFRESH: 'GAMES_GRIDS_CATEGORY_LIST_REFRESH',

	GAMES_GRIDS_SAVE: 'GAMES_GRIDS_SAVE',

	GAMES_GRIDS_GRID_LAYOUT_SET   : 'GAMES_GRIDS_GRID_LAYOUT_SET',
	GAMES_GRIDS_GRID_LAYOUTS_SET  : 'GAMES_GRIDS_GRID_LAYOUTS_SET',
	GAMES_GRIDS_GRID_LAYOUTS_CLEAR: 'GAMES_GRIDS_GRID_LAYOUTS_CLEAR',

	GAMES_GRIDS_UI_REFRESH: 'GAMES_GRIDS_UI_REFRESH',

	GAMES_GRIDS_ADD_TO_CHANGED_LAYOUTS: 'GAMES_GRIDS_ADD_TO_CHANGED_LAYOUTS',
	GAMES_GRIDS_CLEAR_CHANGED_LAYOUTS : 'GAMES_GRIDS_CLEAR_CHANGED_LAYOUTS',
	DATA_RESET                        : 'DATA_RESET',

	// ----------------------------------------------------------------------------------------------
	defaultGridReload: () => ({
		type: actions.GAMES_GRIDS_DEFAULT_GRID_RELOAD,
	}),
	defaultGridRefresh: (defaultGrid) => ({
		type: actions.GAMES_GRIDS_DEFAULT_GRID_REFRESH,
		data: {
			defaultGrid,
		},
	}),

	// ----------------------------------------------------------------------------------------------
	gamesListReload: (websiteID, channelID, categoryID) => ({
		type: actions.GAMES_GRIDS_GAMES_LIST_RELOAD,
		data: {
			websiteID,
			channelID,
			categoryID,
		},
	}),
	gamesListMerge: (gamesList) => ({
		type: actions.GAMES_GRIDS_GAMES_LIST_MERGE,
		data: {
			gamesList,
		},
	}),
	gamesListClear: () => ({
		type: actions.GAMES_GRIDS_GAMES_LIST_CLEAR,
	}),

	// ----------------------------------------------------------------------------------------------
	categoryListRefresh: (categoryList) => ({
		type: actions.GAMES_GRIDS_CATEGORY_LIST_REFRESH,
		data: {
			categoryList,
		},
	}),

	// ----------------------------------------------------------------------------------------------
	gamesGridsSave: () => ({
		type: actions.GAMES_GRIDS_SAVE,
	}),

	// ----------------------------------------------------------------------------------------------
	gridLayoutSet: (categoryID, gridLayout) => ({
		type: actions.GAMES_GRIDS_GRID_LAYOUT_SET,
		data: {
			categoryID,
			gridLayout,
		},
	}),
	gridLayoutsSet: (gridLayouts) => ({
		type: actions.GAMES_GRIDS_GRID_LAYOUTS_SET,
		data: {
			gridLayouts,
		},
	}),
	gridLayoutsClear: () => ({
		type: actions.GAMES_GRIDS_GRID_LAYOUTS_CLEAR,
	}),

	// ----------------------------------------------------------------------------------------------
	uiRefresh: data => ({
		type: actions.GAMES_GRIDS_UI_REFRESH,
		data,
	}),

	// ----------------------------------------------------------------------------------------------
	addToChangedLayouts: (categoryID) => ({
		type: actions.GAMES_GRIDS_ADD_TO_CHANGED_LAYOUTS,
		data: {
			categoryID,
		},
	}),
	clearChangedLayouts: () => ({
		type: actions.GAMES_GRIDS_CLEAR_CHANGED_LAYOUTS,
	}),
	dataReset: () => ({
		type: actions.DATA_RESET,
	}),
};

export default actions;
