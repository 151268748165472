import { normalize, schema } from 'normalizr';
import isArray from 'lodash/isArray';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';

export const fields = {
	id                  : 'id',
	name                : 'name',
	sportID             : 'sport_id',
	countryID           : 'country_id',
	leagueID            : 'league_id',
	statusID            : 'status_id',
	date                : 'start_date',
	dateFrom            : 'date_from',
	dateTo              : 'date_to',
	eventName           : 'event_name',
	marketsCount        : 'markets_count',
	resultedMarketsCount: 'resulted_markets_count',
	resolutionStatusID  : 'resolution_status_id',
	displayTypeIDs      : 'display_type',
	tradingMode         : 'trading_mode',
	tradingModeID       : 'trading_mode',

	countryName: 'country_name',
	leagueName : 'league_name',
	sportName  : 'sport_name',
	providers  : 'providers',
	iconURL    : 'icon_url',

	// markets & selections
	eventID      : 'event_id',
	marketID     : 'market_id',
	eventMarketID: 'event_market_id',
	selectionID  : 'selection_id',
	resultTypeID : 'result_type_id',
	orderID      : 'order_id',
	rowIndex     : 'row_index',
	colIndex     : 'column_index',

	sourceID    : 'source_id',
	sourceTypeID: 'source_type_id',

	won     : 'won',
	void    : 'void',
	lost    : 'lost',
	isMarket: 'isMarket',
	originID: 'originID',

	winningCount: 'winning_count',
	eventMarkets: 'event_markets',
	argument    : 'argument',
};

const listAdapter = createListAdapter();
const providerListAdapter = createProviderListAdapter();

export function getListParams(filter, sorting, pagination) {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);
	builder.addValue('for_result', true);

	builder.addValue('active_only', false);
	builder.addValue('include_providers', false);
	builder.addValue('include_market_counts', true);
	builder.addValue('include_category_names', true);
	builder.addValue('include_resulted_markets_count', true);
	builder.addValue('order', 'desc');
	builder.addValue('start_date_to_day_end', false);

	builder.addValue('limit', pagination.itemsPerPage);
	builder.addValue('page', pagination.currentPage);

	builder.addField(rules.isID, 'sportID', fields.sportID);
	builder.addField(rules.isID, 'countryID', fields.countryID);
	builder.addField(rules.isID, 'leagueID', fields.leagueID);
	builder.addField(rules.isID, 'statusID', fields.statusID);
	builder.addField(rules.isID, 'resolutionStatusID', fields.resolutionStatusID);
	builder.addField(rules.isID, 'tradingMode', fields.tradingMode);

	builder.addField(rules.isString, 'eventName', fields.name);

	builder.addRangeField(rules.isDateTimeRange, filter.date, [
		fields.dateFrom,
		fields.dateTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptResultsList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}
	listAdapter.clearExcludes();
	providerListAdapter.clearExcludes();

	const adaptedData = listAdapter.adaptList(rawData);

	adaptedData.forEach(item => {
		item.providers = providerListAdapter.adaptList(item.providers);
		item.category = `${item.sportName} / ${item.countryName} / ${
			item.leagueName
		}`;
	});

	const tempData = {
		results: adaptedData,
	};

	const result = new schema.Entity('results', {}, { idAttribute: 'id' });
	const resultSchema = { results: [result] };

	const normalizedData = normalize(tempData, resultSchema);

	const listIDs = normalizedData.result.results || [];
	const entities = normalizedData.entities.results || {};

	return {
		listIDs,
		entities,
	};
}

// Adapters ---------------------------------------------------------------------------------------

function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'sportID', fields.sportID);
	adapter.addField(rules.id, 'countryID', fields.countryID);
	adapter.addField(rules.id, 'leagueID', fields.leagueID);
	adapter.addField(rules.id, 'resolutionStatusID', fields.resolutionStatusID);
	adapter.addField(rules.id, 'tradingMode', fields.tradingMode);

	adapter.addField(rules.fullDate, 'date', fields.date);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'sportName', fields.sportName);
	adapter.addField(rules.string, 'countryName', fields.countryName);
	adapter.addField(rules.string, 'leagueName', fields.leagueName);

	adapter.addField(rules.positiveNumber, 'marketsCount', fields.marketsCount);
	adapter.addField(rules.positiveNumber, 'resultedMarketsCount', fields.resultedMarketsCount);

	adapter.addField(rules.arrayObject, 'providers', fields.providers);

	return adapter;
}

function createProviderListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'iconURL', fields.iconURL);

	return adapter;
}
