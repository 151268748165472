export const PERIODS = {
	today    : 'today',
	yesterday: 'yesterday',
	thisWeek : 'thisWeek',
	thisMonth: 'thisMonth',
	lastWeek : 'lastWeek',
	lastMonth: 'lastMonth',
};

export const PROFITABILITYGRADE = {
	NEGATIVE    : 1,
	PROFITABLE  : 2,
	UNPROFITABLE: 3,
	NEUTRAL     : 4,
	1           : 'NEGATIVE',
	2           : 'PROFITABLE',
	3           : 'UNPROFITABLE',
	4           : 'NEUTRAL',
};

export const PRODUCT_TYPE = {
	SPORT : 1,
	CASINO: 2,
	SYSTEM: 3,
};
