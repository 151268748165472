import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import { CHANNEL_TYPES } from '../../../../helpers/commonConstants';
import actions from './actions';

const initState = new Map({

	treeDataSource     : [],
	expandedKeys       : [],
	reorderedCategories: [],

	showHidden  : false,
	showInactive: false,
	selected    : {
		node      : null,
		level     : null,
		nodePos   : null,
		categoryID: null,
		parentID  : null,
		parentPos : null,
		sportID   : null,
	},

	ordersUI: {
		isChanged: false,
		channelID: CHANNEL_TYPES.backend,
	},
});

export default function categoriesTreeReducer(state = initState, action) {

	switch (action.type) {

	case actions.CATEGORY_TREE_REFRESH: {
		return state.set('treeDataSource', action.data);
	}

	case actions.CATEGORY_TREE_SET_EXPANDED_KEYS: {
		return state.set('expandedKeys', action.data);
	}

	case actions.CATEGORY_TREE_SET_SHOW_HIDDEN: {
		return state.set('showHidden', action.data);
	}

	case actions.CATEGORY_TREE_SET_SHOW_INACTIVE: {
		return state.set('showInactive', action.data);
	}

	case actions.CATEGORY_TREE_SET_SELECTED: {
		const target = state.get('selected');
		const result = fill(action.data, target);
		return state.set('selected', result);
	}

	case actions.CATEGORY_TREE_REORDERED_CATEGORIES_REFRESH: {
		const { reorderedCategories } = action.data;
		return state.set('reorderedCategories', reorderedCategories);
	}

	case actions.CATEGORY_TREE_ORDERS_UI_REFRESH: {
		const { ordersUI } = action.data;
		const target = state.get('ordersUI');
		const result = fill(ordersUI, target);
		return state.set('ordersUI', result);
	}

	default:
		return state;
	}
}
