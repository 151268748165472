import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	list: [],

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},

	sorting: {
		sortBy   : 'name',
		sortOrder: 'desc', // 'asc'
	},

	UI: {
		loading: false,
	},
});

export default function participantsListReducer(state = initState, action) {

	switch (action.type) {

	case actions.PARTICIPANTS_LIST_REFRESH: {
		return state.set('list', action.data);
	}

	case actions.PARTICIPANTS_LIST_PAGINATION_REFRESH: {
		const target = state.get('pagination');
		const result = fill(action.data, target);
		return state.set('pagination', result);
	}

	case actions.PARTICIPANTS_LIST_SET_VALUE_UI: {
		const { valueName, value } = action.data;
		const UI           = new Map(state.get('UI')).toObject();
		UI[valueName]      = value;

		return state.set('UI', UI);
	}

	case actions.PARTICIPANTS_SORTING_REFRESH: {
		const target = state.get('sorting');
		const result = fill(action.data, target);
		return state.set('sorting', result);
	}

	default:
		return state;
	}
}
