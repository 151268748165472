const actions = {

	CASINO_INTEGRATORS_LIST_RELOAD      : 'CASINO_INTEGRATORS_LIST_RELOAD',
	CASINO_INTEGRATORS_LIST_REFRESH     : 'CASINO_INTEGRATORS_LIST_REFRESH',
	CASINO_INTEGRATORS_TEMP_LIST_REFRESH: 'CASINO_INTEGRATORS_TEMP_LIST_REFRESH',

	CASINO_INTEGRATORS_LIST_REFRESH_GAMES: 'CASINO_INTEGRATORS_LIST_REFRESH_GAMES',
	CASINO_INTEGRATORS_LIST_RELOAD_GAMES : 'CASINO_INTEGRATORS_LIST_RELOAD_GAMES',

	CASINO_INTEGRATORS_LIST_DELETE_ITEM      : 'CASINO_INTEGRATORS_LIST_DELETE_ITEM',
	CASINO_INTEGRATORS_LIST_SET_DELETED_ITEMS: 'CASINO_INTEGRATORS_LIST_SET_DELETED_ITEMS',

	CASINO_INTEGRATORS_BASE_DATA_REFRESH: 'CASINO_INTEGRATORS_BASE_DATA_REFRESH',
	CASINO_INTEGRATORS_BASE_DATA_RELOAD : 'CASINO_INTEGRATORS_BASE_DATA_RELOAD',

	CASINO_INTEGRATORS_CREATE_UPDATE_SAVE: 'CASINO_INTEGRATORS_CREATE_UPDATE_SAVE',
	CASINO_INTEGRATORS_DELETE_ITEM       : 'CASINO_INTEGRATORS_DELETE_ITEM',

	CASINO_INTEGRATORS_LIST_UI_REFRESH: 'CASINO_INTEGRATORS_LIST_UI_REFRESH',

	CASINO_INTEGRATORS_SAVE: 'CASINO_INTEGRATORS_SAVE',

	CASINO_INTEGRATORS_LIST_SET_FILTERED_ITEMS   : 'CASINO_INTEGRATORS_LIST_SET_FILTERED_ITEMS',

	listReload: data => ({
		type: actions.CASINO_INTEGRATORS_LIST_RELOAD,
		data,
	}),
	listRefresh: entities => ({
		type: actions.CASINO_INTEGRATORS_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	tempListRefresh: tempEntities => ({
		type: actions.CASINO_INTEGRATORS_TEMP_LIST_REFRESH,
		data: {
			tempEntities,
		},
	}),
	deleteItem: customProviderID => ({
		type: actions.CASINO_INTEGRATORS_LIST_DELETE_ITEM,
		data: {
			customProviderID,
		},
	}),
	baseDataRefresh: data => ({
		type: actions.CASINO_INTEGRATORS_BASE_DATA_REFRESH,
		data,
	}),
	setDeletedIntegrators: () => ({
		type: actions.CASINO_INTEGRATORS_LIST_SET_DELETED_ITEMS,
	}),
	baseDataReload: data => ({
		type: actions.CASINO_INTEGRATORS_BASE_DATA_RELOAD,
		data,
	}),
	integratorUpdate: data => ({
		type: actions.CASINO_INTEGRATORS_CREATE_UPDATE_SAVE,
		data,
	}),
	deleteIntegrator: integratorID => ({
		type: actions.CASINO_INTEGRATORS_DELETE_ITEM,
		data: {
			integratorID,
		},
	}),
	setFilteredEmptyIntegrators: filteredEntities => ({
		type: actions.CASINO_INTEGRATORS_LIST_SET_FILTERED_ITEMS,
		data: {
			filteredEntities,
		},
	}),
	uiRefresh: data => ({
		type: actions.CASINO_INTEGRATORS_LIST_UI_REFRESH,
		data,
	}),
};

export default actions;
