const actions = {

	BONUSES_FREE_SPINS_LIST_RELOAD : 'BONUSES_FREE_SPINS_LIST_RELOAD',
	BONUSES_FREE_SPINS_LIST_REFRESH: 'BONUSES_FREE_SPINS_LIST_REFRESH',

	BONUSES_FREE_SPINS_LIST_FILTER_REFRESH: 'BONUSES_FREE_SPINS_LIST_FILTER_REFRESH',
	BONUSES_FREE_SPINS_LIST_FILTER_APPLY  : 'BONUSES_FREE_SPINS_LIST_FILTER_APPLY',
	BONUSES_FREE_SPINS_UI_REFRESH      			: 'BONUSES_FREE_SPINS_UI_REFRESH',

	listReload: () => ({
		type: actions.BONUSES_FREE_SPINS_LIST_RELOAD,
	}),
	listRefresh: list => ({
		type: actions.BONUSES_FREE_SPINS_LIST_REFRESH,
		data: {
			list,
		},
	}),
	uiRefresh: (UI) => ({
		type: actions.BONUSES_FREE_SPINS_UI_REFRESH,
		data: { UI },
	}),
	filterRefresh: data => ({
		type: actions.BONUSES_FREE_SPINS_LIST_FILTER_REFRESH,
		data,
	}),
	filterApply: () => ({
		type: actions.BONUSES_FREE_SPINS_LIST_FILTER_APPLY,
	}),
};

export default actions;
