import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import toInteger from 'lodash/toInteger';

import notifications from '../../../../helpers/notifications';
import { categoriesAPI } from '../../../../helpers/api/categories';
import { marketsAPI } from '../../../../helpers/api/markets';
import { CATEGORIES_TYPES } from '../../../../helpers/commonConstants';

import actions from './actions';
import treeActions from '../tree/actions';
import commonActions from '../../../common/actions';
import { updateNodeInTree } from '../tree/utils';
import {
	adaptBaseData,
	adaptNamesData,
	adaptSettingsData,
	adaptDynamiLimits,
	adaptCMSData,
	adaptGeneralMarketList,
	adaptLeagueMarketList,
	adaptLeagueMarginList,
	adaptHistoryData,
	prepareBaseData,
	prepareNamesData,
	prepareSettingsData,
	prepareDynamicLimits,
	prepareCMSData,
	prepareMarketsData,
	prepareMarginsData,
	changeCode,
} from './utils';
import { adaptSeoData, prepareSeoData } from '../../../casino/game/utils';
import { restoreWebsite } from '../../../../helpers/utility';

const messages = {
	loadBaseData             : 'Loading category base data failed',
	loadSettingsData         : 'Loading category settings failed',
	loadDynamicData          : 'Loading category dynamic limits failed',
	loadNamesData            : 'Loading category names failed',
	loadCMSData              : 'Loading category descriptions failed',
	loadMarketsData          : 'Loading category markets failed',
	loadHistoryData          : 'Loading category history failed',
	loadTournamentType       : 'Loading league tournament type failed',
	loadSportRegulationTypes : 'Loading sport regulation types failed',
	loadLeagueRegulationTypes: 'Loading league regulation types failed',
	loadGeneralMarketList    : 'Loading general market list failed',
	loadLeagueMarketList     : 'Loading league market list failed',
	loadLeagueMarginList     : 'Loading league margin list failed',

	errorSeoReload: 'Loading category SEO failed',
	errorSeoSave  : 'Failed to save Seo data',

	successBaseData       : 'Category base data has been updated',
	successSettingsData   : 'Category settings have been updated',
	successDynamicData    : 'Category dynamic limits have been updated',
	successNamesData      : 'Category names have been updated',
	successCMSData        : 'Category descriptions have been updated',
	successMarketsData    : 'Category markets have been updated',
	successMarginsData    : 'Category markets margins have been updated',
	successRegulationTypes: 'Category regulation types have been updated',
	successTournamentType : 'Category tournament type have been updated',

	updateBaseData       : 'Updating category base data failed',
	updateSettingsData   : 'Updating category settings failed',
	updateDynamicData    : 'Updating category dynamic limits failed',
	updateNamesData      : 'Updating category names failed',
	updateCMSData        : 'Updating category descriptions failed',
	updateMarketsData    : 'Updating category markets failed',
	updateMarginsData    : 'Updating category markets margins failed',
	updateRegulationTypes: 'Updating category regulation types failed',
	updateTournamentType : 'Updating category tournament type failed',
};

function getStoreData({ Sport : { Categories }, App }) {

	return {
		baseData                : Categories.Category.get('baseData'),
		settingsData            : Categories.Category.get('settingsData'),
		dynamicData             : Categories.Category.get('dynamicData'),
		namesData               : Categories.Category.get('namesData'),
		cmsData                 : Categories.Category.get('cmsData'),
		marketsData             : Categories.Category.get('marketsData'),
		leagueRegulationTypeList: Categories.Category.get('leagueRegulationTypeList'),
		modalUI                 : Categories.Category.get('modalUI'),
		isChanged               : Categories.Category.get('isChanged'),
		seoData                 : Categories.Category.get('seoData'),
		showInactive            : Categories.Tree.get('showInactive'),
		ordersUI                : Categories.Tree.get('ordersUI'),
		marketsUI               : Categories.Category.get('marketsUI'),

		treeDataSource: Categories.Tree.get('treeDataSource'),
		websiteID     : App.get('websiteID'),
	};
}

function* baseDataReload() {

	let errorMessage = messages.loadBaseData;

	yield takeEvery(actions.CATEGORY_BASE_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const { modalUI } = storeData;
		const { langID } = modalUI;
		const { categoryID } = action.data;

		const params = {
			lang_id             : langID,
			model_type          : 2,
			include_parent_limit: true,
		};

		let baseData = {};
		try {
			// base data
			errorMessage = messages.loadBaseData;
			const response = yield call(categoriesAPI.categoryInfo, categoryID, params);
			if (response && response.status === 200) {
				baseData = adaptBaseData(response.data.data);
			}

			// League tournament type
			errorMessage = messages.loadTournamentType;
			if (baseData.typeID === CATEGORIES_TYPES.league || baseData.typeID === CATEGORIES_TYPES.subleague) {
				const response = yield call(categoriesAPI.leagueTournamentList, categoryID);
				if (response && response.status === 200) {
					baseData.tournamentTypeID = toInteger(response.data.data.type_id) || null;
				}
			}

			yield put(actions.baseDataRefresh(baseData));
			yield put(actions.modalUIRefresh({ categoryColor: baseData.categoryColor }));

		} catch (error) {
			notifications.showError(errorMessage);
			console.log(error);
		}

		yield put(actions.modalUIRefresh({ loading: false }));
		yield put(actions.isChangedRefresh({ base: false }));
	});
}

function* namesDataReload() {

	yield takeEvery(actions.CATEGORY_NAMES_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { categoryID } = action.data;

		const storeData = yield select(getStoreData);
		const { modalUI } = storeData;
		const { langID, categoryName } = modalUI;

		const params = { lang_id: langID };
		let data = adaptNamesData([], categoryName);
		try {
			const response = yield call(categoriesAPI.categoryNamesList, categoryID, params);
			if (response && response.status === 200) {
				data = adaptNamesData(response.data.data, categoryID);
			}
		} catch (error) {
			notifications.showError(messages.loadNamesData);
			console.log(error);
		}

		yield put(actions.namesDataRefresh(data));
		yield put(actions.modalUIRefresh({ loading: false }));
		yield put(actions.isChangedRefresh({ names: false }));
	});
}

function* settingsDataReload() {

	yield takeEvery(actions.CATEGORY_SETTINGS_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const { modalUI } = storeData;
		const { typeID } = modalUI;
		const { categoryID } = action.data;
		const params = { include_parent_limit: true };
		let data = adaptSettingsData([], typeID);
		try {
			const response = yield call(categoriesAPI.categorySettingsList, categoryID, params);
			if (response && response.status === 200) {
				data = adaptSettingsData(response.data.data, typeID);
			}
		} catch (error) {
			notifications.showError(messages.loadSettingsData);
			console.log(error);
		}

		yield put(actions.settingsDataRefresh(data));
		yield put(actions.modalUIRefresh({ loading: false }));
		yield put(actions.isChangedRefresh({ settings: false }));
	});
}

function* dynamicDataReload() {

	yield takeEvery(actions.CATEGORY_DYNAMIC_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { categoryID } = action.data;
		let data = adaptDynamiLimits([]);
		try {
			const response = yield call(categoriesAPI.categoryDynamicLimitsList, categoryID);
			if (response && response.status === 200) {
				data = adaptDynamiLimits(response.data.data);
			}
		} catch (error) {
			notifications.showError(messages.loadDynamicData);
			console.log(error);
		}

		yield put(actions.dynamicDataRefresh(data));
		yield put(actions.modalUIRefresh({ loading: false }));
		yield put(actions.isChangedRefresh({ dynamic: false }));
	});
}

function* cmsDataReload() {

	yield takeEvery(actions.CATEGORY_CMS_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const { modalUI } = storeData;
		const { langID } = modalUI;
		const { categoryID } = action.data;

		const params = { lang_id: langID };
		let data = adaptCMSData([], categoryID);
		try {
			const response = yield call(categoriesAPI.categoryDescriptionsList, categoryID, params);
			if (response && response.status === 200) {
				data = adaptCMSData(response.data.data, categoryID);
			}
		} catch (error) {
			notifications.showError(messages.loadCMSData);
			console.log(error);
		}

		yield put(actions.cmsDataRefresh(data));
		yield put(actions.modalUIRefresh({ loading: false }));
		yield put(actions.isChangedRefresh({ cms: false }));
	});
}

function* historyDataReload() {

	const takeAction = actions.CATEGORY_HISTORY_DATA_RELOAD;
	const errorMessage = messages.loadHistoryData;

	yield takeEvery(takeAction, function* (action) {
		yield put(actions.modalUIRefresh({ loading: true }));

		let data = adaptHistoryData({});
		const { categoryID } = action.data;
		try {
			const response = yield call(
				categoriesAPI.categoryHistoryList,
				categoryID
			);
			if (response && response.status === 200) {
				data = adaptHistoryData(response.data.data);
				yield put(actions.baseDataRefresh(data));
			}
		} catch (error) {
			notifications.showError(errorMessage);
			console.log(error);
		}

		yield put(actions.historyDataRefresh(data));
		yield put(actions.modalUIRefresh({ loading: false }));
	});
}

function* sportRegulationTypeListReload() {

	yield takeEvery(actions.CATEGORY_SPORT_REGULATION_TYPE_LIST_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { sportID } = action.data;
		let sportRegulationTypeList = [];

		try {
			const response = yield call(categoriesAPI.regulationTypesList, sportID);
			if (response && response.status === 200) {
				sportRegulationTypeList = response.data.data;
			}
		} catch (error) {
			notifications.showError(messages.loadSportRegulationTypes);
			console.log(error);
		}

		yield put(actions.sportRegulationTypeListRefresh(sportRegulationTypeList));
		yield put(actions.modalUIRefresh({ loading: false }));
		yield put(actions.isChangedRefresh({ regulationTypes: false }));
	});
}

function* leagueRegulationTypeListReload() {

	yield takeEvery(actions.CATEGORY_LEAGUE_REGULATION_TYPE_LIST_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { leagueID } = action.data;
		let leagueRegulationTypeList = [];

		try {
			const response = yield call(categoriesAPI.leagueRegulationsList, leagueID);
			if (response && response.status === 200) {
				leagueRegulationTypeList = response.data.data.regulation_types || [];
			}

		} catch (error) {
			notifications.showError(messages.loadLeagueRegulationTypes);
			console.log(error);
		}

		yield put(actions.leagueRegulationTypeListRefresh(leagueRegulationTypeList));
		yield put(actions.modalUIRefresh({ loading: false }));
		yield put(actions.isChangedRefresh({ regulationTypes: false }));
	});
}

function* marketsDataReload() {

	yield takeEvery(actions.CATEGORY_MARKETS_DATA_RELOAD, function* (action) {

		yield put(actions.modalUIRefresh({ loading: true }));

		const { sportID, leagueID } = action.data;

		let marketIDs              = [];
		let liveMarketIDs          = [];
		let prematchMarketIDs      = [];
		let marketEntities         = {};
		let channelMarketList      = adaptLeagueMarketList([]);
		let leagueMarketMarginList = [];

		let params                 = null;
		let errorMessage           = null;
		try {
			// general markets list
			errorMessage = messages.loadGeneralMarketList;
			params = { category_id: sportID };

			const resGeneral = yield call(marketsAPI.marketList, params);
			if (resGeneral && resGeneral.status === 200) {
				const result   = adaptGeneralMarketList(resGeneral.data.data);
				marketIDs      = result.marketIDs;      // eslint-disable-line prefer-destructuring
				marketEntities = result.marketEntities; // eslint-disable-line prefer-destructuring
			}

			// channel market list
			errorMessage = messages.loadLeagueMarketList;
			const resLeague = yield call(categoriesAPI.categoryMarketsList, leagueID);
			if (resLeague && resLeague.status === 200) {
				const result      = adaptLeagueMarketList(resLeague.data.data);
				liveMarketIDs     = result.liveMarketIDs;     // eslint-disable-line prefer-destructuring
				prematchMarketIDs = result.prematchMarketIDs; // eslint-disable-line prefer-destructuring
				channelMarketList = result.channelMarketList; // eslint-disable-line prefer-destructuring
			}

			// league margin list
			errorMessage = messages.loadLeagueMarginList;
			const resMargin = yield call(categoriesAPI.leagueMarketMarginList, leagueID);
			if (resMargin && resMargin.status === 200) {
				leagueMarketMarginList = adaptLeagueMarginList(resMargin.data.data, liveMarketIDs, prematchMarketIDs);
			}
		} catch (error) {
			notifications.showError(errorMessage);
			console.log(error);
		}

		yield put(
			actions.marketsUIRefresh({
				marketIDs,
				marketEntities,
				liveMarketIDs,
				prematchMarketIDs,
				channelMarketList,

				leagueMarketMarginList,
			})
		);
		yield put(actions.modalUIRefresh({ loading: false }));
		yield put(actions.isChangedRefresh({
			markets: false,
			margins: false,
		}));
	});
}

function* saveCategory() {

	let errorMessage = '';

	yield takeEvery(actions.CATEGORY_SAVE, function* () {

		yield put(actions.modalUIRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const {
			baseData,
			settingsData,
			dynamicData,
			namesData,
			cmsData,
			marketsUI,
			leagueRegulationTypeList,
			modalUI,
			isChanged,
			treeDataSource,
			websiteID,
		} = storeData;

		const { categoryName, langID, sportID, editMode, closeModal } = modalUI;
		let { categoryID } = modalUI;
		const params = { website_id: websiteID };
		let isError = false;

		try {
			// base data
			errorMessage = messages.updateBaseData;
			const preparedData = prepareBaseData(baseData);

			yield put(actions.seoDataSave());

			if (editMode) {
				preparedData.id = categoryID;
				const res = yield call(categoriesAPI.updateCategory, preparedData, params);
				if (res.status === 200) {
					const result = res.data.data;
					const treeCopy = changeCode(treeDataSource, result);
					yield put(treeActions.categoryTreeRefresh(treeCopy));
				}

			} else {
				preparedData.name = categoryName;
				const response = yield call(categoriesAPI.addCategory, preparedData, params);
				categoryID = toInteger(response.data.data.id);

				yield put(actions.modalUIRefresh({
					categoryID,
					editMode: true,
				}));
			}

			// names
			if (isChanged.names) {
				errorMessage = messages.updateNamesData;
				const preparedData = prepareNamesData(namesData, categoryID, langID, websiteID);
				const params = { lang_id: langID };
				yield call(categoriesAPI.categoryNamesUpdate, categoryID, preparedData, params);
				yield put(actions.isChangedRefresh({ names: false }));
			}

			// settings
			if (isChanged.settings) {
				errorMessage = messages.updateSettingsData;
				const preparedData = prepareSettingsData(settingsData);
				yield call(categoriesAPI.categorySettingsUpdate, categoryID, preparedData);
				yield put(actions.isChangedRefresh({ settings: false }));
			}

			// dynamic limits
			if (isChanged.dynamic) {
				errorMessage = messages.updateDynamicData;
				const preparedData = prepareDynamicLimits(dynamicData, categoryID);
				yield call(categoriesAPI.categoryDynamicLimitsUpdate, categoryID, preparedData);
				yield put(actions.isChangedRefresh({ dynamic: false }));
			}

			// CMS
			if (isChanged.cms) {
				errorMessage = messages.updateCMSData;
				const preparedData = prepareCMSData(cmsData, categoryID);
				const params = { lang_id: langID };
				yield call(categoriesAPI.categoryDescriptionsUpdate, categoryID, preparedData, params);
				yield put(actions.isChangedRefresh({ cms: false }));
			}

			// markets
			if (isChanged.markets) {
				errorMessage = messages.updateMarketsData;
				const preparedData = prepareMarketsData(
					marketsUI.channelMarketList,
					categoryID
				);
				yield call(categoriesAPI.categoryMarketsUpdate, categoryID, preparedData);
				yield put(actions.isChangedRefresh({ markets: false }));
			}

			// margins
			if (isChanged.margins) {
				errorMessage = messages.updateMarginsData;
				const preparedData = prepareMarginsData(
					marketsUI.leagueMarketMarginList,
					categoryID
				);
				yield call(categoriesAPI.leagueMarketMarginUpdate, categoryID, preparedData);
				yield put(actions.isChangedRefresh({ margins: false }));
			}

			// regulation types
			if (isChanged.regulationTypes) {
				errorMessage = messages.updateRegulationTypes;
				const preparedData = {
					regulation_types: leagueRegulationTypeList.map(item => toInteger(item)),
				};
				yield call(categoriesAPI.leagueRegulationsUpdate, categoryID, preparedData);
				yield put(actions.isChangedRefresh({ regulationTypes: false }));
			}

			// tournament type
			if (isChanged.tournamentType) {
				errorMessage = messages.updateTournamentType;
				const preparedData = { type_id: baseData.tournamentTypeID };
				yield call(categoriesAPI.leagueTournamentUpdate, categoryID, preparedData);
				yield put(actions.isChangedRefresh({ tournamentType: false }));
			}

			notifications.showSuccess(messages.successBaseData);

		} catch (error) {
			isError = true;
			notifications.showError(errorMessage);
			console.dir(error);
		}

		// reload data
		if (!closeModal) {
			yield put(actions.baseDataReload(categoryID));

			if (isChanged.names) {
				yield put(actions.namesDataReload(categoryID));
			}
			if (isChanged.settings) {
				yield put(actions.settingsDataReload(categoryID));
			}
			if (isChanged.dynamic) {
				yield put(actions.dynamicDataReload(categoryID));
			}
			if (isChanged.cms) {
				yield put(actions.cmsDataRefresh(categoryID));
			}
			if (isChanged.regulationTypes) {
				yield put(actions.leagueRegulationTypeListReload(categoryID));
			}

			if (isChanged.markets || isChanged.margins) {
				yield put(actions.marketsDataReload(sportID, categoryID));
			}
		}

		// update data in Tree
		// add new category
		if (!editMode) {
			const { typeID, parentID, parentPos } = modalUI;
			if (typeID === CATEGORIES_TYPES.sport) {
				yield put(commonActions.commonSportListReload());
			} else if (typeID === CATEGORIES_TYPES.country) {
				yield put(treeActions.countryListReload(parentID, parentPos));
			} else if (
				typeID === CATEGORIES_TYPES.league
        || typeID === CATEGORIES_TYPES.subleague
			) {
				yield put(treeActions.leagueListReload(parentID, parentPos));
			}

			// update existing
		} else {
			const { categoryPos } = modalUI;
			const nodeData = {
				name           : categoryName,
				iconURL        : baseData.iconURL,
				displayStatusID: toInteger(baseData.displayStatusID),
				statusID       : toInteger(baseData.statusID),
			};
			const result = updateNodeInTree(treeDataSource, categoryPos, nodeData);

			yield put(treeActions.categoryTreeRefresh(result.treeDataSource));
			yield put(treeActions.setSelected({ node: result.node }));
		}

		// post-processing
		yield put(
			actions.modalUIRefresh({
				loading: false,
				visible: !(closeModal && !isError),
			})
		);
	});
}

function* seoDataReload() {

	yield takeEvery(actions.SPORT_CATEGORY_SEO_DATA_RELOAD, function* () {
		yield put(actions.modalUIRefresh({ loading: true }));
		const { modalUI : { categoryID } } = yield select(getStoreData);

		try {
			const res = yield call(categoriesAPI.getSeo, categoryID);
			if (res && res.status === 200) {
				const adapted = adaptSeoData(res.data.data);
				yield put(actions.seoDataRefresh(adapted));
			}

		} catch (error) {
			notifications.showError(messages.errorSeoReload);
			console.log(error);
		}
		yield put(actions.modalUIRefresh({ loading: false }));
	});
}

function* seoDataSave() {

	yield takeEvery(actions.SPORT_CATEGORY_SEO_DATA_SAVE, function* () {
		yield put(actions.modalUIRefresh({ loading: true }));
		const { seoData, modalUI : { categoryID } } = yield select(getStoreData);
		const params = {
			website_id: restoreWebsite(),
		};
		try {
			const preparedData = prepareSeoData(seoData, true);
			yield call(categoriesAPI.seoSave, preparedData, categoryID, params);

		} catch (e) {
			notifications.showError(messages.errorSeoSave);
		}
		yield put(actions.modalUIRefresh({ loading: false }));
	});
}

export default function* categorySaga() {
	yield all([
		fork(baseDataReload),
		fork(namesDataReload),
		fork(settingsDataReload),
		fork(dynamicDataReload),
		fork(cmsDataReload),

		fork(historyDataReload),
		fork(sportRegulationTypeListReload),
		fork(leagueRegulationTypeListReload),
		fork(marketsDataReload),

		fork(saveCategory),

		fork(seoDataReload),
		fork(seoDataSave),
	]);
}
