import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	columnsIDs     : [],
	columnsEntities: {},

	itemsIDs     : [],
	itemsEntities: {},

	revisionsEntities: {},

	UI: {
		loading      : false,
		showInvisible: true,
		revisionID   : null,
		isMenuChanged: false,
	},

	modalColumnUI: {
		visible   : false,
		editMode  : false,
		loading   : false,
		columnID  : null,
		columnItem: {},
		closeModal: false,
		langID    : 1,
	},

	modalItemUI: {
		visible   : false,
		editMode  : false,
		loading   : false,
		itemID    : null,
		item      : {},
		closeModal: false,
		langID    : 1,
	},
});

export default function contentArticlesReducer(state = initState, action) {
	switch (action.type) {
	case actions.CONTENT_ARTICLES_SETTINGS_REFRESH: {
		const {
			columnsIDs,
			columnsEntities,
			itemsIDs,
			itemsEntities,
		} = action.data;
		return state
			.set('columnsIDs', columnsIDs)
			.set('columnsEntities', columnsEntities)
			.set('itemsIDs', itemsIDs)
			.set('itemsEntities', itemsEntities);
	}

	case actions.CONTENT_ARTICLES_COLUMNS_LIST_REFRESH: {
		const { columnsIDs, columnsEntities } = action.data;
		return state
			.set('columnsIDs', columnsIDs)
			.set('columnsEntities', columnsEntities);
	}

	case actions.CONTENT_ARTICLES_ITEMS_LIST_REFRESH: {
		const { itemsIDs, itemsEntities } = action.data;
		return state
			.set('itemsIDs', itemsIDs)
			.set('itemsEntities', itemsEntities);
	}

	case actions.CONTENT_ARTICLES_REVISIONS_LIST_REFRESH: {
		return state.set('revisionsEntities', action.data);
	}

	case actions.CONTENT_ARTICLES_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.CONTENT_ARTICLES_UI_MODAL_COLUMN_REFRESH: {
		const target = state.get('modalColumnUI');
		const result = fill(action.data, target);
		return state.set('modalColumnUI', result);
	}

	case actions.CONTENT_ARTICLES_UI_MODAL_ITEM_REFRESH: {
		const target = state.get('modalItemUI');
		const result = fill(action.data, target);
		return state.set('modalItemUI', result);
	}

	default:
		return state;
	}
}
