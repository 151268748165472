const actions = {

	BANNERS_LIST_RELOAD : 'BANNERS_LIST_RELOAD',
	BANNERS_LIST_REFRESH: 'BANNERS_LIST_REFRESH',

	BANNERS_LIST_FILTER_REFRESH    : 'BANNERS_LIST_FILTER_REFRESH',
	BANNERS_LIST_FILTER_APPLY      : 'BANNERS_LIST_FILTER_APPLY',
	BANNERS_LIST_PAGINATION_REFRESH: 'BANNERS_LIST_PAGINATION_REFRESH',

	BANNERS_LIST_COPY_ITEM  : 'BANNERS_LIST_COPY_ITEM',
	BANNERS_LIST_DELETE_ITEM: 'BANNERS_LIST_DELETE_ITEM',
	BANNERS_LIST_UI_REFRESH	: 'BANNERS_LIST_UI_REFRESH',

	listReload: () => ({
		type: actions.BANNERS_LIST_RELOAD,
	}),

	uiRefresh: data => ({
		type: actions.BANNERS_LIST_UI_REFRESH,
		data,
	}),
	listRefresh: entities => ({
		type: actions.BANNERS_LIST_REFRESH,
		data: {
			entities,
		},
	}),

	filterRefresh: data => ({
		type: actions.BANNERS_LIST_FILTER_REFRESH,
		data,
	}),

	filterApply: () => ({
		type: actions.BANNERS_LIST_FILTER_APPLY,
	}),

	copyItem: bannerID => ({
		type: actions.BANNERS_LIST_COPY_ITEM,
		data: {
			bannerID,
		},
	}),

	deleteItem: bannerID => ({
		type: actions.BANNERS_LIST_DELETE_ITEM,
		data: {
			bannerID,
		},
	}),

};

export default actions;
