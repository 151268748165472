const actions = {

	WEBSITE_SCRIPTS_RELOAD    : 'WEBSITE_SCRIPTS_RELOAD',
	WEBSITE_SCRIPTS_REFRESH   : 'WEBSITE_SCRIPTS_REFRESH',
	WEBSITE_SCRIPTS_UI_REFRESH: 'WEBSITE_SCRIPTS_UI_REFRESH',
	WEBSITE_SCRIPTS_SAVE      : 'WEBSITE_SCRIPTS_SAVE',

	scriptsReload: websiteID => ({
		type: actions.WEBSITE_SCRIPTS_RELOAD,
		data: {
			websiteID,
		},
	}),
	scriptsRefresh: scriptsList => ({
		type: actions.WEBSITE_SCRIPTS_REFRESH,
		data: {
			scriptsList,
		},
	}),
	uiRefresh: data => ({
		type: actions.WEBSITE_SCRIPTS_UI_REFRESH,
		data,
	}),
	scriptsSave: (websiteID, scriptsList) => ({
		type: actions.WEBSITE_SCRIPTS_SAVE,
		data: {
			websiteID,
			scriptsList,
		},
	}),
};

export default actions;
