import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import { liveStatisticTypesActions } from './actions';

const initState = new Map({
	list    : [],
	entities: {},

	UI: {
		loading     : false,
		editMode    : false,
		visibleModal: false,
	},
});

export default function liveStatisticTypesReducer(state = initState, action) {

	switch (action.type) {
	case liveStatisticTypesActions.LIVE_STATISTIC_TYPES_LIST_RELOAD_CASE: {
		return state.set('list', action.data);
	}

	case liveStatisticTypesActions.LIVE_STATISTIC_TYPES_ENTITIES_RELOAD_CASE: {
		return state.set('entities', action.data);
	}

	case liveStatisticTypesActions.LIVE_STATISTIC_TYPES_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target, true);
		return state.set('UI', result);
	}

	default:
		return state;
	}
}
