import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';

import { websitesAPI } from '../../../../helpers/api/websites';
import { showError, showSuccess } from '../../../../helpers/notifications';

import { adaptScriptsList, prepareScriptsList } from './utils';

const prefix = 'partner.websites.script';

const messages = {
	errorScriptsLoad  : `${prefix}.errorScriptsLoad`,
	errorScriptsSave  : `${prefix}.errorScriptsSave`,
	successScriptsSave: `${prefix}.successScriptsSave`,
};

function* scriptsReload() {

	yield takeEvery(actions.WEBSITE_SCRIPTS_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { websiteID } = action.data;

		let scriptsList = [];
		try {
			const res = yield call(websitesAPI.scriptsList, websiteID);
			if (res && res.status === 200) {
				scriptsList = adaptScriptsList(res.data.data);
			}

		} catch (error) {
			showError(messages.errorScriptsLoad, error);
			console.log(error);
			yield put(actions.uiRefresh({ loading: false }));
		}

		yield put(actions.scriptsRefresh(scriptsList));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* scriptsSave() {

	yield takeEvery(actions.WEBSITE_SCRIPTS_SAVE, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { websiteID, scriptsList } = action.data;

		const preparedData = prepareScriptsList(scriptsList);
		try {
			const res = yield call(websitesAPI.scriptUpdate, websiteID, preparedData);
			if (res && res.status === 200) {
				showSuccess(messages.successScriptsSave);
				yield put(actions.scriptsReload(websiteID));
				return;
			}

		} catch (error) {
			showError(messages.errorScriptsSave, error);
			yield put(actions.uiRefresh({ loading: false }));
			console.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* websiteScriptsSaga() {
	yield all([
		fork(scriptsReload),
		fork(scriptsSave),
	]);
}
