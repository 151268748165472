import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import { WEBSITE_INTEGRATION_TABS } from '../../../../constants/website';
import { fill } from '../../../../helpers/utils';
import actions from './actions';
import { objectsKeys } from '../../../../containers/Partner/Websites/modals/Websites/TabIntegration/utils/utils';

const initState = new Map({
	baseData: {},
	payments: {},

	paymentsListWithNames: {},
	paymentLimits      		: {},
	paymentDescriptions		: {},
	casino             		: {},
	currencies         		: {},
	mailingList        		: {},
	countries          		: {},
	languages          		: {},
	activeTab          		: 'main',
	id                 		: '',
	seoData         					: {},

	UI: {
		visible       			: false,
		websiteID      		: null,
		editMode       		: false,
		loading       			: false,
		closeModal     		: false,
		langID        			: null,
		currentTab     		: WEBSITE_INTEGRATION_TABS.mailingService,
		isBaseChanged   	: false,
		checkAll       		: false,
		isSeoChanged    	: false,
		attachedLangCount: 0,
	},
	changedFields: {
		suspendAll   		: false,
		suspendPrematch: false,
		suspendInplay 	: false,
	},
});
const { websiteAffiliates, websiteAffiliate } = objectsKeys;
const { affiliates } = WEBSITE_INTEGRATION_TABS;

export default function websitesModalReducer(state = initState, action) {
	switch (action.type) {
	case actions.WEBSITE_BASE_DATA_REFRESH: {
		const target = state.get('baseData');
		if (action.currentTab === affiliates &&  target[websiteAffiliate] && target[websiteAffiliate].length  ) {
			action.data[websiteAffiliates][0].id = target?.[websiteAffiliate]?.[0]?.id;
		}
		delete target.website_affiliate;
		const result = fill(action.data, target, true);
		return state.set('baseData', result);
	}
	case actions.WEBSITE_MAILING_LIST: {
		const target = state.get('baseData');
		const result = fill(action.data.mailingList, target, true);
		return state.set('baseData', result);
	}

	case actions.WEBSITE_ADDITIONAL_DOMAINS_REFRESH: {
		const target = state.get('baseData');
		const result = cloneDeep(target);
		result.additionalDomains = action.data;
		return state.set('baseData', result);
	}

	case actions.WEBSITE_PAYMENTS_DATA_REFRESH: {
		return state.set('payments', action.data);
	}

	case actions.WEBSITE_PAYMENTS_LIST_NAMES_DATA_REFRESH: {
		return state.set('paymentsListWithNames', action.data);
	}

	case actions.WEBSITE_PAYMENTS_CHANGED_IDS_REFRESH: {
		const { changedPaymentsIDs } = action.data;
		return state.set('changedPaymentsIDs', changedPaymentsIDs);
	}

	case actions.WEBSITE_PAYMENT_LIMITS_DATA_REFRESH: {
		const { websitePaymentID, limitsData } = action.data;
		const paymentLimits = cloneDeep(state.get('paymentLimits'));
		paymentLimits[websitePaymentID] = limitsData;
		return state.set('paymentLimits', paymentLimits);
	}

	case actions.WEBSITE_PAYMENT_DESCRIPTION_DATA_REFRESH: {
		const { websitePaymentID, data } = action.data;
		const paymentDescriptions = cloneDeep(state.get('paymentDescriptions'));
		paymentDescriptions[websitePaymentID] = data;
		return state.set('paymentDescriptions', paymentDescriptions);
	}

	case actions.WEBSITE_CASINO_DATA_REFRESH: {
		return state.set('casino', action.data);
	}

	case actions.WEBSITE_CURRENCIES_REFRESH: {
		return state.set('currencies', action.data);
	}

	case actions.WEBSITE_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.WEBSITE_CHANGED_FIELDS_REFRESH: {
		const target = state.get('changedFields');
		const result = fill(action.data, target);
		return state.set('changedFields', result);
	}

	case actions.WEBSITE_DATA_RESET: {
		return initState;
	}

	case actions.SET_ACTIVE_TAB: {
		return state.set('activeTab', action.data);
	}
	case actions.WEBSITE_BASE_DATA_CDN_ID: {
		return state.set('id', action.data);
	}

	// case actions.WEBSITE_COUNTRY_LIST_REFRESH: {
	//   return  state.set('countries', action.data);
	// }
	case actions.WEBSITE_ALL_COUNTRY_LIST_REFRESH: {
		return  state.set('countries', action.data);
	}

	case actions.WEBSITE_ALL_LANGUAGES_LIST_REFRESH: {
		return  state.set('languages', action.data);
	}
	case actions.WEBSITE_SEO_DATA_REFRESH: {
		return state.set('seoData', action.data);
	}

	default:
		return state;
	}
}
