import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { fill } from '../../../../helpers/utils';
import { CASINO_GAMES_CATEGORY, CHANNEL_TYPES, CASINO_GAMES_GRID_MODE } from '../../../../helpers/commonConstants';
import actions from './actions';

const initState = new Map({

	defaultGrid : [],
	gamesList   : [],
	categoryList: [],

	gridLayouts: {
		[CASINO_GAMES_CATEGORY.all]: [], // 0 - is an ID for category 'Popular (all)' - predefined game category
	},

	changedLayouts: [],

	UI: {
		loading        : false,
		channelID      : CHANNEL_TYPES.backend,
		categoryID     : CASINO_GAMES_CATEGORY.all,
		gridMode       : CASINO_GAMES_GRID_MODE.byDefault,
		isGridsChanged : false,
		gridLoaded     : false,
		gridInitialized: false,
		visible        : false,

		casinoGameID  : null,
		casinoGameName: '',
		closeModal    : false,
		langID        : null,
		isBaseChanged : false,
		isNamesChanged: false,
		tags          : [],
	},
});

export default function gamesGridsReducer(state = initState, action) {

	switch (action.type) {

	case actions.GAMES_GRIDS_DEFAULT_GRID_REFRESH: {
		const { defaultGrid } = action.data;
		return state.set('defaultGrid', defaultGrid);
	}

	case actions.GAMES_GRIDS_GAMES_LIST_MERGE: {
		const { gamesList } = action.data;
		const existingList = state.get('gamesList');
		const result = uniqBy([...existingList, ...gamesList], 'id');

		return state.set('gamesList', result);
	}

	case actions.GAMES_GRIDS_GAMES_LIST_CLEAR: {
		return state.set('gamesList', []);
	}

	case actions.GAMES_GRIDS_CATEGORY_LIST_REFRESH: {
		const { categoryList } = action.data;
		return state.set('categoryList', categoryList);
	}

	case actions.GAMES_GRIDS_GRID_LAYOUT_SET: {
		const { categoryID, gridLayout } = action.data;

		const gridLayouts       = cloneDeep(state.get('gridLayouts'));
		gridLayouts[categoryID] = gridLayout;

		return state.set('gridLayouts', gridLayouts);
	}

	case actions.GAMES_GRIDS_GRID_LAYOUTS_SET: {
		const { gridLayouts } = action.data;
		return state.set('gridLayouts', gridLayouts);
	}

	case actions.GAMES_GRIDS_GRID_LAYOUTS_CLEAR: {
		const gridLayouts = state.get('gridLayouts');
		const result = {
			[CASINO_GAMES_CATEGORY.all]: gridLayouts[CASINO_GAMES_CATEGORY.all],
		};

		return state.set('gridLayouts', result);
	}

	case actions.GAMES_GRIDS_UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.GAMES_GRIDS_ADD_TO_CHANGED_LAYOUTS: {
		const { categoryID } = action.data;
		const changedLayouts = state.get('changedLayouts');
		if (changedLayouts.includes(categoryID)) {
			return state.set('changedLayouts', changedLayouts);
		}

		changedLayouts.push(categoryID);
		return state.set('changedLayouts', cloneDeep(changedLayouts));
	}

	case actions.GAMES_GRIDS_CLEAR_CHANGED_LAYOUTS: {
		return state.set('changedLayouts', []);
	}

	case actions.DATA_RESET: {
		return initState;
	}

	default:
		return state;
	}
}
