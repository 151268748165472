const actions = {

	EVENTS_FILTER_SET_VALUE           : 'EVENTS_FILTER_SET_VALUE',
	EVENTS_FILTER_REFRESH             : 'EVENTS_FILTER_REFRESH',
	EVENTS_FILTER_COUNTRY_LIST_RELOAD : 'EVENTS_FILTER_COUNTRY_LIST_RELOAD',
	EVENTS_FILTER_COUNTRY_LIST_REFRESH: 'EVENTS_FILTER_COUNTRY_LIST_REFRESH',
	EVENTS_FILTER_LEAGUE_LIST_RELOAD  : 'EVENTS_FILTER_LEAGUE_LIST_RELOAD',
	EVENTS_FILTER_LEAGUE_LIST_REFRESH : 'EVENTS_FILTER_LEAGUE_LIST_REFRESH',
	EVENTS_FILTER_NAME_LIST_RELOAD 		 : 'EVENTS_FILTER_NAME_LIST_RELOAD',
	EVENTS_FILTER_NAME_LIST_REFRESH   : 'EVENTS_FILTER_NAME_LIST_REFRESH',
	EVENTS_CLEAR_FILTERS      							 : 'EVENTS_CLEAR_FILTERS',

	clearEventFilters: () => ({
		type: actions.EVENTS_CLEAR_FILTERS,
	}),

	filterSetValue: (valueName, value) => ({
		type: actions.EVENTS_FILTER_SET_VALUE,
		data: {
			valueName,
			value,
		},
	}),
	filterRefresh: data => ({
		type: actions.EVENTS_FILTER_REFRESH,
		data,
	}),
	countryListReload: sportID => ({
		type: actions.EVENTS_FILTER_COUNTRY_LIST_RELOAD,
		data: {
			sportID,
		},
	}),
	countryListRefresh: list => ({
		type: actions.EVENTS_FILTER_COUNTRY_LIST_REFRESH,
		data: list,
	}),
	leagueListReload: countryID => ({
		type: actions.EVENTS_FILTER_LEAGUE_LIST_RELOAD,
		data: {
			countryID,
		},
	}),
	leagueListRefresh: list => ({
		type: actions.EVENTS_FILTER_LEAGUE_LIST_REFRESH,
		data: list,
	}),
	nameListRefresh: list => ({
		type: actions.EVENTS_FILTER_NAME_LIST_REFRESH,
		data: list,
	}),
	nameListReload: params => ({
		type: actions.EVENTS_FILTER_NAME_LIST_RELOAD,
		data: params,
	}),
};

export default actions;
