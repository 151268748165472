import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import find from 'lodash/find';

import langConfig from '../../containers/Topbar/LanguageSwitcher/config';

import actions from './actions';
import appActions from '../app/actions';
import socketActions from '../socket/actions';
import bannersListActions from '../cms/banners/list/actions';
import { authApi } from '../../helpers/api/auth';
import { showError } from '../../helpers/notifications';
import { restoreLanguage } from '../../helpers/utility';

import { adaptUser } from './utils';

const getStoreData = (state) => {
	const authUI = state.Auth.get('UI');
	return {
		rememberMe: authUI.rememberMe,
	};
};

export function clearLocalStorage() {
	localStorage.removeItem('id_token');
	localStorage.removeItem('token');
	localStorage.removeItem('permissions');
	localStorage.removeItem('user');
	localStorage.removeItem('sidebar');
	localStorage.removeItem('language');
	localStorage.removeItem('appTabs');
	localStorage.removeItem('usersFilter');
	localStorage.removeItem('transactions');
	localStorage.removeItem('websiteID');
	localStorage.removeItem('tabIDS');
	localStorage.removeItem('adminID');
	localStorage.removeItem('partnerID');
}

export function clearSessionStorage() {
	sessionStorage.clear();
}

export const setSessionCommon = (token, remember) => {
	sessionStorage.setItem('id_token', token);
	localStorage.setItem('remember', JSON.stringify(remember));
};

export function* loginRequest() {

	yield takeEvery(actions.LOGIN_REQUEST, function* (authData) {
		yield put(actions.uiRefresh({ loading: true }));
		const reqData = authData.data;
		try {
			const res = yield call(authApi.apiLogin, reqData);

			if (res && res.status === 200) {
				const token = res.data.data.access_token;
				const { adaptedData : user, permissions } = adaptUser(res.data.data.user);
				yield put(actions.loginSucces(token, user, permissions));
				yield put(actions.permissionsRefresh(permissions));
				yield put(bannersListActions.listReload());
			} else {
				yield put(actions.loginError());
			}

		} catch (error) {
			showError('Login failed', error);
			yield put(actions.loginError());
		}

		yield put(actions.uiRefresh({ loading: false }));

	});
}

export const setStorageCommon = (token, remember) => {
	localStorage.setItem('id_token', token);
	localStorage.setItem('remember', JSON.stringify(remember));
};


export function* loginSuccess() {
	yield takeEvery(actions.LOGIN_SUCCESS, function* (action) {
		const { rememberMe } = yield select(getStoreData);
		const { token, user, permissions } = action.data;

		yield localStorage.setItem('user', JSON.stringify(user));
		yield localStorage.setItem('permissions', JSON.stringify(permissions));
		yield localStorage.setItem('remember', JSON.stringify(rememberMe));

		if (rememberMe) {
			setStorageCommon(token, rememberMe);
		} else {
			setSessionCommon(token, rememberMe);
		}

		yield applyUserLanguage(user.langID);
		yield put(socketActions.reconnect());
	});
}

export function* loginError() {
	yield takeEvery(actions.LOGIN_ERROR, function* () {
		yield call(clearLocalStorage);
		yield call(clearSessionStorage);
		yield put(socketActions.reconnect());
	});
}

export function* logOut() {
	yield takeEvery(actions.LOGOUT, function* () {
		try {
			yield call(authApi.logout);
		} catch (e) {
			// showError('Logout failed', e);
		}

		yield put(appActions.appDataReset());
		yield put(actions.logoutCase());
		yield put(appActions.appModalReset());
		yield put(socketActions.reconnect());
		yield call(clearLocalStorage);
		yield call(clearSessionStorage);
	});
}

function* applyUserLanguage(langID) {
	const storedLanguage = yield call(restoreLanguage);
	if (storedLanguage) {
		return;
	}

	let language = find(langConfig.options, { dataBaseLangID: langID });
	if (!language) {
		language = yield select( ({ LanguageSwitcher }) => LanguageSwitcher.get('language') );
	}

	// TODO THIS LINE IS REDUNDANT. IT SHOULD BE REMOVED. checked117
	// yield put(languageActions.changeLanguage(language.dataBaseLangID));
}

export default function* authSaga() {
	yield all([
		fork(loginRequest),
		fork(loginSuccess),
		fork(loginError),
		fork(logOut),
	]);
}
