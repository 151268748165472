/**
 * NOTE: If you are changing Sidebar items, visit
 * /src/helpers/permissions/constants to set up permissions for these items:
 * constant SIDEBAR_PERMISSIONS
 *
 * Also, you can set icon for any level menu item in ./defaultSidebar.icons.js
 * Root-level icons will be shown in sidebar, others - in App Tabs and as site
 * favicon
 */
import { COMPONENTS as cm } from '../constants/components';

const sidebar = [
	{
		key      : 'dashboard',
		title    : 'dashboard',
		url      : 'dashboard',
		order    : 1,
		component: cm.dashboard,
		menuItems: [],
	},
	{
		key      : 'rtm',
		title    : 'rtmMenu',
		order    : 2,
		menuItems: [
			{ key: 'rtm/bets',        title: 'rtm.bets',            url: 'rtm/bets',        order: 1, component: cm.rtmBets },
			{ key: 'rtm/deposits',    title: 'rtm.deposits',        url: 'rtm/deposits',    order: 2, component: cm.rtmDeposits },
			{ key: 'rtm/withdrawals', title: 'rtm.withdrawals',     url: 'rtm/withdrawals', order: 3, component: cm.rtmWithdrawals },
			{ key: 'rtm/casino',      title: 'rtm.casino',          url: 'rtm/casino',      order: 4, component: cm.rtmCasino },
			{ key: 'rtm/documents',   title: 'rtm.documents',       url: 'rtm/documents',   order: 5, component: cm.rtmDocuments },
			{ key: 'rtm/risk-alerts', title: 'rtm.riskManagement',  url: 'rtm/risk-alerts', order: 6, component: cm.rtmRiskManagement },
		],
	},
	{
		key      : 'staff',
		title    : 'staff',
		order    : 3,
		menuItems: [
			{ key: 'staff/personal',      title: 'personal',    url: 'staff/personal',      order: 1,   component: cm.personalList },
			{ key: 'staff/roles',   title: 'userRoles',   url: 'staff/roles',   order: 2,   component: cm.usersRoles },
		],
	},
	{
		key      : 'partners',
		title    : 'partner',
		order    : 4,
		menuItems: [
			{ key: 'partners/partners',    title: 'partner',    url: 'partners/partners',    order: 1,   component: cm.partner },
			{ key: 'partners/websites',    title: 'websites',   url: 'partners/websites',   order: 2,   component: cm.websites },
		],
	},
	{
		key      : 'users',
		title    : 'usersMenu',
		order    : 5,
		menuItems: [
			{ key: 'users/list',   title: 'usersSearch', url: 'users/list',   order: 1, component: cm.usersList },
			{ key: 'users/groups', title: 'userGroups',  url: 'users/groups', order: 2, component: cm.usersGroups },
		],
	},
	{
		key      : 'loyalty',
		title    : 'loyalty',
		order    : 6,
		menuItems: [
			{
				key      : 'bonusingSystem',
				title    : 'bonusesMenu',
				order    : 1,
				menuItems: [
					{ key: 'loyalty/bonusingSystem/bonuses',           title: 'bonuses.bonuses',       url: 'loyalty/bonusingSystem/bonuses',           order: 1, component: cm.bonuses },
					{ key: 'loyalty/bonusingSystem/freeSpins',         title: 'bonuses.freeSpins',     url: 'loyalty/bonusingSystem/freeSpins',         order: 2, component: cm.freeSpinsList },
					{ key: 'loyalty/bonusingSystem/welcome',           title: 'bonuses.welcome',       url: 'loyalty/bonusingSystem/welcome',           order: 3, component: cm.welcomeBonusList },
					{ key: 'loyalty/bonusingSystem/cashback',          title: 'bonuses.cashback',      url: 'loyalty/bonusingSystem/cashback',          order: 4, component: cm.cashbackBonusList },
					{ key: 'loyalty/bonusingSystem/usersWithCashback', title: 'bonuses.cashbackUsers', url: 'loyalty/bonusingSystem/usersWithCashback', order: 5, component: cm.cashbackUsersBonusList },
					{ key: 'loyalty/bonusingSystem/freeBets',          title: 'bonuses.freeBets',      url: 'loyalty/bonusingSystem/freeBets',          order: 6, component: cm.freeBetsList },
					{ key: 'loyalty/bonusingSystem/massBonus',         title: 'bonuses.massBonus',     url: 'loyalty/bonusingSystem/massBonus',         order: 7, component: cm.massBonus },
				],
			},
			{ key: 'loyalty/tournaments', title: 'tournaments',  url: 'loyalty/tournaments', order: 2, component: cm.tournaments },
		],
	},
	// {
	//   key       : 'bonusingSystem',
	//   title     : 'bonusesMenu',
	//   order     : 6,
	//   menuItems : [
	//     { key: 'bonusingSystem/bonuses',           title: 'bonuses.bonuses',       url: 'bonusingSystem/bonuses',           order: 1, component: cm.bonuses },
	//     { key: 'bonusingSystem/freeSpins',         title: 'bonuses.freeSpins',     url: 'bonusingSystem/freeSpins',         order: 2, component: cm.freeSpinsList },
	//     { key: 'bonusingSystem/welcome',           title: 'bonuses.welcome',       url: 'bonusingSystem/welcome',           order: 3, component: cm.welcomeBonusList },
	//     { key: 'bonusingSystem/cashback',          title: 'bonuses.cashback',      url: 'bonusingSystem/cashback',          order: 4, component: cm.cashbackBonusList },
	//     { key: 'bonusingSystem/usersWithCashback', title: 'bonuses.cashbackUsers', url: 'bonusingSystem/usersWithCashback', order: 5, component: cm.cashbackUsersBonusList },
	//     { key: 'bonusingSystem/freeBets',          title: 'bonuses.freeBets',      url: 'bonusingSystem/freeBets',          order: 6, component: cm.freeBetsList },
	//     { key: 'bonusingSystem/massBonus',         title: 'bonuses.massBonus',     url: 'bonusingSystem/massBonus',         order: 7, component: cm.massBonus },
	//   ],
	// },
	{
		key      : 'casino',
		title    : 'casinoMenu',
		order    : 7,
		menuItems: [
			{ key: 'casino/list',            title: 'casino.list',            url: 'casino/list',            order: 1, component: cm.casinoList },
			{ key: 'casino/gameCategories',  title: 'casino.gameCategories',  url: 'casino/gameCategories',  order: 2, component: cm.casinoGameCategories },
			{ key: 'casino/customProviders', title: 'casino.customProviders', url: 'casino/customProviders', order: 3, component: cm.casinoCustomProviders },
			{ key: 'casino/games',           title: 'casino.games',           url: 'casino/games',           order: 4, component: cm.casinoGames },
			{ key: 'casino/integrators',     title: 'casino.integrators',     url: 'casino/integrators',     order: 5, component: cm.casinoIntegrators },
		],
	},
	{
		key      : 'sport',
		title    : 'sportMenu',
		order    : 8,
		menuItems: [
			{ key      : 'sport/categories',
				title    : 'categoriesMenu',
				url      : 'sport/categories',
				order    : 1,
				component: cm.categoriesTree,
			},
			{ key: 'sport/events', title: 'events', url: 'sport/events', order: 2, component: cm.events },
			{ key: 'sport/results', title: 'results', url: 'sport/results', order: 3, component: cm.results },
			{ key      : 'sport/liveStatisticTypes',
				title    : 'liveStatisticTypes',
				url      : 'sport/liveStatisticTypes',
				order    : 4,
				component: cm.liveStatisticTypes,
			},
			{
				key      : 'markets',
				title    : 'marketsMenu',
				order    : 4,
				menuItems: [
					{ key: 'sport/markets/templates',         title: 'markets.templates',     url: 'sport/markets/templates',         order: 1, component: cm.marketsTemplates },
					{ key: 'sport/markets/groups',            title: 'markets.groups',        url: 'sport/markets/groups',            order: 2, component: cm.marketsGroups },
					{ key: 'sport/markets/statisticTypes',    title: 'statisticTypes.list',   url: 'sport/markets/statisticTypes',    order: 3, component: cm.settingsStatisticTypes },
					{ key: 'sport/markets/displayTypes',      title: 'markets.displayTypes',  url: 'sport/markets/displayTypes',      order: 5, component: cm.marketsDisplayTypes },
				],
			},
			{
				key      : 'participants',
				title    : 'participantsMenu',
				order    : 5,
				menuItems: [
					{ key: 'sport/participants/list', title: 'participants.list', url: 'sport/participants/list', order: 1, component: cm.participantsList },
					{ key: 'sport/participants/ages', title: 'participants.ages', url: 'sport/participants/ages', order: 2, component: cm.participantsAges },
				],
			},
			{
				key      : 'sportSettings',
				title    : 'sportSettings',
				order    : 6,
				menuItems: [
					{ key: 'sport/sportSettings/bettingRules',  title: 'bettingRules',    url: 'sport/sportSettings/bettingRules',  order: 1, component: cm.bettingRules },
					{ key: 'sport/sportSettings/scopes',        title: 'scopes.list',     url: 'sport/sportSettings/scopes',        order: 2, component: cm.settingsScopes },
					{ key: 'sport/sportSettings/ladders',       title: 'markets.ladders', url: 'sport/sportSettings/ladders',       order: 3, component: cm.marketsLadders },
				],
			},
		],
	},
	{
		key      : 'settings',
		title    : 'settingsMenu',
		order    : 9,
		menuItems: [
			{ key: 'settings/sidebarEditor',  title: 'sidebarEditor', url: 'settings/sidebarEditor',  order: 1, component: cm.settingsSidebarEditor },
			{
				key      : 'sms',
				title    : 'SMS',
				order    : 2,
				menuItems: [
					{ key: 'settings/sms/smsGateways',  title: 'settings.sms.smsGateways',  url: 'settings/sms/smsGateways',  order: 1, component: cm.smsGateways },
					{ key: 'settings/sms/smsTemplates',  title: 'settings.sms.smsTemplates',  url: 'settings/sms/smsTemplates',  order: 2, component: cm.smsTemplates },
				],
			},
			{ key: 'settings/currencies',              title: 'currencies',    url: 'settings/currencies',     order: 3, component: cm.currencies },
			{ key: 'settings/payments',                title: 'payments',      url: 'settings/payments',       order: 4, component: cm.payments },
			{
				key      : 'security',
				title    : 'securityMenu',
				order    : 5,
				menuItems: [
					{ key: 'settings/security/questions',  title: 'security.questions',  url: 'settings/security/questions',  order: 1, component: cm.securityQuestions },
					{ key: 'settings/security/blockedIps', title: 'security.blockedIps', url: 'settings/security/blockedIps', order: 2, component: cm.securityBlockedIps },
					{ key: 'settings/security/whitelist',  title: 'security.whitelist',  url: 'settings/security/whitelist',  order: 3, component: cm.securityWhitelist },
				],
			},
		],
	},
	{
		key      : 'cms',
		title    : 'CMS',
		order    : 10,
		menuItems: [
			{
				key      : 'content',
				title    : 'cmsMenu',
				order    : 1,
				menuItems: [
					{ key: 'cms/content/header',     title: 'menu.header',     url: 'cms/content/header',     order: 1, component: cm.contentHeader },
					{ key: 'cms/content/footer',     title: 'menu.footer',     url: 'cms/content/footer',     order: 2, component: cm.contentFooter },
					{ key: 'cms/content/articles',   title: 'menu.articles',   url: 'cms/content/articles',   order: 3, component: cm.contentArticles },
				],
			},
			{
				key      : 'homeManage',
				title    : 'homeManage',
				order    : 2,
				menuItems: [
					{ key: 'cms/homeManage/priorities',    title: 'homeManage.priorities',    url: 'cms/homeManage/priorities',    order: 1, component: cm.homeManagePriorities },
					{ key: 'cms/homeManage/specialOffers', title: 'homeManage.specialOffers', url: 'cms/homeManage/specialOffers', order: 2, component: cm.homeManageSpecialOffers },
					{ key: 'cms/homeManage/upcoming',      title: 'homeManage.upcoming',      url: 'cms/homeManage/upcoming',      order: 3, component: cm.homeManageUpcoming },
					{ key: 'cms/homeManage/matchesOfDay',  title: 'homeManage.matchesOfDay',  url: 'cms/homeManage/matchesOfDay',  order: 4, component: cm.homeManageMatchesOfDay },
					{ key: 'cms/homeManage/landingPageGameGrid',     title: 'menu.gameGrid',     url: 'cms/homeManage/landingPageGameGrid',     order: 5, component: cm.contentLandingPageGameGrid },

				],
			},
			{
				key      : 'cmsPages',
				title    : 'cmsPages',
				order    : 3,
				menuItems: [
					{ key: 'cms/cmsPages/infoPages',  title: 'menu.infoPages',  url: 'cms/cmsPages/infoPages',  order: 1, component: cm.contentInfoPages },
					{ key: 'cms/cmsPages/news',       title: 'menu.news',       url: 'cms/cmsPages/news',       order: 2, component: cm.contentNews },
					{ key: 'cms/cmsPages/promotions', title: 'menu.promotions', url: 'cms/cmsPages/promotions', order: 3, component: cm.contentPromotions },
					{ key: 'cms/cmsPages/helpCenter', title: 'menu.helpCenter', url: 'cms/cmsPages/helpCenter', order: 4, component: cm.contentHelpCenter },
				],
			},
			{
				key      : 'banners',
				title    : 'bannersMenu',
				order    : 4,
				menuItems: [
					{ key: 'cms/banners/list',   title: 'banners.list',   url: 'cms/banners/list',   order: 1, component: cm.bannersList },
					{ key: 'cms/banners/groups', title: 'banners.groups', url: 'cms/banners/groups', order: 2, component: cm.bannersGroups },
				],
			},
		],
	},
	{
		key      : 'reports',
		title    : 'reports',
		order    : 11,
		component: cm.reports,
		menuItems: [
			{ key: 'reports/reportByPlayer',     title: 'reports.reportByPlayer',     url: 'reports/reportByPlayer',     order: 1, component: cm.reportByPlayer },
			{ key: 'reports/reportByGame',       title: 'reports.reportByGame',       url: 'reports/reportByGame',       order: 2, component: cm.reportByGame },
			{ key: 'reports/reportByProvider',   title: 'reports.reportByProvider',   url: 'reports/reportByProvider',   order: 3, component: cm.reportByProvider },
			{ key: 'reports/reportByIntegrator', title: 'reports.reportByIntegrator', url: 'reports/reportByIntegrator', order: 4, component: cm.reportByIntegrator },
			{
				key      : 'reports/transactions',
				title    : 'transactions',
				url      : 'reports/transactions',
				order    : 10,
				component: cm.transactions,
				menuItems: [],
			},
		],
	},
	{
		key      : 'notificationCenter',
		title    : 'notificationCenter',
		order    : 12,
		menuItems: [
			{ key: 'notificationCenter/pushNotifications',    title: 'pushNotifications',    url: 'notificationCenter/pushNotifications',    order: 1,   component: cm.pushNotifications },
			{ key: 'notificationCenter/messages',    title: 'messages',    url: 'notificationCenter/messages',    order: 2,   component: cm.messages },
			{ key: 'notificationCenter/sms',    title: 'sms',    url: 'notificationCenter/sms',    order: 3,   component: cm.sms },
			{
				key      : 'emails',
				title    : 'emailsMenu',
				order    : 4,
				menuItems: [
					{ key: 'notificationCenter/emails/list',      title: 'emails.list',      url: 'notificationCenter/emails/list',      order: 1, component: cm.emailsList },
					{ key: 'notificationCenter/emails/templates', title: 'emails.templates', url: 'notificationCenter/emails/templates', order: 2, component: cm.emailsTemplates },
				],
			},
		],
	},
	{
		key      : 'affiliates',
		title    : 'affiliates',
		url      : 'affiliates',
		order    : 13,
		component: cm.affiliates,
		menuItems: [],
	},

];

export default sidebar;
