import ParamsBuilder from '../../../helpers/paramsBuilder';
import { fields } from '../withdrawals/utils';

export function getListParams(filter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isID, 'userID', fields.userID);
	builder.addField(rules.isID, 'wdID', fields.wdID);
	builder.addField(rules.isString, 'wdIP', fields.wdIP);
	builder.addField(rules.isArrayString, 'wdIPCountry', fields.wdIPCountry);
	builder.addField(rules.isArrayID, 'wdChannelIDs', fields.wdChannelIDs);
	builder.addField(rules.isArrayID, 'wdStatusIDs', fields.wdStatusIDs);

	const params = builder.biuldParams(filter);

	return params;
}
