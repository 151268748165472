import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';


const initState = new Map({
	baseData: {
		status         : false,
		activationDelay: 24,
	},
	UI: {
		loading    : false,
		baseChanged: false,
	},
});

export default function responsibleGambling(state = initState, action) {

	switch (action.type) {
	case actions.DATA_REFRESH: {

		return state.set('baseData', action.data);
	}

	case actions.UI_REFRESH: {
		const target = state.get('UI');
		const result = fill(action.data, target);
		return state.set('UI', result);
	}

	case actions.DATA_RESET: {
		return initState;
	}

	default:
		return state;
	}
}
