import { makeActionCreator } from '../../../helpers';

const prefix = 'BONUSES_BONUSES';

const TYPES = {
	LIST_RELOAD   : `${prefix}_LIST_RELOAD`,
	LIST_REFRESH  : `${prefix}_LIST_REFRESH`,
	FILTER_REFRESH: `${prefix}_LIST_FILTER_REFRESH`,
	FILTER_APPLY  : `${prefix}_LIST_FILTER_APPLY`,

	BONUS_SAVE           : `${prefix}_BONUS_SAVE`,
	BONUS_DATA_REFRESH   : `${prefix}_BONUS_DATA_REFRESH`,
	BONUS_DATA_RESET     : `${prefix}_BONUS_DATA_RESET`,
	BONUS_UI_REFRESH     : `${prefix}_BONUS_UI_REFRESH`,
	BONUS_LIST_REFRESH   : `${prefix}_BONUS_LIST_REFRESH`,
	GAME_LIMITS_RELOAD   : `${prefix}_GAME_LIMITS_RELOAD`,
	BETS_REFRESH         : `${prefix}_BETS_REFRESH`,
	DENOMINATIONS_REFRESH: `${prefix}_DENOMINATIONS_REFRESH`,

	HISTORY_LOGS_RELOAD : `${prefix}_HISTORY_LOGS_RELOAD`,
	HISTORY_LOGS_REFRESH: `${prefix}_HISTORY_LOGS_REFRESH`,

	GAME_LIST_INFINITE_RESET          : `${prefix}_GAME_LIST_INFINITE_RESET`,
	NEW_GAMES_HAS_MORE_REFRESH        : `${prefix}_NEW_GAMES_HAS_MORE_REFRESH`,
	NEW_GAMES_BY_PAGE_REFRESH         : `${prefix}_NEW_GAMES_BY_PAGE_REFRESH`,
	NEW_GAMES_BY_PAGE_REFRESH_FILTERED: `${prefix}_NEW_GAMES_BY_PAGE_REFRESH_FILTERED`,
	NEW_GAMES_BY_PAGE_RELOAD          : `${prefix}_NEW_GAMES_BY_PAGE_RELOAD`,

	GAME_PAGE_REFRESH_EMPTY: 'GAME_PAGE_REFRESH_EMPTY',
	CHECKED_GAMES_ID_RESET : 'CHECKED_GAMES_ID_RESET',
	CASINO_GAMES_ID_RESET  : 'CASINO_GAMES_ID_RESET',
};

const actions = {
	...TYPES,

	listReload          : makeActionCreator(TYPES.LIST_RELOAD),
	listRefresh         : makeActionCreator(TYPES.LIST_REFRESH, 'entities'),
	bonusListRefresh    : makeActionCreator(TYPES.BONUS_LIST_REFRESH, 'bonusList'),
	filterRefresh       : makeActionCreator(TYPES.FILTER_REFRESH, 'filter'),
	filterApply         : makeActionCreator(TYPES.FILTER_APPLY),
	bonusSave           : makeActionCreator(TYPES.BONUS_SAVE),
	dataRefresh         : makeActionCreator(TYPES.BONUS_DATA_REFRESH, 'bonusData'),
	dataReset           : makeActionCreator(TYPES.BONUS_DATA_RESET),
	uiRefresh           : makeActionCreator(TYPES.BONUS_UI_REFRESH, 'UI'),
	gameLimitsReload    : makeActionCreator(TYPES.GAME_LIMITS_RELOAD, 'casinoGameID', 'currencyCode', 'withRefresh'),
	betsRefresh         : makeActionCreator(TYPES.BETS_REFRESH, 'bets'),
	denominationsRefresh: makeActionCreator(TYPES.DENOMINATIONS_REFRESH, 'denominations'),
	historyLogsReload   : makeActionCreator(TYPES.HISTORY_LOGS_RELOAD, 'bonusID'),
	historyLogsRefresh  : makeActionCreator(TYPES.HISTORY_LOGS_REFRESH, 'historyLogs'),

	gamesListReloadLimited: (websiteID, casinoID, channelID, byCategory, name, providerType, page, limit, isUnlimit, isSearch ) => {

		return {
			type: actions.NEW_GAMES_BY_PAGE_RELOAD,
			data: {
				websiteID, casinoID, channelID, byCategory, name, providerType, page, limit, isUnlimit, isSearch,
			},
		};
	},

	gamesListRefreshLimited: data => {
		return {
			type: actions.NEW_GAMES_BY_PAGE_REFRESH,
			data,
		};
	},
	gamesListInfiniteReset: () => {
		return {
			type: actions.GAME_LIST_INFINITE_RESET,
		};
	},

	gamesListRefreshFilteredLimited: data => {
		return {
			type: actions.NEW_GAMES_BY_PAGE_REFRESH,
			data,
		};
	},

	gamesListHasMoreRefresh: data => {
		return {
			type: actions.NEW_GAMES_HAS_MORE_REFRESH,
			data,
		};
	},

	gamesListRefreshEmpty: data => {
		return {
			type: actions.GAME_PAGE_REFRESH_EMPTY,
			data,
		};
	},

	checkedGamesIdReset: () => {
		return { type: actions.CHECKED_GAMES_ID_RESET };
	},

	casinoGamesIdReset: () => {
		return { type: actions.CASINO_GAMES_ID_RESET };
	},

	//
	// gamesListReloadLimited: makeActionCreator(TYPES.NEW_GAMES_BY_PAGE_RELOAD,'limit', 'page', 'name'),
	// gamesListRefreshLimited: makeActionCreator(TYPES.NEW_GAMES_BY_PAGE_RELOAD, 'gamesListInfinite'),
};

export default actions;
